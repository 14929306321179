import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { brightGrey, medium, small, red, boldWeight } from '../../../themes/globalConstants';
import { DeliveryShipment } from '../../../store/reducers/ordering-dashboard';
import { Trans, useTranslation } from 'react-i18next';
import { productTypeHelper } from '../../../utility/helpers/order-helpers';

interface Props {
    shipment: DeliveryShipment;
    layout?: 'horizontal' | 'vertical';
}

const useStyles = makeStyles((theme) => ({
    label: {
        color: brightGrey,
        fontWeight: boldWeight,
        fontSize: small,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    dataText: {
        color: brightGrey,
        fontWeight: boldWeight,
        fontSize: medium,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    redText: {
        color: red
    },
    tableText: {
        fontSize: small,
        textTransform: 'uppercase'
    },
    infoText: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    tableWrapper: {
        '& .MuiTableCell-root': {
            border: 0
        }
    }
}));

export default function ShipmentDetail({ shipment, layout = 'horizontal' }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const palletCount = shipment.loads?.reduce((acc, load) => acc + load.palletQuantity, 0) || 0;
    const productCount = shipment.productCount || 0;
    const shipmentType = productTypeHelper(shipment.shipmentType);

    const shipmentTypeText = useMemo(() => {
        if (shipmentType === 'CAN') {
            return t('cansBottles', 'Cans / Bottles');
        } else if (shipmentType === 'END') {
            return t('endsClosures', 'Ends / Closures');
        }
        return '';
    }, [t, shipmentType]);

    if (layout === 'horizontal') {
        return (
            <Grid container direction="row" spacing={2}>
                <Grid item direction="column" xs={4}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="type">Type</Trans>
                    </Typography>
                    <Typography className={classes.dataText}>{shipmentTypeText}</Typography>
                </Grid>
                <Grid item direction="column" xs={4}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="products">Products</Trans>
                    </Typography>
                    <Typography className={classes.dataText}>{productCount}</Typography>
                </Grid>
                <Grid item direction="column" xs={4}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="pallets">Pallets</Trans>
                    </Typography>
                    <Typography className={clsx(classes.dataText, classes.redText)}>
                        {palletCount}
                    </Typography>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Table padding="none" size="small" className={classes.tableWrapper}>
                <TableBody>
                    <TableRow>
                        <TableCell align="right">
                            <Typography variant="body1" className={classes.tableText}>
                                <Trans i18nKey="type">Type</Trans> :
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography className={classes.dataText}>{shipmentTypeText}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">
                            <Typography variant="body1" className={classes.tableText}>
                                <Trans i18nKey="products">Products</Trans> :
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography className={classes.dataText}>{productCount}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right">
                            <Typography variant="body1" className={classes.tableText}>
                                <Trans i18nKey="pallets">Pallets</Trans> :
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography className={clsx(classes.dataText, classes.redText)}>
                                {palletCount}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}
