import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDashboardPositions } from '../../../../../store/actions/user-configuration';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { selectSortedDashboardPositions } from '../../../../../store/selectors/user-configuration.selectors';
import {
    customerDashboardDragAndDropItems,
    useDragAndDrop as useCustomerDashboardDragAndDrop
} from '../utilities/dashboard-positions.config';
import DragAndDrop from '../../../../reusable/molecules/DragAndDrop/DragAndDrop';
import { DragAndDropItem } from '../../../../reusable/molecules/DragAndDrop/models';

interface Props {
    showOrderManagementWidget: boolean;
    showShipmentsAndDeliveriesWidget: boolean;
    showInventoryWidget: boolean;
    showPaymentsManagementWidget: boolean;
    showProductPortfolioWidget: boolean;
}

export default function CustomerDashboardDragAndDrop({
    showOrderManagementWidget,
    showShipmentsAndDeliveriesWidget,
    showInventoryWidget,
    showPaymentsManagementWidget,
    showProductPortfolioWidget
}: Props) {
    const dispatch = useDispatch();
    const sortedDashboardPositions = useTypedSelector(selectSortedDashboardPositions);
    const onReorder = (sorted) => dispatch(updateDashboardPositions(sorted));

    const [listItems, setListItems] = useState<Array<DragAndDropItem>>(
        customerDashboardDragAndDropItems(
            showOrderManagementWidget,
            showShipmentsAndDeliveriesWidget,
            showInventoryWidget,
            showPaymentsManagementWidget,
            showProductPortfolioWidget
        )
    );
    const [onDropEnd, updateListItems] = useCustomerDashboardDragAndDrop(
        listItems,
        setListItems,
        sortedDashboardPositions,
        onReorder
    );

    useEffect(() => {
        const items = customerDashboardDragAndDropItems(
            showOrderManagementWidget,
            showShipmentsAndDeliveriesWidget,
            showInventoryWidget,
            showPaymentsManagementWidget,
            showProductPortfolioWidget
        );
        updateListItems(items);
        //eslint-disable-next-line
    }, [
        showOrderManagementWidget,
        showShipmentsAndDeliveriesWidget,
        showInventoryWidget,
        showPaymentsManagementWidget,
        showProductPortfolioWidget
    ]);

    return <DragAndDrop items={listItems} onDropEnd={onDropEnd} />;
}
