import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import {
    Grid,
    Typography,
    makeStyles,
    Link,
    Paper,
    CircularProgress,
    Button
} from '@material-ui/core';

import {
    boldWeight,
    ballGray,
    black_5,
    blackWeight,
    xxxl,
    ballDrkBlue,
    large,
    ballGrayBlue
} from '../../../../../themes/globalConstants';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import { enUS } from '../../../../../utility/translations/locales';
import DashboardWidgetMetricRow from './DashboardWidgetMetricRow';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import DashboardWidgetMetric from './DashboardWidgetMetric';
import { CustomerOrderingDashboardState } from '../../../../../store/reducers/customer-ordering-dashboard';

interface Props {
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        borderRadius: 4,
        padding: '2em 2em 3em 2em'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    iconText: {
        color: black_5,
        fontWeight: blackWeight,
        marginBottom: '1em',
        marginTop: '1em',
        marginLeft: '0.5em'
    },
    icon: {
        height: '1.25em'
    },
    widgetSectionHeader: {
        color: ballGray,
        fontWeight: blackWeight,
        letterSpacing: 1,
        marginBottom: '1.75em',
        textTransform: 'uppercase'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight,
        marginTop: '2em'
    },
    widgetNumber: {
        fontSize: xxxl,
        color: ballDrkBlue,
        position: 'relative',

        '&:hover::after': {
            content: '""',
            position: 'absolute',
            bottom: '1rem',
            left: '0',
            width: '100%',
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    widgetValue: {
        fontSize: '2rem',
        marginTop: '5rem',
        position: 'relative'
    },
    shipmentsSubHeaderText: {
        color: ballGrayBlue,
        textTransform: 'uppercase'
    },
    numPalletsSubHeaderText: {
        color: black_5,
        fontWeight: boldWeight,
        marginBottom: '2em',
        textTransform: 'uppercase'
    },
    shipmentsSummaryDetailsContainer: {
        height: 100,
        marginTop: -7
    },
    shipmentsSummaryDetails: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    }
}));

interface FutureShipment {
    date: string;
    shipments: number;
    startOfWeek: string;
    endOfWeek: string;
}

export default function ShipmetDeliveryDashboardWidget({ dragHandleProps }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { shipmentsDeliveriesStatus } = useTypedSelector<CustomerOrderingDashboardState>(
        (state) => state.customerOrderingDashboard
    );
    const [futureShipments, setFutureShipments] = useState<FutureShipment[]>([]);

    const { dashboardData } = useTypedSelector<CustomerOrderingDashboardState>(
        (state) => state.customerOrderingDashboard
    );
    useEffect(() => {
        if (dashboardData?.shipmentDeliveriesDashboard?.futureShipments) {
            const agenda = dashboardData.shipmentDeliveriesDashboard.futureShipments;

            const groupedByWeek = agenda.reduce<{
                [key: number]: { week: number; totalPallets: number };
            }>((acc, item) => {
                const week = moment(item.weekDate).week();
                if (week <= moment().week() + 4) {
                    if (!acc[week]) {
                        acc[week] = { week, totalPallets: 0 };
                    }
                    acc[week].totalPallets += item.pallets;
                }
                return acc;
            }, {});

            const newShipments = Object.values(groupedByWeek).map(({ week, totalPallets }) => {
                const startOfWeek = moment().week(week).startOf('week').format('YYYY-MM-DD');
                const endOfWeek = moment()
                    .week(week)
                    .add(7, 'days')
                    .endOf('week')
                    .format('YYYY-MM-DD');
                return {
                    date: moment().week(week).startOf('week').format('MMM/DD'),
                    shipments: totalPallets,
                    startOfWeek: startOfWeek,
                    endOfWeek: endOfWeek
                };
            });

            setFutureShipments(newShipments);
        }
    }, [dashboardData]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="ship-it-dashboard-widget">
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="ship-it-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    {t('shipmentDeliveries', enUS.shipmentDeliveries)}
                </Typography>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="create-order"
                    style={{ marginLeft: '58%' }}
                    onClick={() => history.push('/orders-summary&tab=summaryByShipment')}
                >
                    Order Status
                </Button>
            </div>
            {shipmentsDeliveriesStatus === 'loading' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={6}>
                    <Grid container item xs={7}>
                        <DashboardWidgetMetricSection
                            title={t('upcomingDeliveries', enUS.upcomingDeliveries)}
                            isShipmentDelivery={true}
                            left={
                                <div>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.shipmentsSummaryDetailsContainer}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.shipmentsSummaryDetails}
                                        >
                                            <div>
                                                <Link
                                                    to={'/ship-it-summary?startWeek=0'}
                                                    underline="none"
                                                    component={RouterLink}
                                                    data-testid="current-future-shipments-link"
                                                    className={classes.link}
                                                >
                                                    <Typography className={classes.widgetNumber}>
                                                        {dashboardData?.shipmentDeliveriesDashboard
                                                            ?.arrivingThisWeek ?? 0}
                                                    </Typography>
                                                </Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="center">
                                        <Typography variant="body1" className={classes.iconText}>
                                            <Trans i18nKey="arrivingThisWeek">
                                                Arriving This Week
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                </div>
                            }
                            center={
                                <Grid item style={{ marginTop: '10%' }}>
                                    <Typography className={classes.iconText}>PL</Typography>
                                </Grid>
                            }
                            right={
                                <div>
                                    <Grid
                                        item
                                        style={{ marginLeft: '78px' }}
                                        data-testid="draft-orders-widget"
                                    >
                                        <DashboardWidgetMetric
                                            quantity={
                                                dashboardData?.shipmentDeliveriesDashboard
                                                    ?.draftShipments ?? 0
                                            }
                                            link={
                                                '/orders-summary&tab=summaryByShipment&status=draft'
                                            }
                                            subheader={t('draftShipments', enUS.draftShipments)}
                                            subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Draft_Orders_Icon.svg`}
                                        />
                                    </Grid>
                                </div>
                            }
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.widgetSectionHeader}>
                                <Trans i18nKey="futureDeliveries">Future Deliveries</Trans>
                            </Typography>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={12} container direction="column">
                                {futureShipments.map((shipment, index) => {
                                    let date = shipment.date;
                                    return (
                                        <button
                                            onClick={() =>
                                                history.push(
                                                    `/orders-summary&tab=orderDetails&week=${shipment.startOfWeek}&endWeek=${shipment.endOfWeek}`
                                                )
                                            }
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <DashboardWidgetMetricRow
                                                key={`shipment_${index}`}
                                                title={<Trans>{{ date }}</Trans>}
                                                showSecondary={false}
                                                secondItemCount={shipment.shipments}
                                                secondItemUnit={'PL'}
                                                isLink={true}
                                            />
                                        </button>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
