import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { selectCustomerAccountId } from '../../../store/actions/customer-context';
import { useDispatch } from 'react-redux';
import { loadTradeRequests } from '../../../store/actions/trade-it-summary';
import { TRADE_REQUEST_SUMMARY_RESET } from '../../../store/actions/action-types';
import { TradeRequestSummaryState } from '../../../store/reducers/trade-it-summary';
import TradeRequestSummaryTable from './components/TradeRequestSummaryTable';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
    dashboard: {
        padding: '0'
    },
    tableContainer: {
        marginTop: '1.750em'
    },
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    }
}));

export default function TradeRequestSummary() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const tradeItLink = '/trade-it';
    const [selectedOriginalShipTo, setSelectedOriginalShipTo] = useState<string>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { tradeRequests, loaded } = useTypedSelector<TradeRequestSummaryState>(
        (state) => state.tradeItSummary
    );

    const handleNavigation = (destination: string) => {
        history.push(destination);
    };

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length > 0) {
            dispatch(loadTradeRequests(shipToAccounts));
        }
    }, [shipToAccounts]);

    useEffect(() => {
        return () => {
            dispatch({ type: TRADE_REQUEST_SUMMARY_RESET });
        };
    }, [dispatch]);

    useEffect(() => {
        if (selectedAccountId && isLargeCustomerAccount) {
            setSelectedOriginalShipTo(selectedAccountId);
        }
    }, [selectedAccountId, isLargeCustomerAccount]);

    useEffect(() => {
        // handle change selected ship to - future stories
        if (selectedOriginalShipTo) {
            dispatch(selectCustomerAccountId(selectedOriginalShipTo));
        }
    }, [selectedOriginalShipTo]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('tradeIt', 'Trade It'),
                description: t('tradeRequestSummary', 'Balance Trade Summary'),
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.TradeItSummary}
            restrictToSecurityLevel={SecurityLevel.Edit}
            loading={false}
        >
            <Grid container item md={12} className={classes.main}>
                <Grid container item xs={12} justify="flex-end" className={classes.btnWrapper}>
                    <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        data-testid="trade-it-button-link"
                        className={classes.actionBtn}
                        onClick={() => handleNavigation(tradeItLink)}
                    >
                        <Trans i18nKey="tradeIt">Trade It</Trans>
                    </Button>
                </Grid>
            </Grid>
            <Grid container className={classes.dashboard} data-testid={'trade-it-summary'}>
                {loaded && (
                    <Grid
                        data-testid="trade-it-summary-table"
                        item
                        xs={12}
                        className={classes.tableContainer}
                    >
                        <TradeRequestSummaryTable
                            tradeRequests={tradeRequests}
                        ></TradeRequestSummaryTable>
                    </Grid>
                )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
