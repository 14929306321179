import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import { DeliveryShipment } from '../../../../store/reducers/shipping-dashboard';
import shipItConfigColors from './ShipItConfigColors';
import { blackWeight, ballGray, ballLtGray, xxl } from '../../../../themes/globalConstants';
import { formatEachesOrPallets } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    items: DeliveryShipment;
    truckPalletLimit: number;
    isLargeCustomer?: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: '2.5em'
    },
    colorBlock: {
        width: '2.5em',
        height: '2.5em',
        border: `1px solid ${ballLtGray}`,
        borderRadius: '0.125em',
        margin: '0.250em'
    },
    atmColorBlock: {
        width: '2.5em',
        height: '2.5em',
        border: `1px solid ${ballLtGray}`,
        borderRadius: '0.125em',
        margin: '0.17em'
    },
    colorGrid: {
        display: 'flex',
        width: '6.500em',
        flexWrap: 'wrap-reverse',
        transform: 'rotate(90deg)',
        position: 'absolute',
        top: '-15em',
        marginLeft: '24.75em'
    },
    shipmentDesc: {
        maxWidth: '9.500em'
    },
    shipmentDescMain: {
        color: ballGray
    },
    shipmentDescSub: {
        color: ballGray,
        marginBottom: '0.5em'
    },
    palletCountContainer: {
        fontSize: xxl,
        fontWeight: blackWeight,
        color: ballGray,
        lineHeight: 1.25,
        margin: '.25em 0em 0em 0em'
    },
    eachesCountContainer: {
        marginBottom: '1em'
    },
    truckWrapper: {
        position: 'relative'
    },
    truckSvg: {
        '& > img': {
            width: '100%'
        }
    }
}));

export default function ShipItConfigTruck({ items, isLargeCustomer, truckPalletLimit }: Props) {
    const classes = useStyles();
    const [itemsEachesTotal, setItemsEachesTotal] = useState<number>(0);
    const palletTotal = useMemo(() => {
        if (items.loads?.length) {
            return items.loads.reduce((prev, curr) => prev + curr.palletQuantity, 0);
        }
        return 0;
    }, [items]);

    useEffect(() => {
        if (items && items.loads?.length) {
            const eachesTotal = items.loads.reduce((prev, curr) => {
                const pallets = curr.palletQuantity;
                if (pallets) {
                    prev += pallets * curr.quantityPerPallet!;
                }
                return prev;
            }, 0);
            setItemsEachesTotal(eachesTotal);
        } else {
            setItemsEachesTotal(0);
        }
    }, [items]);

    const renderColorGrid = () => {
        let counter = 0;
        let colorGrid: React.ReactNode[] = [];

        if (items && items.loads && items.loads.length) {
            items.loads
                .sort((a, b) => {
                    if (a.sequence && b.sequence) return a.sequence - b.sequence;
                    return 0;
                })
                .forEach((load, index) => {
                    let pallets = load.palletQuantity;
                    if (pallets) {
                        counter = counter + pallets;
                        for (let x = 0; x < pallets; x++) {
                            colorGrid.push(
                                <Grid
                                    item
                                    key={'color-block-' + x + load.productSku + load.sequence}
                                    className={clsx(classes.colorBlock, {
                                        [classes.atmColorBlock]: isLargeCustomer
                                    })}
                                    style={{ backgroundColor: `${shipItConfigColors[index]}` }}
                                />
                            );
                        }
                    }
                });

            if (counter !== truckPalletLimit) {
                for (let y = 0; y < truckPalletLimit - counter; y++) {
                    colorGrid.push(
                        <Grid
                            item
                            key={'empty-block-' + y}
                            className={clsx(classes.colorBlock, {
                                [classes.atmColorBlock]: isLargeCustomer
                            })}
                        />
                    );
                }
            }
        }
        return colorGrid;
    };

    useTranslation();

    return (
        <Grid container item justify="space-between" alignItems="center" className={classes.main}>
            <Grid item className={classes.shipmentDesc}>
                <Typography variant="subtitle2" className={classes.shipmentDescMain}>
                    <Trans i18nKey="shipment">shipment</Trans>
                </Typography>
                <Typography className={classes.palletCountContainer}>
                    {items.palletCount || 0} of {truckPalletLimit} PL
                </Typography>
                <Typography className={classes.eachesCountContainer}>
                    {itemsEachesTotal ? formatEachesOrPallets(itemsEachesTotal, 'eaches') : '0 ea.'}
                </Typography>
                {palletTotal! < 25 && (
                    <Typography className={classes.shipmentDescSub}>
                        <Trans i18nKey="mayIncurFeesTruckload">
                            Shipping less than a full truckload may incur a fee.
                        </Trans>
                    </Typography>
                )}
            </Grid>
            <Grid item className={classes.truckWrapper}>
                {items && (
                    <>
                        <Typography component="span" className={classes.truckSvg}>
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Truck.svg'}
                                alt="truck svg"
                                height="100%"
                            />
                        </Typography>
                        <Typography component="span" className={classes.colorGrid}>
                            {renderColorGrid()}
                        </Typography>
                    </>
                )}
            </Grid>
            {isLargeCustomer && (
                <Typography className={classes.shipmentDescMain}>
                    <Trans i18nKey="shipmentSize">
                        The standard shipment size for a delivery order is 25 PL, but 27 PL may be
                        allowed for some locations.
                    </Trans>
                </Typography>
            )}
        </Grid>
    );
}
