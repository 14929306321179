import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';
import {
    Grid,
    Typography,
    Button,
    makeStyles,
    FormControl,
    FormHelperText,
    TextField,
    Paper
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import NewAccountSelection from '../../reusable/molecules/NewAccountSelection';
import DatePicker from '../../reusable/atoms/DatePicker';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { useHistory } from 'react-router';
import { FormContext, Controller, useForm, ErrorMessage } from 'react-hook-form';
import { red } from '../../../themes/globalConstants';
import { selectDateFormat } from '../../../store/selectors';
import { OrderingState } from '../../../store/reducers/ordering-dashboard';
import SaveDraftModal from '../../reusable/molecules/SaveDraftModal';
import OrdersService from '../../../utility/services/orders-service';
import {
    resetOrderingProcess,
    setOrderInformation,
    updateDeliveryOrder,
    updateDeliveryOrderUpdateError,
    updateShipments
} from '../../../store/actions/ordering-dashboard';
import { subtractTimezoneOffset } from '../../../utility/helpers/date-helpers';

const useStyles = makeStyles((theme) => ({
    btnWrapper: {
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        margin: theme.spacing(1)
    },
    paper: {
        marginTop: '2em',
        padding: '0 1.5em'
    },
    datePickerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        bottom: 0,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    infoText: {
        color: 'red',
        textAlign: 'center',
        flex: 1
    },
    dateOptionTag: {
        padding: '0.75em',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottomLeftRadius: '0.25em',
        borderBottomRightRadius: '0.25em',
        width: '100%',
        wordSpacing: '5px',
        textTransform: 'uppercase'
    },
    label: {
        textTransform: 'uppercase'
    },
    customIcon: {
        float: 'right',
        padding: '0px',
        color: '#009BBC'
    },
    errorText: {
        color: red
    }
}));

const defaultValues = {
    shipToId: '',
    poNumber: '',
    deliveryInstructions: '',
    deliveryDate: moment().add(1, 'day').startOf('day')
};

export default function CreateOrder() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues
    });
    const { accounts, selectedChildrenAccountId, useOrderingV2 } =
        useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { orderInformation } = useTypedSelector<OrderingState>(
        (state) => state.orderingDashboard
    );
    const state = useTypedSelector((state) => state);
    const dateFormat = useTypedSelector<string>(selectDateFormat);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [openBlockedNavigationModal, setOpenBlockedNavigationModal] = useState<boolean>(false);
    const [openSaveDraftModal, setOpenSaveDraftModal] = useState<boolean>(false);
    const orderSummaryLink = '/create-order-summary';
    const [customerPickUp, setCustomerPickUp] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { control, errors, getValues, setValue, handleSubmit, register, watch, reset } = methods;

    const { t } = useTranslation();

    const today = useMemo(() => moment().startOf('day').format(dateFormat), [dateFormat]);
    const tomorrow = moment().add(1, 'day').startOf('day');
    const firstAvailableDate = useMemo(() => tomorrow.format(dateFormat), [tomorrow, dateFormat]);

    const isOutsideRange = (day) => {
        return day.isBefore(tomorrow.startOf('day'));
    };

    const shipToId = watch('shipToId');
    const poNumber = watch('poNumber');
    const deliveryDate = watch('deliveryDate');

    const isRequired = useMemo(() => <Trans i18nKey="required">Required</Trans>, []);

    const onSubmit = (data) => {
        dispatch(setOrderInformation(data));
        history.push('/create-shipments');
    };

    const handleCancel = () => {
        dispatch(resetOrderingProcess());
        history.push('/dashboard');
    };

    // Sets the date value to the form element
    const handleDateChange = (name: string, newDate: moment.Moment | null) => {
        setValue(name, newDate);
    };

    useEffect(() => {
        if (!orderInformation?.shipToId) {
            dispatch(resetOrderingProcess());
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    useEffect(() => {
        if (selectedChildrenAccountId) {
            reset({
                deliveryDate: moment(orderInformation?.deliveryDate),
                deliveryInstructions: orderInformation?.deliveryInstructions,
                poNumber: orderInformation?.poNumber,
                shipToId: orderInformation?.shipToId || selectedChildrenAccountId
            });
        } else {
            setValue('shipToId', '');
        }
    }, [setValue, selectedChildrenAccountId]);

    useEffect(() => {
        if (shipToId && poNumber && deliveryDate) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [shipToId, poNumber, deliveryDate]);

    const onSaveDraft = () => {
        setOpenBlockedNavigationModal(false);
        setOpenSaveDraftModal(true);
    };

    const onCloseBlockedNavigationModal = () => {
        setOpenBlockedNavigationModal(false);
    };

    const onClose = () => {
        setOpenSaveDraftModal(false);
    };

    const handleSubmitForm = (isDraft = false, draftName?: string) => {
        setIsSubmitting(true);
        const data = {
            shipToId: getValues('shipToId'),
            poNumber: getValues('poNumber'),
            deliveryInstructions: getValues('deliveryInstructions'),
            deliveryDate: getValues('deliveryDate')
        };
        dispatch(setOrderInformation(data));

        let currentShipments: any[] = [];
        currentShipments.push({
            truckId: 1,
            deliveryDateTime: getValues('deliveryDate'),
            deliveryInstructions: getValues('deliveryInstructions'),
            status: null,
            loads: [],
            poNumber: getValues('poNumber')
        });

        OrdersService.submitOrderDraft
            .bind(null, draftName || '')(currentShipments, state, orderInformation)
            .then((response) => {
                let responseObj = { ...response.data };
                let responseShipments = isDraft
                    ? [...responseObj.orders[0].shipments]
                    : [...responseObj.shipments];

                if (responseShipments.length) {
                    responseShipments.map((shipment, i) => {
                        let tempShipment = { ...shipment };
                        let tempShipmentLoads = [...shipment.loads];

                        shipment.shipmentType = tempShipment.shipmentType;
                        tempShipment = { ...shipment, loads: tempShipmentLoads };
                        responseShipments.splice(i, 1, tempShipment);
                        return null;
                    });
                }

                responseObj = { ...responseObj, shipments: responseShipments };
                responseObj.customerPickup = customerPickUp;
                dispatch(updateDeliveryOrder(responseObj));
                dispatch(updateShipments(responseShipments));
                history.push(orderSummaryLink);
            })
            .catch((error) => {
                dispatch(updateDeliveryOrderUpdateError(error));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const footerActions = (
        <>
            <div className={classes.footer}>
                <div>
                    <Button
                        onClick={handleCancel}
                        variant="outlined"
                        color="secondary"
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="cancel">Cancel</Trans>
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.actionBtn}
                        startIcon={<SaveIcon />}
                        disabled={!isFormValid}
                        onClick={onSaveDraft}
                    >
                        <Trans i18nKey="saveDraft">Save Draft</Trans>
                    </Button>
                </div>
                <div>
                    {(errors.poNumber || errors.deliveryDate) && (
                        <Typography className={classes.infoText}>
                            <Trans i18nKey="fillOutRequiredFields">
                                All required fields must be completed prior to submitting.
                            </Trans>
                        </Typography>
                    )}
                    {!useOrderingV2 && (
                        <Typography className={classes.infoText}>
                            <Trans i18nKey="cantProceedWithSelectedAccount">
                                You can't proceed with the selected account
                            </Trans>
                        </Typography>
                    )}
                </div>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isFormValid}
                >
                    <Trans i18nKey="saveAddProduct">Save & Add Products</Trans>
                </Button>
            </div>
        </>
    );

    return (
        <ProcessingPageTemplate
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            banner={{
                header: t('createOrder', 'Create Order'),
                description: t('createDeliveryOrder', 'CREATE DELIVERY ORDER'),
                thinBanner: true,
                billToDropdown: true,
                disableSelect: false,
                hasChildrenSelection: true,
                displayDropdown: true,
                hasClearDropdownSelection: true
            }}
            activity={Activity.CreateOrder}
        >
            <Paper elevation={2} className={classes.paper}>
                <FormContext {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container spacing={3} justify="space-between">
                            <Grid container item xs={4}>
                                <Controller
                                    name="shipToId"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    defaultValue={selectedChildrenAccountId}
                                    as={
                                        <NewAccountSelection
                                            selectionType="shipTo"
                                            disableSelect={false}
                                            isChildrenSelection={true}
                                            isRequired={true}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                spacing={2}
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid container item>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        inputProps={{
                                            'data-testid': 'po-number',
                                            maxLength: 25
                                        }}
                                        label={<Trans i18nKey="poNumber">PO Number</Trans>}
                                        name="poNumber"
                                        data-testid="po-number"
                                        inputRef={register({ required: isRequired, maxLength: 30 })}
                                        error={!!errors?.poNumber}
                                    />
                                    {errors?.poNumber && (
                                        <FormHelperText
                                            className={clsx({
                                                [classes.errorText]: !!errors?.poNumber
                                            })}
                                        >
                                            <ErrorMessage errors={errors} name="poNumber" />
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid container item>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        inputProps={{
                                            'data-testid': 'delivery-instructions',
                                            maxLength: 30
                                        }}
                                        label={
                                            <Trans i18nKey="deliveryOrderInstructions">
                                                Delivery Order Instructions
                                            </Trans>
                                        }
                                        name="deliveryInstructions"
                                        data-testid="delivery-instructions"
                                        inputRef={register({ maxLength: 30 })}
                                        error={!!errors?.deliveryInstructions}
                                    />
                                    {errors?.deliveryInstructions && (
                                        <FormHelperText
                                            className={clsx({
                                                [classes.errorText]: !!errors?.deliveryInstructions
                                            })}
                                        >
                                            <ErrorMessage
                                                errors={errors}
                                                name="deliveryInstructions"
                                            />
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                spacing={2}
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid container item>
                                    <Typography
                                        className={classes.dateOptionTag}
                                        data-testid="today-text"
                                    >
                                        Today: {today} First Available: {firstAvailableDate}
                                    </Typography>
                                </Grid>
                                <Grid container item>
                                    <FormControl
                                        error={!!errors.deliveryDate}
                                        fullWidth
                                        data-testid="delivery-date"
                                    >
                                        <Controller
                                            control={control}
                                            name={'deliveryDate'}
                                            rules={{ required: isRequired }}
                                            as={
                                                <DatePicker
                                                    keepOpen={false}
                                                    numberOfMonths={1}
                                                    isOutsideRange={isOutsideRange}
                                                    onDateChange={handleDateChange}
                                                    placeholder={t('deliveryDate', 'Delivery Date')}
                                                    name="deliveryDate"
                                                    isEditDraft={false}
                                                    date={getValues('deliveryDate')}
                                                    error={!!errors.deliveryDate}
                                                />
                                            }
                                        />
                                        {errors?.deliveryDate && (
                                            <FormHelperText
                                                className={clsx({
                                                    [classes.errorText]: !!errors?.deliveryDate
                                                })}
                                            >
                                                <ErrorMessage errors={errors} name="deliveryDate" />
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormContext>
            </Paper>
            <SaveDraftModal
                data-testid="save-draft-modal"
                onClose={onClose}
                open={openSaveDraftModal}
                onCancel={onCloseBlockedNavigationModal}
                setClose={setOpenSaveDraftModal}
                setAllowNavigation={setAllowNavigation}
                isShipment={true}
                handleShipmentSubmit={(draftName) => handleSubmitForm(true, draftName)}
            />
        </ProcessingPageTemplate>
    );
}
