import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { boldWeight, containerMaxWidth, medium } from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { Persona, hasPersonas } from '../../../utility/auth/useSecurity';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../store/reducers/auth';
import { CustomerContextState, PaymentTerms } from '../../../store/reducers/customer-context';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import ProductsNav from './ProductsNav';
import SiteAdminNav from './SiteAdminNav';
import OrderingNav from './OrderingNav';
import UserAdminNav from './UserAdminNav';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import NavItem from './NavItem';
import {
    selectHasOrderingMakePermissions,
    selectHasPricingBillTo,
    selectIsOnlyGraphicVendor
} from '../../../store/selectors';
import InventoryNav from './InventoryNav';
import OrderingNavV2 from './OrderingNavV2';
import NavLink from './NavLink';
import PayablesNav from './PayablesNav';

const useStyles = makeStyles((theme) => ({
    appBar: {
        marginTop: '2em'
    },
    homeButton: {
        marginRight: theme.spacing(1)
    },
    container: {
        maxWidth: containerMaxWidth,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        flexGrow: 1,
        display: 'block'
    },
    contentTitle: {
        fontSize: 13,
        fontWeight: boldWeight,
        letterSpacing: 1,
        color: theme.palette.secondary.main
    },
    link: {
        fontSize: 11,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        letterSpacing: '1px',
        textTransform: 'uppercase'
    },
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    },
    menuItems: {
        overflow: 'visible',
        width: 'fit-content'
    },
    linkMenuItem: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    homeBtn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    dashboardLink: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    }
}));

const CustomerNavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useTranslation();

    const isPricingCustomer = useTypedSelector<boolean>(selectHasPricingBillTo);
    const hasOnlyVendorPermissions = useTypedSelector<boolean>(selectIsOnlyGraphicVendor);

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector((state) => state.auth);
    const { accounts, shipToAccounts, useOrderingV2 } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [ciaAccount, setCiaAccount] = useState<boolean>(false);
    const [isVendor, setIsVendor] = useState(false);
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
    const [showJoiningBall, setShowJoiningBall] = useState<boolean>(false);
    const [hasCommMgtPersona, setHasCommMgtPersona] = useState<boolean>(false);
    //Hiding certain functionality for Capitol Release
    const hideKBMaxFunctionality = false;
    const hasOrderingMakePermissions = useTypedSelector<boolean>(selectHasOrderingMakePermissions);

    useEffect(() => {
        if (permissions) {
            const hasVendorPersona = hasPersonas([Persona.GraphicsVendor], auth);
            setIsVendor(hasVendorPersona);

            const hasUserAdminPersona = hasPersonas([Persona.AccountAdmin], auth);
            setIsUserAdmin(hasUserAdminPersona);

            const isCommMgtPersona = hasPersonas([Persona.CommunicationManagement], auth);
            setHasCommMgtPersona(isCommMgtPersona);

            const hasJoiningBallPerson = hasPersonas(
                [
                    Persona.JoiningAdmin,
                    Persona.JoiningBillingAndShippingAddresses,
                    Persona.JoiningCreditApplication,
                    Persona.JoiningSalesAndUseTax,
                    Persona.JoiningTermsAndConditions
                ],
                auth
            );
            setShowJoiningBall(hasJoiningBallPerson);

            if (!accounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
    }, [dispatch, auth, permissions, accounts]);

    useEffect(() => {
        if (shipToAccounts) {
            for (let shipTo of shipToAccounts) {
                if (shipTo.paymentTerms === PaymentTerms.CIA) {
                    setCiaAccount(true);
                    return;
                }
            }
        }

        setCiaAccount(false);
    }, [shipToAccounts]);

    return (
        <Grid container item>
            {(!isVendor || !hasOnlyVendorPermissions) && (
                <>
                    {/* DASHBOARD */}
                    <NavLink menuName="Dashboard" path="/dashboard" label="dashboard" />
                    {/* ORDERING */}
                    {!useOrderingV2 && regionCode === Region.NA && <OrderingNav />}
                    {useOrderingV2 && regionCode === Region.NA && <OrderingNavV2 />}
                    {/* INVENTORY */}
                    {useOrderingV2 && <InventoryNav />}
                    {/* PRODUCTS */}
                    <ProductsNav hideKBMaxFunctionality={hideKBMaxFunctionality} />
                    {/* PAYABLES */}
                    {ciaAccount && regionCode === Region.NA && <PayablesNav />}
                    {/* USER ADMIN */}
                    {(isPricingCustomer || hasOrderingMakePermissions || !isVendor) &&
                        regionCode === Region.NA && (
                            <UserAdminNav isUserAdmin={isUserAdmin} isVendor={isVendor} />
                        )}
                    {showJoiningBall && regionCode === Region.NA && (
                        <NavItem
                            menuName={<Trans i18nKey="joiningBall">Joining Ball</Trans>}
                            link="/onboard"
                        />
                    )}
                    {/* SITE ADMIN */}
                    {hasCommMgtPersona && <SiteAdminNav />}
                </>
            )}
        </Grid>
    );
};

export default CustomerNavBar;
