import React from 'react';
import NavLink from './NavLink';

const InventoryNav = () => {

    return (
        <>
            <NavLink menuName="Inventory" path="/inventory" label="inventory"/>
        </>
    );
};

export default InventoryNav;
