import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';

import {
    ballDrkBlue,
    ballGray,
    ballGrayBlue,
    blackWeight,
    black_5,
    boldWeight,
    large,
    white,
    xxxl
} from '../../../../../themes/globalConstants';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import DashboardWidgetMetric from './DashboardWidgetMetric';
import { enUS } from '../../../../../utility/translations/locales';
import { AuthState } from '../../../../../store/reducers/auth';
import { useTypedSelector } from '../../../../../store/reducers/reducer';

import {
    hasPendingPaymentPersonas,
    hasProductPlanningPersonas
} from '../../../../../utility/helpers/dashboard-helpers';
import { selectIsCIACustomer } from '../../../../../store/selectors';
import {
    CustomerOrderingDashboardState,
    orderManagement
} from '../../../../../store/reducers/customer-ordering-dashboard';
import { DragDropProps } from '../utilities/dashboard-positions.config';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em',
        position: 'relative'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    moreIcon: {
        width: '1.25em',
        height: '1.25em',
        marginLeft: '.5em'
    },
    widgetSectionHeader: {
        fontWeight: blackWeight,
        color: ballGray,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: '1.75em'
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight
    },
    agedBalanceDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    widgetNumber: {
        fontSize: xxxl,
        fontWeight: 500,
        lineHeight: 1,
        color: ballDrkBlue,
        marginBottom: '.75rem',
        textDecoration: 'none',
        position: 'relative',
        borderBottom: `1px solid ${white}`,

        '&:hover': {
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    agedBalanceSubHeaderText: {
        color: ballGrayBlue,
        textTransform: 'uppercase'
    },
    numPalletsSubHeaderText: {
        color: black_5,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        marginBottom: '4px'
    },
    clockIcon: {
        height: '1.25em'
    },
    iconText: {
        color: black_5,
        fontWeight: blackWeight,
        marginLeft: '0.5em'
    },
    agedBalanceContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1em 0 2em'
    }
}));

const WeekEnum = {
    '1': 'newWeek',
    '4': 'twoToFourWeeks',
    '8': 'threeToEightWeeks',
    '16': 'eightPlusWeeks'
};

export default function OrderManagementDashboardWidget({ dragHandleProps }: DragDropProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { status, dashboardData, orderManagementStatus } =
        useTypedSelector<CustomerOrderingDashboardState>(
            (state) => state.customerOrderingDashboard
        );
    const [pendingPaymentAccess, setPendingPaymentAccess] = useState<boolean>(false);
    const [productPlanningAccess, setProductPlanningAccess] = useState<boolean>(false);
    const [openOrders, setOpenOrders] = useState<number>(0);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [orderManagementDashboard, setorderManagementDashboard] = useState<orderManagement>();
    const [futureOrders, setFutureOrders] = useState({});
    const history = useHistory();
    const isCiaCustomer = useTypedSelector(selectIsCIACustomer);

    /**need to change reddux state to ordermangement */
    useEffect(() => {
        if (status === 'success' && dashboardData?.orderManagementDashboard) {
            setorderManagementDashboard(dashboardData.orderManagementDashboard);
        }
    }, [status, dashboardData]);

    // Once data has been loaded, handle filtering and formatting for widget
    useEffect(() => {
        if (
            orderManagementDashboard?.productionBalanceItems &&
            orderManagementDashboard?.productionBalanceItems.length > 0
        ) {
            //const currentWeek = orderManagementDashboard?.productionBalance.find((item) => item.week === 0);
            setOpenOrders(dashboardData?.orderManagementDashboard?.openOrders || 0);

            const futureInformation = orderManagementDashboard?.productionBalanceItems
                .filter((item) => item.week > 0)
                .reduce((acc, item) => {
                    return {
                        ...acc,
                        [WeekEnum[item.week]]: {
                            ...item,
                            title: t(WeekEnum[item.week], enUS[item.week])
                        }
                    };
                }, {});
            setFutureOrders(futureInformation);
        }

        setPendingPaymentAccess(hasPendingPaymentPersonas(auth));
        setProductPlanningAccess(hasProductPlanningPersonas(auth));
    }, [orderManagementDashboard, auth, t]);

    return (
        <Paper
            elevation={2}
            className={classes.paper}
            data-testid="order-management-dashboard-widget"
        >
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="order-management-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    {t('orderManagement', enUS.orderManagement)}
                </Typography>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="create-order"
                    onClick={() => {
                        history.push('/create-order');
                    }}
                    style={{ marginLeft: '65%' }}
                >
                    <Trans i18nKey="createOrder"> Create Order</Trans>
                </Button>
            </div>
            <div></div>
            {orderManagementStatus === 'loading' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={6}>
                    <Grid item xs={isCiaCustomer ? 12 : 7}>
                        <Grid container direction="row">
                            <Grid item md={isCiaCustomer ? 6 : 12}>
                                <DashboardWidgetMetricSection
                                    title={t('salesOrder', enUS.salesOrder)}
                                    left={
                                        <DashboardWidgetMetric
                                            quantity={
                                                dashboardData?.orderManagementDashboard
                                                    ?.openOrders ?? 0
                                            }
                                            link={`/orders-summary&tab=summaryByOrder`}
                                            subheader={t('openOrders', enUS.openOrders)}
                                            subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Open_Orders_Icon.svg`}
                                        />
                                    }
                                    center={
                                        <div>
                                            <Grid item data-testid="draft-orders-widget">
                                                <DashboardWidgetMetric
                                                    quantity={
                                                        dashboardData?.orderManagementDashboard
                                                            ?.pendingOrders ?? 0
                                                    }
                                                    link={
                                                        '/orders-summary&tab=summaryByOrder&status=pending'
                                                    }
                                                    type="yellow"
                                                    subheader={t('pending', enUS.pending)}
                                                    subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Pending_Payments_Icon.svg`}
                                                />
                                            </Grid>
                                        </div>
                                    }
                                    right={
                                        <div>
                                            <Grid item data-testid="draft-orders-widget">
                                                <DashboardWidgetMetric
                                                    quantity={
                                                        dashboardData?.orderManagementDashboard
                                                            ?.draftOrders ?? 0
                                                    }
                                                    link={
                                                        '/orders-summary&tab=summaryByOrder&status=draft'
                                                    }
                                                    subheader={t('draftOrders', enUS.draftOrders)}
                                                    subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Draft_Orders_Icon.svg`}
                                                />
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Grid>
                            {isCiaCustomer && (
                                <Grid item md={6} data-testid="pending-payments-widget">
                                    <DashboardWidgetMetricSection
                                        title={t('pendingPayments', enUS.pendingPayments)}
                                        left={
                                            <DashboardWidgetMetric
                                                quantity={
                                                    dashboardData?.orderManagementDashboard
                                                        ?.pastDuePayments || 0
                                                }
                                                subheader={t(
                                                    'paymentsPastDue',
                                                    enUS.paymentsPastDue
                                                )}
                                                subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Red_Exclamation_Icon.svg`}
                                                type="red"
                                                link="/pending-payments?type=pending"
                                                enableLink={pendingPaymentAccess}
                                            />
                                        }
                                        center={
                                            <DashboardWidgetMetric
                                                quantity={
                                                    dashboardData?.orderManagementDashboard
                                                        ?.approachingPayments || 0
                                                }
                                                subheader={t(
                                                    'approachingPayments',
                                                    enUS.approachingPayments
                                                )}
                                                subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Pending_Payments_Icon.svg`}
                                                type="yellow"
                                                link="/pending-payments?type=approaching"
                                                enableLink={pendingPaymentAccess}
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={isCiaCustomer ? 12 : 5}>
                        <Typography variant="h5" className={classes.widgetSectionHeader}>
                            <Trans i18nKey="productionBalance">{enUS.productionBalance}</Trans>
                        </Typography>
                        {futureOrders && Object.keys(futureOrders).length === 4 && (
                            <Grid
                                container
                                direction="row"
                                spacing={isCiaCustomer ? 10 : undefined}
                            >
                                <Grid item md={isCiaCustomer ? 6 : 12} container direction="column">
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['1']].title}
                                        skuCount={futureOrders[WeekEnum['1']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['1']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/create-order-summary?range=${TotalNDayRanges.nineWeeks}`}
                                    />
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['4']].title}
                                        skuCount={futureOrders[WeekEnum['4']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['4']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/create-order-summary?range=${TotalNDayRanges.nineWeeks}`}
                                    />
                                </Grid>
                                <Grid item md={isCiaCustomer ? 6 : 12} container direction="column">
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['8']].title}
                                        skuCount={futureOrders[WeekEnum['8']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['8']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/create-order-summary?range=${TotalNDayRanges.nineWeeks}`}
                                    />
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['16']].title}
                                        skuCount={futureOrders[WeekEnum['16']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['16']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/create-order-summary?range=${TotalNDayRanges.seventeenWeeks}`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid> */}
                </Grid>
            )}
        </Paper>
    );
}
