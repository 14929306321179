import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import {
    formattedDateWithTimezone,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { Trans } from 'react-i18next';
import EachesConversion from '../../../reusable/atoms/EachesConversion';
import { OrderSummary } from '../../../../store/reducers/order-summaries';

interface Props {
    title: string;
    isviewOrderDetailsTab: boolean;
    isviewSummaryOtherTabs: boolean;
    orderingSummaries?: OrderSummary[];
}

const ReviewOrdersPdf = ({
    title,
    orderingSummaries,
    isviewOrderDetailsTab,
    isviewSummaryOtherTabs
}: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const shipments = orderingSummaries?.map((shipment, index) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable, pdfStyles.paragraph]}>
                    <View style={pdfStyles.tableRow} key={index}>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="soldTo">SOLD TO</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}></View>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="shipTo">Ship To</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}></View>
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="orderNumber">ORDER NUMBER</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="shipmentsNumber">SHIPMENT NUMBER</Trans>
                            </Text>
                        </View>
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={pdfStyles.header10}>
                                    <Trans i18nKey="customersPoNumber">CUSTOMER PO NUMBER</Trans>
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={pdfStyles.header10}>
                                    <Trans i18nKey="displayId">
                                        CUSTOMER ID PRODUCT ID GRAPHIC ID
                                    </Trans>
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="productNameDescription">
                                        PRODUCT NAME DESCRIPTION
                                    </Trans>
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="size">SIZE</Trans>
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="shipFrom">SHIP FROM</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="qty">QTY</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow6]}>
                            <Text style={[pdfStyles.subtitle10]}># {shipment.soldTo}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}></View>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={[pdfStyles.subtitle10]}># {shipment.shipTo}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}></View>
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={[pdfStyles.subtitle10]}>{shipment.orderNumber}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={[pdfStyles.subtitle10]}>{shipment.shipmentNumber}</Text>
                        </View>
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.subtitle10]}>
                                    {shipment.customerPoNumber}
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View
                                style={[pdfStyles.borderLessTableColNarrow35, pdfStyles.marginTop3]}
                            >
                                <Text style={pdfStyles.tableCell2}>{shipment.productSKU}</Text>
                                <Text style={pdfStyles.tableCell2}>
                                    {shipment.productGraphicId}
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={pdfStyles.subtitle10}>{shipment.productName}</Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.subtitle10]}>{shipment.size}</Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={[pdfStyles.subtitle10]}># {shipment.shipFrom}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={pdfStyles.tableCell2}>
                                {shipment.palletQuantity + ' PL '}
                            </Text>
                            <Text style={pdfStyles.tableCell2}>
                                <EachesConversion
                                    palletQuantity={shipment.palletQuantity || 0}
                                    quantityPerPallet={shipment.quantityPerPallet || 0}
                                    label={true}
                                />
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.tableRow, pdfStyles.marginTop3]} key={index}>
                        {!isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="shipments">Shipments</Trans>
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="poLine">PO LINE</Trans>
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="releaseNumber">RELEASE NUMBER</Trans>
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="submitted">SUBMITTED</Trans>
                            </Text>
                        </View>
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="requestedDateTime">REQUESTED DATE/TIME</Trans>
                                </Text>
                            </View>
                        )}
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="scheduledDateTime">SCHEDULED DATE/TIME</Trans>
                                </Text>
                            </View>
                        )}
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow6}>
                                <Text style={[pdfStyles.header2]}>
                                    <Trans i18nKey="carrier">CARRIER</Trans>
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="status">STATUS</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.tableRow, pdfStyles.marginBottom3]}>
                        {!isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.subtitle10]}>
                                    {shipment.shipmentNumber}
                                </Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.subtitle10]}>{shipment.poLine}</Text>
                            </View>
                        )}
                        {isviewOrderDetailsTab && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={[pdfStyles.subtitle10]}>{shipment.releaseNumber}</Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={pdfStyles.subtitle10}>
                                {formattedDateWithTimezone(
                                    moment(shipment?.submitted),
                                    'MM/DD/YYYY'
                                )}
                            </Text>
                        </View>
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={pdfStyles.subtitle10}>
                                    {getFullFormattedDateTime('', shipment.requestedDateTime)}
                                </Text>
                            </View>
                        )}
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow35}>
                                <Text style={pdfStyles.subtitle10}>
                                    {getFullFormattedDateTime('', shipment.scheduledDateTime)}
                                </Text>
                            </View>
                        )}
                        {!isviewSummaryOtherTabs && (
                            <View style={pdfStyles.borderLessTableColNarrow6}>
                                <Text style={pdfStyles.subtitle10}>{shipment.carrier}</Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow35}>
                            <Text style={pdfStyles.subtitle10}>{shipment.status}</Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.divider]} fixed />
                </View>
            </>
        );
    });
    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'landscape'}>
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider} />
                {shipments}
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) =>
                            `PAGE ${pageNumber} OF ${totalPages}`
                        }
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};
export default ReviewOrdersPdf;
