import React from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ProductToShip } from '../../../../store/reducers/ordering-dashboard';
import EachesConversion from '../../../reusable/atoms/EachesConversion';
import { blackWeight, regularWeight } from '../../../../themes/globalConstants';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    item: ProductToShip;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    bold: {
        fontWeight: blackWeight
    }
}));

export default function ShipmentConfirmationRow({ item }: Props) {
    const classes = useStyles();

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${item.productSku}`}
            data-pallet-quantity={`${item.availablePallets}`}
            data-can-type={`${item.type}`}
        >
            <TableCell data-testid="sequence">
                <Typography className={classes.bold}>{item.sequence}</Typography>
            </TableCell>
            <TableCell>
                <Typography className={classes.bold}>
                    {item.customerProductId ? item.customerProductId : '-'}
                </Typography>
                <Typography className={classes.bold}>{item.displayId}</Typography>
                <Typography className={classes.bold}>
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="display-name" className={classes.bold}>
                    {item.displayName}
                </Typography>
                <Typography data-testid="description">{item.description}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="coating">{item.coating}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="unit">{item.unit}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="pallets">
                    {formatNumberWithLocale(cultureCode, Number(item.palletQuantity))}
                    {' PL'}
                </Typography>
            </TableCell>
            <TableCell data-testid="quantityPerPallet">
                <Typography data-testid="eaches">
                    {item.palletQuantity && item.quantityPerPallet && (
                        <EachesConversion
                            palletQuantity={item.palletQuantity}
                            quantityPerPallet={item.quantityPerPallet}
                        />
                    )}
                </Typography>
            </TableCell>
            <TableCell data-testid="po-line">
                <Typography>{item.purchaseOrderNumber}</Typography>
            </TableCell>
            <TableCell data-testid="release-number">
                <Typography>{item.releaseNumber}</Typography>
            </TableCell>
            <TableCell data-testid="filler-line">
                <Typography>{item.fillerLine}</Typography>
            </TableCell>
            <TableCell data-testid="reference">
                <Typography>{item.reference}</Typography>
            </TableCell>
            <TableCell data-testid="shipment-instructions">
                <Typography>{item.deliveryInstructions}</Typography>
            </TableCell>
        </TableRow>
    );
}
