import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { OrderingState } from '../../../../store/reducers/ordering-dashboard';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    titleText: {
        color: '#009BBC',
        fontWeight: 'bold',
        textAlign: 'left',
        marginLeft: '3px'
    }
}));

const ShipmentOrderDetail: React.FC<{ shipment: any }> = ({ shipment }) => {
    const classes = useStyles();

    const { orderInformation } = useTypedSelector<OrderingState>(
        (state) => state.orderingDashboard
    );

    return (
        <Typography className={classes.titleText}>
            <Trans i18nKey="customerPo">Customer PO</Trans>: {orderInformation?.poNumber}
        </Typography>
    );
};

export default ShipmentOrderDetail;
