import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default function ShipmentSummaryConfigColumns(culture: string, optionalFields: boolean) {
    return [
        createColumn('sequence', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'loadSeq')
        }),
        createColumn('displayId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
            sortable: true
        }),
        createColumn('graphicIdAndVersion', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'graphicID'),
            filterable: true,
            visible: false
        }),
        createColumn('graphicId', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('displayName', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'productNameDescription')
        }),
        createColumn('productSku', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('name', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('description', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('coating', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'coating')
        }),
        createColumn('size', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('unit', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'size')
        }),
        createColumn('palletQuantity', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'palletsToShip')
        }),
        createColumn('quantityPerPallet', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'unitQty')
        }),
        createColumn('purchaseOrderNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'POline')
        }),
        createColumn('releaseNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'releaseNumber')
        }),
        createColumn('fillerLine', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'fillerLineNumber')
        }),
        createColumn('reference', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'referenceNumber')
        }),
        createColumn('deliveryInstructions', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'shippingInstructions')
        })
    ];
}
