// TODO: When navigation bar is fully updated this file will be deprecated and can be removed in lieu of NavBarTile.tsx
import React from 'react';
import { Button, makeStyles, Link } from '@material-ui/core';
import { black_2, boldWeight, medium, containerMaxWidth } from '../../../themes/globalConstants';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

interface Props {
    menuName: string;
    path: string;
    label: string;
}

const useStyles = makeStyles((theme) => ({
    homeBtn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    popoverPaper: {
        width: '100%',
        maxHeight: 'unset',
        maxWidth: 'unset',
        top: '4.5em !important',
        left: '0px !important',
        borderRadius: 0,
        boxShadow: `0 1px 4px 0 ${black_2}`,
        padding: '0 1em 1em 1em'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    },
    container: {
        maxWidth: containerMaxWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '25.5em'
    },
    southAmericaContainer: {
        maxWidth: '95em',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '27.5em'
    },
    menuName: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        marginTop: '1.8em',
        marginBottom: '0.5em'
    },
    menuNameBar: {
        border: `2px solid ${theme.palette.info.light}`,
        marginBottom: '1.5em'
    },
    navWrapper: {
        '&:focus': {
            outline: 'none'
        }
    },
    selected: {
        borderBottom: '4px solid #009bbb'
    },
    active: {
        borderBottom: '2px solid #009bbb'
    }
}));

const NavLink = ({ menuName, label, path }: Props) => {
    const currentPath = useLocation().pathname;
    const selected = currentPath === path;
    const classes = useStyles();

    return (
        <>
            <div className={clsx(classes.buttonContainer, { [classes.selected]: selected })}>
                <Button className={classes.homeBtn} data-testid={`${label}-nav-btn`}>
                    <Link
                        component={RouterLink}
                        to={path}
                        underline="none"
                        aria-label={label}
                        className={classes.button}
                    >
                        <Trans i18nKey={label}>{menuName}</Trans>
                    </Link>
                </Button>
            </div>
        </>
    );
};

export default NavLink;
