import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { Trans, useTranslation } from 'react-i18next';
import PlanItTabs from './components/PlanItTabs';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import { loadMakeItBulkProducts } from '../../../store/actions/makeit-bulk-upload';
import { InactiveProducts, PlanItSummaryState } from '../../../store/reducers/plan-it-summary';
import { PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS } from '../../../store/actions/action-types';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { copyFromForecast } from '../../../store/actions/makeit-conversion-atm';
import { useHistory } from 'react-router';
import InactiveProductsModal from '../../reusable/molecules/InactiveProductsModal';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    }
}));

export default function PlanItSummary() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);
    const [inactiveProducts, setInactiveProducts] = useState<InactiveProducts[]>();
    const [shipToId, setShipToId] = useState<string | undefined>();

    const { selectedAccountId, useOrderingV2 } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const bulkUploadMakeItState = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { copyToMakeItOrders, orders } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    useEffect(() => {
        if (useOrderingV2) {
            history.push('/dashboard');
        }
    }, [useOrderingV2, history]);

    useEffect(() => {
        dispatch({ type: PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS });
    }, [dispatch]);

    useEffect(() => {
        if (!!selectedAccountId && !bulkUploadMakeItState?.loading) {
            setShipToId(selectedAccountId);
            dispatch(loadMakeItBulkProducts([selectedAccountId], true, true, false));
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (!selectedAccountId) return;
        // Reset copy to make it orders on shipTo change
        if (selectedAccountId !== shipToId) {
            dispatch({ type: PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS });
        }
    }, [selectedAccountId]);

    const onCancel = () => {
        dispatch({ type: PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS });
    };

    const onContinue = () => {
        setOpen(false);
        handleSubmit();
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (!isLargeCustomer) {
            history.push('/make-it?from=summary');
            // All of the orders are loaded into the copyToMakeItOrders array in the Plan It Summary State on routing
        } else {
            dispatch(
                copyFromForecast(
                    copyToMakeItOrders.map((o) => o.productionOrderId) as number[],
                    Number(selectedAccountId)
                )
            );
            history.push('/makeit-conversion-review?from=summary');
        }
    };

    const onSubmit = () => {
        const inactiveProductsInOrders: InactiveProducts[] = [];
        copyToMakeItOrders.forEach((orderToCopy) => {
            const matchingOrder = orders.find(
                (order) => order.productionOrderId === orderToCopy.productionOrderId
            );
            matchingOrder?.lines.forEach((line) => {
                if (
                    !!line.customerProductStatus &&
                    !!line.productSku &&
                    line.customerProductStatus === 'INACTIVE'
                ) {
                    const foundProduct = inactiveProductsInOrders.find(
                        (product) => product.ballProductId === line.productSku!
                    );
                    if (foundProduct) {
                        const index = inactiveProductsInOrders.indexOf(foundProduct);
                        inactiveProductsInOrders[index].forecastWeeks =
                            inactiveProductsInOrders[index].forecastWeeks +
                            ', ' +
                            moment.utc(matchingOrder.atmWeekStart).format('MM/DD/YYYY');
                    } else {
                        const lineToPush: InactiveProducts = {
                            productDescription: line.productName ?? '-',
                            ballProductId: line.productSku ?? '',
                            forecastWeeks: moment
                                .utc(matchingOrder.atmWeekStart)
                                .format('MM/DD/YYYY')
                        };
                        inactiveProductsInOrders.push(lineToPush);
                    }
                }
            });
        });
        if (!!inactiveProductsInOrders && inactiveProductsInOrders.length > 0) {
            setInactiveProducts(inactiveProductsInOrders);
            setOpen(true);
        } else {
            handleSubmit();
        }
    };

    const footerActions = copyToMakeItOrders.length > 0 && (
        <Grid container justify="space-between">
            <Grid container item xs={1}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Grid container item xs={5} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.actionBtn}
                        disabled={copyToMakeItOrders.length === 0}
                        onClick={onSubmit}
                    >
                        <Trans i18nKey="copyToMakeIt">Copy To Make It</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', 'Plan It'),
                description: t('summary', 'Summary').toUpperCase(),
                thinBanner: true,
                displayDropdown: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.PlanItSummary}
            loading={loading}
        >
            <Grid container data-testid="plan-it-summary">
                <PlanItTabs dashboardLoading={setLoading} />
            </Grid>
            {open && (
                <InactiveProductsModal
                    onClose={onClose}
                    onSubmit={onContinue}
                    productsWithWeeks={inactiveProducts!}
                />
            )}
        </ProcessingPageTemplate>
    );
}
