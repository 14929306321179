import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
    Grid,
    Typography,
    makeStyles,
    Link,
    Paper,
    CircularProgress,
    Button
} from '@material-ui/core';

import {
    boldWeight,
    ballGray,
    black_5,
    blackWeight,
    large,
    ballDrkBlue,
    xxxl,
    white
} from '../../../../../themes/globalConstants';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import { enUS } from '../../../../../utility/translations/locales';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { CustomerOrderingDashboardState } from '../../../../../store/reducers/customer-ordering-dashboard';
import { DragDropProps } from '../utilities/dashboard-positions.config';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        borderRadius: 4,
        padding: '2em 2em 3em 2em'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    iconText: {
        color: black_5,
        fontWeight: blackWeight,
        marginBottom: '1em',
        marginTop: '1em',
        marginLeft: '0.5em'
    },
    icon: {
        height: '1.25em'
    },
    widgetSectionHeader: {
        color: ballGray,
        fontWeight: blackWeight,
        letterSpacing: 1,
        marginBottom: '1.75em',
        textTransform: 'uppercase'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight,
        marginTop: '2em'
    },
    inventoryDetailsContainer: {
        height: 100,
        marginTop: -7
    },
    inventoryDetails: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    inventorySummaryDetails: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    widgetNumber: {
        fontSize: xxxl,
        fontWeight: 500,
        lineHeight: 1,
        color: ballDrkBlue,
        marginBottom: '.75rem',
        textDecoration: 'none',
        position: 'relative',
        borderBottom: `1px solid ${white}`,

        '&:hover': {
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    }
}));

export default function Inventory({ dragHandleProps }: DragDropProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const { dashboardData, inventoryStatus } = useTypedSelector<CustomerOrderingDashboardState>(
        (state) => state.customerOrderingDashboard
    );
    useEffect(() => {
        if (dashboardData?.shipmentDeliveriesDashboard) {
            // after inventory logic is done
        }
    }, [dashboardData]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="ship-it-dashboard-widget">
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="order-management-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    {t('inventory', enUS.inventory)}
                </Typography>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="create-order"
                    style={{ marginLeft: '58%', textTransform: 'uppercase' }}
                    onClick={() => history.push('/inventory')}
                >
                    {t('viewInventory', enUS.viewInventory)}
                </Button>
            </div>
            {inventoryStatus === 'loading' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={6}>
                    <Grid container item xs={12} direction="row">
                        <Grid
                            item
                            md={12}
                            data-testid="inventory-widget"
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <Grid container item xs={4}>
                                <DashboardWidgetMetricSection
                                    divider={false}
                                    title={t('saleablePallets', enUS.saleablePallets)}
                                    left={
                                        <div>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                className={classes.inventoryDetailsContainer}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.inventorySummaryDetails}
                                                    style={{ display: 'flex' }}
                                                >
                                                    <div>
                                                        <Link
                                                            to={`/inventory?type=saleable`}
                                                            underline="none"
                                                            component={RouterLink}
                                                            data-testid="product-planning-link"
                                                            className={classes.link}
                                                        >
                                                            <Typography
                                                                className={classes.widgetNumber}
                                                            >
                                                                {
                                                                    dashboardData
                                                                        ?.productPortfolioDashboard
                                                                        ?.activeItems
                                                                }
                                                            </Typography>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    center={
                                        <Grid item style={{ marginTop: '10%' }}>
                                            <Typography className={classes.iconText}>PL</Typography>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid container item xs={4}>
                                <DashboardWidgetMetricSection
                                    divider={false}
                                    title={t('committedPallets', enUS.committedPallets)}
                                    left={
                                        <div>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                className={classes.inventoryDetailsContainer}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.inventorySummaryDetails}
                                                >
                                                    <div>
                                                        <Link
                                                            to={`/inventory?type=committed`}
                                                            underline="none"
                                                            component={RouterLink}
                                                            data-testid="product-planning-link"
                                                            className={classes.link}
                                                        >
                                                            <Typography
                                                                className={classes.widgetNumber}
                                                            >
                                                                {
                                                                    dashboardData
                                                                        ?.productPortfolioDashboard
                                                                        ?.activeItems
                                                                }
                                                            </Typography>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    center={
                                        <Grid item style={{ marginTop: '10%' }}>
                                            <Typography className={classes.iconText}>PL</Typography>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid container item xs={4}>
                                <DashboardWidgetMetricSection
                                    divider={false}
                                    title={t('agedInventory', enUS.agedInventory)}
                                    left={
                                        <div>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                className={classes.inventoryDetailsContainer}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={classes.inventorySummaryDetails}
                                                >
                                                    <div>
                                                        <Link
                                                            to={`/inventory?type=aged`}
                                                            underline="none"
                                                            component={RouterLink}
                                                            data-testid="product-planning-link"
                                                            className={classes.link}
                                                        >
                                                            <Typography
                                                                className={classes.widgetNumber}
                                                            >
                                                                {
                                                                    dashboardData
                                                                        ?.productPortfolioDashboard
                                                                        ?.activeItems
                                                                }
                                                            </Typography>
                                                        </Link>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    center={
                                        <Grid item style={{ marginTop: '10%' }}>
                                            <Typography className={classes.iconText}>PL</Typography>
                                        </Grid>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
