import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { blackWeight, ballGray, small } from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { ProductToShip } from '../../../../store/reducers/ordering-dashboard';
import ShipmentConfirmationRow from './ShipmentConfirmationRow';
import ShipmentSummaryConfigColumns from './ShipmentSummaryConfigColumns';

interface Props {
    loads: ProductToShip[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset',
            marginTop: '10px'
        },
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            marginTop: '-5em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '5%'
                },
                '&:nth-child(2)': {
                    width: '12.5%'
                },
                '&:nth-child(3)': {
                    width: '20%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '6.5%'
                },
                '&:nth-child(7)': {
                    width: '8%'
                },
                '&:nth-child(8)': {
                    width: '10%'
                },
                '&:nth-child(9)': {
                    width: '20%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

export default function ShipmentDetailSummaryGrid({ loads }: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const LoadRow = ({ row }: { row: Partial<ProductToShip> }) => {
        let itemIndex = loads.findIndex((item) => item.productSku === row.productSku);
        return (
            <ShipmentConfirmationRow
                item={row as ProductToShip}
                key={generateKey('ordersummary')}
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper}>
            <DataGrid
                columns={ShipmentSummaryConfigColumns(cultureCode, true)}
                dataSource={loads}
                gridName="Order Summary Grid"
                rowComponent={LoadRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
