import React from 'react';
import { makeStyles, Link, TableRow, TableCell, Grid, Typography } from '@material-ui/core';
import { DeliveryOrderRow } from '../../../../store/reducers/shipping-summary';
import { boldWeight, medium } from '../../../../themes/globalConstants';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { orderStatusCodeFormatter, formatStatus } from '../../../../utility/helpers/order-helpers';
import moment from 'moment';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { selectDateFormat } from '../../../../store/selectors';

interface Props {
    order: DeliveryOrderRow;
    key?: any;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    }
}));

const ShipItClosedDeliveriesRow = ({ order }: Props) => {
    const classes = useStyles();
    const dateFormat = useTypedSelector<string>(selectDateFormat);

    const formatDeliveryOrderNumber = (deliveryOrderNumber?: string, deliveryOrderId?: number) => {
        if (deliveryOrderNumber) {
            return (
                <Link
                    to={`/delivery-order/${deliveryOrderId}`}
                    component={RouterLink}
                    underline="none"
                >
                    {deliveryOrderNumber}
                </Link>
            );
        } else {
            return (
                <em>
                    <Trans i18nKey="pending">pending</Trans>
                </em>
            );
        }
    };

    const formattedDeliveryWeek = order.shipmentDeliveryDateTime
        ? moment(order.shipmentDeliveryDateTime).format(dateFormat)
        : 'N/A';

    return (
        <TableRow hover={true} key={order.deliveryOrderId}>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name} data-testid="ship-to-name">
                        <Typography>{order.shipToName}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.name} data-testid="ship-to-address">
                        <Typography>{order.shipToAddress}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container data-testid="delivery-order-number">
                    <Typography>
                        {formatDeliveryOrderNumber(
                            order.deliveryOrderNumber,
                            order.deliveryOrderId
                        )}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name} data-testid="order-status">
                        <Typography key={'product-status-' + order.deliveryOrderId}>
                            <Trans
                                i18nKey={orderStatusCodeFormatter(
                                    formatStatus(order.status, order.reviewStatus)
                                )}
                            >
                                {formatStatus(order.status, order.reviewStatus)}
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name} data-testid="delivery-date">
                        <Typography>{formattedDeliveryWeek}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name} data-testid="order-quantity">
                        <Typography>{order.orderQuantityContent}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default ShipItClosedDeliveriesRow;
