import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import {
    xl,
    lato,
    blackWeight,
    inactiveTabBlue,
    black,
    medium,
    white
} from '../../../../themes/globalConstants';

import ReviewOrdersConfirmationLinks from './ReviewOrdersConfirmationLinks';
import ReviewOrderSummaryDetailsGrid from './ReviewOrderSummaryDetailsGrid';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { OrderingSummaryState } from '../../../../store/reducers/order-summaries';
import { useTypedSelector } from '../../../../store/reducers/reducer';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        marginBottom: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    appBar: {
        width: '75%'
    },
    tabPanel: {
        width: '100%'
    },
    panelHeader: {
        marginBottom: '1em'
    },
    tabs: {
        height: '3em'
    },
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        maxWidth: 'fit-content',
        '&:not(:first-of-type)': {
            marginLeft: '0.25em'
        },
        paddingLeft: '2em',
        paddingRight: '2em',
        color: black,
        textTransform: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white
    },
    formLinkWrapper: {
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            position: 'inherit'
        }
    }
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`revieworders-tabpanel-${index}`}
            aria-labelledby={`revieworders-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

//This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `revieworders-tab-${index}`,
        'aria-controls': `revieworders-tabpanel-${index}`
    };
};
export default function ReviewOrderSummaryTabs() {
    const classes = useStyles();
    const { t } = useTranslation();

    const [value, setValue] = useState<number>(0);
    const [tabName, setTabName] = useState<string>('');
    const [orderSummaries, setOrderingSummaries] = useState<any>([]);
    const [shipToAddress, setShipToAddress] = useState<string>('');
    const [soldToAddress, setSoldToAddress] = useState<string>('');
    const [isviewOrderDetailsTab, setisviewOrderDetailsTab] = useState<boolean>(false);
    const [isviewSummaryOtherTabs, setisviewSummaryOtherTabs] = useState<boolean>(false);

    const { orderSummariesData, status } = useTypedSelector<OrderingSummaryState>(
        (state) => state.orderingSummaries
    );
    const { shipToAccounts, accounts, selectedAccountId, selectedChildrenAccountId } =
        useTypedSelector<CustomerContextState>((state) => state.customerContext);

    useEffect(() => {
        const shipToaccountInformation =
            shipToAccounts &&
            (selectedChildrenAccountId
                ? shipToAccounts.filter(
                      (account) => account.accountId === selectedChildrenAccountId
                  )
                : shipToAccounts?.filter((account) => account.accountId === selectedAccountId));
        if (shipToaccountInformation) {
            const Address =
                shipToaccountInformation[0]?.address.address1 +
                '' +
                shipToaccountInformation[0]?.address.address2 +
                '' +
                shipToaccountInformation[0]?.address.address3 +
                '' +
                shipToaccountInformation[0]?.address.address4 +
                '' +
                shipToaccountInformation[0]?.address.postalCode +
                '' +
                shipToaccountInformation[0]?.address.city +
                '';
            setShipToAddress(`${shipToaccountInformation[0]?.accountId} ${Address}`);
        }
        const soldToAccountInformation =
            accounts && accounts.filter((account) => account.accountId === selectedAccountId);
        if (soldToAccountInformation) {
            const Address =
                soldToAccountInformation[0]?.address.address1 +
                '' +
                soldToAccountInformation[0]?.address.address2 +
                '' +
                soldToAccountInformation[0]?.address.address3 +
                '' +
                soldToAccountInformation[0]?.address.address4 +
                '' +
                soldToAccountInformation[0]?.address.postalCode +
                '' +
                soldToAccountInformation[0]?.address.city +
                '';
            setSoldToAddress(`${soldToAccountInformation[0]?.accountId} ${Address}`);
        }
    }, [shipToAccounts, accounts, selectedAccountId, selectedChildrenAccountId]);

    useEffect(() => {
        switch (value) {
            case 0:
                setTabName('viewOrderDetailsTab');
                setisviewOrderDetailsTab(true);
                setisviewSummaryOtherTabs(false);
                if (status === 'success' && orderSummariesData?.orderDetails) {
                    orderSummariesData?.orderDetails.forEach((order) => {
                        order.soldTo = soldToAddress;
                        order.shipTo = shipToAddress;
                    });
                    setOrderingSummaries(orderSummariesData?.orderDetails);
                }
                break;
            case 1:
                setisviewOrderDetailsTab(false);
                setisviewSummaryOtherTabs(false);
                setTabName('viewSummaryByShipmentTab');
                if (status === 'success' && orderSummariesData?.summaryByShipment) {
                    orderSummariesData?.summaryByShipment.forEach((order) => {
                        order.soldTo = soldToAddress;
                        order.shipTo = shipToAddress;
                        order.shipments = order.poLine + ' of ' + order.shipments;
                    });
                    setOrderingSummaries(orderSummariesData?.summaryByShipment);
                }
                break;
            case 2:
                setisviewOrderDetailsTab(false);
                setisviewSummaryOtherTabs(true);
                setTabName('viewSummaryByOrderTab');
                if (status === 'success' && orderSummariesData?.summaryByOrder) {
                    orderSummariesData?.summaryByOrder.forEach((order) => {
                        order.soldTo = soldToAddress;
                        order.shipTo = shipToAddress;
                        order.shipments = order.poLine;
                    });
                    setOrderingSummaries(orderSummariesData?.summaryByOrder);
                }
                break;
            default:
                break;
        }
    }, [value, status, setTabName, shipToAddress, soldToAddress, orderSummariesData]);

    const TabLabel = (label: string) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
            </Grid>
        );
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const confirmationLinks = (
        <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <ReviewOrdersConfirmationLinks
                isviewOrderDetailsTab={isviewOrderDetailsTab}
                isviewSummaryOtherTabs={isviewSummaryOtherTabs}
                order={orderSummaries}
                tabName={tabName}
            />
        </Grid>
    );

    return (
        <div className={classes.root} data-testid="revieworder-tabs">
            {confirmationLinks}
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="review order tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(t('viewOrderDetailsTab', 'View Order Details'))}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                    data-testid={'view-order-details-tab'}
                />
                <Tab
                    label={TabLabel(t('viewSummaryByShipmentTab', 'View Summary By Shipment'))}
                    {...a11yProps(1)}
                    className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                    data-testid={'view-summary-by-shipment-tab'}
                />
                <Tab
                    label={TabLabel(t('viewSummaryByOrderTab', 'View Summary By Order'))}
                    {...a11yProps(2)}
                    className={`${classes.tabLabel} ${value === 2 && classes.selectedTab}`}
                    data-testid={'view-summary-by-order-tab'}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ReviewOrderSummaryDetailsGrid
                    isviewOrderDetailsTab={isviewOrderDetailsTab}
                    isviewSummaryOtherTabs={isviewSummaryOtherTabs}
                    order={orderSummaries}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ReviewOrderSummaryDetailsGrid
                    isviewOrderDetailsTab={isviewOrderDetailsTab}
                    isviewSummaryOtherTabs={isviewSummaryOtherTabs}
                    order={orderSummaries}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ReviewOrderSummaryDetailsGrid
                    isviewOrderDetailsTab={isviewOrderDetailsTab}
                    isviewSummaryOtherTabs={isviewSummaryOtherTabs}
                    order={orderSummaries}
                />
            </TabPanel>
        </div>
    );
}
