import { useEffect } from 'react';
import { DraggableProvidedDragHandleProps, DropResult } from 'react-beautiful-dnd';
import { DragAndDropItem } from '../../../../reusable/molecules/DragAndDrop/models';
import InventoryWidget from '../components/Inventory';
import OrderManagementWidget from '../components/OrderManagement';
import PaymentsManagementWidget from '../components/PaymentsManagement';
import ProductPortfolioWidget from '../components/ProductPortfolio';
import ShipmentsAndDeliveriesWidget from '../components/ShipmentsAndDeliveries';

export interface DragDropProps {
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const customerDashboardDragAndDropItems: (
    showOrderManagementWidget: boolean,
    showShipmentsAndDeliveriesWidget: boolean,
    showInventoryWidget: boolean,
    showPaymentsManagementWidget: boolean,
    showProductPortfolioWidget: boolean
) => Array<DragAndDropItem> = (
    showOrderManagementWidget,
    showShipmentsAndDeliveriesWidget,
    showInventoryWidget,
    showPaymentsManagementWidget,
    showProductPortfolioWidget
) => {
    let list: Array<DragAndDropItem> = [];
    if (showOrderManagementWidget) {
        list.push({
            id: 'order-management',
            component: OrderManagementWidget
        });
    }
    if (showShipmentsAndDeliveriesWidget) {
        list.push({
            id: 'shipments-and-deliveries',
            component: ShipmentsAndDeliveriesWidget
        });
    }

    if (showInventoryWidget) {
        list.push({
            id: 'inventory',
            component: InventoryWidget
        });
    }

    if (showPaymentsManagementWidget) {
        list.push({
            id: 'payments-management',
            component: PaymentsManagementWidget
        });
    }

    if (showProductPortfolioWidget) {
        list.push({
            id: 'product-portfolio',
            component: ProductPortfolioWidget
        });
    }
    return list;
};

export interface DragAndDropPosition {
    name: string;
}

export function useDragAndDrop<Type extends DragAndDropItem>(
    items: Array<Type>,
    setItems: React.Dispatch<React.SetStateAction<Type[]>>,
    positions: Array<DragAndDropPosition>,
    onReorder: (arg: Array<Type>) => any
): [(result: DropResult) => void, (items: Array<Type>, hasCiaCustomerChanged?: boolean) => void] {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reordered = reorder(
            items,
            result.source.index,
            result.destination.index
        ) as Array<Type>;

        onReorder(reordered.map((tile) => tile.id));
    }

    const updateListItems = (dragAndDropItems: Array<Type>) => {
        const modified = dragAndDropItems.length !== items.length;
        const newItems: Array<Type> = [];

        let sorted = false;

        if (positions.length) {
            positions.forEach((position, endIndex) => {
                const startIndex = dragAndDropItems.findIndex((tile) => tile.id === position.name);
                if (startIndex !== -1) {
                    if (startIndex !== endIndex) {
                        sorted = true;
                    }

                    newItems.push(dragAndDropItems[startIndex]);
                }
            });
        } else {
            dragAndDropItems.forEach((item) => {
                newItems.push(item);
            });
        }

        if (sorted || modified) {
            setItems(newItems);
        }
    };

    useEffect(() => {
        if (positions && positions.length) {
            updateListItems(items);
        }
        //eslint-disable-next-line
    }, [positions]);

    return [onDragEnd, updateListItems];
}
