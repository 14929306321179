import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';
import NavBarMenu from './NavBarMenu';
import NavBarTile from './NavBarTile';

const PayablesNav = () => {
    const [menuTiles, setMenuTiles] = useState<JSX.Element[]>([]);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const paymentIcon = '/Payment_Icon.svg';

    const paths = ['/pending-payments'];

    useEffect(() => {
        const tiles: JSX.Element[] = [];

        tiles.push(
            <Grid item xs={6} key={'payments-summary'}>
                <NavBarTile
                    link="/pending-payments"
                    translationKey="paymentsSummary"
                    defaultText="Payments Summary"
                    icon={paymentIcon}
                    isProduct={false}
                />
            </Grid>
        );

        setMenuTiles(tiles);
    }, [auth]);

    return (
        <NavBarMenu
            menuName={<Trans i18nKey="payables">Payables</Trans>}
            sections={[]}
            paths={paths}
        >
            <Grid container>{menuTiles}</Grid>
        </NavBarMenu>
    );
};

export default PayablesNav;
