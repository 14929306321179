import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ballBlue, boldWeight, medium } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import {
    addTimezoneOffset,
    formattedDateWithTimezone,
    getSimpleFormattedDate,
    getSimpleFormattedTime
} from '../../../../utility/helpers/date-helpers';

import ReviewOrdersPdf from './ReviewOrdersPdf';
import { OrderSummary} from '../../../../store/reducers/order-summaries';
import moment from 'moment';

interface Props {
    tabName: string;
    isviewOrderDetailsTab:boolean,
    isviewSummaryOtherTabs:boolean;
    order?:OrderSummary[]
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: ballBlue,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    gridMain: {
        display: 'flex'
    },
    gridItem: {
        marginTop: '0.5em'
    },

    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: ballBlue,
        width: '8.75em',
        textDecoration: 'none'
    }
});

const ReviewOrdersConfirmationLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    const { t } = useTranslation();
    const printForm = () => {
        setOpenPdf(true);
    };
    const onClose = () => {
        setOpenPdf(false);
    };
    
    interface CSVRow {
        sold_To?: string;
        ship_To?: string;
        order_Number?: string;
        shipment_Number?: string
        customerPo_Number?: string;
        customer_Productsku?: string;
        product_Name?: string;
        size?: string;
        ship_From?: string;
        qty?: string;
        shipments?: string;
        po_Line?: string;
        release_Number?: string;
        submitted?: string;
        requested_Date?: string;
        scheduled_Date?: string;
        carrier?: string;
        status?: string

    }
    const buildCSVHeaders = (tabName: string) => {
        let csvHeaders: { label: string, key: string }[] = [];
        csvHeaders.push({ label: t('soldTo', 'Sold To').toUpperCase(), key: 'sold_To' })
        csvHeaders.push({ label: t('shipTo', 'Ship To').toUpperCase(), key: 'ship_To' })
        csvHeaders.push({ label: t('orderNumber', 'Order Number').toUpperCase(), key: 'order_Number' })
        csvHeaders.push({ label: t('shipmentsNumber', 'Shipment Number').toUpperCase(), key: 'shipment_Number' })
        csvHeaders.push({ label: t('customersPoNumber', 'Customer PONumber').toUpperCase(), key: 'customerPo_Number' })
        if (tabName.toLocaleLowerCase() === 'vieworderdetailstab') {
            csvHeaders.push({ label: t('displayId', 'Customer ID / Product ID / Graphics ID').toUpperCase(), key: 'customer_Productsku' })
            csvHeaders.push({ label: t('productNameDescription', 'Product Name / Description').toUpperCase(), key: 'product_Name' })
            csvHeaders.push({ label: t('size', 'Size').toUpperCase(), key: 'size' })
        }
        csvHeaders.push({ label: t('shipFrom', 'Ship From').toUpperCase(), key: 'ship_From' })
        csvHeaders.push({ label: t('qty', 'Qty').toUpperCase(), key: 'qty' })
        if (tabName.toLocaleLowerCase() !== 'vieworderdetailstab') {
            csvHeaders.push({ label: t('shipments', 'Shipments').toUpperCase(), key: 'shipments' })
        }
        if (tabName.toLocaleLowerCase() === 'vieworderdetailstab') {
            csvHeaders.push({ label: t('poLine', 'PO Line').toUpperCase(), key: 'po_Line' })
            csvHeaders.push({ label: t('releaseNumber', 'Release Number').toUpperCase(), key: 'release_Number' })
        }
        csvHeaders.push({ label: t('submitted', 'Submitted').toUpperCase(), key: 'submitted' })
        if (tabName.toLocaleLowerCase() !== 'viewsummarybyordertab') {
            csvHeaders.push({ label: t('requestedDateTime', 'Requested Date/Time').toUpperCase(), key: 'requested_Date' })
            csvHeaders.push({ label: t('scheduledDateTime', 'Scheduled Date/Time').toUpperCase(), key: 'scheduled_Date' })
            csvHeaders.push({ label: t('carrier', 'Carrier').toUpperCase(), key: 'carrier' })
        }
        csvHeaders.push({ label: t('status', 'Status').toUpperCase(), key: 'status' })
        return csvHeaders

    }
    const csvArray = () => {
        let rows: any[] = [];
        props.order?.map((shipment) => {
            const eachesAmount =
                shipment.quantityPerPallet && shipment.palletQuantity
                    ? shipment.quantityPerPallet * shipment.palletQuantity
                    : 0;
                    const GraphicId = shipment.productGraphicId?shipment.productGraphicId:""
                    const ProductSKUGraphicId= shipment.productSKU+" "+GraphicId
            const row: CSVRow = {
                sold_To: "#"+ shipment.soldTo,
                ship_To: "#"+ shipment.shipTo,
                order_Number: shipment.shipTo,
                shipment_Number: String(shipment.shipmentNumber),
                customerPo_Number: shipment.customerPoNumber,
                customer_Productsku: ProductSKUGraphicId,
                product_Name: shipment.productName,
                size: shipment.size,
                ship_From: "#"+ shipment.shipFrom,
                qty: shipment.palletQuantity + " PL " + eachesAmount + " ea. ",
                shipments: shipment.shipments ? shipment.shipments : '',
                po_Line: shipment.poLine ? String(shipment.poLine) : '',
                release_Number: shipment.releaseNumber ? String(shipment.releaseNumber) : '',
                submitted: formattedDateWithTimezone(
                    moment(shipment?.submitted),
                    'MM/DD/YYYY'
                ),
                requested_Date: getSimpleFormattedTime('',shipment.requestedDateTime),
                scheduled_Date: getSimpleFormattedTime('',shipment.scheduledDateTime),
                carrier: shipment.carrier,
                status: shipment.status,
            };
            rows.push(row);
        });
        if (props.tabName.toLowerCase() === 'vieworderdetailstab') {
            rows.map(({ shipments, ...rest }) => rest)
        }

        if (props.tabName.toLowerCase() === 'viewsummarybyshipmenttab') {
            rows.map(({ customer_Productsku, ...rest }) => rest);
            rows.map(({ product_Name, ...rest }) => rest);
            rows.map(({ size, ...rest }) => rest);
            rows.map(({ po_Line, ...rest }) => rest);
            rows.map(({ release_Number, ...rest }) => rest);
        }
        if (props.tabName.toLocaleLowerCase() === 'viewsummarybyordertab') {
            rows.map(({ customer_Productsku, ...rest }) => rest);
            rows.map(({ product_Name, ...rest }) => rest);
            rows.map(({ size, ...rest }) => rest);
            rows.map(({ po_Line, ...rest }) => rest);
            rows.map(({ shipment_Number, ...rest }) => rest);
            rows.map(({ release_Number, ...rest }) => rest);
            rows.map(({ requested_Date, ...rest }) => rest);
            rows.map(({ scheduled_Date, ...rest }) => rest);
            rows.map(({ carrier, ...rest }) => rest);
        }
        return rows;
    };
    const csvContents = csvArray();
    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item alignItems="center" justify='space-between' className={classes.gridMain}>
                <Grid className={classes.gridItem}>
                    <PrintOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
                <Grid>
                    <Link
                        className={classes.link}
                        data-testid="print-btn"
                        onClick={printForm}
                    >
                        <Trans i18nKey="print">Print</Trans>
                    </Link>
                </Grid>
            </Grid>
            <Grid item alignItems="center" className={classes.gridMain}>
                <Grid className={classes.gridItem}>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
                <Grid>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        data-testid="download-btn"
                        headers={buildCSVHeaders(props.tabName)}
                        data={csvContents}
                        filename={`${props.order&&props.order[0]?.deliveryOrderId}`}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                </Grid>
            </Grid>
            <Modal
                open={openPdf}
                title={t('orderSummaries', 'Ordering Summaries')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <ReviewOrdersPdf
                    title={t(`${props.tabName}`, `${props.tabName}`)}
                    orderingSummaries={props.order}
                    isviewOrderDetailsTab={props.isviewOrderDetailsTab}
                    isviewSummaryOtherTabs={props.isviewSummaryOtherTabs}
                />
            </Modal>
        </Grid>
    );
};

export default ReviewOrdersConfirmationLinks;
