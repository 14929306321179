import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    TablePagination,
    CircularProgress
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { OrderSummary, OrderingSummaryState } from '../../../../store/reducers/order-summaries';
import ReviewOrderSummaryDetailsCard from './ReviewOrderSummaryDetailsCard';
import {
    ballBlue,
    ballGray,
    boldWeight,
    medium,
    red,
    white
} from '../../../../themes/globalConstants';
import moment from 'moment';
import {
    formattedDateWithTimezone,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: white
    },
    container: {
        display: 'flex',
        height: '100%',
        padding: '24px',
        gap: '16px',
        overflow: 'auto',
        '& .MuiGrid-spacing-xs-2': {
            border: '1px solid lightgray',
            borderRadius: '5px',
            backgroundColor: white,
            marginTop: 3
        }
    },
    filterContainer: {
        flexShrink: 0,
        marginBottom: '16px'
    },
    transformImage: {
        width: '0.25em'
    },
    rotateImage: {
        transform: 'rotate(-90deg)'
    },
    button: {
        borderBottom: 'none',
        color: ballGray
    },
    activeButton: {
        borderBottom: `2px solid ${ballBlue}`,
        color: ballBlue
    },
    rightAlign: {
        textAlign: 'right'
    },
    informationalText: {
        padding: theme.spacing(2)
    },
    redText: {
        color: red,
        textTransform: 'uppercase',
        fontSize: medium
    },
    boldText: {
        fontSize: '9px',
        fontWeight: boldWeight
    },
    columnNames: {
        color: ballGray,
        fontWeight: boldWeight,
        fontSize: '9px',
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    filterInput: {
        width: '100%',
        marginTop: '4px',
        marginBottom: '4px',
        fontSize: '8px'
    },
    filterInputContainer: {
        alignItems: 'center'
    },
    selectInput: {
        height: '38px',
        width: '100%',
        marginTop: '4px',
        marginBottom: '4px',
        fontSize: '8px'
    },
    labelColumn: {
        alignItems: 'flex-end',
        flexWrap: 'nowrap'
    },
    palletLabel: {
        textAlign: 'left',
        marginBottom: '45px',
        color: 'gray',
        fontWeight: boldWeight,
        fontSize: '9px'
    },
    hidden: {
        display: 'none'
    },
    chevrons: {
        margin: '2em 0'
    },
    gridItemSmall: {
        maxWidth: '6em'
    },
    gridItemMedium: {
        maxWidth: '8em'
    },
    columnHeader: {
        display: 'inline'
    },
    sortingDiv: {
        display: 'flex',
        flexDirection: 'column',
        float: 'inline-end'
    }
}));

interface ReviewOrderSummaryDetailsGridProps {
    order?: OrderSummary[];
    isviewOrderDetailsTab: boolean;
    isviewSummaryOtherTabs: boolean;
}

const ReviewOrderSummaryDetailsGrid = (props: ReviewOrderSummaryDetailsGridProps) => {
    const classes = useStyles();
    const [filters, setFilters] = useState({
        soldTo: '',
        shipTo: '',
        orderNumber: '',
        shipmentNumber: '',
        customerPoNumber: '',
        customerProductId: '',
        productName: '',
        size: '',
        qty: '',
        shipFrom: '',
        shipments: '',
        poLine: '',
        releaseNumber: '',
        submitted: '',
        requestedDateTime: '',
        scheduledDateTime: '',
        carrier: '',
        status: ''
    });
    const { t } = useTranslation();
    const [filteredOrders, setFilteredOrders] = useState<OrderSummary[]>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const { status } = useTypedSelector<OrderingSummaryState>((state) => state.orderingSummaries);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rowsPerPageOptions: (number | { label: string; value: number })[] = Array.from(
        { length: 10 },
        (_, index) => (index + 1) * 50
    );

    rowsPerPageOptions.push({ label: 'All', value: -1 });

    useEffect(() => {
        /** need to write filter for remaning fields */
        if (props.order) {
            const filtered = props.order?.filter((product) => {
                let matchesqty: boolean = false;
                if (product.palletQuantity) {
                    const eaches =
                        product.quantityPerPallet &&
                        product.quantityPerPallet * product.palletQuantity;
                    matchesqty = filters.qty
                        ? (product.palletQuantity ?? '').toString().includes(filters.qty) ||
                        (eaches ?? '').toString().includes(filters.qty)
                        : true;
                }
                const matchesSoldTo = filters.soldTo
                    ? (product.soldTo ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.soldTo.toLocaleLowerCase())
                    : true;
                const matchesShipTo = filters.shipTo
                    ? (product.shipTo ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.shipTo.toLocaleLowerCase())
                    : true;
                const matchesorderNumber = filters.orderNumber
                    ? (product.orderNumber ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.orderNumber.toLocaleLowerCase())
                    : true;
                const matchesShipmentNumber = filters.shipmentNumber
                    ? (product.shipmentNumber ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.shipmentNumber.toLocaleLowerCase())
                    : true;
                const matchesId = filters.customerProductId
                    ? (product.productSKU ?? '').includes(filters.customerProductId) ||
                    (product.productGraphicId ?? '')
                        .toString()
                        .includes(filters.customerProductId)
                    : true;
                const matchesCustomerPoNumber = filters.customerPoNumber
                    ? (product.customerPoNumber ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.customerPoNumber.toLocaleLowerCase())
                    : true;
                const matchesProductName = filters.productName
                    ? (product.productName ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.productName.toLocaleLowerCase())
                    : true;
                const matchessize = filters.size
                    ? (product.size ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.size.toLocaleLowerCase())
                    : true;
                const matchesShipFrom = filters.shipFrom
                    ? (product.shipFrom ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.shipFrom.toLocaleLowerCase())
                    : true;
                const matchesShipments = filters.shipments
                    ? (product.shipments ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.shipments.toLocaleLowerCase())
                    : true;
                const matchesPoLine = filters.poLine
                    ? (product.poLine ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.poLine.toLocaleLowerCase())
                    : true;
                const matchesReleaseNumber = filters.releaseNumber
                    ? (product.releaseNumber ?? '')
                        .toString()
                        .toLocaleLowerCase()
                        .includes(filters.releaseNumber.toLocaleLowerCase())
                    : true;
                const matchesSubmitted = filters.submitted
                    ? (formattedDateWithTimezone(moment(product.submitted), 'MM/DD/YYYY') ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.submitted)
                    : true;
                const matchesRequestedDate = filters.requestedDateTime
                    ? (getFullFormattedDateTime('', product.requestedDateTime) ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.requestedDateTime.toLocaleLowerCase())
                    : true;
                const matchesScheduledDate = filters.scheduledDateTime
                    ? (getFullFormattedDateTime('', product.scheduledDateTime) ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.scheduledDateTime.toLocaleLowerCase())
                    : true;
                const matchesCarrier = filters.carrier
                    ? (product.carrier ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.carrier.toLocaleLowerCase())
                    : true;
                const matchesStatus = filters.status
                    ? (product.status ?? '')
                        .toLocaleLowerCase()
                        .toString()
                        .includes(filters.status.toLocaleLowerCase())
                    : true;
                return (
                    matchesSoldTo &&
                    matchesShipTo &&
                    matchesorderNumber &&
                    matchesShipmentNumber &&
                    matchesCustomerPoNumber &&
                    matchesProductName &&
                    matchessize &&
                    matchesShipFrom &&
                    matchesPoLine &&
                    matchesCarrier &&
                    matchesProductName &&
                    matchesReleaseNumber &&
                    matchesScheduledDate &&
                    matchesSubmitted &&
                    matchesRequestedDate &&
                    matchesStatus &&
                    matchesShipments &&
                    matchesId &&
                    matchesqty
                );
            });
            setFilteredOrders(filtered);
        }
    }, [filters, props.order]);

    const handleInputChange = (field: string, value: string) => {
        setFilters({
            ...filters,
            [field]: value
        });
    };

    const handlesorting = (sortField: string, sortOrder: string) => {
        if (sortField) {
            if (props.order) {
                const sorted = [...props.order].sort((a, b) => {
                    if (a[sortField] === null) return 1;
                    if (b[sortField] === null) return -1;
                    if (a[sortField] === null && b[sortField] === null) return 0;
                    return (
                        a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
                            numeric: true
                        }) * (sortOrder === 'asc' ? 1 : -1)
                    );
                });
                setFilteredOrders(sorted);
            }
        }
    };

    const sortingGridFunction = (field: string) => {
        return (
            <div className={classes.sortingDiv}>
                <IconButton
                    onClick={() => {
                        handlesorting(field, 'asc');
                    }}
                >
                    <img
                        className={`${classes.transformImage} ${classes.rotateImage}`}
                        src={`${process.env.PUBLIC_URL}/assets/Chevron_Right.svg`}
                        alt={`Action menu for `}
                    />
                </IconButton>
                <IconButton
                    onClick={() => {
                        handlesorting(field, 'desc');
                    }}
                >
                    <img
                        style={{ width: '0.42em' }}
                        src={`${process.env.PUBLIC_URL}/assets/Chevron_Down.svg`}
                        alt={`Action menu for`}
                    />
                </IconButton>
            </div>
        );
    };

    return (
        <Grid container className={classes.root}>
            <Grid container item xs={12} className={classes.informationalText}>
                <Typography className={classes.redText}>
                    {t('reviewOrdersInfroamtionalText', {
                        Datetime: props.order && props.order[0]?.lastUpdatedDate
                    })}
                </Typography>
            </Grid>
            {status === 'loading' || status === 'idle' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <div className={classes.container}>
                    <div className={classes.filterContainer}>
                        <Grid container spacing={2} className={classes.labelColumn}>
                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="soldTo">SOLD TO</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('soldTo')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.soldTo}
                                        onChange={(e) =>
                                            handleInputChange('soldTo', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item className={classes.gridItemMedium}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="shipTo">Ship To</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('shipTo')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.shipTo}
                                        onChange={(e) =>
                                            handleInputChange('shipTo', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="orderNumber">ORDER NUMBER</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('orderNumber')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.orderNumber}
                                        onChange={(e) =>
                                            handleInputChange('orderNumber', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            {!props.isviewSummaryOtherTabs && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="shipmentsNumber">SHIPMENT NUMBER</Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('shipmentNumber')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.shipmentNumber}
                                            onChange={(e) =>
                                                handleInputChange('shipmentNumber', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="customersPoNumber">
                                            CUSTOMER PO NUMBER
                                        </Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('customerPoNumber')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.customerPoNumber}
                                        onChange={(e) =>
                                            handleInputChange('customerPoNumber', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            {props.isviewOrderDetailsTab && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="displayId">
                                                CUSTOMER ID PRODUCT ID GRAPHIC ID
                                            </Trans>
                                        </Typography>
                                    </div>
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.customerProductId}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'customerProductId',
                                                    e.target.value
                                                )
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {props.isviewOrderDetailsTab && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="productNameDescription">
                                                PRODUCT NAME DESCRIPTION
                                            </Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('productName')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.productName}
                                            onChange={(e) =>
                                                handleInputChange('productName', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {props.isviewOrderDetailsTab && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="size">SIZE</Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('size')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.size}
                                            onChange={(e) =>
                                                handleInputChange('size', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}

                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="shipFrom">SHIP FROM</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('shipFrom')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.shipFrom}
                                        onChange={(e) =>
                                            handleInputChange('shipFrom', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="qty">QTY</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('qty')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        onChange={(e) => handleInputChange('qty', e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            {!props.isviewOrderDetailsTab && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="shipments">Shipments</Trans>
                                        </Typography>
                                    </div>
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.shipments}
                                            onChange={(e) =>
                                                handleInputChange('shipments', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {props.isviewOrderDetailsTab && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="poLine">PO LINE</Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('poLine')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.poLine}
                                            onChange={(e) =>
                                                handleInputChange('poLine', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {props.isviewOrderDetailsTab && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="releaseNumber">RELEASE NUMBER</Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('releaseNumber')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.releaseNumber}
                                            onChange={(e) =>
                                                handleInputChange('releaseNumber', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="submitted">SUBMITTED</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('submitted')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.submitted}
                                        onChange={(e) =>
                                            handleInputChange('submitted', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            {!props.isviewSummaryOtherTabs && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="requestedDateTime">
                                                REQUESTED DATE/TIME
                                            </Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('requestedDateTime')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.requestedDateTime}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'requestedDateTime',
                                                    e.target.value
                                                )
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {!props.isviewSummaryOtherTabs && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="scheduledDateTime">
                                                SCHEDULED DATE/TIME
                                            </Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('scheduledDateTime')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.scheduledDateTime}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'scheduledDateTime',
                                                    e.target.value
                                                )
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            {!props.isviewSummaryOtherTabs && (
                                <Grid item className={classes.gridItemSmall}>
                                    <div className={classes.columnHeader}>
                                        <Typography variant="body1" className={classes.columnNames}>
                                            <Trans i18nKey="carrier">CARRIER</Trans>
                                        </Typography>
                                    </div>
                                    {sortingGridFunction('carrier')}
                                    <div className={classes.filterInputContainer}>
                                        <TextField
                                            className={classes.filterInput}
                                            value={filters.carrier}
                                            onChange={(e) =>
                                                handleInputChange('carrier', e.target.value)
                                            }
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search
                                                            fontSize="inherit"
                                                            style={{ fontSize: '0.75rem' }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )}
                            <Grid item className={classes.gridItemSmall}>
                                <div className={classes.columnHeader}>
                                    <Typography variant="body1" className={classes.columnNames}>
                                        <Trans i18nKey="status">STATUS</Trans>
                                    </Typography>
                                </div>
                                {sortingGridFunction('status')}
                                <div className={classes.filterInputContainer}>
                                    <TextField
                                        className={classes.filterInput}
                                        value={filters.status}
                                        onChange={(e) =>
                                            handleInputChange('status', e.target.value)
                                        }
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search
                                                        fontSize="inherit"
                                                        style={{ fontSize: '0.75rem' }}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid
                                item
                                style={{ visibility: 'hidden' }}
                                className={classes.gridItemSmall}
                            >
                                <Typography
                                    variant="body1"
                                    className={classes.columnNames}
                                ></Typography>
                                <div className={classes.filterInputContainer}>
                                    <TextField className={classes.filterInput} />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item spacing={3}>
                            {filteredOrders
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((order, index) => (
                                    <Grid item xs={12} key={index}>
                                        <ReviewOrderSummaryDetailsCard
                                            order={order}
                                            isviewOrderDetailsTab={props.isviewOrderDetailsTab}
                                            isviewSummaryOtherTabs={props.isviewSummaryOtherTabs}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </div>
                    <div></div>
                </div>
            )}
            {status === 'success' && props.order && props.order.length > 0 && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={props.order?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            )}
        </Grid>
    );
};

export default ReviewOrderSummaryDetailsGrid;
