import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import {
    ballDrkBlue,
    ballGray,
    blackWeight,
    boldWeight,
    large,
    ltBlueGrey_7,
    white,
    xxxl
} from '../../../../../themes/globalConstants';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import DashboardWidgetMetric from './DashboardWidgetMetric';
import DashboardWidgetMetricRow from './DashboardWidgetMetricRow';
import { enUS } from '../../../../../utility/translations/locales';
import { DragDropProps } from '../utilities/dashboard-positions.config';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { CustomerOrderingDashboardState } from '../../../../../store/reducers/customer-ordering-dashboard';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em',
        position: 'relative'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    moreIcon: {
        width: '1.25em',
        height: '1.25em',
        marginLeft: '.5em'
    },
    widgetSectionHeader: {
        fontWeight: blackWeight,
        color: ballGray,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: '1.75em'
    },
    widgetNumberSlant: {
        flexGrow: 1,
        maxWidth: '6em',
        marginLeft: '14em',
        justifyContent: 'center',
        display: 'flex',
        '& > div': {
            width: '1em',
            height: '100%',
            color: ltBlueGrey_7,
            background: `linear-gradient(to top left,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            ${ltBlueGrey_7} 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%)`
        }
    },
    widgetNumberNoSlant: {
        flexGrow: 1,
        maxWidth: '6em',
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '2 rem'
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight
    },
    agedBalanceDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    widgetNumber: {
        fontSize: xxxl,
        fontWeight: 500,
        lineHeight: 1,
        color: ballDrkBlue,
        marginBottom: '.75rem',
        textDecoration: 'none',
        position: 'relative',
        borderBottom: `1px solid ${white}`,

        '&:hover': {
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    shipmentsSummaryDetailsContainer: {
        height: 100,
        marginTop: -7
    },
    shipmentsSummaryDetails: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    container: {
        '& > div': {
            position: 'relative'
        }
    }
}));

export default function PaymentsManagement({ dragHandleProps }: DragDropProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const { dashboardData, paymentsManagementStatus } =
        useTypedSelector<CustomerOrderingDashboardState>(
            (state) => state.customerOrderingDashboard
        );
    useEffect(() => {
        if (dashboardData?.shipmentDeliveriesDashboard) {
            // after inventory logic is done
        }
    }, [dashboardData]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="product-portfolio-widget">
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="order-management-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    Payments Management
                </Typography>
            </div>

            {paymentsManagementStatus === 'loading' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={6}>
                    <Grid item xs={7}>
                        <Grid container direction="row">
                            <Grid item md={12}>
                                <DashboardWidgetMetricSection
                                    title={t('Payments', enUS.payments)}
                                    left={
                                        <DashboardWidgetMetric
                                            type="orange"
                                            quantity={0}
                                            link={``}
                                            subheader={t(
                                                'Non-Payment Impacting delivery',
                                                enUS.nonPayment
                                            )}
                                            subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Warning_Icon.svg`}
                                        />
                                    }
                                    center={
                                        <div>
                                            <Grid item data-testid="draft-orders-widget">
                                                <DashboardWidgetMetric
                                                    quantity={0}
                                                    link={''}
                                                    type="yellow"
                                                    subheader={t('Awating Approved', enUS.awaiting)}
                                                    subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Pending_Payments_Icon.svg`}
                                                />
                                            </Grid>
                                        </div>
                                    }
                                    right={
                                        <div>
                                            <Grid item data-testid="due-this-month">
                                                <DashboardWidgetMetric
                                                    quantity={0}
                                                    link={''}
                                                    subheader={t(
                                                        'Due This Month',
                                                        enUS.dueThisMonth
                                                    )}
                                                    subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Calendar_Dashboard_Icon.svg`}
                                                />
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h5" className={classes.widgetSectionHeader}>
                            <Trans i18nKey="upcomingPayments">{enUS.upcomingPayments}</Trans>
                        </Typography>
                        <Grid container direction="row">
                            <Grid item md={12} container direction="column">
                                <DashboardWidgetMetricRow
                                    title={'December'}
                                    paymentCount={100000}
                                    secondItemCount={20}
                                    secondItemUnit={'PL'}
                                    isLink={true}
                                    isPaymentManagement={true}
                                    route={``}
                                />
                                <DashboardWidgetMetricRow
                                    title={'January'}
                                    paymentCount={200000}
                                    secondItemCount={30}
                                    secondItemUnit={'PL'}
                                    isPaymentManagement={true}
                                    isLink={true}
                                    route={``}
                                />
                                <DashboardWidgetMetricRow
                                    title={'February'}
                                    paymentCount={300000}
                                    secondItemCount={40}
                                    secondItemUnit={'PL'}
                                    isPaymentManagement={true}
                                    isLink={true}
                                    route={``}
                                />
                                <DashboardWidgetMetricRow
                                    title={'March'}
                                    paymentCount={400000}
                                    secondItemCount={50}
                                    isPaymentManagement={true}
                                    secondItemUnit={'PL'}
                                    isLink={true}
                                    route={``}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
