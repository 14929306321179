import { createSelector } from 'reselect';
import { DashboardElement, UserConfigurationState } from '../reducers/user-configuration';

const getDashboardPositions = (state) =>
    (state.userConfiguration as UserConfigurationState).dashboardPositions;

export const selectSortedDashboardPositions = createSelector(
    [getDashboardPositions],
    (dashboardPositions): Array<DashboardElement> => {
        return dashboardPositions && dashboardPositions.length
            ? dashboardPositions.sort((a, b) => a.columnPosition - b.columnPosition)
            : [];
    }
);
