import { UPDATE_REGION, UPDATE_CULTURE, UPDATE_REGION_CULTURE } from '../actions/action-types';
import { KeyValuePair } from '../types';
import { naLanguages, saLanguages, euLanguages, regions } from '../../static-data/dropdown-lists';
import { loadState, saveState } from '../../utility/helpers/localStorage';

export interface RegionCultureState {
    regions: KeyValuePair[];
    regionCode: string;
    region: string;
    cultures: KeyValuePair[];
    culture: string;
    cultureCode: Culture;
}

export enum Culture {
    enUS = 'en-US',
    esES = 'es-ES',
    frFR = 'fr-FR',
    enGB = 'en-GB',
    ptBR = 'pt-BR',
    ruRU = 'ru-RU',
    deDE = 'de-DE',
    esMX = 'es-MX',
    esVE = 'es-VE'
}

const userConfigState = loadState('userConfig');

const validateState = (state: any) => {
    if (state && Object.keys(state).length !== 0) {
        let validCulture: any;
        const validRegion = regions.filter((region) => region.value === state.regionCode);
        if (validRegion && validRegion.length > 0) {
            validCulture = validRegion[0].languages.filter(
                (culture) => culture.value === state.cultureCode
            );
            if (validCulture.length > 0) {
                return {
                    region: validRegion[0],
                    culture: validCulture[0]
                };
            }
        } else {
            return undefined;
        }
    }
    return undefined;
};

const validState = validateState(userConfigState);

// if regionCulture state exist in local storage, hydrate initialState, else get it from user config or set default.
const generateInitialState = (): RegionCultureState => {
    let currentState = {
        regions: regions,
        regionCode: regions[0].value,
        cultures: naLanguages,
        region: regions[0].name,
        culture: naLanguages[0].name,
        cultureCode: naLanguages[0].value as Culture
    };

    if (validState) {
        return {
            ...currentState,
            regionCode: userConfigState?.regionCode ?? currentState.regionCode,
            cultures: validState?.region?.languages ?? currentState.cultures,
            region: validState?.region?.name ?? currentState.region,
            culture: validState?.culture?.name ?? currentState.culture,
            cultureCode: userConfigState?.cultureCode ?? currentState.cultureCode
        };
    }

    return currentState;
};

const initialState = generateInitialState();

let updatedCultures = initialState.cultures;
const getNewCulture = (newRegions: Array<KeyValuePair>, regionCode?: string) => {
    switch (regionCode) {
        case newRegions[0].value:
            updatedCultures = naLanguages;
            break;
        case newRegions[1].value:
            updatedCultures = euLanguages;
            break;
        case newRegions[2].value:
            updatedCultures = saLanguages;
            break;
        default:
            break;
    }
};

const regionCulture = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_REGION_CULTURE:
            getNewCulture(state.regions, action.value.region);
            const newRegionCultureState = Object.assign({}, state, {
                region: regions.find((r) => r.value === action.value.region)?.name,
                regionCode: action.value.region,
                cultures: updatedCultures,
                culture: updatedCultures.find((c) => c.value === action.value.culture)?.name,
                cultureCode: updatedCultures.find((c) => c.value === action.value.culture)
                    ? action.value.culture
                    : updatedCultures[0].value
            });

            saveState('userConfig', {
                ...userConfigState,
                regionCode: newRegionCultureState.regionCode,
                cultureCode: newRegionCultureState.cultureCode
            });
            return newRegionCultureState;
        case UPDATE_REGION: {
            getNewCulture(state.regions, action.value);
            const newRegionState = Object.assign({}, state, {
                region: regions.find((r) => r.value === action.value)?.name,
                regionCode: action.value,
                cultures: updatedCultures,
                culture: updatedCultures[0].name,
                cultureCode: updatedCultures[0].value
            });

            saveState('userConfig', {
                ...userConfigState,
                regionCode: newRegionState.regionCode,
                cultureCode: newRegionState.cultureCode
            });
            return newRegionState;
        }
        case UPDATE_CULTURE: {
            const newCultureState = Object.assign({}, state, {
                culture: state.cultures.find((c) => c.value === action.value)?.name,
                cultureCode: action.value
            });

            saveState('userConfig', {
                ...userConfigState,
                regionCode: newCultureState.regionCode,
                cultureCode: newCultureState.cultureCode
            });
            return newCultureState;
        }
        default:
            return state;
    }
};

export default regionCulture;
