import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Grid, makeStyles } from '@material-ui/core';

import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState, PaymentTerms } from '../../../../store/reducers/customer-context';
import { Activity } from '../../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../../templates/ProcessingPageTemplate';
import { black } from '../../../../themes/globalConstants';
import {
    loadCustomerCachedDashboard,
    loadOrderManagementDashboardInformation,
    loadProductPortfolioDashboardInformation,
    loadShipmentsDeliveriesDashboardInformation
} from '../../../../store/actions/customer-ordering-dashboard';
import { isShipToAccount } from '../../../../utility/helpers/admin-helpers';
import CustomerDashboardDragAndDrop from './components/CustomerDashboardDragAndDrop';
import { CustomerOrderingDashboardState } from '../../../../store/reducers/customer-ordering-dashboard';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0',
        marginTop: '1em',
        marginBottom: '1em',
        flexWrap: 'nowrap'
    },
    side: {
        paddingRight: '0 !important'
    },
    listIcon: {
        color: black,
        '& .MuiListItemIcon-root': {
            color: black
        }
    },
    draggable: {
        paddingBottom: '1em'
    },
    dragDropContainer: {
        marginBottom: '-1em'
    }
}));

export default function Dashboard() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { cachedDashboard } = useTypedSelector<CustomerOrderingDashboardState>(
        (state) => state.customerOrderingDashboard
    );
    const [showOrderManagementWidget, setShowOrderManagementWidget] = useState<boolean>(true);
    const [showShipmentsWidget, setShowShipmentsWidget] = useState<boolean>(true);
    const [showInventoryWidget, setShowInventoryWidget] = useState<boolean>(false);
    const [showPaymentsWidget, setShowPaymentsWidget] = useState<boolean>(false);
    const [showPortfolioWidget, setShowPortfolioWidget] = useState<boolean>(true);

    const { accounts, selectedAccountId, selectedChildrenAccountId } =
        useTypedSelector<CustomerContextState>((state) => state.customerContext);

    useEffect(() => {
        const loadDashboardData = async () => {
            if (selectedAccountId && accounts && accounts.length > 0) {
                const currentAccount = accounts.find(
                    (account) =>
                        account.accountId ===
                        (selectedChildrenAccountId ? selectedChildrenAccountId : selectedAccountId)
                );
                if (currentAccount && isShipToAccount(currentAccount)) {
                    const cacheExists = cachedDashboard.find((cd) => {
                        const isAfter = moment(cd.dateLimit).isAfter(moment());
                        return cd.shipToId === selectedAccountId && isAfter;
                    });
                    if (cacheExists) {
                        dispatch(
                            loadCustomerCachedDashboard(
                                selectedChildrenAccountId
                                    ? selectedChildrenAccountId
                                    : selectedAccountId
                            )
                        );
                    } else {
                        const paymentTerms =
                            accounts.find((account) => account.accountId === selectedAccountId)
                                ?.paymentTerms || PaymentTerms.Unknown;

                        try {
                            await dispatch(
                                loadOrderManagementDashboardInformation(
                                    selectedChildrenAccountId
                                        ? selectedChildrenAccountId
                                        : selectedAccountId,
                                    selectedAccountId,
                                    paymentTerms
                                )
                            );
                            await dispatch(
                                loadProductPortfolioDashboardInformation(
                                    selectedChildrenAccountId
                                        ? selectedChildrenAccountId
                                        : selectedAccountId,
                                    selectedAccountId,
                                    paymentTerms
                                )
                            );
                            await dispatch(
                                loadShipmentsDeliveriesDashboardInformation(
                                    selectedChildrenAccountId
                                        ? selectedChildrenAccountId
                                        : selectedAccountId,
                                    selectedAccountId,
                                    paymentTerms
                                )
                            );
                        } finally {
                            dispatch({ type: 'SET_STATUS_IDLE' });
                        }
                    }
                }
            }
        };

        loadDashboardData();
    }, [dispatch, selectedAccountId, selectedChildrenAccountId, cachedDashboard, accounts]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: '',
                description: '',
                thinBanner: true,
                isDualSelection: true,
                displayDropdown: true,
                completeDropdown: true
            }}
            activity={Activity.Dashboard}
            shipTos={false}
        >
            <Grid
                container
                spacing={2}
                className={classes.dashboard}
                data-testid="dashboard"
                direction="column"
            >
                <Grid item xs={12} className={classes.dragDropContainer}>
                    <CustomerDashboardDragAndDrop
                        showOrderManagementWidget={showOrderManagementWidget}
                        showShipmentsAndDeliveriesWidget={showShipmentsWidget}
                        showInventoryWidget={showInventoryWidget}
                        showPaymentsManagementWidget={showPaymentsWidget}
                        showProductPortfolioWidget={showPortfolioWidget}
                    />
                </Grid>
            </Grid>
        </ProcessingPageTemplate>
    );
}
