import React, { useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ShipmentCard from './components/ShipmentCard';
import InformationalCard from '../../reusable/molecules/InformationalCard';
import OrderProductGrid from './components/OrderProductGrid';

import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import OrderInfoCard from '../../reusable/molecules/OrderInfoCard';
import NotificationBanner from '../../reusable/molecules/NotificationBanner';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { getCurrentlyActiveDocuments } from '../../../utility/helpers/pricing-helpers';
import { Activity } from '../../../utility/auth/useSecurity';
import { stringFormatterReplace } from '../../../utility/helpers/formatters';
import { buildDeliveryTimes, subtractTimezoneOffset } from '../../../utility/helpers/date-helpers';

import {
    selectDateFormat,
    selectIsBillToAccount,
    selectIsImpersonation
} from '../../../store/selectors';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import {
    OrderingState,
    ProductWithPallets,
    DeliveryShipment,
    ProductToShip,
    DeliveryOrder
} from '../../../store/reducers/ordering-dashboard';
import {
    clearContractsAndDocuments,
    loadContractsAndDocuments
} from '../../../store/actions/contract-summary';
import {
    loadOrder,
    loadProducts,
    resetOrderingProcess,
    setOrderInformation,
    updateDeliveryOrder,
    updateDeliveryOrderUpdateError,
    updateOrderDate
} from '../../../store/actions/ordering-dashboard';
import { generateKey, productTypeHelper } from '../../../utility/helpers/order-helpers';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import OrdersService, { Order_Status } from '../../../utility/services/orders-service';
import Button from '../../reusable/atoms/Button';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { ballBlue, boldWeight } from '../../../themes/globalConstants';
import SaveDraftModal from '../../reusable/molecules/SaveDraftModal';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: theme.spacing(1)
    },
    gridContainer: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden'
    },
    pane: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: 'calc(100vh - 140px)'
    },
    sectionHeader: {
        flexShrink: 0,
        marginBottom: 5,
        borderBottom: '1px solid #eee',
        textAlign: 'center',
        width: '100%'
    },
    actionBtn: {
        margin: theme.spacing(0.5)
    },
    infoText: {
        color: 'red',
        fontWeight: 'bold',
        textAlign: 'center',
        flex: 1,
        fontSize: 12,
        textTransform: 'uppercase'
    },
    totalsContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    totalsDiv: {
        display: 'flex',
        flexDirection: 'column',
        height: '72px',
        marginRight: '10px',
        fontSize: '10px'
    },
    shipmentsContainer: {
        overflowY: 'auto',
        height: '100%'
    },
    disabledLink: {
        //This will disable the link in all non-IE browsers
        pointerEvents: 'none',
        //This is for IE
        cursor: 'default'
    },
    availableProductsText: {
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: '14px',
        color: 'orange'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    emptyShipmentText: {
        color: ballBlue,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        paddingBottom: '2em'
    }
}));

export default function CreateShipments() {
    const { id } = useParams<DefaultQueryParams>();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const shipmentContainerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const state = useTypedSelector((state) => state);
    const accessToken = state.auth.accessToken;
    const {
        orderInformation,
        productsToBeShipped,
        loadingOrder,
        loadedOrder,
        loadingProducts,
        loadedProducts,
        deliveryOrder
    } = useTypedSelector<OrderingState>((state) => state.orderingDashboard);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { documents } = useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const isBillToAccount = useTypedSelector<boolean>(selectIsBillToAccount);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const dateFormat = useTypedSelector<string>(selectDateFormat);

    const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);
    const [editingShipmentType, setEditingShipmentType] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);
    const [customerPickUp, setCustomerPickUp] = useState<boolean>(false);
    const [isDraft, setIsDraft] = useState<boolean>(false); // Draft = Status diferente de sent
    const [pristineDeliveryOrder, setPristineDeliveryOrder] = useState<DeliveryOrder>();
    const [shipmentContainerHeight, setShipmentContainerHeight] = useState<number>(0);
    const [currentShipments, setCurrentShipments] = useState<DeliveryShipment[]>([]);
    const [currUptadedLoads, setCurrUpdatedLoads] = useState<DeliveryShipment>();
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [openBlockedNavigationModal, setOpenBlockedNavigationModal] = useState<boolean>(false);
    const [openSaveDraftModal, setOpenSaveDraftModal] = useState<boolean>(false);

    const shipToId = orderInformation?.shipToId;
    const deliveryDate = orderInformation?.deliveryDate;

    const dashboardLink = '/dashboard';
    const createOrderLink = '/create-order';
    const orderSummaryLink = '/create-order-summary';
    const orderSummariesLink = '/orders-summary';

    /** Check for unapproved T&Cs */
    const someCurrentlyActiveDocumentIsUnapproved = useMemo(() => {
        const currentlyActiveDocuments = getCurrentlyActiveDocuments(documents);
        const someCurrentlyActiveDocumentIsUnapproved = currentlyActiveDocuments.some(
            (d) => !d.isApproved
        );
        return someCurrentlyActiveDocumentIsUnapproved;
    }, [documents]);

    const availableProductsDate = useMemo(() => {
        if (deliveryDate) {
            const formattedDeliveryDate = moment(deliveryDate).format(dateFormat);
            return stringFormatterReplace(
                t(
                    'productsAvailableForDelivery',
                    'Products available for delivery on or after {0}'
                ),
                formattedDeliveryDate
            );
        }
        return '';
    }, [t, deliveryDate, dateFormat]);

    const selectedTimesList = useMemo(() => {
        const allTimeList = buildDeliveryTimes(orderInformation?.deliveryDate);
        const usedTimeList = Array.from(
            new Set(
                currentShipments
                    .filter((shipment) => shipment.deliveryDateTime)
                    .map((shipment) => moment(shipment.deliveryDateTime))
            )
        );

        return allTimeList.map((time) => {
            if (usedTimeList.some((usedTime) => time.time.isSame(usedTime))) {
                return { ...time, disabled: true };
            }
            return time;
        });
    }, [orderInformation?.deliveryDate, currentShipments]);

    /** Load terms and condition based on the account selected */
    useEffect(() => {
        const account = accounts?.find((acc) => acc.accountId === shipToId);
        if (shipToId && account && (account?.listCustomer || !isBillToAccount)) {
            dispatch(loadContractsAndDocuments([shipToId], !isBillToAccount));
        } else {
            dispatch(clearContractsAndDocuments());
        }
    }, [dispatch, shipToId, isBillToAccount, accounts]);

    /** Trigger LoadProducts if no products are present and it was not loaded yet and not loading */
    useEffect(() => {
        if (
            orderInformation &&
            orderInformation.shipToId &&
            productsToBeShipped &&
            productsToBeShipped.length === 0 &&
            !loadedProducts &&
            !loadingProducts
        ) {
            dispatch(loadProducts());
        }
    }, [dispatch, auth, loadedProducts, loadingProducts, productsToBeShipped, orderInformation]);

    /** Check if the data is correct in order to display the submit button */
    useEffect(() => {
        let isValid = true;
        if (currentShipments && currentShipments.length > 0) {
            currentShipments.forEach((shipment) => {
                if (!shipment.loads) {
                    isValid = false;
                } else {
                    const emptyLoads = shipment.loads.filter((load) => load.palletQuantity === 0);
                    const totalQuantity = shipment.loads.reduce(
                        (acc, load) => acc + load.palletQuantity,
                        0
                    );

                    if (
                        emptyLoads.length > 0 ||
                        (shipment.palletCapacity && totalQuantity > shipment.palletCapacity)
                    ) {
                        isValid = false;
                    }
                }
            });
        } else {
            isValid = false;
        }

        setIsFormValid(isValid);
    }, [currentShipments]);

    /** Load Mode of Transport from Accounts data */
    useEffect(() => {
        const account = accounts?.find((acc) => acc.accountId === shipToId);
        if (account && account.modeOfTransport === 'CPU') {
            setCustomerPickUp(true);
        }
    }, [accounts, shipToId]);

    /** Load Order from API if ID is present */
    useEffect(() => {
        if (id) {
            setIsEdit(true);
            if (!deliveryOrder && !loadingOrder && !loadedOrder) {
                dispatch(loadOrder(id));
            }
        }
    }, [dispatch, id, deliveryOrder, loadingOrder, loadedOrder]);

    /** Store the original order in a untouchable variable */
    useEffect(() => {
        if (deliveryOrder) {
            setPristineDeliveryOrder(JSON.parse(JSON.stringify(deliveryOrder)));
            if (deliveryOrder.draftId) {
                setIsDraft(true);
            }
        }
    }, [deliveryOrder]);

    useLayoutEffect(() => {
        setShipmentContainerHeight(
            (shipmentContainerRef?.current?.offsetTop || 0) +
                (footerRef?.current?.clientHeight || 0) +
                36
        );
    }, [shipmentContainerRef?.current?.offsetTop]);

    const handleAddProductToShipment = (product: ProductWithPallets) => {
        if (focusedIndex === -1) {
            return;
        }
        const shipmentsArr = [...currentShipments].map((shipment, index) => {
            if (index === focusedIndex) {
                let newSequence = 1;
                if (shipment.loads && shipment.loads.length > 0) {
                    newSequence = shipment.loads[shipment.loads.length - 1].sequence! + 1;
                }
                let loadsArr: ProductToShip[] = shipment.loads || [];
                if (loadsArr && loadsArr.length > 0) {
                    loadsArr.forEach((load) => {
                        let updateLoadDetails = currUptadedLoads?.loads?.find(
                            (x) => x.loadKey === load.loadKey
                        );
                        load.palletQuantity = updateLoadDetails?.palletQuantity || 0;
                        load.deliveryInstructions = updateLoadDetails?.deliveryInstructions;
                        load.fillerLine = updateLoadDetails?.fillerLine;
                        load.purchaseOrderNumber = updateLoadDetails?.purchaseOrderNumber;
                        load.referenceNumber = updateLoadDetails?.reference;
                        load.releaseNumber = updateLoadDetails?.releaseNumber;
                    });
                }
                loadsArr.push({
                    ...product,
                    palletQuantity: 0,
                    loadKey: generateKey('load'),
                    sequence: newSequence
                } as ProductToShip);
                return {
                    ...shipment,
                    loads: loadsArr,
                    shipmentType: productTypeHelper(product.type) as string
                };
            }
            return shipment;
        });

        setCurrentShipments(shipmentsArr);
    };

    const handleUpdateLoads = (shipment: DeliveryShipment) => {
        setCurrUpdatedLoads(shipment);
    };

    const handleRemoveLoads = (shipment: DeliveryShipment) => {
        const shipmentsArr = [...currentShipments].map((currshipment, index) => {
            if (shipment.id === currshipment.id) {
                return {
                    ...shipment,
                    loads: shipment.loads,
                    shipmentType: shipment.shipmentType
                };
            }
            return currshipment;
        });
        setCurrentShipments(shipmentsArr);
    };

    const handleAddShipmentToOrder = () => {
        const shipmentsArr = [...currentShipments];
        setCurrentShipments([
            ...shipmentsArr,
            {
                loads: [],
                id: generateKey('shipment'),
                saved: false,
                editing: true
            }
        ]);
        setFocusedIndex(shipmentsArr.length);
    };

    const handleEditShipment = (shipment: DeliveryShipment, shipmentIndex: number) => {
        let shipmentsArr = [...currentShipments];
        shipmentsArr.splice(shipmentIndex, 1, { ...shipment, editing: true, saved: false });

        setEditingShipmentType(shipment.shipmentType);
        setFocusedIndex(shipmentIndex);

        setCurrentShipments([...shipmentsArr]);
    };

    const handleSaveShipmentChanges = (updatedShipment: DeliveryShipment, index: number) => {
        let shipmentsArr = [...currentShipments];
        let savedShipment = { ...updatedShipment, saved: true };

        shipmentsArr.splice(index, 1, savedShipment);
        setFocusedIndex(-1);
        setCurrentShipments([...shipmentsArr]);
    };

    const handleDeleteShipment = (index: number) => {
        let shipmentsArr = [...currentShipments];

        shipmentsArr.splice(index, 1);
        setFocusedIndex(-1);
        setCurrentShipments([...shipmentsArr]);
    };

    const handleSubmit = (isDraft = false, draftName?: string) => {
        if (!isImpersonation) {
            setIsSubmitting(true);

            if (id) {
                let currentShipments: any[] = [];
                currentShipments?.map((shipment, i) => {
                    let tempLoads: any[] = [];

                    shipment.loads?.map((load) => {
                        tempLoads.push({
                            releaseNumber: load.releaseNumber ? load.releaseNumber : '',
                            referenceNumber: load.reference ? load.reference : '',
                            fillerLine: load.fillerLine ? load.fillerLine : '',
                            palletQuantity: load.palletQuantity,
                            sequence: load.sequence,
                            productSku: load.productSku,
                            purchaseOrderNumber: load.purchaseOrderNumber,
                            deliveryInstructions: load.deliveryInstructions
                        });
                        return null;
                    });

                    const deliveryTime = subtractTimezoneOffset(shipment.deliveryDateTime);

                    currentShipments.push({
                        truckId: shipment.truckId ? shipment.truckId : i + 1,
                        deliveryDateTime: deliveryTime,
                        deliveryInstructions: shipment.deliveryInstructions,
                        status: shipment.status ? shipment.status : null,
                        loads: tempLoads
                    });
                    return null;
                });

                const updatedDeliveryOrder: DeliveryOrder = {
                    ...pristineDeliveryOrder!,
                    deliveryInstructions:
                        orderInformation?.deliveryInstructions ||
                        pristineDeliveryOrder?.deliveryInstructions,
                    poNumber: orderInformation?.poNumber || pristineDeliveryOrder?.poNumber,
                    shipments: currentShipments
                };

                OrdersService.updateShipmentDeliveryOrder(accessToken, updatedDeliveryOrder)
                    .then((response) => {
                        let responseObj = { ...response.data };
                        let responseShipments = [...response.data.shipments];
                        if (responseObj.shipments.length) {
                            responseObj.shipments.map((shipment, i) => {
                                let tempShipment = { ...shipment };
                                let tempShipmentLoads = [...shipment.loads];

                                shipment.loads.map((load, x) => {
                                    let tempLoad = { ...load };

                                    productsToBeShipped.map((product) => {
                                        if (load.productSku === product.productSku) {
                                            tempLoad = {
                                                ...tempLoad,
                                                name: product.name,
                                                description: product.description,
                                                graphicId: product.graphicId,
                                                graphicVersion: product.graphicVersion,
                                                reference: tempLoad.referenceNumber,
                                                type: product.type,
                                                size: product.size,
                                                coating: product.coating,
                                                shape: product.shape,
                                                neckDiameter: product.neckDiameter,
                                                endProfile: product.endProfile,
                                                tabColor: product.tabColor,
                                                unit: product.unit,
                                                customerProductId: product.customerProductId,
                                                customerProductName: product.customerProductName,
                                                displayId: product.customerProductId
                                                    ? product.customerProductId
                                                    : product.productSku,
                                                displayName: product.customerProductName
                                                    ? product.customerProductName
                                                    : product.name,
                                                graphicIdAndVersion: product.graphicIdAndVersion,
                                                purchaseOrderNumber: tempLoad.purchaseOrderNumber,
                                                deliveryInstructions: tempLoad.deliveryInstructions
                                            };
                                            tempShipmentLoads.splice(x, 1, tempLoad);
                                            if (
                                                tempLoad.type === 'CAN' ||
                                                tempLoad.type === 'BOTTLE'
                                            ) {
                                                tempShipment.shipmentType = 'Cans/Bottles';
                                            } else {
                                                tempShipment.shipmentType = 'Ends/Closures';
                                            }
                                        }
                                        return null;
                                    });
                                    return null;
                                });
                                shipment.shipmentType = tempShipment.shipmentType;
                                tempShipment = { ...shipment, loads: tempShipmentLoads };
                                responseShipments.splice(i, 1, tempShipment);
                                return null;
                            });
                        }

                        responseObj = { ...responseObj, shipments: responseShipments };
                        responseObj.customerPickup = customerPickUp;
                        dispatch(updateDeliveryOrder(responseObj));
                        setCurrentShipments(responseShipments);
                        history.push(orderSummaryLink);
                    })
                    .catch((error) => {
                        // handle error
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            } else {
                let currentShipments: any[] = [];
                currentShipments?.map((shipment, i) => {
                    let tempLoads: any[] = [];

                    shipment.loads?.map((load) => {
                        tempLoads.push({
                            releaseNumber: load.releaseNumber ? load.releaseNumber : '',
                            referenceNumber: load.reference ? load.reference : '',
                            fillerLine: load.fillerLine ? load.fillerLine : '',
                            palletQuantity: load.palletQuantity,
                            sequence: load.sequence,
                            productSku: load.productSku,
                            purchaseOrderNumber: load.purchaseOrderNumber,
                            deliveryInstructions: load.deliveryInstructions
                        });
                        return null;
                    });

                    const deliveryTime = subtractTimezoneOffset(shipment.deliveryDateTime);

                    currentShipments.push({
                        truckId: i + 1,
                        deliveryDateTime: deliveryTime,
                        deliveryInstructions: shipment.deliveryInstructions,
                        status: null,
                        loads: tempLoads
                    });
                    return null;
                });

                const submitOrder = isDraft
                    ? OrdersService.submitOrderDraft.bind(null, draftName || '')
                    : OrdersService.submitShipItOrder;

                submitOrder(currentShipments, state, orderInformation)
                    .then((response) => {
                        let responseObj = { ...response.data };
                        let responseShipments = isDraft
                            ? [...responseObj.orders[0].shipments]
                            : [...responseObj.shipments];

                        if (responseShipments.length) {
                            responseShipments.map((shipment, i) => {
                                let tempShipment = { ...shipment };
                                let tempShipmentLoads = [...shipment.loads];

                                shipment.loads.map((load, x) => {
                                    let tempLoad = { ...load };

                                    productsToBeShipped.map((product) => {
                                        if (load.productSku === product.productSku) {
                                            tempLoad = {
                                                ...tempLoad,
                                                name: product.name,
                                                description: product.description,
                                                graphicId: product.graphicId,
                                                graphicVersion: product.graphicVersion,
                                                reference: tempLoad.referenceNumber,
                                                type: product.type,
                                                size: product.size,
                                                coating: product.coating,
                                                shape: product.shape,
                                                neckDiameter: product.neckDiameter,
                                                endProfile: product.endProfile,
                                                tabColor: product.tabColor,
                                                unit: product.unit,
                                                customerProductId: product.customerProductId,
                                                customerProductName: product.customerProductName,
                                                displayId: product.customerProductId
                                                    ? product.customerProductId
                                                    : product.productSku,
                                                displayName: product.customerProductName
                                                    ? product.customerProductName
                                                    : product.name,
                                                graphicIdAndVersion: product.graphicIdAndVersion,
                                                purchaseOrderNumber: tempLoad.purchaseOrderNumber,
                                                deliveryInstructions: tempLoad.deliveryInstructions
                                            };
                                            tempShipmentLoads.splice(x, 1, tempLoad);
                                            if (
                                                tempLoad.type === 'CAN' ||
                                                tempLoad.type === 'BOTTLE'
                                            ) {
                                                tempShipment.shipmentType = 'Cans/Bottles';
                                            } else {
                                                tempShipment.shipmentType = 'Ends/Closures';
                                            }
                                        }
                                        return null;
                                    });
                                    return null;
                                });
                                shipment.shipmentType = tempShipment.shipmentType;
                                tempShipment = { ...shipment, loads: tempShipmentLoads };
                                responseShipments.splice(i, 1, tempShipment);
                                return null;
                            });
                        }

                        responseObj = { ...responseObj, shipments: responseShipments };
                        responseObj.customerPickup = customerPickUp;
                        dispatch(updateDeliveryOrder(responseObj));
                        setCurrentShipments(responseShipments);
                        history.push(orderSummaryLink);
                    })
                    .catch((error) => {
                        dispatch(updateDeliveryOrderUpdateError(error));
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            }
        } else {
            setIsSubmitting(false);
            setImpersonationWarning(true);
        }
    };

    const handleCancelOrder = () => {
        if (pristineDeliveryOrder) {
            setIsSubmitting(true);
            let cancelledDeliveryOrder = pristineDeliveryOrder;
            cancelledDeliveryOrder.status = Order_Status.CancelledPending;
            OrdersService.updateShipmentDeliveryOrder(accessToken, cancelledDeliveryOrder)
                .then((response) => {
                    history.push(orderSummariesLink);
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    dispatch(updateDeliveryOrderUpdateError(error));
                });
        }
    };

    const onCancel = () => {
        setCancelModalOpen(true);
    };

    const onCloseConfirmation = () => {
        setCancelModalOpen(false);
    };

    const handleBackNavigation = () => {
        history.push(createOrderLink);
    };

    const onConfirmedCancel = () => {
        dispatch(resetOrderingProcess());
        setCancelModalOpen(false);
        history.push(dashboardLink);
    };

    const handleConfirmHeaderChanges = (data) => {
        dispatch(setOrderInformation(data));
        dispatch(updateOrderDate(moment(data.deliveryDate)));
    };

    const isUniqueDateTime = (newSelectedDateTime: Moment) => {
        if (currentShipments && currentShipments?.length > 0) {
            for (let shipmentIndex = 0; shipmentIndex < currentShipments.length; shipmentIndex++) {
                let dateTimeToCompare = moment(currentShipments[shipmentIndex].deliveryDateTime);

                if (dateTimeToCompare.isSame(newSelectedDateTime)) {
                    return false;
                }
            }
        }
        return true;
    };

    const onSaveDraft = () => {
        setOpenBlockedNavigationModal(false);
        setOpenSaveDraftModal(true);
    };

    const onCloseBlockedNavigationModal = () => {
        setOpenBlockedNavigationModal(false);
    };

    const onClose = () => {
        setOpenSaveDraftModal(false);
    };

    const footerActions = (
        <>
            <Grid container item xs={3} ref={footerRef}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>

                {(isDraft || !isEdit) && (
                    <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        data-testid="save-draft-btn"
                        onClick={onSaveDraft}
                        className={classes.actionBtn}
                        startIcon={<SaveIcon />}
                        disabled={!isFormValid}
                    >
                        <Trans i18nKey="saveDraft">Save Draft</Trans>
                    </Button>
                )}
                {!isEdit && (
                    <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        data-testid="back-btn"
                        onClick={handleBackNavigation}
                        startIcon={<ArrowBackIcon />}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                )}
            </Grid>
            <Grid container item xs={5} justify="center">
                {someCurrentlyActiveDocumentIsUnapproved && (
                    <NotificationBanner
                        notificationMessage={t(
                            'pricingWarningText',
                            `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`
                        )}
                        notificationType={'WARNING'}
                    />
                )}
                {impersonationWarning && (
                    <Grid container item xs={7}>
                        <ImpersonationWarning
                            showWarning={impersonationWarning}
                            warningType={'SUBMIT'}
                        />
                    </Grid>
                )}
                {!someCurrentlyActiveDocumentIsUnapproved && !impersonationWarning && (
                    <Typography className={classes.infoText}>
                        <Trans i18nKey="rememberReviewShipments">
                            Remember to review shipments for this order prior to Submitting
                        </Trans>
                    </Typography>
                )}
            </Grid>
            <Grid container item xs={4} justify="flex-end" spacing={4}>
                <InformationalCard />
                <Grid item alignContent="center">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="submit-btn"
                        onClick={() => handleSubmit(false)}
                        className={classes.actionBtn}
                        disabled={
                            !isFormValid ||
                            someCurrentlyActiveDocumentIsUnapproved ||
                            focusedIndex !== -1
                        }
                    >
                        <Trans i18nKey="submit">Submit</Trans>
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: !isEdit ? t('Create Order', 'Create Order') : t('Edit Order', 'Edit Order'),
                description: !isEdit
                    ? t('Configure Your Shipments', 'Configure Your Shipments')
                    : t('Edit Delivery Order', 'Edit Delivery Order'),
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.CreateShipments}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            loading={loadingOrder || loadingProducts}
        >
            <div className={classes.root}>
                <div className={classes.content}>
                    <Grid container spacing={1} className={classes.gridContainer}>
                        <Grid item xs={12} md={5} className={classes.pane}>
                            <div className={classes.sectionHeader}>
                                <Button
                                    className={classes.actionBtn}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleAddShipmentToOrder}
                                    disabled={focusedIndex !== -1}
                                >
                                    <Trans i18nKey="addShipment">Add Shipment</Trans>
                                </Button>
                                <Typography className={classes.availableProductsText}>
                                    {availableProductsDate}
                                </Typography>
                            </div>
                            <div style={{ flex: 1, overflow: 'hidden' }}>
                                <OrderProductGrid
                                    products={productsToBeShipped}
                                    IsAnyShipmentEditing={focusedIndex !== -1}
                                    onAddProductToShipment={handleAddProductToShipment}
                                    editingType={editingShipmentType}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.pane}>
                            <div className={classes.sectionHeader}>
                                <OrderInfoCard
                                    isEdit={isEdit}
                                    onCancelOrder={handleCancelOrder}
                                    onConfirmChanges={handleConfirmHeaderChanges}
                                />
                            </div>
                            <div
                                className={classes.sectionHeader}
                                ref={shipmentContainerRef}
                                style={{ height: `calc(100vh - ${shipmentContainerHeight}px)` }}
                            >
                                {currentShipments && currentShipments.length > 0 ? (
                                    <Grid className={classes.shipmentsContainer}>
                                        {currentShipments.map((shipment, index) => (
                                            <ShipmentCard
                                                key={shipment.id}
                                                shipment={shipment}
                                                shipmentIndex={index}
                                                isEditShipment={index === focusedIndex}
                                                onEditShipment={handleEditShipment}
                                                onSaveShipment={handleSaveShipmentChanges}
                                                onDeleteShipment={handleDeleteShipment}
                                                isUniqueDateTime={isUniqueDateTime}
                                                deliveryTimeList={selectedTimesList}
                                                onUpdateLoads={handleUpdateLoads}
                                                onRemoveLoads={handleRemoveLoads}
                                            />
                                        ))}
                                    </Grid>
                                ) : (
                                    <Grid container className={classes.shipmentsContainer}>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            alignItems="center"
                                            justify="center"
                                            direction="column"
                                        >
                                            <Typography className={classes.emptyShipmentText}>
                                                <Trans i18nKey="selectToAddShipmentPickup">
                                                    Select to add shipment or pickup
                                                </Trans>
                                            </Typography>
                                            <Typography className={classes.emptyShipmentText}>
                                                <Trans i18nKey="youMayAddMoreShipments">
                                                    You may add more than one shipment to this order
                                                </Trans>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {isSubmitting && (
                <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            <OrderCancelModal
                open={cancelModalOpen}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCancel}
            />
            <SaveDraftModal
                data-testid="save-draft-modal"
                onClose={onClose}
                open={openSaveDraftModal}
                onCancel={onCloseBlockedNavigationModal}
                setClose={setOpenSaveDraftModal}
                setAllowNavigation={setAllowNavigation}
                isShipment={true}
                handleShipmentSubmit={(draftName) => handleSubmit(true, draftName)}
            />
        </ProcessingPageTemplate>
    );
}
