import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class EditShipmentsAddProductColumns {
    static getAddProductColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('availablePallets', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'availBalance'),
                visible: false
            }),
            createColumn('availableItemsPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                label: ' '
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('previousPalletTotals', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shape', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('size', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('neckDiameter', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('endProfile', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('tabColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('unit', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('orderedPallets', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            })
        ];
    };
}
