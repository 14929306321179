import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';
import {
    getJoiningAccount,
    getOnboardingAccountStatusError,
    getOnboardingAccountStatusLoading,
    getOnboardingAccountStatusSuccess,
    setAuth,
    setPermissions
} from '../../../store/root-actions';
import { UserPermission } from '../../../store/reducers/auth';
import UserService from '../../../utility/services/user-service';
import JoiningService, { OnboardingStepStatus } from '../../../utility/services/onboarding-service';
import {
    hasJoiningPersona,
    hasJoiningPersonaAuth,
    Persona
} from '../../../utility/auth/useSecurity';
import { useOktaAuth } from '@okta/okta-react';
import { CircularProgress } from '@material-ui/core';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import { OnboardingDashboardState } from '../../../store/reducers/onboarding-dashboard';
import { JoiningStatus } from '../../../utility/services/onboarding-service';
import { ErrorStatus } from '../../../utility/helpers/axios-helpers';
import { makeStyles } from '@material-ui/core';
import { getUserConfiguration } from '../../../store/actions/user-configuration';

const HomepageCallback = () => {
    const dispatch = useDispatch();
    var initialPermisions: UserPermission[] = [];

    const { authState, authService } = useOktaAuth();
    const { isCustomer, permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { accountStatus } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );

    const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(true);
    const [isLoadingAccountStatus, setIsLoadingAccountStatus] = useState<boolean>(true);
    const [isJoining, setIsJoining] = useState<boolean>(false);
    const [userPermissions, setUserPermissions] = useState(initialPermisions);
    const [userInfo, setUserInfo] = useState<UserInfo>();

    const createAxiosHeader = (accessToken?: any) => {
        const headers = {
            Authorization: accessToken ? `Bearer ${accessToken}` : ''
        };

        return {
            headers
        };
    };

    const useStyles = makeStyles((theme) => ({
        loadingContainer: {
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }));

    const classes = useStyles();

    const fetchAccountStatus = useCallback(
        (accessToken: string, permissions: UserPermission[]) => {
            dispatch(getOnboardingAccountStatusLoading());
            const joiningAccountId = getJoiningAccount(permissions);

            JoiningService.getOnboardingAccountStatus(accessToken, joiningAccountId)
                .then((resp) => {
                    if (resp.status === ErrorStatus.NoContent) {
                        dispatch(
                            getOnboardingAccountStatusSuccess({
                                accountId: joiningAccountId,
                                addressesStatus: OnboardingStepStatus.INCOMPLETE,
                                taxAppStatus: OnboardingStepStatus.INCOMPLETE,
                                creditAppStatus: OnboardingStepStatus.INCOMPLETE,
                                termsStatus: OnboardingStepStatus.INCOMPLETE,
                                joiningStatus: OnboardingStepStatus.INCOMPLETE,
                                eightStepStatus: OnboardingStepStatus.INCOMPLETE
                            })
                        );
                        setIsLoadingAccountStatus(false);
                    } else {
                        dispatch(getOnboardingAccountStatusSuccess(resp.data));
                        setIsLoadingAccountStatus(false);
                    }
                })
                .catch((err) => {
                    dispatch(getOnboardingAccountStatusError(err));
                    setIsLoadingAccountStatus(false);
                });
        },
        [dispatch]
    );

    const fetchPermissions = useCallback(() => {
        authService.getUser().then((user: UserInfo) => {
            UserService.getPermissions(user, createAxiosHeader(authState.accessToken)).then(
                (response) => {
                    dispatch(getUserConfiguration());
                    const permissions = response.data.permissions;
                    const isNotActive = response.data.isActive === false ? true : false;
                    if (isNotActive) {
                        localStorage.setItem('inactive', 'true');
                        authService.logout('/source');
                        return dispatch(setAuth(false, null, ''));
                    }
                    setUserPermissions(permissions);
                    setUserInfo(user);
                    setIsLoadingPermissions(false);
                    let userIsJoining = permissions.length
                        ? hasJoiningPersona(permissions) && regionCode === Region.NA
                        : false;
                    if (userIsJoining) {
                        fetchAccountStatus(authState.accessToken, permissions);
                    } else {
                        setIsLoadingAccountStatus(false);
                    }
                    setIsJoining(userIsJoining);
                    return dispatch(setPermissions(permissions));
                }
            );
        });
    }, [authService, authState.accessToken, dispatch, regionCode, fetchAccountStatus]);

    const hasPersona = (permissions: UserPermission[] | null, persona: Persona) => {
        if (permissions && permissions?.length) {
            for (const permission of permissions) {
                if (permission.personaId === persona) {
                    return true;
                }
            }
        }

        return false;
    };

    useEffect(() => {
        if (authState.isAuthenticated) {
            fetchPermissions();
        }
    }, [authState, permissions, fetchPermissions, dispatch]);

    if (isLoadingAccountStatus || isLoadingPermissions || (isJoining && !accountStatus.accountId)) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress size={100} thickness={1.8} />
            </div>
        );
    } else {
        if (
            (accountStatus.joiningStatus === JoiningStatus.INCOMPLETE &&
                (hasJoiningPersonaAuth(authState) ||
                    hasJoiningPersona(userPermissions) ||
                    hasJoiningPersona(permissions))) ||
            hasPersona(userPermissions, Persona.JoiningAdmin) ||
            (hasPersona(permissions, Persona.JoiningAdmin) && regionCode === Region.NA)
        ) {
            // If joining flag = true AND user has a joining persona
            // OR if user has joining admin persona, redirect to joining dashboard
            return <Redirect from="/source" to="/onboard" />;
        } else if (
            accountStatus.joiningStatus !== JoiningStatus.INCOMPLETE &&
            (hasJoiningPersonaAuth(authState) ||
                hasJoiningPersona(userPermissions) ||
                hasJoiningPersona(permissions)) &&
            !isCustomer
        ) {
            // If joining flag = false AND user has a joining persona
            // AND user has no customer personas, redirect to GLP
            return <Redirect from="/source" to="/welcome" />;
        } else if (
            userPermissions.length &&
            userPermissions.every((p) => p.personaId === Persona.GraphicsVendor)
        ) {
            return (
                <Redirect
                    from="/source"
                    to={regionCode === Region.EU ? '/emea-graphics-intake' : '/graphics-summary'}
                />
            );
        } else if (
            (isCustomer || !!userPermissions.length) &&
            userInfo?.Custom_Region === Region.NA
        ) {
            return <Redirect from="/source" to="/dashboard" />;
        } else {
            return <Redirect from="/source" to="/welcome" />;
        }
    }
};

export default HomepageCallback;
