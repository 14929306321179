import { saveState } from '../../utility/helpers/sessionStorage';
import {
    CUSTOMER_SELECT_ACCOUNT,
    CUSTOMER_LOADED,
    CUSTOMER_LOADING,
    CUSTOMER_LOADING_ERROR,
    CUSTOMER_SHIP_TOS_LOADED,
    CUSTOMER_SHIP_TOS_LOADING,
    CUSTOMER_SHIP_TOS_LOADING_ERROR,
    CUSTOMER_NO_SHIP_TOS_LOADED,
    CUSTOMER_LAST_BILL_TO,
    CUSTOMER_LAST_SHIP_TO,
    CUSTOMER_SELECT_CHILDREN_ACCOUNT,
    CUSTOMER_UPDATE_ORDERING_FLAG
} from '../actions/action-types';

//CX - Hybrid - Potential to be both a Biller & a Ship To
//CB - Bill To Account (parent accounts)
//CS - Ship To Accounts
//CI - Inactive Ship To Accounts
//CP - Parent Level
//CG - For graphics
//HA - Highest level acct
export enum AddressType {
    Unknown = 'UNKNOWN',
    CX = 'CX',
    CB = 'CB',
    CS = 'CS',
    CP = 'CP',
    CG = 'CG'
}

export enum AccountType {
    BE = 'BE',
    RCE = 'RCE',
    W = 'W',
    F = 'F'
}

export enum Status {
    Unknown = 'Unknown',
    Active = 'Active',
    Inactive = 'Inactive'
}

// UA - CIA account
export enum PaymentTerms {
    Unknown = 'Unknown',
    CIA = 'CashInAdvance',
    CIATag = 'CIA',
    Credit = 'Credit',
    CIA_Credit = 'CIA_Credit'
}

export enum ProdOrderType {
    Unknown = 'Unknown',
    DirectToPO = 'DPO',
    Forecast = 'FOR',
    AuthorizationToManufacture = 'ATM'
}

export enum CustomerType {
    Contract = 'CONTRACT',
    Spot = 'SPOT',
    Negotiated = 'NEGOTIATED'
}

export type MetaContentCustomerType = 'Contract' | 'Spot' | 'Negotiated' | '';

export enum CreditHold {
    C1 = 'C1', // Credit Customers
    C2 = 'C2' // Cash in Advance Customers
}

export enum ModeOfTransport {
    Delivery = 'TMS',
    CustomerPickUp = 'CPU'
}
export interface Account {
    accountId: string;
    parentAccountId?: string;
    deliverToAccountId?: string;
    name: string;
    addressType: AddressType;
    status: Status;
    paymentTerms: PaymentTerms;
    accountType: AccountType;
    accountTypeDesc: string;
    prodOrderType?: ProdOrderType;
    modeOfTransport: string;
    address: {
        address1?: string;
        address2?: string;
        address3?: string;
        address4?: string;
        city?: string;
        stateProvince?: string;
        postalCode?: string;
        country?: string;
    };
    customerType: CustomerType;
    subAccounts?: Account[];
    parentAccount?: Account;
    shipToAccounts?: Account[];
    creditHold?: string;
    deliveryLeadTimeDays: number;
    lockedPeriodDays?: number;
    listCustomer?: boolean;
    soldToAccount?: string;
    orderingV2?: boolean;
}

export interface FormattedShipToAccount extends Account {
    sortableAccountId: number;
    formattedAddress: string;
}

export interface SelectableAccount extends Account {
    hasMatch: boolean;
}

export interface DeliverToAccount {
    deliverToAccountId: string;
    address: {
        address1?: string;
        address2?: string;
        address3?: string;
        address4?: string;
        city?: string;
        stateProvince?: string;
        postalCode?: string;
        country?: string;
    };
    copackers: SelectableAccount[];
}

export interface CustomerContextState {
    accounts?: Account[];
    shipToAccounts?: Account[];
    adminAccounts?: Account[];
    adminAccountsLoaded?: boolean;
    selectedAccountId?: string;
    loading: boolean;
    loaded: boolean;
    error?: string;
    deliveryLeadTimeDays?: number;
    lastShipToIdSelected?: string;
    lastBillToIdSelected?: string;
    useOrderingV2?: boolean;
    selectedChildrenAccountId?: string;
}

const initialState: CustomerContextState = {
    accounts: [],
    shipToAccounts: [],
    selectedAccountId: '',
    loading: false,
    loaded: false,
    adminAccountsLoaded: false,
    useOrderingV2: false
};

const customerContext = (state = initialState, action: any): CustomerContextState => {
    switch (action.type) {
        case CUSTOMER_SELECT_ACCOUNT:
            saveState('selectedAccountId', action.selectedAccountId);
            return {
                ...state,
                selectedAccountId: action.selectedAccountId
            };
        case CUSTOMER_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                accounts: action.accounts,
                shipToAccounts: action.shipToAccounts
            };
        case CUSTOMER_LOADING:
            return { ...state, loading: true, loaded: false, error: undefined };
        case CUSTOMER_LOADING_ERROR:
            return { ...state, loading: false, loaded: true, error: action.error };
        case CUSTOMER_LAST_SHIP_TO:
            return {
                ...state,
                lastShipToIdSelected: action.lastShipToIdSelected
            };
        case CUSTOMER_LAST_BILL_TO:
            return {
                ...state,
                lastBillToIdSelected: action.lastBillToIdSelected
            };
        case CUSTOMER_SELECT_CHILDREN_ACCOUNT:
            saveState('selectedChildrenAccountId', action.selectedChildrenAccountId);
            return {
                ...state,
                selectedChildrenAccountId: action.selectedChildrenAccountId
            };

        case CUSTOMER_SHIP_TOS_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                accounts: action.accounts,
                shipToAccounts: action.shipToAccounts,
                adminAccounts: action.adminAccounts,
                adminAccountsLoaded: true
            };
        case CUSTOMER_NO_SHIP_TOS_LOADED: {
            return {
                ...state,
                loaded: true,
                loading: false,
                adminAccountsLoaded: true
            };
        }
        case CUSTOMER_SHIP_TOS_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false,
                adminAccountsLoaded: false,
                error: undefined
            };
        case CUSTOMER_SHIP_TOS_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                adminAccountsLoaded: true,
                error: action.error
            };
        case CUSTOMER_UPDATE_ORDERING_FLAG:
            return {
                ...state,
                useOrderingV2: action.useOrderingV2
            };
        default:
            return state;
    }
};

export default customerContext;
