import React from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { ProductToShip } from '../../../../store/reducers/ordering-dashboard';

const useStyles = makeStyles((theme) => ({
    container: {
        border: '1px solid #eee',
        borderRadius: '4px',
        padding: '8px'
    },
    textField: {
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: '.125em',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: '9px',
        color: 'gray',
        fontWeight: 'bold',
        marginTop: '0px'
    },
    alignLeft: {
        '& .MuiInputBase-input': {
            textAlign: 'left',
            paddingLeft: '0.375em'
        }
    },
    trash: {
        paddingRight: '0',
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: theme.palette.error.main
        }
    },
    centerText: {
        textAlign: 'center'
    },
    centerItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        fontSize: '8px',
        fontWeight: 'bold',
        color: 'gray'
    },
    loadSequenceDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    purpleLoadSequence: {
        backgroundColor: 'purple',
        width: '5px',
        height: '100%'
    },
    shippingInstructionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '8px',
        justifyContent: 'flex-end'
    },
    shippingInstructionsLabel: {
        fontSize: '11px',
        color: 'gray',
        fontWeight: 'bold',
        marginBottom: '4px',
        marginRight: '5px'
    },
    shippingInstructionsField: {
        width: '45%'
    }
}));

interface ShipmentRowProps {
    item: ProductToShip;
    sequence?: number;
    isEditShipment: boolean;
    onUpdateItem: (item: ProductToShip) => void;
    onRemoveItem: (item: ProductToShip) => void;
}

const ShipmentRow: React.FC<ShipmentRowProps> = ({
    item,
    sequence,
    isEditShipment,
    onUpdateItem,
    onRemoveItem
}) => {
    const classes = useStyles();

    const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue: any = event.target.value;
        if (field === 'palletQuantity') {
            newValue = Number(event.target.value);
        }
        const updatedItem = { ...item, [field]: newValue };
        onUpdateItem(updatedItem);
    };

    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    return (
        <div className={classes.container}>
            <Grid container spacing={1}>
                <Grid item xs={1} className={classes.loadSequenceDiv}>
                    <div className={classes.purpleLoadSequence} />
                    <span style={{ fontWeight: 'bold' }}>{sequence}</span>
                </Grid>
                <Grid item xs={2} className={classes.centerItems} direction="column">
                    <span style={{ fontWeight: 'bold' }}>{item.productSku}</span>
                    <span>{item.productId}</span>
                    <span>{item.graphicId}</span>
                </Grid>
                <Grid item xs={2} className={classes.centerItems} direction="column">
                    <span style={{ fontWeight: 'bold' }}>{item.customerProductName}</span>
                    <span>{item.description}</span>
                </Grid>
                <Grid item xs={1} className={classes.centerItems}>
                    {isEditShipment ? (
                        <TextField
                            value={item.palletQuantity}
                            onChange={handleFieldChange('palletQuantity')}
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                        />
                    ) : (
                        <span>{item.palletQuantity}</span>
                    )}
                </Grid>
                <Grid item xs={2} className={classes.centerItems}>
                    {isEditShipment ? (
                        <TextField
                            value={item.purchaseOrderNumber}
                            onChange={handleFieldChange('purchaseOrderNumber')}
                            variant="outlined"
                            fullWidth
                            required
                            className={classes.textField}
                        />
                    ) : (
                        <span>{item.purchaseOrderNumber}</span>
                    )}
                </Grid>
                <Grid item xs={1} className={classes.centerItems}>
                    {isEditShipment ? (
                        <TextField
                            value={item.releaseNumber}
                            onChange={handleFieldChange('releaseNumber')}
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                        />
                    ) : (
                        <span>{item.releaseNumber}</span>
                    )}
                </Grid>
                <Grid item xs={1} className={classes.centerItems}>
                    {isEditShipment ? (
                        <TextField
                            value={item.fillerLine}
                            onChange={handleFieldChange('fillerLine')}
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                        />
                    ) : (
                        <span>{item.fillerLine}</span>
                    )}
                </Grid>
                <Grid item xs={1} className={classes.centerItems}>
                    {isEditShipment ? (
                        <TextField
                            value={item.reference}
                            onChange={handleFieldChange('reference')}
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                        />
                    ) : (
                        <span>{item.reference}</span>
                    )}
                </Grid>
                <Grid item xs={1} className={classes.centerItems}>
                    {isEditShipment && (
                        <IconButton className={classes.trash} onClick={handleRemoveItem}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.shippingInstructionsContainer}>
                    <span className={classes.shippingInstructionsLabel}>Shipping Instructions</span>
                    <TextField
                        value={item.deliveryInstructions}
                        onChange={handleFieldChange('deliveryInstructions')}
                        variant="outlined"
                        fullWidth
                        className={classes.shippingInstructionsField}
                        disabled={!isEditShipment}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ShipmentRow;
