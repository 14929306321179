import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import BulkUploadMappingTemplate from '../../reusable/molecules/BulkUploadMappingTemplate';
import UploadDeliveryOrder from './components/UploadDeliveryOrder';
import { AuthState } from '../../../store/reducers/auth';
import { BulkUploadDeliveryState, TemplateMapping } from '../../../store/reducers/bulk-upload';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    loadBulkUploadDelivery,
    reviewDeliveryBulkUpload,
    resetBulkUploadState,
    removeShipItBulkOrder,
    resetExpandableLoads
} from '../../../store/root-actions';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';
import { OrderType } from '../../../utility/services/orders-service';
import { FlagTypes, useFeatureFlag } from '../../../utility/helpers/feature-flag';

export interface BulkUploadFieldDefinition {
    [name: string]: FieldProperties;
}

export interface FieldProperties {
    defaultLabel: string;
    required: boolean;
    hide?: boolean;
    defaultValue: string;
    translationValue?: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em',
        display: 'flex',
        justifyContent: 'center'
    },
    formSection: {
        marginBottom: '2.5em',
        flex: '1 1 auto'
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function DeliveryBulkUpload() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { deliveryMappings, file, orders, lineErrors, loading, error } =
        useTypedSelector<BulkUploadDeliveryState>((state) => state.bulkUploadDelivery);
    const { accounts, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<TemplateMapping[]>([]);
    const [isValidFile, setIsValidFile] = useState<boolean>(false);
    const [hasTemplate, setHasTemplate] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(hasTemplate);
    const [openCancel, setOpenCancel] = useState<boolean>(false);
    const [displayServerError, setDisplayServerError] = useState<boolean>(false);
    const [fieldDefinitions, setFieldDefinitions] = useState<BulkUploadFieldDefinition>();

    const cokeFeatureFlag = useFeatureFlag().find((flag) => flag.name === FlagTypes.Coke)?.isActive;

    const onSubmit = () => {
        if (file && userInfo) {
            dispatch(reviewDeliveryBulkUpload(userInfo.preferred_username, file));
            setIsSubmitting(true);
        }
    };

    const onOpenCancelModal = () => {
        setOpenCancel(true);
    };

    const onCancel = () => {
        setOpenCancel(false);
        dispatch(resetBulkUploadState());
        history.push('/');
    };

    const onClose = () => {
        setOpenCancel(false);
    };

    const setEditState = useCallback((edit: boolean) => {
        setIsEdit(edit);
    }, []);

    useEffect(() => {
        if (!deliveryMappings && userInfo) {
            dispatch(loadBulkUploadDelivery(userInfo.preferred_username));
        }
        if (deliveryMappings) {
            setHasTemplate(deliveryMappings.length > 0);
            setTemplateData(deliveryMappings);
        }
    }, [dispatch, deliveryMappings, userInfo]);

    useEffect(() => {
        setIsSubmitting(false);
        if (orders && (!lineErrors || lineErrors.length === 0) && isSubmitting) {
            // make sure this part of state is clear to avoid weird bugs
            dispatch(resetExpandableLoads());
            history.push('/ship-it-bulk-upload-review');
        }
    }, [orders, lineErrors]);

    useEffect(() => {
        let isLargeCustomer = false;
        if (shipToAccounts && shipToAccounts.length > 0) {
            shipToAccounts.forEach((account) => {
                //if at least one of the accounts is considered a "Big" account
                if (
                    account.prodOrderType === ProdOrderType.AuthorizationToManufacture &&
                    !isLargeCustomer
                ) {
                    isLargeCustomer = true;
                }
            });
            setFieldDefinitions({
                shipToId: { defaultLabel: 'Ship To ID', defaultValue: '', required: true },
                productId: { defaultLabel: 'Product ID', defaultValue: '', required: true },
                quantity: { defaultLabel: 'Quantity', defaultValue: '', required: true },
                deliveryDate: { defaultLabel: 'Delivery Date', defaultValue: '', required: true },
                deliveryTime: { defaultLabel: 'Delivery Time', defaultValue: '', required: true },
                purchaseOrderNumber: {
                    defaultLabel: 'Customer PO #',
                    defaultValue: '',
                    required: isLargeCustomer ? isLargeCustomer : false,
                    translationValue: 'customerPoNumber',
                    hide: !cokeFeatureFlag
                },
                deliveryInstructions: {
                    defaultLabel: 'Delivery Instructions',
                    defaultValue: '',
                    required: false
                },
                releaseNumber: {
                    defaultLabel: 'Release Number',
                    defaultValue: '',
                    required: false
                },
                fillerLine: {
                    defaultLabel: 'Filler Line Number',
                    defaultValue: '',
                    required: false
                },
                referenceNumber: {
                    defaultLabel: 'Reference Number',
                    defaultValue: '',
                    required: false
                }
            });
        }
    }, [shipToAccounts]);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    useEffect(() => {
        if (error) {
            setIsSubmitting(false);
            setDisplayServerError(true);
        } else {
            setDisplayServerError(false);
        }
    }, [error]);

    //always remove orders in state on first load, if they exist
    useEffect(() => {
        if (orders && orders.length) {
            dispatch(removeShipItBulkOrder());
        }
    }, []);

    const footerActions = (
        <>
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="review-button"
                    className={classes.actionBtn}
                    onClick={onSubmit}
                    disabled={!hasTemplate || !isValidFile || isSubmitting}
                >
                    <Trans i18nKey="uploadFile">Upload File</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('bulkUpload', 'Bulk Upload'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.ShipItBulkUpload}
            shipTos={false}
            loading={loading}
        >
            <Grid container justify="space-between" data-testid="ship-it-bulk-upload-page">
                <Container className={classes.container}>
                    <Grid container item xs={8} className={classes.formSectionContainer}>
                        <Grid item xs={12} className={classes.formSection}>
                            {fieldDefinitions && (
                                <BulkUploadMappingTemplate
                                    hasTemplate={hasTemplate}
                                    templateData={templateData}
                                    templateType={OrderType.Delivery}
                                    fieldDefinitions={fieldDefinitions}
                                    isEdit={isEdit}
                                    setIsEdit={setEditState}
                                />
                            )}
                        </Grid>
                        <Grid>
                            <UploadDeliveryOrder
                                isEdit={isEdit}
                                isValidFile={isValidFile}
                                setIsValidFile={setIsValidFile}
                            />
                        </Grid>
                    </Grid>
                </Container>
                {displayServerError && (
                    <Grid container item xs={12} justify="center">
                        <Typography>
                            <Trans i18nKey="pleaseReviewCsvData">
                                There has been an error with processing the CSV data. Please double
                                check the column types are set correctly.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                {isSubmitting && (
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
            <ConfirmationModal
                saveProgress={onCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancelSubmission">Cancel Submissions</Trans>}
                subheader={<Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>}
                description={
                    <Trans i18nKey="lostFile">
                        Any file uploaded or unsaved mappings will be permanently gone.
                    </Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancel}
                navigationLink=""
                onCancel={onClose}
            />
        </ProcessingPageTemplate>
    );
}
