export const enUS = {
    // Reusable
    cans: 'Cans',
    view: 'View',
    design: 'Design',
    filling: 'Filling',
    canHandling: 'Can Handling',
    regulations: 'Regulations',
    labLiquid: 'Lab Liquid',
    sustainability: 'Sustainability',
    faq: 'Frequently Asked Questions',
    designInspiration: 'Design Inspiration',
    backToAllProducts: 'Back to All Products',
    shipment: 'Shipment',
    shipments: 'Shipments',
    sku: 'SKU',
    skus: 'SKUs',
    cancelled: 'Cancelled',
    edited: 'Edited',
    revisedDate: 'Revised Date',
    selectShipTo: 'Select Ship To',
    selectBillTo: 'Select Bill To',
    selectAccount: 'Select Account',
    selectSoldTo: 'Select Sold To',
    soldTos: 'Sold Tos',
    account: 'Account',
    searchForShipTo: 'Search for Ship-to',
    searchForBillTo: 'Search for Bill-to',
    searchForSoldTo: 'Search for Sold-to',
    searchForAccount: 'Search for Account',
    new: 'New',
    existing: 'Existing',
    unknown: 'Unknown',
    underReview: 'Under Review',
    complete: 'Complete',
    submitted: 'Submitted',
    summary: 'Summary',
    inactive: 'Inactive',
    active: 'Active',

    // -- NAVIGATION -- //
    home: 'Home',
    homepage: 'My Homepage',
    ourProcess: 'Our Process',
    processOverview: 'Process Overview',
    labDashLiquid: 'Lab / Liquid',
    handling: 'Handling',
    regulatory: 'Regulatory',
    ourProducts: 'Our Products',
    productPortfolio: 'Product Portfolio',
    contactUs: 'Contact Us',
    logout: 'Logout',
    backToPortfolio: 'Back to Portfolio',
    navigation: 'Navigation',
    dashboard: 'Dashboard',
    payables: 'Payables',
    discoverBall: 'Discover Ball',
    productionOrderSummary: 'Production Order Summary',
    shipmentSummary: 'Shipment Summary',
    paymentsSummary: 'Payments Summary',
    deliveryOrderSummary: 'Delivery Order Summary',
    ourProcessLearningContent: 'Our Process and Learning Content',
    howToGuides: 'How To Guides for the Source',
    guided: 'Guided',
    buildAndEstimateNav: 'Build & Estimate',
    buildNav: 'Build',
    graphics: 'Graphics',
    overview: 'Overview',
    theProcess: 'The process',
    ballProductPortfolio: 'Ball Product Portfolio',
    guidesLink: 'How To Guides',
    faqLink: 'FAQ',
    makeItSummary: 'Make It Summary',
    shipItSummary: 'Ship It Summary',
    tradeItRequests: 'Trade It Requests',
    scrapItRequests: 'Scrap It Requests',
    tradeItRequestOrderNumber: 'Trade It Request Order Number',
    submitNewGraphicLink: 'Submit New Graphic',
    submitNewLiquidTestLink: 'Submit New Liquid Test',
    submittedLiquidTests: 'Submitted Liquid Tests',
    openLiquidTests: 'Open Liquid Tests',
    admin: 'Admin',
    userAdmin: 'User Admin',
    manageUsers: 'Manage Users',
    manageLocations: 'Manage Locations',
    addShipTo: 'Add Ship To',
    customerInformation: 'Customer Information',
    newShipToInformation: 'New Ship To Information',
    addNewUser: 'Add New User',
    editUser: 'Edit User',
    viewEditUser: 'View/Edit User',
    lastLogin: 'Last Login',
    myAccount: 'My Account',
    pendingAccessRequests: 'Pending Access Requests',
    myAccess: 'My Access',
    graphicsInProgress: 'Graphics in Progress',
    exit: 'Exit',
    authorizationToManufacture: 'Authorization to Manufacture',
    comingSoon: '<i>Coming Soon<i/>',
    makeItDrafts: 'Make It Drafts',
    checkPendingRequests: 'Check pending requests and registration and manage active users.',
    monitorAndRequest: 'Monitor and request new locations.',
    giveAccountAccess: 'Give account access to new users.',
    viewPricingInformation: 'View pricing information.',
    viewActiveAndInactiveProducts: 'View active and inactive products and manage product statuses.',
    buildSubmitUpdate: 'Build, submit, and update your order forecasts.',
    createMakeItOrders: 'Create Make It orders.',
    scheduleOrders: 'Schedule delivery orders and pickups.',
    learnMoreGraphics:
        'Learn more about color separation, artwork submission, and file preparation.',
    ensureHandling: 'Ensure your team knows how to handle your cans once they’ve arrived.',
    whyTesting: 'Why testing your beverage for compatibility is critical for the canning process.',
    bestCanningPractices: 'Best practices for canning your liquid.',
    learnAboutRegulations: 'Learn about country specific regulations.',
    helpPromoteSustainability: 'Helping you promote your brand’s effort around sustainability.',

    // -- CUSTOMER SATISFACTION SURVEY -- //
    customerSatisfactionSurvey: 'Customer Satisfaction Survey',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Welcome to <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> is your Company’s one-stop shop for Ball Beverage from graphics to delivery.  Depending on your needs, this page has links that allow you to place "Make It" Production Orders, "Ship It" Delivery Orders, and/or manage your Graphic Intake process.  Check back often for new links and features as we continue to upgrade your experience.',
    readyToOrder: 'Ready to order products?',
    makeIt: 'Make It',
    readyToSchedule: 'Ready to schedule a shipment?',
    shipIt: 'Ship It',

    // -- CUSTOMER DASHBOARD -- //
    nextWeek: 'Next Week',
    twoToFourWeeks: 'In 2-4 Weeks',
    fiveToEightWeeks: 'In 5-8 Weeks',
    ninePlusWeeks: 'In 9+ Weeks',
    orderSummary: 'Order Summary',
    openOrders: 'Open Orders',
    agedBalance: 'Aged Balance',
    agedSku: 'Aged SKU',
    agedSkus: 'Aged SKUs',
    draftOrders: 'Draft Orders',
    paymentsPastDue: 'Payments Past Due',
    makeItOrders: 'Make It Orders',
    approachingPayments: 'Approaching Payments',
    createNewShipItOrder: 'Create New Ship It Order',
    createMakeIt: 'Create and update Make It orders. Check the status of your Make It orders.',
    createNewMakeItOrder: 'Create New Make It Order',
    createMakeItShort: 'Create Make It',
    dataIsFiltered: 'Data is filtered. Click icon to clear filter: ',
    viewProductionBalances:
        'View production balances and scheduled shipments for up to sixteen weeks.',
    draftShipments: 'Draft Shipments',
    inventory: 'Inventory',
    viewInventory: 'View Inventory',
    saleablePallets: 'Saleable Pallets',
    committedPallets: 'Committed Pallets',
    agedInventory: 'Aged Inventory',

    // -- GRAPHICS WIDGET -- //
    startGraphicsProcess:
        'Start the graphics process by uploading a new graphic for your products.',
    newGraphicLink: 'Start New Graphic',
    checkGraphicsProcess:
        'Check to see where your graphics are in the process and if there are any actions you need to take.',
    viewInProgressGraphics: 'View In Progress Graphics',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'User Administration',
    setupNewUser:
        'Setup a new user with the correct permissions and locations in <i>The Source</i>.',
    userAdminDashboard: 'User Admin Dashboard',
    viewPendingRequests:
        'View pending access requests, pending new user requests, and active users in <i>The Source</i>.',
    viewUserAdminDashboard: 'View User Admin Dashboard',

    // -- ORDERING WIDGET -- //
    viewProductPlanning: 'View Product Planning',
    productPlanningTileDesc:
        'View production balances and scheduled shipments for up to sixteen weeks.',
    makeProducts: 'Make Products',
    makeProductsTileDesc:
        'Create and update Make It orders. Check the status of your Make It orders. ',
    viewEditMakeItOrders: 'View/Edit Make It Orders',
    shipProducts: 'Ship Products',
    shipProductsTileDesc:
        'Schedule and update Ship It orders and pick ups. Check the status of your Ship It orders. ',
    viewEditShipItOrders: 'View/Edit Ship It Orders',
    shipItOrders: 'Ship It Orders',
    shipmentsSummary: 'Shipments Summary',
    numOfPallets: 'No. of Pallets',
    arrivingThisWeek: 'Arriving This Week',
    futureShipments: 'Future Shipments',
    viewAllFutureShipments: 'View All Future Shipments',

    // -- FAQ Page -- //
    searchFAQs: 'Search Frequently Asked Questions',
    searchFAQplaceholder: 'Cans, Bottles, etc',

    // -- QUICK VIEW -- //
    production: 'Production',
    limitedProductionRun: 'Limited Production Run',
    seeFullProductDtls: 'See Full Product Details',
    timesPerYear: 'Times Per Year',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Product Information',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Make It Lock Window',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'View My Product Information',
    customerProductId: 'Customer Product ID',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Ball Product ID',

    // -- PRODUCT PORTFOLIO -- //
    leadTimesSubjectToChange: '*All lead times are estimates and subject to change.',
    currentBallCustomers: 'Current Ball Customers:',
    leadTimesProduction:
        'Production lead times for new graphics begin when graphics are complete and approved.',
    leadTimesMayVarySixteenOzAlumitek:
        'This product is currently available. Lead times for receiving your first order may vary. Talk with your Sales Rep for additional details.',
    all: 'All',
    bottles: 'Bottles',
    ends: 'Ends',
    end: 'End',
    closures: 'Closures',
    closure: 'Closure',
    lookingForInspiration: 'Looking for Inspiration',
    pallets: 'Pallets',
    itemsPerPallet: 'Items Per Pallet',
    lookingForSomething: 'Looking for something different?',
    sendUsYourComments: 'Send us your comments',
    minOrderPallet: 'Minimum Order in Pallets',
    cansPerPallet: 'Cans Per Pallet',
    quickLook: 'Quick look',
    select: 'Select',
    seeDetailsBtn: 'See Full Product Details',
    compareProducts: 'Compare Products',
    pleaseSelectProducts: 'Please select products to get an estimate',
    myProject: 'My Project',
    myProjects: 'My Projects',
    belowAreYourSavedProjects: 'Below are your saved projects from the last 180 days.',
    viewProject: 'View Project',
    compareProductsBtn: 'Compare',
    build: 'Build',
    compare: 'Compare',
    projectName: 'Project Name',
    productCode: 'Product Code',
    savedOn: 'Saved On',
    capacityCheckStatus: 'Capacity Check Status',
    dateRequested: 'Date Requested',
    ropp38CappingText:
        'Product requires special capping. Please contact Silgan 765-983-9282 for pallet specifications.',

    // -- FACETS -- //
    filterBy: 'Filter By',
    clearAll: 'Clear All',
    // Facet Category
    stylesCategory: 'Product Range',
    sizesCategory: 'Size Range',
    featuresCategory: 'Product Enhancements',
    endsCategory: 'End Compatibility',
    // Facet Tooltips
    stylesTooltip: 'A family of products in similar shapes and sizes.',
    sizesTooltip: 'A range of size options to meet your distinct specifications.',
    featuresTooltip: 'Add-ons available for certain Ball products.',
    endsTooltip: 'List of ends that work with different Ball products.',
    style: 'Style',
    size: 'Size',
    features: 'Features',
    endTile: 'Ends/Closures',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Request an Estimate',
    productDetails: 'Product Details',
    printGuide: 'Print Guide',
    reproStudioGrid: 'Repro Studio Grid',
    customerDesignGuide: 'Customer Design Guide',
    download: 'Download',
    canSpecification: 'Can Specification',
    dieLine: 'Die Line',
    palletSpecifications: 'Pallet Specifications',
    greatFor: 'Great For',
    specifications: 'Specifications',
    currentLeadTime: 'Current Lead Time',
    fillingAvailability: 'Filling Availability',
    compatibleEnds: 'Compatible Ends',
    weeks: 'Weeks',
    months: 'Months',
    minimum: 'Minimum',
    productEnhancements: 'Product Enhancements',
    compatibleFilling: 'Compatible Filling',
    pasteurization: 'Pasteurization',
    retort: 'Retort',
    hotFill: 'Hot Fill',
    coldFill: 'Cold Fill',
    color: 'Color',
    aluminum: 'Aluminum',
    printingOptions: 'Printing Options: Printed Can, Sleeved Can, Bright Can',
    // Ordering
    ordering: 'Ordering',
    pricePerThousand: 'Price per 1,000',
    quantityPerPallet: 'Quantity per Pallet',
    minOrder: 'Minimum Order',
    // Design Specs
    designSpecs: 'Design Specifications',
    circumference: 'Circumference',
    designLength: 'Design Length (Circumference)',
    designHeight: 'Design Height',
    maxTALength: 'Max Text Area (Length)',
    maxTAHeight: 'Max Text Area (Height)',
    maximumColors: 'Maximum No. of Colors',
    windowLimitWidth: 'Window Limit Width',
    windowLimitHeight: 'Window Limit Height',
    underOverlap: 'Under/Overlap Area',
    // Can Specs
    canSpecs: 'Can Specifications',
    endSize: 'End Size',
    bodyOutsideDiameter: 'Body Outside Diameter',
    flangedCanHeight: 'Flanged Can Height',
    coldFillVolume: 'Cold Fill Volume',
    retortFillVolume: 'Retort Fill Volume',
    flangedCanWidth: 'Flange Width',
    endDiameter: 'End Diameter',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Can Effects & Features Available for this Can',
    canFinishesAndEffects: 'Can Finishes & Effects',
    inks: 'Inks',
    overvarnish: 'Overvarnish',
    effects: 'Effects',
    specialFeatures: 'Special Features',

    // -- END DETAILS -- //
    endDetails: 'End Details',
    endFinishesAndEffects: 'End Finishes & Effects',
    compatibleCans: 'Compatible Cans',
    endFeaturesAndEffects: 'End Features & Effects',
    orderIndividually: 'Can be ordered individually',
    roppClosures: 'ROPP Closures are sold via 3rd party',
    endsPack: 'Ends Information Pack',

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Add Product',
    details: 'Details',
    name: 'Name',
    type: 'Type',
    maxColors: 'Max Colors',
    innovativeFeatures: 'Innovative features',
    leadTimes: 'Lead Times',
    currentFillingAvail: 'Current filling availability',
    products: 'Products',
    addAnotherToCompare: 'Add Another Product to Compare',
    removeSelection: 'Remove Selection',
    addToCompare: 'Add to Compare',
    prodComparisonDesc:
        'Compare up to Three different products at a time, regardless of volume or style. See which products offer',
    prodEstimateDesc:
        'Our estimator can help give you a better understanding of the cost of ordering cans with us. Keep a few things in mind when creating your estimate',
    prodHasMinOrder: 'Each product has a minimum order',
    orderInFullPallets: 'Estimates and orders can only be placed in full pallets',
    orderInFullTrucks:
        'We recommend ordering in full truckloads, most trucks can fit about 25 pallets. Less than full truckloads may incur additional charges',
    productComparison: 'Product Comparison',
    compareBuildEstimate: 'Compare, Build & Estimate',
    compareBuild: 'Compare & Build',

    // -- SITE ADMIN -- //
    siteAdmin: 'Site Admin',

    // -- COMMUNICATION MANAGEMENT -- //
    manageCommunication: 'Manage Communication',
    configureCommunication: 'Configure Communication',
    message: 'Message',
    messageok: 'Click OK to continue',
    characterLeft: 'character left',
    charactersLeft: 'characters left',
    newMessage: 'New Message',
    addMessage: 'Add Message',
    messageName: 'Message Name',
    messageNameHelper: 'Name this message for future reference',
    timeZone: 'Time Zone',
    timeZoneHelper: 'Time is displayed in local time zone',
    timeZoneHelper2: 'All times displayed in local time',
    editMessage: 'Edit Message',
    enableMessage: 'Enable Message',
    disableMessage: 'DisableMessage',
    makeMessageActive: 'Make Message Active',
    makeMessageActiveConfirmation:
        'You are about to enable this message.<br />Are you sure you want to continue?',
    makeMessageInactive: 'Make Message Inactive',
    makeMessageInactiveConfirmation:
        'You are about to disable this message.<br />Are you sure you want to continue?',
    messageType: 'Message Type',
    internalExternalUsers: 'Internal/External Users',
    regions: 'Regions',
    pagesToDisplay: 'Pages To Display Message',
    manageCommunicationsSource: 'Manage Communications with the users of The Source.',
    startDateTime: 'Start Date/Time',
    endDateTime: 'End Date/Time',
    submitterNameHelper: 'Name of person submitting this form',
    endDateError: 'End date must be after start date',
    returnToSummary: 'Return to Summary',
    recipientTypeInternal: 'Internal',
    recipientTypeExternal: 'External',
    recipientTypeAll: 'Both',
    Banner: 'Banner',
    Popup: 'Popup',
    Email: 'Email',
    GlobalLandingPage: 'Global Landing Page',
    Dashboard: 'Dashboard',
    PlanItLandingPage: 'Plan It Landing Page',
    MakeItLandingPage: 'Make It Landing Page',
    ShipItLandingPage: 'Ship It Landing Page',
    ProductPlanning: 'Product Planning',
    PayableLandingPage: 'Payable Landing Page',
    MakeItDrafts: 'Make It Drafts',
    SubmitNewGraphics: 'Submit New Graphics',
    SubmitNewLiquidTest: 'Submit New Liquid Test',
    NCA: 'NCA',
    EMEA: 'EMEA',
    SA: 'SA',
    messageNotFound: "The message you're attempting to edit could not be found",
    correctAllErrors: 'Please correct all errors before submitting',
    sucessfulSubmission: 'Your message has been submitted successfully',
    sucessfulMessageUpdate: 'Your message has been updated',
    errorOccurred: 'An error occurred while processing your request',

    // -- CONTACT FORM -- //
    howCanWeHelp: 'How Can We Help?',
    yourName: 'Your Name',
    email: 'Email',
    yourCountry: 'Your Country of Fill',
    yourCity: 'Your City of Fill',
    subjectLine: 'Subject Line',
    contactMessage: 'Tell us how we can help...',
    orderFailedMessage: 'The system was unable to place your order. Please try again later.',
    submit: 'Submit',
    cancel: 'Cancel',
    saveDraft: 'Save Draft',
    sendUsANote: 'Send us a note on what new products you would like to see in the future',
    msgSuccessful: 'Message Successfully Sent.',
    msgFailed: 'Message failed to send, please try again at a different time.',
    sending: 'Sending',
    close: 'Close',
    contactReason: 'Contact Reason',
    contact: 'I have a question',
    support: 'I need help',
    cantLogIn: "I can't log in",

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Get an Estimate',
    desiredAnnualQuantity: 'Desired Annual Quantity',
    enterQuantity: 'Enter Quantity',
    // wouldYouLikeEndsWithThisProduct: 'Would you like ends with this product?',
    remove: 'Remove',
    enterValueGreaterThan: 'Please enter a value greater than 0',
    estimateFailedToSend: 'Estimate failed to send, please try again later.',
    productEstInquiry: 'Product Estimate Enquiry',
    submittingEstimate: 'Submitting below information for an estimate',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Back to Home',
    thankYou: 'Thank You',
    yourRequestHasBeenSubmitted: 'Your request has been submitted!',
    someoneFromOurTeamWillBeInTouchSoon: 'Someone from our team will be in touch soon.',

    // -- REGISTRATION -- //
    registrationHeader: "Let's Get the Ball Rolling",
    registrationDescription:
        'We are almost there, we just need a little bit of information from you to get you to the Ball Customer Portal where you can learn more about cans, how to order, and estimate costs.',
    region: 'Region',
    language: 'Language',
    dateFormat: 'DATE FORMAT',
    aboutYourCompany: 'About your Company',
    companyName: 'Company Name',
    country: 'Country',
    stateProvince: 'State/Province',
    city: 'City',
    postalCode: 'Postal Code',
    yearsInBusiness: 'Years In Business',
    numberOfEmployees: 'Number Of Employees',
    estimatedAnnualRevenue: 'Estimated Annual Revenue',
    beverageInRetail: 'Do you currently have a beverage in retail?',
    currentPackageType: 'Current Package Type',
    beveragePackagingNeeds: 'Your Beverage Packaging Needs',
    beverageCategory: 'Beverage Category',
    productSize: 'Product Size',
    expectedAnnualQuantity: 'Expected Annual Quantity',
    canningLine: 'Do you own or plan to purchase a canning line?',
    copackerName: 'Co-Packer Name',
    fillingCountry: 'Filling Country',
    fillingCity: 'Filling City',
    additionalComments: 'Additional Comments (Optional)',
    artworkComments: 'Artwork Comments (Optional)',
    setupCustomerPortalAccount: 'Setup Your Customer Portal Account',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    alreadyRegistered: 'Already Registered?',
    accountCreated: 'This email account is already tied to The Source.',
    signIn: 'Sign in to the Customer Portal',
    signInConfirmation: 'Please Sign In to The Source and if needed, reset your password.',
    yes: 'Yes',
    no: 'No',
    na: 'N/A',
    incorrectEmailAddress: 'Invalid Email Format',
    cannotAddSelf: 'You cannot add yourself as a new user',
    atLeastEightLetters: 'At least 8 letters',
    atLeastOneCapitalLetter: 'At least one capital letter',
    atLeastOneLowerCaseLetter: 'At least one lowercase letter',
    atLeastOneNumber: 'At least one number',
    noDisallowedValue: "Doesn't include name or part of email",
    mustNotBeACommonPassword: 'Must not be a common password',
    passwordsShouldMatch: 'Passwords must match',
    year: 'year',
    printType: 'Would you like any of the following can types?',
    iAgree: 'I agree to the',
    termsOfUse: 'Terms of Use and Privacy Policies',
    million: 'Million',
    newToBeverage: 'New to Beverage',
    title: 'Title',
    jobTitle: 'Job Title',
    selectSecurityQuestion: 'Question',
    securityAnswer: 'Answer',
    invalidSecurityAnswer: 'Answer must be greater than 5 characters',
    emailValidationError: 'Email doesn’t match required pattern',
    passwordValidationError: 'Password must not be common password',
    securityAnswerValidationError:
        'The security question answer is too weak, answer must not be part of the question.',
    unknownValidationError:
        'There was an error creating a new user account. Please contact the help desk.',
    selectSecurityQuestionPrompt:
        'Select a security question and answer so you may automatically reset your password if you have problems signing in to your account.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Bright Cans',
    sleevedCans: 'Sleeved Cans',
    printedCans: 'Printed Cans',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Beer',
    beverageCategoryBeerCraft: 'Beer - Craft',
    beverageCategoryBeerDomestic: 'Beer - Domestic',
    beverageCategoryBeerImport: 'Beer - Import',
    beverageCategoryCsd: 'Carbonated Soft Drink (CSD)',
    beverageCategoryCarbonatedWater: 'Carbonated Water',
    beverageCategoryCider: 'Cider',
    beverageCategoryCoffee: 'Coffee',
    beverageCategoryIcedCoffee: 'Iced Coffee',
    beverageCategoryEnergyDrink: 'Energy Drinks',
    beverageCategoryFab: 'Flavored Alcoholic Beverage (FAB)',
    beverageCategoryNCAFab: 'Flavored Alcoholic Beverage (FAB)',
    beverageCategoryEMEAFab: 'FAB’s',
    beverageCategoryFmb: 'Flavored Malt Beverage (FMB)',
    beverageCategoryHardLiquor: 'Hard Liquor',
    beverageCategoryThc: 'Hemp/CBD/THC',
    beverageCategoryJuice: 'Juice',
    beverageCategoryProtein: 'Protein/Milk Based',
    beverageCategoryRtd: 'RTD',
    beverageCategorySpirits: 'Spirits',
    beverageCategorySportsDrink: 'Sports Drinks',
    beverageCategoryTea: 'Tea',
    beverageCategoryIcedTea: 'Iced Tea',
    beverageCategoryStillWater: 'Still Water',
    beverageCategoryWater: 'Water',
    beverageCategoryWine: 'Wine',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Other',

    // New Beverage Categories 2024
    naBeverageCategoryCoffeeDairy: 'COFFEE/DAIRY',
    naBeverageCategoryBeerCraft: 'CRAFT BEER',
    naBeverageCategoryCsd: 'CARBONATED SOFT DRINK',
    naBeverageCategoryClubSodaSeltzer: 'CLUB SODA/SELTZER',
    naBeverageCategoryDomesticBeer: 'DOMESTIC BEER',
    naBeverageCategoryEnergyDrink: 'ENERGY DRINK',
    naBeverageCategoryFabCider: 'FAB/CIDER',
    naBeverageCategoryEuropeanBeer: 'EUROPEAN BEER',
    naBeverageCategoryJuice: 'JUICE',
    naBeverageCategorySportsDrink: 'SPORTS DRINKS',
    naBeverageCategoryStillWater: 'SPARKLING/STILL WATER',
    naBeverageCategoryTea: 'TEA',
    naBeverageCategoryWine: 'WINE',
    naBeverageCategoryCarribeanBeer: 'CARIBBEAN BEER',
    naBeverageCategoryMexicanBeer: 'MEXICAN BEER (US)',

    // Registration Security Questions
    securityQuestionFood: 'What is the food you least liked as a child?',
    securityQuestionStuffAnimal: 'What was the name of your first stuffed animal?',
    securityQuestionAward: 'What did you earn your first medal or award for?',
    securityQuestionComputerGame: 'What was the first computer game you played?',
    securityQuestionMovieQuote: 'What is your favorite movie quote?',
    securityQuestionSportsTeam: 'What was the mascot of the first sports team you played on?',
    securityQuestionDessert: 'What was your grandmother’s favorite dessert?',
    securityQuestionCook: 'What was the first thing you learned to cook?',
    securityQuestionDreamJob: 'What was your dream job as a child?',
    securityQuestionVacation: 'Where did you go for your favorite vacation?',

    // Validation
    required: 'Required',

    // -- ORDER SHIPPING -- //
    makeItLink: 'make it',
    shipItLink: 'ship it',
    notSeeingProduct: 'Not seeing a product?',
    checkStatusInProcess: 'Check the status of in-process graphics.',
    share: 'Share:',
    print: 'Print',
    shipTo: 'Ship To',

    // Graphic Intake
    formOverview: 'Form Overview',
    graphicsSubmissionInfo: 'Graphics Submission Information',
    productOptions: 'Product Options',
    colorMatchingOptional: 'Color Matching (Optional)',
    uploadGraphicsAndSubmit: 'Upload Graphics & Submit',
    submitGraphics: 'Submit Your Graphics',
    graphicUploadTimeout: 'graphic upload timed out. Please try again later.',
    requiresSpecialty:
        'you noted your product requires a special coat or specialty. Please select your coating, finish or effect.',
    requiresSubmitter: 'please add a valid email address.',
    fillOutRequiredFields: 'All required fields must be completed prior to submitting.',
    dateErrors:
        'Please ensure Requested Date, In-Retail date, and Out-of-Retail date are valid dates.',
    uploadFileError: 'Please upload a valid zip file before submitting',
    intakeFailedToSave: 'Graphics intake form failed to submit',
    saveProgress: 'Save Progress',
    graphicDraftSaved: 'Graphic Intake Draft Saved',
    networkError:
        'We are experiencing technical difficulties with your upload, please try again. If you continue to experience issues, files can be submitted to CORPGCS@ball.com for manual intake.',
    specialtiesError: 'Please select at least one coating or specialty',
    promotionStartDatePrior:
        'Please ensure your promotion start date is prior to your promotion end date',
    promotionStartDateAfter: 'Please ensure your promotion date to be after your requested date',
    unknownError: 'an unknown error occurred.  Please try again later.',
    requestedByDate: 'Requested Date',
    inPlantDate: 'Requested Date',
    requestedDateHelper:
        '<strong>In Plant Date:</strong> What is the date you need cans in filling location?',
    inRetailDate: 'In-Retail Date',
    shortTermStartDateHelper: 'Date when finished goods will be in retail',
    validInRetailDateError: 'Please make sure In-Retail date is after Requested Date',
    outOfRetailDate: 'Out-of-Retail Date',
    shortTermEndDate: 'Date when finished goods are estimated to be out of retail',
    validOutOfRetailError: 'Please make sure Out-Of-Retail date is after In-Retail date',
    iDontKnow: `I don't know`,
    typeOfGraphic: 'What type of graphic do you want to submit?',
    graphicType: 'Graphic Type',
    blankForm: 'Blank Form',
    billToAddress: 'Company Bill-To',
    graphicYouAreUpdating: 'Graphic You Are Updating',
    replaceExistingGraphics: 'Replace Existing Graphics',
    previousSubmissions: 'Previous Submissions',
    copyWarning:
        'You are about to submit a new graphic, leveraging information provided for a previous graphic. Please carefully review and update all fields to ensure accuracy for the new graphic.',
    revisionWarning:
        'You are about to submit a revision for an active graphic. Please carefully review and update all fields to ensure accuracy for the new graphic.',
    revisionToAnExistingGraphic: 'Is this a revision to an existing graphic?',
    existingGraphicName: 'Existing Graphic Name',
    revisionGraphicNameHelper: 'This is the name of the graphic you are revising',
    enterGraphicNumberOrName: 'Please enter a graphic number or name',
    existingGraphicNumber: 'Existing Graphic Number',
    revisionGraphicNumberHelper: 'This is the graphic number you are revising',
    newGraphicSubmissionName: 'New Graphic Submission Name',
    submissionNameHelper: 'Name this submission for future reference ',
    revisedSubmissionName: 'Which project would you like to revise?',
    alphanumericPattern: 'Please type a name using alphanumerics',
    conversion: 'Is this a Hard or Soft conversion?',
    conversionTooltip:
        'A HARD conversion is a revised graphic with a significant change that CANNOT be mixed with old inventories. If you select HARD conversion for your graphic, you are directing Ball to stop producing this graphic by your selected date and to invoice for any existing inventories having the prior graphic.',
    hardConversionDateHelper: 'What is your hard Conversion date for your artwork',
    shortTerm: 'Is this a promotion or does this graphic require an in or out of market date?',
    graphicsContactInformation: 'Graphics Contact Information',
    graphicsContact: 'Graphics Contact',
    customerSKUNo: 'CUSTOMER SKU #',
    colorStandardsApprover: 'COLOR STANDARDS APPROVER',
    colorMatchNote: 'Note',
    colorMatchNoteText: 'Please include this page along with your color matching object.',
    canFinishes: 'Can Finishes',
    colorMatching: 'Color Matching',
    dateCodeMatching: 'Date Code Matching',
    colorMatch: 'Color Match',
    customerNameHelper: 'Company Submitting on Behalf of',
    yourCompany: 'Your Company',
    nameHelper: 'Name of Person submitting this form',
    phoneNumberHelper: 'Phone Number of person submitting this form',
    emailAddress: 'Email Address',
    submitterEmailHelper: 'Email of person submitting the form',
    colorStandards: 'Approver Information',
    approverName: `Approver's Name`,
    approverNameHelper: 'Name of person who will approve the standards',
    approverEmail: `Approver's Email`,
    approverEmailHelper: 'Email Address of approver',
    address: 'Address',
    street: 'Street',
    addressHelper: 'Street, City, State, Zip ',
    hundredMaxLengthError: 'Please make sure field value is 100 characters or less',
    alphaNumericPatternError: 'Please make sure field is alphanumeric',
    numericPatternError: 'Please enter a numeric value',
    glnNumberError: 'Please enter a 13 digit numeric value',
    thirtyMaxLengthError: 'Please make sure field value is 30 characters or less',
    fiftyMaxLengthError: 'Please make sure field value is 50 characters or less',
    customerGraphicsID: 'Customer Graphics ID',
    customerGraphicsIDHelper:
        '<strong>Artwork Code (Optional):</strong>  This is the code that is printed on the can.',
    customerSKU: 'Customer Material Number',
    customerSKUnumber: 'Customer SKU Number',
    customerSKUHelper:
        "<strong>Customer Material Number:</strong> This Material number will not print on the can but is used to place orders in Ball's system.",
    productPasteurisationHelper:
        'Select the approximate temperature at which to pasteurise your product. If your product requires Hot-Fill or Pasteurization please select an option here. Special retort inks are different from standard inks and will need to be used for your can production.',
    upc: 'UPC # / EAN',
    productSymbolContentHelper:
        "Artwork files requiring a separate set-up/version such as for Kosher (K, CRC, KOF) or for Deposit (DEP) need to be submitted as separate graphic projects through The Source with all necessary symbols present on your artwork file. Ball will not be held responsible for failing to set up an alternate version of your graphic file. If you do not have a special symbol requirement for this graphic please select 'None'",
    upcHelper: 'Provide the full UPC Number or EAN Code, including leading digits (Optional)',
    productCodeHelper: 'Size of product the graphic will be run on',
    neckSize: 'Neck Size',
    neckSizeTooltip: 'Neck size should be 206 unless Ball has previously authorized 209',
    lookingForDesignInspiration: 'Looking for Design Inspiration?',
    specialties: 'Does your product require specific coatings or specialties?',
    baseCoatRequired: 'Does your product need basecoat ink?',
    baseCoatRequiredHelperText:
        'Please note: Basecoat availability is limited and subject to approval, your Graphics representative will follow up.',
    ink: 'Ink',
    inkHelper: 'Ink options available for this product',
    effect: 'Effect',
    effectHelper: 'Effect options available for this product',
    overvarnishHelper: 'Overvarnish options available for this product',
    doYouNeedColorMatching: 'Do you need to color match existing aluminum or other collateral?',
    colorMatchComment: 'Please describe which color you would like matched on your collateral',
    comments: 'Comments',
    colorMatchingText:
        'Color matching of existing aluminum cans (does not have to be Ball-produced) or from other printed collateral (cardboard packaging, can/bottle wrap/printed paper, etc.) Upon completion of this intake form, you will be provided a printable document to include in your package with your color matching sample, and the address where you should mail your sample.',
    colorStandardText:
        'Ball provides a color standard for each new label and all labels come with color changes',
    dateCode: 'Date Code Matching',
    dateCodeText:
        '9 or 10 digit code located near the UPC barcode. Exact location will vary by can size and manufacturer.',
    dateCodeMinMaxError:
        'Please make sure Date Code is 9 or 10 characters. Tenth character may only be an asterisk.',
    invalidIntakeFile: 'This file type is invalid. Please attach a .zip file.',
    onUploadFileError: '*Please upload a valid zip file',
    onFileSizeUploadError: 'File must be below 12MB',
    uploadSection1:
        'All Ball Cans will be run on an Aluminum Substrate with a Gloss Over Varnish unless otherwise specified.',
    uploadSection2:
        'Availability of special inks or varnishes may be limited; requesting these items on this form does not guarantee availability.',
    uploadSection3:
        'Please verify that all information provided on this intake form for your graphic is accurate prior to submission. Ball Corporation will not be held responsible for any mistakes, omissions or inaccurate information provided as a result of this file submission.',
    dragAndDropZipFileHere: 'Drag and drop a zip file here or click',
    dragAndDropCsvFileHere: 'Drag and drop a csv file here or click',
    dragAndDropFileHere: 'Drag and drop a file here or click',
    dropHere: 'Drop file here',
    fileUploadedSuccessfully: 'File Uploaded Successfully:',
    acceptedFileTypes:
        'Accepted file type: Zip file that can include a TIFF and/or collected Illustrator file with images and fonts supplied or converted to outlines.',
    weRecommendPDF:
        'We recommend including a read-only PDF in your collected zip file for viewing purposes only.',
    acceptedFileTypesEmea: 'Accepted file types',
    weRecommendPDFEmea:
        'Note: .Zip files can include a TIFF and/or collected Illustrator file with images and fonts supplied or converted to outlines. We recommend including a read-only PDF in your collected zip file for viewing purposes only.',
    onUploadFileErrorEmea:
        '*One of the files you are trying to upload is not of an approved file type. Please remove and upload an approved file type (.pdf, .ai, .psd, .doc, .docx, .xls, .xlsx, .zip).',
    saveProgressAttachments: "Attached documents are not saved when 'SAVE PROGRESS' is selected.",
    clearYourForm: 'Clear Your Form',
    clearFormConfirmation: 'Are you sure you want to clear the form?',
    formInfoWillBeLost: 'Any information will be lost.',
    yesClearForm: 'Yes, Clear Form',
    excitedThankYou: 'Thank You!',
    graphicsSubmitted: 'Your Graphics have been submitted for review!',
    submissionText:
        'Our team will review your submission for suitability and develop a quote. A graphic specialist will be in touch within 2 business days.',
    subText: 'Your form is also available to view. Pleave save this for your records.',
    intakeForm: 'Intake Form:',
    pleasePrintColorMatchingForm: 'To print or download a copy of your color matching form, please',
    clickHere: 'click here.',
    startNewForm: 'Start New Form',
    backToDashboard: 'Back to My Dashboard',
    graphicsColorSubmitted: 'Thank you for submitting your update!',
    submissionColorText:
        'Our team will review this update and a graphics specialist will be in touch with you shortly. If you would like to review your submission or reprint a color matching form, please',
    viewColorMatchingInfo: 'View Color Matching Information',
    graphicsInformationMessageIE:
        'Your Graphics Summary Information is available for you to open or download within your browser.',
    colorMatchingInformationMessageIE:
        'Your Color Matching Information is available for you to open or download within your browser.',
    companyId: 'Company ID',
    submissionType: 'Submission Type',
    billTo: 'Bill To',
    approverAddress: `Approver's Address`,
    approverCountry: `Approver's Country`,
    approverType: 'Approver Type',
    billing: 'Billing',
    artworkContent: 'Artwork/Content',
    pilotCanColor: 'Pilot Can/Color',
    liquidSamples: 'Liquid Samples',
    graphicsSummary: 'Graphics Summary',
    shippingAddress: 'Shipping Address',
    yourColorMatchingInfo: 'Your Color Matching Information',
    pleasePrintThisCM:
        'Please print this and include in the shipment with your color matching object.',
    submissionName: 'Submission Name',
    colorMatchingDetails: 'Color Matching Details',
    deliveryInstructionsMaxLength: 'Maximum 30 characters',
    cantSaveDraft:
        'Submission name, Company name, and Company Bill-To are required to save a draft.',
    resolveFormErrors: 'Please resolve form errors to save a draft',
    validEmailRequired: 'A valid email is required to save a draft.',
    hard: 'Hard',
    soft: 'Soft',
    addAnotherApprover: 'Add Another Approver',
    shipToLocations: 'Ship To Location(s)',
    customerFillerLocations: 'Ship To Location(s)',
    shipToLocationsHelper:
        '<strong>Customer Ship To / Filler Location:</strong> If you do not know the Customer Ship To / Filler Location, please save your progress and you will have the option to notify the customer account team requesting assistance with Ship To information.',
    draftStoredUntil:
        'Please note that your draft intake form and any files which you might have uploaded with this form will be stored by Ball on this platform until {0}. If you do not submit the form prior to this date to Ball, the form and files will no longer be available. A new submission and files will be required for Ball to start the design development.',
    lastShipToRequestDateBanner:
        'The last notification requesting Ship To Location was sent to the customer on {0}',
    emptyShipToLocations: 'Empty Ship-To Location(s)',
    noShipToLocation:
        'You have not entered a Ship To Location in this draft. Click OK if you would like us to notify the customer.',
    requestShipToGraphicsError:
        'Something went wrong while attempting to send your request. Please try again later.',
    requestShipToGraphicsSuccess:
        'Your request for the Ship To Location has been sent to the customer.',
    noThanks: 'No Thanks',

    // -- GRAPHICS SUMMARY -- //
    submitNewGraphic: 'Submit New Graphic',
    draftGraphicsForms: 'Draft Graphics Forms',
    draftGraphics: 'Draft Graphics',
    deleteYourDraft: 'Delete Your Draft Graphic',
    graphicDeleteConfirmation: 'Are you sure you want to delete this draft graphic intake form?',
    graphicPermantlyGone: 'The form will be permanently deleted.',
    learnAboutGraphics: 'Learn About Our Graphics',
    graphicsProcess:
        'At Ball we have a dependable process for ensuring that your product is created and delivered with the highest quality and care. Below is a timeline to better help you understand the stages of our graphics process.',
    graphicsNote:
        'Note: If a vendor submitted your graphic, you will be alerted when your graphic is received and when your graphic is completed.',
    inProgress: 'In Progress',
    inProgressGraphics: 'In Progress Graphics',
    recentlyCompleted: 'Recently Completed',
    lastNinety: 'Last 90 Days',
    lastThreeSixtyFive: 'Last 365 Days',
    graphicNameAndId: 'Graphic Name & ID',
    completeIntakeForm: 'Complete Intake Form',
    graphicSpecialist: 'Graphic Specialist',
    currentStatus: 'Current Status',
    inStatusSince: 'In Status Since',
    pendingICWeight: 'Pending IC Weight',
    availableActions: 'Available Actions',
    billTos: 'Bill Tos',
    quoteReadyForApproval: 'Quote Ready for Approval',
    separationQuote: 'YOUR SEPARATION QUOTE',
    separationNoteMessage: 'Note: This is the cost of your separation.',
    quoteDetails: 'Quote Details',
    typeSeparation: 'Type of Separation:',
    acceptQuoteMessage: 'Who will be approving this quote ?',

    // Graphics Status
    DRAFT: 'Draft',
    ART_REC: 'Artwork Received; Preparing your Quote',
    QOT_APPRVL: 'Quote Ready for Approval',
    PRF_INPRGRS: 'Creating Proofs',
    PRF_APPRVL: 'Proof Ready for Approval',
    PLT_SCHLD: 'Pilot Scheduled',
    PLT_APPRVL: 'Standard Ready for Approval',
    GRPHC_CMPL: 'Graphics Complete',
    IMAGE_STAGED: 'Submission Received',
    UNDER_REVIEW: 'Submission Received',
    IMAGE_NEEDED: 'Upload Error',
    UPLOAD_ERROR: 'Upload Error',
    OBS: 'Obsolete',
    RTO: 'Ready To Order',
    PLATES: 'Platemaking',
    RTM: 'Ready To Manufacture',
    HOLD: 'On Hold {0} : {1}',
    CANC: 'Cancelled {0} : {1}',

    // -- PENDING PAYMENTS --//
    pendingPayment: 'Pending Payment',
    pendingPayments: 'Pending Payments',
    pendingPaymentsInvoiceEmail:
        'Pro forma invoices for the orders below have been sent to you. When remitting a payment, please reference the corresponding Ball Production Order Number. Note: Submitting payments past the due date will impact the first available date for your order.',
    pendingPaymentsSubmitPayments:
        'In order to expedite processing of a payment, click the “Click if Submitted” button below and provide a remittance number (optional) for your payment.',
    pendingPaymentsDeadlineWarning:
        'You have payment(s) nearing or past a deadline, which will impact your order requested date if not paid by the due date. If you have paid, please click "Click if Submitted" below to notify us.',
    clickIfSubmitted: 'Click if Submitted',
    provideRemittanceNumber: 'Provide a Remittance Number',
    provideRemittance:
        'Providing a remittance number can speed up the processing of funds to your account.',
    remittanceNumber: 'Remittance Number',
    optional: 'Optional',
    confirmPayment: 'Confirm Payment',
    paymentSubmittedMessage:
        'Thank you for submitting your payment. We will process your payment shortly.',
    paymentSubmitted: 'Payment Submitted',
    paymentOverDue: 'Payment is overdue',
    paymentOverDueThisWeek: 'Payment is due this week',
    paymentOverDueThisMonth: 'Payment is due this month',

    // -- MAKE IT -- //
    selectRequestedWk: 'Select your requested week',
    selectForecastWk: 'Select your forecast week(s)',
    campaignPending: 'Campaign Date Pending',
    leadTimeExceedsSelected: 'Lead time exceeds selected week',
    myDashboard: 'My Dashboard',
    createProductionOrder: 'CREATE PRODUCTION ORDER',
    createAtmProductionOrder: 'Create ATM (Authorization to Manufacture)',
    addAtmProductsStep: 'Add products to build your ATM (Authorization to Manufacture):',
    saveForLater: 'Save For Later',
    back: 'Back',
    setDatesQuantities: 'Set Dates & Quantities',
    setQuantities: 'Set Quantities',
    reviewAtm: 'Review ATM (Authorization to Manufacture)',
    adjustmentsToYourOrder:
        'Adjustments may have been made to your order since you last saved. Please review all products and quantities in your order before proceeding.',
    failedToLoadDraft: 'Failed to load draft order',
    noDraftsFound: 'No Draft Orders Found',
    selectProducts: 'Add products to your production order:',
    draftName: 'Draft Name',
    continueEditing: 'Continue Editing',
    yourOrderHasBeenSaved: 'Your order has been saved successfully!',
    yourOrder: 'Your Order',
    yourATM: 'Your ATM (Authorization to Manufacture)',
    search: 'Search',
    range: 'Range',
    cansBottles: 'Cans / Bottles',
    endsClosures: 'Ends / Closures',
    agingBalance: 'Aging Balance',
    campaign: 'Campaign',
    campaignProducts: 'Campaign Products',
    endClosures: 'End/Closures',
    orderedInLastSixMonths: 'Ordered in the Last 6 Months',
    removeAll: 'Remove All',
    addInstructions: `Build your order by clicking the "ADD" button for each product you'd like to include.`,
    addAtmInstructions: `Build your ATM (Authorization to Manufacture) by clicking the "Add" button for each product you'd like to include. You will review your ATM on the next screen.`,
    qtyInstructions:
        'You will enter quantities and requested dates for the selected products on the next screen.',
    qtyAtmInstructions: 'You will review your ATM on the next screen.',
    cancelSubmission: 'Cancel Your Submission',
    saveDraftTitle: 'Save Draft',
    allInfoWillBeLost: 'Are you sure you want to cancel? All information entered will be lost.',
    yesCancel: 'Yes, Cancel',
    productId: 'Product ID',
    graphicID: 'Graphic ID',
    productDescription: 'Product Description',
    characteristics: 'Characteristics',
    coating: 'Coating',
    add: 'Add',
    added: 'Added',
    rowsPerPage: 'Rows Per Page',
    filter: 'Filter',
    poNumber: 'PO Number',
    poNumberHelper: 'A Customer PO Number is required.',
    poNumberMaxLengthHelper: 'Customer PO Number must be 25 characters or less.',
    poNumberPlaceholder: '25 characters or less',
    addProducts: 'Add Products',
    submitError: 'There was an error saving your Make It order. Please try again later.',
    submitOrder: 'Submit Order',
    draftHasBeenSuccessfullySaved: 'Your draft has been saved successfully!',
    designHasBeenSubmittedSuccessfully: 'Your design has been submitted successfully!',
    viewMyInProgressGraphics: 'View Graphics Summary',
    enterDraftName: 'Please enter a draft name for your production order to save it for later.',
    draftOrderError: 'Draft order failed to save properly. Please try again later.',
    minimumOrderQuantities: 'Minimum Order Quantities',
    feeWarning:
        'One or more quantities do not meet the minimum order quantity and will incur a fee.',
    product: 'Product',
    minimumOrderQuantity: 'Minimum Order Quantity',
    makeItMinimumOrderQuantity: 'Make It Minimum Order Quantity',
    fee: 'Fee',
    confirmFeeMessage: 'Do you want to update the quantity or accept the fee?',
    editQuantity: 'Edit Quantity',
    acceptFee: 'Accept Fee',
    today: 'Today',
    leadTimeForThisProduct: 'Lead Time for this Product: {{leadTime}} weeks',
    minimumOrderIs: 'Minimum Order is',
    feeForOrders: 'Fees for orders',
    split: 'Split',
    moqWarningMessage: 'One or more quantities entered do not meet the minimum order quantity',
    orderSubmitted: 'THANK YOU, YOUR ORDER HAS BEEN SUBMITTED',
    ordersSubmitted: 'THANK YOU, YOUR ORDER(S) HAVE BEEN SUBMITTED',
    customerPO: 'Customer PO',
    invoiceSent: 'Invoice Sent',
    cashReceived: 'Cash Received',
    reactToScheduleShipment: 'Ready to Schedule Shipment',
    proFormaInvoice:
        'You should receive your pro forma invoice for this order <strong> within 2-3 business days</strong>.',
    proFormaInvoiceBulk:
        'You should receive your pro forma invoice(s) for the order(s) <strong> within 2-3 business days</strong>.',
    paymentDueImmediately:
        'Payment for the invoice is <strong>due immediately</strong> to secure the requested date for the product(s) on the order.',
    paymentDueImmediatelyBulk:
        'Payment for the invoice(s) is <strong>due immediately</strong> to secure the requested date for the product(s) on the order(s).',
    paymentFor:
        'Payment for the <strong>invoice is due {{paymentDue}}</strong> (four weeks before your earliest requested date)',
    paymentForBulk:
        'Payment for the <strong>invoice(s) is due {{paymentDue}}</strong> (four weeks before your earliest requested date)',
    paymentForBulkNoDate:
        'Payment for the invoice(s) is due four weeks before your earliest requested date.',
    downloadCSV: 'Download .CSV',
    quantity: 'Quantity',
    orderConfirmation: 'Order Confirmation',
    leadTime: 'Lead Time',
    requiredShipDate: 'Required Ship Date',
    requestedAmount: 'Requested Amount',
    quantityToPurchase: 'Quantity To Purchase',
    revisedQuantityToPurchase: 'Revised Quantity To Purchase',
    purchaseOrderNumber: 'Purchase Order Number',
    shortenedPONumber: 'Purchase Order #',
    ballProdOrderNumber: 'Ball Production Order Number',
    ballProdOrderLineNumber: 'Ball Production Order Line Number',
    ballProdOrder: 'Ball Production Order',
    invoiceAmount: 'Invoice Amount',
    orderRequestedDate: 'Order Requested Date',
    orderSubmittedDate: 'Order Submitted Date',
    paymentDueDate: 'Payment Due Date',
    replacedBy: 'Replaced By',
    zeroOrderQuantity:
        'Order quantity cannot be 0. Please enter a quantity or remove the product from the order.',
    zeroForecastQuantity:
        'Forecasted quantity cannot be 0. Please enter a quantity or remove the product from the forecast.',
    blankForecastQuantity:
        'Forecasted quantity cannot be blank. Please enter a quantity or remove the product from the forecast.',
    firstRequestedDate: 'Requested',
    viewCurrentUpcomingCampaigns: 'View Current & Upcoming Campaigns',
    currentUpcomingCampaigns: 'Current & Upcoming Campaigns',
    lookingForCampaignSchedules: 'Looking for campaign schedules?',
    noCampaignsScheduled: 'No campaigns scheduled at this time',
    productSizeShape: 'Product (Size / Shape)',
    makeItOrderOpen: 'Make It Order Open',
    requestWindowOpen: 'Request Window Open',
    makeItOrderClosed: 'Make It Order Closed',
    requestWindowClosed: 'Request Window Closed',
    firstNumAvailableDate: '1st Available Date',
    firstAvailableDate: 'First Available Date',
    availableReleaseDate: 'Available Week',
    newGraphicApprovalDeadline: 'New Graphic Approval Deadline',
    changeRequestedWeekWarning:
        'Changing your requested week will remove all products you have added to the order. Do you want to continue?',
    changeRequestedWeek: 'Change Requested Week',
    impersonationWarningDiscard:
        'This work cannot be discarded. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSave:
        'This work cannot be saved. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSubmit:
        'This work cannot be submitted. You are using an impersonation role that has view-only access in The Source.',
    makeItOrderExistsError:
        'A Make It order has already been placed for this week. Please edit the existing order from the <1>Order Summary</1> to add products or choose a different week.',
    available: 'available',
    noSupplyPlanAvailable: 'No products available',
    searchForProducts: 'Searching for products',
    noWeeksSelected: 'No weeks selected',
    addWeek: 'Add Week',
    reachOutToAccountManager: 'Please reach out to your Account Manager regarding your supply plan',
    orderExitConfirm: 'Are you sure you want to exit the order without saving?',
    orderChangesNotSaved: 'Any changes made to the order will not be saved',
    poNumberWarning: 'Please enter a Customer PO Number with no special characters.',

    // -- Delivery Orders --//
    deliveryOrder: 'Delivery Order',
    deliveryOrderNumber: 'Delivery Order #',
    deliveryOrderPending: 'Delivery Order # Pending',
    submittedByUser: 'User',
    canQuantity: 'Can Quantity',
    endQuantity: 'End Quantity',
    deliveryInstructions: 'Delivery Instructions',
    deliveryOrderDetails: 'Delivery Order Details',
    linkedProductionOrder: 'Production Order',
    linkedATMOrder: 'Make It (ATM) Order',
    shipmentPickupDate: 'Pickup Date',
    deliveryDate: 'Delivery Date',
    shipmentPickupTime: 'Pickup Time',
    deliveryTime: 'Delivery Time',
    shipmentShippedFrom: 'Shipped From',
    shipmentCarrier: 'Carrier',
    deliveryOrderDates: 'Delivery Order Date(s)',
    needToMakeChange: 'Need to make a change?',
    editShipments24Hours: 'Up to 24 hours prior to shipment date and time, you may:',
    editShipmentsOption1:
        'Add/Remove a product/graphic version from the overall order or an individual shipment',
    editShipmentsOption2: 'Update the quantity of a product for a shipment',
    editShipmentsOption3: 'Remove a shipment from a delivery order',
    editShipmentsOption4:
        'Update a delivery order date or time as long as it is more than {{leadTime}} days from the current date',
    editShipmentsOption5:
        'Update delivery instructions, release number, filler line, and reference number',
    editShipmentsOption6: 'Update delivery order date / time within the allowable delivery window',
    editShipmentsOption7: 'Update a delivery order date or time',
    editShipmentsCancellingOrders:
        'Cancelling Orders - Cancellations must be completed at least 24 hours prior to the earliest shipment date on the delivery order.',
    editShipmentsContactRep:
        'Please contact a Ball sales representative if you need to request a change different than those above.',
    yourDeliveryOrder: 'Your Delivery Order',
    cancelDeliveryOrderConfirmation: 'Cancel Delivery Order Confirmation',
    skuCansBottles: '{{ skuCount }} SKU (Cans/Bottles)',
    shipmentNumber: 'Shipment #',
    shipmentDate: 'Shipment Date',
    shipmentTime: 'Shipment Time',
    idNotAvailable: 'ID not available',
    makeItQuantity: 'Make It Quantity',
    confirmSubmission: 'Confirm Submission',
    submitMakeItForFollowing: 'You are about to submit Make It order(s) for the following:',
    submitMakeItErrorWeeks:
        'Please note that the following week(s) are <1>not</1> included due to error(s):',
    areYouSureSubmit: 'Are you sure you want to submit?',
    noCustomerPONumber: 'No Customer PO #',
    noCustomerPO: 'No Customer PO',
    minQtyNotMet: 'Minimum order quantity not met',
    productWithoutQuantity: 'Product without Quantity',
    multipleErrors: 'Multiple errors',
    not: 'not',
    key: 'Key',
    noSupplyPlan: 'No Supply Plan',
    overSupplyPlan: 'Over Supply Plan',
    underSupplyPlan: 'Under Supply Plan',
    supplyPlanMet: 'Supply Plan Met',
    orderWeeks: 'Order Weeks',
    // -- Unauthorized -- //
    unauthorized: 'Unauthorized',
    youAreNotAuthorized: 'You are not authorized to view this page',
    unknownActivity: 'this area',
    dontHaveAccess: "Looks like you don't have access to {{activityString}} for",
    deactivateReactivateUsers: 'Deactivate / Reactivate Users',
    makeItBulkATM: 'Make It Bulk ATM',
    makeItBulkUpload: 'Make It Bulk Upload',
    shipItBulkUpload: 'Ship It Bulk Upload',
    openAccess: 'Open Access',
    onboardingWizard: 'Onboarding Wizard',
    billingAndShipping: 'Billing And Shipping',

    // -- SHIP IT -- //
    enterQuantityUnit: 'Enter Quantities in:',
    createDeliveryOrder: 'CREATE DELIVERY ORDER',
    selectDeliveryWk: 'Select your delivery week',
    todayWithDate: 'Today: {{ today }}',
    chooseDates: 'Choose Dates',
    firstAvailable: 'First Available',
    firstShipment: 'Your first shipment must be at least {{ leadTime }} days from today',
    selectProductsForDelivery: 'Add products to your delivery order:',
    shipitAddInstructions: `Build your order by specifying the number of pallets for each product you'd like to include and then clicking the "ADD" button to add to your order.`,
    configureTrucksInstructions:
        'You will configure specific trucks for your order on the next screen.',
    totalPallets: 'Total Pallets',
    estimatedTrucks: 'Estimated trucks needed:',
    trucksVarianceInstructions:
        'Number of trucks will vary by total weight of ends/closures in delivery.',
    buildYourShipments: 'BUILD YOUR SHIPMENTS',
    deliveryWeek: 'Delivery Week',
    nextDeliveryWeek: 'Next Delivery Week',
    youDidntLoadAll: `You didn't load all your pallets`,
    remainingPalletsToBeOrdered:
        'There are {{ remainingBalance }} unassigned pallets in your order. If you continue, the unloaded pallets will remain unreleased and available for future delivery orders.',
    continueBuildingShipments: 'Continue Building Shipments',
    deliveryDay: 'Delivery Day',
    availableQuantToLoad: 'Available Quantity Out of {{ maxPalletsToLoad }} PL to Load',
    availOutOfMax: '{{ availablePallets }} PL out of {{ maxPallets }} PL',
    addShipment: 'Add Shipment',
    productsToShip: 'Products to Ship',
    remainingProductsToLoad: 'Remaining to load: {{ totalProductPallets }} PL',
    remainingEndsToLoad: 'Remaining to load: {{ totalEndsPallets }} PL',
    palletsAvailableOn: 'Available on {{ date }}: {{ firstAvail }} PL of {{ firstAvailMax }} PL',
    viewBalancesByDay: 'View balances by day',
    notEnoughRemainingPallets:
        'Maximum number of pallets exceeded for a product. Please update the quantities within the shipment before saving.',
    maximumNumberOfPalletsWarning:
        'Maximum number of pallets for a shipment is {{truckPalletLimit}} PL. Please update the quantities within the shipment before saving.',
    freightWarningTruncated:
        'Your freight rate is based on a full truckload of {{ maxPallets }} pallets. Shipping less than a full truckload? We will send you a separate bill for the remainder of the freight.',
    freightWeightWarning:
        'Your freight rate is based on a full truckload of {{ maxPallets }} pallets. Shipping less than a full truckload? We will send you a separate bill for the remainder of the freight. Here is how you can calculate the amount you will see on your bill:',
    atmFreightWeightWarning:
        'Your freight rate is based on a full truckload (typically 25 PL). Shipping less than a full truckload? We will send you a separate bill for the remainder of the freight.',
    shipmentFeeModalWarning:
        'Your freight rate is based on a full truckload (typically 25 PL). Shipping less than a full truckload? We will send you a separate bill for the remainder of the freight. Here is how you would calculate the amount based on 25 pallets.',
    freightWarningMaxPallets:
        'Shipping less than {{ maxPallets }} pallets incurs additional fees. You will see an additional charge for freight on your invoice.',
    feesMayIncur: 'Fees may be incurred for shipments less than a full truckload',
    deliverOn: 'Deliver On',
    pickupOn: 'Pickup On',
    addDeliveryInstructions:
        'Add a product from the list you created and enter the quantities here. The cart will update to display the remaining pallets to load, if any.',
    time: 'Time',
    at: 'At',
    arriving: 'Arriving',
    none: 'None',
    done: 'Done',
    deliveryMountainTime: 'Delivery time should be entered in Mountain Time (MT)',
    deliveryTimeZone: 'Select desired delivery time',
    pleaseEnterDeliveryInstructions: 'Please enter delivery instructions for this shipment.',
    deliveryOrderError: 'Delivery instructions failed to save properly. Please try again later.',
    deliveryConfirmationCarrierAlert:
        'Carrier will be assigned 7 days prior to scheduled delivery date.',
    incursFees: 'Shipping less than {{ truckPalletLimit }} PL incurs fees.',
    mayIncurFees: 'Shipping less than {{ truckPalletLimit }} PL may incur a fee.',
    mayIncurFeesTruckload: 'Shipping less than a full truckload may incur a fee.',
    shipmentSize:
        'The standard shipment size for a delivery order is 25 PL, but 27 PL may be allowed for some locations.',
    buildShipments: 'BUILD YOUR SHIPMENTS',
    delete: 'Delete',
    selectedWeek: 'Selected Week',
    company: 'Company',
    availBalance: 'Avail. Balance',
    numberOfPalletsToShip: 'Number of pallets to ship',
    numberOfEachesToShip: 'Number of eaches to ship',
    eachesTooltip: 'Quantities will be rounded up to the nearest whole pallet',
    releaseNumber: 'Release Number',
    releaseNumberPDF: 'Release #',
    loadSeq: 'Load Seq.',
    fillerLineNumber: 'Filler Line #',
    referenceNumber: 'Reference Number',
    referenceNumberPDF: 'Reference #',
    invalidBulkFile: 'This file type is invalid. Please attach a .csv file.',
    invalidCsv: '*Please upload a valid csv file',
    reviewOrder: 'Review Order',
    resetMapping: 'Reset Your Mapping',
    mappingPermanentlyGone: 'Any changes made to your mapping will be permanently gone.',
    changesPermanentlyGone: 'Any changes made to your submission will be permanently gone.',
    deleteProgress: 'Delete Progress',
    yesReset: 'Yes, Reset',
    editMapping: 'Edit Mapping',
    changeDeliveryWeekWarning:
        'Changing your delivery week will remove all products you have loaded in your order. Do you want to continue with changing the delivery week?',
    changeDeliveryWeek: 'Change Delivery Week',
    deliveryOrderDayTimeError:
        'Shipments on the same delivery order cannot have duplicate dates and times. Please modify the time chosen.',
    changeDeliveryDayWarning:
        'Changing your delivery day could require alterations to your shipment.  Do you want to continue?',
    changeDeliveryDate: 'Change Delivery Date',
    creditHoldWarning:
        'A credit hold has been placed against this ship to. Delivery orders cannot be placed for this ship to until the credit hold has been resolved.',
    copackerCreditHoldWarning:
        'This Ship To location is on hold. Please reach out to your customer for resolution.',
    dashboardCreditHoldWarning:
        'The following Ship Tos have a credit hold against them. Delivery orders cannot be placed against these Ship Tos:',
    additionalFields: 'Additional Fields',

    // -- Summary Pages -- //
    customerPoNumber: 'Customer PO #',
    customerPoNumLong: 'Customer PO Number',
    status: 'Status',
    createdBy: 'Created By',
    dateSubmitted: 'Date Submitted',
    productGraphicId: 'Product Id / Graphic Id',
    customerProductGraphicId: 'Customer Id / Product Id / Graphic Id',
    graphicId: 'Graphic Id',
    descriptionCharacteristics: 'Product Description / Characteristics',
    quantityOrdered: 'Quantity Ordered',
    quantityToDeliver: 'Quantity to Deliver',
    requestedDate: 'Requested Date',
    open: 'Open',
    closed: 'Closed',
    availableToShip: 'Available to Ship',
    completed: 'Complete',
    productionOrderDetails: 'Production Order Details',
    totalOrderQuantity: 'Total Order Quantity',
    totalOrderQuantityPL: 'Total Order Quantity (PL)',
    totalOrderQuantityEA: 'Total Order Quantity (ea.)',
    totalOrderQuantitySKUS: 'Total Order Quantity (SKUs)',
    totalDeliveryQuantity: 'Total Delivery Quantity',
    openProductionOrders: 'Open Production Orders',
    closedProductionOrders: 'Closed Production Orders',
    draftProductionOrders: 'Draft Production Orders',
    draftOrderName: 'Draft Order Name',
    lastSaved: 'Last Saved',
    lastSavedBy: 'Last Saved By',
    actions: 'Actions',
    openDeliveryOrders: 'Open Delivery Orders',
    closedDeliveryOrders: 'Closed Delivery Orders',
    planItPlanItSummary: 'Plan It/Plan It Summary',
    orderStatus: 'Order Status',
    pending: 'Pending',
    editPending: 'Edit Pending',
    received: 'Payment Received',
    reject: 'Rejected',
    confirmed: 'Confirmed',
    shipConfirmed: 'Shipment Confirmed',
    draft: 'Draft',
    delinquent: 'Payment Due',
    payable: 'In Approval',
    cancelledPending: 'Cancelled Pending',
    editUnderReview: 'Edit Under Review',
    openEditReviewed: 'Open (Edit Reviewed)',
    openEditRejected: 'Open (Edit Rejected)',
    productName: 'Product Name',
    arrivalDate: 'Arrival Date',
    arrivalTime: 'Arrival Time',
    lineStatus: 'Line Status',
    poOpenOrderInformation:
        'Up to two weeks prior to the first available date you may remove a product from the production order, delay the first available date for a product, or decrease the quantity of a product.',
    poCannotBeEdited: `If a product has an associated delivery order, you won't be able to make edits to the production order.`,
    edit: 'Edit',
    cancelOrder: 'Cancel Order',
    goBack: 'Go Back',

    // -- EDIT PRODUCTION ORDER -- //
    editProductionOrder: 'Edit Production Order',
    pleaseConfirmRemoveOrder: 'Please confirm you want to remove this order',
    areYouSureYouWantToRemoveProduct:
        'Are you sure you want to remove Product ID {{ productId }} from your order?',
    editRequestedDate: 'Edit Requested Date',
    originalRequestedQuantity: 'Original Requested Quantity',
    editQuantityToPurchase: 'Edit Requested Quantity to Purchase',
    lessThanOriginal: 'Must be less than original',
    mustBeAfterOriginalDate: 'Must be after original date',
    dateMustBeAfterOriginalDate: 'The date must be after the original requested date',
    cancelProductionOrderSubmitted: 'Thank you. Your cancellation has been submitted',
    cancelledDate: 'Cancelled Date',
    productionOrder: 'Production Order',
    cancelProductionOrder: 'Please confirm you want to cancel this order.',
    cancelProductionOrderSubHeader: 'Are you sure you want to cancel Order #',
    cancelProductionOrderConfirmation: 'Are you sure you want to cancel Production Order #',
    previousQuantitySubmitted: 'Previous Quantity Submitted',
    originalQuantityOrdered: 'Original Quantity Ordered',
    previousForecastedQuantity: 'Previous Forecasted Quantity',
    revisedForecastedQuantity: 'Revised Forecasted Quantity',
    revisedQuantityOrdered: 'Revised Quantity Ordered',
    revisedRequestedDate: 'Revised Requested Date',
    editProductionOrderConfirmation: 'Edit Production Order Confirmation',
    editProductionOrderSubmitted: 'THANK YOU. YOUR CHANGES HAVE BEEN RECEIVED',
    dateRevised: 'Date Revised',
    editProductionOrderSubmitChanges: 'You are about to change your production order',
    editProcutionOrderConfirmSubmit:
        'Are you sure you want to save the changes to your production order?',
    removed: 'REMOVED',
    oneOrMoreDeliveryOrdersAttached:
        'One or more products cannot be edited because of tied delivery order(s). View details for those tied delivery orders in the',
    inactiveProductError:
        'Ball SKU {{sku}} is inactive. You cannot submit changes until this is addressed with your Ball representative or removed from the order.',
    currentAvailableBalance: 'Current Available Balance',

    // -- Date Pickers -- //
    legend: 'Legend',
    day: 'Day',
    restrictedDate: 'Restricted <br/> Date',
    dateFirstAvailable: 'Date First Available',
    availableDate: 'Available <br />Date',
    ok: 'Ok',

    // -- PRODUCT PLANNING -- //
    units: 'Units',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    thisWeek: 'This Week',
    weeks0to8: 'Weeks 0-8',
    weeks9to16: 'Weeks 9-16',
    productionBalance: 'Production Balance',
    atmBalance: 'ATM Balance',
    weekOfWithDate: 'Week of {{date}}',
    productionOrders: 'Production Order(s)',
    ordersPendingPayment: 'Orders Pending Payment',
    originalRequestedDate: 'Original Requested Date',
    revisedAvailableDate: 'Revised Available Date',
    late: 'Late',
    deliveryDates: 'Delivery Date(s)',
    productPlanning: 'Product Planning',
    emptyString: '',
    graphicsID: 'Graphics ID',
    ballOrderNumber: 'Ball Order #',
    scheduledFor: 'Scheduled For',
    receivedOn: 'Received On',
    shipmentsExceededProduction: 'Shipments exceeded production balances',
    transferProductionBalance: 'Request a Trade It',
    availableToTrade: 'Available to Trade',
    netBalance: 'Net Balance',
    agingBalanceIndicator: 'Indicates an aging balance',
    assignedGraphicId: ' Indicates Graphic ID is assigned to a current active product',
    displayUnits: 'Display Units',
    apply: 'Apply',
    agingBalances: 'Aging Balances',

    // -- PRODUCT MANAGEMENT -- //
    requestDeactivation: 'Request Deactivation',
    requestActivation: 'Request Activation',
    editProductInfo: 'Edit Product Information',

    // -- TRADE IT -- //
    tradeIt: 'Trade It',
    createTradeRequest: 'Create balance trade request',
    tradeRequestSummary: 'Balance Trade Summary',
    selectYourShipTos: 'Select your Ship Tos',
    originalShipTo: 'Original Ship To',
    receivingShipTo: 'Receiving Ship To',
    originalShipToContactEmail: 'Original Ship To Contact Email',
    receivingShipToContactEmail: 'Receiving Ship To Contact Email',
    selectProductsToTrade: 'Select products to trade',
    makeYourShipToSelections:
        'Make your selections above to see products eligible for trades for the Ship Tos you select.',
    noTradeItProducts:
        'No products with available to trade balance from original ship and mapped to the receiving ship to.',
    willReceiveNotification:
        '* You will receive a notification once the request has been reviewed.',
    originalShipToCurrentProdBalance: 'Current Balance',
    receivingShipToCurrentProdBalance: 'Current Balance',
    quantityToTrade: 'Quantity to Trade (PL)',
    originalShipToNewProdBalance: 'New Balance',
    receivingShipToNewProdBalance: 'New Balance',
    quantityExceedsAvailableBalance: 'Cannot exceed Original Ship To available balance',
    submitRequest: 'Submit Request',
    balanceTradeSubmitted: 'Thank you, your balance trade request has been submitted.',
    tradeItRequest: 'Trade It Request',
    originalShipToPreviousAvailableBalance: 'Previous Available Balance for Original Ship To #',
    receivingShipToPreviousAvailableBalance: 'Previous Available Balance for Receiving Ship To #',
    requestedQuantityToTrade: 'Requested Quantity to Trade',
    originalShipToUpdatedAvailableBalance: 'Updated Available Balance for Original Ship To #',
    receivingShipToUpdatedAvailableBalance: 'Updated Available Balance for Receiving Ship To #',
    originalShipToContact: 'Original Ship To Contact',
    receivingShipToContact: 'Receiving Ship To Contact',
    requestDetails: 'Request Details',
    weekOf: 'Week Of',
    requestStatus: 'Request Status',
    updatedBalance: 'Updated Balance',
    previousBalance: 'Previous Balance',
    requestedTradeQuantity: 'Requested Trade Quantity',
    campaignTradeNotification:
        'Please note that there are one or more campaign products included in your request.',
    campaignTradeRisk:
        'If you make the decision to trade campaign cans causing a premature stock-out, <1>inventory will not be replenished until the next planned campaign</1>.',
    campaignTradeRiskEmphasis: 'inventory will not be replenished until the next planned campaign',
    campaignQuestionsPrompt:
        'If you have any questions, please reach out to your account representative.',
    campaignContinuePrompt: 'Do you wish to continue?',
    campaignProductAlert: 'Campaign Product Alert',
    accept: 'OK',

    // -- DELIVERY ORDER PDF -- //
    total: 'Total',
    dateTime: 'Date / Time',
    week: 'Week',
    revised: 'Revised',
    update: 'Update',
    pickUp: 'Pick Up',
    loadingMessage: 'Loading PDF. This may take up to a few minutes.',

    // -- EDIT SHIPMENTS SUMMARY -- //
    updateOrder: 'Update Order',
    originallyScheduled: 'Originally scheduled',
    ShipmentOriginallyScheduled: 'Shipment Originally scheduled',
    pleaseSelectProductsShipment: 'Please select products to add to your shipment',
    needToChange: 'Need to make a change?',
    shipping: 'Shipping',
    cancelShipment: 'Cancel Shipment',
    customerPickUp: 'Customer Pick Up',
    discardChanges: 'Discard Changes',
    submitChanges: 'Submit Changes',
    shipmentEdited: 'Shipment Edited',
    shipmentCancelled: 'Shipment Cancelled',
    changeDeliveryOrderModalTitle: 'You are about to change your delivery order',
    changeDeliveryOrderModalConfirmationText:
        'Are you sure you want to save the changes to your delivery order?',
    yesSave: 'Yes, Save',
    changesWillNotBeSaved: 'Changes made will not be saved',
    areYouSureDiscard: 'Are you sure you want to discard any changes?',
    yesDiscard: 'Yes, Discard',
    confirmCancelShipment: 'Please confirm you want to cancel this shipment',
    areYouSureCancelShipment: 'Are you sure you want to cancel this shipment?',
    yesCancelShipment: 'Yes, Cancel Shipment',
    feesWillBeIncurred: 'Fees will be incurred for shipments less than {{maxPallets}} pallets',
    doYouWantToAccept: 'Do you want to accept the fee?',
    yesAcceptFee: 'Yes, Accept Fee',
    palletQtyNotZero:
        'Pallet quantity should not be 0.  Please update the quantity or remove the product before saving.',
    changeDay: 'Change Day',
    changeDeliveryDateWarning:
        'Changing your delivery date could require alterations to your shipment',
    productsRemovedFromShipment:
        'Some products or quantities may not be available for shipment on this date and will be removed from your shipment.',
    editYourShipment: 'Edit Your Shipment',
    deliveryOrderConfirmation: 'Delivery Order Confirmation',
    shipmentsWithin24Hrs: 'Shipments scheduled to ship in the next 24 hours are not editable',
    pleaseConfirmCancelOrder: 'Please confirm you want to cancel this order.',
    cancellationReceived: 'Thank you. Your cancellation has been received',
    editDeliveryOrderSubmitted: 'THANK YOU. YOUR CHANGES HAVE BEEN RECEIVED',
    errorProcessingShipment: 'There has been an error processing your edited Delivery Order.',

    // -- DELIVERY BULK UPLOAD --//
    mapTemplateFields: 'Map Template Fields',
    uploadShipItOrders: 'Upload Ship It Order(s)',
    mapShipItTemplateValues: 'Map Ship It Template Values',
    updateShipItTemplateValues: 'Update Ship It Template Values',
    mapTemplateFieldsDescription:
        'Map required values to their corresponding column in your template. All required values must be mapped, optional fields can be left blank if you are not using them.',
    templateFieldMapping: 'Template Field Mapping',
    shipToId: 'Ship To ID',
    columnLetter: 'Column Letter',
    rowNumber: 'Row Number',
    columnLetterOptional: 'Column Letter (Optional)',
    fillerLine: 'Filler Line Number',
    saveMapping: 'Save Mapping',
    uploadOrderFile: 'Upload Order File',
    bulkUploadOverview: 'Bulk Upload Overview',
    mapTemplateValues: 'Map Template Values',
    columnPatternError: 'Please enter 1-2 letters',
    columnNumberPatternError: 'Please enter a number',
    bulkUpload: 'Bulk Upload',
    atmBulkUpload: 'ATM Bulk Upload ',
    reset: 'Reset',
    uploadDeliveryOrder: 'Upload Your Delivery Order',
    acceptedBulkFiles:
        'Please upload your .csv file, making sure your values match with your mapping.',
    lookUpShipTo: 'Need to look up a Ship To?',
    viewAssignedShipTos: 'View Assigned Ship Tos',
    multipleColumnValidationError:
        'Column Letters cannot be duplicated. Please review your entries and resubmit.',
    mustSaveMapping: 'You must save your mapping above in order to upload a file.',
    documentName: 'DOCUMENT NAME',
    documentNotUploaded: 'No document uploaded',
    removeFile: 'Remove File',
    confirmFileRemoval: 'Please confirm you would like to remove this file',
    areYouSureRemoveFile: 'Are you sure you want to remove this file?',
    yesRemove: 'Yes, Remove',
    confirmCancel: 'Are you sure you want to cancel?',
    sureYouWantToLeave: 'Are You Sure You Want To Leave?',
    confirmCancelNoQuestion: 'Are you sure you want to cancel',
    removeProductTitle: 'Remove Product from Order',
    removeProductSubheader: 'Are you sure you want to remove this product from your order?',
    changeWeek: 'Change Week',
    chooseWeekMove: 'Choose a week to move this Product to',
    chooseWeekCopy: 'Choose a week to copy this Product to',
    lostFile: 'Any file uploaded or unsaved mappings will be permanently gone.',
    deliveryOrderHeader: 'Delivery Order {{deliveryOrderNumber}} - <i>Order Number Pending</i>',
    deleteShipment: 'Delete Shipment',
    editShipment: 'Edit Shipment',
    delivery: 'Delivery',
    lowTruckPalletCountWarning: 'This delivery has less than a full truck load.',
    reviewYourShipments: 'Review Your Shipments',
    pleaseReviewShipmentDetails:
        'Please review the details of your shipments below and make any edits necessary before submitting your order(s).',
    allOrdersDeleted:
        'All orders have either been deleted or removed. Please "undo" at least one deleted order or upload a new order file to proceed.',
    allShipmentsDeleted:
        'All shipments have been deleted. Please "undo" at least one deleted shipment or upload a new order file to proceed.',
    allProductsRemoved: 'All products have been removed. Please add products to proceed.',
    line: 'Line',
    fileErrors: 'File Errors',
    lineErrorHelper: 'Please correct the following errors and re-upload your file.',
    shipmentDeleted: 'Shipment Deleted',
    undo: 'Undo',
    yesDelete: 'Yes, Delete',
    noCancel: 'No, Cancel',
    confirmDeleteShipment: 'Are you sure you want to delete this shipment?',
    intakeCancelConfirmation: 'Are you sure you want to cancel your progress without saving?',
    intakeSaveDraft:
        'You are about to save a draft of your Production Order, please name your draft to easily access it later.  Once saved, you will return to the dashboard.',
    saveChanges: 'Save Changes',
    planningView: 'Planning View',
    confirmmExit: 'Are you sure you want to exit the page?',
    changesNotSaved: 'Your changes will not be saved.',
    sureYouWantToNavigateAway: 'Are you sure you want to navigate away?',
    goBackConfirm: 'Are you sure you want to go back?',
    yesGoBack: 'Yes, Go Back',
    twentyFourHourFormat: '24 hour format',
    firstRowOfData: 'First Row of Data',
    enterWeekDatesBelow: 'Please enter the dates of your weeks below:',
    quantityPL: 'Quantity (PL)',
    quantityEA: 'Quantity (EA)',

    // -- DELIVERY BULK UPLOAD VALIDATION RULES -- //
    shipItBulkDataValidation: 'Ship It Bulk Upload Data Validation',
    dataValidationRules: 'Data Validation Rules',
    field: 'Field',
    value: 'Value',
    maxLength: 'Max Length',
    format: 'Format',
    example: 'Example',
    quantityInPallets: 'Quantity (In Pallets)',
    or: 'or',
    viewDataValidationRules: 'View Data Validation Rules',
    upTo8Digits: 'Up to 8 digits',
    upTo15Digits: 'Up to 15,4 digits',
    upTo3Characters: 'Up to 3 characters',
    upTo10Characters: 'Up to 10 characters',
    upTo25Characters: 'Up to 25 characters',
    upTo24Characters: 'Up to 24 characters',
    upTo30Characters: 'Up to 30 characters',
    deliverToBackDoor: 'Deliver to back door',
    dateInputFormat: 'MM/DD/YYYY',

    // -- DELIVERY BULK UPLOAD Errors -- //
    E000001: 'Ship To ID is required and missing from this line. Please provide.',
    E000002: 'Product ID is required and missing from this line. Please provide.',
    E000003: 'Quantity is required and missing from this line. Please provide.',
    E000004: 'Delivery Date is required and missing from this line. Please provide.',
    E000005: 'Delivery Time is required and missing from this line. Please provide.',
    E100003: 'Quantity must be a numeric value less than or equal to 25.',
    E110001: 'Quantity must be a numeric value less than or equal to {0}.',
    E100004: 'Delivery Date is in incorrect format. Please enter as MM/DD/YYYY',
    E100005: 'Delivery Time is in incorrect format. Please enter as HH:MM (24 hour time).',
    E100006:
        'Delivery Instructions is in incorrect format. Value must be a string of less than or equal to 30 characters.',
    E100007:
        'Release Number is in incorrect format. Value must be a string of less than or equal to 10 characters.',
    E100009:
        'Reference Number is in incorrect format. Value must be a string of less than or equal to 30 characters.',
    E100001:
        'Invalid Ship To ID. Check the list of your assigned Ship Tos to obtain valid Ship To IDs.',
    E200001:
        'Invalid Ship To ID. Check the list of your assigned Ship Tos to obtain valid Ship To IDs.',
    E200002: 'Invalid Product ID. Please check and correct.',
    E100002: 'Invalid Product ID. Please check and correct.',
    E100014: 'Quantity is in incorrect format. Please enter up to 12 digits.',
    E100013: 'Quantity is in incorrect format. Please enter up to 6 digits.',
    E100023: 'Please enter quantities as a whole number.',
    E200004:
        'Product ID entered is not mapped to the Ship To entered on this line. Please change the Product or Ship To.',
    E300005:
        'You do not have permissions to submit orders for this Ship To. Please remove rows with this Ship To from the file.',
    E100008: 'Invalid Filler Line. Please check and correct.',
    E300004: 'User is not associated with this Ship To',
    // Note: if you change the following translations be sure the format strings, {n}, are put in the right place of the new string
    E110002:
        'Date entered is within {0} days of today. Please enter a date that is at least {0} days from today.',
    E200006:
        'Total quantity {0} PL across shipments for {1} at Ship To {2} week of {3} exceed the available balance of {4} PL by {5} PL.',
    E200007:
        'Multiple product types (can vs. end) on shipment for Ship To {0} at {1} {2}. Please move some products to a different Delivery Date/Time so that each Ship To and Delivery Date/Time only has one product type.',
    E210005:
        'Quantity entered for Ship To {0} at {1} is greater than {2} PL. Please move some pallets to a different Delivery Date/Time so that each Ship To and Delivery Date/Time has {2} PL maximum.',

    // -- MAKE IT BULK UPLOAD Errors -- //
    EA000001: 'Quantity is in incorrect format. Please enter up to 6 digits.',
    EA000002: 'Quantity is in incorrect format. Please enter up to 12 digits.',
    EA000003: 'Please enter a value that is at least 0.',
    EA000004:
        'Requested Date is in a locked time period. Please select at least {0} for the lead time of {1} weeks.',
    EA100001: 'This is not the correct template format for this customer',
    EP100007: 'This is not the correct template format for this customer',
    EA000006:
        'A Make It order has already been placed for this week and Ship To location. Please edit the existing order from the <1>Order Summary</1> to add products.',
    EP000001: 'Ship To ID is required and missing from this line. Please provide.',
    EP000002: 'Production Order Number is required and missing from this line. Please provide.',
    EP000003: 'Product ID is required and missing from this line. Please provide.',
    EP000004: 'Requested Date is required and missing from this line. Please provide.',
    EP000005: 'Quantity is required and missing from this line. Please provide.',
    EP100006: 'Please enter quantities as a whole number.',
    EP100001: 'Ship To ID is in incorrect format. Please enter up to 8 digits.',
    EP110001: 'Ship To ID is in incorrect format. Please enter up to 8 digits.',
    EP100002:
        'Production Order Number is in incorrect format. Please enter text up to 25 characters.',
    EP100003: 'Product ID is in incorrect format. Please enter text up to 25 characters.',
    EP100004:
        'Requested date is not valid or in the incorrect format. Please check and enter as MM/DD/YYYY.',
    EP100005: 'Quantity is in incorrect format. Please enter up to 6 digits.',
    EP110005: 'Quantity is in incorrect format. Please enter up to 6 digits.',
    EP200001:
        'Date entered is earlier than today. Please enter a future date that accounts for lead time.',
    EP200006: 'Please select a date that is at least next week',
    EP200002: 'Please enter a quantity greater than 0 that accounts for the order minimum.',
    EP200003: 'Invalid Product ID. Please check and correct.',
    EP200004: 'Invalid Product ID. Please check and correct.',
    EP200005:
        'Product ID entered is not mapped to the Ship To entered on this line. Please change the Product or Ship To.',
    EP300001:
        'Invalid Ship To ID. Check the list of your assigned Ship Tos to obtain valid Ship To IDs.',
    EP400001:
        'You do not have permissions to Submit Order(s) for {0}. Please remove rows with this Ship To from the file.',
    EP500002:
        'Requested Date is in a locked time period. Please select a later date that accounts for product lead time.',
    EP100008: 'This product is not available in a current Campaign Window.',
    EP100009:
        'The requested date is prior to the first available delivery date for this product.  Please account for lead time.',
    EP100010:
        'Your file has multiple Customer PO numbers for the following Week(s): {{weeks}}.  Please correct this error and upload again to submit your order.',
    EP100011: `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`,

    // -- MAKE IT BULK UPLOAD -- //
    mapMakeItTemplateValues: 'Map Make It Template Values',
    reviewYourOrders: 'Review Your Order(s)',
    acceptedMakeItBulkFiles:
        'Please upload your .csv file, making sure your values match with your mapping.',
    uponSubmitCancelMessage:
        'Upon submitting, any products previously forecasted and not included again for the Forecast Week and Ship to will be canceled.',
    removeShipToLocationsP1:
        'If you would like to remove full Ship To locations from a forecast week, please edit those records via ',
    removeShipToLocationsP2:
        ' for each ship to and week or include those in the file with a 0 quantity.',
    productNotIncluded: 'Product not included on updated file. Enter quantity to add.',
    yourOrders: 'Your Order(s)',
    makeitMapTemplateFieldsDescription:
        'Map required values to their corresponding column in your template. All values below are required and should be mapped.',
    uploadFile: 'Upload File',
    uploadMakeItOrder: 'Upload Your Make It Order(s)',
    uploadMakeItATMOrder: 'Upload Your ATM Order(s)',
    updateMakeItTemplateValues: 'Update Make It Template Values',
    submitOrders: 'Submit Order(s)',
    needMoreInfo: 'Need more information?',
    viewAssignedShipToLocations: 'View Your Assigned Ship To Locations',
    yourAssignedShipToLocations: 'Your Assigned Ship To Locations',
    orderDeleted: `Make It Order Deleted`,
    deleteOrderModalTitle: `Confirm you would like to delete this order`,
    deleteOrderModalBody: `Are you sure you want to delete this order?`,
    palletQuantityZeroError: `Cannot be 0. Please update or remove.`,
    deleteLastProductModalTitle: `Confirm this order will be deleted`,
    deleteLastProductModalSubheader: `Removing all products in the order will delete the PO.`,
    deleteLastProductModalBody: `Confirm you would like to delete.`,
    dateModified: 'DATE MODIFIED',
    makeItIntakeResetConfirmation: 'Are you sure you want to reset? Any changes will be lost.',
    csvRowNumber: '.CSV ROW',
    dupProductId: 'Duplicate Product IDs found',
    clickToChooseCorrect: 'Click to choose the correct one.',
    productIdModalTitle: 'Confirm Requested Product',
    confirmApplyAll: 'Confirm and Apply to All',
    productIdModalBody:
        'The customer product ID: <strong>{{ customerProductId }}</strong> you entered in your .csv file returned multiple products. Please choose the correct product for this order.',
    productIdDeliveryModalBody:
        'The customer product ID: <strong>{{ customerProductId }}</strong> you requested <strong>{{ requestedQuantity }} Pallets</strong> for in your .csv file returned multiple products. Please choose the correct product for this order.',
    ballProductSku: 'Ball Product SKU',
    chooseLaterDate: 'Please choose a later date',
    makeItBulkError: 'There are errors associated with one or more orders below. Please correct.',
    eaches: 'Eaches',
    reviewATMs: 'Review ATMS',
    editOrders: 'Edit Orders',
    editATMsForecast: 'Edit ATMs and Forecast',
    editForecast: 'Edit Forecast',
    atmWeekSelector: 'View the week of',
    forecastWeekSelector: 'View Forecast for the week of',
    noValidForecasts:
        'The upload did not result in any valid Plan It records. Please edit your file or use Plan It Guided.',
    orderForWk: 'Order for the week of',
    forTheWeekOf: 'for the week of',
    forecastForWk: 'Forecast for the week of',
    editATMforWk: 'Edit ATM for week of',
    addPallet: 'Add 1 pallet to meet your supply plan',
    addPallets: 'Add {0} pallets to meet your supply plan',
    removePallet: 'Remove 1 pallet to meet your supply plan',
    removePallets: 'Remove {0} pallets to meet your supply plan',
    campaignQuantitiesConfirmed:
        'Campaign Quantities will be confirmed by your Ball representative.',
    totalEnteredQuantity: 'Total entered quantity',
    of: 'of',
    quantityRounded: 'Quantity rounded up to the nearest full pallet.',
    quantitiesRounded: 'Quantities rounded up to the nearest full pallet.',
    enterAQuantity: 'Please enter a quantity',
    leadTimeMet: 'This Product’s lead time has been met and cannot be ordered during this week',
    atmSelectorMessage: 'One or more Ship Tos have warnings or errors in them, please review.',
    atmWeekSelectorMessage: 'One or more weeks have warnings or errors in them, please review.',
    atmChangesReceived: 'Thank you, your changes have been received.',
    atmSubmitted: 'Thank you, your ATMs have been submitted.',
    submitATMError: 'The system was unable to process your changes.',
    noProductsOrdered: 'No products ordered for the following supply plans:',
    errorsOnly: 'Errors only',
    warningsOnly: 'Warnings Only',
    alertsOnly: 'Alerts Only',
    submitATMs: 'Submit ATMs',
    productAttributes: 'Product Attributes',
    atmWeekType: 'Are your weeks captured in rows or columns?',
    atmQuantityType: 'Are your quantities in pallets or eaches?',
    weekNumberQuantity: 'Week {{ weekNumber }} Quantity',
    weekNumberDate: 'Week {{ weekNumber }} Date',
    weekQuantityRequiredError: 'Required. Remove if not needed.',
    rows: 'Rows',
    columns: 'Columns',
    clear: 'Clear',
    addAdditionalWeek: 'Add Additional Week',
    weekRequired: 'Required. Please update or remove a mapped week.',
    prevQuantityPallets: 'Previous Submitted Quantity (PL)',
    prevQuantityEaches: 'Previous Submitted Quantity (EA)',
    revisedQuantityPallets: 'Revised Quantity to Purchase (PL)',
    revisedQuantityEaches: 'Revised Quantity to Purchase (EA)',
    quantityPallets: 'Quantity to Purchase (PL)',
    quantityEaches: 'Quantity to Purchase (EA)',
    currentAndUpcomingCampaigns: 'Current & Upcoming Campaigns',
    reviewMy: 'Review My',
    viewMy: 'View My',
    forecastedDemand: 'Forecasted Demand',
    inactiveProductId: 'This product is inactive and cannot be ordered.',
    availableSupplyPlan: 'Available Supply Plan',
    move: 'Move',
    copy: 'Copy',

    // -- MAKE IT BULK UPLOAD VALIDATION RULES -- //
    makeItBulkValidation: 'Make It Bulk Upload Data Validation',
    doNotIncludeCommas: 'Do not include commas within your file.',
    quantityInEaches: 'Quantity (In Eaches)',
    upTo12Digits: 'Up to 12 digits',
    integer: 'Integer',
    text: 'Text',
    upTo6Digits: 'Up to 6 digits',

    // -- Plan IT BULK UPLOAD VALIDATION RULES -- //
    planItBulkValidation: 'Plan It Bulk Upload Data Validation',

    // -- PLAN IT TO MAKE IT CONVERSION-- //
    makeItFromPlanIt: 'Make It From Plan It',
    conversionReviewOrder: 'Review Order',
    makeItSummaryLink: 'Make It Summary',

    // -- MAKE IT ATM SUMMARY -- //
    atmSummary: 'ATM Summary',
    atmOrderNumber: 'ATM Order Number',
    atmOrderNum: 'ATM Order #',
    atmWeek: 'ATM Week',
    totalQuantity: 'Total Quantity',
    futureAtms: 'Future ATMs',
    currentAtms: 'Current ATMs',
    pastAtms: 'Past ATMs',
    playground: 'Playground',
    underSupplyPlanWarning:
        'You’re under the supply plan for one or more product groups in this ATM.',
    updateYourATM: 'Update your ATM',
    atmDetails: 'ATM Details',
    allocationSummaryWarning:
        "You're under the supply plan for one or more product groups in this ATM.",
    underSupplyPlanWarningProduct: "You're below the available supply plan for this product group",
    underSupplyPlanWarningProductPast:
        'You were below the available supply plan for this product group',
    orderWeek: 'Order Week',

    // -- MAKE IT BULK ATM DETAILS -- //
    requestedWeek: 'Requested Week',
    requestedWeekAndPONumber: 'Requested Week and PO Number',
    remainingInSupplyPlan: 'Remaining in Supply Plan',
    requestedQuantity: 'Requested Quantity',
    cannotExportDetails:
        'You cannot export order details until a Ball Production Order Number is assigned',

    // -- MAKE IT BULK ATM REVIEW -- //
    linkedDeliveryOrderOrLeadTimeWarning:
        'One or more products cannot be edited because of lead times.',
    makeItAtmDetails: 'Make It ATM Details',
    lineNumber: 'Line #',
    sameWeekShipTosWarning:
        'There are additional Make It Order(s) for this week. Please see <1>Make It Summary</1> page for details.',
    sameWeekShipTosDraftsWarning:
        'There are draft Make It Order(s) for this week. Please see <1>Make It Drafts</1> page for details.',

    // -- ASSIGNED SHIP TOS -- //
    yourAssignedShipTos: 'Your Assigned Ship Tos',
    shipToName: 'Ship To Name',
    shipToAddress: 'Ship To Address',
    shipTos: 'Ship Tos',
    date: 'Date',

    // -- PLAN IT -- //
    forecast: 'Forecast',
    planItplanItSummary: 'Plan It / Plan It Summary',
    forecastOrderNumber: 'Forecast Order Number',
    forecastWeek: 'Forecast Week',
    forecastNum: 'Forecast #',
    forecastDetails: 'Forecast Details',
    forecastedQuantity: 'Forecasted Quantity',
    forecastedQuantityPL: 'forecasted quantity (PL)',
    forecastedQuantityEA: 'forecasted quantity (EA)',
    totalForecastedQuantity: 'Total Forecasted Quantity',
    forecastCreateMappingText: 'Map Forecast Template Values',
    forecastUpdateMappingText: 'Update Forecast Template Values',
    forecastUploadSectionHeader: 'Upload Your Forecast',
    planItSummary: 'Plan It Summary',
    planItDashboardDesc:
        'Create and submit your order forecast. Update a previously submitted forecast through bulk upload.',
    submitPlanIt: 'Submit Plan It',
    createPlanItLink: 'Create / Update Plan It',
    createAPlanIt: 'Create a Plan It',
    createAMakeIt: 'Create a Make It',
    planItSummaryDashboardDesc:
        'View previously submitted order forecasts. Edit future order forecasts.',
    viewPlanItSummary: 'View Plan It Summary',
    selectProductsPlanIt: 'Add products to your forecast:',
    selecteForecastWeeks: 'Select your forecast week(s) to continue.',
    yourForecast: 'Your Forecast',
    createForecast: 'Create forecast',
    addInstructionsPlanIt: `Build your forecast by clicking the "ADD" button for each product you'd like to include.`,
    planItGuidedPrompt: 'Do you want to submit a Plan It without a file upload?',
    submitForecast: 'Submit Forecast',
    thankYouForecast: 'Thank You, Your Forecast(s) have been submitted',
    forecasts: 'Forecasts',
    previousForecasts: 'Previous Forecasts',
    copyToMakeIt: 'Copy To Make It',
    replicateForecast: 'Replicate Forecast',
    productIncluded: 'Product included on previous forecast. Set quantity to 0 to remove.',
    noEligiblePlanItRecords: 'No eligible Plan It records exist for the current location.',
    visitPlanIt: 'Visit Plan It',
    toCreate: 'to create one.',
    makeItMoq: 'Make It Minimum Order Quantity',
    planItGuided: 'Plan It Guided',

    // -- SCRAP IT -- //
    scrapIt: 'Scrap It',
    availablePallets: 'Available Pallets',
    inputPallets: 'Input Pallets',
    createRequest: 'Create Request',
    selectQuantity: 'Select quantity from balances to scrap:',
    yourRequest: 'Your Request',
    youCanReview: 'You can review selected products on the next screen.',
    quantityToScrap: 'Quantity to Scrap',
    scrapItOrderNumber: 'Scrap It Request Order Number',
    totalAvailableBalance: 'Total Available Balance',
    cannotExceedAvailableBalance: 'Cannot exceed Available Balance',
    reviewScrapItRequest: 'Review Scrap It Request',
    scrapItRequest: 'Scrap It Request',
    scrapItRequestOrderNumber: 'Scrap It Request Order #',

    // -- USER ADMIN REQUEST ACCESS -- //
    requestAccess: 'Request Access',
    locationInformation: 'Location Information',
    addAnotherShipTo: 'Add Another Ship To / Bill To #',
    confirm: 'confirm',
    invalidIds:
        'One or more ID numbers are not valid. Please update or remove these locations to proceed.',
    shipToBillTo: 'Ship To / Bill To #',
    enterComment: 'Enter Comment',
    rejectAction: 'Reject',
    requestAccessUnauthTitle: "Looks like you don't have access to {{area}} yet",
    requestAccessUnauthBody:
        'No problem! Use the button below to request access from your administrator for ',
    accessRequest: 'Access request',
    requestAccessSuccess: `Your access request has been successfully submitted and will be reviewed by
    the corresponding User Administrator(s).`,
    requestAccessError: 'No administrator assigned for the requested account(s)',
    confirmation: 'Confirmation',
    invitationSent: 'Your invitation to join <i>The Source</i> has been sent.',

    // -- USER ADMIN -- //
    pendingRegistration: 'Pending Registration',
    pendingRequests: 'Pending Requests',
    addUser: 'Add User',
    roles: 'Role(s)',
    location: 'Location',
    reviewRequest: 'Review Request',
    sentOn: 'Sent On',
    disable: 'Disable',
    resend: 'Resend',
    disableUser: 'Disable User',
    disableUserWarning:
        'You are about to disable this user’s permissions to your account. They will need to create a new request to regain permissions.',
    disableUserContinue: 'Are you sure you want to continue?',
    yesDisable: 'Yes, Disable',
    viewEdit: 'View/Edit',
    userInformation: 'User Information',
    confirmEmail: 'Confirm Email',
    newUserEmailHelper: 'Email@domain.com',
    locationRoleAccessTableInst: `Choose the role or roles to give this user access per location. View a role's available permissions by clicking the role name or`,
    locationRoleAccessTableRequest: `Choose the role or roles you are requesting for this location. View a role's available permissions by clicking the role name or`,
    locationRoleAccessTableInstLink:
        'view a comparison of all roles and their available permissions.',
    allAccess: 'All Access',
    coPacker: 'Co-Packer',
    warehousingLogistics: 'Warehousing/Logistics',
    acctAdmin: 'Acct. Admin',
    acctPayableReceivable: 'Acct. Payable/Receivable',
    graphicsVendor: 'Graphics Vendor',
    pricingContact: 'Pricing Contact',
    rolesLocations: 'Roles (# of Locations)',
    activeUsers: 'Active Users',
    inactiveUsers: 'Inactive Users',
    makeUserActive: 'Make User Active',
    makeUserInactive: 'Make User Inactive',
    activeWarning: 'You are about to activate this user’s account.',
    inActiveWarning: 'You are about to inactivate this user’s account.',
    accountAdmin: 'Account Admin',
    arAp: 'AR/AP',
    artMgmt: 'Art Mgt.',
    forecastMgmt: 'Forecast Mgt.',
    marketingMgmt: 'Marketing Mgt.',
    orderFulfillment: 'Order Fulfillment',
    packagingMgmt: 'Packaging Mgt.',
    procurementMgmt: 'Procurement Mgt.',
    makeItOnly: 'Make It Only',
    shipItOnly: 'Ship It Only',
    planItOnly: 'Plan It Only',
    impersonation: 'Impersonation',
    roleAccessTableSearchPlaceholder: 'Search...',
    confirmChangesWillNotBeSaved:
        'Any changes made will not be saved. Are you sure you want to proceed?',
    disableUserError: 'The system was unable to disable the selected user',
    locationRoleAccess: 'Location & Role Access',
    latestSubmissionDate: 'Latest Submission Date',
    resubmit: 'Resubmit',
    cancelRequest: 'Cancel Request',
    userAdminSuccessMessage: 'User permissions have been updated',
    userAdminAccessRequired: 'User Administrator permissions are required to make changes.',
    notAdminListedAccounts: 'You are not a User Administrator for some of the listed accounts.',
    noAdminforLocation: 'You are not an Admin for this Location.',
    inactiveAccountWarning:
        'This account is inactive, contact your Ball representative to re-activate.',
    roleComparison: 'The Source Role Comparison',
    graphicsIntakeForm: 'Graphics Intake Form',
    draftGraphicsIntakeForms: 'Draft Graphics Intake Forms',
    inProgressGraphicsStatuses: 'In Progress Graphics Statuses',
    graphicsQuote: 'Graphics Quote',
    graphicsProof: 'Graphics Proof',
    graphicsStandardPilotCan: 'Graphics Standard (Pilot Can)',
    recentlyCompletedGraphics: 'Recently Completed Graphics',
    planning: 'Planning',
    newOpenProductionOrders: 'New / Open Production Orders',
    newOpenDeliveryOrders: 'New / Open Delivery Orders',
    draftDeliveryOrders: 'Draft Delivery Orders',
    paymentsCIAOnly: 'Payments (CIA Only)',
    orderPaymentStatus: 'Order Payment Status',
    orderPaymentFinancials: 'Order Payment Financials',
    liquidTesting: 'Liquid Testing',
    liquidIntakeForm: 'Liquid Intake Form',
    warrantyLetter: 'Warranty Letter',
    termsandConditions: 'Terms and Conditions',
    temporarilyHideSKUs: 'Temporarily Hide SKUs',
    manageBillTo: 'Manage Bill-To',
    manageShipTo: 'Manage Ship-To',
    customerProductIDMappingtoBallIDs: 'Customer Product ID Mapping to Ball IDs',
    createNewUser: 'Create New User',
    editpermissions: 'Edit permissions',
    disablePermissions: 'Disable Permissions',
    troubleshooting: 'Troubleshooting',
    procurementManagement: 'Procurement Management',
    forecastManagement: 'Forecast Management',
    packagingManagement: 'Packaging Management',
    artManagement: 'Art Management',
    marketingManagement: 'Marketing Management',
    accountsPayableAccountsReceivable: 'Accounts Payable / Accounts Receivable',
    userAdministrator: 'User Administrator',
    selectedAccess: 'Selected Access',
    reviewAccessRequest: 'Review Access Request',
    pricing: 'Pricing',
    termsAndConditions: 'Terms and Conditions',
    contractAcknowledgement: 'Acknowledgement',
    pricingBreakdown: 'Pricing Breakdown',
    permissionsFailedToUpdate: 'The requested changes failed to submit due to an unknown error.',
    pricingAcknowledgeTitle:
        'Please confirm your acknowledgement of the updated terms to your account',
    pricingAcknowledgeSubheader:
        'By selecting "Acknowledge" you hereby agree to the Terms & Conditions and Pricing set by Ball Corporation for your selected account(s).',
    pricingAcknowledgeQuestion: 'Do you acknowledge the terms?',
    pricingWarningText: `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`,

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Download .PDF',
    effectiveDate: 'Effective Date',
    currency: 'Currency',
    itemDescription: 'Item Description',
    currentBasePrice: 'Current Base Price',
    newBasePrice: 'New Base Price',
    freightChargeFromLocation: 'Freight Charge From Location (if available)',
    freightChargesAvailable: 'Freight Charges (if available)',
    newBaseWithFreight: 'New Base Price With Freight (if available)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',

    // -- KB MAX -- //
    errorProcessing: 'There has been an error processing.',
    exitWarning: 'Are you sure you want to exit?',
    yesExit: 'Yes, Exit',

    // -- LIQUID TESTING -- //
    liquidTestThankYou: 'Thank you for submitting your liquid test information',
    liquidTestIntakeForm: 'Liquid Test Intake Form',
    liquidTestIntakeFormPrintInstructions:
        'If you did not print the previously displayed form, please print here and include it when you mail your liquid sample. You may also print or download a copy for your records.',
    liquidTestIntakeFormPrintInstructionsNoTest:
        'You may print or download your intake form for your records here.',
    noLiquidTestNeeded:
        'Based on this information, your liquid does not need to be submitted for liquid testing.',
    aceticAcid: 'Acetic Acid',
    addedSulfites: 'Added Sulfites',
    calciumLactate: 'Calcium Lactate',
    foodDyes: '> 80 ppm Food Dyes',
    lacticAcid: 'Lactic Acid',
    malicAcid: 'Malic Acid',
    liquidTest: 'Liquid Test',
    beverageInformation: 'Beverage Information',
    alcoholByVolumePercentage: 'ABV%',
    alcoholByVolumeHelper: 'ABV must be a number between 0 and 100',
    potentialOfHydrogen: 'pH',
    potentialOfHydrogenRange: 'pH must be a number between 0 and 14',
    fourIngredientBeerFlag: '4 Ingredient Beer',
    beverageDescription: 'Beverage Description',
    isRefrigerationRequired: 'Is Refrigeration Required?',
    concentrationSample: 'Syrup Sample',
    beverageContains: 'Beverage Contains',
    instructions: 'Instructions',
    liquidIntakeTestRequired:
        'Please print this form and include it in the package in which you ship your liquid sample. Ship to shipping address to the right.',
    liquidIntakeTestNotRequired:
        'Based on the information provided, your liquid does not need to be submitted for liquid testing. Please print this form for your records.',
    newProduct: 'New Product',
    updatedRecipe: 'Updated Recipe',
    beverageNameOrProjectName: 'Please provide at least one of the following:',
    graphicIdAssociated: 'Do you have a graphic ID associated with this beverage?',
    doNotKnowPH: "I don't know my pH",
    fourIngredientBeer: 'Is this a simple four ingredient beer?',
    newProductOrUpdate: 'Is this a new product or an updated recipe of an existing product?',
    doesBeverageContain: 'Does your beverage contain:',
    isConcentrateSample: 'Is this a syrup sample?',
    throwRatio: 'Throw Ratio',
    formSubmissionError: 'The system was unable to submit the form. Please try again later.',
    requestError: 'The system was unable to process your request. Please try again later.',
    formSubmissionErrorContact:
        'The system was unable to submit the form. Please try again later, if the issue persists please contact your account representative.',
    submittingUser: 'Submitting User',
    submittedDate: 'Submitted Date',
    testingRequired: 'Testing Required?',
    beverageName: 'Beverage Name',
    liquidTestDocuments: 'Liquid Test Documents',
    liquidTestId: 'Liquid Test ID',
    printLiquidTestIntake: 'Print Liquid Test Intake Form',
    packingSlip: 'Packing Slip',
    intakeStatusARS: 'Awaiting Receipt of Sample',
    intakeStatusNTR: 'No Test Required',
    intakeStatusRPT: 'Received, Pending Testing',
    intakeStatusWAQ: 'Warranty Approved, but Qualified',
    intakeStatusWRA: 'Warranty Approved',
    intakeStatusWRR: 'Warranty Rejected, Resubmit',
    printIntakeForm: 'Print Intake Form',
    closedDate: 'Closed Date',
    warrantyDetails:
        'For warranty details on approved liquid tests, please refer to your Contract or Terms & Conditions.',
    beverageNameOrProjectNameRequired: 'Beverage Name or Project Name Required',
    phone: 'Phone',
    viewPrint: 'View /Print',
    cannotImportAlcohol: 'Ball cannot import alcohol. For support contact: ',

    // -- ONBOARDING WIZARD -- //
    saveProgressSecondaryText: 'Your draft has been saved successfully',
    joinBallBanner: 'Join Ball',
    joiningBall: 'Joining Ball',
    step: 'Step',
    gettingStarted: 'Getting Started',
    gettingStartedText:
        'We’re glad you want to take the next step with us. Before you can create your products, we’ll need additional information from you.',
    congratulations: 'Congratulations!',
    onboardingCongratulationsText:
        'Your account has been set up and you are ready to begin using The Source. Before you can place your first order, you must:',
    onboardingCongratulationsAdditionalText:
        'You can also add members of your company by using the User Admin feature in the header above or by',
    clickingHere: 'clicking here',
    submitAGraphic: 'Submit a Graphic',
    submitLiquidSample: 'Submit a Liquid Sample for Testing',
    productSelectionHeader: 'Confirmed Product Selection and Supply Capacity',
    productSelectionText:
        'Good news! The product you selected is available and we can move forward with setting you up as a Ball customer. You will need to complete the steps below and then you will be ready to place an order.',
    yourProducts: 'Your Product(s)',
    completeStatus: 'Completed',
    accountSetupHeader: 'Account Setup',
    accountSetupTCTitle: 'Terms & Conditions',
    accountSetupTCText:
        'You, or an authorized individual, will have to accept Ball’s terms and conditions before continuing through the onboarding process.',
    accountSetupReviewTCAction: 'Review Terms & Conditions',
    billAndShipTitle: 'Billing & Shipping',
    billAndShipText:
        'It’s important we have the correct billing and shipping information for your company.',
    billAndShipAction: 'Enter Information',
    salesAndUseTitle: 'Sales & Use Tax',
    salesAndUseText:
        'US-based customers are required to fill out a Sales and Use Tax form for the state they operate in.',
    salesAndUseAction: 'Enter Information',
    continue: 'Continue',
    creditApplicationTitle: 'Credit Application',
    creditApplicationText:
        'Interested in applying for credit with Ball? Fill out this application to get the process started.',
    creditApplicationAction: 'Start Application',
    creditApplicationActionContinue: 'Continue Application',
    productSetupHeader: 'Product Setup',
    graphicsTitle: 'Graphics',
    graphicsText:
        'Here is the fun part! Start the graphics process by filling out the graphics intake form and submitting your artwork.',
    graphicsAction: 'Start Graphics Process',
    liquidTitle: 'Liquid Testing',
    liquidText:
        'Your beverage may need to go through compatibility testing.  To begin the testing process please fill out our online form and mail in your liquid sample(s).',
    liquidAction: 'Start testing process',
    placeYourOrderHeader: 'Place Your Order',
    placeYourOrderText: 'The time has come! Place your order for your product on your dashboard!',
    placeYourOrderAction: 'Make It',
    optionalStepText: 'Optional',
    accountInformation: 'Account Information',
    billingAddress: 'Billing Address',
    shippingInformation: 'Shipping Information',
    accountSetup: 'Account Setup',
    billMailingName: 'Bill Mailing Name',
    billingAddressLine1: 'Billing Address Line 1',
    billingAddressLine1Helper: 'Street Type, Street Name, House Number',
    billingAddressLine2: 'Billing Address Line 2 (Optional)',
    billingAddressLine2Helper: 'Neighborhood, Municipality',
    billingAddressLine3: 'Billing Address Line 3 (Optional)',
    billMailingNameHelper: 'Name of person who will be receiving communications',
    shippingSameAsBillingAddress: 'Is your shipping address the same as your billing address?',
    shippingName: 'Shipping Name',
    shippingAddressLine1: 'Shipping Address Line 1',
    shippingAddressLine1Helper: 'Street Type, Street Name, House Number',
    shippingAddressLine2: 'Shipping Address Line 2 (Optional)',
    shippingAddressLine2Helper: 'Neighborhood, Municipality',
    shippingAddressLine3: 'Shipping Address Line 3 (Optional)',
    shippingNameHelper: 'Name of person who will be receiving shipments',
    shippedOrDelivered: 'Do you need your cans shipped to you or will you be picking them up?',
    palletConfiguration: 'Pallet Configuration',
    anySpecialEquipmentNeeds: 'Any Special Equipment Needs?',
    cfdi: 'CFDI',
    taxId: 'Tax ID',
    legalEntityOrPrivateEntrepreneur: 'Are you a Legal Entity or Private Party Entrepreneur?',
    importerOfRecord: 'Importer of Record',
    customer: 'Customer',
    requiredDocuments: 'Required Documents',
    entrepreneur: 'Entrepreneur',
    legalEntity: 'Legal Entity',
    reqDocInstructionHeader: 'You must provide the following documents:',
    legalEntReqDocInstruction1: 'Operating Agreement',
    legalEntReqDocInstruction2:
        'Legal Representative Empowerment Letter (if not included in Operating Agreement)',
    legalEntReqDocInstruction3: 'Legal Representative ID',
    legalEntReqDocInstruction4: 'SAT Registration',
    legalEntReqDocInstruction5: 'Company Proof of Address',
    entrepreneurReqDocInstruction1: 'SAT Situation Letter (Mexico IRS document)',
    entrepreneurReqDocInstruction2: 'INE card (Mexico Official ID)',
    entrepreneurReqDocInstruction3: 'Proof of address (phone bill or service bill)',
    accountSetupFailedToSubmit: 'Account Setup form failed to submit',
    accountSetupCantSaveDraft: 'Saving Draft Failed',
    invalidDataMessage: 'Please correct or remove any invalid data before saving your draft',
    inviteToHelpComplete: 'Invite someone to help complete a task',
    inviteModalHelperText:
        'You can invite others to fill out information in the joining process. Enter their email below and select the areas you want them to complete. They will receive an email to complete their assigned section.',
    inviteUserSuccess: 'Users have been invited successfully',
    informationReceived: 'Your information has been received and is being processed.',
    usOnly: '(US Only)',
    moreProductsThanShown:
        'More products are approved but not shown. Go to <1>My Projects</1> to learn more.',

    // -- CREDIT APPLICATION FORM -- //
    companyInformation: 'Company Information',
    supplierReferences: 'Supplier References',
    additionalCommentsRequired: 'Additional Comments',
    currentFinancialStatement: 'Current Financial Statement',
    creditAppListHeader: 'In order to apply for credit with Ball, you must supply the following:',
    creditAppFirstLI:
        'Company information including but not limited to monthly line of credit desired, contact info for account payable department, billing and actual location addresses, names and titles of company owner/officers/partners, ect.',
    creditAppSecondLI:
        '3 supplier references with amounts equal or greater than the monthly line of credit desired',
    creditAppThirdLI: 'Current financial statement of explanation of why it is not present',
    legalNameOfBusiness: 'Legal Name of Business',
    businessName: 'Business Name (if different)',
    businessNameHelper: 'If division, subsidiary',
    typeOfBusiness: 'Type of Business',
    monthlyLineOfCredit: 'Monthly Line of Credit Desired',
    billingCountry: 'Billing Country',
    differentRealAddress: 'Is your actual location address the same as your billing address?',
    accountsPayableContact: 'Accounts Payable Contact',
    accountsPayableContactEmail: 'Accounts Payable Contact Email',
    stateOfIncorporation: 'State of Incorporation',
    dateEstablished: 'Date Established',
    companyInfoHelper: 'Provide the information for your company officers, partners, or owner.',
    supplierReferenceHelper:
        'You must supply three (3) references with amounts equal to or greater than line of credit requested!',
    arContact: 'A/R Contact',
    arEmail: 'A/R Email',
    faxNumber: 'Fax Number',
    financialStatementHelper:
        'If you save your progress and exit the form, you will be asked to upload this file when you return. The file will not be saved.',
    explanationHelper:
        'If you are not uploading a current financial statement, please explain why.',
    addAnother: 'Add Another',
    creditApplicationTitleText:
        'Interested in applying for credit with Ball? Fill out this application to get the process started.',
    explanation: 'Explanation',
    actualMailingName: 'Actual Location Mailing Name',
    actualCountry: 'Actual Location Country',
    actualAddressLine1: 'Actual Location Address Line 1',
    actualAddressLine2: 'Actual Location Address Line 2 (Optional)',
    actualAddressLine3: 'Actual Location Address Line 3 (Optional)',
    actualCity: 'Actual Location City',
    actualStateProvince: 'Actual Location State / Province',
    actualPostalCode: 'Actual Location Postal Code',
    numericInput: 'Invalid Monetary Input',
    usePreviouslyEnteredBillingAddress: 'Use previously entered Billing Address?',

    // -- TERMS AND CONDITIONS -- //
    acceptTermsAndConditions: 'Acknowledge Terms & Conditions',
    authorizedTermsAndConditions:
        'Are you authorized to acknowledge terms and conditions on behalf of your company?',
    readAndAgree: 'I have read and agree to all of the above terms and conditions.',
    adminNotified: 'Your administrator has been notified of your response.',

    // -- SALES AND USE TAX -- //
    returnToDashboard: 'Return To Dashboard',
    salesAndUseTaxBanner: 'Account Setup',
    instructionsAnchorText: 'Instructions',
    sellerInformationAnchorText: 'Seller Information',
    buyerInformationAnchorText: 'Buyer Information',
    eSignatureAnchorText: 'E-Signature',
    salesAndUseTaxSideHeader: 'Sales & Use Tax',
    salesUseTaxInstructionsLinkText: 'State-Specific Instructions',
    salesUseTaxInstructionsHeader: 'Instructions Regarding Uniform Sales and Use Tax Certificate',
    salesUseTaxInstructionsBody1: 'To Seller’s Customers:',
    salesUseTaxInstructionsBody2: `In order to comply with the majority of state and local sales tax law
    requirements, the seller must have in its files a properly executed exemption
    certificate from all of its customers who claim a sales tax exemption. If the
    seller does not have this certificate, it is obliged to collect the tax for the
    state in which the property or service is delivered.`,
    salesUseTaxInstructionsBody3: `If the buyer is entitled to sales tax exemption, the buyer should complete the
    certificate and send it to the seller at its earliest convenience. If the buyer
    purchases tax free for a reason for which this form does not provide, the buyer
    should send the seller its special certificate or statement.`,
    salesUseTaxInstructionsBody4: `Caution to Seller:`,
    salesUseTaxInstructionsBody5: `In order for the certificate to be accepted in good faith by the seller, seller
    must exercise care that the property or service being sold is of a type normally
    sold wholesale, resold, leased, rented, or incorporated as an ingredient or
    component part of a product manufactured by buyer and then resold in the usual
    course of its business. A seller failing to exercise due care could be held
    liable for the sales tax due in some states or cities. Misuse of this
    certificate by seller, lessee, or the representative thereof may be punishable
    by fine, imprisonment, or loss of right to issue certificate in some states or
    cities.`,
    sellerInformationCheckboxSubheader: 'Issued to seller (select all that apply):',
    typeOfCertificateLabel: 'Type of Certificate',
    blanketCertificateCheckbox: 'Blanket Certificate',
    singlePurchaseCheckbox: 'Single Purchase Certificate Relates to Invoice / Purchase Order #:',
    nameOfFirmLabel: 'Name of Firm',
    certifyBody: 'I certify that:',
    buyerSubtext: 'Buyer',
    engagedAsRegisteredLabel: 'Is engaged as registered:',
    wholesalerCheck: 'Wholesaler',
    retailerCheck: 'Retailer',
    manufacturerCheck: 'Manufacturer',
    sellerCheck: 'Seller (California)',
    lessorCheck: 'Lessor',
    otherCheck: 'Other (specify)',
    registeredBody2: `and is registered with the below-listed states and cities within which your firm would deliver purchases to us and that any such purchases are for wholesale, resale, ingredients or components of a new product or service to be resold, leased, or rented in the normal course of business. We are in the business of wholesaling, retailing, manufacturing, leasing (renting) the following:`,
    descriptionOfBusinessLabel: 'Description of Business',
    descriptionOfPropertyLabel:
        'General Description of Tangible Property or Taxable Services to be Purchased from the Seller',
    stateSelect: 'Select State',
    registrationNumberLabel:
        'State Registration, Seller’s Permit, or ID Number of Purchaser (Optional)',
    addAnotherStateLabel: 'Add another state',
    eSignatureHeader: 'E-Signature',
    eSigCertify:
        'I further certify that if any property or service so purchased tax free is used or consumed by the firm as to make it subject to a Sales or use Tax we will pay the tax due directly to the proper taxing authority when state law so provides or inform the seller for added tax billing. This certificate shall be a part of each order which we may hereafter give to you, unless otherwise specified, and shall be valid until canceled by us in writing or revoked by the city or state. Under penalties of perjury, I swear or affirm that the information on this form is true and correct as to every material matter.',
    saveProgressText: 'Please correct or remove any invalid data before saving your draft',
    selectAtLeastOne: 'Required to select at least one.',

    // -- BILLING AND SHIPPING -- //
    shipped: 'Shipped',
    identificationInformation: 'Identification Information',
    ball: 'Ball',

    // -- CAPACITY CHECKS -- //
    myProjectsCapacityCheckStatus: 'My Projects / Capacity Check Status',
    capacityCheck: 'Capacity Check',
    capacityCheckSuccessMessage:
        'Your request has been submitted! Reviews typically take 2-4 weeks. Someone from our team will contact you once complete.',

    // -- ATM PRODUCT PLANNING -- //
    supplyPlanAttributes: 'Supply Plan Attributes',
    makeItATMOrders: 'Make It ATM Order(s)',

    // -- PLAN IT BULK UPLOAD -- //,
    planIt: 'Plan It',
    productAlerts: 'Product Alerts',
    forecastBulkUpload: 'Forecast Bulk Upload',
    supplyPlan: 'Supply Plan',
    productNotRecognized: 'Product Not Recognized',
    includedProductsNotRecognized:
        'One or more of the products included in this upload is not recognized.',
    here: 'here',
    unrecognizedCorrectIt:
        'One or more of the product IDs included in this upload is not recognized. If a Product ID is incorrect, please go back to correct it and re-upload the plan it.',
    unrecognizedIdentified: 'Unrecognized Products Identified',
    unrecognizedContinue:
        'If you wish to continue with unrecognized products included, fill out the table below including the desired products you wish to add to your forecast. The information will be sent to our team to review and reconcile. You will receive a notification regarding the status of your product. Please note that unrecognized products will not be available for Make It or Ship It orders until a resolution is received.',
    ifProductIncorrect:
        'If a product ID is incorrect, please go back to correct it and re-upload the plan. Products not recognized by the Source will not be included in this forecast.',
    continueWithYourForecast:
        "To continue with your forecast without the unrecognized products, click 'Continue.'",
    editProductsCheckStatus:
        'If you want to edit products or check the status of recently added products, you can manage your products <1>here</1>.',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'The request window for {0} will close on {1}.  The first available production date is {2}. ',
    CAMPAIGN_UPCOMING:
        'The request window for {0} will begin on {1}.  Requests must be placed by {2} and the first available production date is {3}. ',
    CAMPAIGN_UPDATE:
        'Request window information for your {0} product has changed.  The updated request window is: {1} - {2} and the first available production date is {3}. ',
    ALLOCATIONS: 'You have a new Supply plan for the week(s) of: {0}.  ',
    NEW_PRODUCT:
        'A new product has been added to your product portfolio: {0} - {1}.  You are now able to add this product to your orders. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Created Date',
    unauthorizedPoTooltip: 'Not authorized to manage POs for this location',
    discard: 'Discard',
    viewDraft: 'View Draft',
    archiveDraftWarning: 'Archived drafts will expire and no longer show in archive after 30 days.',
    resumeOrder: 'Resume Order',
    lockWindowWarning: 'This week will be locked for ordering in {0} days',
    draftDisplayWarning: 'Drafts will no longer show after the lock window begins',
    discardDraftAreYouSure: 'Are you sure you want to discard your draft?',
    discardDraftWillBeDeleted: 'Your draft order will be deleted.',
    openDrafts: 'Open Drafts',
    archivedDrafts: 'Archived Drafts',
    restoreDraft: 'Restore Draft',
    selectWeek: 'Select Week',
    restore: 'Restore',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Manage Products',
    productInformation: 'Product Information',
    activeProducts: 'Active Products',
    inactiveProducts: 'Inactive Products',
    unrecognizedProducts: 'Unrecognized Products',
    displayName: 'Display Name',
    pendingDataChangeAndDeactivation: 'Pending Data Change & Deactivation',
    pendingDataChangeAndActivation: 'Pending Data Change & Activation',
    productSizeAndShape: 'Product Size/Shape',
    productsNotRecognized:
        'These products were included on a Plan It file but are not recognized by Ball.',

    // Countries
    usa: 'USA',
    eu: 'EU',
    uk: 'United Kingdom',
    germany: 'Germany',
    france: 'France',
    canada: 'Canada',
    bahama: 'Bahama',
    aruba: 'Aruba',
    honduras: 'Honduras',
    guatemala: 'Guatemala',
    china: 'China',
    japan: 'Japan',
    southKorea: 'South Korea',
    ausNZ: 'AUS/NZ',
    australia: 'Australia',
    nz: 'New Zealand',
    mexico: 'Mexico',
    india: 'India',
    vietnam: 'Vietnam',
    taiwan: 'Taiwan',
    russia: 'Russia',
    argentina: 'Argentina',
    brazil: 'Brazil',
    paraguay: 'Paraguay',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    noCountryApplies: 'None of the specified countries apply',

    // -- PRODUCT STATUS CHANGES -- //
    PSC00001: 'Pending Data Change',
    PSC00002: 'Pending Activation',
    PSC00003: 'Pending Deactivation',
    PSC00004: 'Pending Plate Change',

    // -- EDIT PRODUCT MODAL -- //
    editProductUpdateFields: 'Edit fields below to update product information.',

    // -- INACTIVE PRODUCTS MODAL -- //
    productAlertsFound: '({0}) Product Alerts Found in Uploaded File',
    inactiveProductsIdentified: 'Inactive Products Identified',
    inactiveProductsSubheader:
        'We have identified inactive products on one or more of the forecasts you are trying to convert. These products will not be included on your Make it.  Please reach out to your Account Representative if you have questions.',
    proceedWithConversionText: 'Click continue to proceed with conversion.',
    identifiedTheFollowingInactiveProducts:
        'We have identified the following inactive products included in your file',
    csvLineNumber: 'Line Number',
    inactiveProductsMustBeActivated:
        'Inactive Products must be activated prior to creating a Make it.  Please reach out to your Ball contact to discuss how to activate products.',
    prodID: 'Product ID',

    // -- ACTIVATE/DEACTIVATE PRODUCT MODALS -- //
    selectProjectedOrderDate: 'Select Projected Order Date',
    restrictedDays: 'Restricted Days',
    availableDays: 'Available Days',
    confirmActivation: 'Are you sure you want to activate this product?',
    activateHelper:
        'Requests to activate a product will be submitted and a notification will be sent to alert of status. Product will remain inactive until approval sent.',
    activateProduct: 'Activate Product',

    // -- MANAGE LOCATIONS -- //
    billToLocation: 'Bill To Location',

    // -- ADD WEEK MODAL -- //
    selectWeekToAdd: 'Select a week to add to your Make It:',

    // -- CONTRACTS AND DOCUMENTS -- //
    contractSummary: 'Summary',
    approvalDate: 'Approval Date',
    expirationDate: 'Expiration Date',
    contractActions: 'Action Needed',
    activeDocuments: 'Active Documents',
    archivedDocuments: 'Archived Documents',
    acknowledge: 'Acknowledge',
    acknowledgementsRequired:
        'One or more Bill Tos have documents that require acknowledgment, please review.',
    acknowledgementRequired:
        'This Bill To has documents that require acknowledgment, please review.',
    selectWeekToAddPlanIt: 'Select a week to add to your Plan It:',

    // -- ADD SHIP TO -- //
    customerName: 'Customer Name',
    customerId: 'Customer ID',
    customerAddress: 'Customer Address',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2 (Optional)',
    addressLine3: 'Address Line 3 (Optional)',
    vat: 'VAT',
    cfid: 'CFID',
    userAdminContact: 'User Admin Contact',
    userAdminPhoneNumber: 'User Admin Phone Number',
    userAdminEmail: 'User Admin Email',
    freightInformation: 'Freight Information',
    orderingInformation: 'Ordering Information',
    basecoat: 'Basecoat',
    tactile: 'Tactile',
    satin: 'Satin',
    laserTab: 'Laser Tab',
    punch: 'Punch',
    coloredTab: 'Colored Tab',
    coloredShell: 'Colored Shell',
    selectProductsShort: 'Select Products',
    anticipatedItems: 'Anticipated Items',
    addItem: 'Add Item',
    both: 'Both',
    specialtyPrinting: 'Specialty Printing (Optional)',
    specialtyTab: 'Specialty Tab (Optional)',
    ballItemNumberOrGraphicNumber: 'Ball Item Number/Graphic Number',
    ballItemNumberOrGraphicNumberOptional: 'Ball Item Number/Graphic Number (Optional)',
    labelName: 'Label Name',
    labelNameOptional: 'Label Name (Optional)',
    viewPalletSpecs: 'See pallet specifications on ',
    specialEquipmentTooltip: 'Roller bed truck, Pallet jack, etc.',
    specialEquipmentOptional: 'Special Equipment (Optional)',
    layersPerPallet: 'Layers per Pallet',
    canFreightManagement: 'Can Freight Management',
    endFreight: 'End Freight',
    canSpecifications: 'Can Specifications',
    blanket: 'Blanket',
    cashInAdvance: 'Cash-in-Advance (CIA)',
    estimatedAnnualVolume: 'Estimated Annual Volume',
    ballManaged: 'Ball Managed',
    customerNumber: 'Customer Number',
    specialtyPrintingReview: 'Specialty Printing',
    specialtyTabs: 'Specialty Tabs',
    specialtyEquipment: 'Specialty Equipment',
    requestNewShipTo: 'Request New Ship To',
    reviewAndSubmit: 'Review And Submit',
    shipToRequestSubmitted:
        'Your request to add a new Ship To location has been submitted. You will receive a notification once the request is reviewed.',
    shipToRequestSave: 'Your form is also available to view. Please save this for your records.',
    acknowledgementSuccessMessage: 'Acknowledgement to the terms and conditions was successful.',
    acknowledgementErrorMessage:
        'Acknowledge to the terms and conditions failed. Please try again.',

    // -- FEEDBACK SURVEY -- //
    feedbackHeader: "We'd love your feedback!",
    satisfaction: 'Overall, how satisfied are you with The Source?',
    enterScore: 'Please enter a score',
    visitPurpose: 'What was the main purpose for your visit today?',
    selectOption: 'Please select option',
    'Learn about working with Ball': 'Learn about working with Ball',
    'Get Design Inspiration': 'Get Design Inspiration',
    'Investigate Lab and Liquid': 'Investigate Lab and Liquid',
    'Explore Sustainability & Regulatory': 'Explore Sustainability & Regulatory',
    'Learn about Handling & Filling': 'Learn about Handling & Filling',
    'Access Can Specifications': 'Access Can Specifications',
    'Obtain Pricing Information': 'Obtain Pricing Information',
    Other: 'Other',
    pleaseSpecify: 'Please specify:',
    suggestionLabel: 'Is there anything we can do to improve your visit to The Source?',
    contactPreferenceLabel:
        'If we need clarification on any of your responses, may we contact you?',
    'Yes, I prefer a phone call': 'Yes, I prefer a phone call',
    'Yes, I prefer email': 'Yes, I prefer email',
    'No, please do not contact me': 'No, please do not contact me',
    selectContactPreference: 'Please select a contact preference',
    nameLabel: 'Name:',
    phoneLabel: 'Phone number:',
    emailLabel: 'Email address:',
    emailError: 'Please enter a valid email',
    closeButton: 'Close',
    submitButton: 'Submit',
    feedbackThankYou: 'Thank You For Providing Feedback',
    feedbackPrivacyPolicy: 'Privacy Policy',
    privacyPolicyMessage: 'Your responses will be used in accordance with our',

    // OrderingNavV2
    createNewOrderTranslationKey: 'Create Orders',
    createNewOrderSecondaryTranslationKey: 'Create a new Order',
    bulkUploadTranslationKey: 'Bulk Upload',
    bulkUploadSecondaryTranslationKey: 'Upload Multiple Orders',
    reviewOrdersTranslationKey: 'Review Orders',
    reviewOrdersSecondaryTranslationKey: 'Review delivery orders and pick-ups',
    reviewShipmentsTranslationKey: 'Shipments',
    reviewShipmentsSecondaryTranslationKey: 'Review delivery orders and pick-ups',
    emptyParentSelection: 'Click to Select Individual Sold To<br />or<br />Choose Ship To Below',
    clearSelection: 'Clear Selection',
    emptyaccountSelection: 'Click to Select Individual Account',
    emptyshipToSelection: 'Click to Select Individual Ship To',
    emptysoldToSelection: 'Click to Select Individual Sold To',
    saveAddProduct: 'Save & Add Products',
    deliveryOrderInstructions: 'Delivery Order Instructions',

    // New Shipment V2
    addLabel: 'Add',
    canBottleCategory: 'Cans/Bottles',
    endsClosuresCategory: 'Ends/Closures',
    sizeProductWarning:
        'Remember to group like products and sizes <br /> while planning your shipments.',
    customProductGraphicId: 'CUSTOM ID / PRODUCT ID / GRAPHIC ID',
    productNameDescription: 'PRODUCT NAME / DESCRIPTION',
    palletsAvailable: 'PALLETS AVAILABLE',
    fillerLineShipment: 'FILLER LINE',
    actionsUpperCase: 'ACTIONS',
    newWeek: 'New',
    threeToEightWeeks: 'In 3-8 Weeks',
    eightPlusWeeks: 'In 8+ Weeks',
    salesOrder: 'Sales Order',
    shipmentDeliveries: 'Shipments and Deliveries',
    upcomingDeliveries: 'Upcoming Deliveries',
    nonPayment: 'Non-Payment Impacting delivery',
    awaiting: 'Awating Approved',
    dueThisMonth: 'Due This Month',
    payments: 'Payments',
    upcomingPayments: 'Upcoming Payments',
    orderManagement: 'Order Management',
    palletsToShip: 'Pallets to Ship',
    unitQty: 'Unit Qty',
    POline: 'PO Line',
    addPalletsToOrder: 'Add pallets to Order',
    orderNumber: 'Order Number',
    editHeader: 'Edit Header',
    ifChangesRequiredCancel:
        'If changes to the ship to are required you will need to cancel this order and place a new one.',
    soldTo: 'Sold To',
    shipFrom: 'Ship From',
    shipmentsNumber: 'Shipment Number',
    customersPoNumber: 'Customer PO Number',
    qty: 'QTY',
    poLine: 'Po Line',
    requestedDateTime: 'Requested Date/Time',
    scheduledDateTime: 'Scheduled Date/Time',
    carrier: 'Carrier',
    alternatesoldToSelection:
        'You are currently viewing all<br/>accounts you have access to<br/>Click to Select Individual Sold To',
    reviewOrdersInfroamtionalText: 'Information Last Updated {{Datetime}}',
    viewOrderDetailsTab: 'Order Details',
    viewSummaryByShipmentTab: 'Summary By Shipment',
    viewSummaryByOrderTab: 'Summary By Order',
    viewOrder: 'View Order',
    editOrder: 'Edit Order',
    createOrder: 'Create Order',
    selectToAddShipmentPickup: 'Select to add shipment or pickup',
    youMayAddMoraShipments: 'You may add more than one shipment to this order'
};
