import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    FormControl,
    FormHelperText,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';

import { useTypedSelector } from '../../../store/reducers/reducer';
import { OrderingState } from '../../../store/reducers/ordering-dashboard';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { boldWeight, large, red, small } from '../../../themes/globalConstants';
import {
    selectChildrenAccountId,
    updateOrderingV2Flag
} from '../../../store/actions/customer-context';
import NewAccountSelection from './NewAccountSelection';
import moment from 'moment';
import { FlagTypes, useFeatureFlag } from '../../../utility/helpers/feature-flag';
import ConfirmationModal from './ConfirmationModal';
import Button from '../atoms/Button';
import { Controller, ErrorMessage, useForm } from 'react-hook-form';
import DatePicker from '../atoms/DatePicker';
import { selectDateFormat } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '1em'
    },
    tableText: {
        fontSize: small,
        textTransform: 'uppercase'
    },
    infoText: {
        fontSize: large,
        fontWeight: boldWeight
    },
    tableWrapper: {
        '& .MuiTableCell-root': {
            border: 0
        }
    },
    tableCellWrapper: {
        padding: theme.spacing(0.5)
    },
    actionBtn: {
        margin: theme.spacing(0.5)
    },
    textField: {
        // Your styles here
    },
    errorText: {
        color: red,
        fontSize: small
    }
}));

interface Props {
    isEdit?: boolean;
    onCancelOrder?: () => void;
    onConfirmChanges?: (data: any) => void;
}

export default function OrderInfoCard({ isEdit, onCancelOrder, onConfirmChanges }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalOrderingV2Flag = useFeatureFlag().find(
        (flag) => flag.name === FlagTypes.OrderingV2
    )?.isActive;

    const dateFormat = useTypedSelector<string>(selectDateFormat);
    const orderingState = useTypedSelector<OrderingState>((state) => state.orderingDashboard);
    const { selectedChildrenAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const {
        control,
        errors,
        getValues,
        setValue,
        handleSubmit,
        register,
        reset,
        triggerValidation
    } = methods;

    const [editHeader, setEditHeader] = useState(false);
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);

    const isRequired = useMemo(() => <Trans i18nKey="required">Required</Trans>, []);

    const tomorrow = moment().add(1, 'day').startOf('day');

    const isOutsideRange = (day) => {
        return day.isBefore(tomorrow.startOf('day'));
    };

    const handleDateChange = (name: string, newDate: moment.Moment | null) => {
        setValue(name, newDate);
    };

    const { orderInformation } = orderingState;

    const formattedDeliveryDate = useMemo(() => {
        if (orderInformation?.deliveryDate) {
            const date = moment(orderInformation.deliveryDate);
            return date.format(dateFormat);
        }
        return '';
    }, [orderInformation?.deliveryDate, dateFormat]);

    const handleEditHeader = () => {
        setEditHeader(true);
        reset({
            deliveryDate: orderInformation?.deliveryDate
                ? moment(orderInformation?.deliveryDate)
                : moment().add(1, 'day').startOf('day'),
            poNumber: orderInformation?.poNumber ?? '',
            deliveryInstructions: orderInformation?.deliveryInstructions ?? ''
        });
    };

    const handleCloseEditHeader = () => {
        setEditHeader(false);
    };

    const handleCancelOrder = () => {
        setCancelModalOpen(true);
    };

    const handleSubmitHeader = async () => {
        await triggerValidation().then((isValid) => {
            if (!isValid) {
                setSaveModalOpen(false);
                return;
            }
            setSaveModalOpen(true);
        });
    };

    const handleCloseModal = () => {
        setSaveModalOpen(false);
        setCancelModalOpen(false);
    };

    const onSubmit = (data) => {
        onConfirmChanges && onConfirmChanges(data);
        handleCloseModal();
        handleCloseEditHeader();
    };

    const onCancel = () => {
        onCancelOrder && onCancelOrder();
        handleCloseModal();
    };

    useEffect(() => {
        if (orderInformation && orderInformation.shipToId) {
            if (
                !selectedChildrenAccountId ||
                selectedChildrenAccountId !== orderInformation.shipToId
            ) {
                dispatch(selectChildrenAccountId(orderInformation.shipToId));
                dispatch(updateOrderingV2Flag(globalOrderingV2Flag));
            }
        }
    }, [dispatch, selectedChildrenAccountId, orderInformation, globalOrderingV2Flag]);

    const CancelModal = () => {
        const title = t('confirmOrderCancelation', 'Confirm Order Cancelation');
        const description = t(
            'confirmOrderCancelationDescription',
            'Are you sure you want to request Cancelation of this order?'
        );

        return (
            <ConfirmationModal
                title={title}
                open={cancelModalOpen}
                description={description}
                cancelText={t('no', 'No')}
                onCancel={handleCloseModal}
                onClose={handleCloseModal}
                saveInputLabel={t('yes', 'Yes')}
                onSave={onCancel}
            />
        );
    };

    const SaveHeaderModal = () => {
        const title = t('confirmHeaderChanges', 'Confirm Header Changes');
        const description = t(
            'confirmHeaderChangesDescription',
            'Changes to the Delivery Date, PO Number, and Delivery Instructions will be pushed to all shipments on this order. Do you wish to continue?'
        );

        return (
            <ConfirmationModal
                title={title}
                open={saveModalOpen}
                description={description}
                cancelText={t('no', 'No')}
                onCancel={handleCloseModal}
                onClose={handleCloseModal}
                saveInputLabel={t('yes', 'Yes')}
                onSave={handleSubmit(onSubmit)}
            />
        );
    };

    return (
        <>
            <Paper elevation={3} className={classes.paper}>
                <Grid container direction="column">
                    <Grid container justify="space-between">
                        <Grid container item xs={6} alignContent="flex-start">
                            <NewAccountSelection
                                disableSelect
                                isChildrenSelection
                                selectionType="shipTo"
                                reverseBackground
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Table padding="none" size="small" className={classes.tableWrapper}>
                                <TableBody>
                                    {orderInformation?.orderNumber && (
                                        <TableRow>
                                            <TableCell
                                                align="right"
                                                className={classes.tableCellWrapper}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.tableText}
                                                >
                                                    <Trans i18nKey="orderNumber">
                                                        Order Number
                                                    </Trans>
                                                    :
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className={classes.tableCellWrapper}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.infoText}
                                                >
                                                    {orderInformation?.orderNumber}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            className={classes.tableCellWrapper}
                                        >
                                            <Typography
                                                variant="body1"
                                                className={classes.tableText}
                                            >
                                                <Trans i18nKey="deliveryDate">Delivery Date</Trans>:
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellWrapper}
                                        >
                                            {editHeader ? (
                                                <FormControl
                                                    error={!!errors.deliveryDate}
                                                    fullWidth
                                                    data-testid="delivery-date"
                                                >
                                                    <Controller
                                                        control={control}
                                                        name={'deliveryDate'}
                                                        rules={{ required: isRequired }}
                                                        as={
                                                            <DatePicker
                                                                keepOpen={false}
                                                                numberOfMonths={1}
                                                                isOutsideRange={isOutsideRange}
                                                                onDateChange={handleDateChange}
                                                                placeholder={t(
                                                                    'deliveryDate',
                                                                    'Delivery Date'
                                                                )}
                                                                name="deliveryDate"
                                                                isEditDraft={false}
                                                                date={getValues('deliveryDate')}
                                                                error={!!errors.deliveryDate}
                                                                small
                                                                isEditPage={true}
                                                            />
                                                        }
                                                    />
                                                    {errors?.deliveryDate && (
                                                        <FormHelperText
                                                            className={clsx({
                                                                [classes.errorText]:
                                                                    !!errors?.deliveryDate
                                                            })}
                                                        >
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="deliveryDate"
                                                            />
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            ) : (
                                                <Typography
                                                    variant="body1"
                                                    className={classes.infoText}
                                                >
                                                    {formattedDeliveryDate ?? '-'}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            className={classes.tableCellWrapper}
                                        >
                                            <Typography
                                                variant="body1"
                                                className={classes.tableText}
                                            >
                                                <Trans i18nKey="poNumber">PO Number</Trans>:
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellWrapper}
                                        >
                                            {editHeader ? (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        variant="standard"
                                                        type="text"
                                                        inputProps={{
                                                            'data-testid': 'po-number',
                                                            maxLength: 25
                                                        }}
                                                        name="poNumber"
                                                        data-testid="po-number"
                                                        inputRef={register({
                                                            required: isRequired,
                                                            maxLength: 30
                                                        })}
                                                        error={!!errors?.poNumber}
                                                    />
                                                    {errors?.poNumber && (
                                                        <FormHelperText
                                                            className={clsx({
                                                                [classes.errorText]:
                                                                    !!errors?.poNumber
                                                            })}
                                                        >
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="poNumber"
                                                            />
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            ) : (
                                                <Typography
                                                    variant="body1"
                                                    className={classes.infoText}
                                                >
                                                    {orderInformation?.poNumber ?? '-'}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            className={classes.tableCellWrapper}
                                        >
                                            <Typography
                                                variant="body1"
                                                className={classes.tableText}
                                            >
                                                <Trans i18nKey="deliveryInstructions">
                                                    Delivery Instructions
                                                </Trans>
                                                :
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            className={classes.tableCellWrapper}
                                        >
                                            {editHeader ? (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        variant="standard"
                                                        type="text"
                                                        inputProps={{
                                                            'data-testid': 'delivery-instructions',
                                                            maxLength: 30
                                                        }}
                                                        name="deliveryInstructions"
                                                        data-testid="delivery-instructions"
                                                        inputRef={register({ maxLength: 30 })}
                                                        error={!!errors?.deliveryInstructions}
                                                    />
                                                    {errors?.deliveryInstructions && (
                                                        <FormHelperText
                                                            className={clsx({
                                                                [classes.errorText]:
                                                                    !!errors?.deliveryInstructions
                                                            })}
                                                        >
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="deliveryInstructions"
                                                            />
                                                        </FormHelperText>
                                                    )}
                                                </>
                                            ) : (
                                                <Typography
                                                    variant="body1"
                                                    className={classes.infoText}
                                                >
                                                    {orderInformation?.deliveryInstructions ?? '-'}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    {isEdit && (
                        <Grid container justify="space-between" direction="row">
                            <Grid item xs={6}>
                                <Typography variant="body1" className={classes.errorText}>
                                    <Trans i18nKey="ifChangesRequiredCancel">
                                        If changes to the ship to are required you will need to
                                        cancel this order and place a new one.
                                    </Trans>
                                </Typography>
                            </Grid>
                            <Grid container item xs={6} justify="flex-end">
                                {!editHeader ? (
                                    <>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            size="small"
                                            onClick={handleCancelOrder}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="cancelOrder">Cancel Order</Trans>
                                        </Button>
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            onClick={handleEditHeader}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="editHeader">Edit Header</Trans>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            className={classes.actionBtn}
                                            onClick={handleCloseEditHeader}
                                        >
                                            <Trans i18nKey="back">Back</Trans>
                                        </Button>
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            onClick={handleSubmitHeader}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="saveChanges">Save Changes</Trans>
                                        </Button>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <CancelModal />
            <SaveHeaderModal />
        </>
    );
}
