import React from 'react';
import { useTypedSelector } from '../../../store/reducers/reducer';
import CustomerDashboardV1 from './CustomerDashboardV1/CustomerDashboard';
import CustomerDashboardV2 from './CustomerDashboardV2/CustomerDashboard';
import { CustomerContextState } from '../../../store/reducers/customer-context';

const CustomerDashboard = () => {
    const { useOrderingV2 } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    return useOrderingV2 ? <CustomerDashboardV2 /> : <CustomerDashboardV1 />;
};

export default CustomerDashboard;
