import { createSelector } from 'reselect';
import {
    Account,
    AddressType,
    CustomerContextState,
    ModeOfTransport,
    PaymentTerms,
    ProdOrderType
} from '../reducers/customer-context';
import { AuthState } from '../reducers/auth';
import { Persona } from '../../utility/auth/useSecurity';
import { getBillToAccounts, getShipToAccountsList } from '../../utility/helpers/graphic-helpers';
import { isPersonaAccount } from '../../utility/helpers/user-helpers';
import { StateName } from '../../utility/helpers/state-helpers';
import { RegionCultureState } from '../reducers/region-culture';
import { UserConfigurationState } from '../reducers/user-configuration';

const getShipToAccounts = (state) => (state.customerContext as CustomerContextState).shipToAccounts;
const getAccounts = (state) => (state.customerContext as CustomerContextState).accounts;
const getSelectedAccountId = (state) =>
    (state.customerContext as CustomerContextState).selectedAccountId;
const getUserCustomerAccounts = (state) => (state.customerContext as CustomerContextState).accounts;
const getPermissions = (state) => (state.auth as AuthState).permissions;
const getRegionCulture = (state) => state.regionCulture as RegionCultureState;
const getUserConfiguration = (state) => state.userConfiguration as UserConfigurationState;
const getStateName = (state, stateName: StateName) => stateName;
const getState = (state) => state;

export const selectStateByName = createSelector(
    [getState, getStateName],
    (state, stateName) => state[stateName]
);

export const selectIsLargeCustomer = createSelector(
    [getShipToAccounts, getPermissions],
    (shipToAccounts, permissions): boolean => {
        return shipToAccounts &&
            permissions &&
            shipToAccounts.filter(
                (shipTo) => shipTo.prodOrderType === ProdOrderType.AuthorizationToManufacture
            ).length > 0 &&
            permissions.length > 0
            ? true
            : false;
    }
);

export const selectIsLargeCustomerAccount = createSelector(
    [getAccounts, getSelectedAccountId],
    (accounts, selectedAccountId): boolean => {
        if (!accounts || !selectedAccountId) {
            return false;
        }

        return accounts.filter(
            (shipTo) =>
                shipTo.accountId === selectedAccountId &&
                shipTo.prodOrderType === ProdOrderType.AuthorizationToManufacture
        ).length > 0
            ? true
            : false;
    }
);

export const selectIsCIACustomer = createSelector(
    [getShipToAccounts, getSelectedAccountId],
    (shipToAccounts, selectedAccountId): boolean => {
        if (!shipToAccounts || !selectedAccountId) {
            return false;
        }

        return shipToAccounts.some(
            (shipTo) =>
                shipTo.accountId === selectedAccountId && shipTo.paymentTerms === PaymentTerms.CIA
        );
    }
);

export const selectSelectedShipTo = createSelector(
    [getShipToAccounts, getSelectedAccountId],
    (shipToAccounts, selectedAccountId): Account | undefined => {
        if (!shipToAccounts || !selectedAccountId) {
            return undefined;
        }

        return shipToAccounts.find((shipTo) => shipTo.accountId === selectedAccountId);
    }
);

/**
 * Returns TRUE if the user's selected ship to belongs to their
 * impersonation persona.
 */
export const selectIsImpersonation = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (selectedAccountId && permissions && permissions.length) {
            const impPersona = permissions.find(
                (permission) => permission.personaId === Persona.Impersonation
            );

            return (
                !!impPersona &&
                impPersona.accountIds.some((accountId) => accountId === selectedAccountId)
            );
        }

        return false;
    }
);

/**
 * Returns TRUE if the user has ANY Bill Tos that have an
 * impersonation persona assigned.
 */
export const selectHasImpersonationBillTo = createSelector(
    [getPermissions, getAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const impPersona = permissions.find(
                (permission) => permission.personaId === Persona.Impersonation
            );

            const billToAccounts = getBillToAccounts(accounts);

            return billToAccounts.some((account) =>
                impPersona?.accountIds.includes(account.accountId)
            );
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has ANY Ship Tos that have an
 * impersonation persona assigned.
 */
export const selectHasImpersonationShipTo = createSelector(
    [getPermissions, getShipToAccounts],
    (permissions, shipToAccounts) => {
        if (permissions?.length && shipToAccounts?.length) {
            const impPersona = permissions.find(
                (permission) => permission.personaId === Persona.Impersonation
            );

            const shipToTypeAccounts = getShipToAccountsList(shipToAccounts);

            return shipToTypeAccounts.some((account) =>
                impPersona?.accountIds.includes(account.accountId)
            );
        }
        return false;
    }
);

/**
 * Returns an array of the user's Impersonation Ship To IDs
 */
export const selectImpersonationShipTos = createSelector(
    [getPermissions, getShipToAccounts],
    (permissions, shipToAccounts) => {
        if (permissions?.length && shipToAccounts?.length) {
            const impPersonas = permissions.filter(
                (permission) => permission.personaId === Persona.Impersonation
            );
            const impersonationShipTos = !!impPersonas[0] ? impPersonas[0].accountIds : [];

            const shipToIds = getShipToAccountsList(shipToAccounts).map(
                (account) => account.accountId
            );

            return impersonationShipTos.length > 0
                ? impersonationShipTos.filter((id) => shipToIds.includes(id))
                : [];
        }
        return [];
    }
);

export const selectIsCustomerPickup = createSelector(
    [getShipToAccounts, getSelectedAccountId],
    (shipToAccounts, selectedAccountId): boolean => {
        if (!shipToAccounts || !selectedAccountId) {
            return false;
        }

        return shipToAccounts.filter(
            (shipTo) =>
                shipTo.accountId === selectedAccountId &&
                shipTo.modeOfTransport === ModeOfTransport.CustomerPickUp
        ).length > 0
            ? true
            : false;
    }
);

/**
 * Returns TRUE if the user has ANY Bill Tos that have an
 * pricing contract persona assigned.
 */
export const selectHasPricingBillTo = createSelector(
    [getPermissions, getUserCustomerAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const pricingPersona = permissions.find(
                (permission) => permission.personaId === Persona.PricingContact
            );

            const billToAccounts = getBillToAccounts(accounts);

            return billToAccounts.some((account) =>
                pricingPersona?.accountIds.includes(account.accountId)
            );
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has ANY Bill Tos that have an
 * account admin persona assigned.
 */
export const selectHasAdminBillTo = createSelector(
    [getPermissions, getUserCustomerAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const adminPersonas = permissions.find(
                (permission) => permission.personaId === Persona.AccountAdmin
            );

            const billToAccounts = getBillToAccounts(accounts);

            return billToAccounts.some((account) =>
                adminPersonas?.accountIds.includes(account.accountId)
            );
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has ANY Bill Tos that have an
 * All Access persona assigned.
 */
export const selectHasAllAccessBillTo = createSelector(
    [getPermissions, getUserCustomerAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const allAccessPersonas = permissions.find(
                (permission) => permission.personaId === Persona.AllAccess
            );

            const billToAccounts = getBillToAccounts(accounts);

            return billToAccounts.some((account) =>
                allAccessPersonas?.accountIds.includes(account.accountId)
            );
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has the Pricing Contact persona assigned for the bill to.
 * ...and the bill to account is marked as a List Customer.
 */
export const selectHasTermsAndConditionsAcknowledgmentAccess = (billTo: string) =>
    createSelector([getPermissions, getUserCustomerAccounts], (permissions, accounts): boolean => {
        if (permissions?.length && accounts?.length) {
            const pricingPersonas = permissions.find(
                (permission) => permission.personaId === Persona.PricingContact
            );
            const adminPersonas = permissions.find(
                (permission) => permission.personaId === Persona.AccountAdmin
            );
            const billToAccounts = getBillToAccounts(accounts);
            const account = billToAccounts.find((account) => account.accountId === billTo);

            const hasPricingForBillTo: boolean =
                pricingPersonas?.accountIds.includes(billTo ?? '') ?? false;
            const hasAdminForBillTo: boolean =
                adminPersonas?.accountIds.includes(billTo ?? '') ?? false;
            const accountIsAListCustomer: boolean = account?.listCustomer ?? false;

            return (hasPricingForBillTo || hasAdminForBillTo) && accountIsAListCustomer;
        }
        return false;
    });

/**
 * Returns TRUE if the user ONLY has graphics vendor persona
 * permissions
 */
export const selectIsOnlyGraphicVendor = createSelector(
    [getPermissions, getUserCustomerAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const graphicsVendorPersona = permissions.find(
                (permission) => permission.personaId === Persona.GraphicsVendor
            );

            const nonGraphicsVendorPersonas = permissions.filter(
                (permission) => permission.personaId !== Persona.GraphicsVendor
            );

            return (
                accounts.some((account) =>
                    graphicsVendorPersona?.accountIds.includes(account.accountId)
                ) && nonGraphicsVendorPersonas.length === 0
            );
        }
        return false;
    }
);

/**
 * Returns TRUE if the user ONLY has plan it only persona
 * permissions
 */
export const selectIsPlanItOnly = createSelector(
    [getPermissions, getUserCustomerAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const planItOnlyPersona = permissions.find(
                (permission) => permission.personaId === Persona.PlanItOnly
            );

            const nonPlanItOnlyPersonas = permissions.filter(
                (permission) => permission.personaId !== Persona.PlanItOnly
            );

            return (
                accounts.some((account) =>
                    planItOnlyPersona?.accountIds.includes(account.accountId)
                ) && nonPlanItOnlyPersonas.length === 0
            );
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has copacker persona
 * for the selected shipto.
 */
export const selectIsCopackerAccount = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId): boolean => {
        if (permissions?.length && selectedAccountId) {
            return !!isPersonaAccount(permissions, Persona.CoPacker, selectedAccountId);
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with ordering access
 * (All Access, Forecast Management, Order Fulfillment, Plan It Only, Make It Only, Ship It Only )
 */
export const selectHasOrderingPermissions = createSelector([getPermissions], (permissions) => {
    if (permissions?.length) {
        const orderingPersonas = permissions.filter(
            (permission) =>
                permission.personaId === Persona.AllAccess ||
                permission.personaId === Persona.ForecastMgmt ||
                permission.personaId === Persona.OrderFulfillment ||
                permission.personaId === Persona.PlanItOnly ||
                permission.personaId === Persona.MakeItOnly ||
                permission.personaId === Persona.ShipItOnly
        );
        return orderingPersonas.length > 0;
    }
    return false;
});

/**
 * Returns TRUE if the user has any permissions
 * on the account with ordering access
 * (All Access, Forecast Management, Order Fulfillment, Plan It Only, Make It Only )
 */
export const selectHasOrderingMakePermissions = createSelector([getPermissions], (permissions) => {
    if (permissions?.length) {
        const orderingPersonas = permissions.filter(
            (permission) =>
                permission.personaId === Persona.AllAccess ||
                permission.personaId === Persona.ForecastMgmt ||
                permission.personaId === Persona.OrderFulfillment ||
                permission.personaId === Persona.PlanItOnly ||
                permission.personaId === Persona.MakeItOnly
        );
        return orderingPersonas.length > 0;
    }
    return false;
});

/**
 * Returns TRUE if the user has any permissions
 * on the account with Make It access (EDIT PERMISSIONS)
 * (All Access, Order Fulfillment, Make It Only )
 */
export const selectHasMakeItEdit = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.OrderFulfillment ||
                    permission.personaId === Persona.MakeItOnly
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with Make It access (VIEW ONLY)
 * (All Access, Order Fulfillment, Make It Only, Forecast Management, Packaging Management, Co-packer, Warehousing & Logistics, AR/AP)
 */
export const selectHasMakeItView = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.OrderFulfillment ||
                    permission.personaId === Persona.MakeItOnly ||
                    permission.personaId === Persona.ForecastMgmt ||
                    permission.personaId === Persona.PackagingMgmt ||
                    permission.personaId === Persona.CoPacker ||
                    permission.personaId === Persona.WarehousingLogistics ||
                    permission.personaId === Persona.ArAp
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with Ship It access (EDIT PERMISSIONS)
 * (All Access, Order Fulfillment, Ship It Only, Co-packer )
 */
export const selectHasShipItEdit = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.OrderFulfillment ||
                    permission.personaId === Persona.ShipItOnly ||
                    permission.personaId === Persona.CoPacker
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with Ship It access (VIEW ONLY)
 * (All Access, Order Fulfillment, Ship It Only, Forecast Management, Packaging Management, Co-packer, Warehousing & Logistics, AR/AP)
 */
export const selectHasShipItView = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.OrderFulfillment ||
                    permission.personaId === Persona.ShipItOnly ||
                    permission.personaId === Persona.ForecastMgmt ||
                    permission.personaId === Persona.PackagingMgmt ||
                    permission.personaId === Persona.CoPacker ||
                    permission.personaId === Persona.WarehousingLogistics ||
                    permission.personaId === Persona.ArAp
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with Plan It access (EDIT PERMISSIONS)
 * (All Access, Plan It Only, Forecast Management )
 */
export const selectHasPlanItEdit = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.ShipItOnly ||
                    permission.personaId === Persona.ForecastMgmt
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with Plan It access (VIEW ONLY)
 * (All Access, Order Fulfillment, Plan It Only, Forecast Management )
 */
export const selectHasPlanItView = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.OrderFulfillment ||
                    permission.personaId === Persona.ShipItOnly ||
                    permission.personaId === Persona.ForecastMgmt
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

/**
 * Returns TRUE if the user has any permissions
 * on the account with Planning access (VIEW ONLY)
 * (All Access, Order Fulfillment, Forecast Management, Procurement Management, Packaging Management, Co-packer, Warehousing & Logistics)
 */
export const selectHasPlanningView = createSelector(
    [getPermissions, getSelectedAccountId],
    (permissions, selectedAccountId) => {
        if (permissions?.length) {
            const orderingPersonas = permissions.filter(
                (permission) =>
                    permission.personaId === Persona.AllAccess ||
                    permission.personaId === Persona.OrderFulfillment ||
                    permission.personaId === Persona.ProcurementMgmt ||
                    permission.personaId === Persona.ForecastMgmt ||
                    permission.personaId === Persona.PackagingMgmt ||
                    permission.personaId === Persona.CoPacker ||
                    permission.personaId === Persona.WarehousingLogistics
            );
            return orderingPersonas.length > 0;
        }
        return false;
    }
);

export const selectIsMakeItAccount = createSelector(
    [getSelectedAccountId, getPermissions],
    (selectedAccountId, permissions): boolean => {
        if (!selectedAccountId || !permissions) {
            return false;
        }
        return permissions.some(
            (permission) =>
                [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment].includes(
                    permission.personaId
                ) && permission.accountIds.includes(selectedAccountId)
        );
    }
);

/**
 * Returns TRUE if the user has ANY Bill Tos that are marked as List Customer
 * ...and either the Account Admin or Pricing Contact persona assigned.
 */
export const selectHasTermsAndConditionsSummaryAccess = createSelector(
    [getPermissions, getUserCustomerAccounts],
    (permissions, accounts) => {
        if (permissions?.length && accounts?.length) {
            const adminPersonas = permissions.find(
                (permission) => permission.personaId === Persona.AccountAdmin
            );
            const pricingPersonas = permissions.find(
                (permission) => permission.personaId === Persona.PricingContact
            );

            const billToAccounts = getBillToAccounts(accounts);

            return billToAccounts.some(
                (account) =>
                    account.listCustomer &&
                    (adminPersonas?.accountIds.includes(account.accountId) ||
                        pricingPersonas?.accountIds.includes(account.accountId))
            );
        }
        return false;
    }
);

export const selectIsBillToAccount = createSelector(
    [getAccounts, getSelectedAccountId],
    (accounts, selectedAccountId): boolean => {
        if (!accounts || !selectedAccountId) {
            return false;
        }

        const selectedAccount = accounts.find((account) => account.accountId === selectedAccountId);
        if (
            selectedAccount &&
            (selectedAccount.addressType === AddressType.CB ||
                selectedAccount.addressType === AddressType.CX ||
                selectedAccount.addressType === AddressType.CP ||
                selectedAccount.addressType === AddressType.CG)
        )
            return true;
        return false;
    }
);

export const selectDateFormat = createSelector(
    [getUserConfiguration],
    (userConfiguration): string => {
        const { dateFormatCode } = userConfiguration;

        if (dateFormatCode) {
            return dateFormatCode;
        }
        return 'MM/DD/YY';
    }
);

export const selectDateTimeFormat = createSelector(
    [getUserConfiguration],
    (userConfiguration): string => {
        const { dateFormatCode } = userConfiguration;

        if (dateFormatCode) {
            return `${dateFormatCode} h:mm A`;
        }
        return 'MM/DD/YY h:mm A';
    }
);
