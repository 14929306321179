import * as types from '../actions/action-types';

export interface OrderSummary {
    deliveryOrderId?: number;
    soldTo?: string;
    shipTo?: string;
    productSKU?: string;
    productGraphicId?: string;
    orderNumber?: string;
    shipmentNumber?: string;
    customerPoNumber?: string;
    customerProductId?: string;
    productName?: string;
    size?: string;
    shipFrom?: string;
    palletQuantity?: number;
    quantityPerPallet?: number;
    shipments?: string;
    poLine?: string;
    releaseNumber?: string;
    submitted?: string;
    requestedDateTime?: string;
    scheduledDateTime?: string;
    lastUpdatedDate?: string;
    carrier?: string;
    status?: string;
}

export interface OrderingSummaries {
    orderDetails?: OrderSummary[];
    summaryByShipment?: OrderSummary[];
    summaryByOrder?: OrderSummary[];
}

export interface OrderingSummaryState {
    orderSummariesData?: OrderingSummaries;
    status: 'idle' | 'loading' | 'error' | 'success';
    error?: any;
}

const initialState: OrderingSummaryState = {
    orderSummariesData: undefined,
    status: 'idle',
    error: undefined
};

const orderingSummaries = (state = initialState, action: any): OrderingSummaryState => {
    switch (action.type) {
        case types.ORDERING_SUMMARIES_LOADING:
            return { ...state, status: 'idle', error: undefined };
        case types.ORDERING_SUMMARIES_LOADED:
            return {
                ...state,
                status: 'success',
                orderSummariesData: {
                    orderDetails: action.orderSummariesData.orderDetails,
                    summaryByShipment: action.orderSummariesData.summaryByShipment,
                    summaryByOrder: action.orderSummariesData.summaryByOrder
                }
            };
        case types.ORDERING_SUMMARIES_RESET:
            return { ...initialState, status: 'idle' };
        case types.ORDERING_SUMMARIES_ERROR:
            return { ...state, status: 'error', error: action.error };
        default:
            return state;
    }
};

export default orderingSummaries;
