import React, { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, makeStyles, Link } from '@material-ui/core';
import {
    ballBlue,
    black,
    blackWeight,
    lato,
    ltBlueGrey,
    statusTrackerGray
} from '../../../../../themes/globalConstants';

interface Props {
    title?: ReactNode;
    skuCount?: number;
    secondItemCount: number;
    secondItemUnit: string;
    isLink?: boolean;
    route?: string;
    showSecondary?: boolean;
    paymentCount?: number;
    isPaymentManagement?: boolean;
}

const useStyles = makeStyles(() => ({
    widgetMetricRow: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${ltBlueGrey}`,
        padding: `.75em 0`
    },
    widgetMetricRowTitle: {
        fontFamily: lato,
        fontWeight: blackWeight,
        fontSize: '.875em',
        color: black
    },
    widgetMetricRowSeparator: {
        fontFamily: lato,
        fontWeight: 600,
        fontSize: '1em',
        color: statusTrackerGray,
        margin: '0 1em'
    },
    widgetMetricRowValue: {
        fontFamily: lato,
        fontWeight: blackWeight,
        fontSize: '.875em',
        color: ballBlue
    }
}));

export default function DashboardWidgetMetricRow({
    title,
    skuCount,
    secondItemCount,
    secondItemUnit,
    isLink = false,
    showSecondary = true,
    route,
    isPaymentManagement = false,
    paymentCount
}: Props) {
    const classes = useStyles();
    return (
        <Grid item className={classes.widgetMetricRow}>
            {isLink && route ? (
                <Link
                    to={route}
                    underline="none"
                    component={RouterLink}
                    data-testid="link"
                    className={classes.widgetMetricRowTitle}
                >
                    {title}
                </Link>
            ) : (
                <span className={classes.widgetMetricRowTitle}>{title}</span>
            )}

            <div>
                {showSecondary && (
                    <>
                        {!isPaymentManagement && (
                            <span className={classes.widgetMetricRowValue}>
                                {skuCount?.toLocaleString()}
                            </span>
                        )}
                        {isPaymentManagement && (
                            <span className={classes.widgetMetricRowValue}>$</span>
                        )}
                        {isPaymentManagement && (
                            <span className={classes.widgetMetricRowValue}>
                                {paymentCount?.toLocaleString()}
                            </span>
                        )}
                        {!isPaymentManagement && (
                            <span
                                className={classes.widgetMetricRowSeparator}
                                style={{ color: black }}
                            >
                                {skuCount === 1 ? 'SKU' : 'SKUs'}
                            </span>
                        )}
                        <span className={classes.widgetMetricRowSeparator}>/</span>
                    </>
                )}
                <span className={classes.widgetMetricRowValue}>
                    {secondItemCount.toLocaleString()}
                </span>
                <span className={classes.widgetMetricRowSeparator} style={{ color: black }}>
                    {secondItemUnit}
                </span>
            </div>
        </Grid>
    );
}
