import React from 'react';
import {
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    Grid,
    Link,
    Tooltip
} from '@material-ui/core';
import { currencyFormatter } from '../../../../utility/helpers/formatters';
import { yellow, warningLabel, redPill, yellowPill } from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { OpenProductionOrderRow } from '../../../../store/reducers/make-it-summary';
import {
    CustomerContextState,
    PaymentTerms,
    ProdOrderType
} from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { ballBlue, blackWeight, boldWeight, medium } from '../../../../themes/globalConstants';
import { resetProductionOrderState } from '../../../../store/actions/production-order-details';
import { Trans, useTranslation } from 'react-i18next';
import {
    formatStatus,
    orderStatusCodeFormatter,
    getRequiredShipDateWarning
} from '../../../../utility/helpers/order-helpers';
import Button from '../../../reusable/atoms/Button';
import { Order_Status } from '../../../../utility/services/orders-service';
import { selectCustomerAccountId } from '../../../../store/actions/customer-context';
import { hasPersonas, Persona } from '../../../../utility/auth/useSecurity';
import { AuthState } from '../../../../store/reducers/auth';
import { getFilteredAccount } from '../../../../utility/helpers/summary-helpers';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';

interface Props {
    order: OpenProductionOrderRow;
    showCustomerPOCol: boolean;
    isLocked: boolean;
    key?: any;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    editButton: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    warningIcon: {
        fill: yellow,
        marginLeft: '10px',
        position: 'relative',
        top: '1px'
    },
    ballPoNumberContainer: {
        display: 'flex'
    },
    warningCell: {
        borderRadius: '.125em',
        backgroundColor: warningLabel
    },
    feeToolTip: {
        maxWidth: 200,
        fontSize: '0.75em'
    },
    cgRed: {
        color: redPill
    },
    goldenPoppy: {
        color: yellowPill
    }
}));

export default function MakeItOpenRow({ order, showCustomerPOCol, isLocked }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const showWarning: boolean = (order.totalFees || 0) > 0;
    const currencyFormattedFee: string = currencyFormatter(cultureCode, order.totalFees);
    const requiredShipDateWarning: boolean | undefined = getRequiredShipDateWarning(
        order.requiredShipDateString
    );
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    useTranslation();

    const formatShipToString = (productionOrderId?: number) => {
        if (shipToAccounts && selectedAccountId) {
            let filteredAccount = getFilteredAccount(shipToAccounts, selectedAccountId);

            if (filteredAccount) {
                return (
                    <>
                        <Typography key={'ship-to-' + productionOrderId}>
                            {filteredAccount.name}
                        </Typography>
                        <Typography key={'ship-to-address-' + productionOrderId}>
                            {getAccountAddress(filteredAccount)}
                        </Typography>
                    </>
                );
            }
        }
        return;
    };

    const formatOrderQuantity = (totalOrderQuantity?: number, totalOrderSKUs?: number) => {
        if (totalOrderSKUs === 1) {
            return (
                <Typography>
                    {totalOrderQuantity} PL ({totalOrderSKUs} <Trans i18nKey="sku">SKU</Trans>)
                </Typography>
            );
        } else {
            return (
                <Typography>
                    {totalOrderQuantity} PL ({totalOrderSKUs} <Trans i18nKey="skus">SKUs</Trans>)
                </Typography>
            );
        }
    };

    const formatProductionOrderNumber = (
        productionOrderNumber?: string,
        productionOrderId?: number
    ) => {
        if (
            productionOrderNumber &&
            productionOrderNumber !== '0' &&
            productionOrderNumber !== 'pending'
        ) {
            return (
                <Link
                    to={`/production-order/${productionOrderId}`}
                    component={RouterLink}
                    underline="none"
                    onClick={resetLoadedOrder}
                >
                    {productionOrderNumber}
                </Link>
            );
        } else {
            return (
                <em>
                    <Link
                        to={`/production-order/${productionOrderId}`}
                        component={RouterLink}
                        underline="none"
                        onClick={resetLoadedOrder}
                    >
                        <Trans i18nKey="pending">Pending</Trans>
                    </Link>
                </em>
            );
        }
    };

    const onEdit = () => {
        if (order.productionOrderId) {
            if (isLargeCustomerAccount) {
                dispatch(selectCustomerAccountId(order.shipToId.toString()));
                history.push(`/edit-production-order/${order.productionOrderId}`);
            } else {
                dispatch(selectCustomerAccountId(order.shipToId.toString()));
                history.push(`/make-it/${order.productionOrderId}`);
            }
        }
    };

    const actions = (rowOrder: OpenProductionOrderRow) => {
        let isCoPacker = hasPersonas([Persona.CoPacker], auth);

        if (!isCoPacker && shipToAccounts && selectedAccountId) {
            let filteredAccount = getFilteredAccount(shipToAccounts, selectedAccountId);

            let showButton =
                rowOrder &&
                rowOrder.status === Order_Status.Open &&
                !isLocked &&
                filteredAccount &&
                filteredAccount.paymentTerms === PaymentTerms.Credit;

            return (
                <TableCell data-testid="available-actions">
                    {showButton && (
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="edit-button"
                            className={classes.editButton}
                            onClick={() => onEdit()}
                        >
                            <Trans i18nKey="edit">Edit</Trans>/
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                    )}
                </TableCell>
            );
        }
    };

    const resetLoadedOrder = () => {
        dispatch(resetProductionOrderState());
    };

    return (
        <TableRow hover={true} key={order.productionOrderId}>
            <TableCell data-testid={'ship-to'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {formatShipToString(order.productionOrderId)}
                    </Grid>
                </Grid>
            </TableCell>
            {showCustomerPOCol && (
                <TableCell data-testid={'customer-po-id'}>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {order.shipToProdOrderType !==
                                ProdOrderType.AuthorizationToManufacture && (
                                <Typography>{order.customerProductionOrderId}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
            )}
            <TableCell
                data-testid={'ball-po-number'}
                className={showWarning ? classes.warningCell : ''}
            >
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <div className={classes.ballPoNumberContainer}>
                            <Typography>
                                {formatProductionOrderNumber(
                                    order.searchablePONumber,
                                    order.productionOrderId
                                )}
                            </Typography>
                            {showWarning && (
                                <Tooltip
                                    title={`One or more quantities entered do not meet the minimum order quantity. Total fees applied to this order: ${currencyFormattedFee}`}
                                    arrow
                                    placement="right"
                                    classes={{ tooltip: classes.feeToolTip }}
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                        alt="Warning Icon"
                                        className={classes.warningIcon}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'submitted-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{order.submittedDateString}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'requested-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{order.firstRequestedDateString}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'requested-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {order.requiredShipDateString ? (
                            <>
                                <Typography
                                    className={
                                        requiredShipDateWarning !== undefined
                                            ? requiredShipDateWarning
                                                ? classes.goldenPoppy
                                                : classes.cgRed
                                            : undefined
                                    }
                                >
                                    {order.requiredShipDateString}
                                </Typography>
                                <Typography>{order.availableQuantityString}</Typography>
                                <Typography>{order.availableEachesString}</Typography>
                            </>
                        ) : (
                            <Typography>N/A</Typography>
                        )}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'order-quantity'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {formatOrderQuantity(order.totalOrderQuantity, order.totalOrderSKUs)}
                        {order.orderQuantityEachesString && (
                            <Typography>{order.orderQuantityEachesString}</Typography>
                        )}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'status'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>
                            <Trans i18nKey={orderStatusCodeFormatter(order.status)}>
                                {formatStatus(order.status)}
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {actions(order)}
        </TableRow>
    );
}
