export const ptBR = {
    // Reusable
    cans: 'Latas',
    view: 'Visualizar',
    design: 'Desenho',
    filling: 'Envase',
    canHandling: 'Manuseio de Latas',
    regulations: 'Regulamentações',
    labLiquid: 'Laboratório de Líquidos',
    sustainability: 'Sustentabilidade',
    faq: 'Perguntas frequentes',
    designInspiration: 'Inspiração de Design',
    backToAllProducts: 'Voltar a Todos Produtos',
    shipTo: 'Enviar para',
    new: 'Novo',
    existing: 'Existir',
    unknown: 'Desconhecido',
    playground: 'Playground',

    // -- NAVIGATION -- //
    home: 'Início',
    homepage: 'Minha página inicial',
    ourProcess: 'Nosso Processo',
    processOverview: 'Visão geral do processo',
    labDashLiquid: 'Laboratório / Líquido',
    handling: 'Manipulação',
    regulatory: 'Regulamentações',
    ourProducts: 'Nossos Produtos',
    productPortfolio: 'Portfólio de Produtos',
    contactUs: 'Entre em Contato Conosco',
    logout: 'Sair',
    navigation: 'Navegação',
    dashboard: 'Painel',
    backToPortfolio: 'Voltar a Portfólio de Produtos',
    buildAndEstimateNav: 'Criar e estimar',
    buildNav: 'Criar',
    graphics: 'Gráficos',
    overview: 'Visão Geral',
    theProcess: 'O processo',
    ballProductPortfolio: 'Portfólio de Produtos Ball',
    guidesLink: 'Guias de como fazer',
    faqLink: 'FAQ',
    makeItSummary: 'Resumo Make It',
    shipItSummary: 'Resumo Ship It',
    submitNewGraphicLink: 'Enviar um novo gráfico',
    productPlanning: 'Planejamento de produto',
    planning: 'Planejamento',
    newOpenProductionOrders: 'Pedidos de Produção Novos/em Aberto',
    payables: 'Pagáveis',
    paymentsSummary: 'Resumo de pagamentos',
    discoverBall: 'Descubra a Ball',

    // -- USER ADMIN -- //
    admin: 'Admin',
    addNewUser: 'Adicionar Novo Usuário',
    userAdmin: 'Admin do Usuário',
    myAccount: 'My Account',
    userInformation: 'Informações do Usuário',
    confirmEmail: 'Confirmar E-mail',
    pendingAccessRequests: 'Pending Access Requests',
    myAccess: 'My Access',
    allAccess: 'Acesso total',
    locationRoleAccess: 'Localização e Acesso da Função',
    orderFulfillment: 'Processamento de pedidos',
    procurementManagement: 'Gerenciamento de Aquisições',
    forecastManagement: 'Projeção de Vendas',
    packagingManagement: 'Gestão de Embalagem',
    coPacker: 'Terceirização de Embalagens',
    warehousingLogistics: 'Armazenamento / Logística',
    artManagement: 'Gerenciamento Artístico',
    marketingManagement: 'Gestão de Marketing',
    accountAdmin: 'Administração de contas',
    accountsPayableAccountsReceivable: 'Contas a Pagar / Receber',
    graphicsVendor: 'Fornecedor de Gráficos',
    edit: 'Editar',
    selectedAccess: 'Acceso Seleccionado',
    graphicsIntakeForm: 'Formulário de Entrada de Gráficos',
    draftGraphicsIntakeForms: 'Rascunho de Formulários de Entrada de Gráficos',
    inProgressGraphicsStatuses: 'Status de Gráficos em Andamento',
    graphicsQuote: 'Cotação de Gráficos',
    graphicsProof: 'Prova de Impressão',
    graphicsStandardPilotCan: 'Padrão de Gráficos (Protótipo de Lata)',
    draftProductionOrders: 'Rascunho de Pedidos de Produção',
    closedProductionOrders: 'Pedidos de produção fechados',
    newOpenDeliveryOrders: 'Pedidos de entrega novos/em aberto',
    closedDeliveryOrders: 'Pedidos de entrega fechados',
    createNewUser: 'Criar novos usuários',
    planItPlanItSummary: 'Planejar/Planejar-lo Resumo',
    changesWillNotBeSaved: 'As alterações feitas não serão salvas',
    areYouSureDiscard: 'Tem certeza de que deseja descartar as alterações?',
    yesDiscard: 'Sim, descartar',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Bem-vindo ao <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> será o seu balcão único para gráficos para entrega. Com o tempo, esta página terá métricas úteis, como pedidos de produção “Fazer”, Pedidos de entrega “Enviar”, Gerenciamento de produtos e muito mais. Agradecemos seu feedback e paciência enquanto atualizamos sua experiência.',
    readyToOrder: 'Pronto para solicitar produtos?',
    makeIt: 'Faça',
    readyToSchedule: 'Pronto para agendar uma remessa?',
    shipIt: 'Enviá-lo',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'User Administration',
    setupNewUser:
        'Setup a new user with the correct permissions and locations in <i>The Source</i>.',
    userAdminDashboard: 'User Admin Dashboard',
    viewPendingRequests:
        'View pending access requests, pending new user requests, and active users in <i>The Source</i>.',
    viewUserAdminDashboard: 'View User Admin Dashboard',

    // -- DASHBOARD -- //
    agedSku: 'SKU envelhecidos',
    agedSkus: 'SKUs envelhecidos',

    // -- FAQ Page -- //
    searchFAQs: 'Procurar perguntas frequentes',
    searchFAQplaceholder: 'Cans, Bottles, etc!',

    // -- QUICK VIEW -- //
    production: 'Produção',
    limitedProductionRun: 'Produção Limitada',
    seeFullProductDtls: 'Veja todos detalhes do produto',
    timesPerYear: 'Times Per Year',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Product Information',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Make It Lock Window',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'View My Product Information',
    customerProductId: 'Customer Product Id',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Ball Product ID',

    // -- PRODUCT PORTFOLIO -- //
    all: 'Tudo',
    bottles: 'Garrafas',
    ends: 'Tampas',
    end: 'Tampas',
    closures: 'Encerramentos',
    closure: 'Encerramento',
    lookingForInspiration: 'Procurando Inspiração',
    pallets: 'Pallet',
    itemsPerPallet: 'Itens por Pallet',
    lookingForSomething: 'Não encontra o que precisa?',
    sendUsYourComments: 'Nos diga o que você está procurando',
    minOrderPallet: 'Pedido mínimo em paletes',
    cansPerPallet: 'Latas por Palete',
    quickLook: 'Guia rápido',
    select: 'Selecione',
    seeDetailsBtn: 'Veja todos detalhes do produto',
    compareProducts: 'Comparar Produtos',
    pleaseSelectProducts: 'Selecione produtos para obter uma estimativa',
    endTile: 'Tampas',
    myProjects: 'Meus projetos',
    compare: 'Comparar',
    belowAreYourSavedProjects: 'Abaixo estão seus projetos salvos nos últimos 180 dias.',
    projectName: 'Nome do projeto',
    productCode: 'Código de produto',
    savedOn: 'Salvo em',
    viewProject: 'Ver projeto',

    // -- FACETS -- //
    filterBy: 'Filtrar por',
    clearAll: 'Limpar tudo',
    // Facet Category
    stylesCategory: 'Gama de Produtos',
    sizesCategory: 'Tamanho',
    featuresCategory: 'Aprimoramentos do produto',
    endsCategory: 'Compatibilidade de Tampas',
    // Facet Tooltips
    stylesTooltip: 'Gama de Produtos dica de ferramenta',
    sizesTooltip: 'Tamanho dica de ferramenta',
    featuresTooltip: 'Aprimoramentos do produto dica de ferramenta',
    endsTooltip: 'Compatibilidade de Tampas de ferramenta',
    style: 'Estilo',
    size: 'Tamanho',
    features: 'Características',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Solicite um Orçamento',
    productDetails: 'Detalhes do Produto',
    printGuide: 'Guia de Impressão',
    customerDesignGuide: 'Guia de Design do Cliente',
    download: 'Baixar',
    canSpecification: 'Especificação de Lata',
    dieLine: 'Die Line',
    palletSpecifications: 'Especificações de Pallet',
    greatFor: 'Ideal para',
    specifications: 'Especificações',
    currentLeadTime: 'Disponibilidade de Envase',
    fillingAvailability: 'Disponibilidade de Preenchimento',
    compatibleEnds: 'Tampas Compatíveis',
    weeks: 'Semanas',
    months: 'Meses',
    minimum: 'Mínimo',
    productEnhancements: 'Melhoria de Produto',
    compatibleFilling: 'Envase Compatível',
    pasteurization: 'Pasteurização',
    hotFill: 'Envase a Quente',
    coldFill: 'Envase a Frio',
    color: 'Cor',
    aluminum: 'Alumínio',
    printingOptions: 'Tipos de Impressão: Lata Litografada, Lata com Sleeve, Lata Lisa',
    // Ordering
    ordering: 'Comprar',
    pricePerThousand: 'Preço por Milheiro',
    quantityPerPallet: 'Quantidade por Pallet',
    minOrder: 'Pedido Mínimo',
    // Design Specs
    designSpecs: 'Especificações do Design',
    circumference: 'Circunferência',
    designLength: 'Comprimento da Arte (Circunferência)',
    designHeight: 'Altura da Arte',
    maxTALength: 'Área de texto máxima (Comprimento)',
    maxTAHeight: 'Área de texto máxima (Altura)',
    maximumColors: 'Máximo de Cores',
    windowLimitWidth: 'Limite de Largura',
    windowLimitHeight: 'Limite de Altura',
    underOverlap: 'Área de sobreposição',
    // Can Specs
    canSpecs: 'Especificações da Lata',
    endSize: 'Tamanho da Tampa',
    bodyOutsideDiameter: 'Diâmetro Externo do Corpo',
    flangedCanHeight: 'Altura da Flange',
    coldFillVolume: 'Volumetria de Envase',
    flangedCanWidth: 'Flange (Largura)',
    endDiameter: 'Diâmetro Fechamentos',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Efeitos e recursos disponíveis para esta lata',
    canFinishesAndEffects: 'Efeitos Especiais',
    inks: 'Tintas',
    overvarnish: 'Vernizes',
    effects: 'Efeitos',
    specialFeatures: 'Características Especiais',
    billTo: 'Cobrança para',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Manage Products',
    productInformation: 'Product Information',
    activeProducts: 'Active Products',
    inactiveProducts: 'Inactive Products',

    // -- END DETAILS -- //
    endDetails: 'Detalhe da Tampa',
    endFinishesAndEffects: 'Tampas Especiais',
    compatibleCans: 'Latas Compatíveis',
    endFeaturesAndEffects: 'Tampas Especiais',
    orderIndividually: 'Pode ser encomendado individualmente',
    roppClosures: 'ROPP Closures are sold via 3rd party',
    endsPack: 'Pacote de informações sobre Tampas',

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Adicionar produto',
    details: 'Detalhes',
    name: 'Nome',
    type: 'Tipo',
    maxColors: 'Max Colors',
    innovativeFeatures: 'Recursos inovadores',
    leadTimes: 'Prazos de entrega',
    requiredShipDate: 'Data de entrega solicitada',
    currentFillingAvail: 'Disponibilidade atual de preenchimento',
    products: 'Produtos',
    addAnotherToCompare: 'Adicione Outro Produto para Comparar',
    removeSelection: 'Remover seleção',
    addToCompare: 'Adicionar para comparar',
    prodComparisonDesc:
        'Compare até três produtos diferentes por vez, independentemente do volume ou estilo. Veja quais produtos oferecem',
    prodEstimateDesc:
        'Nosso estimador pode ajudar a entender melhor o custo do pedido de latas conosco. Lembre-se de algumas coisas ao criar sua estimativa',
    prodHasMinOrder: 'Cada produto tem um pedido mínimo',
    orderInFullPallets: 'Estimativas e pedidos só podem ser feitos em paletes cheios',
    orderInFullTrucks: 'Estimativas e pedidos só podem ser feitos em paletes cheios',
    productComparison: 'Comparação de produto',
    compareBuildEstimate: 'Comparar, criar e estimar',
    compareBuild: 'Comparar e criar',
    myProject: 'Meu projeto',

    // -- CONTACT FORM -- //
    howCanWeHelp: 'Como podemos ajudar?',
    yourName: 'Seu nome',
    email: 'Email',
    yourCountry: 'Seu país de Envase',
    yourCity: 'Seu Cidade de Envase',
    subjectLine: 'Linha de assunto',
    contactMessage: 'Diga-nos como podemos ajudar…',
    submit: 'Enviar',
    cancel: 'Cancelar',
    sendUsANote: 'Como podemos ajudar?',
    msgSuccessful: 'Mensagem enviada com sucesso.',
    msgFailed: 'Falha ao enviar a mensagem. Tente novamente em outro horário.',
    sending: 'Enviando',
    close: 'Feche',
    contactReason: 'Motivo do contato',
    contact: 'Eu tenho uma pergunta',
    support: 'Eu preciso de ajuda',
    login: `I can't log in`,

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Obter uma cotação',
    desiredAnnualQuantity: 'Quantidade anual desejada',
    enterQuantity: 'Digite a quantidade',
    // wouldYouLikeEndsWithThisProduct: 'Deseja tampas com este produto?',
    remove: 'Retirar',
    enterValueGreaterThan: 'Digite um valor maior que 0',
    estimateFailedToSend: 'Falha no envio da estimativa, tente novamente em outro horário',
    productEstInquiry: 'Consulta de Estimativa de Produto',
    submittingEstimate: 'Enviar informações abaixo para uma estimativa',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Voltar à página inicial',
    thankYou: 'Obrigado',
    yourRequestHasBeenSubmitted: 'O seu pedido foi enviado!',
    someoneFromOurTeamWillBeInTouchSoon: 'Alguém da nossa equipe entrará em contato em breve.',

    // -- REGISTRATION -- //
    registrationHeader: 'Vamos fazer latas juntos',
    registrationDescription:
        'Estamos quase chegando, apenas precisamos de um pouco de informações suas para levá-lo ao Ball Ball Portal, onde você pode aprender mais sobre latas, como fazer pedidos e estimar custos.',
    region: 'Região',
    language: 'Língua',
    dateFormat: 'Formato de Data',
    aboutYourCompany: 'Sobre a sua empresa',
    companyName: 'Nome da empresa',
    country: 'País',
    stateProvince: 'Estado',
    city: 'Cidade',
    postalCode: 'Código postal',
    yearsInBusiness: 'Anos de negócio',
    numberOfEmployees: 'Número de empregados',
    estimatedAnnualRevenue: 'Faturamento anual estimado',
    beverageInRetail: 'Você atualmente tem uma bebida em comercialização?',
    currentPackageType: 'Tipo de embalagem atual',
    beveragePackagingNeeds: 'Suas necessidades de embalagens de bebidas',
    beverageCategory: 'Categoria de bebidas',
    productSize: 'Tamanho do produto',
    expectedAnnualQuantity: 'Quantidade anual esperada',
    canningLine: 'Você possui ou planeja comprar uma linha de envase?',
    copackerName: 'Nome do co-packer',
    fillingCountry: 'País de envase',
    fillingCity: 'Cidade de envase',
    additionalComments: 'Comentários adicionais: (opcional)',
    artworkComments: 'Comentários de arte (opcional)',
    setupCustomerPortalAccount: 'Configure sua conta do portal do cliente',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    title: 'Title',
    jobTitle: 'Job Title',
    phoneNumber: 'Número de telefone',
    password: 'Senha',
    confirmPassword: 'Confirme a Senha',
    alreadyRegistered: 'Já registrado?',
    accountCreated: 'This email account is already tied to The Source.',
    signIn: 'Faça login no Portal do Cliente',
    signInConfirmation: 'Please Sign In to The Source and if needed, reset your password.',
    yes: 'Sim',
    no: 'Não',
    incorrectEmailAddress: 'Endereço de e-mail incorreto',
    atLeastEightLetters: 'Pelo menos 8 letras',
    atLeastOneCapitalLetter: 'Pelo menos uma letra maiúscula',
    atLeastOneLowerCaseLetter: 'Pelo menos uma letra minúscula',
    atLeastOneNumber: 'Pelo menos um numero',
    mustNotBeACommonPassword: 'Não deve ser uma senha comum',
    passwordsShouldMatch: 'As senhas devem corresponder!',
    year: 'ano',
    printType: 'Você está procurando por latas lisas, litografadas ou latas com sleeves?',
    iAgree: 'Concordo com os',
    termsOfUse: 'Termos de Uso e com as Políticas de Privacidade',
    million: 'Milhão',
    newToBeverage: 'Novo para Bebidas',
    selectSecurityQuestion: 'Pergunta',
    securityAnswer: 'Resposta',
    invalidSecurityAnswer: 'A resposta deve ser maior que 5 caracteres',
    emailValidationError: 'O e-mail não corresponde ao padrão exigido',
    passwordValidationError: 'A senha não deve ser uma senha comum',
    securityAnswerValidationError:
        'A resposta da pergunta de segurança é muito fraca, a resposta não deve fazer parte da pergunta.',
    unknownValidationError:
        'Ocorreu um erro ao criar uma nova conta de usuário. Entre em contato com o help desk.',
    selectSecurityQuestionPrompt:
        'Selecione uma pergunta de segurança e uma resposta para que você possa redefinir automaticamente sua senha se tiver problemas para entrar em sua conta.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Lisa',
    sleevedCans: 'Sleeve',
    printedCans: 'Litografada',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Cerveja',
    beverageCategoryBeerCraft: 'Cerveja Artesanal',
    beverageCategoryBeerDomestic: 'Cerveja Nacional',
    beverageCategoryBeerImport: 'Cerveja Importada',
    beverageCategoryCsd: 'Bebida Carbonatada',
    beverageCategoryCider: 'Cidra',
    beverageCategoryCoffee: 'Café',
    beverageCategoryIcedCoffee: 'Café gelado',
    beverageCategoryEnergyDrink: 'Energético',
    beverageCategoryFab: 'FAB’s',
    beverageCategoryFmb: 'Flavored Malt Beverage (FMB)',
    beverageCategoryHardLiquor: 'Destilado',
    beverageCategoryThc: 'Hemp/CBD/THC',
    beverageCategoryJuice: 'Suco',
    beverageCategoryProtein: 'Protein/Milk Based',
    beverageCategoryRtd: 'Bebida Mista',
    beverageCategorySpirits: 'Destilado',
    beverageCategorySportsDrink: 'Bebida Esportiva',
    beverageCategoryTea: 'Chá',
    beverageCategoryIcedTea: 'Chá gelado',
    beverageCategoryWater: 'Água',
    beverageCategoryWine: 'Vinho',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Outros',

    // Validation
    required: 'Requeridos',

    // Registration Security Questions
    securityQuestionFood: 'Qual alimento você menos gostava quando era criança?',
    securityQuestionStuffAnimal: 'Qual é o nome do seu primeiro animal de pelúcia?',
    securityQuestionAward: 'Qual foi a primeira medalha ou prêmio que você ganhou?',
    securityQuestionComputerGame: 'Qual é o primeiro jogo de computador que você jogou?',
    securityQuestionMovieQuote: 'Qual é sua citação de filme favorita?',
    securityQuestionSportsTeam:
        'Qual era o mascote da primeira equipe esportiva da qual você participou?',
    securityQuestionDessert: 'Qual é a sobremesa favorita de sua avó?',
    securityQuestionCook: 'Qual foi a primeira coisa que aprendeu a cozinhar?',
    securityQuestionDreamJob: 'O que você queria ser quando crescer?',
    securityQuestionVacation: 'Quais foram as suas férias favoritas?',

    // --- GRAPHICS INTAKE --- //
    hard: 'Duro',
    soft: 'Suave',
    addAnotherApprover: 'Add Another Approver',

    // --- USER ADMIN --- //
    arAp: 'Contas a Pagar/Receber',
    artMgmt: 'Gerenciamento artístico',
    forecastMgmt: 'Projeção de Vendas',
    marketingMgmt: 'Gestão de Marketing',
    packagingMgmt: 'Gestão de Embalagem',
    procurementMgmt: 'Gerenciamento de Aquisições',
    impersonation: 'Impersonation',
    addUser: 'Adicionar Usuário',
    pendingRequests: 'Solicitações Pendentes',
    pendingRegistration: 'Cadastro Pendente',
    activeUsers: 'Usuários Ativos',
    rolesLocations: 'Funções (número de locais)',
    actions: 'Ação',
    viewEdit: 'Visualizar/Editar',
    disable: 'Desabilitar',
    roles: 'Funções',
    sentOn: 'Enviado em',
    resend: 'Reenviar',
    reviewRequest: 'Solicitação de Revisão',
    disableUserError: 'O sistema não conseguiu desativar o usuário selecionado',
    disableUser: 'Desativar Usuário',
    disableUserWarning:
        'Você está prestes a desativar as permissões deste usuário em sua conta. Ele precisará criar uma nova solicitação para recuperar as permissões. Tem certeza que quer continuar?',
    yesDisable: 'Sim, desativar',
    location: 'Localização',

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Download .PDF',
    effectiveDate: 'Effective Date',
    currency: 'Currency',
    itemDescription: 'Item Description',
    currentBasePrice: 'Current Base Price',
    newBasePrice: 'New Base Price',
    freightChargeFromLocation: 'Freight Charge From Location (if available)',
    freightChargesAvailable: 'Freight Charges (if available)',
    newBaseWithFreight: 'New Base Price With Freight (if available)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',

    // -- KB MAX -- //
    errorProcessing: 'Houve um processamento de erro.',
    exitWarning: 'Tem certeza de que quer sair?',
    yesExit: 'Sim, Sair',
    exit: 'Sair',

    //Bulk upload errors
    EP100004:
        'A data solicitada não é válida ou está em formato incorreto. Verifique e digite como MM/DD/AAAA.',

    // -- MAKE IT BULK UPLOAD -- //
    inactiveProductId: 'Este produto está inativo e não pode ser encomendado.',
    previousForecasts: 'Previous Forecasts',
    planItSummaryDashboardDesc:
        'View previously submitted order forecasts. Edit future order forecasts.',

    // -- CONTRIES -- //
    Argentina: 'Argentina',
    Brazil: 'Brasil',
    Chile: 'Chile',
    Colombia: 'Colombia',
    Ecuador: 'Equador',
    Paraguay: 'Paraguai',
    Peru: 'Peru',
    Uruguay: 'Uruguai',

    // -- FEEDBACK SURVEY -- //
    feedbackHeader: 'Adoraríamos conhecer sua opinião!',
    satisfaction: 'No geral, como você classifica sua experiência com o The Source?',
    enterScore: 'Por favor insira uma pontuação',
    visitPurpose: 'Qual o principal propósito da sua visita hoje?',
    selectOption: 'Selecione a opção',
    'Learn about working with Ball': 'Saiba mais sobre como trabalhar com Ball',
    'Get Design Inspiration': 'Tenha inspiração de projetos',
    'Investigate Lab and Liquid': 'Investigar Laboratório e Líquido',
    'Explore Sustainability & Regulatory': 'Explorar Sustentabilidade e Regulamentação',
    'Learn about Handling & Filling': 'Saiba mais sobre Manuseio e Envase',
    'Access Can Specifications': 'Acessar especificações da lata',
    'Obtain Pricing Information': 'Obter informações sobre preços',
    Other: 'Outros',
    pleaseSpecify: 'Por favor, especifique:',
    suggestionLabel: 'O que mais podemos fazer para melhorar sua experiência com o The Source?',
    contactPreferenceLabel:
        'Se precisarmos de esclarecimentos sobre alguma de suas respostas, podemos entrar em contato com você?',
    'Yes, I prefer a phone call': 'Sim, prefiro um telefonema.',
    'Yes, I prefer email': 'Sim, eu prefiro e-mail',
    'No, please do not contact me': 'Não, por favor, não entre em contato comigo.',
    selectContactPreference: 'Selecione uma preferência de contato',
    nameLabel: 'Nome',
    phoneLabel: 'Número de telefone',
    emailLabel: 'Endereço de e-mail',
    emailError: 'por favor digite um email válido',
    closeButton: 'Fechar',
    submitButton: 'Enviar',
    feedbackThankYou: 'Obrigado por fornecer feedback',
    feedbackPrivacyPolicy: 'política de privacidade',
    privacyPolicyMessage: 'Suas respostas serão usadas de acordo com nossa'
};
