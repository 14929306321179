import React, { useEffect } from 'react';
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray, blackWeight, medium, red, small } from '../../../../themes/globalConstants';
import { DeliveryTime } from '../../../../store/reducers/ordering-dashboard';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    redText: {
        color: red,
        fontSize: small
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                marginBottom: '-0.250em',
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        },
        '& .MuiSelect-root': {
            textAlign: 'left',
            backgroundColor: theme.palette.common.white,
            minWidth: '6.250em',
            display: 'flex',
            alignItems: 'center',
            fontSize: medium,
            fontWeight: blackWeight
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0.875em'
        },
        '& .MuiFormLabel-filled': {
            fontWeight: blackWeight
        },
        '& .MuiInputLabel-root': {
            fontSize: medium,
            fontWeight: blackWeight
        }
    },
    select: {
        '& ul': {
            maxHeight: '18.750em'
        }
    },
    timeSelect: {
        maxHeight: '15em',
        top: '23.125em !important',
        marginRight: '2em'
    },
    topLabel: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: medium,
        textAlign: 'left'
    },
    lowerLabel: {
        color: ballGray,
        fontSize: small,
        textAlign: 'left',
        marginTop: theme.spacing(1)
    }
}));

interface ShipmentTimeSelectorProps {
    shipmentDeliveryDateAndTime: string | undefined;
    ExpandMoreIcon: any;
    deliveryTimeList: DeliveryTime[];
}

const ShipmentTimeSelector: React.FC<ShipmentTimeSelectorProps> = ({
    shipmentDeliveryDateAndTime,
    ExpandMoreIcon,
    deliveryTimeList
}) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const { control, errors, triggerValidation } = useFormContext();

    const isRequired = (
        <Trans i18nKey="required" i18n={i18n}>
            Required
        </Trans>
    );

    /** Trigger validation when this component is loaded to show the informtion to the user */
    useEffect(() => {
        triggerValidation();
    }, [triggerValidation]);

    return (
        <Grid
            container
            direction="column"
            className={classes.root}
            spacing={1}
            alignItems="flex-start"
        >
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.topLabel}>
                    <Trans i18nKey="deliveryTime">Delivery Time</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} className={classes.selectWrapper}>
                <FormControl error={!!errors.deliveryDateTime}>
                    <InputLabel>
                        <Trans i18nKey="time">Time</Trans>
                    </InputLabel>
                    <Controller
                        as={
                            <Select
                                inputProps={{
                                    'data-testid': 'time-select'
                                }}
                                IconComponent={ExpandMoreIcon}
                                className={classes.select}
                                value={shipmentDeliveryDateAndTime}
                                MenuProps={{ classes: { paper: classes.timeSelect } }}
                            >
                                <MenuItem>&nbsp;</MenuItem>
                                {deliveryTimeList.length &&
                                    deliveryTimeList.map((time) => (
                                        <MenuItem
                                            key={time.label}
                                            value={moment(time.time).format()}
                                            disabled={time.disabled}
                                        >
                                            {time.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        }
                        name="deliveryDateTime"
                        defaultValue={shipmentDeliveryDateAndTime}
                        control={control}
                        rules={{ required: isRequired }}
                    />
                    <FormHelperText>
                        <ErrorMessage errors={errors} name="deliveryDateTime" />
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.redText}>
                    Shipping less than 25 PL incurs fees.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ShipmentTimeSelector;
