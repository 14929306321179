export const naLanguages = [
    { name: 'English', value: 'en-US', salesforceValue: 'English-US' },
    { name: 'Español', value: 'es-MX', salesforceValue: 'Spanish' },
    { name: 'Français', value: 'fr-FR', salesforceValue: 'French' }
];

export const saLanguages = [
    { name: 'Português', value: 'pt-BR', salesforceValue: 'Portuguese' },
    { name: 'English', value: 'en-US', salesforceValue: 'English-US' },
    { name: 'Español', value: 'es-ES', salesforceValue: 'Spanish' }
];

export const euLanguages = [
    { name: 'English', value: 'en-GB', salesforceValue: 'English-UK' },
    { name: 'Español', value: 'es-ES', salesforceValue: 'Spanish' },
    { name: 'Deutsche', value: 'de-DE', salesforceValue: 'German' }
];

export const naCountries = [
    { name: 'Aruba', value: 'AW', i18nKey: 'aruba' },
    { name: 'Bahama', value: 'BS', i18nKey: 'bahama' },
    { name: 'Canada', value: 'CA', i18nKey: 'canada' },
    { name: 'Guatemala', value: 'GU', i18nKey: 'guatemala' },
    { name: 'Honduras', value: 'HN', i18nKey: 'honduras' },
    { name: 'Mexico', value: 'MX', i18nKey: 'mexico' },
    { name: 'United States', value: 'US', i18nKey: 'usa' }
];

export const saCountries = [
    { name: 'Argentina', value: 'AR' },
    { name: 'Brazil', value: 'BR' },
    { name: 'Chile', value: 'CL' },
    { name: 'Colombia', value: 'CO' },
    { name: 'Ecuador', value: 'EC' },
    { name: 'Paraguay', value: 'PY' },
    { name: 'Peru', value: 'PE' },
    { name: 'Uruguay', value: 'UY' }
];

export const euCountries = [
    { name: 'Albania', value: 'AL' },
    { name: 'Algeria', value: 'DZ' },
    { name: 'Austria', value: 'AT' },
    { name: 'Azerbaijan', value: 'AZ' },
    { name: 'Bahrain', value: 'BH' },
    { name: 'Belgium', value: 'BE' },
    { name: 'Belarus', value: 'BY' },
    { name: 'Bosnia-Herz', value: 'BA' },
    { name: 'Bulgaria', value: 'BG' },
    { name: 'Croatia', value: 'HR' },
    { name: 'Czech', value: 'CZ' },
    { name: 'Denmark', value: 'DK' },
    { name: 'Egypt', value: 'EG' },
    { name: 'Estonia', value: 'EE' },
    { name: 'Faroe Islands', value: 'FO' },
    { name: 'Finland', value: 'FI' },
    { name: 'France', value: 'FR' },
    { name: 'Georgia', value: 'GE' },
    { name: 'Germany', value: 'DE' },
    { name: 'Greece', value: 'GR' },
    { name: 'Hungary', value: 'HU' },
    { name: 'Iceland', value: 'IS' },
    { name: 'Iraq', value: 'IQ' },
    { name: 'Ireland', value: 'IE' },
    { name: 'Italy', value: 'IT' },
    { name: 'Israel', value: 'IL' },
    { name: 'Jordan', value: 'JO' },
    { name: 'Kazakhstan', value: 'KZ' },
    { name: 'Kosovo', value: 'XK' },
    { name: 'Kyrgyzstan', value: 'KG' },
    { name: 'Kuwait', value: 'KW' },
    { name: 'Latvia', value: 'LV' },
    { name: 'Lebanon', value: 'LB' },
    { name: 'Libya', value: 'LY' },
    { name: 'Lithuania', value: 'LT' },
    { name: 'Netherlands', value: 'NL' },
    { name: 'Norway', value: 'NO' },
    { name: 'Macedonia', value: 'MK' },
    { name: 'Morocco', value: 'MA' },
    { name: 'Montenegro', value: 'ME' },
    { name: 'Oman', value: 'OM' },
    { name: 'Palestine', value: 'PS' },
    { name: 'Poland', value: 'PL' },
    { name: 'Portugal', value: 'PT' },
    { name: 'Romania', value: 'RO' },
    { name: 'Saudi Arabia', value: 'SA' },
    { name: 'Serbia', value: 'RS' },
    { name: 'Slovakia', value: 'SK' },
    { name: 'Slovenia', value: 'SI' },
    { name: 'Spain', value: 'ES' },
    { name: 'Sweden', value: 'SE' },
    { name: 'Switzerland', value: 'CH' },
    { name: 'Tunisia', value: 'TN' },
    { name: 'Turkey', value: 'TR' },
    { name: 'Turkmenistan', value: 'TM' },
    { name: 'UAE', value: 'AE' },
    { name: 'Ukraine', value: 'UA' },
    { name: 'UK', value: 'GB' },
    { name: 'Uzbekistan', value: 'UZ' },
    { name: 'Yemen', value: 'YE' }
];

export const printType = [
    { name: 'Bright Cans', i18nKey: 'brightCans' },
    { name: 'Sleeved Cans', i18nKey: 'sleevedCans' },
    { name: 'Printed Cans', i18nKey: 'printedCans' }
];

export const naProductSize = [
    '5.5oz/170mL - Slim',
    '187mL - Slim',
    '6.8oz/200mL - Slim',
    '8.4oz - Slim',
    '250mL - Slim Wine Only',
    '7.5oz - Sleek',
    '8.0oz - Sleek',
    '12oz/355mL - Sleek',
    '12oz/355mL - Standard',
    '375mL – Standard',
    '16oz/473mL - Standard',
    '19.2oz/568mL - Standard',
    '24oz - Cap Can',
    '24oz - King',
    '25.4oz/750mL - King',
    '32oz - King',
    '12oz - Alumi-Tek® - Bottle',
    '16oz - Alumi-Tek® - Bottle',
    '8.4oz – Standard Widget Inside™',
    '12oz - Sleek Widget Inside™',
    '12oz – Standard Widget Inside™',
    '16oz – Standard Widget Inside™',
    '200 CDL-W - End',
    '202 B64 - End',
    '202 CDL+ - End',
    '206 CDL-W - End',
    '209 LOE - End',
    '300 OSR - End',
    '38MM Lug - End',
    'Other'
];
export const euProductSize = [
    '150mL - Slim',
    '200mL - Slim',
    '250mL - Slim',
    '250mL - Sleek',
    '330mL - Sleek',
    '355mL - Sleek',
    '400mL - Sleek',
    '450mL - Sleek',
    '473mL - Sleek',
    '591mL - Sleek',
    '330mL- Standard',
    '440mL - Standard',
    '500mL - Standard',
    '568mL - Standard',
    '750mL - King',
    '900mL - King',
    '1000mL - King',
    '12oz - Alumi-Tek® - Bottle',
    '16oz - Alumi-Tek® - Bottle',
    '275mL - Silhouette',
    '330mL - Silhouette',
    '330mL - Impact Long Neck',
    'Other'
];
export const saProductSize = [
    '269mL - Sleek',
    '310mL - Sleek',
    '7.5oz - Sleek',
    '12oz/355mL - Sleek',
    '410mL - Sleek',
    '12oz/355mL - Standard',
    '16oz/473mL - Standard',
    '24oz - King',
    '202 B64 - End',
    '202 CDL+ - End',
    '206 CDL-W - End',
    '209 LOE - End',
    '300 OSR - End',
    'Other'
];

export const naBeverageCategories = [
    { value: 'COFFEE/DAIRY', key: 'CoffeeDairy', i18nKey: 'naBeverageCategoryCoffeeDairy' },
    { value: 'CRAFT BEER', key: 'BeerCraft', i18nKey: 'naBeverageCategoryBeerCraft' },
    { value: 'CARBONATED SOFT DRINK', key: 'Csd', i18nKey: 'naBeverageCategoryCsd' },
    {
        value: 'CLUB SODA/SELTZER',
        key: 'ClubSodaSeltzer',
        i18nKey: 'naBeverageCategoryClubSodaSeltzer'
    },
    { value: 'DOMESTIC BEER', key: 'DomesticBeer', i18nKey: 'naBeverageCategoryDomesticBeer' },
    { value: 'ENERGY DRINK', key: 'EnergyDrink', i18nKey: 'naBeverageCategoryEnergyDrink' },
    { value: 'FAB/CIDER', key: 'FabCider', i18nKey: 'naBeverageCategoryFabCider' },
    { value: 'EUROPEAN BEER', key: 'EuropenBeer', i18nKey: 'naBeverageCategoryEuropeanBeer' },
    { value: 'JUICE', key: 'Juice', i18nKey: 'naBeverageCategoryJuice' },
    { value: 'SPORTS DRINKS', key: 'SportsDrink', i18nKey: 'naBeverageCategorySportsDrink' },
    { value: 'SPARKLING/STILL WATER', key: 'StillWater', i18nKey: 'naBeverageCategoryStillWater' },
    { value: 'TEA', key: 'Tea', i18nKey: 'naBeverageCategoryTea' },
    { value: 'WINE', key: 'Wine', i18nKey: 'naBeverageCategoryWine' },
    { value: 'CARIBBEAN BEER', key: 'CarribeanBeer', i18nKey: 'naBeverageCategoryCarribeanBeer' },
    { value: 'MEXICAN BEER (US)', key: 'MexicanBeer', i18nKey: 'naBeverageCategoryMexicanBeer' }
];

export const euBeverageCategories = [
    { value: 'Beer', key: 'Beer', i18nKey: 'beverageCategoryBeer' },
    { value: 'Carbonated Soft Drink (CSD)', key: 'Csd', i18nKey: 'beverageCategoryCsd' },
    { value: 'Cider', key: 'Cider', i18nKey: 'beverageCategoryCider' },
    { value: 'Iced Coffee', key: 'IcedCoffee', i18nKey: 'beverageCategoryIcedCoffee' },
    { value: 'Iced Tea', key: 'IcedTea', i18nKey: 'beverageCategoryIcedTea' },
    { value: 'Energy Drinks', key: 'EnergyDrink', i18nKey: 'beverageCategoryEnergyDrink' },
    { value: 'Hard Seltzer', key: 'HardSeltzer', i18nKey: 'beverageCategoryHardSeltzer' },
    { value: 'Juice', key: 'Juice', i18nKey: 'beverageCategoryJuice' },
    { value: 'Protein/Milk Based', key: 'Protein', i18nKey: 'beverageCategoryProtein' },
    { value: 'Sports/Functional', key: 'SportsDrink', i18nKey: 'beverageCategorySportsDrink' },
    { value: 'Water', key: 'Water', i18nKey: 'beverageCategoryWater' },
    { value: 'Wine', key: 'Wine', i18nKey: 'beverageCategoryWine' },
    { value: 'Spirits', key: 'Spirits', i18nKey: 'beverageCategorySpirits' },
    { value: "FAB's", key: 'Fab', i18nKey: 'beverageCategoryEMEAFab' },
    { value: 'Other', key: 'Other', i18nKey: 'beverageCategoryOther' }
];

export const saBeverageCategories = [
    { value: 'Beer - Craft', key: 'BeerCraft', i18nKey: 'beverageCategoryBeerCraft' },
    { value: 'Beer - Domestic', key: 'BeerDomestic', i18nKey: 'beverageCategoryBeerDomestic' },
    { value: 'Beer - Import', key: 'BeerImport', i18nKey: 'beverageCategoryBeerImport' },
    { value: 'Carbonated Soft Drink (CSD)', key: 'Csd', i18nKey: 'beverageCategoryCsd' },
    { value: 'Cider', key: 'Cider', i18nKey: 'beverageCategoryCider' },
    { value: 'Energy Drinks', key: 'EnergyDrink', i18nKey: 'beverageCategoryEnergyDrink' },
    { value: 'Hard Liquor', key: 'HardLiquor', i18nKey: 'beverageCategoryHardLiquor' },
    { value: 'Juice', key: 'Juice', i18nKey: 'beverageCategoryJuice' },
    { value: 'RTD', key: 'Rtd', i18nKey: 'beverageCategoryRtd' },
    { value: 'Sports Drinks', key: 'SportsDrink', i18nKey: 'beverageCategorySportsDrink' },
    { value: 'Tea', key: 'Tea', i18nKey: 'beverageCategoryTea' },
    { value: 'Water', key: 'Water', i18nKey: 'beverageCategoryWater' },
    { value: 'Wine', key: 'Wine', i18nKey: 'beverageCategoryWine' },
    { value: 'Other', key: 'Other', i18nKey: 'beverageCategoryOther' }
];

export const emeaCanSize = [
    { value: '150 ml', name: '150 ml', i18nKey: 'emeaCanSize150ml' },
    { value: '185 ml', name: '185 ml', i18nKey: 'emeaCanSize185ml' },
    { value: '200 ml', name: '200 ml', i18nKey: 'emeaCanSize200ml' },
    { value: '237 ml', name: '237 ml', i18nKey: 'emeaCanSize237ml' },
    { value: '250 ml', name: '250 ml', i18nKey: 'emeaCanSize250ml' },
    { value: '275 ml', name: '275 ml', i18nKey: 'emeaCanSize275ml' },
    { value: '330 ml', name: '330 ml', i18nKey: 'emeaCanSize330ml' },
    { value: '354 ml (12 oz)', name: '354 ml (12 oz)', i18nKey: 'emeaCanSize354ml' },
    { value: '355 ml', name: '355 ml', i18nKey: 'emeaCanSize355ml' },
    { value: '400 ml', name: '400 ml', i18nKey: 'emeaCanSize400ml' },
    { value: '440 ml', name: '440 ml', i18nKey: 'emeaCanSize440ml' },
    { value: '450 ml', name: '450 ml', i18nKey: 'emeaCanSize450ml' },
    { value: '473 ml', name: '473 ml', i18nKey: 'emeaCanSize473ml' },
    { value: '500 ml', name: '500 ml', i18nKey: 'emeaCanSize500ml' },
    { value: '568 ml', name: '568 ml', i18nKey: 'emeaCanSize568ml' },
    { value: '591 ml', name: '591 ml', i18nKey: 'emeaCanSize591ml' },
    { value: '710 ml', name: '710 ml', i18nKey: 'emeaCanSize710ml' },
    { value: '750 ml', name: '750 ml', i18nKey: 'emeaCanSize750ml' },
    { value: '900 ml', name: '900 ml', i18nKey: 'emeaCanSize900ml' },
    { value: '1000 ml', name: '1000 ml', i18nKey: 'emeaCanSize1000ml' }
];

export const emeaCanType = [
    {
        value: 'Standard + 202 Neck',
        name: 'Standard + 202 Neck',
        i18nKey: 'emeaCanTypeStandard202Neck'
    },
    { value: 'Slim + 200 Neck', name: 'Slim + 200 Neck', i18nKey: 'emeaCanTypeSlim200Neck' },
    { value: 'Sleek + 200 Neck', name: 'Sleek + 200 Neck', i18nKey: 'emeaCanTypeSleek200Neck' },
    { value: 'Sleek + 202 Neck', name: 'Sleek + 202 Neck', i18nKey: 'emeaCanTypeSleek202Neck' },
    {
        value: 'Supersleek + 202 Neck',
        name: 'Supersleek + 202 Neck',
        i18nKey: 'emeaCanTypeSupersleek202Neck'
    },
    { value: 'King + 209 Neck', name: 'King + 209 Neck', i18nKey: 'emeaCanTypeKing209Neck' },
    { value: 'Alumitek (Bottle)', name: 'Alumitek (Bottle)', i18nKey: 'emeaCanTypeAlumitekBottle' },
    {
        value: 'Silhouette (Bottle)',
        name: 'Silhouette (Bottle)',
        i18nKey: 'emeaCanTypeSilhouetteBottle'
    }
];

export const emeaMetal = [
    { value: 'Aluminium', name: 'Aluminium', i18nKey: 'emeaMetalAluminium' }, // If you change value also change defaultValue for Metal field in EMEA graphics form
    { value: 'Steel', name: 'Steel', i18nKey: 'emeaMetalSteel' }
];

export const emeaBallSpecialFinishes = [
    {
        value: 'No Special finish (Design)',
        name: 'No Special finish (Design)',
        i18nKey: 'emeaBallSpecialFinishesNoSpecialfinish'
    }, // If you change value also change defaultValue for Ball Special Finishes field in EMEA graphics form
    { value: 'Dynamark TM', name: 'Dynamark TM', i18nKey: 'emeaBallSpecialFinishesDynamarkTM' },
    {
        value: 'Register Deboss',
        name: 'Register Deboss',
        i18nKey: 'emeaBallSpecialFinishesRegisterDeboss'
    }
];

export const emeaBasecoatColour = [
    { value: 'No Basecoat', name: 'No Basecoat', i18nKey: 'emeaBasecoatColourNoBasecoat' },
    { value: 'White Basecoat', name: 'White Basecoat', i18nKey: 'emeaBasecoatColourWhiteBasecoat' },
    {
        value: 'Clear Basecoat (Steel only)',
        name: 'Clear Basecoat (Steel only)',
        i18nKey: 'emeaBasecoatColourClearBasecoatSteelonly'
    },
    {
        value: 'Alu like basecoat (Steel only)',
        name: 'Alu like basecoat (Steel only)',
        i18nKey: 'emeaBasecoatColourAlulikebasecoatSteelonly'
    }
];

export const emeaOverVarnish = [
    { value: 'Standard', name: 'Standard', i18nKey: 'emeaOverVarnishStandard' },
    { value: 'Matte', name: 'Matte', i18nKey: 'emeaOverVarnishMatte' },
    { value: 'Tactile', name: 'Tactile', i18nKey: 'emeaOverVarnishTactile' },
    {
        value: 'Gloss + Connect (HST)',
        name: 'Gloss + Connect (HST)',
        i18nKey: 'emeaOverVarnissGlossConnect'
    },
    {
        value: 'Matte + Connect (HST)',
        name: 'Matte + Connect (HST)',
        i18nKey: 'emeaOverVarnishMatteConnect'
    }
];

export const emeaBallSpecialInk = [
    { value: 'No Special ink', name: 'No Special ink', i18nKey: 'emeaBallSpecialInkNoSpecialink' }, // If you change value also change defaultValue for Ball Special Ink field in EMEA graphics form
    {
        value: 'Neon (UV Fluorescent)',
        name: 'Neon (UV Fluorescent)',
        i18nKey: 'emeaBallSpecialInkNeonUVFluorescent'
    },
    {
        value: 'Glow in the Dark',
        name: 'Glow in the Dark',
        i18nKey: 'emeaBallSpecialInkGlowintheDark'
    },
    { value: 'DayGlo', name: 'DayGlo', i18nKey: 'emeaBallSpecialInkDayGlo' },
    { value: 'Thermochromic', name: 'Thermochromic', i18nKey: 'emeaBallSpecialInkThermochromic' },
    { value: 'Photochromic', name: 'Photochromic', i18nKey: 'emeaBallSpecialInkPhotochromic' }
];

export const emeaDepositScheme = [
    { value: 'Not Applicable', name: 'Not Applicable', i18nKey: 'emeaDepositSchemeNotApplicable' }, // If you change value also change defaultValue for Deposit Scheme field in EMEA graphics form
    { value: 'German - DPG', name: 'German - DPG', i18nKey: 'emeaDepositSchemeGermanDPG' }, // value is used for a watch in MarketInfo.tsx
    {
        value: 'Sweden - Returpack',
        name: 'Sweden - Returpack',
        i18nKey: 'emeaDepositSchemeSwedenReturpack'
    },
    {
        value: 'Denmark - Retursystem',
        name: 'Denmark - Retursystem',
        i18nKey: 'emeaDepositSchemeDenmarkRetursystem'
    },
    { value: 'Finland - Palpa', name: 'Finland - Palpa', i18nKey: 'emeaDepositSchemeFinlandPalpa' }
];

export const emeaProductContent = [
    { value: 'Beer - Beer', name: 'Beer - Beer', i18nKey: 'emeaProductContentBeerBeer' },
    { value: 'Beer - Craft', name: 'Beer - Craft', i18nKey: 'emeaProductContentBeerCraft' },
    {
        value: 'Beer - Flavoured',
        name: 'Beer - Flavoured',
        i18nKey: 'emeaProductContentBeerFlavoured'
    },
    {
        value: 'Beer - Non alcoholic',
        name: 'Beer - Non alcoholic',
        i18nKey: 'emeaProductContentBeerNonalcoholic'
    },
    { value: 'Cider - Cider', name: 'Cider - Cider', i18nKey: 'emeaProductContentCiderCider' },
    {
        value: 'Cider - Flavoured',
        name: 'Cider - Flavoured',
        i18nKey: 'emeaProductContentCiderFlavoured'
    },
    {
        value: 'Cider - Non alcoholic',
        name: 'Cider - Non alcoholic',
        i18nKey: 'emeaProductContentCiderNonalcoholic'
    },
    { value: 'Wine - Still', name: 'Wine - Still', i18nKey: 'emeaProductContentWineStill' },
    {
        value: 'Wine - Sparkling',
        name: 'Wine - Sparkling',
        i18nKey: 'emeaProductContentWineSparkling'
    },
    {
        value: 'Wine - Still & Sparkling wine based cocktail',
        name: 'Wine - Still & Sparkling wine based cocktail',
        i18nKey: 'emeaProductContentWineStillSparklingwinebasedcocktail'
    },
    {
        value: 'FABs - Non Wine based',
        name: 'FABs - Non Wine based',
        i18nKey: 'emeaProductContentFABsNonWinebased'
    },
    {
        value: 'Energy Drink - Carbonated',
        name: 'Energy Drink - Carbonated',
        i18nKey: 'emeaProductContentEnergyDrinkCarbonated'
    },
    {
        value: 'Energy Drink - Non Carbonated',
        name: 'Energy Drink - Non Carbonated',
        i18nKey: 'emeaProductContentEnergyDrinkNonCarbonated'
    },
    {
        value: 'Soft Drink - Carbonated',
        name: 'Soft Drink - Carbonated',
        i18nKey: 'emeaProductContentSoftDrinkCarbonated'
    },
    {
        value: 'Soft Drink - Non Carbonated',
        name: 'Soft Drink - Non Carbonated',
        i18nKey: 'emeaProductContentSoftDrinkNonCarbonated'
    },
    {
        value: 'Soft Drink - Carbonated - Juice content',
        name: 'Soft Drink - Carbonated - Juice content',
        i18nKey: 'emeaProductContentSoftDrinkCarbonatedJuicecontent'
    },
    {
        value: 'Soft Drink - Non Carbonated - Juice content',
        name: 'Soft Drink - Non Carbonated - Juice content',
        i18nKey: 'emeaProductContentSoftDrinkNonCarbonatedJuicecontent'
    },
    {
        value: 'Juice - Juice 100%',
        name: 'Juice - Juice 100%',
        i18nKey: 'emeaProductContentJuiceJuice100%'
    },
    {
        value: 'Water - Carbonated',
        name: 'Water - Carbonated',
        i18nKey: 'emeaProductContentWaterCarbonated'
    },
    {
        value: 'Water - Non Carbonated',
        name: 'Water - Non Carbonated',
        i18nKey: 'emeaProductContentWaterNonCarbonated'
    },
    {
        value: 'Water - Flavoured - Carbonated',
        name: 'Water - Flavoured - Carbonated',
        i18nKey: 'emeaProductContentWaterFlavouredCarbonated'
    },
    {
        value: 'Water - Flavoured - Non Carbonated',
        name: 'Water - Flavoured - Non Carbonated',
        i18nKey: 'emeaProductContentWaterFlavouredNonCarbonated'
    },
    {
        value: 'Dairy - Dairy Based',
        name: 'Dairy - Dairy Based',
        i18nKey: 'emeaProductContentDairyDairyBased'
    },
    {
        value: 'Coffee - RTD coffee drinks',
        name: 'Coffee - RTD coffee drinks',
        i18nKey: 'emeaProductContentCoffeeRTDcoffeedrinks'
    },
    {
        value: 'Tea - RTD Tea drinks',
        name: 'Tea - RTD Tea drinks',
        i18nKey: 'emeaProductContentTeaRTDTeadrinks'
    },
    {
        value: 'Functional - Functional drinks',
        name: 'Functional - Functional drinks',
        i18nKey: 'emeaProductContentFunctionalFunctionaldrinks'
    },
    {
        value: 'Niche - Niche Soft Drinks',
        name: 'Niche - Niche Soft Drinks',
        i18nKey: 'emeaProductContentNicheNicheSoftDrinks'
    }
];

export const emeaProductFormulation = [
    { value: 'New', name: 'New Recipe', i18nKey: 'emeaProductFormulationNewRecipe' },
    { value: 'Existing', name: 'Existing Recipe', i18nKey: 'emeaProductFormulationExistingRecipe' }
];

export const emeaProductPasteurisation = [
    { value: 'None', name: 'None', i18nKey: 'emeaProductPasteurisationNone' },
    { value: 'Normal < 68°C', name: 'Normal < 68°C', i18nKey: 'emeaProductPasteurisationNormal' },
    {
        value: 'High >68°C & <100°C',
        name: 'High >68°C & <100°C',
        i18nKey: 'emeaProductPasteurisationHigh'
    },
    { value: 'Retort >110°C', name: 'Retort >110°C', i18nKey: 'emeaProductPasteurisationRetort' }
];

export const ncaProductQualitySymbol = [
    { value: 'None', name: 'None', i18nKey: 'ncaProductQualitySymbolNone' },
    { value: 'Kosher K', name: 'Kosher K', i18nKey: 'ncaProductQualitySymbolKosherK' },
    { value: 'Kosher CRC', name: 'Kosher CRC', i18nKey: 'ncaProductQualitySymbolKosherCRC' },
    { value: 'Kosher KOF', name: 'Kosher KOF', i18nKey: 'ncaProductQualitySymbolKosherKOF' },
    { value: 'Deposit', name: 'Deposit', i18nKey: 'ncaProductQualitySymbolDeposit' },
    { value: 'NY Band', name: 'NY Band', i18nKey: 'ncaProductQualitySymbolNYBand' }
];

export const emeaCountriestoMarketArtwork = [
    { value: 'EU', name: 'EU', i18nKey: 'eu' },
    { value: 'United Kingdom', name: 'United Kingdom', i18nKey: 'uk' },
    { value: 'Germany', name: 'Germany', i18nKey: 'germany' },
    { value: 'France', name: 'France', i18nKey: 'france' },
    { value: 'Canada', name: 'Canada', i18nKey: 'canada' },
    { value: 'USA', name: 'USA', i18nKey: 'usa' },
    { value: 'China', name: 'China', i18nKey: 'china' },
    { value: 'Japan', name: 'Japan', i18nKey: 'japan' },
    {
        value: 'South Korea',
        name: 'South Korea',
        i18nKey: 'southKorea'
    },
    { value: 'Australia', name: 'Australia', i18nKey: 'australia' },
    { value: 'New Zealand', name: 'New Zealand', i18nKey: 'nz' },
    { value: 'Mexico', name: 'Mexico', i18nKey: 'mexico' },
    { value: 'India', name: 'India', i18nKey: 'india' },
    { value: 'Vietnam', name: 'Vietnam', i18nKey: 'vietnam' },
    { value: 'Taiwan', name: 'Taiwan', i18nKey: 'taiwan' },
    { value: 'Argentina', name: 'Argentina', i18nKey: 'argentina' },
    { value: 'Brazil', name: 'Brazil', i18nKey: 'brazil' },
    { value: 'Paraguay', name: 'Paraguay', i18nKey: 'paraguay' },
    { value: 'Uruguay', name: 'Uruguay', i18nKey: 'uruguay' },
    { value: 'Venezuela', name: 'Venezuela', i18nKey: 'venezuela' },
    { value: 'None', name: 'None of the specified countries apply', i18nKey: 'noCountryApplies' }
];

export const naExpectedQuantity = [
    '< 125,000',
    '125,000 - 2,000,000',
    '2,000,000 - 10,000,000',
    '10,000,000+'
];

export const euExpectedQuantity = [
    'Below 500k',
    '500k – 1 million',
    '1 million – 5 million',
    '5 million – 10 million',
    '10 million+'
];

export const saAnnualQuantity = [
    '< 36,000 cans',
    '36,000 – 200,000 cans',
    '200,000 – 400,000',
    '400,000 - 2,000,000',
    '> 2,000,000'
];

export const yearsInBusiness = ['< 1 year', '1-3 years', '4-5 years', '6-10 years', '11+ years'];

export const numberOfEmployees = ['<5', '5-25', '26-100', '101-1,000', '1,000+'];

export const naAnnualRevenue = [
    'New to Beverage',
    '< $1 Million',
    '$1-$5 Million',
    '$6-$10 Million',
    '$11-$20 Million',
    '$21 Million+'
];

export const euAnnualRevenue = [
    'New to Beverage',
    '<€1 Million',
    '€1-€5 Million',
    '€6-€10 Million',
    '€11-€20 Million',
    '€21 Million+'
];

export const saAnnualRevenue = [
    'New to Beverage',
    '< R$1 Million',
    'R$1-R$5 Million',
    'R$6-R$10 Million',
    'R$11-R$20 Million',
    'R$21 Million+'
];

export const securityQuestions = [
    { value: 'What is the food you least liked as a child?', i18nKey: 'securityQuestionFood' },
    {
        value: 'What was the name of your first stuffed animal?',
        i18nKey: 'securityQuestionStuffAnimal'
    },
    { value: 'What did you earn your first medal or award for?', i18nKey: 'securityQuestionAward' },
    {
        value: 'What was the first computer game you played?',
        i18nKey: 'securityQuestionComputerGame'
    },
    { value: 'What is your favorite movie quote?', i18nKey: 'securityQuestionMovieQuote' },
    {
        value: 'What was the mascot of the first sports team you played on?',
        i18nKey: 'securityQuestionSportsTeam'
    },
    { value: 'What was your grandmother’s favorite dessert?', i18nKey: 'securityQuestionDessert' },
    { value: 'What was the first thing you learned to cook?', i18nKey: 'securityQuestionCook' },
    { value: 'What was your dream job as a child?', i18nKey: 'securityQuestionDreamJob' },
    { value: 'Where did you go for your favorite vacation?', i18nKey: 'securityQuestionVacation' }
];

export const packageType = ['Cans', 'Draft/Kegs', 'Glass', 'Plastic', 'Carton', 'Other'];

export const dateFormats = [
    { name: 'MM/DD/YY', value: 'MM/DD/YY', i18nKey: 'mmddyy' },
    { name: 'DD/MM/YY', value: 'DD/MM/YY', i18nKey: 'ddmmyy' }
];

export const regions = [
    {
        name: 'North and Central America',
        value: 'NA',
        salesforceValue: 'NCA',
        beverageCategories: naBeverageCategories,
        expectedAnnualQuantity: naExpectedQuantity,
        languages: naLanguages,
        countries: naCountries,
        annualRevenue: naAnnualRevenue,
        productSize: naProductSize,
        securityQuestions: securityQuestions
    },
    {
        name: 'Europe, Middle East, and Africa',
        value: 'EU',
        salesforceValue: 'EMEA',
        beverageCategories: euBeverageCategories,
        expectedAnnualQuantity: euExpectedQuantity,
        languages: euLanguages,
        countries: euCountries,
        annualRevenue: euAnnualRevenue,
        productSize: euProductSize,
        securityQuestions: securityQuestions
    },
    {
        name: 'South America',
        value: 'SA',
        salesforceValue: 'SA',
        beverageCategories: saBeverageCategories,
        expectedAnnualQuantity: saAnnualQuantity,
        languages: saLanguages,
        countries: saCountries,
        annualRevenue: saAnnualRevenue,
        productSize: saProductSize,
        securityQuestions: securityQuestions
    }
];

export const contacts = [
    'ballaluminumcansales@ball.com',
    'balleurope@ball.com',
    'novosnegocios@ball.com'
];

export const shipmentQuantityUnits = [
    { value: 'pallets', name: 'Pallets' },
    { value: 'eaches', name: 'Eaches' }
];

export const businessTypes = [
    { name: 'Corporation', value: 'CORPORATION', i18nKey: 'corporation' },
    { name: 'Sole Proprietorship', value: 'SOLE', i18nKey: 'soleProprietorship' },
    { name: 'Partnership', value: 'PARTNERSHIP', i18nKey: 'partnership' }
];

export const usStateProvs = [
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VA', name: 'Virginia' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' }
];

export const canadaStateProvs = [
    { value: 'AB', name: 'Alberta' },
    { value: 'BC', name: 'British Columbia' },
    { value: 'MB', name: 'Manitoba' },
    { value: 'NB', name: 'New Brunswick' },
    { value: 'NL', name: 'Newfoundland and Labrador' },
    { value: 'NS', name: 'Nova Scotia' },
    { value: 'NT', name: 'Northwest Territories' },
    { value: 'NU', name: 'Nunavut' },
    { value: 'ON', name: 'Ontario' },
    { value: 'PE', name: 'Prince Edward Island' },
    { value: 'QC', name: 'Quebec' },
    { value: 'SK', name: 'Saskatchewan' },
    { value: 'YT', name: 'Yukon' }
];

export const mexicoStateProvs = [
    { value: 'DIF', name: 'Distrito Federal' },
    { value: 'AGS', name: 'Aguascalientes' },
    { value: 'BCN', name: 'Baja California' },
    { value: 'BCS', name: 'Baja California Sur' },
    { value: 'CAM', name: 'Campeche' },
    { value: 'CHP', name: 'Chiapas' },
    { value: 'CHI', name: 'Chihuahua' },
    { value: 'COA', name: 'Coahuila' },
    { value: 'COL', name: 'Colima' },
    { value: 'DUR', name: 'Durango' },
    { value: 'GTO', name: 'Guanajuato' },
    { value: 'GRO', name: 'Guerrero' },
    { value: 'HGO', name: 'Hidalgo' },
    { value: 'JAL', name: 'Jalisco' },
    { value: 'MEX', name: 'México' },
    { value: 'MIC', name: 'Michoacán' },
    { value: 'MOR', name: 'Morelos' },
    { value: 'NAY', name: 'Nayarit' },
    { value: 'NLE', name: 'Nuevo León' },
    { value: 'OAX', name: 'Oaxaca' },
    { value: 'PUE', name: 'Puebla' },
    { value: 'QRO', name: 'Querétaro' },
    { value: 'ROO', name: 'Quintana Roo' },
    { value: 'SLP', name: 'San Luis Potosí' },
    { value: 'SIN', name: 'Sinaloa' },
    { value: 'SON', name: 'Sonora' },
    { value: 'TAB', name: 'Tabasco' },
    { value: 'TAM', name: 'Tamaulipas' },
    { value: 'TLX', name: 'Tlaxcala' },
    { value: 'VER', name: 'Veracruz' },
    { value: 'YUC', name: 'Yucatán' },
    { value: 'ZAC', name: 'Zacatecas' }
];

export const specialtyPrintingOptions = [
    { name: 'Basecoat', value: 'basecoat', i18nKey: 'basecoat' },
    { name: 'Retort', value: 'retort', i18nKey: 'retort' },
    { name: 'Tactile', value: 'tactile', i18nKey: 'tactile' },
    { name: 'Satin', value: 'satin', i18nKey: 'satin' }
];
export const specialtyTabOptions = [
    { name: 'Laser Tab', value: 'laserTab', i18nKey: 'laserTab' },
    { name: 'Punch', value: 'punch', i18nKey: 'punch' },
    { name: 'Colored Tab', value: 'coloredTab', i18nKey: 'coloredTab' },
    { name: 'Colored Shell', value: 'coloredShell', i18nKey: 'coloredShell' }
];

export const freightManagementOptions = [
    { name: 'Ball Managed', value: 'ballManaged', i18nKey: 'ballManaged' },
    { name: 'Customer Pick Up', value: 'customerPickUp', i18nKey: 'customerPickUp' }
];

export const customerTypeOptions = [
    {
        name: 'Forecast',
        value: 'Forecast',
        i18nKey: 'forecast'
    },
    {
        name: 'Blanket',
        value: 'Blanket',
        i18nKey: 'blanket'
    },
    {
        name: 'Cash-in-Advance (CIA)',
        value: 'CashInAdvance',
        i18nKey: 'cashInAdvance'
    }
];
