import {
    Grid,
    Typography,
    makeStyles,
    Divider,
    FormControl,
    FormHelperText,
    TextField,
    InputLabel,
    Select,
    ListItemText,
    MenuItem,
    Checkbox
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel,
    ballBlue,
    blackWeight,
    orange
} from '../../../../themes/globalConstants';
import { Controller, ErrorMessage, useFieldArray, useFormContext } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import RadioInput from '../../../reusable/molecules/RadioInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import CountrySelect from '../../../reusable/molecules/CountrySelect';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { setFormSectionStatus } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import DatePicker from '../../../reusable/atoms/DatePicker';
import { GRAPHIC_INFORMATION_STATUS_UPDATE } from '../../../../store/actions/action-types';
import { AuthState } from '../../../../store/reducers/auth';
import {
    Approver,
    ApproverType,
    GraphicIntakeRequestType,
    Graphics,
    Region
} from '../../../../store/reducers/graphic-intake';
import PhoneInput from '../../../reusable/molecules/PhoneInput';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import { Account } from '../../../../store/reducers/customer-context';
import { isDateSameOrAfter } from '../../../../utility/helpers/date-helpers';
import Button from '../../../reusable/atoms/Button';
import {
    formatAccountDropdown,
    getCompanyList,
    MAX_APPROVERS,
    SelectInputDropdown
} from '../../../../utility/helpers/graphic-helpers';
import { KeyValuePair } from '../../../../store/types';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';
import {
    selectCustomerAccountId,
    setLastBillToId
} from '../../../../store/actions/customer-context';

interface Props {
    isEditDraft: boolean;
    graphicData?: Graphics;
    companyDropdown: Account[];
    shipToLocations: Account[];
    clearForm: boolean;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    },
    fieldSet: {
        border: 'none',
        padding: 0
    },
    approverWrapper: {
        marginLeft: '0.25em'
    },
    addApprover: {
        backgroundColor: 'transparent',
        outline: 'none',
        fontWeight: blackWeight,
        fontSize: medium,
        textDecoration: 'underline',
        '&:hover': {
            color: ballBlue,
            cursor: 'pointer'
        }
    },
    removeApprover: {
        fontWeight: blackWeight,
        color: orange,
        fontSize: medium,
        textDecoration: 'underline',
        margin: '2em 0',
        '&:hover': {
            color: orange,
            cursor: 'pointer'
        }
    },
    thinDivider: {
        height: 2,
        backgroundColor: ltBlueGrey_34,
        marginBottom: '1em'
    }
}));

const GraphicsInformation = ({
    isEditDraft,
    graphicData,
    companyDropdown,
    clearForm,
    shipToLocations
}: Props) => {
    const classes = useStyles();
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const { control, errors, formState, getValues, watch, setValue, setError, clearError } =
        useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'approvers'
    });
    const { isValid } = formState;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const [selectedCompany, setSelectedCompany] = useState<Account | null>(null);
    const [billTo, setBillTo] = useState<SelectInputDropdown[]>([]);
    const [draftLoaded, setDraftLoaded] = useState<boolean>(false);
    const [hasBeenRemoved, setHasBeenRemoved] = useState<boolean>(false);
    const [colorApproverCount, setColorApproverCount] = useState<number>(0);

    const graphicType = [
        { value: GraphicIntakeRequestType.New, name: t('blankForm', 'Blank Form') },
        {
            value: GraphicIntakeRequestType.Revision,
            name: t('replaceExistingGraphics', 'Replace Existing Graphics')
        }
    ];
    const approverTypes = [
        { value: ApproverType.Billing, name: t('billing', ApproverType.Billing) },
        {
            value: ApproverType.ArtworkContent,
            name: t('artworkContent', ApproverType.ArtworkContent)
        },
        {
            value: ApproverType.PilotCanColor,
            name: t('pilotCanColor', ApproverType.PilotCanColor)
        },
        {
            value: ApproverType.LiquidSamples,
            name: t('liquidSamples', ApproverType.LiquidSamples)
        }
    ];

    const isRequired = useMemo(() => <Trans i18nKey="required">Required</Trans>, []);

    const isPromotion = watch('shortTerm') === 'Yes';
    const tomorrow = moment().add(1, 'day').startOf('day');
    const reviseGraphic = watch('requestType') === GraphicIntakeRequestType.Revision;
    const requestedByDate = watch('requestedByDate');
    const hardConversionDate = watch('hardConversionDate');
    const inRetailDate = watch('shortTermStartDate');
    const outOfRetailDate = watch('shortTermEndDate');
    const conversionType = watch('conversionType');
    const billToAddress = watch('billToAddress');
    const shipToAddress = watch('shipToAddress') as string[];
    const approvers = watch(`approvers`);

    const isOutsideRange = (day) => {
        return day.isBefore(tomorrow.startOf('day'));
    };

    const inRetailDateRange = (day) => {
        if (requestedByDate) {
            return day.isBefore(moment(requestedByDate).add(1, 'day').startOf('day'));
        }
        return isOutsideRange(day);
    };

    const outOfRetailDateRange = (day) => {
        if (inRetailDate) {
            return day.isBefore(moment(inRetailDate).add(1, 'day').startOf('day'));
        } else if (requestedByDate) {
            return day.isBefore(moment(requestedByDate).add(1, 'day').startOf('day'));
        }
        return isOutsideRange(day);
    };

    useEffect(() => {
        const approverTypes = approvers.map((_, index) => {
            const approver = `approvers[${index}]`;
            const approverType = watch(`${approver}.approverType`);

            return approverType;
        });

        const colorApproverCount = approverTypes.filter((types) =>
            types.includes('Pilot Can/Color')
        ).length;

        setColorApproverCount(colorApproverCount);
    }, [approvers]);

    useEffect(() => {
        if (isPromotion) {
            // Handle error displays for Short Term dates
            if (isDateSameOrAfter(requestedByDate, inRetailDate)) {
                setError('shortTermStartDate', { type: 'manual' });
            } else if (inRetailDate && errors.shortTermStartDate) {
                clearError('shortTermStartDate');
            }

            if (isDateSameOrAfter(inRetailDate, outOfRetailDate)) {
                setError('shortTermEndDate', { type: 'manual' });
            } else if (outOfRetailDate && errors.shortTermEndDate) {
                clearError('shortTermEndDate');
            }
        }
        // Clears required error for datepicker
        if (requestedByDate && errors.requestedByDate) {
            clearError('requestedByDate');
        }
    }, [isPromotion, requestedByDate, inRetailDate, outOfRetailDate, clearError, setError, errors]);

    useEffect(() => {
        // Clears required error for datepicker
        if (hardConversionDate && errors.hardConversionDate) {
            clearError('hardConversionDate');
        }
    }, [hardConversionDate, clearError, errors]);

    const customerShortTerm = [
        { value: 'Yes', label: <Trans i18nKey="yes">Yes</Trans> },
        { value: 'No', label: <Trans i18nKey="no">No</Trans> }
    ];

    // Sets the date value to the form element
    const handleDateChange = (name: string, newDate: moment.Moment | null) => {
        setValue(name, newDate);
    };

    const handleUpdateAccountInformation = (event: any, updatedSelection: Account | null) => {
        setSelectedCompany(updatedSelection);
    };

    const handleLoadDraftAccountInformation = useCallback(
        (customerAccountId: string | undefined) => {
            let account = companyDropdown.find(
                (company) => company.accountId.toString() === customerAccountId?.toString()
            );
            if (account) {
                setSelectedCompany(account);
            }
        },
        [companyDropdown]
    );

    const handleLoadDraftApproverCountry = (name: string, selectedCountry: KeyValuePair) => {
        setValue(name, selectedCountry);
    };

    const handleAddApprover = () => {
        const blankApprover = {
            approverName: '',
            approverEmail: '',
            approverAddress: '',
            approverAddressStreet: '',
            approverAddressCity: '',
            approverAddressState: '',
            approverAddressPostalCode: '',
            approverAddressCountry: '',
            approverPhoneNumber: undefined,
            approverType: []
        };
        append(blankApprover);
    };

    const handleRemoveApprover = (index) => {
        remove(index);
    };

    useEffect(() => {
        if (graphicIntake && isValid !== graphicIntake.graphicsInformationValid) {
            dispatch(setFormSectionStatus(isValid, GRAPHIC_INFORMATION_STATUS_UPDATE));
        }
    }, [dispatch, isValid, graphicIntake]);

    useEffect(() => {
        if (userInfo) {
            setValue('fullName', userInfo.name);
            setValue('submitter', userInfo.email);
            setValue('phoneNumber', userInfo.PhoneNumber);
        }
    }, [setValue, userInfo]);

    useEffect(() => {
        if (selectedCompany) {
            setValue('customerSelected', selectedCompany);
        }
    }, [dispatch, setValue, selectedCompany]);

    useEffect(() => {
        if (billToAddress) {
            dispatch(selectCustomerAccountId(billToAddress));
            dispatch(setLastBillToId(billToAddress));
        }
    }, [dispatch, billToAddress]);

    //set company information
    useEffect(() => {
        if (companyDropdown?.length > 0) {
            const billTos = getCompanyList(companyDropdown);
            setBillTo(formatAccountDropdown(billTos));

            if (isEditDraft && graphicData) {
                handleLoadDraftAccountInformation(graphicData.customerAccount);
            } else {
                // set company and/or bill-to if there is only one option available
                if (companyDropdown?.length === 1) setSelectedCompany(companyDropdown[0]);
                if (billTos?.length === 1) setValue('billToAddress', billTos[0].accountId);
            }
        }
    }, [companyDropdown, graphicData, isEditDraft, handleLoadDraftAccountInformation, setValue]);

    // set values if the component is in edit draft state
    useEffect(() => {
        if (isEditDraft && graphicData) {
            setValue('requestType', graphicData.requestType ?? GraphicIntakeRequestType.New);
            setValue('requestName', graphicData.requestName);
            setValue(
                'requestedByDate',
                graphicData.requestedByDate ? moment(graphicData.requestedByDate) : null
            );
            setValue('billToAddress', graphicData.billToAddress ?? '');
            setValue('shipToAddress', graphicData.shipToAddress ?? []);
            setValue('shortTerm', graphicData.shortTerm);
            setValue('fullName', graphicData.fullName);
            setValue('phoneNumber', graphicData.phoneNumber);
            setValue('submitter', graphicData.submitter);
            setValue('beverageType', graphicData.beverageType);

            if (graphicData.approvers) {
                let approverList: Approver[] = [];
                graphicData.approvers.forEach((approver) => {
                    let singleApprover = {
                        approverName: approver.approverName,
                        approverEmail: approver.approverEmail,
                        approverAddressStreet: approver.approverAddressStreet,
                        approverAddressCity: approver.approverAddressCity,
                        approverAddressState: approver.approverAddressState,
                        approverAddressPostalCode: approver.approverAddressPostalCode,
                        approverAddressCountry: approver.approverAddressCountry,
                        approverPhoneNumber: approver.approverPhoneNumber
                            ? approver.approverPhoneNumber
                            : undefined,
                        approverType: approver.approverType ?? []
                    };
                    approverList.push(singleApprover);
                });
                append(approverList);
            }
            setDraftLoaded(true);
        }
    }, [isEditDraft, graphicData, setValue, append]);

    // only set these values if the component is in edit draft state and it is an existing graphic
    useEffect(() => {
        if (isEditDraft && graphicData && reviseGraphic) {
            setValue('revisionGraphicName', graphicData.revisionGraphicName);
            setValue('revisionGraphicNumber', graphicData.revisionGraphicNumber);
            setValue(
                'conversionType',
                graphicData.conversionType ? graphicData.conversionType.toLowerCase() : null
            );
        }
    }, [isEditDraft, reviseGraphic, graphicData, setValue]);

    useEffect(() => {
        if (
            isEditDraft &&
            graphicData &&
            conversionType &&
            conversionType.toLowerCase() === 'hard'
        ) {
            setValue(
                'hardConversionDate',
                graphicData.hardConversionDate ? moment(graphicData.hardConversionDate) : null
            );
        }
    }, [isEditDraft, graphicData, conversionType, setValue]);

    // only set these values if the component is in edit draft state and it is a promotion
    useEffect(() => {
        if (isEditDraft && graphicData && isPromotion) {
            setValue(
                'shortTermStartDate',
                graphicData.shortTermStartDate ? moment(graphicData.shortTermStartDate) : null
            );
            setValue(
                'shortTermEndDate',
                graphicData.shortTermEndDate ? moment(graphicData.shortTermEndDate) : null
            );
        }
    }, [isPromotion, graphicData, setValue, isEditDraft]);

    useEffect(() => {
        if (isEditDraft && !hasBeenRemoved && draftLoaded) {
            remove(0);
            setHasBeenRemoved(true);
        }
    }, [isEditDraft, draftLoaded, hasBeenRemoved, remove]);

    return (
        <FormSection
            testId={'graphics-information-section'}
            sectionHeader={
                <Trans i18nKey="graphicsSubmissionInfo">Graphics Submission Information</Trans>
            }
        >
            <Grid item xs={12}>
                <Typography className={classes.label} variant="body1">
                    <Trans i18nKey="typeOfGraphic">
                        What type of graphic do you want to submit?
                    </Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6} data-testid="request-type">
                    <SelectInput
                        label={<Trans i18nKey="graphicType">Graphic Type</Trans>}
                        name="requestType"
                        testId="requestType"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.requestType}
                        errors={errors}
                        selectObj={graphicType}
                        defaultValue={GraphicIntakeRequestType.New}
                    />
                </Grid>
                {reviseGraphic && (
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="graphicYouAreUpdating">
                                    Graphic You Are Updating
                                </Trans>
                            }
                            name="revisionGraphicName"
                            testId="revisionGraphicName"
                            control={control}
                            rules={{ required: isRequired, pattern: /^[a-zA-Z0-9'-\s]*$/ }}
                            error={errors.revisionGraphicName}
                            errors={errors}
                            styles={classes.helperText}
                            helperText={
                                <Trans i18nKey="revisedSubmissionName">
                                    Which project would you like to revise?
                                </Trans>
                            }
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.revisionGraphicName &&
                                errors.revisionGraphicName.type === 'required' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'revisionGraphicName'}
                                        message={isRequired}
                                    />
                                )}
                            {errors.revisionGraphicName &&
                                errors.revisionGraphicName.type === 'pattern' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'revisionGraphicName'}
                                        message={
                                            <Trans i18nKey="alphanumericPattern">
                                                Please type a name using alphanumerics
                                            </Trans>
                                        }
                                    />
                                )}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="newGraphicSubmissionName">
                                New Graphic Submission Name
                            </Trans>
                        }
                        name="requestName"
                        testId="requestName"
                        control={control}
                        rules={{ required: isRequired, pattern: /^[a-zA-Z0-9'-\s]*$/ }}
                        error={errors.requestName}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="submissionNameHelper">
                                Name this submission for future reference{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.requestName && errors.requestName.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'requestName'}
                                message={isRequired}
                            />
                        )}
                        {errors.requestName && errors.requestName.type === 'pattern' && (
                            <ErrorMessage
                                errors={errors}
                                name={'requestName'}
                                message={
                                    <Trans i18nKey="alphanumericPattern">
                                        Please type a name using alphanumerics
                                    </Trans>
                                }
                            />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <FormControl
                        fullWidth
                        error={errors.requestedByDate}
                        data-testid="requestedByDate"
                    >
                        <Controller
                            as={
                                <DatePicker
                                    keepOpen={false}
                                    isOutsideRange={isOutsideRange}
                                    numberOfMonths={1}
                                    placeholder={t('requestedDate', 'Requested Date')}
                                    onDateChange={handleDateChange}
                                    name="requestedByDate"
                                    isEditDraft={isEditDraft}
                                    date={
                                        isEditDraft && graphicData?.requestedByDate
                                            ? moment(graphicData?.requestedByDate)
                                            : requestedByDate
                                    }
                                />
                            }
                            control={control}
                            name={'requestedByDate'}
                            rules={{ required: isRequired }}
                        />
                        {!errors.requestedByDate && (
                            <FormHelperText>
                                <Trans i18nKey="requestedDateHelper">
                                    <strong>In Plant Date:</strong> What is the date you need cans
                                    in filling location?
                                </Trans>
                            </FormHelperText>
                        )}
                        {errors.requestedByDate && (
                            <FormHelperText>
                                <ErrorMessage errors={errors} name={'requestedByDate'} />
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            {reviseGraphic && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <RadioInput
                            styles={classes.radioGroup}
                            label={
                                <Trans i18nKey="conversion">
                                    Is this a Hard or Soft conversion?
                                </Trans>
                            }
                            tooltip={
                                <Trans i18nKey="conversionTooltip">
                                    A HARD conversion is a revised graphic with a significant change
                                    that CANNOT be mixed with old inventories. If you select HARD
                                    conversion for your graphic, you are directing Ball to stop
                                    producing this graphic by your selected date and to invoice for
                                    any existing inventories having the prior graphic.
                                </Trans>
                            }
                            name="conversionType"
                            testId="conversionType"
                            control={control}
                            rules={{
                                required: isRequired,
                                validate: {
                                    isValidConversion: (value) => {
                                        if (
                                            value.toLowerCase() !== 'hard' &&
                                            value.toLowerCase() !== 'soft'
                                        )
                                            return isRequired;
                                        return;
                                    }
                                }
                            }}
                            radioControls={[
                                { value: 'hard', label: <Trans i18nKey="hard">Hard</Trans> },
                                { value: 'soft', label: <Trans i18nKey="soft">Soft</Trans> }
                            ]}
                            error={errors.conversionType}
                            errors={errors}
                            defaultValue={
                                graphicData?.conversionType
                                    ? graphicData.conversionType.toLowerCase()
                                    : ''
                            }
                        />
                    </Grid>
                    {conversionType && conversionType.toLowerCase() === 'hard' && (
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={errors.hardConversionDate}
                                data-testid="hardConversionDate"
                            >
                                <Controller
                                    as={
                                        <DatePicker
                                            keepOpen={false}
                                            isOutsideRange={isOutsideRange}
                                            numberOfMonths={1}
                                            placeholder={t(
                                                'hardConversionDate',
                                                'Hard Conversion Date'
                                            )}
                                            onDateChange={handleDateChange}
                                            name="hardConversionDate"
                                            isEditDraft={isEditDraft}
                                            date={
                                                isEditDraft && graphicData?.hardConversionDate
                                                    ? moment(graphicData?.hardConversionDate)
                                                    : hardConversionDate
                                            }
                                        />
                                    }
                                    control={control}
                                    name={'hardConversionDate'}
                                    rules={{ required: isRequired }}
                                />
                                {!errors.hardConversionDate && (
                                    <FormHelperText>
                                        <Trans i18nKey="hardConversionDateHelper">
                                            What is your hard Conversion date for your artwork?
                                        </Trans>
                                    </FormHelperText>
                                )}
                                {errors.hardConversionDate && (
                                    <FormHelperText>
                                        <ErrorMessage errors={errors} name={'hardConversionDate'} />
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
            )}
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={
                            <Trans i18nKey="shortTerm">
                                Is this a promotion or does this graphic require an in or out of
                                market date?
                            </Trans>
                        }
                        name="shortTerm"
                        testId="shortTerm"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={customerShortTerm}
                        error={errors.shortTerm}
                        errors={errors}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            {isPromotion && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            error={errors.shortTermStartDate}
                            data-testid="graphics-info-start-date"
                        >
                            <Controller
                                as={
                                    <DatePicker
                                        keepOpen={false}
                                        numberOfMonths={1}
                                        isOutsideRange={inRetailDateRange}
                                        placeholder={t('inRetailDate', 'In-Retail Date')}
                                        onDateChange={handleDateChange}
                                        name="shortTermStartDate"
                                        isEditDraft={isEditDraft}
                                        date={
                                            isEditDraft && graphicData?.shortTermStartDate
                                                ? moment(graphicData?.shortTermStartDate)
                                                : getValues('shortTermStartDate')
                                        }
                                    />
                                }
                                control={control}
                                name={'shortTermStartDate'}
                                rules={{ required: isRequired }}
                            />
                            {!errors.shortTermStartDate && (
                                <FormHelperText>
                                    <Trans i18nKey="shortTermStartDateHelper">
                                        Date when finished goods will be in retail
                                    </Trans>
                                </FormHelperText>
                            )}
                            {errors.shortTermStartDate &&
                                errors.shortTermStartDate.type === 'required' && (
                                    <FormHelperText className={classes.errorText}>
                                        <ErrorMessage errors={errors} name={'shortTermStartDate'} />
                                    </FormHelperText>
                                )}
                            {errors.shortTermStartDate && errors.shortTermStartDate.isManual && (
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'shortTermStartDate'}
                                        message={
                                            <Trans i18nKey="validInRetailDateError">
                                                Please make sure In-Retail date is after Requested
                                                Date
                                            </Trans>
                                        }
                                    />
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            error={errors.shortTermEndDate}
                            data-testid="graphics-info-end-date"
                        >
                            <Controller
                                as={
                                    <DatePicker
                                        keepOpen={false}
                                        numberOfMonths={1}
                                        isOutsideRange={outOfRetailDateRange}
                                        placeholder={t('outOfRetailDate', 'Out-of-Retail Date')}
                                        onDateChange={handleDateChange}
                                        name="shortTermEndDate"
                                        isEditDraft={isEditDraft}
                                        date={
                                            isEditDraft && graphicData?.shortTermEndDate
                                                ? moment(graphicData?.shortTermEndDate)
                                                : getValues('shortTermEndDate')
                                        }
                                    />
                                }
                                control={control}
                                name={'shortTermEndDate'}
                                rules={{ required: isRequired }}
                            />
                            {!errors.shortTermEndDate && (
                                <FormHelperText>
                                    <Trans i18nKey="shortTermEndDate">
                                        Date when finished goods are estimated to be out of retail
                                    </Trans>
                                </FormHelperText>
                            )}
                            {errors.shortTermEndDate &&
                                errors.shortTermEndDate.type === 'required' && (
                                    <FormHelperText className={classes.errorText}>
                                        <ErrorMessage errors={errors} name={'shortTermEndDate'} />
                                    </FormHelperText>
                                )}
                            {errors.shortTermEndDate && errors.shortTermEndDate.isManual && (
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'shortTermEndDate'}
                                        message={
                                            <Trans i18nKey="validOutOfRetailError">
                                                Please make sure Out-Of-Retail date is after
                                                In-Retail date
                                            </Trans>
                                        }
                                    />
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subsectionHeader}>
                    <Trans i18nKey="graphicsContactInformation">Graphics Contact Information</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth data-testid="customerSelected">
                        <Controller
                            as={
                                <Autocomplete
                                    id="customer-name"
                                    options={companyDropdown}
                                    getOptionLabel={(option) =>
                                        `${option.name} - ${option.accountId}`
                                    }
                                    value={selectedCompany}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('companyName', 'Company Name')}
                                            error={!!errors.customerSelected}
                                        />
                                    )}
                                    defaultValue={selectedCompany ? selectedCompany : null}
                                    getOptionSelected={(option, value) =>
                                        option.accountId === value.accountId
                                    }
                                />
                            }
                            onChange={([event, value]: any) => {
                                handleUpdateAccountInformation(event, value);
                                return value;
                            }}
                            name="customerSelected"
                            control={control}
                            rules={{ required: isRequired }}
                        />
                        {errors.customerSelected && (
                            <FormHelperText
                                className={classes.errorText}
                                data-testid="company-name-error"
                            >
                                <ErrorMessage
                                    errors={errors}
                                    name={'customerSelected'}
                                    message={isRequired}
                                />
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6} data-testid="bill-to">
                    <SelectInput
                        label={<Trans i18nKey="billToAddress">Company Bill-To</Trans>}
                        name="billToAddress"
                        testId="billToAddress"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billToAddress}
                        errors={errors}
                        selectObj={billTo}
                        defaultValue={''}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="name">Name</Trans>}
                        name="fullName"
                        testId="fullName"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.fullName}
                        errors={errors}
                        styles={classes.helperText}
                        defaultValue={userInfo?.name}
                        helperText={
                            <Trans i18nKey="nameHelper">Name of Person submitting this form</Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.fullName && errors.fullName.type === 'required' && (
                            <ErrorMessage errors={errors} name={'fullName'} message={isRequired} />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    {clearForm && (
                        <Controller
                            as={
                                <PhoneInput
                                    label={<Trans i18nKey="phoneNumber">Phone Number</Trans>}
                                    testId="phoneNumber"
                                    disableAreaCodes={true}
                                    defaultCountry={'us'}
                                    defaultValue={getValues('phoneNumber')}
                                />
                            }
                            rules={{
                                required: isRequired
                            }}
                            name="phoneNumber"
                            control={control}
                            error={errors.phoneNumber}
                            errors={errors}
                            styles={clsx(classes.helperText, {
                                [classes.errorText]: errors.phoneNumber
                            })}
                            defaultValue={userInfo?.PhoneNumber}
                        />
                    )}
                    {!clearForm && (
                        <Controller
                            as={
                                <PhoneInput
                                    label={<Trans i18nKey="phoneNumber">Phone Number</Trans>}
                                    testId="phoneNumber"
                                    disableAreaCodes={true}
                                    defaultCountry={'us'}
                                    defaultValue={getValues('phoneNumber')}
                                />
                            }
                            rules={{
                                required: isRequired
                            }}
                            name="phoneNumber"
                            control={control}
                            error={errors.phoneNumber}
                            errors={errors}
                            styles={clsx(classes.helperText, {
                                [classes.errorText]: errors.phoneNumber
                            })}
                            defaultValue={userInfo?.PhoneNumber}
                        />
                    )}
                    {!errors.phoneNumber && (
                        <FormHelperText>
                            <Trans i18nKey="phoneNumberHelper">
                                Phone Number of person submitting this form
                            </Trans>
                        </FormHelperText>
                    )}
                    <FormHelperText className={classes.errorText} data-testid="phone-error">
                        {errors.phoneNumber && errors.phoneNumber.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'phoneNumber'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="emailAddress">Email Address</Trans>}
                        name="submitter"
                        testId="submitter"
                        control={control}
                        rules={{
                            required: isRequired,
                            validate: {
                                isValidEmail: (value) => {
                                    return (
                                        isValidEmail(value) || (
                                            <Trans i18nKey="incorrectEmailAddress">
                                                Invalid Email Format
                                            </Trans>
                                        )
                                    );
                                }
                            }
                        }}
                        error={errors.submitter}
                        errors={errors}
                        defaultValue={userInfo?.email}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="submitterEmailHelper">
                                Email of person submitting the form
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.submitter && <ErrorMessage errors={errors} name={'submitter'} />}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.shipToAddress} data-testid="shipToAddress">
                        <InputLabel>
                            <Trans i18nKey="shipToLocations">Ship To Location(s)</Trans>
                        </InputLabel>
                        <Controller
                            as={
                                <Select
                                    MenuProps={{ variant: 'menu' }}
                                    inputProps={{
                                        'data-testid': 'shipToAddress'
                                    }}
                                    multiple
                                    renderValue={(selected: any) => {
                                        const selectedAccountNames = shipToLocations
                                            .filter((account) =>
                                                selected?.includes(account.accountId)
                                            )
                                            .map((i) => i.name);
                                        return (selectedAccountNames as string[])
                                            .join(', ')
                                            .substring(0, 40);
                                    }}
                                >
                                    {shipToLocations.length &&
                                        shipToLocations.map((account) => (
                                            <MenuItem
                                                key={account.accountId}
                                                value={account.accountId}
                                            >
                                                <Checkbox
                                                    checked={
                                                        shipToAddress?.indexOf(account.accountId) >
                                                        -1
                                                    }
                                                />
                                                <ListItemText primary={account.name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            }
                            name="shipToAddress"
                            control={control}
                            defaultValue={[]}
                            rules={{
                                required: isRequired,
                                validate: {
                                    hasAtLeastOne: (value) => {
                                        return value?.length || isRequired;
                                    }
                                }
                            }}
                        />
                        <FormHelperText>
                            <Trans i18nKey="shipToLocationsHelper">
                                <strong>Customer Ship To / Filler Location:</strong> If you do not
                                know the Customer Ship To / Filler Location, please save your
                                progress and you will have the option to notify the customer account
                                team requesting assistance with Ship To information.
                            </Trans>
                        </FormHelperText>
                        <FormHelperText>
                            <ErrorMessage errors={errors} name="shipToAddress" />
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.subsectionHeader}>
                    <Trans i18nKey="colorStandards">Approver Information</Trans>
                </Typography>
            </Grid>
            {fields.map((field: Approver, index) => {
                const fieldName = `approvers[${index}]`;
                const currentWatch = watch(`${fieldName}.approverType`);

                return (
                    <Grid item xs={12} key={field.id}>
                        <fieldset name={fieldName} key={field.id} className={classes.fieldSet}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        defaultValue={
                                            field.approverName !== '' ? field.approverName : ''
                                        }
                                        label={
                                            <Trans i18nKey="approverName">Approver's Name</Trans>
                                        }
                                        name={`${fieldName}.approverName`}
                                        testId={`${fieldName}.approverName`}
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverName
                                        }
                                        errors={errors}
                                        styles={classes.helperText}
                                        helperText={
                                            <Trans i18nKey="approverNameHelper">
                                                Name of person who will approve the standards
                                            </Trans>
                                        }
                                    />
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverName && (
                                            <FormHelperText
                                                className={classes.errorText}
                                                data-testid="approver-name-error"
                                            >
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverName`}
                                                    message={isRequired}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        defaultValue={
                                            field.approverEmail !== '' ? field.approverEmail : ''
                                        }
                                        label={
                                            <Trans i18nKey="approverEmail">Approver's Email</Trans>
                                        }
                                        name={`${fieldName}.approverEmail`}
                                        testId={`${fieldName}.approverEmail`}
                                        control={control}
                                        rules={{
                                            required: isRequired,
                                            validate: {
                                                isValidEmail: (value) => {
                                                    return (
                                                        isValidEmail(value) || (
                                                            <Trans i18nKey="incorrectEmailAddress">
                                                                Invalid Email Format
                                                            </Trans>
                                                        )
                                                    );
                                                }
                                            }
                                        }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverEmail
                                        }
                                        errors={errors}
                                        styles={classes.helperText}
                                        helperText={
                                            <Trans i18nKey="approverEmailHelper">
                                                Email Address of approver
                                            </Trans>
                                        }
                                    />
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverEmail && (
                                            <FormHelperText className={classes.errorText}>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverEmail`}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        defaultValue={
                                            field.approverAddressStreet !== ''
                                                ? field.approverAddressStreet
                                                : ''
                                        }
                                        label={<Trans i18nKey="street">Street</Trans>}
                                        name={`${fieldName}.approverAddressStreet`}
                                        testId={`${fieldName}.approverAddressStreet`}
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverAddressStreet
                                        }
                                        errors={errors}
                                        styles={classes.helperText}
                                        helperText={
                                            <Trans i18nKey="approverAddress">
                                                Approver's Address
                                            </Trans>
                                        }
                                    />
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverAddressStreet && (
                                            <FormHelperText
                                                className={classes.errorText}
                                                data-testid="app-address-street-error"
                                            >
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverAddressStreet`}
                                                    message={isRequired}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        defaultValue={
                                            field.approverAddressCity !== ''
                                                ? field.approverAddressCity
                                                : ''
                                        }
                                        label={<Trans i18nKey="city">City</Trans>}
                                        name={`${fieldName}.approverAddressCity`}
                                        testId={`${fieldName}.approverAddressCity`}
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverAddressCity
                                        }
                                        errors={errors}
                                        styles={classes.helperText}
                                    />
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverAddressCity && (
                                            <FormHelperText
                                                className={classes.errorText}
                                                data-testid="app-address-city-error"
                                            >
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverAddressCity`}
                                                    message={isRequired}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        defaultValue={
                                            field.approverAddressState !== ''
                                                ? field.approverAddressState
                                                : ''
                                        }
                                        label={<Trans i18nKey="stateProvince">State</Trans>}
                                        name={`${fieldName}.approverAddressState`}
                                        testId={`${fieldName}.approverAddressState`}
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverAddressState
                                        }
                                        errors={errors}
                                        styles={classes.helperText}
                                    />
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverAddressState && (
                                            <FormHelperText
                                                className={classes.errorText}
                                                data-testid="app-address-state-error"
                                            >
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverAddressState`}
                                                    message={isRequired}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        defaultValue={
                                            field.approverAddressPostalCode !== ''
                                                ? field.approverAddressPostalCode
                                                : ''
                                        }
                                        label={<Trans i18nKey="postalCode">Postal Code</Trans>}
                                        name={`${fieldName}.approverAddressPostalCode`}
                                        testId={`${fieldName}.approverAddressPostalCode`}
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverAddressPostalCode
                                        }
                                        errors={errors}
                                        styles={classes.helperText}
                                    />
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverAddressPostalCode && (
                                            <FormHelperText
                                                className={classes.errorText}
                                                data-testid="app-address-postal-code-error"
                                            >
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverAddressPostalCode`}
                                                    message={isRequired}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid
                                    item
                                    xs={6}
                                    data-testid={`${fieldName}.approverAddressCountry`}
                                >
                                    <CountrySelect
                                        defaultValue={
                                            field.approverAddressCountry !== ''
                                                ? field.approverAddressCountry
                                                : ''
                                        }
                                        name={`${fieldName}.approverAddressCountry`}
                                        regionCode={
                                            userInfo?.Custom_Region
                                                ? userInfo.Custom_Region.toUpperCase()
                                                : Region.NA
                                        }
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverAddressCountry
                                        }
                                        errors={errors}
                                        handleLoadDraftApproverCountry={
                                            handleLoadDraftApproverCountry
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {clearForm && (
                                        <Controller
                                            as={
                                                <PhoneInput
                                                    label={
                                                        <Trans i18nKey="phoneNumber">
                                                            Phone Number
                                                        </Trans>
                                                    }
                                                    testId={`${fieldName}.approverPhoneNumber`}
                                                    disableAreaCodes={true}
                                                    defaultCountry={'us'}
                                                    name={`${fieldName}.approverPhoneNumber`}
                                                    defaultValue={
                                                        field.approverPhoneNumber !== undefined
                                                            ? field.approverPhoneNumber
                                                            : ''
                                                    }
                                                />
                                            }
                                            rules={{
                                                required: isRequired
                                            }}
                                            name={`${fieldName}.approverPhoneNumber`}
                                            control={control}
                                            error={
                                                errors.approvers &&
                                                errors.approvers[index] &&
                                                errors.approvers[index].approverPhoneNumber
                                            }
                                            errors={errors}
                                            styles={clsx(classes.helperText, {
                                                [classes.errorText]:
                                                    errors.approvers &&
                                                    errors.approvers[index] &&
                                                    errors.approvers[index].approverPhoneNumber
                                            })}
                                            defaultValue={
                                                field.approverPhoneNumber !== undefined
                                                    ? field.approverPhoneNumber
                                                    : ''
                                            }
                                        />
                                    )}
                                    {!clearForm && (
                                        <Controller
                                            as={
                                                <PhoneInput
                                                    label={
                                                        <Trans i18nKey="phoneNumber">
                                                            Phone Number
                                                        </Trans>
                                                    }
                                                    testId={`${fieldName}.approverPhoneNumber`}
                                                    disableAreaCodes={true}
                                                    defaultCountry={'us'}
                                                    name={`${fieldName}.approverPhoneNumber`}
                                                    defaultValue={
                                                        field.approverPhoneNumber !== undefined
                                                            ? field.approverPhoneNumber
                                                            : ''
                                                    }
                                                />
                                            }
                                            rules={{
                                                required: isRequired
                                            }}
                                            name={`${fieldName}.approverPhoneNumber`}
                                            control={control}
                                            error={
                                                errors.approvers &&
                                                errors.approvers[index] &&
                                                errors.approvers[index].approverPhoneNumber
                                            }
                                            errors={errors}
                                            styles={clsx(classes.helperText, {
                                                [classes.errorText]:
                                                    errors.approvers &&
                                                    errors.approvers[index] &&
                                                    errors.approvers[index].approverPhoneNumber
                                            })}
                                            defaultValue={
                                                field.approverPhoneNumber !== undefined
                                                    ? field.approverPhoneNumber
                                                    : ''
                                            }
                                        />
                                    )}
                                    {errors.approvers &&
                                        errors.approvers[index] &&
                                        errors.approvers[index].approverPhoneNumber && (
                                            <FormHelperText
                                                className={classes.errorText}
                                                data-testid="app-phone-error"
                                            >
                                                <ErrorMessage
                                                    errors={errors}
                                                    name={`${fieldName}.approverPhoneNumber`}
                                                    message={isRequired}
                                                />
                                            </FormHelperText>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6} alignContent="flex-end">
                                    <FormControl
                                        fullWidth
                                        error={
                                            errors.approvers &&
                                            errors.approvers[index] &&
                                            errors.approvers[index].approverType
                                        }
                                    >
                                        <InputLabel>
                                            <Trans i18nKey="approverType">Approver Type</Trans>
                                        </InputLabel>
                                        <Controller
                                            as={
                                                <Select
                                                    MenuProps={{ variant: 'menu' }}
                                                    inputProps={{
                                                        'data-testid': `${fieldName}.approverType`
                                                    }}
                                                    multiple
                                                    renderValue={(selected: any) => {
                                                        const selectedTypes = approverTypes
                                                            .filter((type) =>
                                                                selected?.includes(type.value)
                                                            )
                                                            .map((type) => type.name);
                                                        return selectedTypes
                                                            .join(', ')
                                                            .substring(0, 40);
                                                    }}
                                                >
                                                    {approverTypes
                                                        .filter((type) => {
                                                            if (
                                                                colorApproverCount < 3 ||
                                                                currentWatch?.includes(type.value)
                                                            )
                                                                return type;
                                                            return type.name !== 'Pilot Can/Color';
                                                        })
                                                        .map((approverType) => (
                                                            <MenuItem
                                                                key={approverType.value}
                                                                value={approverType.value}
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        currentWatch?.indexOf(
                                                                            approverType.value
                                                                        ) > -1
                                                                    }
                                                                />
                                                                <ListItemText
                                                                    primary={approverType.name}
                                                                />
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            }
                                            name={`${fieldName}.approverType`}
                                            control={control}
                                            defaultValue={
                                                field.approverType?.length ? field.approverType : []
                                            }
                                            rules={{
                                                required: isRequired,
                                                validate: {
                                                    hasAtLeastOne: (value) => {
                                                        return value?.length || isRequired;
                                                    }
                                                }
                                            }}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage
                                                errors={errors}
                                                name={`${fieldName}.approverType`}
                                            />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </fieldset>
                        {fields.length > 1 && (
                            <>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    spacing={2}
                                    alignItems="center"
                                    className={classes.approverWrapper}
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                        alt="Trash Icon"
                                    />
                                    <Button
                                        variant="text"
                                        color="primary"
                                        data-testid="remove-approver-button"
                                        className={classes.removeApprover}
                                        onClick={() => handleRemoveApprover(index)}
                                    >
                                        <Trans i18nKey="removeApprover">Remove Approver</Trans>
                                    </Button>
                                </Grid>
                                <Divider className={classes.thinDivider} />
                            </>
                        )}
                    </Grid>
                );
            })}
            {fields.length < MAX_APPROVERS && (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    className={classes.approverWrapper}
                >
                    <img src={process.env.PUBLIC_URL + '/assets/Circle_Add.svg'} alt="Add Icon" />
                    <Button
                        variant="text"
                        color="primary"
                        data-testid="add-approver-button"
                        className={classes.addApprover}
                        onClick={() => handleAddApprover()}
                    >
                        <Trans i18nKey="addAnotherApprover">Add Another Approver</Trans>
                    </Button>
                </Grid>
            )}
        </FormSection>
    );
};

export default GraphicsInformation;
