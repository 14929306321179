import * as types from './action-types';
import OrdersService from '../../utility/services/orders-service';
import {
    ProductToShip,
    DeliveryShipment,
    DeliveryOrder,
    ExpandableLoad,
    OrderInformation
} from '../reducers/ordering-dashboard';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType,
    productTypeHelper
} from '../../utility/helpers/order-helpers';
import moment, { Moment } from 'moment';

export const setOrderInformation = (orderInformation) => ({
    type: types.ORDERING_DASHBOARD_ADD_ORDER_INFORMATION,
    orderInformation: orderInformation
});

export const orderingDashboardUpdatePallets = (productsWithPallets, scrapIt: boolean = false) => ({
    type: scrapIt ? types.SCRAP_IT_UPDATE_PALLETS : types.ORDERING_DASHBOARD_UPDATE_PALLETS,
    productsWithPallets: productsWithPallets
});

export const loadOrderingProductsToBeShipped = () => {
    return (dispatch, getState) => {
        dispatch({ type: types.ORDERING_ORDER_LOADING });
        const productsToShip = getState().orderingDashboard.productsToBeShipped;

        dispatch({
            type: types.ORDERING_ORDER_LOADED,
            productsToBeShipped: productsToShip
        });
    };
};

export const updateOrderingProductToBeShipped = (productsToShip: ProductToShip[]) => {
    return (dispatch) => {
        dispatch({
            type: types.ORDERING_ORDER_PRODUCTS_UPDATE,
            productsToBeShipped: productsToShip
        });
    };
};

export const updateShipments = (shipments: DeliveryShipment[]) => {
    return (dispatch) => {
        let orderUniqueProducts = new Set<string>();
        let totalOrderQuantity = 0;

        shipments.forEach((shipment) => {
            let uniqueProducts = new Set<string>();
            let totalShipmentQuantity = 0;

            shipment.loads &&
                shipment.loads.forEach((load) => {
                    load.productSku && uniqueProducts.add(load.productSku);
                    load.productSku && orderUniqueProducts.add(load.productSku);
                    totalShipmentQuantity += load.palletQuantity;
                });

            shipment.palletCount = totalShipmentQuantity;
            shipment.productCount = uniqueProducts.size;

            totalOrderQuantity += totalShipmentQuantity;
        });

        const orderInformation: Partial<OrderInformation> = {
            palletCount: totalOrderQuantity,
            shipmentCount: shipments.length,
            productCount: orderUniqueProducts.size
        };

        dispatch({
            type: types.ORDERING_DASHBOARD_ADD_ORDER_INFORMATION,
            orderInformation: orderInformation
        });
        dispatch({
            type: types.ORDERING_ORDER_SHIPMENTS_UPDATE,
            shipmentsOrder: shipments
        });
    };
};

export const updateOrderDate = (deliveryDate: Moment) => {
    return (dispatch, getState) => {
        const shipments = getState().orderingDashboard.shipmentsOrder;
        const newShipments = shipments.map((shipment) => {
            return {
                ...shipment,
                deliveryDateTime: moment(
                    moment(deliveryDate).format('YYYY-MM-DD') +
                        ' ' +
                        moment(shipment.deliveryDateTime).format('HH:mm')
                ).format()
            };
        });

        dispatch({
            type: types.ORDERING_ORDER_SHIPMENTS_UPDATE,
            shipmentsOrder: newShipments
        });
    };
};

export const updateDeliveryOrder = (order: DeliveryOrder) => {
    return (dispatch) => {
        dispatch({
            type: types.ORDERING_UPDATE_DELIVERY_ORDER,
            deliveryOrder: order
        });
    };
};

export const updateDeliveryOrderProductsUpdateError = (error: any) => {
    return (dispatch) => {
        dispatch({
            type: types.DELIVERY_ORDER_UPDATE_ERROR,
            error: 'Error loading products for delivery order with: ' + error
        });
    };
};

export const updateDeliveryOrderUpdateError = (error: any) => {
    return (dispatch) => {
        dispatch({
            type: types.DELIVERY_ORDER_UPDATE_ERROR,
            error: 'Error updating delivery order: ' + error
        });
    };
};

export const toggleOrderingAdd = (product: ProductToShip, remove: boolean) => {
    return (dispatch, getState) => {
        let productsToShip: ProductToShip[] = [...getState().orderingDashboard.productsToBeShipped];

        productsToShip.map((item) => {
            if (item.productSku === product.productSku) {
                item.addDisabled = remove;
            }
            return null;
        });

        dispatch(updateOrderingProductToBeShipped(productsToShip));
    };
};

export const disableAllProducts = () => {
    return (dispatch, getState) => {
        let productsToShip: ProductToShip[] = [...getState().orderingDashboard.productsToBeShipped];

        productsToShip.map((item) => {
            item.addDisabled = true;
            return null;
        });

        dispatch(updateOrderingProductToBeShipped(productsToShip));
    };
};

export const resetOrderingProcess = () => {
    return (dispatch) => {
        dispatch({
            type: types.ORDERING_DASHBOARD_RESET_STATE
        });
    };
};

export const updateConfigFlag = (value: boolean) => {
    return (dispatch) => {
        dispatch({
            type: types.ORDERING_DASHBOARD_CONFIG_FLAG,
            fromConfig: value
        });
    };
};

export const updateExpandableOrderingLoad = (expandableLoad: ExpandableLoad) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().orderingDashboard.expandableLoads;
        const updatedExpandableLoads = expandableLoads.filter(
            (load) =>
                load.shipmentId !== expandableLoad.shipmentId ||
                load.displayId !== expandableLoad.displayId
        );
        dispatch({
            type: types.ORDERING_DASHBOARD_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads, expandableLoad]
        });
    };
};

export const changeAllExpandableOrderingLoads = (shipmentId: string, expanded: boolean) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().orderingDashboard.expandableLoads;
        const updatedExpandableLoads = expandableLoads
            .filter((load) => load.shipmentId === shipmentId)
            .map((load) => {
                return { ...load, expanded: expanded };
            });
        dispatch({
            type: types.ORDERING_DASHBOARD_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads]
        });
    };
};

export const loadProducts = (bypassShipToId?: string) => {
    return (dispatch, getState) => {
        dispatch({ type: types.ORDERING_DASHBOARD_LOAD_PRODUCTS });

        const accessToken = getState().auth.accessToken;
        const shipToId = bypassShipToId || getState().orderingDashboard?.orderInformation?.shipToId;
        if (!shipToId) {
            dispatch({
                type: types.ORDERING_DASHBOARD_LOAD_PRODUCTS_ERROR,
                error: 'ShipToId is not defined'
            });
        } else {
            const requestObj = {
                ShipToIds: [shipToId],
                OnlyActive: true
            };

            OrdersService.getProductsNew(accessToken, requestObj)
                .then((response) => {
                    const products = response.data.products;
                    products.map((product) => {
                        const canBottleDesc = constructCanDescription(product);
                        const endDesc = constructEndDescription(product);
                        const isEndType = isProductEndType(product.type);
                        product.description = isEndType ? endDesc : canBottleDesc;
                        product.availableItemsPerPallet = product?.quantityPerPallet!;
                        product.graphicIdAndVersion =
                            product.graphicId && product.graphicVersion
                                ? product.graphicId + '-' + product.graphicVersion
                                : '';

                        let matchingDestinationStatus: string | undefined = undefined;
                        if (product.destinations) {
                            product.destinations?.forEach((destination) => {
                                if (
                                    destination.shipToId.toString() === shipToId &&
                                    destination.status === 'ACTIVE'
                                ) {
                                    product.customerProductId = destination.customerProductId;
                                    product.customerProductName = destination.customerProductName;
                                    if (matchingDestinationStatus === undefined) {
                                        matchingDestinationStatus = product.status;
                                    }
                                }
                            });
                        }
                        product.status = matchingDestinationStatus ?? 'INACTIVE';
                        product.displayId = product.productSku;
                        product.displayName = product.customerProductName
                            ? product.customerProductName
                            : product.name;
                        return null;
                    });
                    dispatch({
                        type: types.ORDERING_DASHBOARD_PRODUCTS_LOADED,
                        products: products as ProductToShip[]
                    });
                })
                .catch((error) => {
                    dispatch({ type: types.ORDERING_DASHBOARD_LOAD_PRODUCTS_ERROR, error: error });
                });
        }
    };
};

export const loadOrder = (orderId: string) => {
    return (dispatch, getState) => {
        dispatch({ type: types.ORDERING_ORDER_LOADING });

        const state = getState();

        const accessToken = state.auth.accessToken;

        OrdersService.getDeliveryOrder(accessToken, orderId)
            .then(async (response) => {
                const order: DeliveryOrder = response.data;

                let deliveryDate = moment().toString();
                if (order.shipments) {
                    deliveryDate = order.shipments[0].deliveryDateTime || deliveryDate;
                }

                const shipToId = order.shipToId?.toString();

                const orderInformation: OrderInformation = {
                    shipToId: shipToId || '',
                    poNumber: order.poNumber || '',
                    deliveryInstructions: order.deliveryInstructions || '',
                    palletCount: order.totalOrderQuantity || 0,
                    shipmentCount: order.totalShipments || 0,
                    productCount: order.totalOrderSkus || 0,
                    orderNumber: order.deliveryOrderNumber || '0',
                    deliveryDate: deliveryDate
                };

                const requestObj = {
                    ShipToIds: [shipToId],
                    OnlyActive: true
                };

                dispatch({ type: types.ORDERING_DASHBOARD_LOAD_PRODUCTS });

                let products: any[] = [];

                await OrdersService.getProductsNew(accessToken, requestObj)
                    .then((response) => {
                        products = response.data.products;
                        products.map((product) => {
                            const canBottleDesc = constructCanDescription(product);
                            const endDesc = constructEndDescription(product);
                            const isEndType = isProductEndType(product.type);
                            product.description = isEndType ? endDesc : canBottleDesc;
                            product.availableItemsPerPallet = product?.quantityPerPallet!;
                            product.graphicIdAndVersion =
                                product.graphicId && product.graphicVersion
                                    ? product.graphicId + '-' + product.graphicVersion
                                    : '';

                            let matchingDestinationStatus: string | undefined = undefined;
                            if (product.destinations) {
                                product.destinations?.forEach((destination) => {
                                    if (
                                        destination.shipToId.toString() === shipToId &&
                                        destination.status === 'ACTIVE'
                                    ) {
                                        product.customerProductId = destination.customerProductId;
                                        product.customerProductName =
                                            destination.customerProductName;
                                        if (matchingDestinationStatus === undefined) {
                                            matchingDestinationStatus = product.status;
                                        }
                                    }
                                });
                            }
                            product.status = matchingDestinationStatus ?? 'INACTIVE';
                            product.displayId = product.productSku;
                            product.displayName = product.customerProductName
                                ? product.customerProductName
                                : product.name;
                            return null;
                        });

                        dispatch({
                            type: types.ORDERING_DASHBOARD_PRODUCTS_LOADED,
                            products: products as ProductToShip[]
                        });
                    })
                    .catch((error) => {
                        dispatch({
                            type: types.ORDERING_DASHBOARD_LOAD_PRODUCTS_ERROR,
                            error: error
                        });
                    });

                const shipments = order.shipments
                    ? order.shipments.map((shipment) => {
                          let uniqueProducts = new Set<string>();
                          let totalShipmentQuantity = 0;

                          let loadsArr = shipment.loads || [];
                          let shipmentType: any;

                          loadsArr = loadsArr.map((load) => {
                              load.productSku && uniqueProducts.add(load.productSku);
                              totalShipmentQuantity += load.palletQuantity;

                              const product = products.find(
                                  (product) => product.productSku === load.productSku
                              );

                              shipmentType = productTypeHelper(product?.type);

                              return { ...product, ...load };
                          });

                          return {
                              ...shipment,
                              loads: loadsArr,
                              shipmentType,
                              palletCount: totalShipmentQuantity,
                              productCount: uniqueProducts.size
                          };
                      })
                    : [];

                dispatch({
                    type: types.ORDERING_DASHBOARD_ADD_ORDER_INFORMATION,
                    orderInformation: orderInformation
                });

                dispatch({
                    type: types.ORDERING_ORDER_SHIPMENTS_UPDATE,
                    shipmentsOrder: shipments
                });

                dispatch({
                    type: types.ORDERING_ORDER_UPDATE,
                    deliveryOrder: order
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.ORDERING_ORDER_LOADING_ERROR,
                    error: 'Error loading order with: ' + error
                });
            })
            .finally(() => {
                dispatch({ type: types.ORDERING_ORDER_LOADED });
            });
    };
};
