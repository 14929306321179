import { combineReducers } from 'redux';
import auth from './reducers/auth';
import regionCulture from './reducers/region-culture';
import productPortfolio from './reducers/product-portfolio';
import productDetails from './reducers/product-details';
import endDetails from './reducers/product-end-details';
import productEstimate from './reducers/product-estimate';
import contentPage from './reducers/content-page';
import headerContent from './reducers/header-content';
import customerContext from './reducers/customer-context';
import glp from './reducers/glp';
import graphicIntake from './reducers/graphic-intake';
import graphicDashboard from './reducers/graphic-dashboard';
import makeItDashboard from './reducers/makeit-dashboard';
import shippingDashboard from './reducers/shipping-dashboard';
import makeItDrafts from './reducers/make-it-drafts';
import makeItSummary from './reducers/make-it-summary';
import pendingPaymentsDashboard from './reducers/pending-payments';
import shippingSummary from './reducers/shipping-summary';
import deliveryOrderDetails from './reducers/delivery-order-details';
import productionOrderDetails from './reducers/production-order-details';
import atmProductionOrderDetails from './reducers/atm-production-order-details';
import productPlanning from './reducers/product-planning';
import bulkUploadDelivery from './reducers/bulk-upload';
import editShipments from './reducers/edit-shipments';
import editProductionOrder from './reducers/edit-production-order';
import securityLevel from './reducers/security-level';
import bulkUploadMakeItState from './reducers/makeit-bulk-upload';
import bulkUploadMakeItATMState from './reducers/makeit-bulk-atm';
import bulkUploadPlanIt from './reducers/planit-bulk-atm';
import requestAccess from './reducers/request-access';
import userAdmin, { userAdminDashboard, myAccountDashboard } from './reducers/user-admin';
import productsConfiguration from './reducers/product-configurations';
import liquidIntake from './reducers/liquid-intake';
import liquidTestSummary from './reducers/liquid-test-summary';
import onboardingDashboardState from './reducers/onboarding-dashboard';
import makeItATMSummary from './reducers/make-it-atm-summary';
import planItGuided from './reducers/plan-it-guided';
import planItSummary from './reducers/plan-it-summary';
import capacityCheck from './reducers/capacity-checks';
import productPlanningATM from './reducers/product-planning-atm';
import customerDashboard from './reducers/customer-dashboard';
import userConfiguration from './reducers/user-configuration';
import pricingBreakdown from './reducers/pricing-breakdown';
import notifications from './reducers/notifications';
import makeItConversionATMState from './reducers/makeit-conversion-atm';
import manageProductsState from './reducers/manage-products';
import tradeItRequest from './reducers/trade-it';
import tradeItSummary from './reducers/trade-it-summary';
import productInformation from './reducers/product-information';
import tradeItDetails from './reducers/trade-it-details';
import scrapItState from './reducers/scrap-it';
import scrapItRequestDetails from './reducers/scrap-it-details';
import shipToLocations from './reducers/ship-to-locations';
import contractSummary from './reducers/contract-summary';
import communicationManagement from './reducers/communication-management';
import communicationMessages from './reducers/communication-messages';
import orderingDashboard from './reducers/ordering-dashboard';
import customerOrderingDashboard from './reducers/customer-ordering-dashboard';
import orderingSummaries  from './reducers/order-summaries';

const rootReducer = combineReducers({
    atmProductionOrderDetails,
    auth,
    bulkUploadDelivery,
    bulkUploadMakeItATMState,
    bulkUploadMakeItState,
    bulkUploadPlanIt,
    capacityCheck,
    communicationManagement,
    communicationMessages,
    contentPage,
    contractSummary,
    customerContext,
    customerDashboard,
    deliveryOrderDetails,
    endDetails,
    editProductionOrder,
    editShipments,
    glp,
    graphicDashboard,
    graphicIntake,
    headerContent,
    liquidIntake,
    liquidTestSummary,
    manageProductsState,
    makeItDashboard,
    makeItDrafts,
    makeItATMSummary,
    makeItConversionATMState,
    makeItSummary,
    planItGuided,
    planItSummary,
    myAccountDashboard,
    notifications,
    onboardingDashboardState,
    pendingPaymentsDashboard,
    pricingBreakdown,
    productDetails,
    productEstimate,
    productInformation,
    productionOrderDetails,
    productPlanning,
    productPlanningATM,
    productPortfolio,
    productsConfiguration,
    regionCulture,
    requestAccess,
    securityLevel,
    shippingDashboard,
    scrapItState,
    scrapItRequestDetails,
    shippingSummary,
    shipToLocations,
    tradeItDetails,
    tradeItRequest,
    tradeItSummary,
    userAdmin,
    userAdminDashboard,
    userConfiguration,
    orderingDashboard,
    customerOrderingDashboard,
    orderingSummaries
});

export default rootReducer;
