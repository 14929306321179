import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import ShipmentRow from './ShipmentRow';
import { ProductToShip } from '../../../../store/reducers/ordering-dashboard';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '24px',
        gap: '16px',
        overflow: 'hidden'
    },
    productContainer: {
        flex: 1,
        overflowY: 'auto',
        border: '1px solid #eee',
        borderRadius: '4px',
        padding: '16px',
        marginRight: '-24px',
        marginLeft: '-24px',
        textAlign: 'left',
        height: '100%'
    },
    columnNames: {
        color: 'gray',
        fontWeight: 'bold',
        fontSize: '9px',
        textAlign: 'left'
    },
    labelColumn: {
        alignItems: 'flex-end',
        textTransform: 'uppercase'
    },
    palletLabel: {
        textAlign: 'left',
        marginBottom: '45px',
        color: 'gray',
        fontWeight: 'bold',
        fontSize: '9px'
    }
}));

interface ShipmentGridProps {
    loads?: ProductToShip[];
    isEditShipment: boolean;
    onUpdateLoads: (loads: ProductToShip[]) => void;
    onRemoveLoads: (loads: ProductToShip[]) => void;
}

const ShipmentGrid: React.FC<ShipmentGridProps> = ({
    loads,
    isEditShipment,
    onUpdateLoads,
    onRemoveLoads
}) => {
    const classes = useStyles();

    const handleUpdateItem = (item: ProductToShip) => {
        const updatedLoads = loads?.map((load: ProductToShip) => {
            if (item.loadKey === load.loadKey) {
                return item;
            }
            return load;
        });

        onUpdateLoads(updatedLoads || []);
    };

    const handleRemoveItem = (item: ProductToShip) => {
        const updatedLoads = loads?.filter((load: ProductToShip) => item.loadKey !== load.loadKey);

        onRemoveLoads(updatedLoads || []);
    };

    return (
        <div className={classes.container}>
            <div className={classes.productContainer}>
                <Grid container spacing={1}>
                    <Grid item xs={1} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="loadSeq">
                                LOAD <br /> SEQ.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="customProductGraphicId">
                                CUSTOM ID / PRODUCT ID / GRAPHIC ID
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="productNameDescription">
                                PRODUCT NAME / DESCRIPTION
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="palletsToShip">
                                PALLETS <br /> TO SHIP
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="purchaseOrderNumber">PURCHASE ORDER NUMBER</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="releaseNumber">
                                RELEASE <br /> NUMBER
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="fillerLineShipment">
                                FILLER <br /> LINE
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="referenceNumber">
                                REFERENCE <br /> NUMBER
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="actionsUpperCase">ACTIONS</Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {loads?.map((load: ProductToShip) => (
                        <Grid item xs={12} key={load.loadKey}>
                            <ShipmentRow
                                item={load}
                                sequence={load.sequence}
                                isEditShipment={isEditShipment}
                                onUpdateItem={handleUpdateItem}
                                onRemoveItem={handleRemoveItem}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ShipmentGrid;
