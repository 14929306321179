export const UPDATE_CULTURE = 'UPDATE_CULTURE';
export const UPDATE_REGION = 'UPDATE_REGION';
export const UPDATE_REGION_CULTURE = 'UPDATE_REGION_CULTURE';
export const UPDATE_DATEFORMAT = 'UPDATE_DATEFORMAT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const SET_AUTH = 'SET_AUTH';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SECURITY_LEVEL_SET = 'SECURITY_LEVEL_SET';
export const SECURITY_LEVEL_CLEARED = 'SECURITY_LEVEL_CLEARED';
export const SECURITY_LEVEL_SET_PERSONAS = 'SECURITY_LEVEL_SET_PERSONAS';

export const HEADER_LABELS_LOAD = 'HEADER_LABELS_LOAD';

export const PRODUCT_UPDATE_VIEW = 'PRODUCT_UPDATE_VIEW';
export const PRODUCT_UPDATE_RESULTS = 'PRODUCT_UPDATE_RESULTS';
export const PRODUCT_UPDATE_SELECTED_PRODUCT = 'PRODUCT_UPDATE_SELECTED_PRODUCT';
export const PRODUCT_CLEAR_SELECTED_PRODUCT = 'PRODUCT_CLEAR_SELECTED_PRODUCT';
export const PRODUCT_SELECTED = 'PRODUCT_SELECTED';

export const PRODUCT_PORTFOLIO_CONTENT_LOADED = 'PRODUCT_PORTFOLIO_CONTENT_LOADED';
export const PRODUCT_PORTFOLIO_CONTENT_ERROR = 'PRODUCT_PORTFOLIO_CONTENT_ERROR';

export const PRODUCT_LOADED = 'PRODUCT_LOADED';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_LOADING_ERROR = 'PRODUCT_LOADING_ERROR';
export const PRODUCT_LOADED_BANNER = 'PRODUCT_LOADED_BANNER';

export const PRODUCT_UPDATE_SLIDER = 'PRODUCT_UPDATE_SLIDER';
export const PRODUCT_SET_CULTURE = 'PRODUCT_SET_CULTURE';
export const PRODUCT_LOADING_FACETS = 'PRODUCT_LOADING_FACETS';
export const PRODUCT_LOAD_FACETS = 'PRODUCT_LOAD_FACETS';
export const PRODUCT_CLEAR_FACETS = 'PRODUCT_CLEAR_FACETS';
export const PRODUCT_UPDATE_FACET = 'PRODUCT_UPDATE_FILTER';
export const PRODUCT_LOAD_FACETS_ERROR = 'PRODUCT_LOAD_FACETS_ERROR';

export const PRODUCT_DETAILS_LOADED = 'PRODUCT_DETAILS_LOADED';
export const PRODUCT_DETAILS_LOADING = 'PRODUCT_DETAILS_LOADING';
export const PRODUCT_DETAILS_ERROR = 'PRODUCT_DETAILS_ERROR';

export const PRODUCT_END_DETAILS_LOADING = 'PRODUCT_END_DETAILS_LOADING';
export const PRODUCT_END_DETAILS_LOADED = 'PRODUCT_END_DETAILS_LOADED';
export const PRODUCT_END_DETAILS_ERROR = 'PRODUCT_END_DETAILS_ERROR';
export const PRODUCT_END_LOADED_BANNER = 'PRODUCT_END_LOADED_BANNER';
export const PRODUCT_END_LOADED_CONTENT = 'PRODUCT_END_LOADED_CONTENT';

export const PRODUCT_ESTIMATE_LOADING = 'PRODUCT_ESTIMATE_LOADING';
export const PRODUCT_ESTIMATE_LOADED = 'PRODUCT_ESTIMATE_LOADED';
export const PRODUCT_ESTIMATE_ERROR = 'PRODUCT_ESTIMATE_ERROR';
export const PRODUCT_ESTIMATE_DETAILS_LOADED = 'PRODUCT_ESTIMATE_DETAILS_LOADED';
export const PRODUCT_ESTIMATE_BANNER_LOADED = 'PRODUCT_ESTIMATE_BANNER_LOADED';

export const GRAPHIC_INTAKE_LOADING = 'GRAPHIC_INTAKE_LOADING';
export const GRAPHIC_INFORMATION_STATUS_UPDATE = 'GRAPHIC_INFORMATION_STATUS_UPDATE';
export const PRODUCT_OPTION_STATUS_UPDATE = 'PRODUCT_OPTION_STATUS_UPDATE';
export const COLOR_MATCHING_STATUS_UPDATE = 'COLOR_MATCHING_STATUS_UPDATE';
export const GRAPHICS_FILE_STATUS_UPDATE = 'GRAPHICS_FILE_STATUS_UPDATE';
export const GRAPHICS_RESET_FILE = 'GRAPHICS_RESET_FILE';
export const SAVE_GRAPHICS_FORM_DATA = 'SAVE_GRAPHICS_FORM_DATA';
export const GRAPHICS_ID_UPDATE = 'GRAPHICS_ID_UPDATE';
export const GRAPHICS_DATA_LOADED = 'GRAPHICS_DATA_LOADED';
export const GRAPHICS_DATA_LOADING = 'GRAPHICS_DATA_LOADING';
export const GRAPHICS_DATA_LOADING_ERROR = 'GRAPHICS_DATA_LOADING_ERROR';
export const GRAPHICS_DATA_RESET_STATE = 'GRAPHICS_DATA_RESET_STATE';
export const GRAPHICS_UPDATE_EXISTING_FILES = 'GRAPHICS_UPDATE_EXISTING_FILES';

export const LIQUID_INTAKE_DATA_LOADED = 'LIQUID_INTAKE_DATA_LOADED';
export const LIQUID_INTAKE_DATA_LOADING = 'LIQUID_INTAKE_DATA_LOADING';
export const LIQUID_INTAKE_DATA_LOADING_ERROR = 'LIQUID_INTAKE_DATA_LOADING_ERROR';
export const LIQUID_INTAKE_DATA_RESET_STATE = 'LIQUID_INTAKE_DATA_RESET_STATE';
export const LIQUID_INTAKE_SUBMITTING = 'LIQUID_INTAKE_SUBMITTING';
export const LIQUID_INTAKE_SUBMITTED = 'LIQUID_INTAKE_SUBMITTED';
export const LIQUID_INTAKE_SUBMIT_ERROR = 'LIQUID_INTAKE_SUBMIT_ERROR';

export const LIQUID_INTAKE_SUMMARY_LOADING = 'LIQUID_INTAKE_SUMMARY_LOADING';
export const LIQUID_INTAKE_SUMMARY_LOAD_OPEN = 'LIQUID_INTAKE_SUMMARY_LOAD_OPEN';
export const LIQUID_INTAKE_SUMMARY_LOAD_COMPLETED = 'LIQUID_INTAKE_SUMMARY_LOAD_COMPLETED';
export const LIQUID_INTAKE_SUMMARY_LOAD_ERROR = 'LIQUID_INTAKE_SUMMARY_LOAD_ERROR';

export const GRAPHICS_DASHBOARD_LOAD_DRAFT = 'GRAPHICS_DASHBOARD_LOAD_DRAFT';
export const GRAPHICS_DASHBOARD_LOAD_COMPLETED = 'GRAPHICS_DASHBOARD_LOAD_COMPLETED';
export const GRAPHICS_DASHBOARD_LOAD_INPROGRESS = 'GRAPHICS_DASHBOARD_LOAD_INPROGRESS';
export const GRAPHICS_DASHBOARD_LOADING = 'GRAPHICS_DASHBOARD_LOADING';
export const GRAPHICS_DASHBOARD_LOADED = 'GRAPHICS_DASHBOARD_LOADED';
export const GRAPHICS_DASHBOARD_ERROR = 'GRAPHICS_DASHBOARD_ERROR';

export const CONTENT_LOADING = 'CONTENT_LOADING';
export const CONTENT_LOADED = 'CONTENT_LOADED';
export const CONTENT_LOADING_ERROR = 'CONTENT_LOADING_ERROR';

export const GLP_LOADING = 'GLP_LOADING';
export const GLP_LOADING_ERROR = 'GLP_LOADING_ERROR';
export const GLP_LOADED = 'GLP_LOADED';
export const GLP_LOADED_MISSION = 'GLP_LOADED_MISSION';
export const GLP_LOADED_PARTNERING = 'GLP_LOADED_PARTNERING';
export const GLP_LOADED_FIND_PRODUCTS = 'GLP_LOADED_FIND_PRODUCTS';
export const GLP_LOADED_BANNER = 'GLP_LOADED_BANNER';
export const GLP_LOADED_CAN_CONTENT = 'GLP_LOADED_CAN_CONTENT';

export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CUSTOMER_LOADED = 'CUSTOMER_LOADED';
export const CUSTOMER_LOADING_ERROR = 'CUSTOMER_LOADING_ERROR';
export const CUSTOMER_LAST_SHIP_TO = 'CUSTOMER_LAST_SHIP_TO';
export const CUSTOMER_LAST_BILL_TO = 'CUSTOMER_LAST_BILL_TO';
export const CUSTOMER_SELECT_ACCOUNT = 'CUSTOMER_SELECT_ACCOUNT';
export const CUSTOMER_SHIP_TOS_LOADING = 'CUSTOMER_SHIP_TOS_LOADING';
export const CUSTOMER_SHIP_TOS_LOADED = 'CUSTOMER_SHIP_TOS_LOADED';
export const CUSTOMER_SHIP_TOS_LOADING_ERROR = 'CUSTOMER_SHIP_TOS_LOADING_ERROR';
export const CUSTOMER_NO_SHIP_TOS_LOADED = 'CUSTOMER_NO_SHIP_TOS_LOADED';
export const CUSTOMER_SELECT_CHILDREN_ACCOUNT = 'CUSTOMER_SELECT_CHILDREN_ACCOUNT';
export const CUSTOMER_UPDATE_ORDERING_FLAG = 'CUSTOMER_UPDATE_ORDERING_FLAG';
export const PRODUCT_PLANNING_UPDATE_RANGE = 'PRODUCT_PLANNING_UPDATE_RANGE';
export const PRODUCT_PLANNING_LOADING = 'PRODUCT_PLANNING_LOADING';
export const PRODUCT_PLANNING_LOADED = 'PRODUCT_PLANNING_LOADED';
export const PRODUCT_PLANNING_LOADING_ERROR = 'PRODUCT_PLANNING_LOADING_ERROR';
export const PRODUCT_PLANNING_LINKED_POS_LOADING = 'PRODUCT_PLANNING_LINKED_POS_LOADING';
export const PRODUCT_PLANNING_LINKED_POS_LOADED = 'PRODUCT_PLANNING_LINKED_POS_LOADED';
export const PRODUCT_PLANNING_SHIPPING_ITEM_LOADING = 'PRODUCT_PLANNING_SHIPPING_ITEM_LOADING';
export const PRODUCT_PLANNING_SHIPPING_ITEM_LOADED = 'PRODUCT_PLANNING_SHIPPING_ITEM_LOADED';
export const PRODUCT_PLANNING_SHIPPING_ITEM_LOADING_ERROR =
    'PRODUCT_PLANNING_SHIPPING_ITEM_LOADING_ERROR';
export const PRODUCT_PLANNING_SHIPPING_ITEM_CLEARED = 'PRODUCT_PLANNING_SHIPPING_ITEM_CLEARED';
export const PRODUCT_PLANNING_LINKED_PO_CLEARED = 'PRODUCT_PLANNING_LINKED_PO_CLEARED';
export const PRODUCT_PLANNING_UPDATE_COLLAPSE = 'PRODUCT_PLANNING_UPDATE_COLLAPSE';
export const PRODUCT_PLANNING_UPDATE_ALLOCATION_COLLAPSE =
    'PRODUCT_PLANNING_UPDATE_ALLOCATION_COLLAPSE';
export const PRODUCT_PLANNING_UPDATE_QUANTITY_UNIT = 'PRODUCT_PLANNING_UPDATE_QUANTITY_UNIT';
export const PRODUCT_PLANNING_FROM_DASHBOARD_LINK = 'PRODUCT_PLANNING_FROM_DASHBOARD_LINK';
export const PRODUCT_PLANNING_RESET = 'PRODUCT_PLANNING_RESET';

export const PRODUCT_PLANNING_FILTER = 'PRODUCT_PLANNING_FILTER';

export const PRODUCT_PLANNING_ATM_LOADED = 'PRODUCT_PLANNING_ATM_LOADED';
export const PRODUCT_PLANNING_ATM_RESET = 'PRODUCT_PLANNING_ATM_RESET';
export const PRODUCT_PLANNING_ATM_LOADING = 'PRODUCT_PLANNING_ATM_LOADING';
export const PRODUCT_PLANNING_ATM_LOADING_ERROR = 'PRODUCT_PLANNING_ATM_LOADING_ERROR';
export const PRODUCT_PLANNING_ATM_UPDATE_RANGE = 'PRODUCT_PLANNING_ATM_UPDATE_RANGE';
export const PRODUCT_PLANNING_ATM_UPDATE_FILTER = 'PRODUCT_PLANNING_ATM_UPDATE_FILTER';
export const PRODUCT_PLANNING_ATM_UPDATE_QUANTITY_UNIT =
    'PRODUCT_PLANNING_ATM_UPDATE_QUANTITY_UNIT';
export const PRODUCT_PLANNING_ATM_UPDATE_COLLAPSE = 'PRODUCT_PLANNING_ATM_UPDATE_COLLAPSE';

export const PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADING =
    'PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADING';
export const PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADED =
    'PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADED';
export const PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_ERROR =
    'PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_ERROR';

export const MAKEIT_DASHBOARD_LOADING = 'MAKEIT_DASHBOARD_LOADING';
export const MAKEIT_DASHBOARD_LOADED = 'MAKEIT_DASHBOARD_LOADED';
export const MAKEIT_DASHBOARD_LOADING_ERROR = 'MAKEIT_DASHBOARD_LOADING_ERROR';
export const SAVE_MAKEIT_DASHBOARD_ORDER = 'SAVE_MAKEIT_DASHBOARD_ORDER';
export const MAKEIT_DASHBOARD_UPDATE_VIEW = 'MAKEIT_DASHBOARD_UPDATE_VIEW';
export const MAKEIT_DASHBOARD_VIEW_ERROR = 'MAKEIT_DASHBOARD_VIEW_ERROR';
export const MAKEIT_DASHBOARD_UPDATE_RANGE = 'MAKEIT_DASHBOARD_UPDATE_RANGE';
export const MAKEIT_DASHBOARD_NO_PRODUCTS = 'MAKEIT_DASHBOARD_NO_PRODUCTS';
export const SAVE_MAKE_ORDER_CONFIG = 'SAVE_MAKE_ORDER_CONFIG';
export const MAKEIT_TOGGLE_ADD = 'MAKEIT_TOGGLE_ADD';
export const MAKEIT_SAVE_ORDER_LIST = 'MAKEIT_SAVE_ORDER_LIST';
export const MAKEIT_SET_ORDER_LINES = 'MAKEIT_SET_ORDER_LINES';
export const MAKEIT_PRODUCTS_TO_ORDER_UPDATE = 'MAKEIT_PRODUCTS_TO_ORDER_UPDATE';
export const MAKEIT_SAVE_INPROGRESS = 'MAKEIT_SAVE_INPROGRESS';
export const MAKEIT_ORDER_LOADING = 'MAKEIT_ORDER_LOADING';
export const MAKEIT_ORDER_LOADED = 'MAKEIT_ORDER_LOADED';
export const MAKEIT_ORDER_LOADING_ERROR = 'MAKEIT_ORDER_LOADING_ERROR';
export const MAKEIT_RESET_STATE = 'MAKEIT_RESET_STATE';
export const MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE = 'MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE';
export const MAKEIT_DASHBOARD_UPDATE_QUANTITY_UNIT = 'MAKEIT_DASHBOARD_UPDATE_QUANTITY_UNIT';
export const MAKEIT_DASHBOARD_UPDATE_PRODUCTS = 'MAKEIT_DASHBOARD_UPDATE_PRODUCTS';

export const MAKE_SUMMARY_LOAD_OPEN = 'MAKE_SUMMARY_LOAD_OPEN';
export const MAKE_SUMMARY_LOAD_CLOSED = 'MAKE_SUMMARY_LOAD_CLOSED';
export const MAKE_SUMMARY_LOAD_PENDING = 'MAKE_SUMMARY_LOAD_PENDING';
export const MAKE_SUMMARY_LOAD_DRAFT = 'MAKE_SUMMARY_LOAD_DRAFT';
export const MAKE_SUMMARY_LOAD_ORDERS = 'MAKE_SUMMARY_LOAD_ORDERS';
export const MAKE_SUMMARY_ERROR = 'MAKE_SUMMARY_ERROR';
export const MAKE_SUMMARY_LOADING = 'MAKE_SUMMARY_LOADING';
export const MAKE_SUMMARY_SET_SEARCH = 'MAKE_SUMMARY_SET_SEARCH';
export const MAKE_SUMMARY_USE_SEARCH = 'MAKE_SUMMARY_USE_SEARCH';
export const MAKE_SUMMARY_RESET = 'MAKE_SUMMARY_RESET';
export const MAKE_SUMMARY_PENDING_CANCEL_REQUESTED = 'MAKE_SUMMARY_PENDING_CANCEL_REQUESTED';
export const MAKE_SUMMARY_PENDING_CANCEL_COMPLETED = 'MAKE_SUMMARY_PENDING_CANCEL_COMPLETED';
export const MAKE_SUMMARY_PENDING_CANCEL_ERROR = 'MAKE_SUMMARY_PENDING_CANCEL_ERROR';

export const MAKE_IT_ATM_SUMMARY_LOADING = 'MAKE_IT_ATM_SUMMARY_LOADING';
export const MAKE_IT_ATM_SUMMARY_LOADED = 'MAKE_IT_ATM_SUMMARY_LOADED';
export const MAKE_IT_ATM_SUMMARY_ERROR = 'MAKE_IT_ATM_SUMMARY_ERROR';

export const PLAN_IT_SUMMARY_LOADING = 'PLAN_IT_SUMMARY_LOADING';
export const PLAN_IT_SUMMARY_LOADED = 'PLAN_IT_SUMMARY_LOADED';
export const PLAN_IT_SUMMARY_ERROR = 'PLAN_IT_SUMMARY_ERROR';
export const PLAN_IT_SUMMARY_UPDATE_COPY_TO_MAKE_IT_ORDERS =
    'PLAN_IT_SUMMARY_UPDATE_COPY_TO_MAKE_IT_ORDERS';
export const PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS =
    'PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS';

export const PLAN_IT_GUIDED_ADD_WEEK = 'PLAN_IT_GUIDED_ADD_WEEK';
export const PLAN_IT_GUIDED_SET_PRODUCTS = 'PLAN_IT_GUIDED_SET_PRODUCTS';
export const PLAN_IT_GUIDED_SET_PRODUCTS_EXISTING = 'PLAN_IT_GUIDED_SET_PRODUCTS_EXISTING';
export const PLAN_IT_GUIDED_RESET = 'PLAN_IT_GUIDED_RESET';
export const PLAN_IT_GUIDED_UPDATE_QUANTITY_UNIT = 'PLAN_IT_GUIDED_UPDATE_QUANTITY_UNIT';
export const PLAN_IT_GUIDED_SUBMIT_LOADING = 'PLAN_IT_GUIDED_SUBMIT_LOADING';
export const PLAN_IT_GUIDED_SUBMIT_SUCCESS = 'PLAN_IT_GUIDED_SUBMIT_SUCCESS';
export const PLAN_IT_GUIDED_SUBMIT_ERROR = 'PLAN_IT_GUIDED_SUBMIT_ERROR';
export const PLAN_IT_GUIDED_SET_CUSTOMER_PO = 'PLAN_IT_GUIDED_SET_CUSTOMER_PO';
export const PLAN_IT_GUIDED_INITIZLIZE_WEEKS = 'PLAN_IT_GUIDED_INITIZLIZE_WEEKS';
export const PLAN_IT_GUIDED_TOGGLE_SELECTED_WEEK = 'PLAN_IT_GUIDED_TOGGLE_SELECTED_WEEK';

export const PRODINFO_PRODUCTS_LOADING = 'PRODINFO_PRODUCTS_LOADING';
export const PRODINFO_PRODUCTS_LOADED = 'PRODINFO_PRODUCTS_LOADED';
export const PRODINFO_PRODUCTS_LOADING_ERROR = 'PRODINFO_PRODUCTS_LOADING_ERROR';
export const PRODINFO_PRODUCTS_NO_PRODUCTS = 'PRODINFO_PRODUCTS_NO_PRODUCTS';
export const PRODINFO_PRODUCTS_RESET_STATE = 'PRODINFO_PRODUCTS_RESET_STATE';
export const PRODINFO_PRODUCTS_UPDATE_VIEW = 'PRODINFO_PRODUCTS_UPDATE_VIEW';

export const EDIT_PRODUCTION_ORDER_SET_ORDER = 'EDIT_PRODUCTION_ORDER_SET_ORDER';
export const EDIT_PRODUCTION_ORDER_ERROR = 'EDIT_PRODUCTION_ORDER_ERROR';
export const EDIT_PRODUCTION_ORDER_LOADING = 'EDIT_PRODUCTION_ORDER_LOADING';
export const EDIT_PRODUCTION_ORDER_RESET = 'EDIT_PRODUCTION_ORDER_RESET';
export const EDIT_PRODUCTION_ORDER_UPDATED_PO = 'EDIT_PRODUCTION_ORDER_UPDATED_PO';

export const SHIPPING_DASHBOARD_LOADING = 'SHIPPING_DASHBOARD_LOADING';
export const SHIPPING_DASHBOARD_UPDATE_QUANTITY_UNIT = 'SHIPPING_DASHBOARD_UPDATE_QUANTITY_UNIT';
export const SHIPPING_DASHBOARD_LOADED = 'SHIPPING_DASHBOARD_LOADED';
export const SHIPPING_DASHBOARD_LOADING_ERROR = 'SHIPPING_DASHBOARD_LOADING_ERROR';
export const SHIPPING_DASHBOARD_UPDATE_VIEW = 'SHIPPING_DASHBOARD_UPDATE_VIEW';
export const SHIPPING_DASHBOARD_VIEW_ERROR = 'SHIPPING_DASHBOARD_VIEW_ERROR';
export const SHIPPING_DASHBOARD_UPDATE_RANGE = 'SHIPPING_DASHBOARD_UPDATE_RANGE';
export const SHIPPING_DASHBOARD_NO_PRODUCTS = 'SHIPPING_DASHBOARD_NO_PRODUCTS';
export const SHIPPING_DASHBOARD_UPDATE_RESULTS = 'SHIPPING_DASHBOARD_UPDATE_RESULTS';
export const SHIPPING_DASHBOARD_UPDATE_PALLETS = 'SHIPPING_DASHBOARD_UPDATE_PALLETS';
export const SHIPPING_DASHBOARD_UPDATE_DELIVERY_DATE = 'SHIPPING_DASHBOARD_UPDATE_DELIVERY_DATE';
export const SHIPPING_ORDER_LOADING = 'SHIPPING_ORDER_LOADING';
export const SHIPPING_ORDER_LOADED = 'SHIPPING_ORDER_LOADED';
export const SHIPPING_ORDER_LOADING_ERROR = 'SHIPPING_ORDER_LOADING_ERROR';
export const SHIPPING_ORDER_PRODUCTS_UPDATE = 'SHIPPING_ORDER_PRODUCTS_UPDATE';
export const SHIPPING_ORDER_SHIPMENTS_UPDATE = 'SHIPPING_ORDER_SHIPMENTS_UPDATE';
export const SHIPPING_UPDATE_DELIVERY_ORDER = 'SHIPPING_ORDER_UPDATE_DELIVERY_ORDER';
export const SHIPPING_DASHBOARD_RESET_STATE = 'SHIPPING_DASHBOARD_RESET_STATE';
export const SHIPPING_DASHBOARD_RESET_PRODUCT_STATE = 'SHIPPING_DASHBOARD_RESET_PRODUCT_STATE';
export const SHIPPING_DASHBOARD_CONFIG_FLAG = 'SHIPPING_DASHBOARD_CONFIG_FLAG';
export const SHIPPING_DASHBOARD_EXPANDABLE_LOAD_CHANGE =
    'SHIPPING_DASHBOARD_EXPANDABLE_LOAD_CHANGE';

export const SCRAP_IT_LOADING = 'SCRAP_IT_LOADING';
export const SCRAP_IT_UPDATE_QUANTITY_UNIT = 'SCRAP_IT_UPDATE_QUANTITY_UNIT';
export const SCRAP_IT_LOADED = 'SCRAP_IT_LOADED';
export const SCRAP_IT_LOADING_ERROR = 'SCRAP_IT_LOADING_ERROR';
export const SCRAP_IT_UPDATE_VIEW = 'SCRAP_IT_UPDATE_VIEW';
export const SCRAP_IT_VIEW_ERROR = 'SCRAP_IT_VIEW_ERROR';
export const SCRAP_IT_NO_PRODUCTS = 'SCRAP_IT_NO_PRODUCTS';
export const SCRAP_IT_UPDATE_PALLETS = 'SCRAP_IT_UPDATE_PALLETS';
export const SCRAP_IT_ORDER_PRODUCTS_UPDATE = 'SCRAP_IT_ORDER_PRODUCTS_UPDATE';
export const SCRAP_IT_RESET_PRODUCT_STATE = 'SCRAP_IT_RESET_PRODUCT_STATE';
export const SCRAP_IT_SUBMIT_SUCCESS = 'SCRAP_IT_SUBMIT_SUCCESS';
export const SCRAP_IT_SUBMIT_ERROR = 'SCRAP_IT_SUBMIT_ERROR';
export const SCRAP_IT_LOAD_AGED_PRODUCTS = 'SCRAP_IT_LOAD_AGED_PRODUCTS';
export const SCRAP_IT_SUBMIT_LOADING = 'SCRAP_IT_SUBMIT_LOADING';
export const SCRAP_IT_SUMMARY_LOADED = 'SCRAP_IT_SUMMARY_LOADED';
export const SCRAP_IT_SUMMARY_ERROR = 'SCRAP_IT_SUMMARY_ERROR';

export const SCRAP_IT_REQUEST_DETAILS_LOADING = 'SCRAP_IT_REQUEST_DETAILS_LOADING';
export const SCRAP_IT_REQUEST_DETAILS_LOADED = 'SCRAP_IT_REQUEST_DETAILS_LOADED';
export const SCRAP_IT_REQUEST_DETAILS_ERROR = 'SCRAP_IT_REQUEST_DETAILS_ERROR';
export const SCRAP_IT_REQUEST_DETAILS_RESET = 'SCRAP_IT_REQUEST_DETAILS_RESET';

export const SHIPPING_SUMMARY_LOADING = 'SHIPPING_SUMMARY_LOADING';
export const SHIPPING_SUMMARY_LOADED = 'SHIPPING_SUMMARY_LOADED';
export const SHIPPING_SUMMARY_LOADING_ERROR = 'SHIPPING_SUMMARY_LOADING_ERROR';
export const SHIPPING_SUMMARY_LOAD_OPEN = 'SHIPPING_SUMMARY_LOAD_OPEN';
export const SHIPPING_SUMMARY_LOAD_CLOSED = 'SHIPPING_SUMMARY_LOAD_CLOSED';
export const SHIPPING_SUMMARY_UPDATED = 'SHIPPING_SUMMARY_UPDATED';
export const SHIPPING_SUMMARY_RESET = 'SHIPPING_SUMMARY_RESET';
export const SHIPPING_SUMMARY_LOADING_OPEN_ERROR = 'SHIPPING_SUMMARY_LOADING_OPEN_ERROR';
export const SHIPPING_SUMMARY_LOADING_CLOSED_ERROR = 'SHIPPING_SUMMARY_LOADING_CLOSED_ERROR';

export const PENDING_PAYMENTS_LOADING = 'PENDING_PAYMENTS_LOADING';
export const PENDING_PAYMENTS_LOADED = 'PENDING_PAYMENTS_LOADED';
export const PENDING_PAYMENTS_LOADING_ERROR = 'PENDING_PAYMENTS_LOADING_ERROR';
export const PENDING_PAYMENTS_SUBMITTED = 'PENDING_PAYMENTS_SUBMITTED';

export const DELIVERY_ORDER_LOADING = 'DELIVERY_ORDER_LOADING';
export const DELIVERY_ORDER_LOADED = 'DELIVERY_ORDER_LOADED';
export const DELIVERY_ORDER_LOADING_ERROR = 'DELIVERY_ORDER_LOADING_ERROR';
export const DELIVERY_ORDER_UPDATE_ERROR = 'DELIVERY_ORDER_UPDATE_ERROR';

export const PRODUCTION_ORDER_LOADING = 'PRODUCTION_ORDER_LOADING';
export const PRODUCTION_ORDER_LOADED = 'PRODUCTION_ORDER_LOADED';
export const PRODUCTION_ORDER_LOADING_ERROR = 'PRODUCTION_ORDER_LOADING_ERROR';
export const PRODUCTION_ORDER_DELIVERY_ORDER_LOADING = 'PRODUCTION_ORDER_DELIVERY_ORDER_LOADING';
export const PRODUCTION_ORDER_DELIVERY_ORDER_LOADED = 'PRODUCTION_ORDER_DELIVERY_ORDER_LOADED';
export const PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR =
    'PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR';
export const PRODUCTION_ORDER_RESET_STATE = 'PRODUCTION_ORDER_RESET_STATE';

export const ATM_PRODUCTION_ORDER_LOADING = 'ATM_PRODUCTION_ORDER_LOADING';
export const ATM_PRODUCTION_ORDER_LOADED = 'ATM_PRODUCTION_ORDER_LOADED';
export const ATM_PRODUCTION_ORDER_LOADING_ERROR = 'ATM_PRODUCTION_ORDER_LOADING_ERROR';
export const ATM_PRODUCTION_ORDER_RESET_STATE = 'ATM_PRODUCTION_ORDER_RESET_STATE';

export const ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING =
    'ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING';
export const ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADED =
    'ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADED';
export const ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR =
    'ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR';

export const BULK_UPLOAD_LOADING = 'BULK_UPLOAD_LOADING';
export const BULK_UPLOAD_DELIVERY_LOADED = 'BULK_UPLOAD_DELIVERY_LOADED';
export const BULK_UPLOAD_DELIVERY_ERROR = 'BULK_UPLOAD_DELIVERY_ERROR';
export const BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW = 'BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW';
export const BULK_UPLOAD_DELIVERY_FILE = 'BULK_UPLOAD_DELIVERY_FILE';
export const BULK_UPLOAD_DELIVERY_SUBMIT_ORDERS = 'BULK_UPLOAD_DELIVERY_SUBMIT_ORDERS';
export const BULK_UPLOAD_RESET_STATE = 'BULK_UPLOAD_RESET_STATE';
export const BULK_UPLOAD_PRODUCTS = 'BULK_UPLOAD_PRODUCTS';
export const BULK_UPLOAD_DELETE_SHIPMENT = 'BULK_UPLOAD_DELETE_SHIPMENT';
export const BULK_UPLOAD_UPDATE_SHIPMENT = 'BULK_UPLOAD_UPDATE_SHIPMENT';
export const BULK_UPLOAD_UPDATE_DELIVERY_DATE = 'BULK_UPLOAD_UPDATE_DELIVERY_DATE';
export const BULK_UPLOAD_PALLETS_LOADING = 'BULK_UPLOAD_PALLETS_LOADING';
export const BULK_UPLOAD_PALLETS_LOADED = 'BULK_UPLOAD_PALLETS_LOADED';
export const BULK_UPLOAD_PALLETS_ERROR = 'BULK_UPLOAD_PALLETS_ERROR';
export const BULK_UPLOAD_REMOVE_ORDER = 'BULK_UPLOAD_REMOVE_ORDER';

export const MAKEIT_BULK_UPLOAD_LOADING = 'MAKEIT_BULK_UPLOAD_LOADING';
export const MAKEIT_BULK_UPLOAD_LOADED = 'MAKEIT_BULK_UPLOAD_LOADED';
export const MAKEIT_BULK_UPLOAD_ERROR = 'MAKEIT_BULK_UPLOAD_ERROR';
export const MAKEIT_BULK_UPLOAD_FILE = 'MAKEIT_BULK_UPLOAD_FILE';
export const MAKEIT_BULK_UPLOAD_RESET_STATE = 'MAKEIT_BULK_UPLOAD_RESET_STATE';
export const MAKEIT_BULK_UPLOAD_GET_PRODUCTS = 'MAKEIT_BULK_UPLOAD_GET_PRODUCTS';
export const MAKEIT_BULK_SUBMIT_REVIEW = 'MAKEIT_BULK_SUBMIT_REVIEW';
export const MAKEIT_BULK_UPDATE_PALLET_QUANTITY = 'MAKEIT_BULK_UPDATE_PALLET_QUANTITY';
export const MAKEIT_BULK_UPDATED_REQUESTED_DATE = 'MAKEIT_BULK_UPDATED_REQUESTED_DATE';
export const MAKEIT_BULK_UPDATED_PRODUCT_SKU = 'MAKEIT_BULK_UPDATED_PRODUCT_SKU';
export const MAKEIT_BULK_UPDATED_LINE_ITEM = 'MAKEIT_BULK_UPDATED_LINE_ITEM';
export const MAKEIT_BULK_MARK_ORDER_REMOVED = 'MAKEIT_BULK_MARK_ORDER_REMOVED';
export const MAKEIT_BULK_DELETE_ORDER = 'MAKEIT_BULK_DELETE_ORDER';
export const MAKEIT_BULK_UNDO_DELETE_ORDER = 'MAKEIT_BULK_UNDO_DELETE_ORDER';
export const MAKEIT_BULK_UPLOAD_SUBMIT = 'MAKEIT_BULK_UPLOAD_SUBMIT';
export const MAKEIT_BULK_DELETE_PRODUCT = 'MAKEIT_BULK_DELETE_PRODUCT';
export const MAKEIT_BULK_UPLOAD_REMOVE_ORDER = 'MAKEIT_BULK_UPLOAD_REMOVE_ORDER';
export const MAKEIT_BULK_UPLOAD_REFRESH_STATE = 'MAKEIT_BULK_UPLOAD_REFRESH_STATE';

export const MAKEIT_BULK_ATM_SUBMIT_REVIEW = 'MAKEIT_BULK_ATM_SUBMIT_REVIEW';
export const MAKEIT_BULK_UPLOAD_ATM_LOADED = 'MAKEIT_BULK_UPLOAD_ATM_LOADED';
export const MAKEIT_BULK_UPLOAD_ATM_LOADING = 'MAKEIT_BULK_UPLOAD_ATM_LOADING';
export const MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR = 'MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR';
export const MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR = 'MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR';
export const MAKEIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY =
    'MAKEIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY';
export const MAKEIT_BULK_ATM_SNOSKU_ORDER = 'MAKEIT_BULK_ATM_SNOSKU_ORDER';
export const MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING =
    'MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING';
export const MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS = 'MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS';
export const MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR =
    'MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR';
export const MAKEIT_BULK_UPLOAD_ATM_RESET_ALLOCATIONS_STATE =
    'MAKEIT_BULK_UPLOAD_ATM_RESET_ALLOCATIONS_STATE';
export const MAKEIT_BULK_ATM_DELETE_PRODUCT = 'MAKEIT_BULK_ATM_DELETE_PRODUCT';
export const MAKEIT_BULK_ATM_ADD_PRODUCT = 'MAKEIT_BULK_ATM_ADD_PRODUCT';
export const MAKEIT_BULK_ATM_LOAD_ORDERS = 'MAKEIT_BULK_ATM_LOAD_ORDERS';
export const MAKEIT_BULK_ATM_ADD_WEEK = 'MAKEIT_BULK_ATM_ADD_WEEK';
export const MAKEIT_BULK_ATM_COPY_PRODUCT = 'MAKEIT_BULK_ATM_COPY_PRODUCT';
export const MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR = 'MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR';
export const MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS = 'MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS';
export const MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING = 'MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING';
export const MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR = 'MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR';
export const MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS = 'MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS';
export const MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING = 'MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING';
export const MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR = 'MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR';
export const MAKEIT_BULK_ATM_EDIT_ORDER_LOADED = 'MAKEIT_BULK_ATM_EDIT_ORDER_LOADED';
export const MAKEIT_BULK_ATM_EDIT_ORDER_LOADING = 'MAKEIT_BULK_ATM_EDIT_ORDER_LOADING';
export const MAKEIT_BULK_ATM_EDIT_ORDER_ERROR = 'MAKEIT_BULK_ATM_EDIT_ORDER_ERROR';
export const MAKEIT_BULK_ATM_UPDATED_PRODUCT_SKU = 'MAKEIT_BULK_ATM_UPDATED_PRODUCT_SKU';
export const MAKEIT_BULK_ATM_EXISTING_ORDERS = 'MAKEIT_BULK_ATM_EXISTING_ORDERS';
export const MAKEIT_BULK_ATM_DRAFT_ORDER_LOADING = 'MAKEIT_BULK_ATM_DRAFT_ORDER_LOADING';
export const MAKEIT_BULK_ATM_DRAFT_ORDER_LOADED = 'MAKEIT_BULK_ATM_DRAFT_ORDER_LOADED';
export const MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR = 'MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR';
export const MAKEIT_BULK_ATM_UPDATE_CUSTOMER_PO = 'MAKEIT_BULK_ATM_UPDATE_CUSTOMER_PO';

export const MAKEIT_CONVERSION_ORDER_LOADING = 'MAKEIT_CONVERSION_ORDER_LOADING';
export const MAKEIT_CONVERSION_ORDER_LOADED = 'MAKEIT_CONVERSION_ORDER_LOADED';
export const MAKEIT_CONVERSION_ORDER_LOADING_ERROR = 'MAKEIT_CONVERSION_ORDER_LOADING_ERROR';
export const MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_LOADING =
    'MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_LOADING';
export const MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS = 'MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS';
export const MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_ERROR =
    'MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_ERROR';
export const MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADING = 'MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADING';
export const MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADED = 'MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADED';
export const MAKEIT_CONVERSION_ORDER_PRODUCTS_ERROR = 'MAKEIT_CONVERSION_ORDER_PRODUCTS_ERROR';
export const MAKEIT_CONVERSION_ORDER_UPDATE_PALLET_QUANTITY =
    'MAKEIT_CONVERSION_ORDER_UPDATE_PALLET_QUANTITY';
export const MAKEIT_CONVERSION_ORDER_DELETE_PRODUCT = 'MAKEIT_CONVERSION_ORDER_DELETE_PRODUCT';
export const MAKEIT_CONVERSION_ORDER_ADD_PRODUCT = 'MAKEIT_CONVERSION_ORDER_ADD_PRODUCT';
export const MAKEIT_CONVERSION_ORDER_COPY_FORECAST = 'MAKEIT_CONVERSION_ORDER_COPY_FORECAST';
export const MAKEIT_CONVERSION_ORDER_EXISTING_ORDERS = 'MAKEIT_CONVERSION_ORDER_EXISTING_ORDERS';
export const MAKEIT_CONVERSION_RESET_STATE = 'MAKEIT_CONVERSION_RESET_STATE';
export const MAKEIT_CONVERSION_SUBMIT_LOADING = 'MAKEIT_CONVERSION_SUBMIT_LOADING';
export const MAKEIT_CONVERSION_SUBMIT_ERROR = 'MAKEIT_CONVERSION_SUBMIT_ERROR';
export const MAKEIT_CONVERSION_SUBMIT_SUCCESS = 'MAKEIT_CONVERSION_SUBMIT_SUCCESS';
export const MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU =
    'MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU';

export const MAKE_IT_DRAFTS_LOADING = 'MAKE_IT_DRAFTS_LOADING';
export const MAKE_IT_DRAFTS_LOADED = 'MAKE_IT_DRAFTS_LOADED';
export const MAKE_IT_DRAFTS_ERROR = 'MAKE_IT_DRAFTS_ERROR';
export const MAKE_IT_DRAFTS_CHANGE_SELECTION = 'MAKE_IT_DRAFTS_CHANGE_SELECTION';
export const MAKE_IT_DRAFTS_RESET = 'MAKE_IT_DRAFTS_RESET';

export const PLANIT_BULK_UPLOAD_LOADING = 'PLANIT_BULK_UPLOAD_LOADING';
export const PLANIT_BULK_UPLOAD_LOADED = 'PLANIT_BULK_UPLOAD_LOADED';
export const PLANIT_BULK_UPLOAD_ERROR = 'PLANIT_BULK_UPLOAD_ERROR';
export const PLANIT_BULK_UPLOAD_FILE = 'PLANIT_BULK_UPLOAD_FILE';
export const PLANIT_BULK_UPLOAD_RESET_STATE = 'PLANIT_BULK_UPLOAD_RESET_STATE';
export const PLANIT_BULK_UPLOAD_GET_PRODUCTS = 'PLANIT_BULK_UPLOAD_GET_PRODUCTS';
export const PLANIT_BULK_SUBMIT_REVIEW = 'PLANIT_BULK_SUBMIT_REVIEW';
export const PLANIT_BULK_UPDATE_PALLET_QUANTITY = 'PLANIT_BULK_UPDATE_PALLET_QUANTITY';
export const PLANIT_BULK_UPDATED_REQUESTED_DATE = 'PLANIT_BULK_UPDATED_REQUESTED_DATE';
export const PLANIT_BULK_UPDATED_PRODUCT_SKU = 'PLANIT_BULK_UPDATED_PRODUCT_SKU';
export const PLANIT_BULK_UPDATED_LINE_ITEM = 'PLANIT_BULK_UPDATED_LINE_ITEM';
export const PLANIT_BULK_MARK_ORDER_REMOVED = 'PLANIT_BULK_MARK_ORDER_REMOVED';
export const PLANIT_BULK_DELETE_ORDER = 'PLANIT_BULK_DELETE_ORDER';
export const PLANIT_BULK_UNDO_DELETE_ORDER = 'PLANIT_BULK_UNDO_DELETE_ORDER';
export const PLANIT_BULK_UPLOAD_SUBMIT = 'PLANIT_BULK_UPLOAD_SUBMIT';
export const PLANIT_BULK_DELETE_PRODUCT = 'PLANIT_BULK_DELETE_PRODUCT';
export const PLANIT_BULK_UPLOAD_REMOVE_ORDER = 'PLANIT_BULK_UPLOAD_REMOVE_ORDER';
export const PLANIT_BULK_UPLOAD_REFRESH_STATE = 'PLANIT_BULK_UPLOAD_REFRESH_STATE';

export const PLANIT_BULK_ATM_SUBMIT_REVIEW = 'PLANIT_BULK_ATM_SUBMIT_REVIEW';
export const PLANIT_BULK_ATM_SUBMIT_DELTA_REVIEW = 'PLANIT_BULK_ATM_SUBMIT_DELTA_REVIEW';
export const PLANIT_BULK_UPLOAD_ATM_LOADED = 'PLANIT_BULK_UPLOAD_ATM_LOADED';
export const PLANIT_BULK_UPLOAD_ATM_LOADING = 'PLANIT_BULK_UPLOAD_ATM_LOADING';
export const PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR = 'PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR';
export const PLANIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR = 'PLANIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR';
export const PLANIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY =
    'PLANIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY';
export const PLANIT_BULK_ATM_SNOSKU_ORDER = 'PLANIT_BULK_ATM_SNOSKU_ORDER';
export const PLANIT_BULK_ATM_DELETE_PRODUCT = 'PLANIT_BULK_ATM_DELETE_PRODUCT';
export const PLANIT_BULK_ATM_ADD_PRODUCT = 'PLANIT_BULK_ATM_ADD_PRODUCT';
export const PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS = 'PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS';
export const PLANIT_BULK_ATM_SUBMIT_ORDERS_LOADING = 'PLANIT_BULK_ATM_SUBMIT_ORDERS_LOADING';
export const PLANIT_BULK_ATM_SUBMIT_ORDERS_ERROR = 'PLANIT_BULK_ATM_SUBMIT_ORDERS_ERROR';
export const PLANIT_BULK_ATM_EDIT_ORDER_LOADED = 'PLANIT_BULK_ATM_EDIT_ORDER_LOADED';
export const PLANIT_BULK_ATM_EDIT_ORDER_LOADING = 'PLANIT_BULK_ATM_EDIT_ORDER_LOADING';
export const PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU = 'PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU';
export const PLANIT_BULK_ATM_EXISTING_ORDERS = 'PLANIT_BULK_ATM_EXISTING_ORDERS';
export const PLANIT_BULK_ATM_UPDATE_CUSTOMER_PO = 'PLANIT_BULK_ATM_UPDATE_CUSTOMER_PO';

export const DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADING =
    'DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADING';
export const DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADED =
    'DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADED';
export const DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_ERROR =
    'DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_ERROR';

export const EDIT_SHIPMENTS_SUMMARY_LOADING = 'EDIT_SHIPMENTS_SUMMARY_LOADING';
export const EDIT_SHIPMENTS_SUMMARY_LOADED = 'EDIT_SHIPMENTS_SUMMARY_LOADED';
export const EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR = 'EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR';
export const EDIT_SHIPMENTS_SUMMARY_UPDATE_ORDER = 'EDIT_SHIPMENTS_SUMMARY_UPDATE_ORDER';
export const EDIT_SHIPMENTS_PRODUCTS_LOADING = 'EDIT_SHIPMENTS_PRODUCTS_LOADING';
export const EDIT_SHIPMENTS_PRODUCTS_LOADED = 'EDIT_SHIPMENTS_PRODUCTS_LOADED';
export const EDIT_SHIPMENTS_PRODUCTS_LOADING_ERROR = 'EDIT_SHIPMENTS_PRODUCTS_LOADING_ERROR';
export const EDIT_SHIPMENTS_NO_PRODUCTS = 'EDIT_SHIPMENTS_NO_PRODUCTS';
export const EDIT_SHIPMENTS_SHIPMENT_EDITED = 'EDIT_SHIPMENTS_SHIPMENT_EDITED';
export const EDIT_SHIPMENTS_SHIPMENT_CANCELLED = 'EDIT_SHIPMENTS_SHIPMENT_CANCELLED';
export const EDIT_SHIPMENTS_CLEAR_STATE = 'EDIT_SHIPMENTS_CLEAR_STATE';
export const EDIT_SHIPMENTS_UPDATE_AVAILABLE_PALLETS = 'EDIT_SHIPMENTS_UPDATE_PALLETS';
export const EDIT_SHIPMENTS_UPDATE_DELIVERY_DATE = 'EDIT_SHIPMENTS_UPDATE_DELIVERY_DATE';
export const EDIT_SHIPMENTS_UPDATE_PALLETS_BY_DAY = 'EDIT_SHIPMENTS_UPDATE_PALLETS_BY_DAY';
export const EDIT_SHIPMENTS_UPDATE_PALLET_TOTALS = 'EDIT_SHIPMENTS_UPDATE_PALLET_TOTALS';
export const EDIT_SHIPMENTS_UPDATE_PRODUCTS_TO_EDIT = 'EDIT_SHIPMENTS_UPDATE_PRODUCTS_TO_EDIT';
export const EDIT_SHIPMENTS_CLEAR_PRODUCTS_TO_EDIT = 'EDIT_SHIPMENTS_CLEAR_PRODUCTS_TO_EDIT';
export const EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY =
    'EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY';
export const EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_ERROR =
    'EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_ERROR';
export const EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_LOADING =
    'EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_LOADING';
export const EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_SUCCESS =
    'EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_SUCCESS';
export const EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_EXCLUDE_DO =
    'EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_EXCLUDE_DO';
export const EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE = 'EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE';
export const EDIT_SHIPMENTS_LAST_EDITED_SHIPMENT_ID = 'EDIT_SHIPMENTS_LAST_EDITED_SHIPMENT_ID';

export const USER_ADMIN_USER_LOADING = 'USER_ADMIN_USER_LOADING';
export const USER_ADMIN_USER_LOADED = 'USER_ADMIN_USER_LOADED';
export const USER_ADMIN_USER_CLEARED = 'USER_ADMIN_USER_CLEARED';
export const USER_ADMIN_VIEW_EDIT_USER = 'USER_ADMIN_VIEW_EDIT_USER';
export const USER_ADMIN_USER_LOADING_ERROR = 'USER_ADMIN_USER_LOADING_ERROR';
export const USER_ADMIN_PENDING_REQUEST_LOADED = 'USER_ADMIN_PENDING_REQUEST_LOADED';
export const USER_ADMIN_PENDING_REQUEST_REJECTED = 'USER_ADMIN_PENDING_REQUEST_REJECTED';
export const USER_ADMIN_PENDING_REQUEST_APPROVED = 'USER_ADMIN_PENDING_REQUEST_APPROVED';

export const USER_ADMIN_USERS_LOADING = 'USER_ADMIN_USERS_LOADING';
export const USER_ADMIN_USERS_LOADED = 'USER_ADMIN_USERS_LOADED';
export const USER_ADMIN_USERS_CLEARED = 'USER_ADMIN_USERS_CLEARED';
export const USER_ADMIN_USERS_LOADING_ERROR = 'USER_ADMIN_USERS_LOADING_ERROR';
export const USER_ADMIN_USER_DISABLING_ERROR = 'USER_ADMIN_USER_DISABLING_ERROR';
export const USER_ADMIN_ADD_USER_ERROR = 'USER_ADMIN_ADD_USER_ERROR';
export const USER_ADMIN_ADD_USER_SUCCESS = 'USER_ADMIN_ADD_USER_SUCCESS';
export const USER_ADMIN_ADD_USER_LOADING = 'USER_ADMIN_ADD_USER_LOADING';
export const USER_ADMIN_PENDING_REQUEST_RESET = 'USER_ADMIN_PENDING_REQUEST_RESET';
export const USER_ADMIN_ADD_USER_RESET_STATUS = 'USER_ADMIN_ADD_USER_RESET_STATUS';
export const USER_ADMIN_USER_UPDATE_STATUS_ERROR = 'USER_ADMIN_USER_UPDATE_STATUS_ERROR';

export const REQUEST_ACCESS_LOADING = 'REQUEST_ACCESS_LOADING';
export const REQUEST_ACCESS_LOADING_ERROR = 'REQUEST_ACCESS_LOADING_ERROR';
export const REQUEST_ACCESS_SET_ACCOUNTS = 'REQUEST_ACCESS_SET_ACCOUNTS';
export const REQUEST_ACCESS_SET_COMMENT = 'REQUEST_ACCESS_SET_COMMENT';
export const REQUEST_ACCESS_SUBMIT_REQUEST_SUCCESS = 'REQUEST_ACCESS_SUBMIT_REQUEST_SUCCESS';
export const REQUEST_ACCESS_SUBMIT_REQUEST_ERROR = 'REQUEST_ACCESS_SUBMIT_REQUEST_ERROR';
export const REQUEST_ACCESS_CLEARED = 'REQUEST_ACCESS_CLEARED';

export const USER_ADMIN_PENDING_REGISTRATIONS = 'USER_ADMIN_PENDING_REGISTRATIONS';
export const USER_ADMIN_PENDING_REQUESTS = 'USER_ADMIN_PENDING_REQUESTS';

export const MY_ACCOUNT_PERMISSION_CHANGES_LOADING = 'MY_ACCOUNT_PERMISSION_CHANGES_LOADING';
export const MY_ACCOUNT_PERMISSION_CHANGES_LOADED = 'MY_ACCOUNT_PERMISSION_CHANGES_LOADED';
export const MY_ACCOUNT_PERMISSION_CHANGES_ERROR = 'MY_ACCOUNT_PERMISSION_CHANGES_ERROR';

export const PRODUCTS_CONFIGURED_LOADING = 'PRODUCTS_CONFIGURED_LOADING';
export const PRODUCTS_CONFIGURED_SAVED = 'PRODUCTS_CONFIGURED_SAVED';
export const PRODUCTS_CONFIGURED_ERROR = 'PRODUCTS_CONFIGURED_ERROR';
export const PRODUCTS_CONFIGURED_CLEAR = 'PRODUCTS_CONFIGURED_CLEAR';
export const PRODUCTS_CONFIGURATIONS_LOADING = 'PRODUCTS_CONFIGURATIONS_LOADING';
export const PRODUCTS_CONFIGURATIONS_LOADED = 'PRODUCTS_CONFIGURATIONS_LOADED';
export const PRODUCTS_CONFIGURATIONS_ERROR = 'PRODUCTS_CONFIGURATIONS_ERROR';

export const CAPACITY_CHECK_LOADING = 'CAPACITY_CHECK_LOADING';
export const CAPACITY_CHECK_LOADED = 'CAPACITY_CHECK_LOADED';
export const CAPACITY_CHECK_SAVED = 'CAPACITY_CHECK_SAVED';
export const CAPACITY_CHECK_ERROR = 'CAPACITY_CHECK_ERROR';
export const CAPACITY_CHECK_CLEAR = 'CAPACITY_CHECK_CLEAR';
export const CAPACITY_CHECKS_LOADING = 'CAPACITY_CHECKS_LOADING';
export const CAPACITY_CHECKS_LOADED = 'CAPACITY_CHECKS_LOADED';
export const CAPACITY_CHECKS_SAVED = 'CAPACITY_CHECKS_SAVED';
export const CAPACITY_CHECKS_ERROR = 'CAPACITY_CHECKS_ERROR';
export const CAPACITY_CHECKS_CLEAR = 'CAPACITY_CHECKS_CLEAR';
export const CAPACITY_CHECK_CLEAR_ERROR = 'CAPACITY_CHECK_CLEAR_ERROR';

export const ONBOARDING_CREDITAPP_SUCCESS = 'ONBOARDING_CREDITAPP_SUCCESS';
export const ONBOARDING_CREDITAPP_LOADING = 'ONBOARDING_CREDITAPP_LOADING';
export const ONBOARDING_CREDITAPP_DRAFT_LOADED = 'ONBOARDING_CREDITAPP_DRAFT_LOADED';
export const ONBOARDING_CREDITAPP_DRAFT_SAVED = 'ONBOARDING_CREDITAPP_DRAFT_SAVED';
export const ONBOARDING_CREDITAPP_SUBMIT_SUCCESS = 'ONBOARDING_CREDITAPP_SUBMIT_SUCCESS';
export const ONBOARDING_CREDITAPP_ERROR = 'ONBOARDING_CREDITAPP_ERROR';

export const ONBOARDING_BILLINGANDSHIPPING_DRAFT_SAVED =
    'ONBOARDING_BILLINGANDSHIPPING_DRAFT_SAVED';
export const ONBOARDING_BILLINGANDSHIPPING_DRAFT_LOADED =
    'ONBOARDING_BILLINGANDSHIPPING_DRAFT_LOADED';
export const ONBOARDING_BILLINGANDSHIPPING_LOADING = 'ONBOARDING_BILLINGANDSHIPPING_LOADING';
export const ONBOARDING_BILLINGANDSHIPPING_LOADED = 'ONBOARDING_BILLINGANDSHIPPING_LOADED';
export const ONBOARDING_BILLINGANDSHIPPING_SUCCESS = 'ONBOARDING_BILLINGANDSHIPPING_SUCCESS';
export const ONBOARDING_BILLINGANDSHIPPING_ERROR = 'ONBOARDING_BILLINGANDSHIPPING_ERROR';

export const ONBOARDING_SALESANDUSETAX_FETCH_SUCCESS = 'ONBOARDING_SALESANDUSETAX_FETCH_COMPLETE';
export const ONBOARDING_SALESANDUSETAX_FETCH_LOADING = 'ONBOARDING_SALESANDUSETAX_FETCH_LOADING';
export const ONBOARDING_SALESANDUSETAX_FETCH_ERROR = 'ONBOARDING_SALESANDUSETAX_FETCH_ERROR';
export const ONBOARDING_SALESANDUSETAX_SUCCESS = 'ONBOARDING_SALESANDUSETAX_COMPLETE';
export const ONBOARDING_SALESANDUSETAX_LOADING = 'ONBOARDING_SALESANDUSETAX_LOADING';
export const ONBOARDING_SALESANDUSETAX_ERROR = 'ONBOARDING_SALESANDUSETAX_ERROR';
export const ONBOARDING_SALESANDUSETAX_SUBMIT_SUCCESS = 'ONBOARDING_SALESANDUSETAX_SUBMIT_COMPLETE';
export const ONBOARDING_SALESANDUSETAX_SUBMIT_LOADING = 'ONBOARDING_SALESANDUSETAX_SUBMIT_LOADING';
export const ONBOARDING_SALESANDUSETAX_SUBMIT_ERROR = 'ONBOARDING_SALESANDUSETAX_SUBMIT_ERROR';
export const ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_SUCCESS =
    'ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_COMPLETE';
export const ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_LOADING =
    'ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_LOADING';
export const ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_ERROR =
    'ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_ERROR';
export const ONBOARDING_SALESANDUSETAX_CANCEL = 'ONBOARDING_SALESANDUSETAX_CANCEL';
export const ONBOARDING_SALESANDUSETAX_BACKAFTERSAVE = 'ONBOARDING_SALESANDUSETAX_BACKAFTERSAVE';
export const ONBOARDING_SALESANDUSETAX_CONTINUEEDIT = 'ONBOARDING_SALESANDUSETAX_CONTINUEEDIT';

export const ONBOARDING_ACCOUNTSTATUS_SUCCESS = 'ONBOARDING_ACCOUNTSTATUS_SUCCESS';
export const ONBOARDING_ACCOUNTSTATUS_LOADING = 'ONBOARDING_ACCOUNTSTATUS_LOADING';
export const ONBOARDING_ACCOUNTSTATUS_ERROR = 'ONBOARDING_ACCOUNTSTATUS_ERROR';
export const ONBOARDING_ACCOUNTSTATUS_RESET = 'ONBOARDING_ACCOUNTSTATUS_RESET';
export const ONBOARDING_INVITATION_SUCCESS = 'ONBOARDING_INVITATION_SUCCESS';
export const ONBOARDING_INVITATION_RESET = 'ONBOARDING_INVITATION_RESET';
export const ONBOARDING_LOAD_CAPACITY_CHECKS_ERROR = 'ONBOARDING_LOAD_CAPACITY_CHECKS_ERROR';

export const CUSTOMER_DASHBOARD_LOADING = 'CUSTOMER_DASHBOARD_LOADING';
export const CUSTOMER_DASHBOARD_ERROR = 'CUSTOMER_DASHBOARD_ERROR';
export const CUSTOMER_DASHBOARD_RESET = 'CUSTOMER_DASHBOARD_RESET';
export const CUSTOMER_DASHBOARD_RESET_STATUS = 'CUSTOMER_DASHBOARD_RESET_STATUS';
export const CUSTOMER_DASHBOARD_CACHE_SAVE = 'CUSTOMER_DASHBOARD_CACHE_SAVE';
export const CUSTOMER_DASHBOARD_SINGLE_CACHE_RESET = 'CUSTOMER_DASHBOARD_SINGLE_CACHE_RESET';
export const CUSTOMER_DASHBOARD_MAKEIT_WIDGET_ORDERS = 'CUSTOMER_DASHBOARD_MAKEIT_WIDGET_ORDERS';
export const CUSTOMER_DASHBOARD_MAKEIT_WIDGET_INVOICES =
    'CUSTOMER_DASHBOARD_MAKEIT_WIDGET_INVOICES';
export const CUSTOMER_DASHBOARD_MAKEIT_WIDGET_AGED_BALANCE =
    'CUSTOMER_DASHBOARD_MAKEIT_WIDGET_AGED_BALANCE';
export const CUSTOMER_DASHBOARD_MAKEIT_WIDGET_PRODUCT_BALANCE =
    'CUSTOMER_DASHBOARD_MAKEIT_WIDGET_PRODUCT_BALANCE';
export const CUSTOMER_DASHBOARD_LOADED = 'CUSTOMER_DASHBOARD_LOADED';

export const USER_CONFIGURATION_LOADING = 'USER_CONFIGURATION_LOADING';
export const USER_CONFIGURATION_LOADED = 'USER_CONFIGURATION_LOADED';
export const USER_CONFIGURATION_UPDATE = 'USER_CONFIGURATION_UPDATE';
export const USER_CONFIGURATION_ERROR = 'USER_CONFIGURATION_ERROR';

export const PRICING_BREAKDOWN_LOADING = 'PRICING_BREAKDOWN_LOADING';
export const PRICING_BREAKDOWN_LOADED = 'PRICING_BREAKDOWN_LOADED';
export const PRICING_BREAKDOWN_ERROR = 'PRICING_BREAKDOWN_ERROR';
export const PRICING_BREAKDOWN_RESET = 'PRICING_BREAKDOWN_RESET';

export const COMMUNICATION_SUMMARY_LOADING = 'COMMUNICATION_SUMMARY_LOADING';
export const COMMUNICATION_SUMMARY_LOADED = 'COMMUNICATION_SUMMARY_LOADED';
export const COMMUNICATION_SUMMARY_ERROR = 'COMMUNICATION_SUMMARY_ERROR';
export const COMMUNICATION_SUMMARY_RESET = 'COMMUNICATION_SUMMARY_RESET';

export const COMMUNICATION_STATUS_UPDATE = 'COMMUNICATION_STATUS_UPDATE';
export const COMMUNICATION_STATUS_UPDATE_ERROR = 'COMMUNICATION_STATUS_UPDATE_ERROR';
export const COMMUNICATION_STATUS_UPDATE_SUCCESS = 'COMMUNICATION_STATUS_UPDATE_SUCCESS';

export const COMMUNICATION_UPDATE = 'COMMUNICATION_UPDATE';
export const COMMUNICATION_UPDATE_ERROR = 'COMMUNICATION_UPDATE_ERROR';
export const COMMUNICATION_UPDATE_SUCCESS = 'COMMUNICATION_UPDATE_SUCCESS';

export const COMMUNICATION_MESSAGES_LOADING = 'COMMUNICATION_MESSAGES_LOADING';
export const COMMUNICATION_MESSAGES_LOADED = 'COMMUNICATION_MESSAGES_LOADED';
export const COMMUNICATION_MESSAGES_ERROR = 'COMMUNICATION_MESSAGES_ERROR';
export const COMMUNICATION_MESSAGES_RESET = 'COMMUNICATION_MESSAGES_RESET';
export const COMMUNICATION_MESSAGES_DISMISS = 'COMMUNICATION_MESSAGES_DISMISS';

export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const NOTIFICATIONS_RESET = 'NOTIFICATIONS_RESET';
export const NOTIFICATIONS_DISMISS_NOTIFICATION = 'NOTIFICATIONS_DISMISS_NOTIFICATION';

export const MANAGE_PRODUCTS_RESET = 'MANAGE_PRODUCTS_RESET';
export const MANAGE_PRODUCTS_LOADING = 'MANAGE_PRODUCTS_LOADING';
export const MANAGE_PRODUCTS_LOADED = 'MANAGE_PRODUCTS_LOADED';
export const MANAGE_PRODUCTS_ERROR = 'MANAGE_PRODUCTS_ERROR';
export const MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_SET_EXPANDED =
    'MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_SET_EXPANDED';
export const MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_UPDATE_QUANTITIES =
    'MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_UPDATE_QUANTITIES';
export const MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_CANCEL_UPDATES =
    'MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_CANCEL_UPDATES';

export const TRADE_IT_BALANCES_LOADING = 'TRADE_IT_BALANCES_LOADING';
export const TRADE_IT_BALANCES_LOADED = 'TRADE_IT_BALANCES_LOADED';
export const TRADE_IT_BALANCES_LOADING_ERROR = 'TRADE_IT_BALANCES_LOADING_ERROR';
export const TRADE_IT_BALANCES_PRODUCT_LOADING_ERROR = 'TRADE_IT_BALANCES_PRODUCT_LOADING_ERROR';
export const TRADE_IT_BALANCES_RESET = 'TRADE_IT_BALANCES_RESET';

export const TRADE_IT_EMAILS_LOADING = 'TRADE_IT_EMAILS_LOADING';
export const TRADE_IT_EMAILS_LOADED = 'TRADE_IT_EMAILS_LOADED';
export const TRADE_IT_EMAILS_LOADING_ERROR = 'TRADE_IT_EMAILS_LOADING_ERROR';
export const TRADE_IT_EMAILS_RESET = 'TRADE_IT_EMAILS_RESET';
export const TRADE_IT_TO_EMAILS_RESET = 'TRADE_IT_TO_EMAILS_RESET';
export const TRAADE_IT_FROM_EMAILS_RESET = 'TRADE_IT_FROM_EMAILS_RESET';

export const TRADE_REQUEST_SUMMARY_LOADING = 'TRADE_REQUEST_SUMMARY_LOADING';
export const TRADE_REQUEST_SUMMARY_LOADED = 'TRADE_REQUEST_SUMMARY_LOADED';
export const TRADE_REQUEST_SUMMARY_LOADING_ERROR = 'TRADE_REQUEST_SUMMARY_LOADING_ERROR';
export const TRADE_REQUEST_SUMMARY_RESET = 'TRADE_REQUEST_SUMMARY_RESET';

export const TRADE_IT_UPDATE_QUANTITY_TO_TRADE = 'TRADE_IT_UPDATE_QUANTITY_TO_TRADE';
export const TRADE_IT_REQUEST_SUBMITTED = 'TRADE_IT_REQUEST_SUBMITTED';

export const TRADEIT_REQUEST_DETAIL_LOADING = 'TRADEIT_REQUEST_DETAIL_LOADING';
export const TRADEIT_REQUEST_DETAIL_LOADED = 'TRADEIT_REQUEST_DETAIL_LOADED';
export const TRADEIT_REQUEST_DETAIL_LOADING_ERROR = 'TRADEIT_REQUEST_DETAIL_LOADING_ERROR';
export const TRADEIT_REQUEST_DETAIL_RESET = 'TRADEIT_REQUEST_DETAIL_RESET';
export const TRADEIT_REQUEST_DETAIL_PRODUCTS_LOADING_ERROR =
    'TRADEIT_REQUEST_DETAIL_PRODUCTS_LOADING_ERROR';

export const LOCATION_MGMT_SHIPTO_ADD_REVIEW = 'LOCATION_MGMT_SHIPTO_ADD_REVIEW';
export const LOCATION_MGMT_SHIPTO_ADD_RESET = 'LOCATION_MGMT_SHIPTO_ADD_RESET';
export const LOCATION_MGMT_SHIPTO_ADD_SUBMITTING = 'LOCATION_MGMT_SHIPTO_ADD_SUBMITTING';
export const LOCATION_MGMT_SHIPTO_ADD_SUBMIT = 'LOCATION_MGMT_SHIPTO_ADD_SUBMIT';
export const LOCATION_MGMT_SHIPTO_ADD_ERROR = 'LOCATION_MGMT_SHIPTO_ADD_ERROR';
export const CONTRACT_SUMMARY_LOADING = 'CONTRACT_SUMMARY_LOADING';
export const CONTRACT_SUMMARY_LOADED = 'CONTRACT_SUMMARY_LOADED';
export const CONTRACT_SUMMARY_ERROR = 'CONTRACT_SUMMARY_ERROR';
export const CONTRACT_ACKNOWLEDGEMENT_SUCCEEDED = 'CONTRACT_ACKNOWLEDGEMENT_SUCCEEDED';
export const CONTRACT_ACKNOWLEDGEMENT_ERROR = 'CONTRACT_SUMMARY_ERROR';
export const CONTRACT_ACKNOWLEDGEMENT_RESET = 'CONTRACT_ACKNOWLEDGEMENT_RESET';

// added actions for orderingV2
export const ORDERING_DASHBOARD_UPDATE_VIEW = 'ORDERING_DASHBOARD_UPDATE_VIEW';
export const ORDERING_DASHBOARD_VIEW_ERROR = 'ORDERING_DASHBOARD_VIEW_ERROR';
export const ORDERING_DASHBOARD_UPDATE_RANGE = 'ORDERING_DASHBOARD_UPDATE_RANGE';
export const ORDERING_DASHBOARD_UPDATE_PALLETS = 'ORDERING_DASHBOARD_UPDATE_PALLETS';
export const ORDERING_ORDER_LOADING = 'ORDERING_ORDER_LOADING';
export const ORDERING_ORDER_LOADED = 'ORDERING_ORDER_LOADED';
export const ORDERING_ORDER_UPDATE = 'ORDERING_ORDER_UPDATE';
export const ORDERING_ORDER_LOADING_ERROR = 'ORDERING_ORDER_LOADING_ERROR';
export const ORDERING_ORDER_PRODUCTS_UPDATE = 'ORDERING_ORDER_PRODUCTS_UPDATE';
export const ORDERING_ORDER_SHIPMENTS_UPDATE = 'ORDERING_ORDER_SHIPMENTS_UPDATE';
export const ORDERING_UPDATE_DELIVERY_ORDER = 'ORDERING_UPDATE_DELIVERY_ORDER';
export const ORDERING_DASHBOARD_RESET_STATE = 'ORDERING_DASHBOARD_RESET_STATE';
export const ORDERING_DASHBOARD_CONFIG_FLAG = 'ORDERING_DASHBOARD_CONFIG_FLAG';
export const ORDERING_DASHBOARD_EXPANDABLE_LOAD_CHANGE =
    'ORDERING_DASHBOARD_EXPANDABLE_LOAD_CHANGE';
export const ORDERING_DASHBOARD_ADD_ORDER_INFORMATION = 'ORDERING_DASHBOARD_ADD_ORDER_INFORMATION';
export const ORDERING_DASHBOARD_ORDER_LOADING = 'ORDERING_DASHBOARD_ORDER_LOADING';
export const ORDERING_DASHBOARD_LOAD_PRODUCTS = 'ORDERING_DASHBOARD_LOAD_PRODUCTS';
export const ORDERING_DASHBOARD_PRODUCTS_LOADED = 'ORDERING_DASHBOARD_PRODUCTS_LOADED';
export const ORDERING_DASHBOARD_LOAD_PRODUCTS_ERROR = 'ORDERING_DASHBOARD_LOAD_PRODUCTS_ERROR';
export const CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE = 'CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE';
export const CUSTOMER_ORDERING_DASHBOARD_ERROR = 'CUSTOMER_ORDERING_DASHBOARD_ERROR';
export const CUSTOMER_ORDERING_DASHBOARD_LOADED = 'CUSTOMER_ORDERING_DASHBOARD_LOADED';
export const CUSTOMER_ORDERING_DASHBOARD_LOADING = 'CUSTOMER_ORDERING_DASHBOARD_LOADING';
export const CUSTOMER_ORDERING_DASHBOARD_RESET_STATUS = 'CUSTOMER_ORDERING_DASHBOARD_RESET_STATUS';

export const ORDERING_SUMMARIES_LOADING = 'ORDERING_SUMMARIES_LOADING';
export const ORDERING_SUMMARIES_LOADED = 'ORDERING_SUMMARIES_LOADED';
export const ORDERING_SUMMARIES_ERROR = 'ORDERING_SUMMARIES_ERROR';
export const ORDERING_SUMMARIES_RESET = 'ORDERING_SUMMARIES_RESET';
export const CUSTOMER_ORDERING_DASHBOARD_RESET = 'CUSTOMER_ORDERING_DASHBOARD_RESET';
export const CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED = 'CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED';
export const CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED = 'CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED';
export const CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED = 'CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED';
export const CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING = 'CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING';
export const CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING = 'CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING';
export const CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING = 'CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING';
export const CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_ERROR = 'CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_ERROR';
export const CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_ERROR = 'CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_ERROR';
export const CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_ERROR = 'CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_ERROR';