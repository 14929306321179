import React, { useEffect, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { BulkUploadDeliveryState, BulkUploadShipment } from '../../../store/reducers/bulk-upload';
import clsx from 'clsx';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { Trans, useTranslation } from 'react-i18next';
import { ProductToShip, ProductWithPallets } from '../../../store/reducers/shipping-dashboard';
import { useDispatch } from 'react-redux';
import EditShipmentsAddProductGrid from '../EditShipmentsConfiguration/components/EditShipmentsAddProductGrid';
import {
    loadBulkProductsWithPallets,
    updatedProductsWithPallets,
    updateRevisedShipment
} from '../../../store/actions/bulk-upload';
import ShipmentDiscardModal from '../../reusable/molecules/ShipmentDiscardModal';
import { generateKey } from '../../../utility/helpers/order-helpers';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import BulkEditShipmentConfigShipment from './components/BulkEditShipmentConfigShipment';
import { resetExpandableLoads } from '../../../store/root-actions';
import { selectIsLargeCustomer } from '../../../store/selectors';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';
import { fullATMTruckPallets, fullTruckPallets } from '../../../themes/globalConstants';
import { getFullFormattedDateTime } from '../../../utility/helpers/date-helpers';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '2.750em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    shipmentWrapper: {
        margin: '1em auto 0 auto'
    },
    addProductGrid: {
        width: '54.75em',
        margin: '0 auto 3.75em auto',
        display: 'none'
    },
    showProductGrid: {
        display: 'block'
    }
}));

export default function DeliveryBulkUploadEditShipment() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<DefaultQueryParams>();
    const { productsWithPallets, orders, productsLoading } =
        useTypedSelector<BulkUploadDeliveryState>((state) => state.bulkUploadDelivery);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [shipment, setShipmentToEdit] = useState<BulkUploadShipment>({} as BulkUploadShipment);
    const [isFormatted, setisFormatted] = useState<boolean>(false);
    const [reloadProducts, setReloadProducts] = useState<boolean>(false);
    const [productsReloaded, setProductsReloaded] = useState<boolean>(false);
    const [loadsWithoutPallets, setLoadsWithoutPallets] = useState<boolean>(false);
    const [shipToId, setShipToId] = useState<string | undefined>('');
    const [addProducts, setAddProducts] = useState<boolean>(false);
    const [discardModal, setDiscardModal] = useState<boolean>(false);
    const [validShipment, setValidShipment] = useState<boolean>(false);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);

    const editShipmentsLink = '/ship-it-bulk-upload-review';
    const shipmentAccount = shipToAccounts?.find(
        (account) => account.accountId === shipment.shipToId?.toString()
    );
    const truckPalletLimit =
        shipmentAccount?.prodOrderType === ProdOrderType.AuthorizationToManufacture
            ? fullATMTruckPallets
            : fullTruckPallets;

    const handleOpenDiscardModal = () => {
        setDiscardModal(true);
    };

    const handleCloseDiscardModal = () => {
        setDiscardModal(false);
    };

    const handleDiscardChanges = () => {
        dispatch(resetExpandableLoads());
        history.push(editShipmentsLink);
    };

    const handleSubmit = () => {
        let updatedShipment = JSON.parse(JSON.stringify(shipment)) as BulkUploadShipment;

        updatedShipment = {
            ...updatedShipment,
            loads: updatedShipment.loads?.sort((a, b) => {
                if (a.sequence && b.sequence) return a.sequence - b.sequence;
                return 0;
            })
        };
        dispatch(updateRevisedShipment(updatedShipment, id));
        dispatch(resetExpandableLoads());
        history.push(editShipmentsLink);
    };

    const getOrdersWithUpdatedShipment = () => {
        let ordersToEdit = JSON.parse(JSON.stringify(orders));
        let orderIndex = ordersToEdit?.findIndex(
            (order) => order?.shipToId?.toString() === shipToId
        );
        let shipmentsOrder = orders?.find((order) => order?.shipToId?.toString() === shipToId);
        let shipmentIndex = shipmentsOrder?.shipments?.findIndex(
            (order) => order.bulkShipmentId === parseInt(id)
        );
        if (orderIndex !== -1 && shipmentIndex !== -1 && shipmentIndex !== undefined) {
            ordersToEdit[orderIndex].shipments[shipmentIndex] = shipment;
        }
        return ordersToEdit;
    };

    const handleDateUpdate = (newDateTime: string) => {
        shipment.deliveryDateTime = newDateTime;
        shipment.deliveryTime = moment(newDateTime).format('hh:mm A');
        setReloadProducts(true);
    };

    const handleAddProductToShipment = (product: ProductWithPallets) => {
        let currentShipment = JSON.parse(JSON.stringify(shipment)) as BulkUploadShipment;
        const currentLoads = currentShipment.loads as ProductToShip[];

        if (currentLoads && currentLoads.length) {
            let itemIndex = currentLoads.findIndex(
                (load) => load.productSku === product.productSku
            );
            if (itemIndex === -1) {
                let newSequence = 0;
                if (currentShipment.loads) {
                    newSequence =
                        currentShipment.loads[currentShipment.loads.length - 1].sequence! + 1;
                }

                const newLoads = [
                    ...currentLoads,
                    {
                        ...product,
                        palletQuantity: 0,
                        sequence: newSequence,
                        isCustomerProductIdDistinct: true
                    }
                ].sort((a, b) => {
                    if (a.sequence && b.sequence) return a.sequence - b.sequence;
                    return 0;
                });

                setShipmentToEdit({
                    ...currentShipment,
                    loads: newLoads,
                    shipmentType: product.type
                });
            }
        } else {
            const newLoad = [{ ...product, palletQuantity: 0, sequence: 1 }];
            setShipmentToEdit({
                ...currentShipment,
                loads: newLoad,
                shipmentType: product.type
            });
        }
    };

    const handleUpdatePallets = (
        product: ProductToShip,
        numOfPallets: number,
        palletDifference?: number
    ) => {
        let currentProduct = {
            ...product,
            availablePallets: product.availablePallets,
            availableItemsPerPallet: product.availableItemsPerPallet
        };

        //Get the index on productsToEdit to get pristine pallet data
        let productIndex = productsWithPallets.findIndex(
            (editProduct) => editProduct.productSku === currentProduct.productSku
        );

        if (productIndex !== -1 && palletDifference) {
            //Calculate using pristine pallets from productsToEdit
            const availablePallets = currentProduct.availablePallets
                ? currentProduct.availablePallets
                : 0;
            let newPalletTotal = 0;

            shipment.loads?.forEach((load) => {
                if (load.sequence === product.sequence) {
                    newPalletTotal += numOfPallets;
                } else {
                    newPalletTotal += load.palletQuantity;
                }
            });

            let isAddingPallets = palletDifference < 0;
            let isRemovingPallets = palletDifference > 0;
            let hasEnoughRemainingPallets = product.availablePallets! >= Math.abs(palletDifference);

            if (
                (newPalletTotal <= truckPalletLimit || shipment.shipmentType === 'END') &&
                palletDifference &&
                (isAddingPallets ? hasEnoughRemainingPallets : isRemovingPallets)
            ) {
                shipment.palletCount = newPalletTotal;
                const newAvailablePallets = availablePallets + palletDifference;
                setPalletsForProduct(product, newAvailablePallets);
            }
        }
    };

    const setPalletsForProduct = (product: ProductToShip, newAvailablePallets: number) => {
        let currentShipment = JSON.parse(JSON.stringify(shipment));
        const currentLoads = currentShipment.loads as ProductToShip[];

        if (currentLoads && currentLoads.length) {
            currentLoads.forEach((load) => {
                if (load.productSku === product.productSku) {
                    load.availablePallets = newAvailablePallets;
                }
            });
            setShipmentToEdit({ ...currentShipment });
        }
    };

    const handleShowProductGrid = () => {
        setAddProducts(true);
    };

    const handleUpdateShipment = (shipment: BulkUploadShipment) => {
        setShipmentToEdit(shipment);
    };

    const deleteProductUpdatePallets = (product: ProductToShip) => {
        let productToUpdate = productsWithPallets.find(
            (item) => item.productSku === product.productSku
        );

        let availablePallets = product.availablePallets ? product.availablePallets : 0;

        if (productToUpdate) {
            productToUpdate.availablePallets = availablePallets + product.palletQuantity;
        }
    };

    const formatShipment = (shipmentToFormat: BulkUploadShipment) => {
        if (shipmentToFormat.loads) {
            shipmentToFormat.loads.forEach((product) => {
                let productWithPallets = productsWithPallets.find(
                    (item) => item.productSku === product.productSku
                );

                product.availableItemsPerPallet = productWithPallets?.quantityPerPallet;
                product.availablePallets = productWithPallets?.availablePallets;
                product.reference = product.referenceNumber;

                if (product.destinations) {
                    product.destinations?.forEach((destination) => {
                        if (destination.shipToId.toString() === shipToId) {
                            product.customerProductId = destination.customerProductId;
                            product.customerProductName = destination.customerProductName;
                        }
                    });
                }
                product.displayId = product.customerProductId
                    ? product.customerProductId
                    : product.productSku;
                product.displayName = product.customerProductName
                    ? product.customerProductName
                    : product.name;
            });
            setShipmentToEdit(shipmentToFormat);
            setisFormatted(true);
        }
    };

    const isUniqueDateTime = (time: string, shipToId: number, currentShipmentId: number) => {
        let isUnique = true;
        orders?.forEach((order) => {
            if (order.shipToId === shipToId && order.shipments) {
                for (
                    let shipmentIndex = 0;
                    shipmentIndex < order.shipments.length;
                    shipmentIndex++
                ) {
                    let dateToCompare = getFullFormattedDateTime(
                        moment(order.shipments[shipmentIndex].deliveryDateTime).format()
                    );
                    let shipmentToCompare = order.shipments[shipmentIndex].bulkShipmentId;
                    if (dateToCompare === time && currentShipmentId !== shipmentToCompare) {
                        isUnique = false;
                    }
                }
            }
        });
        return isUnique;
    };

    const checkAvailablePallets = () => {
        let shipmentsOrder = JSON.parse(JSON.stringify(shipment));
        if (shipmentsOrder) {
            //If Shipment Order has products(loads)
            if (shipment.loads) {
                shipment.loads.map((productInShipment) => {
                    //product shipment index
                    const productIndex = shipment!.loads!.findIndex((load) =>
                        load !== undefined
                            ? load.productSku === productInShipment.productSku
                            : false
                    );

                    if (productIndex !== -1 && productIndex !== undefined) {
                        const desiredPallets = productInShipment.palletQuantity;
                        const subtractedPallets = productInShipment.availablePallets;
                        const availablePallets = subtractedPallets! + desiredPallets;

                        if (availablePallets >= 0 && desiredPallets) {
                            switch (true) {
                                case desiredPallets > availablePallets:
                                    //Set desiredPallets to equal number of pallets available that day
                                    shipmentsOrder.loads![productIndex].palletQuantity =
                                        availablePallets;
                                    shipmentsOrder.loads![productIndex].availablePallets = 0;
                                    let palletToUpdate = productsWithPallets.find(
                                        (product) =>
                                            product.productSku === productInShipment.productSku
                                    );
                                    if (palletToUpdate) {
                                        palletToUpdate.availablePallets = 0;
                                    }
                                    break;

                                case desiredPallets <= availablePallets:
                                    shipmentsOrder.loads![productIndex].availablePallets =
                                        subtractedPallets;
                                    break;

                                case availablePallets === 0:
                                    shipmentsOrder.loads![productIndex].availablePallets = 0;
                                    break;
                            }
                        } else if (availablePallets && availablePallets < 0 && shipment.loads) {
                            // Flag product for removal from shipment
                            shipmentsOrder.loads[productIndex] = undefined;
                        }
                    }
                    return null;
                });
            }
        }

        let filteredLoads = shipmentsOrder.loads.filter((load) => load !== undefined);
        shipmentsOrder.loads = filteredLoads;

        setShipmentToEdit(shipmentsOrder as BulkUploadShipment);

        let newPalletTotal = 0;
        shipment.loads?.forEach((load) => {
            newPalletTotal += load.palletQuantity;
        });

        if (newPalletTotal <= truckPalletLimit) {
            shipment.palletCount = newPalletTotal;
        }

        //Filter through products, remove them if there are none available on provided delivery date
        let filteredProducts = productsWithPallets.filter(
            (product) => product.availablePallets !== undefined && product.availablePallets >= 0
        );
        //We are removing the dependency of ProductionOrders and this should be removed
        //dispatch(updatedProductsWithPallets(filteredProducts));
    };

    useEffect(() => {
        if (shipment.loads) {
            let loadsWithoutPallets = shipment.loads.filter((load) => load.palletQuantity === 0);
            setLoadsWithoutPallets(loadsWithoutPallets.length > 0 ? true : false);
        }
    }, [shipment]);

    useEffect(() => {
        if (productsReloaded && !productsLoading) {
            formatShipment(shipment);
            checkAvailablePallets();
            setProductsReloaded(false);
        }
    }, [productsReloaded, productsLoading]);

    useEffect(() => {
        if (reloadProducts) {
            let ordersToEdit = getOrdersWithUpdatedShipment();
            if (ordersToEdit) {
                dispatch(
                    loadBulkProductsWithPallets(shipToId, shipment.deliveryDateTime, ordersToEdit)
                );
            }
            setReloadProducts(false);
            setProductsReloaded(true);
        }
    }, [reloadProducts]);

    useEffect(() => {
        if (!isFormatted && productsWithPallets.length) {
            formatShipment(shipment);
        }
    }, [productsWithPallets]);

    useEffect(() => {
        if (shipToId) {
            dispatch(loadBulkProductsWithPallets(shipToId, shipment.deliveryDateTime, orders));
        }
    }, [shipToId]);

    useEffect(() => {
        let tempOrders = JSON.parse(JSON.stringify(orders));
        tempOrders!.forEach((order) => {
            if (order.shipments) {
                let filteredShipment = order.shipments.find(
                    (shipment) => shipment.bulkShipmentId.toString() === id
                );
                if (filteredShipment) {
                    setShipToId(filteredShipment.shipToId?.toString());
                    setShipmentToEdit(filteredShipment);
                }
            }
        });
    }, [orders]);

    const footerActions = (
        <>
            <Grid container item xs={4} className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="discard-btn"
                    className={classes.actionBtn}
                    onClick={handleOpenDiscardModal}
                >
                    <Trans i18nKey="discardChanges">Discard Changes</Trans>
                </Button>
            </Grid>
            <Grid container item xs={4} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="save-btn"
                    className={classes.actionBtn}
                    onClick={handleSubmit}
                    disabled={!validShipment || productsLoading || loadsWithoutPallets}
                >
                    <Trans i18nKey="saveChanges">Save Changes</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('bulkUpload', 'Bulk Upload'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.ShipItBulkUpload}
            loading={productsLoading}
        >
            {isFormatted && (
                <Grid container spacing={2} className={classes.main}>
                    <Grid item className={classes.shipmentWrapper}>
                        <BulkEditShipmentConfigShipment
                            shipment={shipment}
                            onShowProductGrid={handleShowProductGrid}
                            onUpdateShipment={handleUpdateShipment}
                            onDateUpdate={handleDateUpdate}
                            onUpdatePallets={handleUpdatePallets}
                            isUniqueDateTime={isUniqueDateTime}
                            isValidShipment={setValidShipment}
                            deleteProductUpdatePallets={deleteProductUpdatePallets}
                            data-testid={'edit-shipment-config'}
                            isLargeCustomer={isLargeCustomer}
                            truckPalletLimit={truckPalletLimit}
                        />
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.addProductGrid, {
                            [classes.showProductGrid]: addProducts
                        })}
                        key={generateKey('add-product-grid')}
                    >
                        <EditShipmentsAddProductGrid
                            shipItItems={productsWithPallets as ProductWithPallets[]}
                            onAddItem={handleAddProductToShipment}
                            shipment={shipment}
                            isBulkUpload={true}
                            data-testid={'add-product-grid'}
                        />
                    </Grid>
                    <ShipmentDiscardModal
                        open={discardModal}
                        onDiscardChanges={handleDiscardChanges}
                        onClose={handleCloseDiscardModal}
                        onCancel={handleCloseDiscardModal}
                        data-testid={'edit-shipment-discard-modal'}
                    />
                </Grid>
            )}
        </ProcessingPageTemplate>
    );
}
