import React from 'react';
import { Grid, Button, Link, makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballBlue, boldWeight, red, small } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    loadText: {
        color: ballBlue,
        fontWeight: boldWeight,
        textDecoration: 'underline',
        textAlign: 'left',
        display: 'none'
    },
    errorText: {
        color: red,
        fontSize: small
    }
}));

interface ShipmentFooterActionsProps {
    isEditShipment: boolean;
    shipmentErrorMessage?: string;
    onDeleteShipment: () => void;
    onSaveShipment: () => void;
    onEditShipment: () => void;
}

const ShipmentFooterActions: React.FC<ShipmentFooterActionsProps> = ({
    isEditShipment,
    shipmentErrorMessage = '',
    onDeleteShipment,
    onSaveShipment,
    onEditShipment
}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} alignItems="center" justify="space-between">
            <Grid item xs={4}>
                {isEditShipment && (
                    <Link href="#" className={classes.loadText}>
                        Change Load <br /> Sequence
                    </Link>
                )}
            </Grid>
            <Grid item xs={4}>
                <Typography className={classes.errorText}>{shipmentErrorMessage}</Typography>
            </Grid>
            <Grid container item xs={4} spacing={1} justify="flex-end">
                {isEditShipment ? (
                    <>
                        <Grid item>
                            <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                data-testid="delete-shipment-btn"
                                onClick={onDeleteShipment}
                            >
                                <Trans i18nKey="delete">Delete</Trans>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                size="small"
                                data-testid="done-btn"
                                onClick={onSaveShipment}
                            >
                                <Trans i18nKey="save">Save</Trans>
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <Grid item>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={onEditShipment}
                            disabled={isEditShipment}
                        >
                            <Trans i18nKey="edit">Edit</Trans>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ShipmentFooterActions;
