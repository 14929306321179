import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
    Button,
    FormControlLabel,
    Grid,
    makeStyles,
    Paper,
    Switch,
    Typography
} from '@material-ui/core';

import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { ballBlue, containerMaxWidth } from '../../../themes/globalConstants';
import NewAccountSelection from '../../reusable/molecules/NewAccountSelection';
import ReviewOrderSummaryTabs from './components/ReviewOrdersSummaryTabs';
import {
    loadOrderingSummariesInformation,
    resetOrderingSummaries
} from '../../../store/root-actions';

const useStyles = makeStyles((theme) => ({
    btnWrapper: {
        marginTop: theme.spacing(2)
    },
    actionBtn: {
        margin: theme.spacing(0.5)
    },
    rootPaper: {
        width: '100%',
        padding: '2em',
        marginTop: '3em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    label: {
        textTransform: 'uppercase',
        color: ballBlue
    }
}));

const createOrderLink = '/create-order';
// TODO: This will be changed in the future to the correct url
//const bulkUploadLink = '/bulk-upload-orders';
const bulkUploadLink = '/ship-it-bulk-upload';

export default function ReviewOrders() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const [toggleOpenOrders, setToggleOpenOrders] = useState<boolean>(true);

    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { selectedChildrenAccountId, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    useEffect(() => {
        dispatch(resetOrderingSummaries());
        dispatch(
            loadOrderingSummariesInformation(
                selectedAccountId,
                selectedChildrenAccountId,
                toggleOpenOrders
            )
        );
    }, [dispatch, toggleOpenOrders, selectedChildrenAccountId, selectedAccountId]);

    const handleBulkOrder = () => {
        history.push(bulkUploadLink);
    };

    const handleCreateOrder = () => {
        history.push(createOrderLink);
    };

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('reviewOrders', 'Orders'),
                description: '',
                thinBanner: true,
                billToDropdown: true,
                disableSelect: false,
                hasChildrenSelection: true,
                displayDropdown: true
            }}
            activity={Activity.ReviewOrders}
        >
            <Grid container>
                <Paper elevation={2} className={classes.rootPaper}>
                    <Grid container spacing={2} justify="space-between">
                        <Grid container item xs={4}>
                            <NewAccountSelection
                                selectionType="shipTo"
                                disableSelect={false}
                                isChildrenSelection={true}
                            />
                        </Grid>
                        <Grid container item xs={4} alignItems="center" justify="center">
                            <FormControlLabel
                                labelPlacement="start"
                                name="toggleOpenOrders"
                                control={
                                    <Switch
                                        onChange={(e) => {
                                            setToggleOpenOrders(e.target.checked);
                                        }}
                                        color="primary"
                                        checked={toggleOpenOrders}
                                    />
                                }
                                label={
                                    <Typography className={classes.label}>
                                        Current View contains all orders Toggle to view open orders
                                        only
                                    </Typography>
                                }
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={4}
                            spacing={2}
                            alignItems="center"
                            justify="flex-end"
                        >
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleBulkOrder}
                                    className={classes.actionBtn}
                                >
                                    <Trans i18nKey="bulkUpload">Bulk Upload</Trans>
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleCreateOrder}
                                    className={classes.actionBtn}
                                >
                                    <Trans i18nKey="createOrder">Create Order</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <ReviewOrderSummaryTabs />
            </Grid>
        </ProcessingPageTemplate>
    );
}
