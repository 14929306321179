import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setAuth,
    updateRegionCulture,
    setAccessToken,
    getPermissions
} from '../../store/root-actions';
import { useOktaAuth } from '@okta/okta-react';
import { useTypedSelector } from '../../store/reducers/reducer';
import * as actions from '../../store/actions/action-types';
import IdleTimer from 'react-idle-timer';
import React from 'react';
import { AuthState } from '../../store/reducers/auth';
import { Region } from '../../store/reducers/graphic-intake';
import { Culture, RegionCultureState } from '../../store/reducers/region-culture';
import { logout } from '../../store/root-actions';
import { loadState } from '../../utility/helpers/localStorage';
import { loadCommunicationMessagesData } from '../../store/actions/communications';
import { getUserConfiguration } from '../../store/actions/user-configuration';

export const AuthHandler = () => {
    const dispatch = useDispatch();
    const { authState, authService } = useOktaAuth();
    const { userInfo, accessToken, authenticated, hasAuthenticated } = useTypedSelector<AuthState>(
        (state) => state.auth
    );
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );

    const fetchPermissions = (user: UserInfo | null) => {
        dispatch(getPermissions(user, authService));
    };

    const fetchCommunications = () => {
        dispatch(loadCommunicationMessagesData());
    };

    const fetchConfigurations = () => {
        dispatch(getUserConfiguration());
    };

    // Check every two minutes if token is out of date
    useEffect(() => {
        const interval = setInterval(() => {
            if (authState.isAuthenticated) {
                authService.updateAuthState();
                fetchPermissions(userInfo);
            }
        }, 1000 * 60 * 2);
        return () => clearInterval(interval);
    }, []);

    // Check every five seconds that token is still present in local storage
    // This will ensure automatic logout if another tab has logged out
    useEffect(() => {
        const interval = setInterval(() => {
            const oktaToken = loadState('okta-token-storage');
            if (!oktaToken?.idToken) {
                clearInterval(interval);
                dispatch(logout(authService));
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [dispatch]);

    // Automatically logout if user loses authentication or access token for API requests
    useEffect(() => {
        if (hasAuthenticated && !(authenticated && accessToken)) {
            dispatch(logout(authService));
        }
    }, [authenticated, hasAuthenticated, accessToken]);

    useEffect(() => {
        if (authState.isAuthenticated) {
            // immediately set access token
            dispatch(setAccessToken(authState.accessToken));

            // get communications
            fetchCommunications();

            // get user configurations
            fetchConfigurations();

            // only get new userInfo if we dont already have it
            if (!userInfo) {
                authService.getUser().then((user: UserInfo) => {
                    // only update regionCulture if local state does not exist or it's out of sync with user info
                    if (
                        regionCode !== (user?.Custom_Region || Region.NA) ||
                        cultureCode !== (user?.Custom_Language || Culture.enUS)
                    ) {
                        const newRegion = user.Custom_Region
                            ? user.Custom_Region.toUpperCase()
                            : Region.NA;
                        const newCulture = user?.Custom_Language || Culture.enUS;

                        dispatch(
                            updateRegionCulture(actions.UPDATE_REGION_CULTURE, {
                                region: newRegion,
                                culture: newCulture
                            })
                        );
                    }

                    dispatch(setAuth(authState.isAuthenticated, user, authState.accessToken));
                    fetchPermissions(user);
                });
            }
        } else {
            dispatch(setAuth(false, null, ''));
        }
    }, [authService, authState.isAuthenticated, authState.accessToken, dispatch]);

    const onIdle = () => {
        authService.logout('/source');
    };

    /* Example:
    /* 1000 milliseconds * 60 => 1 minute
    /* 60000 milliseconds * 120 => 2 hours
    /* Total timeout value is 2 hours */

    const minuteInMilliseconds = 1000 * 60;
    const idleTimeoutInMinutes =
        `${process.env.REACT_APP_IDLE_TIMEOUT}` === 'undefined'
            ? '120'
            : `${process.env.REACT_APP_IDLE_TIMEOUT}`;
    const totalIdleTime = minuteInMilliseconds * Number(idleTimeoutInMinutes);

    return <IdleTimer onIdle={onIdle} timeout={totalIdleTime}></IdleTimer>;
};
