import React, { useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Typography, makeStyles, Grid, Menu, MenuItem } from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';
import { AuthHandler } from '../../../utility/auth/auth-handler';
import LogoutLink from './LogoutLink';
import { medium, boldWeight } from '../../../themes/globalConstants';
import {
    UPDATE_CULTURE,
    UPDATE_REGION,
    UPDATE_DATEFORMAT
} from '../../../store/actions/action-types';
import HeaderDropdown from '../atoms/HeaderDropdown';
import { createAxiosHeader } from '../../../utility/helpers/axios-helpers';
import { getLanguageCode } from '../../../utility/helpers/user-helpers';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import UserService from '../../../utility/services/user-service';
import { HeaderContent } from '../../../store/reducers/header-content';
import { updateRegionCulture, productPortfolioClearFacets } from '../../../store/root-actions';
import { logout } from '../../../store/root-actions';
import MyAccountLink from './MyAccountLink';
import { Region } from '../../../store/reducers/graphic-intake';
import { dateFormats } from '../../../static-data/dropdown-lists';
import { saveUserConfiguration } from '../../../store/actions/user-configuration';
import { UserConfigurationState } from '../../../store/reducers/user-configuration';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 0
    },
    name: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: 1.3,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        paddingRight: '0.5em'
    },
    namePopup: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: 1.3,
        textTransform: 'uppercase',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        paddingTop: '1em',
        paddingBottom: '1em',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    },
    menuNameBar: {
        border: `2px solid ${theme.palette.info.light}`,
        marginBottom: '1.5em'
    },
    navWrapper: {
        '&:focus': {
            outline: 'none'
        }
    },
    menuLink: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        marginBottom: '.5em',
        marginTop: '.5em'
    },
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '50em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    }
}));

const ProfileMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    const history = useHistory();
    const { accessToken, userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { authService } = useOktaAuth();
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const userConfiguration = useTypedSelector<UserConfigurationState>(
        (state) => state.userConfiguration
    );
    const headerContent = useTypedSelector<HeaderContent>((state) => state.headerContent);
    const auth = useTypedSelector<AuthState>((state) => state.auth);

    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(logout(authService, auth.accessToken));
    };

    const changeRegionCulture = (type: any, value: any) => {
        const axiosConfig: AxiosRequestConfig = createAxiosHeader(
            regionCulture.cultureCode,
            regionCulture.regionCode,
            accessToken
        );
        let newUserDetails = {};
        switch (type) {
            case UPDATE_CULTURE:
                // change language/culture
                dispatch(updateRegionCulture(type, value));
                // Update User Information
                if (value !== regionCulture.cultureCode) {
                    newUserDetails = { language: value };
                    UserService.update(userInfo as UserInfo, newUserDetails, axiosConfig);

                    // Update user Configuration
                    dispatch(saveUserConfiguration({ cultureCode: value }));
                }
                break;

            case UPDATE_REGION:
                // change region
                dispatch(updateRegionCulture(type, value));
                // clear facets
                dispatch(productPortfolioClearFacets());
                // Update User Information
                if (value !== regionCulture.regionCode) {
                    let culture = getLanguageCode(value);
                    newUserDetails = { region: value, language: culture };
                    UserService.update(userInfo as UserInfo, newUserDetails, axiosConfig);

                    // Update user Configuration
                    dispatch(
                        saveUserConfiguration({
                            regionCode: value,
                            cultureCode: culture
                        })
                    );
                }
                // go home
                history.push('/');
                break;

            case UPDATE_DATEFORMAT:
                // change date format
                dispatch(updateRegionCulture(type, value));

                // Update Configuration
                if (value !== userConfiguration.dateFormatCode) {
                    dispatch(saveUserConfiguration({ dateFormatCode: value }));
                }
                break;

            default:
                break;
        }
    };

    const onMyAccount = () => {
        history.push('/my-account-dashboard');
    };

    return (
        <>
            <AuthHandler />
            <Button
                onClick={handleClick}
                aria-haspopup="true"
                className={classes.button}
                data-testid="user-menu"
            >
                <Typography
                    variant="h6"
                    component="h1"
                    aria-label="name"
                    className={classes.name}
                    data-testid="user-name"
                >
                    {userInfo?.name}
                </Typography>
                <PersonOutlineIcon color="primary" />
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <section role="navigation" className={classes.navWrapper}>
                    <MenuItem onClick={handleClose} className={classes.namePopup}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>{userInfo?.name}</Grid>
                            <Grid item>
                                <PersonOutlineIcon />
                            </Grid>
                        </Grid>
                    </MenuItem>
                    {regionCulture.regionCode === Region.NA && (
                        <MenuItem onClick={onMyAccount}>
                            <MyAccountLink className={classes.menuLink} />
                        </MenuItem>
                    )}
                    <HeaderDropdown
                        label={headerContent.content.regionLabel}
                        dropdownContent={regionCulture.regions}
                        defaultValue={regionCulture.regionCode}
                        actionType={UPDATE_REGION}
                        aria={'region'}
                        onChange={changeRegionCulture}
                    />
                    <HeaderDropdown
                        label={headerContent.content.languageLabel}
                        dropdownContent={regionCulture.cultures}
                        defaultValue={regionCulture.cultureCode}
                        actionType={UPDATE_CULTURE}
                        aria={'language'}
                        onChange={changeRegionCulture}
                    />
                    <HeaderDropdown
                        label={headerContent.content.dateFormatLabel}
                        dropdownContent={dateFormats}
                        defaultValue={userConfiguration.dateFormatCode}
                        actionType={UPDATE_DATEFORMAT}
                        aria={'dateFormatCode'}
                        onChange={changeRegionCulture}
                    />
                    <MenuItem onClick={handleLogout}>
                        <LogoutLink className={classes.menuLink} position="menu" />
                    </MenuItem>
                </section>
            </Menu>
        </>
    );
};

export default ProfileMenu;
