import {
    Paper,
    Grid,
    Typography,
    makeStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
    GridSize
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { DeliveryShipment, OrderInformation } from '../../../../store/reducers/ordering-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import {
    selectChildrenAccountId,
    updateOrderingV2Flag
} from '../../../../store/actions/customer-context';
import NewAccountSelection from '../../../reusable/molecules/NewAccountSelection';
import ShipmentDetailSummaryGrid from './ShipmentDetailSummaryGrid';
import { selectDateTimeFormat } from '../../../../store/selectors';
import { boldWeight, medium, small } from '../../../../themes/globalConstants';
import ShipmentDetail from '../../../reusable/molecules/ShipmentDetail';
import { FlagTypes, useFeatureFlag } from '../../../../utility/helpers/feature-flag';

interface Props {
    orderInformation?: OrderInformation;
    shipment: DeliveryShipment;
    index: number;
    total: number;
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '0.2em',
        padding: '0.5em 0.75em'
    },
    shipmentNumberText: {
        fontWeight: boldWeight,
        textTransform: 'uppercase'
    },
    tableText: {
        fontSize: small,
        textTransform: 'uppercase'
    },
    infoText: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    tableWrapper: {
        '& .MuiTableCell-root': {
            border: 0
        }
    }
}));

export default function ShipmentSummaryCard({ orderInformation, shipment, index, total }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dateTimeFormat = useTypedSelector(selectDateTimeFormat);

    const [showShipFrom, setShowShipFrom] = useState<boolean>(false);
    const [columnWidth, setColumnWidth] = useState<GridSize>(3);

    const { selectedChildrenAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const globalOrderingV2Flag = useFeatureFlag().find(
        (flag) => flag.name === FlagTypes.OrderingV2
    )?.isActive;

    const formattedDeliveryDate = useMemo(() => {
        if (orderInformation?.deliveryDate) {
            const date = moment(orderInformation.deliveryDate);
            return date.format(dateTimeFormat);
        }
        return '';
    }, [orderInformation?.deliveryDate, dateTimeFormat]);

    useEffect(() => {
        if (orderInformation && orderInformation.shipToId) {
            if (
                !selectedChildrenAccountId ||
                selectedChildrenAccountId !== orderInformation.shipToId
            ) {
                dispatch(selectChildrenAccountId(orderInformation.shipToId));
                dispatch(updateOrderingV2Flag(globalOrderingV2Flag));
            }
        }
    }, [dispatch, selectedChildrenAccountId, orderInformation, globalOrderingV2Flag]);

    // TODO: Create logic to determine if Ship From should be shown
    useEffect(() => {
        //setShowShipFrom(true);
    }, [orderInformation]);

    useEffect(() => {
        if (showShipFrom) {
            setColumnWidth(3);
        } else {
            setColumnWidth(4);
        }
    }, [showShipFrom]);

    return (
        <Grid item xs={12}>
            <Paper elevation={2}>
                <Grid
                    container
                    xs={12}
                    spacing={2}
                    className={classes.container}
                    justify="space-between"
                >
                    <Grid
                        container
                        item
                        xs={2}
                        direction="column"
                        alignContent="flex-start"
                        alignItems="center"
                    >
                        <Grid>
                            <Typography color="primary" className={classes.shipmentNumberText}>
                                <Trans i18nKey="shipment">Shipment</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="primary" className={classes.shipmentNumberText}>
                                {index + 1} <Trans i18nKey="of">of</Trans> {total}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={10} justify="space-between">
                        {showShipFrom && (
                            <Grid item xs={columnWidth}>
                                <NewAccountSelection
                                    disableSelect
                                    hasChildrenSelection
                                    selectionType="soldTo"
                                    reverseBackground
                                    alternateLabel={t('shipFrom', 'Ship From')}
                                />
                            </Grid>
                        )}
                        <Grid item xs={columnWidth}>
                            <NewAccountSelection
                                disableSelect
                                isChildrenSelection
                                selectionType="shipTo"
                                reverseBackground
                            />
                        </Grid>
                        <Grid item xs={columnWidth}>
                            <ShipmentDetail shipment={shipment} layout="vertical" />
                        </Grid>
                        <Grid item xs={columnWidth}>
                            <Table padding="none" size="small" className={classes.tableWrapper}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">
                                            <Typography
                                                variant="body1"
                                                className={classes.tableText}
                                            >
                                                <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="body1"
                                                className={classes.infoText}
                                            >
                                                {formattedDeliveryDate ?? '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right">
                                            <Typography
                                                variant="body1"
                                                className={classes.tableText}
                                            >
                                                <Trans i18nKey="poNumber">PO Number</Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="body1"
                                                className={classes.infoText}
                                            >
                                                {orderInformation?.poNumber ?? '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="right">
                                            <Typography
                                                variant="body1"
                                                className={classes.tableText}
                                            >
                                                <Trans i18nKey="deliveryInstructions">
                                                    Delivery Instructions
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                variant="body1"
                                                className={classes.infoText}
                                            >
                                                {orderInformation?.deliveryInstructions ?? '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
                {shipment.loads && shipment.loads.length > 0 && (
                    <ShipmentDetailSummaryGrid loads={shipment.loads} />
                )}
            </Paper>
        </Grid>
    );
}
