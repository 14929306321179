import React from 'react';
import { Grid, Typography, makeStyles, Link, Paper } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { boldWeight, medium, ballGray, black_5 } from '../../../../../themes/globalConstants';
import Image from '../../../../reusable/atoms/Image';
import { enUS } from '../../../../../utility/translations/locales';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em'
    },
    wrapper: {
        color: theme.palette.secondary.main,
        '& svg': {
            width: '1em',
            marginRight: '0.25em'
        }
    },
    header: {
        fontWeight: boldWeight,
        marginBottom: '1em'
    },
    subHeader: {
        color: ballGray,
        fontWeight: boldWeight,
        marginBottom: '.5em',
        textTransform: 'uppercase'
    },
    subHeaderDetails: {
        color: black_5,
        fontWeight: boldWeight,
        marginBottom: '1em'
    },
    detailsWrapper: {
        alignItems: 'flex-start'
    },
    iconTextWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginBottom: '0.625em',
        maxHeight: '2.5em'
    },
    image: {
        height: '9em',
        backgroundSize: 'contain'
    },
    iconContainer: {
        marginTop: '1em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    },
    linkDivider: {
        fontWeight: boldWeight,
        margin: '0 1em'
    },
    subContainer: {
        paddingRight: '2em'
    }
}));

export default function UserAdminWidget() {
    const classes = useStyles();
    useTranslation();

    const userAdminIcon = `${process.env.PUBLIC_URL}/assets/User_Admin_Icon.svg`;
    const userAdminIconText = 'User Admin Icon';
    const addNewUserLink = '/add-new-user';
    const adminDashboardLink = '/user-admin-dashboard';

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid container item alignItems="flex-start" data-testid="user-admin-widget">
                <Grid item xs={12} className={classes.iconContainer}>
                    <Image src={userAdminIcon} alt={userAdminIconText} className={classes.icon} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.header}>
                        <Trans i18nKey="userAdministration">User Administration</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item>
                        <Grid item xs={4} className={classes.subContainer}>
                            <Typography variant="subtitle1" className={classes.subHeader}>
                                <Trans i18nKey="addNewUser">${enUS.addNewUser}</Trans>
                            </Typography>
                            <Typography className={classes.subHeaderDetails}>
                                <Trans i18nKey="setupNewUser">${enUS.setupNewUser}</Trans>
                            </Typography>
                            <Link
                                to={addNewUserLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="add-new-user-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="addNewUser">${enUS.addNewUser}</Trans>
                            </Link>
                        </Grid>
                        <Grid item xs={4} className={classes.subContainer}>
                            <Typography variant="subtitle1" className={classes.subHeader}>
                                <Trans i18nKey="userAdminDashboard">
                                    ${enUS.userAdminDashboard}
                                </Trans>
                            </Typography>
                            <Typography className={classes.subHeaderDetails}>
                                <Trans i18nKey="viewPendingRequests">
                                    ${enUS.viewPendingRequests}
                                </Trans>
                            </Typography>
                            <Link
                                to={adminDashboardLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="admin-dashboard-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="viewUserAdminDashboard">
                                    ${enUS.viewUserAdminDashboard}
                                </Trans>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
