import React, { ReactNode } from 'react';

export enum FlagTypes {
    GraphicsSummary = 'GraphicsSummary',
    KBMax = 'KBMax',
    Coke = 'Coke',
    MakeItBulkUpload = 'MakeItBulkUpload',
    Playground = 'Playground',
    Liquids = 'Liquids',
    HideEdit = 'HideEdit',
    InternalPasswordReset = 'InternalPasswordReset',
    TradeIt = 'TradeIt',
    TradeItSummary = 'TradeItSummary',
    ScrapIt = 'ScrapIt',
    ProductIdManagement = 'ProductIdManagement',
    AvailableToTrade = 'AvailableToTrade',
    ActivateProduct = 'ActivateProduct',
    DeactivateProduct = 'DeactivateProduct',
    EditProductName = 'EditProductName',
    ManageLocations = 'ManageLocations',
    GraphicsFileOptional = 'GraphicsFileOptional',
    ProductAlerts = 'ProductAlerts',
    UnrecognizedProductsTab = 'UnrecognizedProductsTab',
    OrderingV2 = 'OrderingV2',
}

export interface FlagState {
    name: string;
    isActive: boolean;
}

export interface ProviderProps {
    children: ReactNode;
    flags: FlagState[];
}

export interface FlagProps {
    children?: ReactNode;
    authorizedFlags: string[];
    exactFlags?: boolean;
    renderOn?(): any;
    renderOff?(): any;
}

const FlagsContext = React.createContext({} as FlagState[]);

function Flags(props: FlagProps) {
    const {
        children = null,
        authorizedFlags,
        exactFlags = false,
        renderOn = () => null,
        renderOff = () => null
    } = props;

    const matchingFlags = (flags) => {
        return flags.filter((flag) => {
            return flag.isActive && authorizedFlags.includes(flag.name);
        });
    };

    const resolveRender = (matchingFlags) => {
        const value = children && children ? children : renderOn();
        return value;
    };

    return (
        <FlagsContext.Consumer>
            {(flags) => {
                const matchings = matchingFlags(flags);
                if (exactFlags) {
                    return matchings.length === authorizedFlags.length
                        ? resolveRender(matchings)
                        : renderOff();
                } else {
                    return matchings.length ? resolveRender(matchings) : renderOff();
                }
            }}
        </FlagsContext.Consumer>
    );
}

function FlagsProvider(props: ProviderProps) {
    const { children, flags } = props;

    return <FlagsContext.Provider value={flags}> {children} </FlagsContext.Provider>;
}

function GetFeatureFlag(desiredFlag: FlagTypes) {
    const featureFlag = useFeatureFlag().find((flag) => flag.name === desiredFlag)?.isActive;
    return featureFlag;
}

const useFeatureFlag = () => React.useContext(FlagsContext);

export { FlagsProvider, Flags, useFeatureFlag, GetFeatureFlag };
