import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';
import {
    ballGray,
    ballBlue,
    ballLtGray,
    ballLtGray_15,
    white,
    inputBorderGray,
    datepickerHoverGray,
    datepickerDefaultGray,
    lato
} from '../../../themes/globalConstants';
import { makeStyles } from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import clsx from 'clsx';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { selectDateFormat } from '../../../store/selectors';
import { useTypedSelector } from '../../../store/reducers/reducer';

interface Props {
    name: string;
    isEditDraft: boolean;
    date: moment.Moment | null;
    placeholder?: string;
    isOutsideRange?: (day: any) => boolean;
    availableDates?: string;
    keepOpen?: boolean;
    noBorder?: boolean;
    calendarInfo?: any;
    customInputIcon?: any;
    numberOfMonths?: number;
    onDateChange?: (name: string, date: moment.Moment | null) => void;
    readOnly?: boolean;
    onBlur?: (name: string) => void;
    firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
    enableOutsideDays?: boolean;
    error?: boolean;
    small?: boolean;
    isEditPage?: boolean;
}

const StyledDatePickerWrapper = styled.div<{ error?: boolean }>`
    & .SingleDatePicker,
    .SingleDatePickerInput {
        width: 100%;
        .DateInput {
            max-width: 17.5em;
            width: 100%;

            .DateInput_input {
                ::placeholder {
                    color: #363b3e;
                }
                background-color: ${ballLtGray_15};
                border-bottom: 1px solid ${(props) => (props.error ? 'red' : inputBorderGray)}; // Apply red border if error
                padding: 0.9em 0.7em 1.1em;
                font-size: 14px;
            }
        }

        .SingleDatePicker_picker {
            z-index: 100;
        }

        .SingleDatePickerInput__withBorder {
            border: 0;
            width: 100%;
        }

        .CalendarDay__selected_span {
            background: ${ballGray};
            color: ${white};
            border-bottom: 1px solid ${ballLtGray};
        }

        .CalendarDay__selected {
            background: ${ballBlue};
            border: 1px double ${ballBlue};
            color: ${white};
        }

        .CalendarDay__selected:hover {
            background: ${ballLtGray};
            color: ${datepickerHoverGray};
            border: 1px double ${ballBlue};
        }

        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
            background: ${ballLtGray};
        }

        .CalendarDay {
            font-family: ${lato};
            .CalendarDay_default {
                border: 1px solid ${datepickerDefaultGray};
                border-radius: 2px;
            }
        }

        .CalendarMonth_caption {
            font-family: ${lato};
        }

        .DayPicker_weekHeader_li {
            font-family: ${lato};
        }

        .DayPickerKeyboardShortcuts_show__bottomRight::before {
            border-top: 26px solid transparent;
            border-right: 33px solid ${ballBlue};
        }

        ${(props) =>
            props.isEditPage &&
            `
            @media (min-width: 2560px) {
                .DayPicker__withBorder {
                    left: -100px;
                }
            }
            @media (min-width: 1920px) and (max-width: 2559px) {
                .DayPicker__withBorder {
                    left: -105px;
                }
            }
            @media (min-width: 1440px) and (max-width: 1919px) {
                .DayPicker__withBorder {
                    left: -70px;
                }
            }
            @media (min-width: 1024px) and (max-width: 1439px) {
                .DayPicker__withBorder {
                    left: -30px;
                }
            }
        `}
    }
`;
const useStyles = makeStyles((theme) => ({
    navBtn: {
        position: 'absolute',
        top: 18,
        lineHeight: 0.78,
        borderRadius: 3,
        padding: '6px 9px',
        color: ballBlue,
        fontSize: '2rem'
    },
    prevIcon: {
        transform: 'rotate(90deg)',
        left: 22,
        display: 'none'
    },
    nextIcon: {
        transform: 'rotate(270deg)',
        right: 22,
        display: 'none'
    },
    customDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    btnPrevNext: {
        border: 'none',
        backgroundColor: 'Transparent'
    },
    navPrevNext: {
        transform: 'scale(1.25)',
        verticalAlign: 'middle',
        color: ballBlue
    },
    customSelect: {
        padding: '3px 0px 5px 1px',
        border: 'none'
    }
}));

const DatePicker: React.FC<Props> = ({ isEditPage = false, ...props }) => {
    const [date, setDate] = useState<moment.Moment | null>(props.date ?? null);
    const [focused, setFocused] = useState<boolean | null>(null);
    const classes = useStyles();
    const dateFormat = useTypedSelector<string>(selectDateFormat);

    // Sets date to local state and any parent level methods
    const handleDateChange = (newDate: moment.Moment | null) => {
        setDate(newDate);
        if (props.onDateChange) {
            props.onDateChange(props.name, newDate);
        }
    };

    // Used to reset value of date picker for form reset
    useEffect(() => {
        if (props.date === null) {
            handleDateChange(props.date);
        } else if (props.date && !date) {
            handleDateChange(props.date);
        }
        //eslint-disable-next-line
    }, [props.date]);

    useEffect(() => {
        if (props.onBlur && !focused) {
            props.onBlur(props.name);
        }
        //eslint-disable-next-line
    }, [focused]);

    const prevIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.prevIcon)} />;
    const nextIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.nextIcon)} />;

    const renderMonthElement = ({
        month,
        onMonthSelect,
        onYearSelect
    }: {
        month: moment.Moment;
        onMonthSelect: (month: moment.Moment, value: string) => void;
        onYearSelect: (month: moment.Moment, value: string) => void;
    }) => {
        const monthKey = month.month();
        return (
            <div className={classes.customDiv} key={monthKey}>
                <div>
                    <button
                        className={clsx(classes.btnPrevNext)}
                        onClick={(e) => {
                            //selcted year is current year
                            if (month.year() === moment().year() && month.month() > 0) {
                                //restrict users from going beyond current year first month
                                onMonthSelect(month, String(month.month() - 1));
                            }
                            if (month.year() > moment().year()) {
                                if (month.month() === 0) onMonthSelect(month, String(11));
                                else onMonthSelect(month, String(month.month() - 1));
                            }
                        }}
                    >
                        <NavigateBefore className={clsx(classes.navPrevNext)} />
                    </button>
                    <select
                        className={clsx(classes.customSelect)}
                        value={month.month()}
                        onChange={(e) => {
                            onMonthSelect(month, e.target.value);
                        }}
                    >
                        {moment.months().map((label: string, value: number) => (
                            <option key={value} value={value}>
                                {label.slice(0, 3)}
                            </option>
                        ))}
                    </select>
                    <button
                        className={clsx(classes.btnPrevNext)}
                        onClick={(e) => {
                            if (month.year() <= moment().year() + 5) {
                                if (month.month() <= 10)
                                    onMonthSelect(month, String(month.month() + 1));
                            }
                        }}
                    >
                        <NavigateNext className={clsx(classes.navPrevNext)} />
                    </button>
                </div>
                <div style={{ paddingLeft: '3px' }}>
                    <button
                        className={clsx(classes.btnPrevNext)}
                        onClick={(e) => {
                            if (moment().year() <= month.year() - 1) {
                                onYearSelect(month, String(month.year() - 1));
                            }
                        }}
                    >
                        <NavigateBefore className={clsx(classes.navPrevNext)} />
                    </button>
                    <select
                        className={clsx(classes.customSelect)}
                        value={month.year()}
                        onChange={(e) => onYearSelect(month, e.target.value)}
                    >
                        {returnYears()}
                    </select>
                    <button
                        className={clsx(classes.btnPrevNext)}
                        onClick={(e) => {
                            if (moment().year() + 5 >= month.year() + 1) {
                                onYearSelect(month, String(month.year() + 1));
                            }
                        }}
                    >
                        <NavigateNext className={clsx(classes.navPrevNext)} />
                    </button>
                </div>
            </div>
        );
    };
    const returnYears = () => {
        let years: any = [];
        for (let i = moment().year(); i <= moment().year() + 5; i++) {
            years.push(
                <option value={i} key={i}>
                    {i}
                </option>
            );
        }
        return years;
    };
    return (
        <StyledDatePickerWrapper error={props.error} isEditPage={isEditPage}>
            <SingleDatePicker
                placeholder={props.placeholder ? props.placeholder : ''}
                date={date}
                id={props.name}
                keepOpenOnDateSelect={props.keepOpen}
                navPrev={prevIcon}
                navNext={nextIcon}
                numberOfMonths={props.numberOfMonths}
                enableOutsideDays={props.enableOutsideDays}
                customInputIcon={props.customInputIcon}
                noBorder={props.noBorder}
                hideKeyboardShortcutsPanel={true}
                onDateChange={(newDate) => handleDateChange(newDate)}
                focused={focused}
                displayFormat={dateFormat}
                onFocusChange={({ focused }) => setFocused(focused)}
                isOutsideRange={props.isOutsideRange}
                readOnly={props.readOnly}
                firstDayOfWeek={props.firstDayOfWeek ?? 0}
                renderMonthElement={renderMonthElement}
                small={props.small}
            />
        </StyledDatePickerWrapper>
    );
};

export default DatePicker;
