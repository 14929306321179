import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import {
    ballDrkBlue,
    ballGray,
    blackWeight,
    black_5,
    lato,
    ltBlueGrey_7,
    orange,
    white,
    xxxl,
    yellow
} from '../../../../../themes/globalConstants';
import { Link as RouterLink } from 'react-router-dom';

/**
 * Example usage:
 * <DashboardWidgetMetric
 *    quantity={5}
 *    link="/route-name"
 *    subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Open_Orders_Icon.svg`}
 *    subheaderIconAlt="Open Orders Icon"
 *    subheader="Open Orders" />
 */

interface Props {
    quantity: number;
    link?: string;
    subheaderIconUrl: string;
    subheaderIconAlt?: string;
    subheader: string;
    type?: 'normal' | 'orange' | 'yellow';
    enableLink?: boolean;
}

const useStyles = makeStyles((theme) => ({
    widgetSectionHeader: {
        fontWeight: blackWeight,
        color: ballGray,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: '1.75em'
    },
    widgetNumber: {
        fontSize: xxxl,
        fontWeight: 500,
        lineHeight: 1,
        color: ballDrkBlue,
        fontFamily: lato,
        marginBottom: '.75rem'
    },
    orange: {
        color: orange
    },
    yellow: {
        color: yellow
    },
    widgetNumberLink: {
        textDecoration: 'none',
        position: 'relative',
        borderBottom: `1px solid ${white}`,
        '&:hover': {
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    widgetNumberSlant: {
        width: '2.625em',
        color: ltBlueGrey_7,
        margin: '0 2em',
        background: `linear-gradient(to top left,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            ${ltBlueGrey_7} 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%)`
    },
    widgetNumberContainer: {
        display: 'flex',
        margin: '1em 0 2em',
        flexDirection: 'column'
    },
    image: {
        height: '1.25em'
    },
    iconText: {
        color: black_5,
        fontWeight: blackWeight,
        marginLeft: '0.5em'
    },
    disable: {
        pointerEvents: 'none',
        cursor: 'default'
    }
}));

export default function DashboardWidgetMetric({
    quantity,
    link,
    subheaderIconUrl: iconUrl,
    subheaderIconAlt: iconAlt,
    subheader: iconText,
    type = 'normal',
    enableLink
}: Props) {
    const classes = useStyles();
    return (
        <Grid item className={classes.widgetNumberContainer}>
            {link && (
                <RouterLink
                    to={link}
                    data-testid="widget-link"
                    className={clsx([
                        classes.widgetNumber,
                        classes.widgetNumberLink,
                        {
                            [classes.orange]: type === 'orange',
                            [classes.yellow]: type === 'yellow',
                            [classes.disable]: enableLink === false
                        }
                    ])}
                >
                    {quantity}
                </RouterLink>
            )}
            {!link && (
                <span
                    className={clsx(classes.widgetNumber, {
                        [classes.orange]: type === 'orange',
                        [classes.yellow]: type === 'yellow'
                    })}
                >
                    {quantity}
                </span>
            )}
            <Grid container alignItems="center" justify="flex-start">
                <img className={classes.image} src={iconUrl} alt={iconAlt || `${iconText} Icon`} />
                <Typography variant="body1" className={classes.iconText}>
                    {iconText}
                </Typography>
            </Grid>
        </Grid>
    );
}
