import Axios from 'axios';
export interface OrderingSummaryRequest {
    selectedAccountId?: string,
    selectedChildrenAccountId?: string,
    allOrders?: boolean
}
export default class OrderingSummariesService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` },
    });

    static getOrderingSummariesInformation = (
        accessToken: string,
        request: OrderingSummaryRequest
    ) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/orders/summary`;
        let urlContent = `?AllOrders=${request.allOrders}`;
        urlContent += request.selectedAccountId && `&SoldToId=${request.selectedAccountId}`;
        urlContent += request.selectedChildrenAccountId && `&ShipToId=${request.selectedChildrenAccountId}`;
        const requestUrl = baseUrl + urlContent;
        return Axios.get(requestUrl, OrderingSummariesService.createConfig(accessToken));
    };




}
