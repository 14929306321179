import * as types from '../actions/action-types';
import { OrderProduct } from './orders-dashboard';
import { Moment } from 'moment';

export type QuantityUnit = 'pallets' | 'eaches';

export enum ShipmentEditStatus {
    Unedited = 'Unedited',
    Edited = 'Edited',
    Cancelled = 'Cancelled'
}

export enum ReviewStatus {
    New = 'New',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Unknown = 'Unknown'
}

export enum ReviewedOrderStatus {
    UnderReview = 'Edit Under Review',
    OpenEditReviewed = 'Open (Edit Reviewed)',
    OpenEditRejected = 'Open (Edit Rejected)'
}

export interface DeliveryTime {
    time: moment.Moment;
    label: string;
    disabled?: boolean;
}

export interface OrderInformation {
    shipToId: string;
    poNumber: string;
    deliveryInstructions: string;
    deliveryDate: string;
    palletCount: number;
    shipmentCount: number;
    productCount: number;
    orderNumber: string;
}

export interface DeliveryShipment {
    loads?: ProductToShip[];
    palletCapacity?: number;
    palletCount?: number;
    shipmentType?: string;
    shipmentId?: number;
    truckId?: number;
    deliveryDateTime?: string;
    originalDateTime?: string;
    deliveryTime?: string;
    deliveryInstructions?: string;
    sendDeliveryInstructions?: boolean;
    shipDateTime?: string;
    loadOrder?: string;
    status?: string;
    pallets?: number;
    saved?: boolean;
    editing?: boolean;
    id?: string;
    deliveryDateIndex?: number;
    shipmentQuantity?: number;
    carrier?: string;
    warehouseName?: string;
    warehouseAddress?: string;
    shipmentSKUs?: number;
    canQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantityPallets?: number;
    endQuantitySKUs?: number;
    shipmentQuantityEaches?: number;
    shipmentEditStatus?: ShipmentEditStatus;
    edited?: boolean;
    cancelled?: boolean;
    updatedDeliveryDate?: string;
    shipToId?: number;
    dateEditedBefore?: boolean;
    productCount?: number;
}

export interface ProductToShip extends ProductWithPallets {
    palletQuantity: number;
    palletsOnShipments?: number;
    availableDate?: string;
    shipmentIndex?: number;
    loadId?: number;
    editLoadId?: number;
    releaseNumber?: string;
    reference?: string;
    referenceNumber?: string;
    fillerLine?: string;
    purchaseOrderNumber?: string;
    sequence?: number;
    addDisabled?: boolean;
    calculatedTotalsForPrevNDays?: number[];
    productWithPallets?: number[];
    previousPalletTotals?: number[];
    status?: string;
    customerProductName?: string;
    userSuppliedProductId?: string;
    customerProductId?: string;
    deliveryInstructions?: string;
    originalCsvLineNumber?: number;
    eachesQuantity?: number;
    palletsRounded?: boolean;
    isCustomerProductIdDistinct?: boolean;
    candidateProductSkus?: string[];
    originalPalletQuantity?: number;
    loadKey?: string;
}

export interface LinkedProductionOrders {
    loadsToLinkedOrders?: DeliveryOrderLinkedProductionOrder;
}

export interface DeliveryOrderLinkedProductionOrder {
    productionOrderId: number;
    productionOrderNumber: number; //this is the JDE one
    customerProductionOrderId: string;
    quantity: number;
}

export interface ProductWithPallets extends OrderProduct {
    availableItemsPerPallet?: number;
    availablePallets?: number;
    previousPalletTotals?: number[];
    inputPallets?: number;
    inputEaches?: number;
    orderedPallets?: number;
    agedPalletQuantity?: number;
}

export interface Load {
    loadId?: number;
    releaseNumber?: string;
    referenceNumber?: string;
    fillerLine?: string;
    palletQuantity?: number;
    sequence?: number;
    purchaseOrderNumber?: string;
    deliveryInstructions?: string;
}

export interface DeliveryOrder {
    deliveryOrderId?: number;
    deliveryOrderNumber?: string;
    deliveryOrderNumberString?: string;
    submittedDate?: string;
    formattedSubmittedDate?: string;
    earliestShipmentDate?: string | Moment;
    latestShipmentDate?: string | Moment;
    submittedBy?: string;
    createdBy?: string;
    type?: string;
    status?: string;
    shipments?: DeliveryShipment[];
    shipToId?: number;
    totalOrderQuantity?: number;
    totalOrderSkus?: number;
    deliveryWeekDate?: any;
    totalShipments?: number;
    customerPickup?: boolean;
    canQuantityPallets?: number;
    endQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantitySKUs?: number;
    canQuantityEaches?: number;
    endQuantityEaches?: number;
    remainingThisWeekShipments?: number;
    remainingThisWeekOrderQuantity?: number;
    remainingThisWeekOrderSkus?: number;
    isLocked?: boolean;
    totalOrderQuantityEaches?: number;
    searchTerms?: string;
    statusReview?: OrderStatusReview;
    reviewStatus?: ReviewStatus;
    deliveryInstructions?: string;
    poNumber?: string;
    draftId?: number;
}

export interface OrderStatusReview {
    status: ReviewStatus;
    reason: string;
    acknowledged: boolean;
    createDate: Date;
    updateDate: Date;
}

export interface DeliveryOrderRequest extends DeliveryOrder {
    region: string;
    country: string;
    createdBy: string;
    status: string;
    submittedDate: string;
    shipToId: number;
    updateDate?: string;
}

export interface ExpandableLoad {
    shipmentId?: string;
    displayId?: string;
    expanded: boolean;
}

export interface ShipItProductsRequest {
    ShipToIds: number[];
    Types?: string[];
    UsedWithinNMonths?: number;
    ActiveOnly?: boolean;
}

export interface OrderingState {
    orderInformation?: OrderInformation;
    productsWithPallets: ProductWithPallets[];
    productsToBeShipped: ProductToShip[];
    shipmentsOrder?: DeliveryShipment[];
    deliveryOrder?: DeliveryOrder;
    view?: string[];
    range?: string[];
    fromConfig: boolean;
    expandableLoads?: ExpandableLoad[];
    loadingOrder: boolean;
    loadedOrder: boolean;
    loadingProducts: boolean;
    loadedProducts: boolean;
    error?: any;
}

const initialState: OrderingState = {
    orderInformation: undefined,
    productsWithPallets: [],
    productsToBeShipped: [],
    shipmentsOrder: [],
    deliveryOrder: undefined,
    view: [''],
    range: [''],
    fromConfig: false,
    expandableLoads: [],
    loadingOrder: false,
    loadedOrder: false,
    loadingProducts: false,
    loadedProducts: false,
    error: undefined
};

const orderingDashboard = (state = initialState, action: any): OrderingState => {
    switch (action.type) {
        case types.ORDERING_ORDER_LOADING:
            return { ...state, loadedOrder: false, loadingOrder: true, error: undefined };
        case types.ORDERING_ORDER_LOADED:
            return { ...state, loadedOrder: true, loadingOrder: false, error: undefined };
        case types.ORDERING_DASHBOARD_UPDATE_VIEW:
            return { ...state, loadedOrder: true, loadingOrder: false, view: action.view };
        case types.ORDERING_DASHBOARD_VIEW_ERROR:
            return { ...state, loadingOrder: false, loadedOrder: false, error: action.error };
        case types.ORDERING_DASHBOARD_UPDATE_RANGE:
            return { ...state, loadedOrder: true, loadingOrder: false, range: action.range };
        case types.ORDERING_DASHBOARD_UPDATE_PALLETS:
            return {
                ...state,
                loadedOrder: true,
                loadingOrder: false,
                productsWithPallets: action.productsWithPallets
            };
        case types.ORDERING_ORDER_LOADING_ERROR:
            return { ...state, loadedOrder: true, loadingOrder: false, error: action.error };
        case types.ORDERING_ORDER_PRODUCTS_UPDATE:
            return {
                ...state,
                loadingOrder: false,
                loadedOrder: false,
                productsToBeShipped: action.productsToBeShipped
            };
        case types.ORDERING_ORDER_UPDATE:
            return { ...state, deliveryOrder: action.deliveryOrder };
        case types.ORDERING_ORDER_SHIPMENTS_UPDATE:
            return { ...state, shipmentsOrder: action.shipmentsOrder };
        case types.ORDERING_DASHBOARD_RESET_STATE:
            return {
                ...initialState
            };
        case types.ORDERING_DASHBOARD_CONFIG_FLAG:
            return { ...state, fromConfig: action.fromConfig };
        case types.ORDERING_DASHBOARD_EXPANDABLE_LOAD_CHANGE:
            return { ...state, expandableLoads: action.expandableLoads };
        case types.ORDERING_DASHBOARD_ADD_ORDER_INFORMATION:
            return {
                ...state,
                orderInformation: { ...state.orderInformation, ...action.orderInformation }
            };
        case types.ORDERING_DASHBOARD_ORDER_LOADING:
            return {
                ...state,
                loadedOrder: false,
                loadingOrder: true
            };
        case types.ORDERING_DASHBOARD_LOAD_PRODUCTS:
            return { ...state, loadingProducts: true, loadedProducts: false, error: undefined };
        case types.ORDERING_DASHBOARD_PRODUCTS_LOADED:
            return {
                ...state,
                productsToBeShipped: action.products,
                loadingProducts: false,
                loadedProducts: true
            };
        case types.ORDERING_DASHBOARD_LOAD_PRODUCTS_ERROR:
            return { ...state, loadingProducts: false, loadedProducts: true, error: action.error };
        default:
            return state;
    }
};

export default orderingDashboard;
