import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ShipmentTitle from './ShipmentTitle';
import ShipmentTimeSelector from './ShipmentTimeSelector';
import ShipmentOrderDetail from './ShipmentOrderDetail';
import ShipmentDetail from '../../../reusable/molecules/ShipmentDetail';
import ShipmentGrid from './ShipmentGrid';
import ShipmentFooterActions from './ShipmentFooterActions';

import {
    ProductToShip,
    DeliveryShipment,
    OrderingState,
    DeliveryTime
} from '../../../../store/reducers/ordering-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import { fullTruckPallets } from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateShipments } from '../../../../store/actions/ordering-dashboard';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2)
    },
    yellowBanner: {
        backgroundColor: 'yellow',
        padding: theme.spacing(1),
        textAlign: 'right'
    },
    redText: {
        color: 'red',
        fontSize: '12px'
    },
    textField: {
        marginTop: theme.spacing(1)
    }
}));

interface ShipmentCardProps {
    shipment: DeliveryShipment;
    shipmentIndex: number;
    isEditShipment: boolean;
    deliveryTimeList: DeliveryTime[];
    onEditShipment: (shipment: DeliveryShipment, shipmentIndex: number) => void;
    onSaveShipment: (shipment: DeliveryShipment, shipmentIndex: number) => void;
    onUpdateLoads: (shipment: DeliveryShipment) => void;
    onRemoveLoads: (shipment: DeliveryShipment) => void;
    onDeleteShipment: (index: number) => void;
    isUniqueDateTime: (newDateTime: Moment) => boolean;
}

export default function ShipmentCard({
    shipment,
    shipmentIndex,
    isEditShipment,
    deliveryTimeList,
    onEditShipment,
    onUpdateLoads,
    onRemoveLoads,
    onSaveShipment,
    onDeleteShipment,
    isUniqueDateTime
}: ShipmentCardProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            deliveryDateTime: ''
        }
    });
    const { reset, triggerValidation, getValues, errors } = methods;
    const dispatch = useDispatch();
    const [currentShipment, setCurrentShipment] = useState({} as DeliveryShipment);
    const [, setIsShipmentValid] = useState<boolean>(false);
    const [truckWarning, setTruckWarning] = useState<boolean>(false);
    const [shipmentErrorMessage, setShipmentErrorMessage] = useState<string>('');

    const { orderInformation } = useTypedSelector<OrderingState>(
        (state) => state.orderingDashboard
    );

    //const maxPallets = getPalletsPerTruck(isLargeCustomer, isCopacker, isLargeCustomerAccount);
    const maxPallets = fullTruckPallets;
    const freightWarningMessage = t('freightWarningMaxPallets', { maxPallets });

    const handleEditClick = () => {
        onEditShipment(shipment, shipmentIndex);
        reset({
            deliveryDateTime: shipment.deliveryDateTime
        });
    };

    const handleSaveClick = async () => {
        // Validate the form values (DeliveryDateTime)
        await triggerValidation().then((isValid) => {
            if (!isValid) {
                return;
            }
            const newSelectedDateTime = moment(getValues('deliveryDateTime'));
            let newDeliveryDateTime = '';
            if (isUniqueDateTime(newSelectedDateTime)) {
                newDeliveryDateTime = newSelectedDateTime.format();
            }

            onSaveShipment(
                {
                    ...currentShipment,
                    deliveryDateTime: newDeliveryDateTime
                },
                shipmentIndex
            );
        });
    };

    const handleDeleteClick = () => {
        onDeleteShipment(shipmentIndex);
    };

    const handleUpdateLoads = (loads: ProductToShip[]) => {
        const updatedShipment = { ...currentShipment, loads };
        const shipmentDelivery: DeliveryShipment[] = [];
        shipmentDelivery.push(updatedShipment);
        dispatch(updateShipments(shipmentDelivery));
        setCurrentShipment(updatedShipment);
        onUpdateLoads(updatedShipment);
    };

    const handleRemoveLoads = (loads: ProductToShip[]) => {
        const updatedShipment = { ...currentShipment, loads };
        setCurrentShipment(updatedShipment);
        onRemoveLoads(updatedShipment);
    };

    useEffect(() => {
        if (isEditShipment && errors) {
            let errorMessage = '';
            if (!!errors.deliveryDateTime) {
                errorMessage = t(
                    'deliveryTimeRequired',
                    'Delivery Time is required in order to save your shipment'
                );
            }
            setShipmentErrorMessage(errorMessage);
        } else {
            setShipmentErrorMessage('');
        }
    }, [t, isEditShipment, errors]);

    useEffect(() => {
        setCurrentShipment({ ...shipment, palletCount: shipment.palletCount || 0 });
    }, [shipment]);

    useEffect(() => {
        if (
            currentShipment.deliveryDateTime &&
            currentShipment.loads &&
            currentShipment.loads.length > 0
        ) {
            const emptyLoads = currentShipment.loads.filter((load) => load.palletQuantity === 0);
            const totalQuantity = currentShipment.loads.reduce(
                (acc, load) => acc + load.palletQuantity,
                0
            );

            if (
                emptyLoads.length > 0 ||
                (shipment.palletCapacity && totalQuantity > shipment.palletCapacity)
            ) {
                setIsShipmentValid(false);
            } else {
                setIsShipmentValid(true);
            }
        } else {
            setIsShipmentValid(false);
        }
    }, [currentShipment.deliveryDateTime, currentShipment.loads, shipment.palletCapacity]);

    useEffect(() => {
        if (
            currentShipment.shipmentType === 'CAN' &&
            currentShipment.palletCount &&
            currentShipment.palletCount < fullTruckPallets
        ) {
            setTruckWarning(true);
        } else {
            setTruckWarning(false);
        }
    }, [currentShipment]);

    return (
        <Card className={classes.card} elevation={2}>
            <FormContext {...methods}>
                <form autoComplete="off">
                    <CardContent>
                        <ShipmentTitle
                            shipment={currentShipment}
                            shipmentIndex={shipmentIndex}
                            isEditShipment={isEditShipment}
                            orderNumber={orderInformation?.orderNumber || undefined}
                        />
                        {isEditShipment ? (
                            <>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <ShipmentTimeSelector
                                            shipmentDeliveryDateAndTime={
                                                currentShipment.deliveryDateTime
                                            }
                                            ExpandMoreIcon={ExpandMoreIcon}
                                            deliveryTimeList={deliveryTimeList}
                                        />
                                    </Grid>
                                    <Grid item direction="column" xs={8}>
                                        <ShipmentDetail shipment={currentShipment} />
                                        {/* <ShipmentTruck items={shipment} truckPalletLimit={25} /> */}
                                    </Grid>
                                </Grid>
                                <ShipmentGrid
                                    loads={currentShipment.loads}
                                    isEditShipment={isEditShipment}
                                    onUpdateLoads={handleUpdateLoads}
                                    onRemoveLoads={handleRemoveLoads}
                                />
                            </>
                        ) : (
                            <>
                                <Grid container direction="column">
                                    <Grid container direction="row">
                                        <Grid item xs={6}>
                                            <ShipmentOrderDetail shipment={currentShipment} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ShipmentDetail shipment={currentShipment} />
                                        </Grid>
                                    </Grid>
                                    <WarningAlert
                                        showWarning={truckWarning}
                                        maxPallets={maxPallets}
                                        warningMessage={freightWarningMessage}
                                    ></WarningAlert>
                                    <ShipmentGrid
                                        loads={currentShipment.loads}
                                        isEditShipment={isEditShipment}
                                        onUpdateLoads={handleUpdateLoads}
                                        onRemoveLoads={handleRemoveLoads}
                                    />
                                </Grid>
                            </>
                        )}
                        <ShipmentFooterActions
                            isEditShipment={isEditShipment}
                            shipmentErrorMessage={shipmentErrorMessage}
                            onDeleteShipment={handleDeleteClick}
                            onEditShipment={handleEditClick}
                            onSaveShipment={handleSaveClick}
                        />
                    </CardContent>
                </form>
            </FormContext>
        </Card>
    );
}
