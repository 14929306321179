export const enGB = {
    // Reusable
    cans: 'Cans',
    view: 'View',
    design: 'Design',
    filling: 'Filling',
    canHandling: 'Can Handling',
    regulations: 'Regulations',
    labLiquid: 'Lab Liquid',
    sustainability: 'Sustainability',
    faq: 'Frequently Asked Questions',
    designInspiration: 'Design Inspiration',
    backToAllProducts: 'Back to All Products',
    shipTo: 'Ship To',
    billTo: 'Bill To',
    new: 'New',
    existing: 'Existing',
    unknown: 'Unknown',
    playground: 'Playground',

    // -- NAVIGATION -- //
    home: 'Home',
    homepage: 'My Homepage',
    ourProcess: 'Our Process',
    processOverview: 'Process Overview',
    labDashLiquid: 'Lab / Liquid',
    handling: 'Handling',
    regulatory: 'Regulatory',
    ourProducts: 'Our Products',
    productPortfolio: 'Product Portfolio',
    contactUs: 'Contact Us',
    logout: 'Logout',
    backToPortfolio: 'Back to Portfolio',
    navigation: 'Navigation',
    guided: 'Guided',
    graphics: 'Graphics',
    overview: 'Overview',
    theProcess: 'The process',
    ballProductPortfolio: 'Ball Product Portfolio',
    guidesLink: 'How To Guides',
    faqLink: 'FAQ',
    makeItSummary: 'Make It Summary',
    shipItSummary: 'Ship It Summary',
    tradeItRequests: 'Trade It Requests',
    scrapItRequests: 'Scrap It Requests',
    reviewScrapItRequest: 'Review Scrap It Request',
    scrapItRequestOrderNumber: 'Scrap It Request Order #',
    tradeItRequestOrderNumber: 'Trade It Request Order Number',
    submitNewGraphicLink: 'Submit New Graphic',
    admin: 'Admin',
    userAdmin: 'User Admin',
    manageUsers: 'Manage Users',
    manageLocations: 'Manage Locations',
    addNewUser: 'Add New User',
    myAccount: 'My Account',
    userInformation: 'User Information',
    confirmEmail: 'Confirm Email',
    pendingAccessRequests: 'Pending Access Requests',
    myAccess: 'My Access',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Welcome to <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> will be your one-stop-shop for design to delivery. Over time, this page will have useful metrics like “Make It” Production Orders, “Ship It” Delivery Orders, Product Management, and more. We appreciate your feedback and patience as we upgrade your experience.',
    readyToOrder: 'Ready to order products?',
    makeIt: 'Make It',
    readyToSchedule: '/    Ready to schedule a shipment?',
    shipIt: 'Ship It',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'User Administration',
    setupNewUser:
        'Setup a new user with the correct permissions and locations in <i>The Source</i>.',
    userAdminDashboard: 'User Admin Dashboard',
    viewPendingRequests:
        'View pending access requests, pending new user requests, and active users in <i>The Source</i>.',
    viewUserAdminDashboard: 'View User Admin Dashboard',

    // -- DASHBOARD -- //
    agedSku: 'Aged SKU',
    agedSkus: 'Aged SKUs',

    // -- USER ADMIN -- //
    pendingRegistration: 'Pending Registration',
    pendingRequests: 'Pending Requests',
    addUser: 'Add User',
    roles: 'Role(s)',
    location: 'Location',
    reviewRequest: 'Review Request',
    sentOn: 'Sent On',
    disable: 'Disable',
    resend: 'Resend',
    disableUser: 'Disable User',
    disableUserWarning:
        'You are about to disable this user’s permissions to your account. They will need to create a new request to regain permissions.',
    disableUserContinue: 'Are you sure you want to continue?',
    yesDisable: 'Yes, Disable',
    viewEdit: 'View/Edit',
    newUserEmailHelper: 'Email@domain.com',
    locationRoleAccessTableInst: `Choose the role or roles to give this user access per location. View a role's available permissions by clicking the role name or`,
    locationRoleAccessTableRequest: `Choose the role or roles you are requesting for this location. View a role's available permissions by clicking the role name or`,
    locationRoleAccessTableInstLink:
        'view a comparison of all roles and their available permissions.',
    allAccess: 'All Access',
    coPacker: 'Co-Packer',
    warehousingLogistics: 'Warehousing/Logistics',
    acctAdmin: 'Acct. Admin',
    acctPayableReceivable: 'Acct. Payable/Receivable',
    graphicsVendor: 'Graphics Vendor',
    rolesLocations: 'Roles (# of Locations)',
    activeUsers: 'Active Users',
    accountAdmin: 'Account Admin',
    arAp: 'AR/AP',
    artMgmt: 'Art Mgt.',
    forecastMgmt: 'Forecast Mgt.',
    marketingMgmt: 'Marketing Mgt.',
    orderFulfillment: 'Order Fulfillment',
    packagingMgmt: 'Packaging Mgt.',
    procurementMgmt: 'Procurement Mgt.',
    impersonation: 'Impersonation',
    roleAccessTableSearchPlaceholder: 'Search...',
    confirmChangesWillNotBeSaved:
        'Any changes made will not be saved. Are you sure you want to proceed?',
    changesWillNotBeSaved: 'Changes made will not be saved',
    areYouSureDiscard: 'Are you sure you want to discard any changes?',
    yesDiscard: 'Yes, Discard',
    disableUserError: 'The system was unable to disable the selected user',
    locationRoleAccess: 'Location & Role Access',
    latestSubmissionDate: 'Latest Submission Date',
    resubmit: 'Resubmit',
    cancelRequest: 'Cancel Request',
    userAdminSuccessMessage: 'User permissions have been updated',
    roleComparison: 'The Source Role Comparison',
    graphicsIntakeForm: 'Design Intake Form',
    draftGraphicsIntakeForms: 'Draft Graphics Intake Forms',
    inProgressGraphicsStatuses: 'In Progress Design Statuses',
    graphicsQuote: 'Graphics Quote',
    graphicsProof: 'Graphics Proof',
    graphicsStandardPilotCan: 'Graphics Standard (Pilot Can)',
    recentlyCompletedGraphics: 'Recently Completed Graphics',
    planning: 'Planning',
    newOpenProductionOrders: 'New / Open Production Orders',
    newOpenDeliveryOrders: 'New / Open Delivery Orders',
    draftDeliveryOrders: 'Draft Delivery Orders',
    paymentsCIAOnly: 'Payments (CIA Only)',
    orderPaymentStatus: 'Order Payment Status',
    orderPaymentFinancials: 'Order Payment Financials',
    liquidTesting: 'Liquid Testing',
    liquidIntakeForm: 'Liquid Intake Form',
    warrantyLetter: 'Warranty Letter',
    termsandConditions: 'Terms and Conditions',
    temporarilyHideSKUs: 'Temporarily Hide SKUs',
    manageBillTo: 'Manage Bill-To',
    manageShipTo: 'Manage Ship-To',
    customerProductIDMappingtoBallIDs: 'Customer Product ID Mapping to Ball IDs',
    createNewUser: 'Create New User',
    editpermissions: 'Edit permissions',
    disablePermissions: 'Disable Permissions',
    troubleshooting: 'Troubleshooting',
    procurementManagement: 'Procurement Management',
    forecastManagement: 'Forecast Management',
    packagingManagement: 'Packaging Management',
    artManagement: 'Art Management',
    marketingManagement: 'Marketing Management',
    accountsPayableAccountsReceivable: 'Accounts Payable / Accounts Receivable',
    userAdministrator: 'User Administrator',
    selectedAccess: 'Selected Access',
    reviewAccessRequest: 'Review Access Request',
    // -- FAQ Page -- //
    searchFAQs: 'Search Frequently Asked Questions',
    searchFAQplaceholder: 'Cans, Bottles, etc!',

    // -- QUICK VIEW -- //
    production: 'Production',
    limitedProductionRun: 'Limited Production Run',
    seeFullProductDtls: 'See Full Product Details',
    timesPerYear: 'Times Per Year',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Product Information',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Make It Lock Window',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'View My Product Information',
    customerProductId: 'Customer Product Id',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Ball Product ID',
    previousForecasts: 'Previous Forecasts',
    copyToMakeIt: 'Copy To Make It',
    planItSummaryDashboardDesc:
        'View previously submitted order forecasts. Edit future order forecasts.',

    // -- PRODUCT PORTFOLIO -- //
    all: 'All',
    bottles: 'Bottles',
    ends: 'Ends',
    end: 'End',
    closures: 'Closures',
    closure: 'Closure',
    lookingForInspiration: 'Looking for Inspiration',
    pallets: 'Pallets',
    itemsPerPallet: 'Items Per Pallet',
    lookingForSomething: "Can't find what you need?",
    sendUsYourComments: "Tell us what you're looking for",
    minOrderPallet: 'Minimum Order in Pallets',
    cansPerPallet: 'Cans Per Pallet',
    quickLook: 'Quick look',
    select: 'Select',
    seeDetailsBtn: 'See Full Product Details',
    compareProducts: 'Compare Products',
    pleaseSelectProducts: 'Please select products to get an estimate',
    endTile: 'Ends/Closures',
    myProjects: 'My Projects',
    compare: 'Compare',
    belowAreYourSavedProjects: 'Below are your saved projects from the last 180 days.',
    projectName: 'Project Name',
    productCode: 'Product Code',
    savedOn: 'Saved On',
    viewProject: 'View Project',

    // -- FACETS -- //
    filterBy: 'Filter By',
    clearAll: 'Clear All',
    // Facet Category
    stylesCategory: 'Product Range',
    sizesCategory: 'Size',
    featuresCategory: 'Product Enhancements',
    endsCategory: 'End Compatibility',
    // Facet Tooltips
    stylesTooltip: 'A family of products in similar shapes and sizes.',
    sizesTooltip: 'A range of size options to meet your distinct specifications.',
    featuresTooltip: 'Add-ons available for certain Ball products.',
    endsTooltip: 'List of ends that work with different Ball products.',
    style: 'Style',
    size: 'Size',
    features: 'Features',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Request an Estimate',
    productDetails: 'Product Details',
    printGuide: 'Print Guide',
    reproStudioGrid: 'Repro Studio Grid',
    customerDesignGuide: 'Customer Design Guide',
    download: 'Download',
    canSpecification: 'Can Specification',
    dieLine: 'Die Line',
    palletSpecifications: 'Pallet Specifications',
    greatFor: 'Great For',
    specifications: 'Specifications',
    currentLeadTime: 'Current Lead Time',
    fillingAvailability: 'Filling Availability',
    compatibleEnds: 'Compatible Ends',
    weeks: 'Weeks',
    months: 'Months',
    minimum: 'Minimum',
    productEnhancements: 'Product Enhancements',
    compatibleFilling: 'Compatible Filling',
    pasteurization: 'Pasteurisation',
    retort: 'Retort',
    hotFill: 'Hot Fill',
    coldFill: 'Cold Fill',
    color: 'Colour',
    aluminum: 'Aluminium',
    // Ordering
    ordering: 'Ordering',
    pricePerThousand: 'Price per 1,000',
    quantityPerPallet: 'Quantity per Pallet',
    minOrder: 'Minimum Order',
    // Design Specs
    designSpecs: 'Design Specifications',
    circumference: 'Circumference',
    designLength: 'Design Length (Circumference)',
    designHeight: 'Design Height',
    maxTALength: 'Max Text Area (Length)',
    maxTAHeight: 'Max Text Area (Height)',
    maximumColors: 'Maximum Colour(s)',
    windowLimitWidth: 'Window Limit Width',
    windowLimitHeight: 'Window Limit Height',
    underOverlap: 'Under/Overlap Area',
    // Can Specs
    canSpecs: 'Can Specifications',
    endSize: 'End Size',
    bodyOutsideDiameter: 'Body Outside Diameter',
    flangedCanHeight: 'Flanged Can Height',
    coldFillVolume: 'Cold Fill Volume',
    retortFillVolume: 'Retort Fill Volume',
    flangedCanWidth: 'Flange Width',
    endDiameter: 'End Diameter',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Can Effects & Features Available for this Can',
    canFinishesAndEffects: 'Can Finishes & Effects',
    inks: 'Inks',
    overvarnish: 'Overvarnish',
    effects: 'Effects',
    specialFeatures: 'Special Features',

    // -- END DETAILS -- //
    endDetails: 'End Details',
    endFinishesAndEffects: 'End Finishes & Effects',
    compatibleCans: 'Compatible Cans',
    endFeaturesAndEffects: 'End Features & Effects',
    orderIndividually: 'Can be ordered individually',
    roppClosures: 'ROPP Closures are sold via 3rd party',
    endsPack: 'Ends Information Pack',

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Add Product',
    details: 'Details',
    name: 'Name',
    type: 'Type',
    maxColors: 'Max Colours',
    innovativeFeatures: 'Innovative features',
    leadTimes: 'Lead Times',
    currentFillingAvail: 'Current filling availability',
    products: 'Products',
    addAnotherToCompare: 'Add Another Product to Compare',
    removeSelection: 'Remove Selection',
    addToCompare: 'Add to Compare',
    prodComparisonDesc:
        'Compare up to Three different products at a time, regardless of volume or style. See which products offer',
    prodEstimateDesc:
        'Our estimator can help give you a better understanding of the cost of ordering cans with us. Keep a few things in mind when creating your estimate',
    prodHasMinOrder: 'Each product has a minimum order',
    orderInFullPallets: 'Estimates and orders can only be placed in full pallets',
    orderInFullTrucks:
        'We recommend ordering in full truckloads, most trucks can fit about 25 pallets. Less than full truckloads may incur additional charges',
    productComparison: 'Product Comparison',
    compareBuildEstimate: 'Compare, Build & Estimate',
    compareBuild: 'Compare & Build',
    myProject: 'My Project',

    // -- CONTACT FORM -- //
    howCanWeHelp: 'How Can We Help?',
    yourName: 'Your Name',
    email: 'Email',
    yourCountry: 'Your Country of Fill',
    subjectLine: 'Subject Line',
    contactMessage: 'Tell us how we can help...',
    submit: 'Submit',
    cancel: 'Cancel',
    sendUsANote: 'Send us a note on what new products you would like to see in the future',
    msgSuccessful: 'Message Successfully Sent.',
    msgFailed: 'Message failed to send, please try again at a different time.',
    sending: 'Sending',
    close: 'Close',
    contactReason: 'Contact Reason',
    contact: 'I have a question',
    support: 'I need help',
    cantLogIn: "I can't log in",

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Get an Estimate',
    desiredAnnualQuantity: 'Desired Annual Quantity',
    enterQuantity: 'Enter Quantity',
    // wouldYouLikeEndsWithThisProduct: 'Would you like ends with this product?',
    remove: 'Remove',
    enterValueGreaterThan: 'Please enter a value greater than 0',
    estimateFailedToSend: 'Estimate failed to send, please try again later.',
    productEstInquiry: 'Product Estimate Enquiry',
    submittingEstimate: 'Submitting below information for an estimate',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Back to Home',
    thankYou: 'Thank You',
    yourRequestHasBeenSubmitted: 'Your request has been submitted!',
    someoneFromOurTeamWillBeInTouchSoon: 'Someone from our team will be in touch soon.',

    // -- REGISTRATION -- //
    registrationHeader: "Let's Get the Ball Rolling",
    registrationDescription:
        'We are almost there, we just need a little bit of information from you to get you to the Ball Customer Portal where you can learn more about cans and how to order.',
    region: 'Region',
    language: 'Language',
    dateFormat: 'DATE FORMAT',
    aboutYourCompany: 'About your Company',
    companyName: 'Company Name',
    country: 'Country',
    stateProvince: 'State/Province',
    city: 'City',
    postalCode: 'Postal Code',
    yearsInBusiness: 'Years In Business',
    numberOfEmployees: 'Number Of Employees',
    estimatedAnnualRevenue: 'Estimated Annual Revenue',
    beverageInRetail: 'Do you currently have a beverage in retail?',
    currentPackageType: 'Current Package Type',
    beveragePackagingNeeds: 'Your Beverage Packaging Needs',
    beverageCategory: 'Beverage Category',
    productSize: 'Product Size',
    expectedAnnualQuantity: 'Expected Annual Quantity',
    canningLine: 'Do you own or plan to purchase a canning line?',
    copackerName: 'Co-Packer Name',
    fillingCountry: 'Filling Country',
    fillingCity: 'Filling City',
    additionalComments: 'Additional Comments (Optional)',
    artworkComments: 'Artwork Comments (Optional)',
    setupCustomerPortalAccount: 'Setup Your Customer Portal Account',
    firstName: 'First Name',
    lastName: 'Last Name',
    title: 'Title',
    jobTitle: 'Job Title',
    phoneNumber: 'Phone Number',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    alreadyRegistered: 'Already Registered?',
    accountCreated: 'This email account is already tied to The Source.',
    signIn: 'Sign in to the Customer Portal',
    signInConfirmation: 'Please Sign In to The Source and if needed, reset your password.',
    yes: 'Yes',
    no: 'No',
    incorrectEmailAddress: 'Invalid Email Format',
    atLeastEightLetters: 'At least 8 letters',
    atLeastOneCapitalLetter: 'At least one capital letter',
    atLeastOneLowerCaseLetter: 'At least one lowercase letter',
    atLeastOneNumber: 'At least one number',
    mustNotBeACommonPassword: 'Must not be a common password',
    passwordsShouldMatch: 'Passwords must match',
    year: 'year',
    printType: 'Would you like any of the following can types?',
    iAgree: 'I agree to the',
    termsOfUse: 'Terms of Use and Privacy Policies',
    million: 'Million',
    newToBeverage: 'New to Beverage',
    selectSecurityQuestion: 'Question',
    securityAnswer: 'Answer',
    invalidSecurityAnswer: 'Answer must be greater than 5 characters',
    emailValidationError: 'Email doesn’t match required pattern',
    passwordValidationError: 'Password must not be common password',
    securityAnswerValidationError:
        'The security question answer is too weak, answer must not be part of the question.',
    unknownValidationError:
        'There was an error creating a new user account. Please contact the help desk.',
    selectSecurityQuestionPrompt:
        'Select a security question and answer so you may automatically reset your password if you have problems signing in to your account.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Bright Cans',
    sleevedCans: 'Sleeved Cans',
    printedCans: 'Printed Cans',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Beer',
    beverageCategoryBeerCraft: 'Beer - Craft',
    beverageCategoryBeerDomestic: 'Beer - Domestic',
    beverageCategoryBeerImport: 'Beer - Import',
    beverageCategoryCsd: 'Carbonated Soft Drink (CSD)',
    beverageCategoryCider: 'Cider',
    beverageCategoryCoffee: 'Coffee',
    beverageCategoryIcedCoffee: 'Iced Coffee',
    beverageCategoryEnergyDrink: 'Energy Drinks',
    beverageCategoryFab: 'FAB’s',
    beverageCategoryFmb: 'Flavored Malt Beverage (FMB)',
    beverageCategoryHardLiquor: 'Hard Liquor',
    beverageCategoryThc: 'Hemp/CBD/THC',
    beverageCategoryJuice: 'Juice',
    beverageCategoryProtein: 'Protein/Milk Based',
    beverageCategoryRtd: 'RTD',
    beverageCategorySpirits: 'Spirits',
    beverageCategorySportsDrink: 'Sports Drinks',
    beverageCategoryTea: 'Tea',
    beverageCategoryIcedTea: 'Iced Tea',
    beverageCategoryWater: 'Water',
    beverageCategoryWine: 'Wine',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Other',

    // Registration Security Questions
    securityQuestionFood: 'What is the food you least liked as a child?',
    securityQuestionStuffAnimal: 'What was the name of your first stuffed animal?',
    securityQuestionAward: 'What did you earn your first medal or award for?',
    securityQuestionComputerGame: 'What was the first computer game you played?',
    securityQuestionMovieQuote: 'What is your favorite movie quote?',
    securityQuestionSportsTeam: 'What was the mascot of the first sports team you played on?',
    securityQuestionDessert: 'What was your grandmother’s favorite dessert?',
    securityQuestionCook: 'What was the first thing you learned to cook?',
    securityQuestionDreamJob: 'What was your dream job as a child?',
    securityQuestionVacation: 'Where did you go for your favorite vacation?',

    // Validation
    required: 'Required',

    // -- EMEA GRAPHICS INTAKE -- //
    // Form Overview
    graphicsSubmissionInfo: 'General Information',
    designInfo: 'Design Information',
    colourInfo: 'Colour Information',
    marketInfo: 'Market Information',
    productQuality: 'Product Quality Specification',
    approverInfo: 'For Approval',
    // General Info
    graphicsSubInfo: 'General Information',
    companyId: 'Company Name and ID',
    contractFiller: 'Contract Filler',
    brandOwner: 'Brand Owner',
    canSize: 'Can Size',
    canType: 'Can Type',
    metalType: 'Metal',
    // Design Info
    designSubInfo: 'Design Information',
    graphicType: 'Type of Request',
    requestTypeNew: 'New Design From Scratch',
    requestTypeExisting: 'New Version on existing Design',
    requestTypePromo: 'Promo Design – based on (but not replacing) existing Design',
    designPoNo: 'Design Development PO no.',
    designName: 'Design Name',
    previousBallDesignNum: 'Previous Ball Design no.',
    newCustRefNum: 'Customer Reference no.',
    oldCustRefNum: 'Previous Customer Reference no.',
    barcode: 'Barcode',
    barcodeHelper: 'Enter the 8 or 13 digit EAN code or the 12 digit UPC code',
    barcodeBarsColor: 'Barcode Colour - Bars',
    barcodeBarsColorHelper: 'Enter the colour shade of the bars',
    barcodeInfillColor: 'Barcode Colour - Infill',
    barcodeInfillColorHelper: 'Enter the colour shade for the barcode background',
    specialFinish: 'Ball Special Finishes',
    specialFinishHelper:
        'Select which of Ball’s Value added products you wish to add to your layout. Note: An additional upcharge might apply.',
    requestType: 'Type of Request',
    // Color Info
    colourSubInfo: 'Colour Information',
    overVarnish: 'Over Varnish',
    basecoatColour: 'Basecoat Colour',
    specialInk: 'Ball Special Ink',
    colourTarget: 'Colour Target',
    colourTargetHelper: 'Specify if you have a colour target you would like Ball to match',
    // Market Info
    marketSubInfo: 'Market Information',
    distributeCountries: 'Countries to Market Artwork',
    distributeCountriesHelperText: 'Select the countries in which you plan to market the artwork',
    depositScheme: 'Deposit Scheme',
    depositSchemeHelper:
        'Specify if artwork will be marketed in country eligible for deposit scheme',
    recycleMark: 'Metals Recycle Forever Mark on Design?',
    recycleMarkHelper: 'Select "No" if you do not want Ball to add the MRF logo on your design',
    glnNumber: 'Customer GLN Number',
    glnNumberHelper: 'Enter your 13 digit GLN code',
    asiCertified: 'Is this design ASI certified?',
    asiCertifiedHelper: 'Select “Yes” if this design is ASI certified',
    // Product Quality Info
    productQualitySubInfo: 'Product Quality Specification',
    productContent: 'Product Content',
    productFormulation: 'Product Formulation',
    refDesignNum: 'Design no. to Reference',
    productPasteurisation: 'Product Pasteurisation',
    productPasteurization: 'Product Pasteurization',
    bpaFree: 'BPANI',
    bpaniId: 'BPANI Identification on Design?',
    // Approval Info (For Approval)
    approvalSubInfo: 'For Approval',
    forApprovalSubTitle:
        'A layout proof is always supplied by Ball for approval of the full layout following amendments. Ball supplies a colour proof (Contract proof or rollouts) for colour approval unless a clear production target is received.',
    proofSession: 'Proof Session Required?',
    attendProduction: 'Attend Production?',
    responsibleForApproval: 'Responsible for Approval',
    emailAddress: 'Email Address',
    // Additional Remarks
    generalCommentsSubInfo: 'General Comments',
    generalComments: 'General Comments',
    generalCommentsHelper: 'Enter general comments you would like Ball to be aware of.',
    additionalRemarksSubInfo: 'Additional Remarks',
    additionalRemarks: 'Additional Remarks',
    responsibleForCompletion: 'Responsible for Completion',
    hard: 'Hard',
    soft: 'Soft',
    addAnotherApprover: 'Add Another Approver',

    // -- Summary Pages -- //
    status: 'Status',

    // -- GRAPHICS SUMMARY -- //
    graphicsSummary: 'Customer Design Project Overview',
    submitNewGraphic: 'Submit New Design',
    submitNewGraphicEmea: 'Start New Design Form',
    cantSaveDraft: '“Company Name and ID” and “Design Name” required to save a draft.',
    draftGraphicsFormsEmea: 'Draft Design Forms',
    draftGraphics: 'Draft Designs',
    deleteYourDraftEmea: 'Delete Your Draft Design',
    graphicDeleteConfirmationEmea: 'Are you sure you want to delete this draft design intake form?',
    graphicPermantlyGone: 'The form will be permanently deleted.',
    learnAboutGraphics: 'Learn About Our Designs',
    graphicsProcessEmea:
        'At Ball we have a dependable process for ensuring that your product is created and delivered with the highest quality and care. Below is a timeline to better help you understand the stages of our design process.',
    graphicProcessEmea:
        'At Ball we have a dependable process for ensuring that your product is created and delivered with the highest quality and care. Below is a timeline to better help you understand our design process.',
    graphicsNoteEmea:
        'Note: If a vendor submitted your design, you will be alerted when your design is received and when your design is completed.',
    inProgress: 'In Progress',
    inProgressGraphics: 'In Progress Designs',
    recentlyCompletedEmea: 'Completed',
    lastNinety: 'Last 90 Days',
    lastThreeSixtyFive: 'Last 365 Days',
    graphicNameAndIdEmea: 'Design Name & Number',
    completeIntakeForm: 'Complete Intake Form',
    graphicSpecialistEmea: 'Graphics Coordinator',
    currentStatus: 'Current Status',
    inStatusSince: 'In Status Since',
    graphicName: 'Design Name',
    availableActions: 'Available Actions',
    graphicStatusHistory: 'Design Status History',
    statusDate: 'Status Date',
    newGraphicLink: 'Start New Design',
    yesDelete: 'Yes, Delete',
    noCancel: 'No, Cancel',
    graphicDraftSavedEmea: 'Design Intake Draft Saved',
    draftHasBeenSuccessfullySaved: 'Your draft has been saved successfully!',
    saveProgressSecondaryText: 'Your draft has been saved successfully',
    backToDashboardEmea: 'Back to My Dashboard',
    viewMyInProgressGraphicsEmea: 'View Design Summary',
    cancelSubmissionEmea: 'Cancel Your Submission',
    intakeCancelConfirmationEmea: 'Are you sure you want to cancel your progress without saving?',
    changesPermanentlyGoneEmea: 'Any changes made to your submission will be permanently gone.',
    deleteProgressEmea: 'Delete Progress',
    saveProgressEmea: 'Save Progress',
    noRecordsFound: 'No Records Found',
    saveProgressAttachments: "Attached documents are not saved when 'SAVE PROGRESS' is selected.",
    selectBillTo: 'Select Sold To',
    billTos: 'Sold Tos',
    designSubmitted: 'Design Development Form Submitted',
    submitGraphics: 'Submit Your Design',
    uploadGraphicsAndSubmit: 'Upload Design & Submit',
    customerAccountHelper: ' Name of Company and ID that needs this design',
    glnNumberError: 'Please enter a 13 digit numeric value',

    // Default Graphic Statuses
    UNDER_REVIEW: 'Design Submitted - Awaiting Review',
    IMAGE_STAGED: 'Design Submitted - Awaiting Review',

    // EMEA Graphics Status
    DSAR: 'Design Submitted - Awaiting Review',
    ARFC: 'Artwork Received; Feasibility Check in Progress	',
    FCOH: 'Feasibility Check on Hold',
    FCCI: 'Feasibility Check Complete; Need Customer Input – Iteration {0}',
    LDIP: 'Layout Development in Progress',
    LDOH: 'Layout Development on Hold',
    LPRA: 'Layout Proof Ready for Approval',
    LAPP: 'Layout Approved',
    CDIP: 'Colour Development in Progress',
    CDOH: 'Colour Development on Hold',
    CTRA: 'Colour Target Ready for Approval',
    CAPP: 'Colours Approved',
    PIPR: 'Proofing in Progress	',
    PCRA: 'Proof Can Ready for Approval',
    DDCA: 'Design Development Completed',
    DDCC: 'Design Development Complete – Change Pending',
    PAPP: 'Proof Can Approved',

    // Graphics Status
    ART_REC: 'Artwork Received; Preparing your Quote',
    QOT_APPRVL: 'Quote Ready for Approval',
    PRF_INPRGRS: 'Creating Proofs',
    PRF_APPRVL: 'Proof Ready for Approval',
    PLT_SCHLD: 'Pilot Scheduled',
    PLT_APPRVL: 'Standard Ready for Approval',
    GRPHC_CMPL: 'Design Complete',

    // -- Delivery Orders --//
    deliveryOrder: 'Delivery Order',
    dateSubmitted: 'Date Submitted',
    submittedByUser: 'User',
    canQuantity: 'Can Quantity',
    endQuantity: 'End Quantity',
    deliveryInstructions: 'Delivery Instructions',
    deliveryOrderDetails: 'Delivery Order Details',
    linkedProductionOrder: 'Production Order',
    linkedATMOrder: 'Make It (ATM) Order',
    shipmentPickupDate: 'Pickup Date',
    deliveryDate: 'Delivery Date',
    shipmentPickupTime: 'Pickup Time',
    deliveryTime: 'Delivery Time',
    shipmentShippedFrom: 'Shipped From',
    shipmentCarrier: 'Carrier',
    deliveryOrderDates: 'Delivery Order Date(s)',
    shipmentDate: 'Shipment Date',
    shipmentTime: 'Shipment Time',
    idNotAvailable: 'ID not available',

    // -- ORDER SHIPPING -- //
    deliveryInstructionsMaxLength: 'Maximum 30 characters',

    // -- EDIT SHIPMENTS SUMMARY -- //
    updateOrder: 'Update Order',
    originallyScheduled: 'Originally scheduled',
    needToChange: 'Need to make a change?',
    shipping: 'Shipping',
    cancelShipment: 'Cancel Shipment',
    customerPickUp: 'Customer Pick Up',
    discardChanges: 'Discard Changes',
    submitChanges: 'Submit Changes',
    shipmentEdited: 'Shipment Edited',
    shipmentCancelled: 'Shipment Cancelled',

    // -- Unauthorized -- //
    unauthorized: 'Unauthorized',
    youAreNotAuthorized: 'You are not authorized to view this page',
    unknownActivity: 'this area',
    dontHaveAccess: "Looks like you don't have access to {{activityString}} for",
    deactivateReactivateUsers: 'Deactivate / Reactivate Users',
    makeItBulkATM: 'Make It Bulk ATM',
    makeItBulkUpload: 'Make It Bulk Upload',
    shipItBulkUpload: 'Ship It Bulk Upload',
    openAccess: 'Open Access',
    onboardingWizard: 'Onboarding Wizard',
    billingAndShipping: 'Billing And Shipping',

    // -- SHIP IT -- //
    changeDeliveryDayWarning:
        'Changing your delivery day could require alterations to your shipment.  Do you want to continue?',
    changeDeliveryDate: 'Change Delivery Date',

    // SHIP IT BULK //
    uploadShipItOrders: 'Zu versendende Bestellung(en) hochladen',
    mapShipItTemplateValues: 'Erfassen Versand Vorlage Werte',
    updateShipItTemplateValues: 'Aktualisieren Versand Vorlage Werte',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'Ordering for your {0} products will close on {1}.  The first available production date is {2}. ',
    CAMPAIGN_UPCOMING:
        'Ordering for your {0} products will begin on {1}.  Orders must be placed by {2} and the first available production date is {3}. ',
    CAMPAIGN_UPDATE:
        'Ordering information for your {0} products has changed.  The updated ordering window is: {1} - {2} and the first available production date is {3}. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Created Date',
    unauthorizedPoTooltip: 'Not authorized to manage POs for this location',
    discard: 'Discard',
    resumeOrder: 'Resume Order',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Manage Products',
    productInformation: 'Product Information',
    activeProducts: 'Active Products',
    inactiveProducts: 'Inactive Products',

    // Countries
    usa: 'USA',
    eu: 'EU',
    uk: 'United Kingdom',
    germany: 'Germany',
    france: 'France',
    canada: 'Canada',
    china: 'China',
    japan: 'Japan',
    southKorea: 'South Korea',
    ausNZ: 'AUS/NZ',
    australia: 'Australia',
    nz: 'New Zealand',
    mexico: 'Mexico',
    india: 'India',
    vietnam: 'Vietnam',
    taiwan: 'Taiwan',
    russia: 'Russia',
    argentina: 'Argentina',
    brazil: 'Brazil',
    paraguay: 'Paraguay',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    noCountryApplies: 'None of the specified countries apply',

    actions: 'Actions',

    supplyPlan: 'Supply Plan',

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Download .PDF',
    effectiveDate: 'Effective Date',
    currency: 'Currency',
    itemDescription: 'Item Description',
    currentBasePrice: 'Current Base Price',
    newBasePrice: 'New Base Price',
    freightChargeFromLocation: 'Freight Charge From Location (if available)',
    freightChargesAvailable: 'Freight Charges (if available)',
    newBaseWithFreight: 'New Base Price With Freight (if available)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',

    // -- KB MAX -- //
    errorProcessing: 'There has been an error processing.',

    // -- MAKE IT BULK UPLOAD -- //
    inactiveProductId: 'This product is inactive and cannot be ordered.',

    // -- ROLES AND ACCESS -- //
    planItPlanItSummary: 'Plan It/Plan It Summary'
};
