export const frFR = {
    // Reusable
    cans: 'Boîtes',
    view: 'Vue',
    design: 'Conception',
    filling: 'Remplissage',
    canHandling: 'Manipulation de Boîte',
    regulations: 'Règlements',
    labLiquid: 'Liquide de laboratoire',
    sustainability: 'Durabilité',
    faq: 'Foire aux questions',
    designInspiration: 'Design Inspiration',
    backToAllProducts: 'Retour aux Autres Productions',
    selectShipTo: 'Sélectionner Envoyez à',
    new: 'Nouveau',
    existing: 'Existant',
    unknown: 'Inconnu',
    underReview: "À l'étude",
    complete: 'Terminé',
    submitted: 'Soumis',
    cancelled: 'Annulé',
    howCanWeHelp: 'Comment pouvons-nous vous aider?',

    // -- NAVIGATION -- //
    home: 'Maison',
    homepage: "GLP page d'accueil",
    ourProcess: 'Le processus',
    processOverview: 'Présentation du Processus',
    labDashLiquid: 'Labo / Liquide',
    handling: 'Manipulation',
    regulatory: 'Réglementaire',
    ourProducts: 'Le produits',
    productPortfolio: 'Portefeuille Client',
    contactUs: 'Contactez Nous',
    logout: 'Se déconnecter',
    backToPortfolio: 'Retour au portfolio',
    navigation: 'Navigation',
    dashboard: 'Tableau de bord',
    payables: 'Compte à recevoir',
    discoverBall: 'Découvrez Ball',
    productionOrderSummary: `Résumé de l'ordre de fabrication`,
    deliveryOrderSummary: 'Résumé des Commandes de Livraison',
    shipmentSummary: `Résumé de l'envoi`,
    paymentsSummary: 'Résumé des paiements',
    ourProcessLearningContent: `Notre processus et contenu d'apprentissage`,
    howToGuides: 'Guides pratiques pour la source',
    guided: 'Guidé',
    buildAndEstimateNav: 'Construire et estimer',
    buildNav: 'Construire',
    graphics: 'Graphiques',
    overview: 'Aperçu',
    theProcess: 'Le processus',
    ballProductPortfolio: 'Portefeuille de Produits Ball',
    guidesLink: 'Guides pratiques',
    faqLink: 'FAQ',
    makeItSummary: 'Résumé de Créer un bon de production (Créer)',
    shipItSummary: `Résumé d'Expédier un bon de production`,
    scrapItRequests: 'Scrap It Requests',
    submitNewGraphicLink: 'Soumettre un nouveau graphique',
    submitNewLiquidTestLink: 'Soumettre un nouveau test de liquide',
    submittedLiquidTests: 'Tests de liquides soumis',
    openLiquidTests: 'Ouvrir les tests de liquides',
    admin: 'Administrateur',
    userAdmin: 'Administrateur des Utilisateurs',
    manageUsers: 'Gérer les Utilisateurs',
    manageLocations: 'Gérer les Emplacements',
    addShipTo: 'Add Ship To',
    customerInformation: 'Customer Information',
    newShipToInformation: 'New Ship To Information',
    addNewUser: 'Ajouter un Nouvel Utilisateur',
    myAccount: 'Mon Compte',
    pendingAccessRequests: 'Demandes D’accès en Attente',
    myAccess: 'Mon Accès',
    graphicsInProgress: 'Graphiques en Cours',
    exit: 'Quitter',
    editUser: 'Modifier L’utilisateur',
    viewEditUser: 'Afficher/Modifier L’utilisateur',
    lastLogin: 'Dernière connexion',
    authorizationToManufacture: 'Autorisation de fabrication',
    comingSoon: '<i>Bientôt<i/>',
    makeItDrafts: 'Brouillons de Créer un bon de production (Créer)',
    checkPendingRequests:
        'Comprobar las solicitudes pendientes y el registro y gestionar los usuarios activos.',
    monitorAndRequest: 'Surveiller et demander de nouveaux emplacements.',
    giveAccountAccess: 'Donner l’accès au compte à de nouveaux utilisateurs.',
    viewPricingInformation: 'Voir les informations sur les prix.',
    viewActiveAndInactiveProducts:
        'Afficher les produits actifs et inactifs et gérer les états des produits.',
    buildSubmitUpdate: 'Créer, soumettre et mettre à jour vos prévisions de commandes.',
    createMakeItOrders: 'Créer des commandes « Make It ».',
    scheduleOrders: 'Planifier les ordres de livraison et les ramassages.',
    learnMoreGraphics:
        'En savoir plus sur la séparation des couleurs, la soumission des graphismes et la préparation des fichiers.',
    ensureHandling:
        'Assurez-vous que votre équipe sait comment manipuler vos canettes une fois qu’elles sont arrivées.',
    whyTesting:
        'Pourquoi le test de compatibilité de votre boisson est-il essentiel pour le processus de mise en canette?',
    bestCanningPractices: 'Les meilleures pratiques pour la mise en canette de votre liquide.',
    learnAboutRegulations: 'Découvrir les réglementations spécifiques à chaque pays.',
    helpPromoteSustainability:
        'Vous aider à promouvoir les efforts de votre marque en matière de durabilité.',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Bienvenue à <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> sera votre guichet unique pour les graphiques à la livraison. Au fil du temps, cette page comportera des mesures utiles telles que les commandes de production "Make It", les commandes de livraison "Ship It", la gestion des produits, etc. Nous apprécions vos commentaires et votre patience pendant que nous améliorons votre expérience.',
    readyToOrder: 'Prêt à commander des produits?',
    makeIt: 'Fais-le',
    readyToSchedule: 'Prêt à planifier une expédition?',
    shipIt: 'Expédier',
    shipItOnly: 'Expédier Uniquement',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'Administration des Utilisateurs',
    setupNewUser:
        'Configurez un nouvel utilisateur avec les autorisations et les emplacements appropriés dans <i>The Source</i>.',
    userAdminDashboard: 'Tableau de Bord D’administration des Utilisateurs',
    viewPendingRequests:
        'Afficher les demandes d’accès en attente, les nouvelles demandes d’utilisateurs en attente et les utilisateurs actifs dans <i>The Source</i>.',
    viewUserAdminDashboard: 'Vue Tableau de Bord D’administration des Utilisateurs',

    // -- GRAPHICS WIDGET -- //
    startGraphicsProcess:
        'Commencez le processus graphique en téléchargeant un nouveau graphique pour vos produits.',
    newGraphicLink: 'Démarrer un nouveau graphique',
    checkGraphicsProcess:
        "Vérifiez où en sont vos graphiques et s'il y a des actions que vous devez entreprendre.",
    viewInProgressGraphics: 'Voir les graphiques en cours',

    // -- ORDERING WIDGET -- //
    viewProductPlanning: 'Voir la planification des produits',
    productPlanningTileDesc:
        "Consultez les bilans de production et les expéditions prévues jusqu'à seize semaines.",
    makeProducts: 'Fabriquer des produits',
    makeProductsTileDesc:
        'Créer et mettre à jour les commandes Créer. Vérifiez le statut de vos commandes Créer.',
    viewEditMakeItOrders: 'Afficher/modifier les commandes Créer',
    shipProducts: 'Expédier produits',
    shipProductsTileDesc:
        'Programmer et mettre à jour les commandes et les cueillettes à Expédier. Vérifiez le statut de vos commandes à Expédier.',
    viewEditShipItOrders: 'Afficher/modifier les commandes à Expédier',

    // -- DASHBOARD -- //
    makeItOrders: 'Commandes à fabriquer',
    orderSummary: 'Synthèse de la commande',
    openOrders: 'Commandes ouvertes',
    agedBalance: 'Vieillissement du solde',
    paymentsPastDue: 'Paiements en souffrance',
    nextWeek: 'Prochaine semaine',
    twoToFourWeeks: 'Dans 2 à 4 semaines',
    fiveToEightWeeks: 'Dans 5 à 8 semaines',
    ninePlusWeeks: 'Dans plus de 9 semaines',
    shipItOrders: 'Commandes à expédier',
    shipmentsSummary: 'Synthèse de l’expédition',
    arrivingThisWeek: 'Arrive cette semaine',
    futureShipments: 'Expéditions futures',
    viewAllFutureShipments: 'Voir tous les expéditions futurs',
    createNewShipItOrder: 'Créer une nouvelle commande Ship It',
    createNewMakeItOrder: 'Créer une nouvelle commande Make It',
    dataIsFiltered: `Les données sont filtrées. Cliquez sur l'icône pour supprimer le filtre:`,
    draftOrders: 'Draft Orders',
    agedSku: 'UGS à échéance',
    agedSkus: 'UGS à échéance',

    // -- FAQ Page -- //
    searchFAQs: 'Rechercher foire aux questions',
    searchFAQplaceholder: 'Cans, Bottles, etc!',

    // -- QUICK VIEW -- //
    production: 'Production',
    limitedProductionRun: 'Production limitée',
    seeFullProductDtls: 'Vue Detaillée de la Production',
    timesPerYear: 'Fois par An',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Renseignements sur le produit',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Fenêtre de Verrouillage de « Make It »',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'Afficher mes informations sur le produit',
    customerProductId: 'Identifiant du produit client',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Identifiant du produit Ball',

    // -- PRODUCT PORTFOLIO -- //
    leadTimesSubjectToChange: '*Tous les délais sont des estimations et peuvent être modifiés.',
    currentBallCustomers: 'Clients actuels de Ball:',
    leadTimesProduction:
        'Les délais de production pour les nouveaux graphiques commencent lorsque les graphiques sont terminés et approuvés.',
    leadTimesMayVarySixteenOzAlumitek: `Ce produit est actuellement disponible. Les délais de réception de votre première commande peuvent varier. Contactez votre représentant commercial pour plus de détails.`,
    all: 'Tout',
    bottles: 'Buteilles',
    ends: 'Couvercles',
    end: 'Couvercle',
    closures: 'Bouchons',
    closure: 'Fermeture',
    lookingForInspiration: 'Inspiration',
    pallets: 'Palettes',
    itemsPerPallet: 'Nombre par palette',
    lookingForSomething: 'Je netrouve pas ce dont vous avez besoin?',
    sendUsYourComments: 'Que cherchez-vous?',
    minOrderPallet: 'Commande Minimum en Palettes',
    cansPerPallet: 'Bidons par Palette',
    quickLook: "Coup d'œil rapide",
    select: 'Sélectionner',
    seeDetailsBtn: 'Voir les Détails Complets du Produit',
    compareProducts: 'Comparer les Produits',
    pleaseSelectProducts: 'Veuillez sélectionner des produits pour obtenir un devis',
    endTile: 'Couvercles',
    compare: 'Comparez',
    myProjects: 'Mes projets',
    belowAreYourSavedProjects:
        'Vous trouverez ci-dessous les projets dont vous avez enregistrés au cours des 180 derniers jours.',
    projectName: 'Nom de projet',
    productCode: 'Code de produit',
    savedOn: 'Enregistré le',
    viewProject: 'Afficher le projet',
    capacityCheckStatus: 'Statut du contrôle des capacités',
    dateRequested: 'Date demandée',
    ropp38CappingText:
        'Product requires special capping. Please contact Silgan 765-983-9282 for pallet specifications.',

    // -- FACETS -- //
    filterBy: 'Filtrer par',
    clearAll: 'Tout effacer',
    // Facet Category
    stylesCategory: 'Gamme de produits',
    sizesCategory: 'Taille',
    featuresCategory: 'Ameliorations de remplissage',
    endsCategory: 'Prend fin',
    // Facet Tooltips
    stylesTooltip: 'Gamme de produits info-bulle',
    sizesTooltip: 'Taille info-bulle',
    featuresTooltip: 'Ameliorations de remplissage info-bulle',
    endsTooltip: 'Prend fin info-bulle',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Demander un Devis',
    productDetails: 'Détails du Produit',
    printGuide: "Manuel d'impression",
    customerDesignGuide: "Manuel d'impression client",
    download: 'Télécharger',
    canSpecification: 'Spécification de Bidons',
    dieLine: 'Die Line',
    palletSpecifications: 'Spécifications de Palette',
    greatFor: 'Idéal pour',
    specifications: 'Spécifications',
    currentLeadTime: 'Délai actuel',
    fillingAvailability: 'Disponibilité de Remplissage',
    compatibleEnds: 'Couvercles Compatibles',
    weeks: 'Semaines',
    months: 'Mois',
    minimum: 'Le Minimum',
    productEnhancements: 'Ameliorations de Remplissage',
    compatibleFilling: 'Compatibilité de Remplissage',
    pasteurization: 'Pasteurisation',
    retort: 'Pasteurisateur',
    hotFill: 'Remplissage à chaud',
    coldFill: 'Remplissage à froid',
    color: 'Couleur',
    aluminum: 'Aluminum',
    // Ordering
    ordering: 'Commande',
    pricePerThousand: 'Prix au 1000',
    quantityPerPallet: 'Quantité par palette',
    minOrder: 'Commande minimum',
    // Design Specs
    designSpecs: 'Spécifications du Décor',
    circumference: 'Circonférence',
    designHeight: 'Hauteur du Décor',
    maxTALength: 'Zone de Texte Maxi (Longueur)',
    maxTAHeight: 'Zone de Texte Maxi (Hauteur)',
    maximumColors: 'Maximum de Couleurs',
    windowLimitWidth: 'Hauteur limite du cadre',
    windowLimitHeight: 'Largeur limite du cadre',
    underOverlap: 'Zone bande de recouvrement',
    designLength: 'Longueur du décor (circonférence)',
    // Can Specs
    canSpecs: 'Spécification de la Boîte',
    endSize: 'Diametre couvercle',
    bodyOutsideDiameter: 'Diamètre extérieur de corps',
    flangedCanHeight: 'Hauteur de boîte totale',
    coldFillVolume: 'Volume de remplissage (cold-fill/ à froid)',
    retortFillVolume: 'Volume de remplissage (retort-fill /pour pasteurisation)',
    flangedCanWidth: 'Bord à Sertir',
    endDiameter: "Diamètre d'extrémité",
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Effets et fonctionnalités disponibles pour cette boîte',
    canFinishesAndEffects: 'Finitions de la boîte et effets',
    inks: 'Encres',
    overvarnish: 'Vernis exterieur',
    effects: 'Effets',
    specialFeatures: 'Particularités',

    // -- END DETAILS -- //
    endDetails: 'Configuration du couvercles',
    endFinishesAndEffects: 'Finitions du couvercles et effets',
    compatibleCans: 'Boîtes Compatibles',
    endFeaturesAndEffects: 'Finitions du couvercles et effets',
    orderIndividually: 'Peut être commandé individuellement',
    roppClosures: 'ROPP Closures sont vendues via une tierce partie',
    endsPack: "Pack d'informations sur les couvercles",

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Ajouter un produit',
    details: 'Détails',
    name: 'Nom',
    type: 'Type',
    maxColors: 'Couleurs max',
    innovativeFeatures: 'Des fonctionnalités innovantes',
    leadTimes: 'Les délais',
    currentFillingAvail: 'Disponibilité actuelle de remplissage',
    products: 'Produits',
    addAnotherToCompare: 'Ajouter un Autre Produit à Comparer',
    removeSelection: 'Supprimer la sélection',
    addToCompare: 'ajouter pour comparer',
    prodComparisonDesc:
        "Comparez jusqu'à trois produits différents à la fois, quel que soit le volume ou le style. Découvrez les produits proposés",
    prodEstimateDesc:
        "Notre estimateur peut vous aider à mieux comprendre le coût de la commande de bidons avec nous. Gardez quelques éléments à l'esprit lors de la création de votre estimation",
    prodHasMinOrder: 'Chaque produit a une commande minimum',
    orderInFullPallets: "Les devis et commandes ne peuvent être placés qu'en palettes complètes",
    orderInFullTrucks:
        'Nous recommandons de commander en camions complets, la plupart des camions peuvent contenir environ 25 palettes. Moins de camions pleins peuvent entraîner des frais supplémentaires',
    productComparison: 'Comparaison des produits',
    compareBuildEstimate: 'Comparer, construire et estimer',
    compareBuild: 'Comparer et construire',
    myProject: 'Mon projet',

    // -- CONTACT FORM -- //
    yourName: 'Votre Nom',
    email: 'Courriel',
    yourCountry: 'Votre pays de remplissage',
    subjectLine: 'Objet',
    contactMessage: 'Dites-nous comment nous pouvons aider…',
    submit: 'Soumettre',
    cancel: 'Annuler',
    saveDraft: 'Enregistrer le brouillon',
    sendUsANote: 'Comment pouvons nous aider?',
    msgSuccessful: 'Message envoyé avec succès.',
    msgFailed: "Le message n'a pas pu être envoyé, veuillez réessayer à un autre moment.",
    sending: 'Envoi',
    close: 'Ferme',
    contactReason: 'Motif du contact',
    contact: "J'ai une question",
    support: "J'ai besoin d'aide",
    cantLogIn: 'Je ne peux pas me connecter',

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Demander un Devis',
    desiredAnnualQuantity: 'Quantité annuelle souhaitée',
    enterQuantity: 'Entrez la Quantité',
    // wouldYouLikeEndsWithThisProduct: 'Souhaitez-vous des couvercles avec ce produit?',
    remove: 'Retirer',
    estimateFailedToSend:
        "L'estimation n'a pas pu être envoyée, veuillez réessayer à un autre moment",
    productEstInquiry: "Demande d'estimation de produit",
    submittingEstimate: 'Soumettre les informations ci-dessous pour une estimation',
    enterValueGreaterThan: 'Veuillez saisir une valeur supérieure à 0',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'De retour à la maison',
    thankYou: 'Merci',
    yourRequestHasBeenSubmitted: 'Votre requête à bien été envoyée!',
    someoneFromOurTeamWillBeInTouchSoon: 'Un membre de notre équipe vous contactera bientôt.',

    // -- REGISTRATION -- //
    registrationHeader: 'Faisons rouler le Ball',
    registrationDescription:
        "Nous y sommes presque, nous avons juste besoin d'un peu d'informations de votre part pour vous amener au portail client Ball où vous pourrez en savoir plus sur les boîtes, comment commander et estimer les coûts.",
    region: 'Région',
    language: 'Langue',
    dateFormat: 'DATE FORMAT',
    aboutYourCompany: 'À propos de votre entreprise',
    companyName: 'Nom de l’entreprise',
    country: 'Pays',
    stateProvince: 'État/Province',
    city: 'Ville',
    postalCode: 'Code postal',
    yearsInBusiness: "Ans d'activité",
    numberOfEmployees: "Nombre d'employés",
    estimatedAnnualRevenue: 'Revenus annuels estimés',
    beverageInRetail: 'Avez-vous actuellement une boisson dans le commerce de détail?',
    currentPackageType: 'Type de package actuel',
    beveragePackagingNeeds: "Vos besoins en matière d'emballage de boissons",
    beverageCategory: 'Catégorie de la boisson',
    productSize: 'Taille du produit',
    expectedAnnualQuantity: 'Quantité annuelle prévue',
    canningLine: "Possédez-vous ou prévoyez-vous d'acheter une ligne de mise en conserve?",
    copackerName: 'Nom du co-emballeur',
    fillingCountry: 'Pays de remplissage',
    fillingCity: 'Remplir la ville',
    additionalComments: 'Autres commentaires: (facultatif)',
    artworkComments: 'Commentaires sur les illustrations (facultatif)',
    setupCustomerPortalAccount: 'Configurer votre compte de portail client',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phoneNumber: 'Numéro de téléphone',
    password: 'Mot de passe',
    confirmPassword: 'Confirmer le mot de passe',
    alreadyRegistered: 'Déjà enregistré?',
    accountCreated: 'Ce compte courriel est déjà lié à la Source.',
    signIn: 'Connectez-vous au portail client',
    signInConfirmation:
        'Veuillez vous connecter à La Source et, si nécessaire, réinitialiser votre mot de passe.',
    yes: 'Oui',
    no: 'Non',
    incorrectEmailAddress: 'Adresse e-mail incorrecte',
    atLeastEightLetters: 'Au moins 8 lettres',
    atLeastOneCapitalLetter: 'Au moins une lettre majuscule',
    atLeastOneLowerCaseLetter: 'Au moins une lettre minuscule',
    atLeastOneNumber: 'Au moins un numéro',
    mustNotBeACommonPassword: 'Ne doit pas être un mot de passe commun',
    passwordsShouldMatch: 'Les mots de passe doivent correspondre',
    year: 'an',
    printType: 'Souhaitez-vous l’un des types de canons suivants?',
    iAgree: "J'accepte",
    termsOfUse: "les conditions d'utilisation et les politiques de confidentialité",
    million: 'Million',
    newToBeverage: 'Nouveau aux Boissons',
    title: 'Titre',
    jobTitle: 'Job Title',
    selectSecurityQuestion: 'Question',
    securityAnswer: 'Réponse',
    invalidSecurityAnswer: 'La réponse doit être supérieure à 5 caractères',
    emailValidationError: "L'e-mail ne correspond pas au modèle requis",
    passwordValidationError: 'Le mot de passe ne doit pas être un mot de passe commun',
    securityAnswerValidationError:
        'La réponse à la question de sécurité est trop faible, la réponse ne doit pas faire partie de la question.',
    unknownValidationError:
        "Une erreur s'est produite lors de la création d'un nouveau compte utilisateur. Veuillez contacter le service d'assistance.",
    selectSecurityQuestionPrompt:
        'Sélectionnez une question et une réponse de sécurité afin de réinitialiser automatiquement votre mot de passe si vous avez des problèmes de connexion à votre compte.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Bright Cans',
    sleevedCans: 'Sleeved Cans',
    printedCans: 'Printed Cans',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Bière',
    beverageCategoryBeerCraft: 'Bière – Artisanale',
    beverageCategoryBeerDomestic: 'Bière - Nationale',
    beverageCategoryBeerImport: 'Bière - Importation',
    beverageCategoryCsd: 'Boisson gazeuse (CSD)',
    beverageCategoryCider: 'Cidre',
    beverageCategoryCoffee: 'Café',
    beverageCategoryIcedCoffee: 'Café glacé',
    beverageCategoryEnergyDrink: 'Boissons énergisantes',
    beverageCategoryFab: 'FAB’s',
    beverageCategoryFmb: 'Boisson de malt aromatisée (FMB)',
    beverageCategoryHardLiquor: 'Liqueur Dure',
    beverageCategoryThc: 'Chanvre/CBD/THC',
    beverageCategoryJuice: 'Jus',
    beverageCategoryProtein: 'À base de protéines/lait',
    beverageCategoryRtd: 'RTD',
    beverageCategorySpirits: 'Boissons spiritueuses',
    beverageCategorySportsDrink: 'Boissons sportives',
    beverageCategoryTea: 'Thé',
    beverageCategoryIcedTea: 'Thé glacé',
    beverageCategoryWater: 'Eau',
    beverageCategoryWine: 'Vin',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Autres',

    // Registration Security Questions
    securityQuestionFood:
        'Quelle est la nourriture que vous aimiez le moins quand vous étiez enfant ?',
    securityQuestionStuffAnimal: 'Quel est le nom de votre premier animal en peluche ?',
    securityQuestionAward: `Qu'aviez-vous accompli lorsque vous avez remporté votre première médaille ou récompense ?`,
    securityQuestionComputerGame: 'Quel fut le premier jeu vidéo auquel vous avez joué ?',
    securityQuestionMovieQuote: 'Quelle est votre citation de film favorite ?',
    securityQuestionSportsTeam:
        'Quelle était la mascotte de la première équipe de sport pour laquelle vous avez joué ?',
    securityQuestionDessert: 'Quel était le dessert préféré de votre grand-mère ?',
    securityQuestionCook: 'Quelle fut la première chose que vous avez appris à cuisiner ?',
    securityQuestionDreamJob: 'Quel travail rêviez-vous de faire quand vous étiez enfant ?',
    securityQuestionVacation: 'Quel était votre endroit préféré pour vos vacances ?',

    // Validation
    required: 'Obligatoire',

    // Graphic Intake - Vendor
    formOverview: 'Présentation du formulaire',
    graphicsSubmissionInfo: 'Informations sur la Soumission des Graphiques',
    productOptions: 'Options de Produit',
    colorMatchingOptional: 'Correspondance des Couleurs (Facultatif)',
    uploadGraphicsAndSubmit: 'Télécharger des Graphiques et Soumettre',
    submitGraphics: 'Soumettez vos Graphiques',
    graphicUploadTimeout:
        'téléchargement graphique chronométré. S’il vous plaît essayer à nouveau plus tard.',
    requiresSpecialty:
        'vous avez noté que votre produit nécessite une couche ou une spécialité spéciale, veuillez sélectionner votre revêtement, finition ou effet. S’il vous plaît essayer à nouveau plus tard.',
    requiresSubmitter: 's’il vous plaît ajouter une adresse e-mail valide.',
    fillOutRequiredFields:
        'Tous les champs obligatoires doivent être remplis avant de soumettre la demande.',
    dateErrors:
        'Veuillez vous assurer que la date demandée, la date de vente au détail et la date de vente au détail sont des dates valides.',
    uploadFileError: 'Veuillez télécharger un fichier zip valide avant de soumettre',
    intakeFailedToSave: 'Formulaire d’admission graphique n’a pas réussi à enregistrer',
    saveProgress: 'Enregistrer la progression',
    graphicDraftSaved: 'Projet d’admission Graphique Enregistré',
    promotionStartDatePrior:
        'Veuillez vous assurer que votre date de début de promotion est antérieure à la date de fin de votre promotion',
    promotionStartDateAfter:
        'Veuillez vous assurer que votre date de promotion sera après la date de votre demande',
    unknownError:
        'une erreur inconnue s’est produite.  S’il vous plaît essayer à nouveau plus tard.',

    requestedByDate: 'Date Demandée',
    requestedDateHelper: 'Quel type de graphique souhaitez-vous soumettre?  ',
    inRetailDate: 'Date de vente au détail',
    shortTermStartDateHelper: 'Date à laquelle les produits finis seront en vente au détail',
    validInRetailDateError:
        'S’il vous plaît assurez-vous que la date de vente au détail est après la date demandée',
    outOfRetailDate: 'Date de non-vente',
    shortTermEndDate:
        'Date à laquelle on estime que les produits finis ne sont plus en vente au détail',
    validOutOfRetailError:
        'S’il vous plaît assurez-vous que la date de vente au détail est après la date de vente au détail',
    iDontKnow: 'Je ne sais pas',
    typeOfGraphic: 'Quel type de graphique souhaitez-vous soumettre ?',
    graphicType: 'Type graphique',
    blankForm: 'Formulaire vierge',
    replaceExistingGraphics: 'Remplacer les graphiques existants',
    billToAddress: 'Facture d’entreprise à',
    graphicYouAreUpdating: 'Graphique que vous mettez à jour',
    previousSubmissions: 'Soumissions Antérieures',
    copyWarning:
        'Vous êtes sur le point de soumettre un nouveau graphique, en tirant parti des informations fournies pour un graphique précédent. Veuillez examiner attentivement et mettre à jour tous les champs afin d’assurer l’exactitude du nouveau graphique.',
    revisionWarning:
        'Vous êtes sur le point de soumettre une révision pour un graphique actif. Veuillez examiner attentivement et mettre à jour tous les champs afin d’assurer l’exactitude du nouveau graphique.',
    revisionToAnExistingGraphic: 'S’agit-il d’une révision d’un graphique existant?',
    existingGraphicName: 'Nom graphique existant',
    revisionGraphicNameHelper: 'C’est le nom du graphique que vous révisez',
    enterGraphicNumberOrName: 'Entrez un numéro ou un nom graphique',
    existingGraphicNumber: 'Numéro graphique existant',
    revisionGraphicNumberHelper: 'C’est le numéro graphique que vous révisez',
    newGraphicSubmissionName: 'Nouveau nom de soumission graphique',
    submissionNameHelper: 'Nommez cette soumission pour une référence future',
    revisedSubmissionName: 'Quel projet souhaitez-vous réviser?',
    alphanumericPattern: 'S’il vous plaît taper un nom à l’aide alphanumériques',
    conversion: `S’agit-il d'une conversion dure ou douce?`,
    conversionTooltip:
        'Une conversion matérielle sera obsolète et remplacera complètement la version sélectionnée de ce graphique. Une conversion douce vous permettra de conserver la version sélectionnée et la nouvelle version de ce graphique.',
    shortTerm: `S'agit-il d'une promotion à court terme?`,
    //graphicsContact: '',
    //customerSKUNo: '',
    //colorStandardsApprover: '',
    //colorMatchNote: '',
    //colorMatchNoteText: '',
    //canFinishes: '',
    //colorMatching: '',
    //dateCodeMatching: '',
    //colorMatch: '',
    graphicsContactInformation: 'Infromation de Contact Graphique',
    customerNameHelper: 'Société se soumettant au nom de',
    yourCompany: 'Votre Entreprise',
    nameHelper: 'Nom de la personne soumettant ce formulaire',
    phoneNumberHelper: 'Numéro de téléphone de la personne soumettant ce formulaire',
    emailAddress: 'Email',
    submitterEmailHelper: 'Adresse e-mail de la personne soumettant ce formulaire',
    colorStandards: `Informations sur l'approbateur Color Standars`,
    approverName: `Nom de l'approbateur`,
    approverNameHelper: 'Nom de la personne qui approuvera les normes',
    approverEmail: `Courriel de l'approbateur`,
    approverEmailHelper: `Adresse e-mail de l'approbateur`,
    address: `Adresse de livraison de l'approbateur`,
    addressHelper: 'Rue, Ville, Zip',
    hundredMaxLengthError:
        'S’il vous plaît assurez-vous que la valeur du champ est de 100 caractères ou moins',
    alphaNumericPatternError: 'S’il vous plaît assurez-vous que le champ est alphanumérique',
    numericPatternError: 'Entrez une valeur numérique',
    thirtyMaxLengthError:
        'S’il vous plaît assurez-vous que la valeur du champ est de 30 caractères ou moins',
    fiftyMaxLengthError:
        'S’il vous plaît assurez-vous que la valeur du champ est de 50 caractères ou moins',
    customerGraphicsID: `Numéro d'identification graphique du client`,
    customerGraphicsIDHelper: `Numéro d'identification graphique du client (facultatif)`,
    customerSKU: 'Numéro SKU',
    customerSKUHelper: 'Numéro SKU du client (facultatif)',
    upc: 'UPC # / EAN',
    upcHelper:
        'Fournir le numéro UPC complet ou le code EAN, y compris les chiffres principaux (facultatif) ',
    productCodeHelper: 'Taille du produit sur lequel le graphique sera exécuté',
    neckSize: 'Taille du cou',
    neckSizeTooltip:
        'La taille du cou devrait être de 206 à moins que Ball n’ait déjà autorisé 209',
    lookingForDesignInspiration: `À la recherche d'inspiration design?`,
    specialties: 'Votre produit nécessite-t-il des revêtements ou des spécialités spécifiques?',
    ink: 'Encre',
    inkHelper: `Options d'encre disponibles pour ce produit`,
    effect: 'Effets',
    effectHelper: `Options d'effets disponibles pour ce produit`,
    overvarnishHelper: 'Options de sur-vernis disponibles pour ce produit',
    doYouNeedColorMatching: `Avez-vous besoin de faire correspondre la couleur à l'aluminium existant ou à d'autres garanties?`,
    colorMatchComment: 'Veuillez décrire la couleur que vous souhaitez associer à votre garantie.',
    comments: 'Commentaires',
    colorMatchingText: `La correspondance des couleurs des canettes en aluminium existantes (ne doit pas nécessairement être produite par Ball) ou d'autres supports imprimés (emballage en carton, emballage de canettes / bouteilles / papier imprimé, etc.) est possible. À la fin de ce formulaire d'admission, vous recevrez un document imprimable à inclure dans votre colis avec votre échantillon de couleurs assorties, et l'adresse où vous devez envoyer votre échantillon.`,
    colorStandardText:
        'Ball fournit une norme de couleur pour chaque nouvelle étiquette et toutes les étiquettes sont livrées avec des changements de couleur.',
    dateCode: 'Correspondance de code de date',
    dateCodeText: `Code de 9 ou 10 caractères situé près du code à barres CUP. L’emplacement exact varie selon la taille de la boîte et le fabricant.`,
    dateCodeMinMaxError: 'Veuillez vous assurer que le code de date comporte 9 ou 10 caractères',
    invalidIntakeFile: 'Ce type de fichier n’est pas valide. Veuillez joindre un fichier .zip',
    onUploadFileError: '*Veuillez télécharger un fichier zip valide',
    onFileSizeUploadError: 'File must be below 12MB',
    uploadSection1:
        'Toutes les boîtes à Ball seront exécutées sur un substrat en aluminium avec un vernis brillant par-dessus, sauf indication contraire.',
    uploadSection2:
        'La disponibilité des encres ou vernis spéciaux peut être limitée; demander ces articles sur ce formulaire ne garantit pas la disponibilité.',
    dragAndDropZipFileHere: 'Faites glisser et déposez un fichier ici ou cliquez sur.',
    dragAndDropCsvFileHere: 'Faites glisser et déposez un fichier ici ou cliquez sur.',
    dragAndDropFileHere: 'Faites glisser et déposez un fichier ici ou cliquez sur.',
    dropHere: 'Déposer le fichier ici',
    fileUploadedSuccessfully: 'Fichier téléchargé avec succès:',
    acceptedFileTypes:
        'Type de fichier accepté : fichier Zip qui peut inclure un fichier TIFF et/ou un fichier Illustrator collecté avec des images et des polices fournies ou converties en contours.',
    weRecommendPDF: `Nous vous recommandons d'inclure un PDF en lecture seule dans votre fichier zip collecté à des fins de visualisation uniquement.`,
    acceptedFileTypesEmea: 'Accepted file types',
    weRecommendPDFEmea:
        'Note: .Zip files can include a TIFF and/or collected Illustrator file with images and fonts supplied or converted to outlines. We recommend including a read-only PDF in your collected zip file for viewing purposes only.',
    onUploadFileErrorEmea:
        '*One of the files you are trying to upload is not of an approved file type. Please remove and upload an approved file type (.pdf, .ai, .psd, .doc, .docx, .xls, .xlsx, .zip).',

    saveProgressAttachments:
        "Les documents joints ne sont pas sauvegardés lorsque l'option «Enregistrer la progression» est sélectionnée.",
    clearYourForm: 'Effacer votre Formulaire',
    clearFormConfirmation: 'Etes-vous sûr de vouloir effacer le formulaire ?',
    formInfoWillBeLost: 'Toute information sera perdue.',
    yesClearForm: 'Oui, forme claire',
    excitedThankYou: 'Merci!',
    graphicsSubmitted: 'Vos graphiques ont été soumis pour examen!',
    submissionText:
        'Notre équipe examinera la pertinence de votre soumission et élaborera un devis. Un graphiste vous contactera sous 2 jours ouvrés.',
    subText: `Votre formulaire peut également être consulté. Veuillez l'enregistrer pour vos dossiers.`,
    intakeForm: 'Formulaire d’admission: ',
    pleasePrintColorMatchingForm:
        'Pour imprimer ou télécharger une copie de votre formulaire correspondant aux couleurs, veuillez ',
    clickHere: 'cliquez ici.',
    startNewForm: 'Démarrer un Nouveau Formulaire',
    graphicsColorSubmitted: `Merci d'avoir soumis votre mise à jour!`,
    submissionColorText:
        'Notre équipe examinera cette mise à jour et un spécialiste graphique vous contactera sous peu. Si vous souhaitez revoir votre soumission ou réimprimer un formulaire de correspondance des couleurs, veuillez cliquer ici.',
    viewColorMatchingInfo: 'Afficher les informations de correspondance des couleurs',
    graphicsInformationMessageIE:
        'Vos informations de résumé graphique sont disponibles pour vous ouvrir ou télécharger dans votre navigateur.',
    colorMatchingInformationMessageIE:
        'Vos informations de correspondance de couleur sont disponibles pour vous ouvrir ou télécharger dans votre navigateur.',
    companyId: 'ID de l’entreprise',
    submissionType: 'Type de soumission',
    billTo: 'Projet de loi à',
    approverAddress: `Adresse de l’approbateur`,
    approverCountry: `Pays de l’approbateur`,
    graphicsSummary: 'Résumé Graphique',
    shippingAddress: 'Adresse de livraison',
    yourColorMatchingInfo: 'Vos informations de correspondance de couleur',
    pleasePrintThisCM:
        'S’il vous plaît imprimer ce et inclure dans l’expédition avec votre objet correspondant à la couleur.',
    submissionName: 'Nom de soumission',
    colorMatchingDetails: 'Détails de correspondance de couleur',
    cantSaveDraft: 'Nom de soumission requis pour enregistrer une ébauche.',
    resolveFormErrors: 'Resuelva los errores de formulario para guardar un borrador.',
    validEmailRequired: 'Un e-mail valide est nécessaire pour enregistrer un brouillon.',
    hard: 'Dur',
    soft: 'Doux',
    addAnotherApprover: 'Add Another Approver',

    // -- EMEA GRAPHICS INTAKE -- //
    graphicsSubInfo: 'Données générales',
    companyIdHelper: ' ',

    // -- ORDER SHIPPING -- //
    deliveryInstructionsMaxLength: 'Maximum de 30 caractères',

    // -- GRAPHICS SUMMARY -- //
    submitNewGraphic: 'Soumettre un nouveau graphique',
    draftGraphicsForms: 'Rédiger des formulaires graphiques',
    draftGraphics: 'Graphiques brouillons',
    deleteYourDraft: 'Supprimer Votre Projet Graphique',
    graphicDeleteConfirmation:
        'Etes-vous sûr que vous voulez supprimer ce projet de formulaire d’admission graphique?',
    graphicPermantlyGone: 'Le formulaire sera définitivement supprimé.',
    learnAboutGraphics: 'En savoir plus sur nos graphiques',
    graphicsProcess:
        'Chez Ball, nous avons un processus fiable pour s’assurer que votre produit est créé et livré avec la plus haute qualité et de soins. Voici une chronologie pour mieux vous aider à comprendre les étapes de notre processus graphique.',
    graphicsNote:
        'Remarque : Si un fournisseur a soumis votre graphique, vous serez alerté lorsque votre graphique sera reçu et lorsque votre graphique sera terminé.',
    inProgress: 'En cours',
    inProgressGraphics: 'Graphiques en cours',
    recentlyCompleted: 'Récemment terminé',
    lastNinety: 'Au cours des 90 derniers jours',
    lastThreeSixtyFive: 'Au cours des 365 derniers jours',
    graphicNameAndId: 'Nom graphique & ID',
    completeIntakeForm: 'Formulaire d’admission complet',
    graphicSpecialist: 'Spécialiste graphique',
    currentStatus: 'État actuel',
    inStatusSince: 'En statut depuis',

    // Graphics Status
    ART_REC: 'Œuvres d’art reçues; Préparation de votre devis',
    QOT_APPRVL: 'Devis prêt pour approbation',
    PRF_INPRGRS: 'Création de preuves',
    PRF_APPRVL: 'Preuve prête à être approuvée',
    PLT_SCHLD: 'Pilote prévu',
    PLT_APPRVL: 'Norme prête pour l’approbation',
    IMAGE_STAGED: 'Soumission reçue',
    UNDER_REVIEW: 'Soumission reçue',
    IMAGE_NEEDED: 'Erreur de téléchargement',
    UPLOAD_ERROR: 'Erreur de téléchargement',
    GRPHC_CMPL: 'Graphiques complets',

    // -- PENDING PAYMENTS --//
    pendingPayment: 'Paiement en attente',
    pendingPayments: 'Paiements en attente',
    pendingPaymentsInvoiceEmail:
        'Des factures pro forma vous ont été envoyées pour les commandes ci-dessous. Lorsque vous soumettez un paiement, veuillez consulter le numéro d’ordre de production Ball correspondant. Remarque : L’envoi de paiements après la date d’échéance aura une incidence sur la première date disponible pour votre commande.',
    pendingPaymentsSubmitPayments:
        'Afin d’accélérer le traitement d’un paiement, cliquez sur le bouton « Click if Submitted » (Cliquez si soumis) et fournissez un numéro de transfert (facultatif) pour votre paiement.',
    pendingPaymentsDeadlineWarning:
        'Vous avez un ou des paiements qui approchent ou ont dépassé leur date d’échéance. Cela aura une incidence sur la date demandée pour votre commande si vous ne payez pas avant la date d’échéance. Si vous avez payé, veuillez cliquer sur « Click if Submitted » (Cliquez si soumis) ci-dessous pour nous en informer.',
    clickIfSubmitted: 'CLIQUEZ SI SOUMIS',
    provideRemittanceNumber: 'Fournissez un numéro de versement',
    provideRemittance:
        'Fournir un numéro de versement peut accélérer le traitement des fonds sur votre compte.',
    remittanceNumber: 'Numéro de remise',
    optional: 'Optionnel',
    confirmPayment: 'CONFIRMER LE PAIEMENT',
    paymentSubmittedMessage: `Merci d'avoir soumis votre paiement. Nous traiterons votre paiement immédiatement.`,
    paymentSubmitted: 'PAIEMENT SOUMIS',
    paymentOverDue: 'Le paiement est en retard',
    paymentOverDueThisWeek: 'Le paiement est dû cette semaine',
    paymentOverDueThisMonth: 'Le paiement est dû ce mois-ci',

    // -- MAKE IT -- //
    myDashboard: 'Mon Tableau de Bord',
    createProductionOrder: 'CREAR ORDEN DE PRODUCCIÓN',
    saveForLater: 'GARDER POUR PLUS TARD',
    back: 'Arrière',
    setDatesQuantities: 'Fixer des Dates et des Quantités',
    setQuantities: 'Entrez les quantités',
    adjustmentsToYourOrder:
        'Des ajustements ont peut-être été apportés à votre commande depuis votre dernier enregistrement. Veuillez examiner tous les produits et quantités dans votre commande avant de procéder.',
    failedToLoadDraft: 'Échec de chargement de la commande provisoire',
    selectProducts: 'Sélectionnez les produits à ajouter à votre commande :',
    draftName: 'Nom du projet',
    continueEditing: 'Continuer la modification',
    yourOrderHasBeenSaved: 'Votre commande a bien été enregistrée!',
    shipTo: 'Envoyez à',
    yourOrder: 'Votre Commande',
    search: 'Rechercher',
    range: 'Intervalle',
    cansBottles: 'Boîtes / Bouteilles',
    agingBalance: 'Échéance du solde',
    campaign: 'Campagne',
    campaignProducts: 'Produits de campagne',
    endsClosures: 'Couvercles / Bouchons',
    endClosures: 'Couvercle/Bouchons',
    orderedInLastSixMonths: 'Commandé au cours des 6 derniers mois',
    removeAll: 'Enlever Tout',
    addInstructions:
        'Créez votre commande en cliquant sur le bouton "AJOUTER" pour chaque produit que vous souhaitez inclure.',
    qtyInstructions: `Vous entrerez les quantités et les dates demandées pour les produits sélectionnés sur l'écran suivant.`,
    cancelSubmission: 'Annuler votre soumission',
    saveDraftTitle: 'Enregistrer le projet de titre',
    allInfoWillBeLost:
        'Voulez-vous vraiment annuler? Tous les renseignements saisis seront perdus.',
    yesCancel: 'Oui, Annuler',
    productId: 'Identifiant du Produit',
    graphicID: 'Identifiant graphique',
    productDescription: 'Description du Produit',
    characteristics: 'Caractéristiques',
    coating: 'Enrobage',
    add: 'Ajouter',
    makeItMinimumOrderQuantity: 'Make It Minimum Order Quantity',
    added: 'Ajouté',
    rowsPerPage: 'Lignes par page',
    filter: 'Filtre',
    poNumber: 'Numéro de bon de commande',
    poNumberHelper: 'Un PO client est requis.',
    poNumberMaxLengthHelper: 'Le PO client doit être de 25 caractères ou moins.',
    poNumberPlaceholder: '25 caractères ou moins',
    addProducts: 'Ajouter des produits',
    submitError:
        'Une erreur est survenue lors de l’enregistrement de votre commande. Veuillez réessayer plus tard.',
    submitOrder: 'Envoyer l’ordre',
    draftHasBeenSuccessfullySaved: 'Votre brouillon a bien été enregistré!',
    backToDashboard: 'Retour à mon tableau de bord',
    viewMyInProgressGraphics: 'Afficher le résumé graphique',
    enterDraftName:
        'Veuillez saisir un nom provisoire pour votre commande de production afin de l’enregistrer pour plus tard.',
    draftOrderError:
        'La commande provisoire n’a pas été enregistrée. Veuillez réessayer plus tard.',
    minimumOrderQuantities: 'Quantités minimales de commandes',
    feeWarning:
        'Une ou plusieurs quantités ne correspondent pas à la quantité minimale de commande et entraîneront des frais.',
    product: 'Produit',
    minimumOrderQuantity: `Quantité minimum d'achat`,
    fee: 'Frais',
    confirmFeeMessage: 'Voulez-vous mettre à jour la quantité ou accepter les frais?',
    editQuantity: 'Modifier la Quantité',
    acceptFee: 'Accepter les Frais',
    today: `Aujourd'hui`,
    leadTimeForThisProduct: 'Délai de livraison pour ce produit : {{leadTime}} semaines',
    minimumOrderIs: 'La commande minimum est',
    feeForOrders: 'Frais pour les commandes',
    split: 'Divisé',
    moqWarningMessage:
        'Une ou plusieurs quantités saisies ne répondent pas à la quantité minimale de commande.',
    orderSubmitted: 'MERCI, VOTRE COMMANDE A ÉTÉ SOUMISE',
    ordersSubmitted: 'Merci, vos commandes ont été soumises',
    customerPO: 'PO Client',
    invoiceSent: 'Facture Envoyée',
    cashReceived: 'Argent reçu',
    reactToScheduleShipment: `Prêt à planifier l'expédition`,
    proFormaInvoice:
        'Vous devriez recevoir votre facture pro forma pour cette commande dans un <strong>délai de 2 à 3 jours ouvrables</strong>.',
    proFormaInvoiceBulk:
        'Vous devriez recevoir votre facture(s) pro forma pour cette commande dans un <strong>délai de 2 à 3 jours ouvrables</strong>.',
    paymentDueImmediately:
        'Le paiement de la facture est <strong>dû immédiatement</strong> afin de réserver la date demandée pour le ou les produits de la commande.',
    paymentDueImmediatelyBulk:
        'Le paiement de la facture(s) est <strong>dû immédiatement</strong> afin de réserver la date demandée pour le ou les produits de la commande(s).',
    paymentFor:
        'Le paiement sur la <strong>facture est dû{{paymentDue}</strong>} (quatre semaines avant votre première date demandée).',
    paymentForBulk:
        'Le paiement sur la <strong>facture(s) est dû{{paymentDue}</strong>} (quatre semaines avant votre première date demandée).',
    paymentForBulkNoDate:
        'Payment for the invoice(s) is due four weeks before your earliest requested date.',
    share: 'Partager:',
    print: 'Imprimer',
    downloadCSV: 'Télécharger .CSV',
    canQuantity: 'Quantité de cannettes',
    endQuantity: 'Quantité de couvercles',
    quantity: 'Quantité',
    orderConfirmation: 'Confirmation de Commande',
    leadTime: 'Délai de livraison',
    requiredShipDate: 'Date d’expédition requise',
    requestedAmount: 'Requested Amount',
    quantityToPurchase: 'Quantité À Acheter',
    purchaseOrderNumber: 'Numéro De Commande',
    shortenedPONumber: 'Numéro De Commande',
    ballProdOrderNumber: 'Numéro De Commande De Production De Ball',
    ballProdOrderLineNumber: 'Ball Production Order Line Number',
    ballProdOrder: 'Commande De Production De Ball',
    invoiceAmount: 'Montant De Facture',
    orderRequestedDate: 'Commande Date Demandée',
    orderSubmittedDate: 'Date de soumission de la commande',
    paymentDueDate: 'Date Limite De Paiement',
    zeroOrderQuantity:
        'La quantité de commande ne peut pas être 0. Veuillez saisir une quantité ou retirer le produit de la commande.',
    zeroForecastQuantity:
        'Forecasted quantity cannot be 0. Please enter a quantity or remove the product from the forecast.',
    blankForecastQuantity:
        'Forecasted quantity cannot be blank. Please enter a quantity or remove the product from the forecast.',
    firstRequestedDate: 'Demandé',
    viewCurrentUpcomingCampaigns: 'Voir les Campagnes en Cours ou à Venir',
    currentUpcomingCampaigns: 'Les Campagnes en Cours ou à Venir',
    lookingForCampaignSchedules: 'Vous Recherchez des Calendriers de Campagne?',
    noCampaignsScheduled: 'No campaigns scheduled at this time',
    productSizeShape: 'Product (Size / Shape)',
    makeItOrderOpen: 'Make It Order Open',
    requestWindowOpen: 'Request Window Open',
    makeItOrderClosed: 'Make It Order Closed',
    requestWindowClosed: 'Request Window Closed',
    firstNumAvailableDate: '1st Available Date',
    newGraphicApprovalDeadline: 'New Graphic Approval Deadline',
    createAtmProductionOrder: 'Créer un ATM (Autorisation de fabrication)',
    addAtmProductsStep:
        'Ajouter des produits pour construire votre ATM (Autorisation de fabrication):',
    yourATM: 'Votre ATM (Autorisation de fabrication)',
    addAtmInstructions: `Créez votre ATM (Autorisation de fabrication) en cliquant sur le bouton " Ajouter " pour chaque produit que vous souhaitiez inclure. Vous passerez en revue votre ATM sur l'écran suivant.`,
    enterQuantityUnit: 'Entrez les quantités en :',
    qtyAtmInstructions: "Vous passerez en revue votre ATM sur l'écran suivant.",
    changeRequestedWeekWarning:
        'Changing your requested week will remove all products you have added to the order. Do you want to continue?',
    changeRequestedWeek: 'Change Requested Week',
    impersonationWarningDiscard:
        'This work cannot be discarded. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSave:
        'This work cannot be saved. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSubmit:
        'Ce travail ne peut pas être soumis. Vous utilisez un rôle d’usurpation d’identité qui dispose d’un accès visuel uniquement dans La Source.',
    makeItOrderExistsError:
        'Une commande Faire a déjà été passée pour cette semaine. Veuillez modifier la commande existante à partir du résumé de la commande pour ajouter des produits ou choisir une autre semaine.',
    available: 'disponible',
    noSupplyPlanAvailable: 'Aucun plan d’approvisionnement n’est disponible.',
    noWeeksSelected: 'Aucune semaine sélectionnée',
    addWeek: 'Ajouter la semaine',
    reachOutToAccountManager:
        'Veuillez communiquer avec votre gestionnaire de compte au sujet de votre plan d’approvisionnement.',

    // -- Delivery Orders --//
    submittedByUser: 'Utilisateur',
    deliveryOrderNumber: 'Commande de Livraison #',
    deliveryOrderDetails: 'Détails de la Commande de Livraison',
    linkedProductionOrder: 'Bon de production',
    linkedATMOrder: 'Make It (ATM) Order',
    shipmentPickupDate: 'Date de ramassage',
    deliveryDate: 'Date de livraison',
    shipmentPickupTime: 'Heure de ramassage',
    deliveryTime: 'Heure de livraison',
    shipmentShippedFrom: 'Expéditeur',
    shipmentCarrier: 'Transporteur',
    deliveryOrderDates: 'Dates de la Commande de Livraison',
    needToMakeChange: 'Besoin de faire un changement?',
    editShipments24Hours:
        'Jusqu’à 24 heures avant la date et l’heure de l’expédition, vous pouvez:',
    editShipmentsOption1:
        'Añadir/Quitar una versión de producto/gráfico del pedido global o de un envío individual',
    editShipmentsOption2: 'Mettre à jour la quantité d’un produit pour une expédition',
    editShipmentsOption3: 'Supprimer une expédition d’une commande de livraison',
    editShipmentsOption4:
        'Mettre à jour une date ou une heure de commande de livraison tant qu’elle est supérieure à {{leadTime}} jours à compter de la date actuelle',
    editShipmentsOption5:
        'Mettre à jour les instructions de livraison, le numéro de sortie, la ligne de remplissage et le numéro de référence',
    editShipmentsOption6: 'Update delivery order date / time within the allowable delivery window',
    editShipmentsOption7: 'Mettre à jour la date ou l’heure d’une commande de livraison',
    editShipmentsCancellingOrders:
        'Annulation des commandes - Les annulations doivent être effectuées au moins 24 heures avant la date d’expédition la plus précoce de la commande de livraison.',
    editShipmentsContactRep:
        'Veuillez contacter un représentant des ventes de balle si vous devez demander un changement différent de ceux ci-dessus.',
    yourDeliveryOrder: 'Votre Commande De Livraison',
    cancelDeliveryOrderConfirmation: 'Annuler la confirmation de la commande de livraison',
    skuCansBottles: '{{ skuCount }} SKU (Boîtes/Bouteilles)',
    shipmentNumber: 'Nombre de ramassage',
    shipmentDate: 'Shipment Date',
    shipmentTime: 'Shipment Time',
    idNotAvailable: 'ID not available',

    // -- Unauthorized -- //
    unauthorized: 'Non autorisé',
    youAreNotAuthorized: 'Vous n’êtes pas autorisé à afficher cette page',
    unknownActivity: 'this area',
    dontHaveAccess: "Looks like you don't have access to {{activityString}} for",
    deactivateReactivateUsers: 'Deactivate / Reactivate Users',
    makeItBulkATM: 'Make It Bulk ATM',
    makeItBulkUpload: 'Make It Bulk Upload',
    shipItBulkUpload: 'Ship It Bulk Upload',
    openAccess: 'Open Access',
    onboardingWizard: 'Onboarding Wizard',
    billingAndShipping: 'Billing And Shipping',

    // -- SHIP IT -- //
    createDeliveryOrder: 'CRÉER UN BON DE LIVRAISON',
    selectDeliveryWk: 'Sélectionnez votre semaine de livraison',
    todayWithDate: `Aujourd'hui: {{ today }}`,
    chooseDates: 'Choose Dates',
    firstAvailable: 'Première disponible',
    firstShipment: `Votre premier envoi doit être d'au moins {{ leadTime }} jours à compter d'aujourd'hui`,
    selectProductsForDelivery: 'Ajouter des produits à votre commande de livraison',
    shipitAddInstructions: `Créez votre commande en spécifiant le nombre de palettes pour chaque produit que vous souhaitez inclure, puis en cliquant sur le bouton "AJOUTER" pour l'ajouter à votre commande.`,
    configureTrucksInstructions:
        'Vous construirez vos envois et sélectionnerez les dates et heures de livraison sur votre prochain écran.',
    totalPallets: 'Palettes totales',
    estimatedTrucks: 'Estimation des camions nécessaires:',
    trucksVarianceInstructions:
        'Le nombre de camions variera en fonction du poids total des extrémités / fermetures lors de la livraison.',
    buildYourShipments: 'CONSTRUISEZ VOS EXPÉDITIONS',
    deliveryWeek: 'Semaine de livraison',
    nextDeliveryWeek: 'Semaine de livraison prochaine',
    youDidntLoadAll: `Vous n'avez pas chargé toutes vos palettes`,
    remainingPalletsToBeOrdered:
        'Il y a {{ remainingBalance }} palettes non attribuées dans votre commande. Si vous continuez, les palettes déchargées resteront disponibles pour les bons de livraison futurs.',
    continueBuildingShipments: 'Continuer à construire les envois',
    deliveryDay: 'Jour de livraison',
    availableQuantToLoad: 'Quantité disponible sur {{ maxPalletsToLoad }} palette(s) à charger',
    availOutOfMax: '{{ availablePallets }} palette(s) sur {{ maxPallets }} palette(s)',
    addShipment: 'AJOUTER EXPÉDITION',
    productsToShip: 'Produits à expédier',
    remainingProductsToLoad: 'Restant à charger: {{ totalProductPallets }} PL',
    remainingEndsToLoad: 'Restant à charger: {{ totalEndsPallets }} PL',
    palletsAvailableOn:
        'Disponible le {{ date }} : {{ firstAvail }} palette(s) sur {{ firstAvailMax }} palette(s)',
    viewBalancesByDay: 'Afficher les soldes par jour',
    notEnoughRemainingPallets:
        'Nombre maximal de palettes dépassé pour un produit. Veuillez mettre à jour les quantités dans l’envoi avant d’enregistrer.',
    maximumNumberOfPalletsWarning:
        'Le nombre maximal de palettes pour un envoi est de {{truckPalletLimit}}. Veuillez mettre à jour les quantités dans l’envoi avant d’enregistrer.',
    freightWeightWarning:
        'Le tarif de votre fret est basé sur un chargement complet de 25 palettes. Vous expédiez moins d’un camion complet? Nous vous enverrons une facture distincte pour le reste du fret. Voici comment calculer le montant que vous verrez sur votre facture :',
    atmFreightWeightWarning:
        'Le tarif de votre fret est basé sur un chargement complet (typiquement 25 palettes). Vous expédiez moins d’un camion complet? Nous vous enverrons une facture distincte pour le reste du fret.',
    shipmentFeeModalWarning:
        'Le tarif de votre fret est basé sur un chargement complet (typiquement 25 palettes). Vous expédiez moins d’un camion complet? Nous vous enverrons une facture distincte pour le reste du fret. Voici comment calculer le montant sur la base de 25 palettes.',
    feesMayIncur:
        'Des frais peuvent être facturés pour les expéditions inférieures à un chargement complet.',
    deliverOn: 'Livraison le',
    pickupOn: 'Cueillette le',
    addDeliveryInstructions:
        'Ajoutez un produit de la liste que vous avez créée et entrez les quantités ici. Le chariot sera mis à jour pour afficher les palettes restantes à charger, le cas échéant.',
    deliveryInstructions: 'Instructions de livraison',
    time: 'Hora',
    at: 'À',
    arriving: 'En arrivant',
    none: 'Aucun',
    done: 'Done',
    deliveryMountainTime: 'L’heure de livraison doit être saisie en heure des Rocheuses (HR)',
    deliveryTimeZone: 'Select desired delivery time',
    pleaseEnterDeliveryInstructions:
        'Veuillez saisir les instructions de livraison pour cet envoi.',
    deliveryOrderError:
        'Les instructions de livraison n’ont pas pu être enregistrées correctement. Veuillez réessayer plus tard.',
    deliveryOrder: 'Votre Bon de Livraison',
    deliveryOrderPending: '# de Bon de Livraison Attente',
    deliveryConfirmationCarrierAlert:
        'Le transporteur sera affecté 7 jours avant la date de livraison prévue.',
    incursFees: `L'expédition à moins de {{truckPalletLimit}} PL entraîne des frais.`,
    mayIncurFees: `L'expédition à moins de {{truckPalletLimit}} PL peut entraîner des frais.`,
    mayIncurFeesTruckload: `L'expédition d'un chargement inférieur à un camion complet peut entraîner des frais.`,
    shipmentSize: `La taille standard d'un envoi pour un ordre de livraison est de 25 palettes, mais un maximum de 27 palettes peut être autorisé pour certains endroits.`,
    buildShipments: 'CONSTRUISEZ VOS EXPÉDITIONS',
    shipment: 'Expédition',
    shipments: 'Expéditions',
    sku: 'SKU',
    skus: 'SKUs',
    delete: 'Eliminar',
    selectedWeek: 'Semaine Sélectionnée',
    company: 'Entreprise',
    availBalance: 'Solde disp.',
    numberOfPalletsToShip: 'Nombre de palettes à expédier',
    releaseNumber: 'Numéro de libération',
    releaseNumberPDF: 'Numéro de libération',
    loadSeq: 'Séquence de charge',
    fillerLineNumber: 'Ligne de Remplissage #',
    referenceNumber: 'Numéro de référence',
    referenceNumberPDF: 'Numéro de référence',
    invalidBulkFile: 'Ce type de fichier n’est pas valide. Veuillez joindre un fichier .csv',
    invalidCsv: '*Veuillez télécharger un fichier csv valide',
    reviewOrder: 'Ordre d’examen',
    resetMapping: 'Réinitialiser Votre Mappage',
    mappingPermanentlyGone:
        'Toutes les modifications apportées à votre mappage seront définitivement disparues.',
    changesPermanentlyGone:
        'Toutes les modifications apportées à votre soumission seront définitivement supprimées.',
    deleteProgress: 'Supprimer la progression',
    yesReset: 'Oui, réinitialiser',
    editMapping: 'Modifier le Mappage',
    changeDeliveryWeekWarning:
        'La modification de votre semaine de livraison supprimera tous les produits que vous avez chargés dans votre commande. Voulez-vous continuer à changer la semaine de livraison?',
    deliveryOrderDayTimeError:
        'Les envois sur la même commande de livraison ne peuvent pas avoir de dates et d’heures en double. Veuillez modifier l’heure choisie.',
    changeDeliveryWeek: 'Change Delivery Week',
    changeDeliveryDayWarning:
        'Changer votre jour de livraison pourrait nécessiter des modifications à votre envoi.  Voulez-vous continuer?',
    changeDeliveryDate: 'Modifier la date de livraison',
    creditHoldWarning:
        'Une prise de crédit a été placée contre ce vaisseau. Les commandes de livraison ne peuvent pas être passées pour ce navire jusqu’à ce que la prise de crédit ait été résolue.',
    copackerCreditHoldWarning:
        'Cet emplacement ship to est en attente. S’il vous plaît contacter votre client pour la résolution.',
    dashboardCreditHoldWarning:
        'Les Ship Tos suivants ont une prise de crédit contre eux. Les commandes de livraison ne peuvent pas être passées contre ces tos de navire :',
    additionalFields: 'Champs Supplémentaires',

    // -- Summary Pages -- //
    customerPoNumber: 'PO client',
    customerPoNumLong: 'PO client',
    status: 'Statut',
    createdBy: 'Créé par',
    dateSubmitted: 'Date de soumission',
    productGraphicId: 'ID produit / ID graphique',
    graphicId: 'ID graphique',
    descriptionCharacteristics: 'Description du Produit / Caractéristiques',
    quantityOrdered: 'Quantité commandée',
    quantityToDeliver: 'Quantité à livrer',
    requestedDate: 'Date demandée',
    open: 'Ouvert',
    closed: 'Clôturés',
    availableToShip: "Disponible pour l'expédition",
    completed: 'Achevée',
    productionOrderDetails: "Détails de L'ordre de Fabrication",
    totalOrderQuantity: 'Quantité totale de commande',
    openProductionOrders: 'Ordres de fabrication ouverts',
    closedProductionOrders: 'Ordres de fabrication clôturés',
    totalDeliveryQuantity: 'Quantité totale de livraison',
    draftProductionOrders: 'Projets d’ordonnances de production',
    draftOrderName: 'Nom de l’ordre provisoire',
    lastSaved: 'Dernière enregistrée',
    lastSavedBy: 'Dernière sauvegarde par',
    actions: 'Actions',
    openDeliveryOrders: 'Ouvrir les commandes de livraison',
    closedDeliveryOrders: 'Commandes de Livraison Fermées',
    orderStatus: 'Statut de commande',
    pending: 'Attente',
    editPending: 'Modifier en attente',
    received: 'Paiement reçu',
    reject: 'Rejeté',
    confirmed: 'Confirmé',
    shipConfirmed: 'Expédition confirmée',
    draft: 'Projet',
    delinquent: 'Paiement dû',
    payable: 'En approbation',
    cancelledPending: 'Annuler en attente',
    editUnderReview: 'Modifier à l’étude',
    openEditReviewed: 'Ouvrir (modifier révisé)',
    openEditRejected: 'Ouvrir (modifier rejeté)',
    reviewStatus: 'Statut de révision',
    originallyScheduled: 'Initialement prévu',
    productName: 'Nom du Produit',
    arrivalDate: 'Date d’arrivée',
    arrivalTime: 'Heure d’arrivée',
    lineStatus: 'État de la Ligne',
    scheduledFor: 'Prévu Pour',
    receivedOn: 'Reçu le',
    arrivedOn: 'Arrivé Sur',
    poOpenOrderInformation:
        'Jusqu’à deux semaines avant la première date disponible, vous pouvez retirer un produit de l’ordre de production, retarder la première date disponible pour un produit ou diminuer la quantité d’un produit.',
    poCannotBeEdited: `Si un produit a une commande de livraison associée, vous ne serez pas en mesure de faire des modifications à l’ordre de production.`,
    edit: 'Modifier',
    cancelOrder: 'Annuler Commande',
    cancelProductionOrder: 'Veuillez confirmer que vous souhaitez annuler cette commande.',
    cancelProductionOrderSubHeader: 'Êtes-vous sûr que vous voulez annuler la commande #',
    cancelProductionOrderConfirmation: 'Are you sure you want to cancel Production Order #',
    goBack: 'Retour',

    // -- EDIT PRODUCTION ORDER -- //
    editProductionOrder: 'Modifier l’ordre de Production',
    pleaseConfirmRemoveOrder:
        'S’il vous plaît confirmer que vous souhaitez supprimer cette commande',
    areYouSureYouWantToRemoveProduct:
        'Êtes-vous sûr de vouloir supprimer Product ID {{ productId }} de votre commande?',
    editRequestedDate: 'Modifier la Date Demandée',
    originalRequestedQuantity: 'Quantité Demandée Originale',
    editQuantityToPurchase: 'Modifier la Quantité Demandée à l’achat',
    lessThanOriginal: 'Doit être inférieur à l’original',
    mustBeAfterOriginalDate: 'Doit être après la date d’origine',
    dateMustBeAfterOriginalDate: 'La date doit être après  la date d’origine demandée',
    cancelProductionOrderSubmitted: 'Merci. Votre annulation a été soumise',
    cancelledDate: 'Date annulée',
    productionOrder: 'Ordre de production',
    originalQuantityOrdered: 'Quantité originale commandée',
    revisedQuantityOrdered: 'Quantité révisée commandée',
    revisedRequestedDate: 'Date demandée révisée',
    editProductionOrderConfirmation: 'Modifier la confirmation de l’ordre de production',
    editProductionOrderSubmitted: 'MERCI. VOS MODIFICATIONS ONT ÉTÉ REÇUES',
    dateRevised: 'Date de révision',
    submitChanges: 'Soumettre des modifications',
    editProductionOrderSubmitChanges: 'Vous êtes sur le point de changer votre ordre de production',
    editProcutionOrderConfirmSubmit:
        'Etes-vous sûr de vouloir enregistrer les modifications apportées à votre commande de production?',
    removed: 'ENLEVé',
    inactiveProductError:
        'Ball SKU {{sku}} is inactive. You cannot submit changes until this is addressed with your Ball representative or removed from the order.',
    currentAvailableBalance: 'Current Available Balance',

    // -- Date Pickers -- //
    legend: 'Légende',
    day: 'Jour',
    restrictedDate: 'Jour restreinte',
    dateFirstAvailable: 'Jour de première disponibilité',
    availableDate: 'Jour disponible',
    ok: `D'accord`,

    // -- PRODUCT PLANNING -- //
    units: 'Unités',
    expandAll: 'Développer tout',
    collapseAll: 'Réduire tout',
    thisWeek: 'Cette Semaine',
    weeks0to8: 'Semaines 0-8',
    weeks9to16: 'Semaines 9-16',
    productionBalance: 'Balance de Production',
    atmBalance: 'Solde de l’ATM',
    weekOfWithDate: 'Semaine de {{date}}',
    productionOrders: 'Ordre(s) de fabrication',
    ordersPendingPayment: 'Ordonnances en attente de paiement',
    originalRequestedDate: `Date d'échéance du paiement`,
    revisedAvailableDate: `Date d'origine demandée`,
    late: 'Retard',
    deliveryDates: 'Date(s) de Livraison',
    productPlanning: 'Planification du produit',
    emptyString: '',
    week: 'Semaine',
    graphicsID: `Numéro d'identification`,
    ballOrderNumber: 'Numéro d’ordre de Ball',
    shipmentsExceededProduction: 'Les expéditions ont dépassé les soldes de production',
    transferProductionBalance: 'Demander un Trade It',
    displayUnits: 'Display Units',
    apply: 'Appliquer',
    agingBalances: 'Échéance des Soldes',
    agingBalanceIndicator: 'Indique une échéance du solde',

    // -- TRADE IT -- //
    tradeIt: 'Trade It',
    createTradeRequest: 'Créer une demande d’échange de solde',
    tradeRequestSummary: 'Balance Trade Summary',
    selectYourShipTos: 'Sélectionnez vos destinataires',
    originalShipTo: 'Destinataire d’origine',
    receivingShipTo: 'Destinataire recevant',
    originalShipToContactEmail: 'Courriel de contact du destinataire d’origine',
    receivingShipToContactEmail: 'Courriel de contact du destinataire recevant',
    selectProductsToTrade: 'Sélectionnez les produits à échanger',
    makeYourShipToSelections:
        'Make your selections above to see products eligible for trades for the Ship Tos you select.',
    noTradeItProducts:
        'No products with available to trade balance from original ship and mapped to the receiving ship to.',
    willReceiveNotification: '* Vous recevrez un avis une fois que la demande aura été examinée.',
    originalShipToCurrentProdBalance: 'Solde actuel',
    receivingShipToCurrentProdBalance: 'Solde actuel',
    quantityToTrade: 'Quantité à échanger (palettes/PL)',
    originalShipToNewProdBalance: 'Nouveau solde',
    receivingShipToNewProdBalance: 'Nouveau solde',
    quantityExceedsAvailableBalance: 'Cannot exceed Original Ship To available balance',
    submitRequest: 'Soumettre la demande',
    balanceTradeSubmitted: 'Merci, votre demande a été transmise.',
    tradeItRequest: 'No de demande Trade It',
    originalShipToPreviousAvailableBalance: 'Previous Available Balance for Original Ship To #',
    receivingShipToPreviousAvailableBalance: 'Previous Available Balance for Receiving Ship To #',
    requestedQuantityToTrade: 'Requested Quantity to Trade',
    originalShipToUpdatedAvailableBalance: 'Updated Available Balance for Original Ship To #',
    receivingShipToUpdatedAvailableBalance: 'Updated Available Balance for Receiving Ship To #',
    originalShipToContact: 'Courriel de contact du destinataire d’origine',
    receivingShipToContact: 'Courriel de contact du destinataire recevant',
    requestDetails: 'Request Details',
    weekOf: 'Semaine du',
    requestStatus: 'Request Status',
    updatedBalance: 'Updated Balance',
    previousBalance: 'Previous Balance',
    requestedTradeQuantity: 'Requested Trade Quantity',
    campaignTradeNotification:
        'Veuillez noter que votre commande comporte un ou plusieurs produits de campagne.',
    campaignTradeRisk:
        'Si vous décidez d’échanger des canettes de campagne en causant une rupture de stock prématurée, <1>le stock ne sera pas regarni avant la prochaine campagne prévue</1>.',
    campaignTradeRiskEmphasis: 'le stock ne sera pas regarni avant la prochaine campagne prévue',
    campaignQuestionsPrompt:
        'Pour toute question, veuillez communiquer avec le représentant de votre compte.',
    campaignContinuePrompt: 'Souhaitez-vous continuer?',
    campaignProductAlert: 'Alerte produit de campagne',
    accept: 'OK',

    // -- DELIVERY ORDER PDF -- //
    total: 'Total',
    dateTime: 'Date / Heure',
    revised: 'Révisé',
    update: 'Mettre à jour',
    pickUp: 'Décroche',
    loadingMessage: 'Loading PDF. This may take up to a few minutes.',

    // -- EDIT SHIPMENTS SUMMARY -- //
    pleaseSelectProductsShipment: 'Veuillez sélectionner des produits à ajouter à votre envoi',
    changesWillNotBeSaved: 'Les modifications apportées ne seront pas enregistrées',
    areYouSureDiscard: 'Êtes-vous sûr de vouloir supprimer les modifications?',
    yesDiscard: 'Oui, jeter',
    changeDeliveryOrderModalTitle: 'Vous êtes sur le point de modifier votre commande de livraison',
    changeDeliveryOrderModalConfirmationText:
        'Êtes-vous sûr de vouloir enregistrer les modifications apportées à votre commande de livraison ?',
    yesSave: 'Oui, Enregistrer',
    confirmCancelShipment: 'Veuillez confirmer que vous souhaitez annuler cette expédition',
    areYouSureCancelShipment: 'Êtes-vous sûr de vouloir annuler cette expédition?',
    yesCancelShipment: 'Oui, Annuler l’expédition',
    feesWillBeIncurred:
        'Des frais seront engagés pour les expéditions de moins de {{maxPallets}} palettes',
    doYouWantToAccept: 'Voulez-vous accepter les frais?',
    yesAcceptFee: 'Oui, accepter les frais',

    // -- EDIT SHIPMENTS -- //
    palletQtyNotZero:
        'La quantité de palette ne doit pas être de 0.  S’il vous plaît mettre à jour la quantité ou supprimer le produit avant d’économiser.',
    editYourShipment: 'Modifier votre envoi',
    shipmentCancelled: 'Expédition annulée',
    shipmentEdited: 'Expédition éditée',
    deliveryOrderConfirmation: 'Confirmation des commandes de livraison',
    shipmentsWithin24Hrs:
        'Les envois qui doivent être expédiés dans les prochaines 24 heures ne sont pas modifiables',
    customerPickUp: 'Pick-up client',
    shipping: 'livraison',
    changeDay: 'Jour du changement',
    changeDeliveryDateWarning:
        'La modification de votre date de livraison pourrait nécessiter des modifications à votre envoi',
    productsRemovedFromShipment:
        'Certains produits ou quantités peuvent ne pas être disponibles pour l’expédition à cette date et seront retirés de votre envoi.',
    cancelShipment: 'Annuler l’expédition',
    revisedDate: 'Date révisée',
    pleaseConfirmCancelOrder:
        'S’il vous plaît confirmer que vous souhaitez annuler cette commande.',
    cancellationReceived: 'Merci. Votre annulation a été reçue',
    editDeliveryOrderSubmitted: 'MERCI. VOS MODIFICATIONS ONT ÉTÉ REÇUES',
    errorProcessingShipment: 'There has been an error processing your edited Delivery Order.',

    // -- DELIVERY BULK UPLOAD --//
    mapTemplateFields: 'Champs de Modèle de Carte',
    uploadShipItOrders: 'Téléverser votre ou vos commandes Expédier',
    mapShipItTemplateValues: 'Mettre en correspondance les valeurs du modèle Expédier',
    updateShipItTemplateValues: 'Mettre à jour les valeurs du modèle Expédier',
    mapTemplateFieldsDescription:
        'Mapper les valeurs requises à leur colonne correspondante dans votre modèle. Toutes les valeurs requises doivent être mappées, les champs facultatifs peuvent être laissés vides si vous ne les utilisez pas.',
    templateFieldMapping: 'Mappage de Champs de Modèle',
    shipToId: 'Expédier à l’ID',
    columnLetter: 'Lettre de Colonne',
    columnLetterOptional: 'Lettre de Colonne (Facultatif)',
    fillerLine: 'Numéro de Ligne de Remplissage',
    saveMapping: 'Enregistrer le Mappage',
    uploadOrderFile: 'Télécharger le Fichier de Commande',
    bulkUploadOverview: `Vue d’ensemble du Téléchargement en Bloc`,
    mapTemplateValues: 'Valeurs de Modèle de Carte',
    columnPatternError: 'Veuillez saisir 1-2 lettres',
    bulkUpload: 'Téléchargement en Bloc',
    atmBulkUpload: 'ATM et Téléchargement en masse des prévisions',
    reset: 'Réinitialiser',
    uploadDeliveryOrder: 'Télécharger Votre Commande de Livraison',
    acceptedBulkFiles:
        'S’il vous plaît télécharger .csv fichier, en s’assurant que vos valeurs correspondent à votre cartographie.',
    lookUpShipTo: 'Besoin de Chercher un Envoyez à?',
    viewAssignedShipTos: 'Voir Les Tos de Navire Assignés',
    multipleColumnValidationError:
        'Les lettres de colonne ne peuvent pas être dupliquées. S’il vous plaît examiner vos entrées et soumettre à nouveau.',
    mustSaveMapping:
        'Vous devez enregistrer votre mappage ci-dessus afin de télécharger un fichier.',
    documentName: 'NOM DU DOCUMENT',
    documentNotUploaded: 'Aucun document téléchargé',
    removeFile: 'Supprimer le fichier',
    confirmFileRemoval: 'Veuillez confirmer que vous souhaitez supprimer ce fichier',
    areYouSureRemoveFile: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
    yesRemove: 'Oui, Supprimer',
    confirmCancel: 'Voulez-vous vraiment annuler?',
    sureYouWantToLeave: 'Are You Sure You Want To Leave?',
    removeProductTitle: 'Remove Product from Order',
    removeProductSubheader: 'Are you sure you want to remove this product from your order?',
    changeWeek: 'Change Week',
    chooseWeekMove: 'Choose a week to move this Product to',
    chooseWeekCopy: 'Choose a week to copy this Product to',
    confirmCancelNoQuestion: 'Voulez-vous vraiment annuler',
    lostFile: 'Tout fichier téléchargé ou des mappages non sauvés disparaîtra définitivement.',
    deliveryOrderHeader:
        'Commande de Livraison {{deliveryOrderNumber}} - <i>Numéro de Commande en Attente</i>',
    deleteShipment: 'Supprimer l’expédition',
    editShipment: 'Modifier l’expédition',
    delivery: 'Livraison',
    lowTruckPalletCountWarning: 'Cette livraison a une charge de camion moins que complète.',
    reviewYourShipments: 'Examiner Vos Envois',
    pleaseReviewShipmentDetails:
        'Veuillez consulter les détails de vos envois ci-dessous et effectuer les modifications nécessaires avant de soumettre vos commandes.',
    allOrdersDeleted:
        'All orders have either been deleted or removed. Please "undo" at least one deleted order or upload a new order file to proceed.',
    allShipmentsDeleted:
        'All shipments have been deleted. Please "undo" at least one deleted shipment or upload a new order file to proceed.',
    allProductsRemoved: 'All products have been removed. Please add products to proceed.',
    line: 'Ligne',
    fileErrors: 'Erreurs de fichier',
    lineErrorHelper: 'Veuillez corriger les erreurs suivantes et re-télécharger votre fichier.',
    shipmentDeleted: 'Expédition supprimée',
    undo: 'Annuler',
    yesDelete: 'Oui, Supprimer',
    noCancel: 'No, Annuler',
    confirmDeleteShipment: 'Êtes-vous sûr de vouloir supprimer cette expédition ?',
    intakeCancelConfirmation: 'Êtes-vous sûr de vouloir annuler votre progression sans épargner?',
    intakeSaveDraft:
        " Vous êtes sur le point d'enregistrer un brouillon de votre ordre de production, veuillez nommer votre brouillon pour y accéder facilement plus tard. Une fois enregistré, vous reviendrez au tableau de bord",
    saveChanges: 'Enregistrer les Modifications',
    discardChanges: 'Supprimer les Modifications',
    planningView: 'Vue de Planification',
    changesNotSaved: 'Vos modifications ne seront pas enregistrées.',
    sureYouWantToNavigateAway: 'Are you sure you want to navigate away?',
    goBackConfirm: 'Tu es sûr de vouloir y retourner ?',
    yesGoBack: 'Oui, Retourne',
    twentyFourHourFormat: 'Format 24 heures sur 24',
    quantityPL: 'Quantité (PL)',
    quantityEA: 'Quantité (EA)',

    // -- DELIVERY BULK UPLOAD VALIDATION RULES -- //
    shipItBulkDataValidation: 'Expédier la Validation des Données de Téléchargement en Vrac',
    dataValidationRules: 'Règles de Validation des Données',
    field: 'Champ',
    value: 'Valeur',
    maxLength: 'Longueur Maximale',
    format: 'Format',
    example: 'Exemple',
    quantityInPallets: 'Quantité (en Palettes)',
    or: 'ou',
    viewDataValidationRules: 'Afficher les Règles de Validation des Données',
    upTo8Digits: 'Jusqu’à 8 chiffres',
    upTo15Digits: 'Jusqu’à 15,4 chiffres',
    upTo3Characters: 'Jusqu’à 3 caractères',
    upTo10Characters: 'Jusqu’à 10 caractères',
    upTo25Characters: 'Jusqu’à 25 caractères',
    upTo24Characters: 'Jusqu’à 24 caractères',
    upTo30Characters: 'Jusqu’à 30 caractères',
    deliverToBackDoor: 'Livrer à la Porte Dérobée',
    dateInputFormat: 'MM/JJ/AAAA',

    // -- DELIVERY BULK UPLOAD Errors -- //
    E000001: 'Ship To ID est requis et manquant de cette ligne. S’il vous plaît fournir.',
    E000002: 'L’ID de produit est requis et manquant de cette ligne. S’il vous plaît fournir.',
    E000003: 'La quantité est nécessaire et manquante de cette ligne. S’il vous plaît fournir.',
    E000004:
        'La date de livraison est requise et manquante de cette ligne. S’il vous plaît fournir.',
    E000005:
        'Le délai de livraison est requis et manquant de cette ligne. S’il vous plaît fournir.',
    E100003: 'La quantité doit être une valeur numérique inférieure ou égale à 25.',
    E110001: 'La quantité doit être une valeur numérique inférieure ou égale à {0}.',
    E100004:
        'La date de livraison est en format incorrect. Veuillez entrer sous le titre MM/DD/YYYY',
    E100005:
        'Le délai de livraison est en format incorrect. Veuillez entrer sous le nom de HH:MM (24 heures).',
    E100006:
        'Instructions de livraison est en format incorrect. La valeur doit être une chaîne inférieure ou égale à 30 caractères.',
    E100007:
        'Le numéro de sortie est en format incorrect. La valeur doit être une chaîne inférieure ou égale à 10 caractères.',
    E100009:
        'Numéro de référence est en format incorrect. La valeur doit être une chaîne inférieure ou égale à 30 caractères.',
    E100001:
        'Livraison non valide à ID. Vérifiez la liste de vos vos iss de livraison affectées pour obtenir des ID de livraison valides.',
    E200001:
        'Livraison non valide à ID. Vérifiez la liste de vos vos iss de livraison affectées pour obtenir des ID de livraison valides.',
    E200002: 'ID de produit non valide. S’il vous plaît vérifier et corriger.',
    E100002: 'ID de produit non valide. S’il vous plaît vérifier et corriger.',
    E200004:
        'L’ID de produit entré n’est pas mappé au navire entré sur cette ligne. Veuillez modifier le produit ou le vaisseau.',
    E300005:
        'Vous n’avez pas l’autorisation de soumettre des commandes pour ce navire à. Veuillez supprimer les lignes avec ce vaisseau vers du fichier.',
    E100008: 'Ligne de remplissage non valide. S’il vous plaît vérifier et corriger.',
    E300004: 'L’utilisateur n’est pas associé à ce',
    // Note: if you change the following translations be sure the format strings, {n}, are put in the right place of the new string
    E110002:
        'La date d’inscription est dans les {0} jours suivant aujourd’hui. Veuillez indiquer une date d’au moins {0} jours à partir d’aujourd’hui.',
    E200006:
        'Les quantités entrées dans les livraisons pour {1} à l’adresse d’expédition {2} pour la semaine du {3} dépassent le solde disponible de {4}.',
    E200007:
        "Plusieurs types de produits (canette vs couvercle) pour la livraison pour l'expédition à {0} au {1} {2}. Veuillez déplacer certains produits vers une date/heure de livraison différente afin que chaque date/heure de livraison et chaque date/heure de livraison n'aient qu'un seul type de produit.",
    E210005:
        'La quantité saisie pour le navire à {0} à {1} est supérieure à {2} PL. Veuillez déplacer certaines palettes à une date/heure de livraison différente afin que chaque date/heure de livraison et de livraison ait un maximum de {2} PL.',

    // -- MAKE IT BULK UPLOAD Errors -- //
    EA000001: 'La quantité est dans un format incorrect. Veuillez saisir jusqu’à six chiffres.',
    EA000002: 'La quantité est dans un format incorrect. Veuillez saisir jusqu’à douze chiffres.',
    EA000003: 'Veuillez saisir une quantité demandée d’au moins.',
    EA000004:
        'Requested Date is in a locked time period. Please select at least {0} for the lead time of {1} weeks.',
    EA000006:
        "Une commande Faire a déjà été passée pour cette semaine et ce lieu d'expédition. Veuillez modifier la commande existante à partir du résumé de la commande pour ajouter des produits.",
    EA100001:
        'Cette expédition n’est pas configurée pour les commandes ATM (Autorisation de fabrication). S’il vous plaît retirer.',
    EP100007: 'This is not the correct template format for this customer',
    EP000001: 'Ship To ID est obligatoire et manquant dans cette ligne. Veuillez le fournir.',
    EP000002:
        'Le numéro de bon de commande du client est obligatoire et manquant dans cette ligne. Veuillez le fournir.',
    EP000003: 'Product ID is required and missing from this line. Please provide.',
    EP000004:
        'La date de la demande de commande est obligatoire et manquante dans cette ligne. Veuillez la fournir.',
    EP000005:
        'La quantité (en palettes) est requise et manquante dans cette ligne. Veuillez la fournir.',
    EP100006: 'Veuillez saisir les quantités sous forme de nombre entier.',
    E100023: 'Veuillez saisir les quantités sous forme de nombre entier.',
    E100013: 'La quantité est dans un format incorrect. Veuillez saisir jusqu’à six chiffres.',
    E100014: 'La quantité est dans un format incorrect. Veuillez saisir jusqu’à douze chiffres.',
    EP100001:
        "Le format de 'identifiant d’expédition est incorrect. Veuillez saisir jusqu’à huit chiffres.",
    EP110001:
        "Le format de 'identifiant d’expédition est incorrect. Veuillez saisir jusqu’à huit chiffres.",
    EP100002:
        'Le format du numéro de bon de commande du client est incorrect. Veuillez saisir au moins 25 caractères maximum de texte.',
    EP100003:
        'Le format de l’identifiant de produit est incorrect. Veuillez saisir au moins 25 caractères de texte.',
    EP100004:
        'La date demandée n’est pas valide ou n’a pas le bon format. Veuillez vérifier et entrer en tant que MM/JJ/AAAA.',
    EP100005: 'La quantité est dans un format incorrect. Veuillez saisir jusqu’à six chiffres.',
    EP110005: 'La quantité est dans un format incorrect. Veuillez saisir jusqu’à six chiffres.',
    EP200001: `La date saisie est antérieure à aujourd'hui. Veuillez saisir une date ultérieure qui tienne compte du délai d'exécution.`,
    EP200002:
        'Veuillez saisir une quantité supérieure à 0 qui tienne compte du minimum de commande.',
    EP200003: 'ID de produit non valide. S’il vous plaît vérifier et corriger.',
    EP200004: 'ID de produit non valide. S’il vous plaît vérifier et corriger.',
    EP200005:
        'L’identifiant de produit saisi n’a pas été mis en correspondance avec le destinataire saisi sur cette ligne. Veuillez changer le produit ou le destinataire.',
    EP300001:
        'Livraison non valide à ID. Vérifiez la liste de vos vos iss de livraison affectées pour obtenir des ID de livraison valides.',
    EP400001:
        'Vous n’avez pas l’autorisation de soumettre des commandes pour ce destinataire {0}. Veuillez supprimer les lignes contenant ce destinataire du fichier.',
    EP500002:
        'La date demandée est dans une période bloquée. Veuillez choisir une date ultérieure qui tienne compte du délai de livraison du produit.',
    EP100008: "Ce produit n'est pas disponible dans une fenêtre de campagne en cours.",
    EP100009:
        'La date demandée est antérieure à la première date de livraison disponible pour ce produit. Veuillez tenir compte du délai de livraison.',
    EP100010:
        'Your file has multiple Customer PO numbers for the following Week(s): {{weeks}}.  Please correct this error and upload again to submit your order.',

    // -- MAKE IT BULK UPLOAD -- //
    mapMakeItTemplateValues: 'Mettre en correspondance les valeurs du modèle Créer',
    reviewYourOrders: 'Vérifier vos commandes',
    acceptedMakeItBulkFiles:
        'Veuillez téléverser votre fichier .csv en vous assurant que vos valeurs correspondent à votre mise en correspondance.',
    uponSubmitCancelMessage:
        'Lors de la soumission, tous les produits précédemment prévus et non inclus à nouveau pour la semaine de prévision et à l’adresse d’expédition seront annulés.',
    removeShipToLocationsP1:
        "Si vous souhaitez supprimer toutes les adresses d'expédition d'une semaine de prévision, veuillez modifier ces enregistrements via le résumé ",
    removeShipToLocationsP2:
        ' pour chaque expédition et chaque semaine ou incluez ces renseignements dans le fichier avec une quantité de 0.',
    productNotIncluded:
        'Produit non inclus dans le fichier mis à jour. Entrez la quantité à ajouter.',
    yourOrders: 'Vos Commandes',
    makeitMapTemplateFieldsDescription:
        'Mapper les valeurs requises à leur colonne correspondante dans votre modèle. Toutes les valeurs ci-dessous sont obligatoires.',
    uploadFile: 'Téléverser un fichier',
    uploadMakeItOrder: 'Téléverser votre ou vos commandes Créer',
    uploadMakeItATMOrder: 'Upload Your ATM Order(s)',
    updateMakeItTemplateValues: 'Mettre à jour les valeurs du modèle Créer',
    submitOrders: 'Soumettre les commandes',
    needMoreInfo: 'Vous avez besoin d’autres renseignements?',
    viewAssignedShipToLocations: 'Afficher les destinataires attribués',
    yourAssignedShipToLocations: 'Vos destinataires attribués',
    dateModified: 'DATE DE MODIFICATION',
    makeItIntakeResetConfirmation:
        'Etes-vous sûr que vous voulez réinitialiser? Tout changement sera perdu.',
    orderDeleted: `Commande Créer supprimée`,
    deleteOrderModalTitle: `Confirmez que vous souhaitez supprimer cette commande`,
    deleteOrderModalBody: `Voulez-vous vraiment supprimer cette commande?`,
    palletQuantityZeroError: `Ne peut pas être 0. Veuillez mettre à jour ou supprimer.`,
    deleteLastProductModalTitle: `Confirmez que cette commande sera supprimée`,
    deleteLastProductModalSubheader: `La suppression de tous les produits de la commande supprimera le bon de commande.`,
    deleteLastProductModalBody: `Confirmez que vous souhaitez le supprimer.`,
    csvRowNumber: 'LIGNE .CSV',
    productIdModalTitle: 'Confirm Requested Product',
    confirmApplyAll: 'Confirm and Apply to All',
    productIdModalBody:
        'The customer product ID: <strong>{{ customerProductId }}</strong> you entered in your .csv file returned multiple products. Please choose the correct product for this order.',
    productIdDeliveryModalBody:
        'The customer product ID: <strong>{{ customerProductId }}</strong> you requested <strong>{{ requestedQuantity }} Pallets</strong> for in your .csv file returned multiple products. Please choose the correct product for this order.',
    chooseLaterDate: 'Veuillez choisir une date ultérieure',
    makeItBulkError:
        'Il y a des erreurs associées à une ou plusieurs commandes ci-dessous. Veuillez corriger.',
    reviewATMs: 'Examiner les ATM',
    editATM: 'Modifier les ATM',
    editATMsForecast: 'Modifier les ATM et prévision',
    editForecast: 'Modifier prévision',
    atmWeekSelector: 'Voir la semaine du',
    forecastWeekSelector: 'Regardez les prévisions pour la semaine de',
    noValidForecasts:
        'The upload did not result in any valid Plan It records. Please edit your file or use Plan It Guided.',
    orderForWk: 'Commande pour la semaine du',
    forTheWeekOf: 'pour la semaine de',
    forecastForWk: 'Prévisions pour la semaine du',
    editATMforWk: 'Modifier ATM pour la semaine du',
    addPallet: 'Ajouter 1 palette pour respecter votre plan d’approvisionnement',
    addPallets: 'Ajouter {0} palettes pour respecter votre plan d’approvisionnement',
    removePallet: 'Retirer 1 palette pour respecter votre plan d’approvisionnement',
    campaignQuantitiesConfirmed:
        'Campaign Quantities will be confirmed by your Ball representative.',
    removePallets: 'Retirer {0} palettes pour respecter votre plan d’approvisionnement',
    totalEnteredQuantity: 'Quantité totale saisie',
    of: 'de',
    quantityRounded: 'Quantité arrondie à la palette complète la plus près.',
    enterAQuantity: 'Veuillez saisir une quantité.',
    leadTimeMet: 'This Product’s lead time has been met and cannot be ordered during this week',
    atmSelectorMessage:
        'Une ou plusieurs expéditions comportent des avertissements ou des erreurs, veuillez les examiner.',
    atmWeekSelectorMessage:
        'Une ou plusieurs semaines comportent des avertissements ou des erreurs, veuillez les examiner.',
    previousQuantitySubmitted: 'Quantité précédente soumise',
    atmChangesReceived: 'Merci, vos modifications ont bien été reçues.',
    atmSubmitted: 'Merci, vos ATM ont été soumis.',
    submitATMError: "Le système n'a pas pu traiter vos modifications.",
    noProductsOrdered: "Aucun produit commandé pour les plans d'approvisionnement suivants:",
    eaches: 'Chacun',
    errorsOnly: 'Erreurs uniquement',
    warningsOnly: 'Avertissements uniquement',
    alertsOnly: 'Alertes uniquement',
    revisedQuantityToPurchase: 'Quantité révisée à acheter',
    submitATMs: 'Soumettre les ATM',
    productAttributes: 'Attributs du produit',
    atmWeekType: 'Vos semaines sont-elles saisies en lignes ou en colonnes?',
    atmQuantityType: 'Vos quantités sont-elles en palettes ou chacune?',
    prevQuantityPallets: 'Quantité précédente soumise (PL)',
    prevQuantityEaches: 'Quantité précédente soumise (EA)',
    revisedQuantityPallets: 'Quantité révisée à acheter (PL)',
    revisedQuantityEaches: 'Quantité révisée à acheter (EA)',
    quantityPallets: 'Quantité à acheter (PL)',
    quantityEaches: 'Quantité à acheter (EA)',
    currentAndUpcomingCampaigns: 'Current & Upcoming Campaigns',
    rows: 'Lignes',
    columns: 'Colonnes',
    firstRowOfData: 'Première ligne de données',
    weekNumberQuantity: 'Semaine {{ weekNumber }} Quantité',
    weekQuantityRequiredError: 'Nécessaire. Enlever si non nécessaire.',
    addAdditionalWeek: 'Ajouter une semaine supplémentaire',
    weekNumberDate: 'Semaine {{ weekNumber }} Date',
    enterWeekDatesBelow: 'VEUILLEZ SAISIR LES DATES DE VOS SEMAINES CI-DESSOUS:',
    weekRequired: 'Nécessaire. Veuillez mettre à jour ou supprimer une semaine cartographiée.',
    forecastedDemand: 'Demande prévue',
    reviewAtm: 'Passer en revue des ATM (Autorisation de fabrication)',
    availableReleaseDate: 'Semaine Disponible',
    selectRequestedWk: 'Sélectionnez la semaine demandée',
    selectForecastWk: 'Sélectionnez votre/vos semaine(s) de prévision',
    inactiveProductId: 'Ce produit est inactif et ne peut pas être commandé.',
    availableSupplyPlan: 'Plan d’approvisionnement disponible',
    move: 'Déplacer',
    copy: 'Copier',

    // -- MAKE IT BULK UPLOAD VALIDATION RULES -- //
    makeItBulkValidation: 'Validation des données de téléversement de masse Créer',
    orderRequestedWeek: 'Order Requested Week',
    doNotIncludeCommas: 'Do not include commas within your file.',
    quantityInEaches: 'Quantity (In Eaches)',
    upTo12Digits: 'Jusqu’à douze chiffres',
    integer: 'Entier',
    text: 'Texte',
    upTo6Digits: 'Jusqu’à six chiffres',

    // -- Plan IT BULK UPLOAD VALIDATION RULES -- //
    planItBulkValidation: 'Plan It Bulk Upload Data Validation',
    productNotRecognized: 'Produit Non Reconnu',
    includedProductsNotRecognized:
        'Un ou plusieurs des produits inclus dans ce téléversement ne sont pas reconnus.',
    here: 'here',
    ifProductIncorrect:
        'Si l’identifiant d’un produit est incorrect, veuillez le corriger et téléverser le plan de nouveau. Les produits non reconnus par la Source ne seront pas inclus dans cette prévision.',
    continueWithYourForecast:
        'Pour poursuivre votre prévision sans les produits non reconnus, cliquez sur « Continuer ».',
    editProductsCheckStatus:
        'Si vous souhaitez modifier des produits ou vérifier l’état des produits récemment ajoutés, vous pouvez gérer vos produits <1>ici</1>.',
    unrecognizedIdentified: 'Unrecognized Products Identified',
    unrecognizedCorrectIt:
        'One or more of the product IDs included in this upload is not recognized. If a Product ID is incorrect, please go back to correct it and re-upload the plan.',
    unrecognizedContinue:
        'If you wish to continue with unrecognized products included, fill out the table below including the desired products you wish to add to your forecast. The information will be sent to our team to review and reconcile. You will receive a notification regarding the status of your product. Please note that unrecognized products will not be available for Make It or Ship It orders until a resolution is received.',
    prodID: 'Identifiant du Produit',

    // -- PLAN IT TO MAKE IT CONVERSION-- //
    makeItFromPlanIt: 'Make It de Plan It',
    conversionReviewOrder: 'Vérifier la commande',
    makeItSummaryLink: 'Make It',

    // -- MAKE IT ATM SUMMARY -- //
    atmSummary: 'Sommaire des ATM',
    atmOrderNumber: 'Numéro de commande des ATM',
    atmOrderNum: 'Commande d’ATM',
    atmWeek: 'ATM de la semaine',
    totalQuantity: 'Quantité totale',
    playground: 'Playground',
    futureAtms: 'ATM Futurs',
    currentAtms: 'ATM Actuels',
    pastAtms: 'ATM Passés',
    underSupplyPlanWarning:
        'Vous êtes sous le plan d’approvisionnement pour un ou plusieurs groupes de produits dans cette ATM.',
    updateYourATM: 'Mettre à jour votre ATM',
    underSupplyPlanWarningProduct: "You're below the available supply plan for this product group",
    underSupplyPlanWarningProductPast:
        'You were below the available supply plan for this product group',
    orderWeek: 'Semaine de commande',
    leadTimeExceedsSelected: 'Le délai dépasse la semaine sélectionnée',

    // -- MAKE IT BULK ATM DETAILS -- //
    requestedWeek: 'Semaine demandée',
    requestedWeekAndPONumber: 'Semaine demandée et numéro de bon de commande (BC)',
    remainingInSupplyPlan: 'Restant dans le plan d’approvisionnement',
    requestedQuantity: 'Quantité demandée',

    // -- MAKE IT BULK ATM REVIEW -- //
    linkedDeliveryOrderOrLeadTimeWarning:
        'Un ou plusieurs produits ne peuvent être édités en raison des délais de livraison',
    makeItAtmDetails: 'Make It ATM Details',
    lineNumber: 'Numéro de ligne #',
    sameWeekShipTosWarning:
        'Une ou plusieurs commandes Make It existent pour cette semaine. Veuillez consulter la page de résumé <1>Make It</1> pour les détails.',
    sameWeekShipTosDraftsWarning:
        'Il y a des brouillons de commande Make IT pour cette semaine. Veuillez consulter la page <1>Make It Drafts</1> pour plus de détails.',

    // -- ASSIGNED SHIP TOS -- //
    yourAssignedShipTos: 'Votre envoyez à assignée',
    shipToName: 'Envoyez à nom',
    shipToAddress: 'Envoyez à l’adresse',
    shipTos: 'Envoyez à',
    date: 'Jour',
    distributeCountries: 'Pays de commercialisation du graphisme',
    availableBalanceDisclaimer:
        '* Le solde disponible est basé sur la semaine de livraison sélectionnée.',

    // -- User Admin -- //
    graphicsIntakeForm: 'Formulaire D’admission des Graphiques',
    draftGraphicsIntakeForms: 'Formulaires D’admission des Graphiques (Brouillons)',
    inProgressGraphicsStatuses: 'États des Graphiques en Cours',
    graphicsQuote: 'Devis des Graphiques',
    graphicsProof: 'Épreuve des Graphiques',
    graphicsStandardPilotCan: 'Norme Graphique (Canette Pilote)',
    recentlyCompletedGraphics: 'Graphiques Récemment Terminés',
    planning: 'Planification',
    newOpenProductionOrders: 'Bons de Production Nouveaux/Ouverts',
    newOpenDeliveryOrders: 'Bons de Livraison Nouveaux/Ouverts',
    draftDeliveryOrders: 'Bons de Livraison (Brouillons)',
    paymentsCIAOnly: 'Paiements (CIA Seulement)',
    orderPaymentStatus: 'Statut du Paiement de la Commande',
    orderPaymentFinancials: 'Financières de Paiement par Ordre',
    liquidTesting: 'Test de liquide',
    liquidIntakeForm: 'Formulaire D’admission Liquide',
    warrantyLetter: 'Lettre de Garantie',
    termsandConditions: 'Conditions Générales',
    temporarilyHideSKUs: 'Masquer Temporairement les SKU',
    manageBillTo: 'Gérer Bill-To',
    manageShipTo: 'Gérer Ship-To',
    customerProductIDMappingtoBallIDs: 'Cartographie de l’ID du Produit Client Pour Ball IDs',
    createNewUser: 'Créer de Nouveaux Utilisateurs',
    editpermissions: 'Modifier les Autorisations',
    pricing: 'Prix',
    termsAndConditions: 'Contrats et Documents',
    contractAcknowledgement: 'Accusé de Réception du Contrat',
    pricingBreakdown: 'Ventilation des Prix',
    disablePermissions: 'Désactiver les Autorisations',
    troubleshooting: 'Dépannage',
    procurementManagement: 'Gest. des Achats',
    forecastManagement: 'Gest. des Prévisions',
    packagingManagement: 'Gest. des Emballages',
    artManagement: 'Gest. des Illustrations',
    marketingManagement: 'Gest. de la Commercialisation',
    accountsPayableAccountsReceivable: 'Comptes Fournisseurs / Recevables',
    userAdministrator: 'Administrateur de Compte',
    selectedAccess: 'Accès Sélectionné',
    disableUser: 'Désactiver L’utilisateur',
    disableUserWarning:
        'Vous êtes sur le point de désactiver les autorisations de cet utilisateur sur votre compte. L’utilisateur devra créer une nouvelle demande pour récupérer les autorisations.',
    disableUserContinue: 'Voulez-vous poursuivre?',
    yesDisable: 'Oui, Désactiver',
    viewEdit: 'Afficher/Modifier',
    userInformation: 'Renseignements sur L’utilisateur',
    sentOn: 'Enviado',
    disable: 'Deshabilitar',
    resend: 'Renvoyer',
    confirmEmail: 'Confirmer le Courriel',
    newUserEmailHelper: 'Courriel@domaine.com',
    locationRoleAccessTableInst: `Choisissez le ou les rôles pour donner à cet utilisateur l’accès par emplacement. Affichez les autorisations disponibles d’un rôle en cliquant sur le nom du rôle ou`,
    locationRoleAccessTableRequest: `Choisissez le ou les rôles pour donner à cet utilisateur l’accès par emplacement. Affichez les autorisations disponibles d’un rôle en cliquant sur le nom du rôle ou`,
    locationRoleAccessTableInstLink:
        'affichez une comparaison de tous les rôles et de leurs autorisations applicables.',
    allAccess: 'Tous les Accès',
    coPacker: 'Co-Emballeur',
    warehousingLogistics: 'Entreposage et Logistique',
    acctAdmin: 'Admin. de Compte',
    acctPayableReceivable: 'Comptes Fournisseurs/Recevables',
    graphicsVendor: 'Fournisseur Graphique',
    pricingContact: 'Personne-ressource chargée des prix',
    rolesLocations: 'Rôles (nombre d’emplacements)',
    activeUsers: 'Utilisateurs Actifs',
    accountAdmin: 'Administrateur de Compte',
    arAp: 'Comptes Fournisseurs/Recevables',
    artMgmt: 'Gest. des Illustrations',
    forecastMgmt: 'Gest. des Prévisions',
    marketingMgmt: 'Gest. de la Commercialisation',
    orderFulfillment: 'Exécution des Commandes',
    packagingMgmt: 'Gest. des Emballages',
    procurementMgmt: 'Gest. des Achats',
    makeItOnly: 'Make It Only',
    planItOnly: 'Plan It Only',
    impersonation: 'Impersonation',
    roleAccessTableSearchPlaceholder: 'Search...',
    confirmChangesWillNotBeSaved:
        'Les modifications apportées ne seront pas enregistrées. Êtes-vous sûr que vous voulez procéder?',
    pendingRequests: 'Requêtes en Attente',
    location: 'Emplacement',
    roles: 'Rôle(s)',
    disble: 'Désactiver',
    reviewRequest: 'Demande de Révision',
    disableUserError: 'Le système n’a pas pu désactiver l’utilisateur sélectionné',
    locationRoleAccess: 'Accès à L’emplacement et aux Rôles',
    latestSubmissionDate: 'Dernière Date de Soumission',
    resubmit: 'Soumettre à Nouveau',
    cancelRequest: 'Demande D’annulation',
    userAdminSuccessMessage: 'Les autorisations des utilisateurs ont été mises à jour',
    reviewAccessRequest: 'Examiner la demande d’accès',
    approve: 'Approuver',
    addUser: 'Ajouter un Utilisateur',
    pendingRegistration: 'Inscription en Attente',
    roleComparison: 'The Source Comparaison des Rôle',
    permissionsFailedToUpdate:
        'Les modifications demandées n’ont pas pu être soumises en raison d’une erreur inconnue.',
    confirmSubmission: 'Confirm Submission',
    submitMakeItForFollowing:
        'Vous êtes sur le point de soumettre une ou plusieurs commandes Make It pour les éléments suivants:',
    submitMakeItErrorWeeks:
        'Veuillez noter que la ou les semaines suivantes ne sont pas comprises à la suite d’une ou de plusieurs erreurs :',
    areYouSureSubmit: 'Voulez-vous vraiment la soumettre?',
    noCustomerPONumber: 'Pas de bon de commande client #',
    noCustomerPO: 'No Customer PO',
    minQtyNotMet: 'Minimum order quantity not met',
    productWithoutQuantity: 'Product without Quantity',
    multipleErrors: 'Erreurs multiples',
    not: 'not',
    key: 'Clé',
    noSupplyPlan: 'Aucun plan d’approvisionnement',
    overSupplyPlan: 'Plan d’approvisionnement excédentaire',
    underSupplyPlan: 'Plan d’approvisionnement insuffisant',
    supplyPlanMet: 'Plan d’approvisionnement respecté',
    orderWeeks: 'Semaines de commande',
    makeItQuantity: 'Quantité de « Make It »',

    // -- USER ADMIN REQUEST ACCESS -- //
    requestAccess: 'Demande D’accès',
    locationInformation: 'Renseignements sur L’emplacement',
    addAnotherShipTo: 'Ajouter un Autre Numéro de Livraison/de Facturation',
    confirm: 'Confirmer',
    invalidIds:
        'Un ou plusieurs numéros d’identification ne sont pas valides. Veuillez mettre à jour ou supprimer ces emplacements pour continuer.',
    shipToBillTo: 'Ajouter un Autre Numéro de Livraison/de Facturation',
    enterComment: 'Saisir des Commentaires',
    rejectAction: 'Rejeter',
    requestAccessUnauthTitle: 'Il semble que vous n’ayez pas encore accès à {{cette zone}}',
    requestAccessUnauthBody:
        'Aucun problème, utilisez le bouton ci-dessous pour demander à votre administrateur l’accès à ',
    accessRequest: 'Demande D’accès',
    requestAccessSuccess: `Votre demande d’accès a été soumise avec succès et sera examinée par l’administrateur des utilisateurs correspondant.`,
    requestAccessError: 'No administrator assigned for the requested account(s)',
    confirmation: 'Confirmation',
    invitationSent: 'Votre invitation à rejoindre <i>The Source</i> a été envoyée',

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Télécharger. PDF',
    effectiveDate: 'Date d’entrée en vigueur',
    currency: 'Monnaie',
    itemDescription: 'Description de l’article',
    currentBasePrice: 'Prix de base actuel',
    newBasePrice: 'Nouveau prix de base',
    freightCharges: 'Frais de transport',
    freightChargeFromLocation: 'Frais de transport depuis l’emplacement (si disponibles)',
    freightChargesAvailable: 'Frais de transport (si disponibles)',
    newBaseWithFreight: 'Nouveau prix de base incluant le transport (si disponible)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',

    // -- KB MAX -- //
    errorProcessing: 'Il y a eu une erreur de traitement.',
    exitWarning: 'Êtes-vous sûr de vouloir quitter?',
    yesExit: 'Oui, Quitter',

    // -- LIQUID TESTING -- //
    liquidTestThankYou: 'Merci d’avoir soumis vos informations du test de liquide',
    liquidTestIntakeForm: 'Formulaire d’admission au test de liquide',
    liquidTestIntakeFormPrintInstructions:
        'Si vous n’avez pas imprimé le formulaire précédemment affiché, veuillez l’imprimer ici et l’inclure lorsque vous postez votre échantillon de liquide. Vous pouvez également imprimer ou télécharger une copie pour vos registres.',
    liquidTestIntakeFormPrintInstructionsNoTest:
        'Vous pouvez imprimer ou télécharger votre formulaire d’admission pour vos registres ici.',
    noLiquidTestNeeded:
        'Sur la base de ces informations, votre liquide n’a pas besoin d’être soumis à un test de liquide.',
    aceticAcid: 'Acide acétique',
    addedSulfites: 'Sulfites ajoutés',
    calciumLactate: 'Lactate de calcium',
    foodDyes: '> 80 ppm de colorants alimentaires',
    lacticAcid: 'Acide lactique',
    malicAcid: 'Acide malique',
    liquidTest: 'Test de liquide',
    beverageInformation: 'Information sur la boisson',
    alcoholByVolumePercentage: '% ABV',
    potentialOfHydrogen: 'pH',
    fourIngredientBeerFlag: 'Bière à 4 ingrédients',
    beverageDescription: 'Description de la boisson',
    isRefrigerationRequired: 'La réfrigération est-elle nécessaire?',
    concentrationSample: 'Échantillon de Sirop',
    beverageContains: 'La boisson contient',
    potentialOfHydrogenRange: 'pH must be a number between 0 and 14',
    instructions: 'Instructions',
    liquidIntakeTestRequired:
        'Veuillez imprimer ce formulaire et l’inclure dans l’emballage dans lequel vous expédiez votre échantillon liquide. Effectuer l’expédition à l’adresse de livraison figurant à droite.',
    liquidIntakeTestNotRequired:
        'Sur la base de ces informations, votre liquide n’a pas besoin d’être soumis à un test de liquide. Veuillez imprimer ce formulaire pour vos registres.',
    newProduct: 'Nouveau produit',
    updatedRecipe: 'Recette mise à jour',
    beverageNameOrProjectName: 'Veuillez fournir au moins l’un des éléments suivants',
    graphicIdAssociated: 'Avez-vous un identifiant graphique associé à cette boisson?',
    doNotKnowPH: 'Je ne connais pas mon pH',
    fourIngredientBeer: 'Est-ce une simple bière à 4 ingrédients?',
    newProductOrUpdate:
        'Est-ce un nouveau produit ou une recette mise à jour d’un produit existant?',
    doesBeverageContain: 'Votre boisson contient-elle:',
    isConcentrateSample: 'Est-ce un échantillon concentré?',
    throwRatio: 'Vitesse de projection',
    formSubmissionError:
        'Le système n’a pas été en mesure de soumettre le formulaire. Veuillez réessayer plus tard.',
    formSubmissionErrorContact:
        'The system was unable to submit the form. Please try again later, if the issue persists please contact your account representative.',
    submittingUser: 'Utilisateur qui fait la demande',
    submittedDate: 'Date de présentation',
    testingRequired: 'Testing Required?',
    beverageName: 'Nom de la boisson',
    liquidTestDocuments: 'Documents du test de liquide',
    liquidTestId: 'Liquid Test ID',
    printLiquidTestIntake: 'Imprimer Formulaire d’admission au test de liquide',
    packingSlip: 'Packing Slip',
    intakeStatusARS: 'En attente de réception de l’échantillon',
    intakeStatusNTR: 'Aucun test requis',
    intakeStatusRPT: 'Reçu, en attente de test',
    intakeStatusWAQ: 'Garantie approuvée, mais qualifiée',
    intakeStatusWRA: 'Garantie approuvée',
    intakeStatusWRR: 'Garantie rejetée, soumettre à nouveau',
    printIntakeForm: 'Imprimer le formulaire d’admission',
    closedDate: 'Date de fermeture',
    warrantyDetails:
        'Pour plus de détails sur la garantie des tests de liquides approuvés, veuillez vous référer à votre contrat ou aux conditions générales.',
    beverageNameOrProjectNameRequired: 'Nom de boisson ou nom du projet requis',
    phone: 'Téléphone',
    viewPrint: 'Vue /Imprimer',
    submitNewTest: 'Soumettre un nouveau test',
    alcoholByVolumeHelper: 'ABV doit être un nombre compris entre 0 et 100',
    potentialOfHydrogenHelper: 'Le pH doit être un nombre compris entre 0 et 14',

    // -- TERMS AND CONDITIONS -- //
    acceptTermsAndConditions: 'Accepter les conditions générales',
    authorizedTermsAndConditions:
        'Êtes-vous autorisé à accepter les conditions générales au nom de votre entreprise?',
    readAndAgree: 'J’ai lu et j’accepte toutes les conditions générales ci-dessus.',
    cannotImportAlcohol: 'Ball ne peut pas importer l’alcool. Pour obtenir de l’aide, contactez: ',
    adminNotified: 'Votre administrateur a été informé de votre réponse.',

    // -- SALES AND USE TAX -- //
    returnToDashboard: 'Retourner au tableau de bord',
    salesAndUseTaxBanner: 'Configuration du compte',
    instructionsAnchorText: 'Instructions',
    sellerInformationAnchorText: 'Informations sur le vendeur',
    buyerInformationAnchorText: 'Informations sur l’acheteur',
    eSignatureAnchorText: 'Signature électronique',
    salesAndUseTaxSideHeader: 'Instructions propres à l’État',
    salesUseTaxInstructionsLinkText: 'Instructions propres à l’État',
    salesUseTaxInstructionsHeader:
        'Instructions concernant le certificat de taxe de vente et d’utilisation uniforme',
    salesUseTaxInstructionsBody1: 'Aux clients du vendeur:',
    salesUseTaxInstructionsBody2: `Afin de se conformer à la majorité des exigences des lois nationales et locales sur la taxe de vente, le vendeur doit avoir dans ses dossiers un certificat d’exonération correctement rempli de tous ses clients qui réclament une exonération de taxe de vente. Si le vendeur ne dispose pas de ce certificat, il est tenu de percevoir la taxe pour l’État dans lequel le bien est livré ou le service est fourni.`,
    salesUseTaxInstructionsBody3: `Si l’acheteur a droit à l’exonération de la taxe de vente, l’acheteur doit remplir le certificat et l’envoyer au vendeur dans les meilleurs délais. Si l’acheteur achète hors taxes pour une raison que ce formulaire ne prévoit pas, l’acheteur doit envoyer au vendeur son certificat ou relevé spécial.`,
    salesUseTaxInstructionsBody4: `Avertissement au vendeur:`,
    salesUseTaxInstructionsBody5: `Pour que le certificat soit accepté de bonne foi par le vendeur, ce dernier doit veiller à ce que le bien ou le service vendu soit d’un type normalement vendu en gros, revendu, loué à bail, loué ou incorporé en tant qu’ingrédient ou composant d’un produit fabriqué par l’acheteur puis revendu dans le cadre normal de ses activités. Un vendeur ne faisant pas preuve de diligence pourrait être tenu responsable de la taxe de vente due dans certains États ou dans certaines villes. L’utilisation abusive de ce certificat par le vendeur, le preneur ou son représentant peut être passible d’une amende, d’une peine d’emprisonnement ou de la perte du droit de délivrer un certificat dans certains États ou dans certaines villes.`,
    sellerInformationCheckboxSubheader:
        'Délivré(s) au vendeur (sélectionnez tout ce qui s’applique) :',
    typeOfCertificateLabel: 'Type de certificat',
    blanketCertificateCheckbox: 'Certificat général',
    singlePurchaseCheckbox:
        'Le certificat d’achat unique se rapporte à la facture/au bon de commande n°:',
    nameOfFirmLabel: 'Nom de l’entreprise',
    certifyBody: 'Je certifie que:',
    buyerSubtext: 'Acheteur',
    engagedAsRegisteredLabel: 'Est engagé comme enregistré:',
    wholesalerCheck: 'Vente en gros',
    retailerCheck: 'Vente au détail',
    manufacturerCheck: 'Fabricant',
    sellerCheck: 'Vendeur (Californie)',
    lessorCheck: 'Preneur',
    otherCheck: 'Autre (préciser)',
    registeredBody2: `et est enregistré auprès des États et des villes énumérés ci-dessous dans lesquels votre entreprise devrait nous livrer des achats et que ces achats sont destinés à la vente en gros, à la revente, aux ingrédients ou aux composants d’un nouveau produit ou service à revendre, à louer bail ou à louer dans le cadre normal des affaires. Nous exerçons nos activités dans la vente en gros, la vente au détail, la fabrication, la location à bail (location) des produits suivants:`,
    descriptionOfBusinessLabel: 'Description des activités',
    descriptionOfPropertyLabel:
        'Description générale des biens corporels ou des services taxables à acheter auprès du vendeur',
    stateSelect: 'Sélectionner l’état',
    registrationNumberLabel:
        'Enregistrement d’État, permis de vendeur ou numéro d’identification de l’acheteur (facultatif)',
    addAnotherStateLabel: 'Ajouter un autre État',
    eSignatureHeader: 'Signature électronique',
    eSigCertify:
        'En outre, je certifie que si un bien ou un service ainsi acheté hors taxe est utilisé ou consommé par l’entreprise pour le rendre soumis à une taxe de vente ou d’utilisation, nous paierons la taxe due directement à l’autorité fiscale compétente lorsque la loi de l’État le prévoit ou en informerons le vendeur pour la facturation des taxes supplémentaires. Ce certificat fera partie de chaque commande que nous pourrons vous donner par la suite, sauf indication contraire, et sera valide jusqu’à ce que nous l’annulions par écrit ou qu’il soit révoqué par la ville ou par l’État. Sous peine de parjure, je jure ou j’affirme que les informations sur ce formulaire sont véridiques et correctes quant à toute question importante.',
    saveProgressText:
        'Veuillez corriger ou supprimer toute donnée invalide avant d’enregistrer votre brouillon',

    // -- ONBOARDING WIZARD -- //
    saveProgressSecondaryText: 'Votre brouillon a été enregistré avec succès',
    joinBallBanner: 'Rejoinez Ball',
    joiningBall: 'Rejoindre Ball',
    step: 'Pas',
    gettingStarted: 'Démarrer',
    gettingStartedText:
        'Nous sommes ravis que vous souhaitiez passer à l’étape suivante avec nous. Avant de pouvoir créer vos produits, nous aurons besoin d’informations supplémentaires de votre part.',
    congratulations: 'Félicitations!',
    onboardingCongratulationsText:
        'Votre compte a été configuré et vous pouvez commencer à utiliser La Source. Avant de pouvoir passer votre première commande, vous devez : ',
    onboardingCongratulationsAdditionalText:
        'Vous pouvez également ajouter des membres de votre entreprise en utilisant la fonction d’administration des utilisateurs dans l’en-tête ci-dessus ou en',
    clickingHere: 'cliquant ici',
    submitAGraphic: 'Soumettre un graphique',
    submitLiquidSample: 'Soumettre un échantillon liquide pour test',
    productSelectionHeader: 'Confirmed Product Selection and Supply Capacity',
    productSelectionText:
        'Bonnes nouvelles! Le produit que vous avez sélectionné est disponible et nous pouvons procéder à votre inscription en tant que client de Ball. Vous devrez suivre les étapes ci-dessous et vous serez alors prêt à passer une commande.',
    yourProducts: 'Votre/vos produits',
    completeStatus: 'Terminée',
    accountSetupHeader: 'Configuration du compte',
    accountSetupTCTitle: 'Conditions Générales',
    accountSetupTCText:
        'Vous, ou toute personne autorisée, devrez accepter les conditions générales de Ball avant de poursuivre le processus d’intégration.',
    accountSetupReviewTCAction: 'Consulter les conditions générales',
    billAndShipTitle: 'Facturation et expédition',
    billAndShipText:
        'Il est important que nous ayons les informations de facturation et d’expédition correctes pour votre entreprise.',
    billAndShipAction: 'Saisir les informations',
    salesAndUseTitle: 'Taxe de vente et d’utilisation',
    salesAndUseText:
        'Les clients basés aux États-Unis doivent remplir un formulaire de taxe de vente et d’utilisation pour l’État dans lequel ils opèrent.',
    salesAndUseAction: 'Saisir les informations',
    continue: 'Continuer',
    creditApplicationTitle: 'Demande de crédit',
    creditApplicationText:
        'Intéressé par une demande de crédit auprès de Ball? Remplissez cette demande pour démarrer le processus.',
    creditApplicationAction: 'Démarrer l’application',
    creditApplicationActionContinue: 'Continuer l’application',
    productSetupHeader: 'Configuration du produit',
    graphicsTitle: 'Graphisme',
    graphicsText:
        'Voici la partie amusante! Commencez le processus graphique en remplissant le formulaire d’admission graphique et en soumettant votre œuvre d’art.',
    graphicsAction: 'Démarrer le processus graphique',
    liquidTitle: 'Liquid Testing',
    liquidText:
        'Votre boisson devra peut-être passer par des tests de compatibilité.  Pour commencer le processus de test, veuillez remplir notre formulaire en ligne et envoyer par la poste vos échantillons liquides.',
    liquidAction: 'Démarrer le processus de test',
    placeYourOrderHeader: 'Passez votre commande',
    placeYourOrderText:
        'Le moment est venu! Passez votre commande pour votre produit sur votre tableau de bord!',
    placeYourOrderAction: 'Fais-le',
    optionalStepText: 'Facultative',
    accountInformation: 'Informations sur le compte',
    billingAddress: 'Adresse de facturation',
    shippingInformation: 'Informations sur la livraison',
    accountSetup: 'Configuration du compte',
    billMailingName: 'Nom du destinataire de la facture',
    billingAddressLine1: 'Adresse de facturation, ligne 1',
    billingAddressLine1Helper: 'Type de rue, nom de rue, numéro de maison',
    billingAddressLine2: 'Adresse de facturation, ligne 2 (Facultative)',
    billingAddressLine2Helper: 'Quartier, municipalité',
    billingAddressLine3: 'Adresse de facturation, ligne 3 (Facultative)',
    billMailingNameHelper: 'Nom de la personne qui recevra les communications',
    shippingSameAsBillingAddress:
        'Votre adresse de livraison est-elle la même que votre adresse de facturation?',
    shippingName: 'Nom pour la livraison',
    shippingAddressLine1: 'Adresse de livraison, ligne 1',
    shippingAddressLine1Helper: 'Type de rue, nom de rue, numéro de maison',
    shippingAddressLine2: 'Adresse de livraison, ligne 2 (Facultative)',
    shippingAddressLine2Helper: 'Quartier, municipalité',
    shippingAddressLine3: 'Adresse de livraison, ligne 3 (Facultative)',
    shippingNameHelper: 'Nom de la personne qui recevra les envois',
    shippedOrDelivered:
        'Avez-vous besoin que vos canettes vous soient expédiées ou allez-vous les récupérer?',
    palletConfiguration: 'Configuration de la palette',
    anySpecialEquipmentNeeds: 'Tout besoin d’équipement spécial?',
    cfdi: 'CFDI',
    taxId: 'Numéro d’identification fiscale',
    legalEntityOrPrivateEntrepreneur: 'Êtes-vous une entité juridique ou un entrepreneur privé',
    importerOfRecord: 'Importateur officiel',
    customer: 'Client',
    requiredDocuments: 'Documents requis',
    entrepreneur: 'Entrepreneur',
    legalEntity: 'Entité juridique',
    reqDocInstructionHeader: 'Vous devez fournir les documents suivants:',
    legalEntReqDocInstruction1: 'Accord d’exploitation',
    legalEntReqDocInstruction2:
        'Lettre d’habilitation du représentant légal (parfois, cette lettre fait partie de l’accord d’exploitation)',
    legalEntReqDocInstruction3: 'Identifiant du représentant légal (identifiant INE)',
    legalEntReqDocInstruction4: 'Enregistrement SAT (document IRS du Mexique)',
    legalEntReqDocInstruction5: 'Preuve d’adresse de l’entreprise',
    entrepreneurReqDocInstruction1: 'Lettre de situation SAT (document IRS du Mexique)',
    entrepreneurReqDocInstruction2: 'Carte INE (identifiant officiel du Mexique)',
    entrepreneurReqDocInstruction3:
        'Justificatif de domicile (facture de téléphone ou de service public)',
    accountSetupFailedToSubmit: 'Échec de l’envoi du formulaire de configuration du compte',
    accountSetupCantSaveDraft: 'Échec de l’enregistrement du brouillon',
    invalidDataMessage:
        'Veuillez corriger ou supprimer toute donnée non valide avant d’enregistrer votre brouillon',
    inviteToHelpComplete: 'Inviter quelqu’un à aider à accomplir une tâche',
    inviteModalHelperText:
        'Vous pouvez inviter d’autres personnes à remplir des informations lors du processus d’adhésion. Entrez leur courriel ci-dessous et sélectionnez les champs que vous souhaitez qu’ils complètent. Ils recevront un courriel pour compléter la section qui leur a été attribuée.',
    informationReceived: 'Su información fue recibida y está siendo procesada.',
    usOnly: '(É.-U. uniquement)',
    selectAtLeastOne: 'Requis pour sélectionner au moins un.',
    moreProductsThanShown:
        'More products are approved but not shown. Go to <1>My Projects</1> to learn more.',

    // -- CREDIT APPLICATION FORM -- //
    companyInformation: 'Informations sur l’entreprise',
    supplierReferences: 'Références fournisseurs',
    additionalCommentsRequired: 'Commentaires supplémentaires',
    currentFinancialStatement: 'État financier actuel',
    creditAppListHeader:
        'Pour faire une demande de crédit auprès de Ball, vous devez fournir les éléments suivants:',
    creditAppFirstLI:
        'Informations sur l’entreprise, y compris, mais sans s’y limiter, la marge de crédit mensuelle souhaitée, les coordonnées du service des comptes fournisseurs, l’adresse de facturation et l’adresse réelle, les noms et titres des propriétaires/dirigeants/partenaires de l’entreprise, etc.',
    creditAppSecondLI:
        'Trois références fournisseurs avec des montants égaux ou supérieurs à la marge de crédit mensuelle souhaitée',
    creditAppThirdLI: 'État financier actuel expliquant pourquoi il n’est pas présent',
    legalNameOfBusiness: 'Nom légal de l’entreprise',
    businessName: 'Nom commercial (si différent)',
    businessNameHelper: 'En cas de division, de filiale',
    typeOfBusiness: 'Type d’activités',
    monthlyLineOfCredit: 'Marge de crédit mensuelle souhaitée',
    billingCountry: 'Pays de facturation',
    differentRealAddress: 'Votre adresse réelle est-elle la même que votre adresse de facturation?',
    accountsPayableContact: 'Contact des comptes fournisseurs',
    accountsPayableContactEmail: 'Courriel de contact des comptes fournisseurs',
    stateOfIncorporation: 'État de constitution',
    dateEstablished: 'Date établie',
    companyInfoHelper:
        'Fournissez les informations pour les dirigeants, les partenaires ou le propriétaire de votre entreprise.',
    supplierReferenceHelper:
        'Vous devez fournir trois (3) références avec des montants égaux ou supérieurs à la marge de crédit demandée!',
    arContact: 'Contact des comptes clients',
    arEmail: 'Courriel des comptes clients',
    faxNumber: 'Numéro de télécopie',
    financialStatementHelper:
        'Si vous enregistrez votre progression et quittez le formulaire, il vous sera demandé de télécharger ce fichier à votre retour. Le fichier ne sera pas enregistré.',
    explanationHelper:
        'Si vous ne téléchargez pas d’état financier actuel, veuillez expliquer pourquoi.',
    addAnother: 'Ajouter',
    creditApplicationTitleText:
        'Intéressé par une demande de crédit auprès de Ball? Remplissez cette demande pour démarrer le processus.',
    explanation: 'Explication',
    actualMailingName: 'Nom du destinataire à l’adresse réelle',
    actualCountry: 'Pays de l’adresse réelle',
    actualAddressLine1: 'Adresse réelle, ligne 1',
    actualAddressLine2: 'Adresse réelle, ligne 2 (facultative)',
    actualAddressLine3: 'Adresse réelle, ligne 3 (facultative)',
    actualCity: 'Ville de l’adresse réelle',
    actualStateProvince: 'État/province de l’adresse réelle',
    actualPostalCode: 'Code postal de l’adresse réelle',
    numericInput: 'Entrée monétaire non valide',
    usePreviouslyEnteredBillingAddress: 'Use previously entered Billing Address?',

    // -- CAPACITY CHECKS -- //
    capacityCheckSuccessMessage:
        'Votre demande a été soumise! Les examens prennent généralement de 2 à 4 semaines. Un membre de notre équipe vous contactera une fois terminée.',
    myProjectsCapacityCheckStatus: 'Mes projets / Statut du contrôle des capacités',

    // -- ATM PRODUCT PLANNING -- //
    supplyPlanAttributes: "Attributs du plan d'approvisionnement",
    supplyPlan: 'Plan d’approvisionnement',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'La fenêtre de demande pour {0} sera fermée le {1}. La première date de production disponible est le {2}. ',
    CAMPAIGN_UPCOMING:
        'La fenêtre de demande pour {0} s’ouvrira le {1}. Les demandes doivent être passées avant le {2} et la première date de production disponible est le {3}. ',
    CAMPAIGN_UPDATE:
        'Les informations de la fenêtre de demande pour votre produit {0} ont changé. La fenêtre de demande mise à jour est : {1} — {2} et la première date de production disponible est {3}. ',
    ALLOCATIONS: 'You have a new Supply plan for the week(s) of: {0}.  ',
    NEW_PRODUCT:
        'A new product has been added to your product portfolio: {0} - {1}.  You are now able to add this product to your orders. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Date de création',
    unauthorizedPoTooltip: 'Non autorisé à gérer les OP pour cet emplacement',
    discard: 'Abandonner',
    viewDraft: 'View Draft',
    archiveDraftWarning: 'Archived drafts will expire and no longer show in archive after 30 days.',
    resumeOrder: 'Reprendre Order',
    lockWindowWarning: 'Cette semaine sera verrouillée pour être commandée dans {0} jours',
    draftDisplayWarning:
        'Les brouillons ne s’affichent plus après le début de la fenêtre de verrouillage',
    discardDraftAreYouSure: 'Êtes-vous sûr de vouloir jeter votre brouillon?',
    discardDraftWillBeDeleted: 'Votre projet de commande sera supprimé.',
    openDrafts: 'Open Drafts',
    archivedDrafts: 'Archived Drafts',
    restoreDraft: 'Restore Draft',
    selectWeek: 'Select Week',
    restore: 'Restore',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Gérer les Produits',
    productInformation: 'Renseignements sur le produit',
    activeProducts: 'Produits Actifs',
    inactiveProducts: 'Produits Inactifs',
    unrecognizedProducts: 'Unrecognized Products',
    displayName: 'Afficher un nom',
    pendingDataChangeAndDeactivation: 'Pending Data Change & Deactivation',
    pendingDataChangeAndActivation: 'Pending Data Change & Activation',
    productSizeAndShape: 'Product Size/Shape',
    productsNotRecognized:
        'These products were included on a Plan It file but are not recognized by Ball.',
    requestDeactivation: 'Demande de Désactivation',
    editProductInfo: 'Renseignements sur la Modification du Produit',
    assignedGraphicId:
        'Indique que l’dentifiant graphique est attribué à un produit actuellement actif',

    // Countries
    usa: 'EEUU',
    eu: 'Europa',
    uk: 'Royaume-Uni',
    germany: 'Alemania',
    france: 'Francia',
    canada: 'Canadá',
    china: 'China',
    japan: 'Japón',
    southKorea: 'Corea del Sur',
    ausNZ: 'Australia/Nueva Zelanda',
    australia: 'Australia',
    nz: 'Nueva Zelanda',
    mexico: 'México',
    india: 'India',
    vietnam: 'Vietnam',
    taiwan: 'Taiwan',
    russia: 'Russia',
    argentina: 'Argentina',
    brazil: 'Brasil',
    paraguay: 'Paraguay',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    noCountryApplies: 'Ninguno de los países especificados se aplica',
    planIt: 'Planifiez-le',
    productAlerts: 'Product Alerts',
    forecastBulkUpload: 'Téléchargement en masse des prévisions',
    forecastCreateMappingText: 'Carte à jour des valeurs du modèle des prévisions',
    forecastUpdateMappingText: 'Mise à jour des valeurs du modèle des prévisions',
    forecastUploadSectionHeader: 'Télécharger vos prévisions',
    forecast: 'Prévisions',
    forecastWeek: 'Prévisions pour la semaine',
    previousForecastedQuantity: 'Quantité précédemment prévue',
    revisedForecastedQuantity: 'Quantité prévue révisée',
    reviewMy: 'Réviser mon / ma',
    forecastOrderNumber: 'Numéro de commande des prévisions',
    forecastedQuantity: 'Quantité prévue',
    viewMy: 'Regardez mon / ma',
    forecastDetails: 'Détails du Prévisions',
    forecastNum: 'Prévisions #',
    summary: 'Résumé',
    inactive: 'Inactif',
    active: 'Actif',
    forecasts: 'Prévisions',
    forecastSummary: 'Résumé des Prévisions',
    planItSummary: 'Résumé du Planifiez-le',
    planItPlanItSummary: 'Planifiez-le / Résumé du Planifiez-le',
    forecastedQuantityPL: 'Quantité prévue (PL)',
    forecastedQuantityEA: 'Quantité prévue (EA)',
    planItDashboardDesc:
        'Créez et soumettez vos prévisions de commande. Mettez à jour une prévision précédemment soumise via le téléchargement en masse.',
    submitPlanIt: 'Soumettre planifiez-le',
    createPlanItLink: 'Créer / Mettre à jour planifiez-le',
    createAPlanIt: 'Créer un « Plan It »',
    createAMakeIt: 'Create a Make It',
    planItSummaryDashboardDesc:
        'Consultez les prévisions de commandes soumises précédemment. Modifiez les prévisions de commandes futures.',
    viewPlanItSummary: 'Voir le résumé du plan',
    selectProductsPlanIt: 'Ajouter des produits à vos prévisions:',
    selecteForecastWeeks: 'Sélectionnez votre/vos semaines(s) de prévision pour continuer.',
    yourForecast: 'Vos prévisions',
    planItGuidedPrompt: 'Souhaitez-vous soumettre un » Plan It » sans télécharger de fichier?',
    createForecast: 'Créer une prévision',
    addInstructionsPlanIt: `Créez votre prévision en cliquant sur le bouton « AJOUTER » pour chaque produit que vous souhaitez inclure`,
    submitForecast: 'Soumettre des prévisions',
    thankYouForecast: 'Thank You, Your Forecast(s) have been submitted',
    productIncluded:
        'Produit inclus dans la prévision précédente. Définir la quantité sur 0 pour le supprimer.',
    noEligiblePlanItRecords: 'No eligible Plan It records exist for the current location.',
    visitPlanIt: 'Visit Plan It',
    toCreate: 'to create one.',
    makeItMoq: 'Quantité minimale de commandes « Make It »',
    planItGuided: '« Plan It » Guidé',

    // -- SCRAP IT -- //
    scrapIt: 'Scrap It',
    availablePallets: 'Palettes disponibles',
    inputPallets: 'Palettes entrantes',
    createRequest: 'Créer la demande',
    replicateForecast: 'Replicate Forecast',
    selectQuantity: 'Sélectionner les quantités des soldes aux débris:',
    yourRequest: 'Votre demande',
    youCanReview: 'Vous pourrez vérifier les produits sélectionnés sur l’écran suivant.',
    quantityToScrap: 'Quantity to Scrap',
    scrapItOrderNumber: 'Scrap It Request Order Number',
    totalAvailableBalance: 'Total Available Balance',
    cannotExceedAvailableBalance: 'Ne peut dépasser le solde disponible',
    reviewScrapItRequest: 'Vérifier la demande « Scrap It » ',
    scrapItRequest: 'Scrap It Request',
    scrapItRequestOrderNumber: 'Scrap It Request Order #',

    // -- PRODUCT STATUS CHANGES -- //
    PSC00001: 'Modification des Données en Attente',
    PSC00002: 'Pending Activation',
    PSC00003: 'Désactivation en Attente',
    PSC00004: 'Pending Plate Change',

    // -- EDIT PRODUCT MODAL -- //
    editProductUpdateFields: 'Edit fields below to update product information.',
    previousForecasts: 'Prévisions précédentes',
    previousForecast: 'Prévisions précédente',
    copyToMakeIt: 'Copier vers « Make It »',

    // -- INACTIVE PRODUCTS MODAL -- //
    productAlertsFound: '({0}) Product Alerts Found in Uploaded File',
    inactiveProductsIdentified: 'Produits Inactifs Identifiés',
    inactiveProductsSubheader:
        'We have identified inactive products on one or more of the forecasts you are trying to convert. These products will not be included on your Make it.  Please reach out to your Account Representative if you have questions.',
    proceedWithConversionText: 'Click continue to proceed with conversion.',
    identifiedTheFollowingInactiveProducts:
        'Nous avons identifié les produits inactifs suivants inclus dans votre fichier :',
    csvLineNumber: 'Numéro de Ligne',
    inactiveProductsMustBeActivated:
        'Les produits inactifs doivent être activés avant de créer un « Make it ». Veuillez communiquer avec votre interlocuteur Ball pour discuter de la manière d’activer les produits.',

    // -- ADD WEEK MODAL -- //
    selectWeekToAdd: 'Sélectionner en tant que semaine à ajouter à votre « Make It » (Faire)',
    selectWeekToAddPlanIt: 'Sélectionner en tant que semaine à ajouter à votre « Plan It » (Faire)',

    // -- ADD SHIP TO -- //
    customerName: 'Customer Name',
    customerId: 'Customer ID',
    customerAddress: 'Customer Address',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2 (Optional)',
    addressLine3: 'Address Line 3 (Optional)',
    vat: 'VAT',
    cfid: 'CFID',
    userAdminContact: 'User Admin Contact',
    userAdminPhoneNumber: 'User Admin Phone Number',
    userAdminEmail: 'User Admin Email',
    freightInformation: 'Freight Information',
    orderingInformation: 'Ordering Information',
    basecoat: 'Basecoat',
    tactile: 'Tactile',
    satin: 'Satin',
    laserTab: 'Laser Tab',
    punch: 'Punch',
    coloredTab: 'Colored Tab',
    coloredShell: 'Colored Shell',
    selectProductsShort: 'Select Products',
    anticipatedItems: 'Anticipated Items',
    addItem: 'Add Item',
    both: 'Both',
    specialtyPrinting: 'Specialty Printing (Optional)',
    specialtyTab: 'Specialty Tab (Optional)',
    ballItemNumberOrGraphicNumber: 'Ball Item Number/Graphic Number',
    ballItemNumberOrGraphicNumberOptional: 'Ball Item Number/Graphic Number (Optional)',
    labelName: 'Label Name',
    labelNameOptional: 'Label Name (Optional)',
    viewPalletSpecs: 'See pallet specifications on ',
    specialEquipmentTooltip: 'Roller bed truck, Pallet jack, etc.',
    specialEquipmentOptional: 'Special Equipment (Optional)',
    layersPerPallet: 'Layers per Pallet',
    canFreightManagement: 'Can Freight Management',
    endFreight: 'End Freight',
    canSpecifications: 'Can Specifications',
    blanket: 'Blanket',
    cashInAdvance: 'Cash-in-Advance (CIA)',
    estimatedAnnualVolume: 'Estimated Annual Volume',
    ballManaged: 'Ball Managed',
    customerNumber: 'Customer Number',
    specialtyPrintingReview: 'Specialty Printing',
    specialtyTabs: 'Specialty Tabs',
    specialtyEquipment: 'Specialty Equipment',
    requestNewShipTo: 'Request New Ship To',
    reviewAndSubmit: 'Review And Submit',
    shipToRequestSubmitted:
        'Your request to add a new Ship To location has been submitted. You will receive a notification once the request is reviewed.',
    shipToRequestSave: 'Your form is also available to view. Please save this for your records.',

    // -- FEEDBACK SURVEY -- //
    feedbackHeader: 'Nous aimerions connaître votre rétroaction!',
    satisfaction: 'Dans l’ensemble, dans quelle mesure êtes-vous satisfait de The Source?',
    enterScore: 'Veuillez saisir un score',
    visitPurpose: 'Quel était le but principal de votre visite aujourd’hui?',
    selectOption: 'Veuillez sélectionner une option',
    'Learn about working with Ball': 'Apprendre à travailler avec Ball',
    'Get Design Inspiration': 'Trouver une inspiration pour la conception',
    'Investigate Lab and Liquid': 'Enquêter sur le laboratoire et le liquide',
    'Explore Sustainability & Regulatory': 'Explorer la durabilité et la réglementation',
    'Learn about Handling & Filling': 'En savoir plus sur la manipulation et le remplissage',
    'Access Can Specifications': 'Accéder aux spécifications des canettes',
    'Obtain Pricing Information': 'Obtenir des informations sur les prix',
    Other: 'Autre',
    pleaseSpecify: 'Veuillez préciser :',
    suggestionLabel:
        'Y a-t-il autre chose que nous puissions faire pour améliorer votre visite de The Source?',
    contactPreferenceLabel:
        'Si nous avons besoin de précisions sur l’une de vos réponses, pouvons-nous vous contacter?',
    'Yes, I prefer a phone call': 'Oui, je préfère un appel téléphonique',
    'Yes, I prefer email': 'Oui, je préfère l’e-mail',
    'No, please do not contact me': 'Non, s’il vous plaît ne me contactez pas',
    selectContactPreference: 'Veuillez sélectionner une préférence de contact',
    nameLabel: 'Comment tu t’appelles',
    phoneLabel: 'Quel est ton numéro de téléphone?',
    emailLabel: 'Courriel',
    emailError: 'Veuillez entrer un email valide',
    closeButton: 'Fermer',
    submitButton: 'Soumettre',
    feedbackThankYou: 'Merci pour votre rétroaction',
    feedbackPrivacyPolicy: 'politique de confidentialité',
    privacyPolicyMessage: 'Vos réponses seront utilisées conformément à notre'
};
