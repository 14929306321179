import React from 'react';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { Order_Status } from '../../../../utility/services/orders-service';

interface Props {
    onEditOrder: () => void;
    onViewOrder: () => void;
    onMenuBlur: () => void;
    anchorEl: HTMLElement | null;
    orderStatus: string;
}

const useStyles = makeStyles(() => ({
    menuWrapper: {
        '& .MuiPopover-paper': {
            marginTop: '3.2em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            marginRight: '2em',
            minWidth: '15em',
            borderTop: '1em'
        }
    }
}));

const editableStatuses = [Order_Status.Confirmed, Order_Status.Draft, Order_Status.Open];

const SummaryContextMenu = ({
    onEditOrder,
    onViewOrder,
    onMenuBlur,
    anchorEl,
    orderStatus
}: Props) => {
    const classes = useStyles();

    const editDisabled = () => {
        if (orderStatus) {
            const status = orderStatus as Order_Status;

            return !editableStatuses.includes(status) ? true : false;
        } else {
            return true;
        }
    };

    return (
        <Menu
            className={classes.menuWrapper}
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: -110,
                vertical: 'top'
            }}
            onBlur={onMenuBlur}
        >
            <MenuItem onClick={onViewOrder}>
                <Trans i18nKey="viewOrder">View Order</Trans>
            </MenuItem>
            <MenuItem onClick={onEditOrder} disabled={editDisabled()}>
                <Trans i18nKey="editOrder">Edit Order</Trans>
            </MenuItem>
        </Menu>
    );
};

export default SummaryContextMenu;
