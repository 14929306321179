import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Logo from '../atoms/Logo';
import DiscoverBallNav from './DiscoverBallNav';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import { AuthState } from '../../../store/reducers/auth';

const MainNavBar = () => {
    useTranslation();

    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const baseContentLink = `${process.env.PUBLIC_URL}/content/${regionCode}/Pages/`;
    const hideKBMaxFunctionality = regionCode !== Region.NA;

    return (
        <>
            <Grid container justify="flex-start" xs={3}>
                <Grid item>
                    <Logo />
                </Grid>

                {/* DISCOVER BALL/GLP */}
                {userInfo && regionCode !== Region.EU && (
                    <DiscoverBallNav
                        baseContentLink={baseContentLink}
                        hideKBMaxFunctionality={hideKBMaxFunctionality}
                    />
                )}
            </Grid>
        </>
    );
};

export default MainNavBar;
