import { regions } from '../../static-data/dropdown-lists';
import UserService from '../../utility/services/user-service';
import { AuthState } from '../reducers/auth';
import { DashboardElement, DashboardElementId } from '../reducers/user-configuration';
import * as types from './action-types';

/**
 * Fetches dashboard positions from user configuration.  Used on page load only.
 * @returns
 */
export const getUserConfiguration = () => {
    return (dispatch, getState) => {
        dispatch({ type: types.USER_CONFIGURATION_LOADING });
        const { auth } = getState();
        if (auth.accessToken) {
            UserService.getUserConfiguration(auth.accessToken)
                .then((data) => {
                    const dashboardPositions: Array<DashboardElement> =
                        data?.dashboardPositions?.map((position) => ({
                            name: position.name,
                            columnPosition: position.columnPosition
                        })) ?? [];

                    const correctRegionCode = regions.find(
                        (region) => region.salesforceValue === data?.regionCode
                    )?.value;

                    dispatch({
                        type: types.USER_CONFIGURATION_LOADED,
                        dashboardPositions,
                        dateFormatCode: data?.dateFormat,
                        regionCode: correctRegionCode,
                        cultureCode: data?.cultureCode
                    });
                })
                .catch((error) => {
                    dispatch({ type: types.USER_CONFIGURATION_ERROR, error });
                });
        }
    };
};

/**
 * @param positions Array of string identifiers for dashboard widgets.  The order will be ascending.
 * @returns
 */
export const updateDashboardPositions = (positionOrder: Array<DashboardElementId>) => {
    return (dispatch, getState) => {
        dispatch({ type: types.USER_CONFIGURATION_LOADING });
        const { auth } = getState();
        const dashboardPositions = positionOrder.reduce((prev, curr, i) => {
            prev.push({ name: curr, columnPosition: i, rowPosition: 0, elementCode: '' });
            return prev;
        }, [] as Array<DashboardElement>);

        if (auth.accessToken) {
            UserService.updateUserConfiguration(auth.accessToken, auth.userInfo.email, {
                dashboardPositions
            })
                .then(() => {
                    dispatch({
                        type: types.USER_CONFIGURATION_UPDATE,
                        dashboardPositions
                    });
                })
                .catch((error) => {
                    dispatch({ type: types.USER_CONFIGURATION_ERROR, error });
                });
        }
    };
};

/**
 * USER CONFIGURATION UPDATE
 * @param dateFormat new dateFormat user has defined
 * @returns
 */
export const saveUserConfiguration = (configuration: {
    regionCode?: string | undefined;
    cultureCode?: string | undefined;
    dateFormatCode?: string | undefined;
}) => {
    return (dispatch, getState) => {
        dispatch({ type: types.USER_CONFIGURATION_LOADING });
        const auth = getState().auth as AuthState;
        const email = auth?.userInfo?.email;
        let mapRegionCode;
        if (configuration.regionCode) {
            mapRegionCode =
                regions.find((region) => region.value === configuration.regionCode)
                    ?.salesforceValue ?? configuration.regionCode;
        }
        if (email) {
            UserService.updateUserConfiguration(auth.accessToken, email, {
                ...configuration,
                regionCode: mapRegionCode,
                dateFormat: configuration.dateFormatCode
            })
                .then(() => {
                    dispatch({
                        type: types.USER_CONFIGURATION_UPDATE,
                        ...configuration,
                        regionCode: mapRegionCode,
                        dateFormat: configuration.dateFormatCode
                    });
                })
                .catch((error) => {
                    dispatch({ type: types.USER_CONFIGURATION_ERROR, error });
                });
        }
    };
};
