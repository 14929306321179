import Axios from 'axios';
export interface DashboardRequest {
    shipToId: string;
    soldToId?: string;
    paymentTerms?: string;
}
export default class OrderingService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getOrderManagementDashboardInformation = (
        accessToken: string,
        request: DashboardRequest
    ) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/dashboard/order-management`;
        let urlContent = `?PaymentTerms=${request.paymentTerms}`;
        urlContent += request.shipToId && `&ShipToId=${request.shipToId}`;
        const requestUrl = baseUrl + urlContent;
        return Axios.get(requestUrl, OrderingService.createConfig(accessToken));
    };

    static getProductPortfolioDashboardInformation = (
        accessToken: string,
        request: DashboardRequest
    ) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/dashboard/product-portfolio`;
        let urlContent = `?SoldToId=${request.soldToId}`;
        urlContent += request.shipToId && `&ShipToId=${request.shipToId}`;
        const requestUrl = baseUrl + urlContent;
        return Axios.get(requestUrl, OrderingService.createConfig(accessToken));
    };

    static getShipmentsDeliveriesDashboardInformation = (
        accessToken: string,
        request: DashboardRequest
    ) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/dashboard/shipments-deliveries`;
        let urlContent = `?ShipToId=${request.shipToId}`;
        const requestUrl = baseUrl + urlContent;
        return Axios.get(requestUrl, OrderingService.createConfig(accessToken));
    };
}
