import React, { ReactNode } from 'react';
import { Grid, makeStyles, Link } from '@material-ui/core';
import {
    ballBlue,
    ballGray,
    blackWeight,
    lato,
    ltBlueGrey,
    statusTrackerGray
} from '../../../../../themes/globalConstants';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    title?: ReactNode;
    skuCount: number;
    secondItemCount: number;
    secondItemUnit: string;
    isLink?: boolean;
    route?: string;
}

const useStyles = makeStyles(() => ({
    widgetMetricRow: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${ltBlueGrey}`,
        padding: `.75em 0`
    },
    widgetMetricRowTitle: {
        fontFamily: lato,
        fontWeight: blackWeight,
        fontSize: '.875em',
        color: ballBlue
    },
    widgetMetricRowSeparator: {
        fontFamily: lato,
        fontWeight: 600,
        fontSize: '1em',
        color: statusTrackerGray,
        margin: '0 1em'
    },
    widgetMetricRowValue: {
        fontFamily: lato,
        fontWeight: blackWeight,
        fontSize: '.875em',
        color: ballGray
    }
}));

export default function DashboardWidgetMetricRow({
    title,
    skuCount,
    secondItemCount,
    secondItemUnit,
    isLink = false,
    route
}: Props) {
    const classes = useStyles();
    return (
        <Grid item className={classes.widgetMetricRow}>
            {isLink && route ? (
                <Link
                    to={route}
                    underline="none"
                    component={RouterLink}
                    data-testid="link"
                    className={classes.widgetMetricRowTitle}
                >
                    {title}
                </Link>
            ) : (
                <span className={classes.widgetMetricRowTitle}>{title}</span>
            )}

            <div>
                <span className={classes.widgetMetricRowValue}>
                    {skuCount.toLocaleString()} {skuCount === 1 ? 'SKU' : 'SKUs'}
                </span>
                <span className={classes.widgetMetricRowSeparator}>/</span>
                <span className={classes.widgetMetricRowValue}>
                    {secondItemCount.toLocaleString()} {secondItemUnit}
                </span>
            </div>
        </Grid>
    );
}
