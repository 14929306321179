import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ProcessingPageTemplate } from '../../../templates/ProcessingPageTemplate';
import { Activity, hasPersonas, Persona } from '../../../../utility/auth/useSecurity';
import { CustomerContextState, PaymentTerms } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { black } from '../../../../themes/globalConstants';
import { AuthState } from '../../../../store/reducers/auth';
import GraphicsWidget from './components/GraphicsWidget';
import UserAdminWidget from './components/UserAdminWidget';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { Region } from '../../../../store/reducers/graphic-intake';
import {
    selectHasOrderingPermissions,
    selectIsCIACustomer,
    selectIsLargeCustomerAccount
} from '../../../../store/selectors';
import {
    hasMakeItPersonas,
    hasGraphicsPersonas,
    hasShipItPersonas
} from '../../../../utility/helpers/dashboard-helpers';
import { usePrevious } from '../../../../utility/helpers/react-util';
import CustomerDashboardDragAndDrop from './components/CustomerDashboardDragAndDrop';
import { CustomerDashboardState } from '../../../../store/reducers/customer-dashboard';
import {
    loadDashboardInformation,
    loadCachedDashboard
} from '../../../../store/actions/customer-dashboard';
import { AccountNotification, NotificationsState } from '../../../../store/reducers/notifications';
import { dismissNotification, loadNotificationData } from '../../../../store/actions/notifications';
import DashboardBannerNotification from './components/DashboardBannerNotification';
import PlanItWidget from './components/PlanItWidget';
import { CUSTOMER_DASHBOARD_RESET_STATUS } from '../../../../store/actions/action-types';
import { isShipToAccount } from '../../../../utility/helpers/admin-helpers';
import CanResearchSection from '../../Home/components/CanResearchSection';
import { GlpState } from '../../../../store/reducers/glp';
import { loadGlobalLandingPageContent } from '../../../../store/root-actions';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0',
        marginTop: '1em',
        marginBottom: '1em',
        flexWrap: 'nowrap'
    },
    side: {
        paddingRight: '0 !important'
    },
    listIcon: {
        color: black,
        '& .MuiListItemIcon-root': {
            color: black
        }
    },
    draggable: {
        paddingBottom: '1em'
    },
    dragDropContainer: {
        marginBottom: '-1em'
    }
}));

export default function CustomerDashboard() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { accounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { status, cachedDashboard } = useTypedSelector<CustomerDashboardState>(
        (state) => state.customerDashboard
    );

    const { notifications, dismissedNotifications, notificationDataLoaded } =
        useTypedSelector<NotificationsState>((state) => state.notifications);

    const hasOrderingPermissions = useTypedSelector<boolean>(selectHasOrderingPermissions);
    const isCiaCustomer = useTypedSelector(selectIsCIACustomer);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const prevSelectedAccountId = usePrevious(selectedAccountId);

    const [showShipItWidget, setShowShipItWidget] = useState<boolean>(false);
    const [showGraphicsWidget, setShowGraphicsWidget] = useState<boolean>(false);
    const [showAdminWidget, setShowAdminWidget] = useState<boolean>(false);
    const [showMakeItWidget, setShowMakeItWidget] = useState<boolean>(false);
    const [showPlanItWidget, setShowPlanItWidget] = useState<boolean>(false);
    const [showFallbackWidget, setShowFallbackWidget] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [bannerNotifications, setBannerNotifications] = useState<AccountNotification[]>([]);
    const glpState = useTypedSelector<GlpState>((state) => state.glp);
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        if (
            regionCulture.cultureCode !== glpState.loadedLanguage ||
            regionCulture.regionCode !== glpState.loadedRegion
        ) {
            dispatch(
                loadGlobalLandingPageContent(regionCulture.regionCode, regionCulture.cultureCode)
            );
        }
    }, [
        dispatch,
        glpState.loadedLanguage,
        glpState.loadedRegion,
        regionCulture.cultureCode,
        regionCulture.regionCode
    ]);

    useEffect((): void => {
        const setWidgetDisplay = () => {
            let totalWidgets = 0;
            if (accounts && selectedAccountId) {
                const currentAccount = accounts.find(
                    (account) => account.accountId === selectedAccountId
                );
                if (currentAccount && isShipToAccount(currentAccount)) {
                    // Show Ship It Widget for non-ATM customers
                    const showShipIt =
                        regionCode === Region.NA && hasShipItPersonas(auth, selectedAccountId);
                    setShowShipItWidget(showShipIt);
                    totalWidgets = totalWidgets + (showShipIt ? 1 : 0);

                    // Show Make It Widget for non-ATM customers
                    const showMakeIt =
                        regionCode === Region.NA && hasMakeItPersonas(auth, selectedAccountId);
                    setShowMakeItWidget(showMakeIt);
                    totalWidgets = totalWidgets + (showMakeIt ? 1 : 0);

                    // Show Plan It Widget for non-ATM customers
                    const showPlanIt = regionCode === Region.NA && hasOrderingPermissions;
                    setShowPlanItWidget(showPlanIt);
                    totalWidgets = totalWidgets + (showPlanIt ? 1 : 0);
                } else {
                    setShowShipItWidget(false);
                    setShowMakeItWidget(false);
                    setShowPlanItWidget(false);
                }

                // Show the Graphics Widget for users with Graphics Personas
                const showGraphics = hasGraphicsPersonas(auth);
                setShowGraphicsWidget(showGraphics);
                totalWidgets = totalWidgets + (showGraphics ? 1 : 0);

                // Show the Admin Widget for users with Account Admin Persona
                const showAdmin = hasPersonas([Persona.AccountAdmin], auth);
                setShowAdminWidget(showAdmin);
                totalWidgets = totalWidgets + (showAdmin ? 1 : 0);

                // Show the Fallback Widget if no other widgets are shown
                if (totalWidgets === 0) {
                    setShowFallbackWidget(true);
                } else {
                    setShowFallbackWidget(false);
                }
            } else {
                setShowShipItWidget(false);
                setShowMakeItWidget(false);
                setShowPlanItWidget(false);
                setShowGraphicsWidget(false);
                setShowAdminWidget(false);
                setShowFallbackWidget(true);
            }
        };
        if (permissions?.length && accounts?.length) {
            setWidgetDisplay();
        }
    }, [
        dispatch,
        accounts,
        isLargeCustomer,
        auth,
        regionCode,
        permissions,
        selectedAccountId,
        hasOrderingPermissions
    ]);

    useEffect(() => {
        if (!notificationDataLoaded && accounts && permissions && accounts.length > 0) {
            dispatch(loadNotificationData(accounts));
        }
    }, [dispatch, notificationDataLoaded, accounts, permissions]);

    useEffect(() => {
        if (notifications && selectedAccountId && notifications[selectedAccountId]?.length > 0) {
            const filteredNotifications = notifications[selectedAccountId]
                .filter((notification) => {
                    return dismissedNotifications.indexOf(notification.notificationId) === -1;
                })
                .filter((notification) => {
                    return moment(notification.expirationDate)
                        .startOf('day')
                        .isAfter(moment().startOf('day'));
                })
                //TODO: WHEN API FILTERING IS COMPLETE (CP-6006), REMOVE THIS FILTER
                .filter(
                    (notification) => !(isLargeCustomer && notification.type === 'Allocations')
                );
            setBannerNotifications(filteredNotifications);
        } else {
            setBannerNotifications([]);
        }
    }, [notifications, selectedAccountId, dismissedNotifications, isLargeCustomer]);

    useEffect(() => {
        if (status !== 'loading') {
            setLoading(false);
        } else if (status === 'loading' && regionCode === Region.NA && !isLargeCustomer) {
            setLoading(true);
        }
    }, [status, regionCode, isLargeCustomer]);

    useEffect((): void => {
        if (status === 'idle' && selectedAccountId && accounts && accounts.length > 0) {
            const currentAccount = accounts.find(
                (account) => account.accountId === selectedAccountId
            );
            if (currentAccount && isShipToAccount(currentAccount)) {
                const cacheExists = cachedDashboard.find((cd) => {
                    const isAfter = moment(cd.dateLimit).isAfter(moment());
                    return cd.shipToId === selectedAccountId && isAfter;
                });
                if (cacheExists) {
                    dispatch(loadCachedDashboard(selectedAccountId));
                } else {
                    const paymentTerms =
                        accounts.find((account) => account.accountId === selectedAccountId)
                            ?.paymentTerms || PaymentTerms.Unknown;
                    dispatch(loadDashboardInformation(selectedAccountId, paymentTerms));
                }
            }
        }
    }, [dispatch, accounts, selectedAccountId, status, cachedDashboard]);

    useEffect(() => {
        if (prevSelectedAccountId && selectedAccountId !== prevSelectedAccountId) {
            dispatch({ type: CUSTOMER_DASHBOARD_RESET_STATUS, prevSelectedAccountId });
        }
    }, [prevSelectedAccountId, selectedAccountId, dispatch]);

    const handleNotificationDismiss = (notificationId) => {
        if (notificationId && selectedAccountId) {
            dispatch(dismissNotification(notificationId, selectedAccountId));
        }
    };

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('myDashboard', 'My Dashboard'),
                description: '',
                thinBanner: true,
                displayDropdown: true,
                completeDropdown: true
            }}
            activity={Activity.Dashboard}
            shipTos={false}
            loading={loading}
        >
            <Grid
                container
                spacing={2}
                className={classes.dashboard}
                data-testid="dashboard"
                direction="column"
            >
                <Grid item xs={12}>
                    {bannerNotifications &&
                        bannerNotifications.map((item, index) => (
                            <DashboardBannerNotification
                                notification={item}
                                key={item.notificationId}
                                warningMessage={item.type}
                                dismiss={handleNotificationDismiss}
                            />
                        ))}
                </Grid>
                <Grid item xs={12} className={classes.dragDropContainer}>
                    <CustomerDashboardDragAndDrop
                        showMakeItWidget={showMakeItWidget}
                        showShipItWidget={showShipItWidget}
                        isCiaCustomer={isCiaCustomer}
                        isLargeCustomer={isLargeCustomer}
                    />
                </Grid>
                {showPlanItWidget && (
                    <Grid item xs={12}>
                        <PlanItWidget />
                    </Grid>
                )}
                {showGraphicsWidget && (
                    <Grid item xs={12}>
                        <GraphicsWidget permissions={permissions} />
                    </Grid>
                )}
                {showAdminWidget && (
                    <Grid item xs={12}>
                        <UserAdminWidget />
                    </Grid>
                )}
                {showFallbackWidget && (
                    <Grid container item xs={12}>
                        {glpState.canContent ? (
                            <CanResearchSection {...glpState.canContent} />
                        ) : null}
                    </Grid>
                )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
