import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
    Button,
    CircularProgress,
    Grid,
    Link,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core';

import {
    ballDrkBlue,
    ballGray,
    blackWeight,
    boldWeight,
    large,
    ltBlueGrey_7,
    white,
    xxxl
} from '../../../../../themes/globalConstants';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import DashboardWidgetMetricRow from './DashboardWidgetMetricRow';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { selectIsLargeCustomerAccount } from '../../../../../store/selectors';
import { CustomerOrderingDashboardState } from '../../../../../store/reducers/customer-ordering-dashboard';
import { RegionCultureState } from '../../../../../store/reducers/region-culture';
import { Region } from '../../../../../store/reducers/graphic-intake';
import { DragDropProps } from '../utilities/dashboard-positions.config';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em',
        position: 'relative'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    moreIcon: {
        width: '1.25em',
        height: '1.25em',
        marginLeft: '.5em'
    },
    widgetSectionHeader: {
        fontWeight: blackWeight,
        color: ballGray,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: '1.75em'
    },
    widgetNumberSlant: {
        flexGrow: 1,
        maxWidth: '6em',
        marginLeft: '14em',
        justifyContent: 'center',
        display: 'flex',
        '& > div': {
            width: '1em',
            height: '100%',
            color: ltBlueGrey_7,
            background: `linear-gradient(to top left,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            ${ltBlueGrey_7} 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%)`
        }
    },
    widgetNumberNoSlant: {
        flexGrow: 1,
        maxWidth: '6em',
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '2 rem'
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight
    },
    agedBalanceDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    widgetNumber: {
        fontSize: xxxl,
        fontWeight: 500,
        lineHeight: 1,
        color: ballDrkBlue,
        marginBottom: '.75rem',
        textDecoration: 'none',
        position: 'relative',
        borderBottom: `1px solid ${white}`,

        '&:hover': {
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    shipmentsSummaryDetailsContainer: {
        height: 100,
        marginTop: -7
    },
    shipmentsSummaryDetails: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    container: {
        '& > div': {
            position: 'relative'
        }
    }
}));

export default function ProductPortfolio({ dragHandleProps }: DragDropProps) {
    const history = useHistory();
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const largeCustomerLink = () => {
        return isLargeCustomer ? 'po-' : '';
    };
    const classes = useStyles();
    const { dashboardData, productPortfolioStatus } =
        useTypedSelector<CustomerOrderingDashboardState>(
            (state) => state.customerOrderingDashboard
        );

    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [startNewGraphicLink, setStartNewGraphicLink] =
        React.useState<string>('/graphics-intake');
    useEffect(() => {
        if (regionCode === Region.EU) {
            setStartNewGraphicLink('/emea-graphics-intake');
        } else {
            setStartNewGraphicLink('/graphics-intake');
        }
    }, [regionCode]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="product-portfolio-widget">
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="order-management-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    My Product PortFolio
                </Typography>
                <div style={{ display: 'flex', marginLeft: '25%' }}>
                    <div>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            data-testid="upload-new-graphic"
                            onClick={() => history.push(startNewGraphicLink)}
                        >
                            UPLOAD NEW GRAPHIC
                        </Button>
                    </div>
                    <div style={{ marginLeft: '16em' }}>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            data-testid="submit-new-test"
                            onClick={() => history.push('/liquid-test-intake')}
                        >
                            SUBMIT NEW TEST
                        </Button>
                    </div>
                </div>
            </div>
            <div></div>
            {productPortfolioStatus === 'loading' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={1}>
                    <Grid container item xs={4}>
                        <DashboardWidgetMetricSection
                            title={'Active Items'}
                            isShipmentDelivery={true}
                            left={
                                <div>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.shipmentsSummaryDetailsContainer}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.shipmentsSummaryDetails}
                                        >
                                            <div>
                                                <Link
                                                    to={`/${largeCustomerLink()}product-planning?expand=1`}
                                                    underline="none"
                                                    component={RouterLink}
                                                    data-testid="product-planning-link"
                                                    className={classes.link}
                                                >
                                                    <Typography className={classes.widgetNumber}>
                                                        {
                                                            dashboardData?.productPortfolioDashboard
                                                                ?.activeItems
                                                        }
                                                    </Typography>
                                                </Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.widgetSectionHeader}>
                                Graphics
                            </Typography>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={8} container direction="column">
                                <DashboardWidgetMetricRow
                                    title={'IN PROGRESS'}
                                    showSecondary={false}
                                    secondItemCount={
                                        dashboardData?.productPortfolioDashboard?.graphics
                                            .inProgress
                                            ? dashboardData?.productPortfolioDashboard.graphics
                                                  .inProgress
                                            : 0
                                    }
                                    secondItemUnit={''}
                                    isLink={true}
                                    route={`/graphics-summary?status=InProgress`}
                                />
                                <DashboardWidgetMetricRow
                                    title={'DRAFTS'}
                                    showSecondary={false}
                                    secondItemCount={
                                        dashboardData?.productPortfolioDashboard?.graphics.drafts
                                            ? dashboardData?.productPortfolioDashboard.graphics
                                                  .drafts
                                            : 0
                                    }
                                    secondItemUnit={''}
                                    isLink={true}
                                    route={`/graphics-summary?status=Draft`}
                                />
                                <DashboardWidgetMetricRow
                                    title={'RECENTLY COMPLETED'}
                                    showSecondary={false}
                                    secondItemCount={
                                        dashboardData?.productPortfolioDashboard?.graphics
                                            ?.recentlyCompleted
                                            ? dashboardData?.productPortfolioDashboard.graphics
                                                  ?.recentlyCompleted
                                            : 0
                                    }
                                    secondItemUnit={''}
                                    isLink={true}
                                    route={`/graphics-summary?status=Completed`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={10}>
                            <Typography variant="h5" className={classes.widgetSectionHeader}>
                                LAB AND LIQUID TESTING
                            </Typography>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={12} container direction="column">
                                <DashboardWidgetMetricRow
                                    title={'IN PROGRESS'}
                                    showSecondary={false}
                                    secondItemCount={
                                        dashboardData?.productPortfolioDashboard?.liquidTests
                                            ?.inProgress
                                            ? dashboardData?.productPortfolioDashboard.liquidTests
                                                  ?.inProgress
                                            : 0
                                    }
                                    secondItemUnit={''}
                                    isLink={true}
                                    route={`/liquid-test-summary?status=InProgress`}
                                />
                                <DashboardWidgetMetricRow
                                    title={'RECENTLY COMPLETED'}
                                    showSecondary={false}
                                    secondItemCount={
                                        dashboardData?.productPortfolioDashboard?.liquidTests
                                            ?.recentlyCompleted
                                            ? dashboardData?.productPortfolioDashboard.liquidTests
                                                  ?.recentlyCompleted
                                            : 0
                                    }
                                    secondItemUnit={''}
                                    isLink={true}
                                    route={`/liquid-test-summary?status=Draft`}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
