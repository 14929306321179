import React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DeliveryShipment } from '../../../../store/reducers/ordering-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { selectDateFormat, selectDateTimeFormat } from '../../../../store/selectors';
import {
    ballBlue,
    boldWeight,
    large,
    medium,
    small,
    yellowPill
} from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    headerLargeText: {
        color: ballBlue,
        fontWeight: boldWeight,
        fontSize: large,
        textTransform: 'uppercase'
    },
    headerNormalText: {
        color: ballBlue,
        fontWeight: boldWeight,
        fontSize: medium
    },
    isEditingBanner: {
        backgroundColor: yellowPill,
        borderRadius: '10px',
        height: '15px',
        fontSize: small,
        fontWeight: boldWeight,
        padding: '5px'
    }
}));

interface ShipmentTitleProps {
    shipment: DeliveryShipment;
    shipmentIndex: number;
    isEditShipment: boolean;
    orderNumber?: string;
}

export default function ShipmentTitle({
    shipment,
    shipmentIndex,
    isEditShipment,
    orderNumber
}: ShipmentTitleProps) {
    const classes = useStyles();

    const dateFormat = useTypedSelector(selectDateFormat);
    const dateTimeFormat = useTypedSelector(selectDateTimeFormat);

    const deliveryDate = moment(shipment.deliveryDateTime).format(dateFormat);
    const deliveryDateTime = moment(shipment.deliveryDateTime).format(dateTimeFormat);
    const shipmentIdentification = shipment.truckId || shipmentIndex + 1;

    return (
        <Grid container justify="space-between">
            <Grid item>
                <Typography className={classes.headerLargeText}>
                    <Trans i18nKey="shipment">Shipment</Trans> {shipmentIdentification}
                </Typography>
            </Grid>
            {isEditShipment ? (
                <>
                    <Grid item>
                        <Typography className={classes.headerLargeText}>
                            <Trans i18nKey="buildingDeliveryOnDate">Building for Delivery on</Trans>{' '}
                            {deliveryDate}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.isEditingBanner}>
                            <Trans i18nKey="openedForEditing">Opened for Editing</Trans>
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <Typography className={classes.headerLargeText}>
                            <Trans i18nKey="order">Order</Trans>
                            {' #'}
                            {orderNumber}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.headerNormalText}>
                            Delivery Date: {deliveryDateTime}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
