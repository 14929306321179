import { useState, useEffect, useMemo } from 'react';
import { useTypedSelector } from '../../store/reducers/reducer';
import { AuthState, UserPermission } from '../../store/reducers/auth';
import { Account, AddressType, CustomerContextState } from '../../store/reducers/customer-context';

export enum ValidationMethod {
    byPersona,
    byAccess
}

export enum Activity {
    AddShipTo,
    BillingAndShipping,
    ClosedDeliveryOrders,
    ClosedProductionOrders,
    ContractSummary,
    CreateNewUser,
    CreditApplication,
    Dashboard,
    DeactivateReactivateUsers,
    DraftDeliveryOrders,
    DraftGraphicsIntakeForm,
    DraftProductionOrders,
    ForecastProductInformation,
    GraphicsIntakeForm,
    GraphicsProof,
    GraphicsQuote,
    GraphicsStandard,
    GraphicsSummary,
    InProgressGraphicsStatuses,
    LiquidTestIntakeForm,
    LiquidTestIntakeSummary,
    MakeItATMSummary,
    MakeItBulkATM,
    MakeItBulkUpload,
    MakeIt,
    ManageLocations,
    ManageProducts,
    ModifyPermissions,
    NewOpenDeliveryOrders,
    NewOpenProductionOrders,
    OnboardingWizard,
    OpenAccess,
    OrderPaymentFinancials,
    PlanningPage,
    PlanningPageATM,
    PlanItBulkUpload,
    PlanItOrderDetails,
    PlanItSummary,
    PricingBreakdown,
    PricingContractAcknowledge,
    RecentlyCompletedGraphics,
    RequestAccess,
    SalesAndUseTax,
    ShipItBulkUpload,
    TermsAndConditions,
    TradeIt,
    TradeItSummary,
    TradeItDetails,
    ScrapIt,
    ScrapItDetails,
    Troubleshooting,
    UserAdminAddUser,
    UserAdminDashboard,
    UserAdminEditUser,
    UserAdminReviewRequest,
    CommunicationManagement,
    BulkUploadOrders,
    CreateOrder,
    Inventory,
    ReviewOrders,
    ReviewShipments,
    CreateShipments
}

export enum Persona {
    AccountAdmin = 'ACC_ADM',
    AllAccess = 'ALL_ACC',
    ArAp = 'AR_AP',
    ArtMgmt = 'ART_MGT',
    CoPacker = 'CO_PACK',
    ForecastMgmt = 'FORE_MGT',
    GraphicsVendor = 'GRF_VEN',
    Impersonation = 'IMPER',
    JoiningAdmin = 'JOIN_ADM',
    JoiningBillingAndShippingAddresses = 'JOIN_BILL',
    JoiningCreditApplication = 'JOIN_CRDT',
    JoiningSalesAndUseTax = 'JOIN_SUTS',
    JoiningTermsAndConditions = 'JOIN_TCS',
    MakeItOnly = 'MAKE_ONLY',
    MarketingMgmt = 'MKT_MGR',
    OrderFulfillment = 'ORD_FULL',
    PackagingMgmt = 'PKG_MGT',
    PlanItOnly = 'PLAN_ONLY',
    PricingContact = 'PRC_CON',
    ProcurementMgmt = 'PROC_MGT',
    ShipItOnly = 'SHIP_ONLY',
    WarehousingLogistics = 'WARE_LOG',
    CommunicationManagement = 'COMM_MGT'
}

export enum SecurityLevel {
    None = 0,
    View = 1,
    Edit = 2
}

export interface SecurityContext {
    accountId: string;
}

// Evaluate operation level security
export const getSecurityLevel = (
    operation: Activity,
    auth: AuthState,
    context: SecurityContext,
    validationMethod?: ValidationMethod
): SecurityLevel => {
    switch (operation) {
        case Activity.GraphicsIntakeForm:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.MarketingMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ArtMgmt,
                    Persona.GraphicsVendor
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;

        case Activity.OpenAccess:
            return SecurityLevel.View;

        case Activity.DraftGraphicsIntakeForm:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.MarketingMgmt,
                    Persona.ArtMgmt,
                    Persona.ProcurementMgmt,
                    Persona.GraphicsVendor
                ],
                auth
            )
                ? SecurityLevel.Edit
                : hasPersonas(
                      [Persona.PackagingMgmt, Persona.ForecastMgmt, Persona.OrderFulfillment],
                      auth
                  )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.InProgressGraphicsStatuses:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.MarketingMgmt,
                    Persona.PackagingMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ArtMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.GraphicsQuote:
            return hasAccess(
                [Persona.AllAccess, Persona.ProcurementMgmt, Persona.ArtMgmt],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : hasAccess([Persona.MarketingMgmt], auth, context)
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.GraphicsProof:
            return hasAccess([Persona.AllAccess, Persona.ArtMgmt], auth, context)
                ? SecurityLevel.Edit
                : SecurityLevel.None;

        case Activity.GraphicsStandard:
            return hasAccess([Persona.AllAccess, Persona.ArtMgmt], auth, context)
                ? SecurityLevel.Edit
                : SecurityLevel.None;

        case Activity.RecentlyCompletedGraphics:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.MarketingMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ArtMgmt,
                    Persona.OrderFulfillment,
                    Persona.ForecastMgmt,
                    Persona.CoPacker,
                    Persona.WarehousingLogistics,
                    Persona.GraphicsVendor
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.GraphicsSummary:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.ProcurementMgmt,
                    Persona.ForecastMgmt,
                    Persona.PackagingMgmt,
                    Persona.CoPacker,
                    Persona.WarehousingLogistics,
                    Persona.MarketingMgmt,
                    Persona.GraphicsVendor,
                    Persona.ArtMgmt
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.MakeIt:
        case Activity.NewOpenProductionOrders:
            return hasAccess(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : hasAccess(
                      [
                          Persona.PackagingMgmt,
                          Persona.ForecastMgmt,
                          Persona.WarehousingLogistics,
                          Persona.CoPacker,
                          Persona.ArAp
                      ],
                      auth,
                      context
                  )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.MakeItATMSummary:
        case Activity.MakeItBulkATM:
            return hasAccess(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : hasAccess(
                      [
                          Persona.PackagingMgmt,
                          Persona.ForecastMgmt,
                          Persona.WarehousingLogistics,
                          Persona.CoPacker,
                          Persona.ArAp
                      ],
                      auth,
                      context
                  )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.DraftProductionOrders:
            return hasAccess(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : hasAccess([Persona.ArAp], auth, context)
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.ClosedProductionOrders:
            return hasAccess(
                [
                    Persona.AllAccess,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.NewOpenDeliveryOrders:
            return hasAccess(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.CoPacker, Persona.ShipItOnly],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : hasAccess(
                      [
                          Persona.PackagingMgmt,
                          Persona.ForecastMgmt,
                          Persona.WarehousingLogistics,
                          Persona.ArAp
                      ],
                      auth,
                      context
                  )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.DraftDeliveryOrders:
            return hasAccess(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.CoPacker,
                    Persona.ArAp,
                    Persona.ShipItOnly
                ],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.ClosedDeliveryOrders:
            return hasAccess(
                [
                    Persona.AllAccess,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp,
                    Persona.ShipItOnly
                ],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.Dashboard:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.MarketingMgmt,
                    Persona.ArtMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ArAp,
                    Persona.AccountAdmin,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PricingContact,
                    Persona.PlanItOnly
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.OnboardingWizard:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.MarketingMgmt,
                    Persona.ArtMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ArAp,
                    Persona.JoiningAdmin,
                    Persona.JoiningTermsAndConditions,
                    Persona.JoiningSalesAndUseTax,
                    Persona.JoiningBillingAndShippingAddresses,
                    Persona.JoiningCreditApplication
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.PlanningPageATM:
            return hasAccess(
                [
                    Persona.AllAccess,
                    Persona.PackagingMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.MakeItOnly,
                    Persona.PlanItOnly
                ],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.PlanningPage:
            return hasAccess(
                [
                    Persona.AllAccess,
                    Persona.PackagingMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.MakeItOnly,
                    Persona.PlanItOnly
                ],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.OrderPaymentFinancials:
            return hasAccess([Persona.AllAccess, Persona.ArAp], auth, context)
                ? SecurityLevel.Edit
                : SecurityLevel.None;

        case Activity.MakeItBulkUpload:
            return hasPersonas(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;

        case Activity.PlanItSummary:
            return validationMethod === ValidationMethod.byAccess
                ? hasAccess(
                      [Persona.AllAccess, Persona.ForecastMgmt, Persona.PlanItOnly],
                      auth,
                      context
                  )
                    ? SecurityLevel.Edit
                    : hasPersonas([Persona.OrderFulfillment], auth)
                    ? SecurityLevel.View
                    : SecurityLevel.None
                : hasPersonas([Persona.AllAccess, Persona.ForecastMgmt, Persona.PlanItOnly], auth)
                ? SecurityLevel.Edit
                : hasPersonas([Persona.OrderFulfillment], auth)
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.PlanItOrderDetails:
            return hasPersonas([Persona.AllAccess, Persona.ForecastMgmt, Persona.PlanItOnly], auth)
                ? SecurityLevel.Edit
                : hasAccess([Persona.OrderFulfillment], auth, context)
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ForecastProductInformation:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.CoPacker,
                    Persona.ForecastMgmt,
                    Persona.PlanItOnly
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.CreateNewUser:
        case Activity.ModifyPermissions:
        case Activity.DeactivateReactivateUsers:
        case Activity.Troubleshooting:
            return hasAccess([Persona.AllAccess, Persona.AccountAdmin], auth, context)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.UserAdminReviewRequest:
        case Activity.UserAdminAddUser:
            return hasPersonas([Persona.AccountAdmin], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.UserAdminDashboard:
        case Activity.UserAdminEditUser:
            return hasPersonas([Persona.AccountAdmin], auth)
                ? SecurityLevel.Edit
                : !hasPersonas([Persona.GraphicsVendor], auth)
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.RequestAccess:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.MarketingMgmt,
                    Persona.PackagingMgmt,
                    Persona.ProcurementMgmt,
                    Persona.ArtMgmt,
                    Persona.ForecastMgmt,
                    Persona.OrderFulfillment,
                    Persona.ArAp,
                    Persona.AccountAdmin,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.GraphicsVendor
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.LiquidTestIntakeForm:
            return hasPersonas(
                [Persona.AllAccess, Persona.MarketingMgmt, Persona.ProcurementMgmt],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.TermsAndConditions:
            return hasPersonas([Persona.JoiningAdmin, Persona.JoiningTermsAndConditions], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.BillingAndShipping:
            return hasPersonas(
                [Persona.JoiningAdmin, Persona.JoiningBillingAndShippingAddresses],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.SalesAndUseTax:
            return hasPersonas([Persona.JoiningAdmin, Persona.JoiningSalesAndUseTax], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.CreditApplication:
            return hasPersonas([Persona.JoiningAdmin, Persona.JoiningCreditApplication], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.LiquidTestIntakeSummary:
            return hasPersonas(
                [Persona.AllAccess, Persona.MarketingMgmt, Persona.ProcurementMgmt],
                auth
            )
                ? SecurityLevel.Edit
                : hasPersonas([Persona.OrderFulfillment], auth)
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ShipItBulkUpload:
            return hasPersonas(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.CoPacker, Persona.ShipItOnly],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.PricingBreakdown:
            return hasPersonas([Persona.PricingContact, Persona.AccountAdmin], auth)
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.PricingContractAcknowledge:
            return hasPersonas([Persona.PricingContact, Persona.AccountAdmin], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.PlanItBulkUpload:
            return validationMethod === ValidationMethod.byAccess
                ? hasAccess(
                      [Persona.AllAccess, Persona.ForecastMgmt, Persona.PlanItOnly],
                      auth,
                      context
                  )
                    ? SecurityLevel.Edit
                    : SecurityLevel.None
                : hasPersonas([Persona.AllAccess, Persona.ForecastMgmt, Persona.PlanItOnly], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.ManageProducts:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.MakeItOnly,
                    Persona.OrderFulfillment,
                    Persona.PlanItOnly
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.TradeItSummary:
            return hasAccess(
                [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.TradeItDetails:
            return hasAccess(
                [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ScrapIt:
            return hasAccess(
                [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment],
                auth,
                context
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.ContractSummary:
            return SecurityLevel.View;
        case Activity.ManageLocations:
            return hasPersonas([Persona.AccountAdmin], auth)
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.AddShipTo:
            return hasPersonas([Persona.AccountAdmin], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.ScrapItDetails:
            return hasAccess(
                [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment],
                auth,
                context
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.CommunicationManagement:
            return hasPersonas([Persona.CommunicationManagement], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.BulkUploadOrders:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.CreateOrder:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.Inventory:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ReviewOrders:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ReviewShipments:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.CreateShipments:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        default:
            return SecurityLevel.None;
    }
};

// Evaluate ATM activity level security
export const getATMSecurityLevel = (
    activity: Activity,
    auth: AuthState,
    context: SecurityContext,
    isLargeCustomer: boolean
): SecurityLevel => {
    switch (activity) {
        case Activity.MakeItATMSummary:
        case Activity.MakeItBulkATM:
            return isLargeCustomer
                ? hasAccess(
                      [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                      auth,
                      context
                  )
                    ? SecurityLevel.Edit
                    : hasAccess(
                          [
                              Persona.PackagingMgmt,
                              Persona.ForecastMgmt,
                              Persona.WarehousingLogistics,
                              Persona.CoPacker,
                              Persona.ArAp
                          ],
                          auth,
                          context
                      )
                    ? SecurityLevel.View
                    : SecurityLevel.None
                : SecurityLevel.None;
        case Activity.NewOpenProductionOrders:
            return isLargeCustomer
                ? SecurityLevel.None
                : hasAccess(
                      [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                      auth,
                      context
                  )
                ? SecurityLevel.Edit
                : hasAccess(
                      [
                          Persona.PackagingMgmt,
                          Persona.ForecastMgmt,
                          Persona.WarehousingLogistics,
                          Persona.CoPacker,
                          Persona.ArAp
                      ],
                      auth,
                      context
                  )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.PlanningPageATM:
            return isLargeCustomer &&
                hasAccess(
                    [
                        Persona.AllAccess,
                        Persona.PackagingMgmt,
                        Persona.ProcurementMgmt,
                        Persona.ForecastMgmt,
                        Persona.OrderFulfillment,
                        Persona.WarehousingLogistics,
                        Persona.MakeItOnly,
                        Persona.PlanItOnly
                    ],
                    auth,
                    context
                )
                ? SecurityLevel.View
                : SecurityLevel.None;

        case Activity.TradeIt:
            return isLargeCustomer &&
                hasAccess(
                    [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                    auth,
                    context
                )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.TradeItSummary:
            return isLargeCustomer &&
                hasAccess(
                    [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                    auth,
                    context
                )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.ScrapIt:
            return isLargeCustomer &&
                hasAccess(
                    [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                    auth,
                    context
                )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.ScrapItDetails:
            return isLargeCustomer &&
                hasAccess(
                    [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment],
                    auth,
                    context
                )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.TradeItDetails:
            return isLargeCustomer &&
                hasAccess(
                    [Persona.AllAccess, Persona.MakeItOnly, Persona.OrderFulfillment],
                    auth,
                    context
                )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.CommunicationManagement:
            return hasPersonas([Persona.CommunicationManagement], auth)
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.BulkUploadOrders:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.CreateOrder:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        case Activity.Inventory:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ReviewOrders:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.ReviewShipments:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.View
                : SecurityLevel.None;
        case Activity.CreateShipments:
            return hasPersonas(
                [
                    Persona.AllAccess,
                    Persona.OrderFulfillment,
                    Persona.MakeItOnly,
                    Persona.ShipItOnly,
                    Persona.PackagingMgmt,
                    Persona.ForecastMgmt,
                    Persona.WarehousingLogistics,
                    Persona.CoPacker,
                    Persona.ArAp
                ],
                auth
            )
                ? SecurityLevel.Edit
                : SecurityLevel.None;
        default:
            return SecurityLevel.None;
    }
};

// Check if the user has permissions to the current context with one of the available personas
export const hasAccess = (
    personas: Persona[],
    auth: AuthState,
    context: SecurityContext
): boolean => {
    const permissions = auth.permissions;

    if (permissions && permissions?.length) {
        for (const permission of permissions) {
            if (!!personas.find((p) => p === permission.personaId)) {
                for (const accountId of permission.accountIds) {
                    if (accountId === context.accountId) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

export const hasPersonas = (personas: Persona[], auth: AuthState) => {
    const permissions = auth.permissions;

    if (permissions && permissions?.length) {
        for (const permission of permissions) {
            if (personas.find((persona) => persona === permission.personaId)) {
                return true;
            }
        }
    }

    return false;
};

export const hasPersonasByShipTo = (
    personas: Persona[],
    selectedShipToId?: string,
    permissions?: UserPermission[]
) => {
    if (permissions && permissions?.length && selectedShipToId !== '') {
        const shipToPermissions = permissions.filter((permission) =>
            permission.accountIds.find((accountId) => accountId === selectedShipToId)
        );
        for (const permission of shipToPermissions) {
            if (personas.find((persona) => persona === permission.personaId)) {
                return true;
            }
        }
    }

    return false;
};

// returns whether or not an Activity is ATM related
// these activities have different permissions based on isLargeCustomer
export const isATMActivity = (activity: Activity): boolean => {
    switch (activity) {
        case Activity.MakeItATMSummary:
        case Activity.MakeItBulkATM:
        case Activity.NewOpenProductionOrders:
        case Activity.PlanningPageATM:
        case Activity.TradeIt:
        case Activity.TradeItSummary:
        case Activity.TradeItDetails:
        case Activity.ScrapIt:
        case Activity.ScrapItDetails:
            return true;
        default:
            return false;
    }
};

/*
    Returns an array of shipToIds that are allowed for the user
    based on the user's permission and the activity.
    The return value will be filtered from the
    accounts input parameter.
*/
export const filterShipToIdsByPermission = (
    auth: AuthState,
    accounts: number[],
    activity: Activity,
    securityLevel: SecurityLevel,
    validationMethod?: ValidationMethod
): number[] => {
    return accounts.filter((account) => {
        const securityContext = {
            accountId: account.toString()
        };
        return getSecurityLevel(activity, auth, securityContext, validationMethod) >= securityLevel;
    });
};

/*
    Returns true if permissions contains one or more Joining persona
*/
export const hasJoiningPersonaAuth = (auth: AuthState): boolean => {
    return hasPersonas(
        [
            Persona.JoiningAdmin,
            Persona.JoiningTermsAndConditions,
            Persona.JoiningSalesAndUseTax,
            Persona.JoiningBillingAndShippingAddresses,
            Persona.JoiningCreditApplication
        ],
        auth
    );
};

/*
    Returns true if permissions contains ANY Joining personas
*/
export const hasJoiningPersona = (permissions: UserPermission[] | null) => {
    if (permissions && permissions?.length) {
        for (const permission of permissions) {
            if (
                permission.personaId === Persona.JoiningAdmin ||
                permission.personaId === Persona.JoiningBillingAndShippingAddresses ||
                permission.personaId === Persona.JoiningCreditApplication ||
                permission.personaId === Persona.JoiningSalesAndUseTax ||
                permission.personaId === Persona.JoiningTermsAndConditions
            ) {
                return true;
            }
        }
    }

    return false;
};

/*
    Returns true if permissions contains ONLY AccountAdmin persona
*/
export const hasAdminPersona = (permissions: UserPermission[] | null) => {
    if (permissions && permissions?.length) {
        for (const permission of permissions) {
            if (permission.personaId === Persona.AccountAdmin) {
                return true;
            }
        }
    }

    return false;
};

/*
    Returns true if user is AccountAdmin for the accountid
*/
export const hasAdminPersonaForAccount = (
    permissions: UserPermission[] | null,
    accountId: string | null
) => {
    if (permissions && permissions?.length && accountId) {
        for (const permission of permissions) {
            if (
                permission.personaId === Persona.AccountAdmin &&
                permission.accountIds.includes(accountId)
            ) {
                return true;
            }
        }
    }

    return false;
};

/*
    Returns true if permissions contains ONLY Joining personas
*/
export const hasOnlyJoiningPersonas = (permissions: UserPermission[]) => {
    for (const permission of permissions) {
        if (
            permission.personaId !== Persona.JoiningAdmin &&
            permission.personaId !== Persona.JoiningBillingAndShippingAddresses &&
            permission.personaId !== Persona.JoiningCreditApplication &&
            permission.personaId !== Persona.JoiningSalesAndUseTax &&
            permission.personaId !== Persona.JoiningTermsAndConditions
        ) {
            return false;
        }
    }

    return true;
};

export const userHasPersonaForAddressTypes = (
    permission: Persona,
    locationTypes: AddressType[],
    accounts: Account[],
    permissions: UserPermission[]
) => {
    const personaAccount = permissions.find(
        (userPermission) => userPermission.personaId === permission
    );
    if (!personaAccount) return false;
    return accounts.some(
        (account) =>
            personaAccount.accountIds.includes(account.accountId) &&
            locationTypes.includes(account.addressType)
    );
};

/*
    Example: 
    const orderSecurity = useSecurity(Activity.CreateNewUser);
    const editDisabled = (orderSecurity !== SecurityLevel.Edit);

    <Button disabled={editDisabled}>Create User</Button>
*/
const useSecurity = (operation: Activity) => {
    const [securityLevel, setSecurityLevel] = useState(SecurityLevel.None);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const securityContext = useMemo(
        () => ({
            accountId: selectedAccountId as string
        }),
        [selectedAccountId]
    );

    useEffect(() => {
        setSecurityLevel(getSecurityLevel(operation, auth, securityContext));
    }, [securityContext, auth, operation]);

    return securityLevel;
};

export default useSecurity;
