import moment from 'moment';
import OrderingSummariesService, {
    OrderingSummaryRequest
} from '../../utility/services/ordering-summaries-service';
import { AuthState } from '../reducers/auth';
import { OrderSummary } from '../reducers/order-summaries';
import {
    ORDERING_SUMMARIES_ERROR,
    ORDERING_SUMMARIES_LOADED,
    ORDERING_SUMMARIES_LOADING,
    ORDERING_SUMMARIES_RESET
} from './action-types';
import OrdersService from '../../utility/services/orders-service';
import { getFullFormattedDateTime } from '../../utility/helpers/date-helpers';
import { ProductWithPallets } from '../reducers/ordering-dashboard';
import { DeliveryOrder } from '../reducers/shipping-dashboard';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType
} from '../../utility/helpers/order-helpers';

export const loadOrderingSummariesInformation = (
    selectedAccountId?: string,
    selectedChildrenAccountId?: string,
    allOrders?: boolean
) => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        dispatch({ type: ORDERING_SUMMARIES_LOADING });
        const reviewOrderSummaryRequest: OrderingSummaryRequest = {
            selectedAccountId: selectedAccountId ? selectedAccountId : '',
            selectedChildrenAccountId: selectedChildrenAccountId ? selectedChildrenAccountId : '',
            allOrders: allOrders
        };
        const actualShipToId = selectedChildrenAccountId
            ? selectedChildrenAccountId
            : selectedAccountId;
        const requestObj = {
            ShipToIds: [actualShipToId],
            OnlyActive: true
        };

        let orderingSummariesCalls = [
            OrdersService.getProductsNew(auth.accessToken, requestObj),
            OrderingSummariesService.getOrderingSummariesInformation(
                auth.accessToken,
                reviewOrderSummaryRequest
            )
        ];
        Promise.all(orderingSummariesCalls)
            .then(([productsResponse, ordersResponse]) => {
                const products: ProductWithPallets[] = productsResponse.data.products;
                products.map((product) => {
                    const canBottleDesc = constructCanDescription(product);
                    const endDesc = constructEndDescription(product);
                    const isEndType = isProductEndType(product.type);
                    product.description = isEndType ? endDesc : canBottleDesc;
                    product.availableItemsPerPallet = product?.quantityPerPallet!;
                    product.graphicIdAndVersion =
                        product.graphicId && product.graphicVersion
                            ? product.graphicId + '-' + product.graphicVersion
                            : '';

                    let matchingDestinationStatus: string | undefined = undefined;
                    if (product.destinations) {
                        product.destinations?.forEach((destination) => {
                            if (
                                destination.shipToId.toString() === actualShipToId &&
                                destination.status === 'ACTIVE'
                            ) {
                                product.customerProductId = destination.customerProductId;
                                product.customerProductName = destination.customerProductName;
                                if (matchingDestinationStatus === undefined) {
                                    matchingDestinationStatus = product.status;
                                }
                            }
                        });
                    }
                    product.status = matchingDestinationStatus ?? 'INACTIVE';
                    product.displayId = product.productSku;
                    product.displayName = product.customerProductName
                        ? product.customerProductName
                        : product.name;
                    return null;
                });

                const orderSummaries: OrderSummary[] = [];
                ordersResponse.data.orders?.forEach((order: DeliveryOrder) => {
                    const submittedDate = order.submittedDate;
                    order.shipments?.forEach((shipment) => {
                        if (shipment.loads) {
                            shipment.loads.forEach((load) => {
                                const product = products.find(
                                    (x) => x.productSku === load.productSku
                                );

                                const orderSummaryRow: OrderSummary = {
                                    deliveryOrderId: order.deliveryOrderId,
                                    orderNumber: order.deliveryOrderNumber,
                                    shipmentNumber: shipment.truckId?.toString() || '-',
                                    shipFrom: shipment.warehouseAddress,
                                    customerPoNumber: order.poNumber,
                                    productSKU: load.productSku,
                                    productGraphicId: load.productGraphicId,
                                    shipments: shipment.loads?.length.toString() || '-',
                                    palletQuantity: load.palletQuantity,
                                    quantityPerPallet: load.quantityPerPallet,
                                    poLine: load.sequence?.toString() || '-',
                                    releaseNumber: load.releaseNumber,
                                    productName: `${product?.displayName} \n ${product?.description}`,
                                    size: product?.size?.toString() || '-',
                                    submitted: submittedDate,
                                    lastUpdatedDate: getFullFormattedDateTime('', submittedDate),
                                    status: shipment?.status || '-',
                                    requestedDateTime: shipment.deliveryDateTime,
                                    scheduledDateTime: shipment.shipDateTime,
                                    carrier: shipment.carrier
                                };
                                orderSummaries.push(orderSummaryRow);
                            });
                        }
                    });
                });
                //sort by dec submitted date
                orderSummaries.sort((a, b) => {
                    const dateA = moment(a.submitted);
                    const dateB = moment(b.submitted);
                    return dateA.date() - dateB.date();
                });
                const orderingSummariesResponse = {
                    ...ordersResponse.data,
                    orderDetails: orderSummaries,
                    summaryByShipment: orderSummaries,
                    summaryByOrder: orderSummaries
                };
                dispatch({
                    type: ORDERING_SUMMARIES_LOADED,
                    orderSummariesData: orderingSummariesResponse
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: ORDERING_SUMMARIES_ERROR, error: err });
            });
    };
};
export const resetOrderingSummaries = () => {
    return (dispatch) => {
        dispatch({ type: ORDERING_SUMMARIES_RESET });
    };
};
