import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { ballGray, blackWeight, ltBlueGrey_7 } from '../../../../../themes/globalConstants';

interface Props {
    title: string;
    left: ReactNode;
    center?: ReactNode;
    right?: ReactNode;
    className?: string;
    isShipmentDelivery?: boolean;
    divider?: boolean;
}

const useStyles = makeStyles((theme) => ({
    widgetSectionHeader: {
        fontWeight: blackWeight,
        color: ballGray,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: '1.75em'
    },
    widgetMargin: {
        marginLeft: '2 rem'
    },
    widgetNumberNoSlant: {
        flexGrow: 1,
        maxWidth: '6em',
        justifyContent: 'center',
        display: 'flex',
        paddingLeft: '2 rem'
    },
    widgetNumberSlant: {
        flexGrow: 1,
        maxWidth: '6em',
        justifyContent: 'center',
        display: 'flex',
        '& > div': {
            width: '1em',
            height: '100%',
            color: ltBlueGrey_7,
            background: `linear-gradient(to top left,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            ${ltBlueGrey_7} 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%)`
        }
    },
    container: {
        '& > div': {
            position: 'relative'
        }
    }
}));

export default function DashboardWidgetMetricSection({
    title,
    left,
    center,
    right,
    isShipmentDelivery,
    divider = true
}: Props) {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h5" className={classes.widgetSectionHeader}>
                {title}
            </Typography>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                className={classes.container}
            >
                {left}
                {center && (
                    <>
                        {divider === true ? (
                            <div
                                className={
                                    isShipmentDelivery
                                        ? classes.widgetNumberNoSlant
                                        : classes.widgetNumberSlant
                                }
                            >
                                <div />
                            </div>
                        ) : null}
                    </>
                )}
                {center}
                {right && (
                    <div
                        className={classes.widgetNumberSlant}
                        style={{ marginLeft: isShipmentDelivery ? '78px' : '' }}
                    >
                        <div />
                    </div>
                )}
                {right}
            </Grid>
        </div>
    );
}
