import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDashboardPositions } from '../../../../../store/actions/user-configuration';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { selectSortedDashboardPositions } from '../../../../../store/selectors/user-configuration.selectors';
import {
    customerDashboardDragAndDropItems,
    useDragAndDrop as useCustomerDashboardDragAndDrop
} from '../utilities/dashboard-positions.config';
import DragAndDrop from '../../../../reusable/molecules/DragAndDrop/DragAndDrop';
import { DragAndDropItem } from '../../../../reusable/molecules/DragAndDrop/models';
import { usePrevious } from '../../../../../utility/helpers/react-util';

interface Props {
    isCiaCustomer: boolean;
    isLargeCustomer: boolean;
    showMakeItWidget: boolean;
    showShipItWidget: boolean;
}

export default function CustomerDashboardDragAndDrop({
    showMakeItWidget,
    showShipItWidget,
    isCiaCustomer,
    isLargeCustomer
}: Props) {
    const dispatch = useDispatch();
    const sortedDashboardPositions = useTypedSelector(selectSortedDashboardPositions);
    const onReorder = (sorted) => dispatch(updateDashboardPositions(sorted));
    const prevIsCiaCustomer = usePrevious(isCiaCustomer);

    const [listItems, setListItems] = useState<Array<DragAndDropItem>>(
        customerDashboardDragAndDropItems(
            showMakeItWidget,
            showShipItWidget,
            isCiaCustomer,
            isLargeCustomer
        )
    );
    const [onDropEnd, updateListItems] = useCustomerDashboardDragAndDrop(
        listItems,
        setListItems,
        sortedDashboardPositions,
        onReorder
    );

    useEffect(() => {
        const items = customerDashboardDragAndDropItems(
            showMakeItWidget,
            showShipItWidget,
            isCiaCustomer,
            isLargeCustomer
        );
        const ciaCustomerChanged = prevIsCiaCustomer !== isCiaCustomer;
        updateListItems(items, ciaCustomerChanged);
    }, [showMakeItWidget, showShipItWidget, isCiaCustomer, prevIsCiaCustomer, isLargeCustomer]);

    return <DragAndDrop items={listItems} onDropEnd={onDropEnd} />;
}
