import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ballBlue, boldWeight, medium } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import {
    addTimezoneOffset,
    getSimpleFormattedDate,
    getSimpleFormattedTime
} from '../../../../utility/helpers/date-helpers';
import DeliveryBulkUploadPdf from './DeliveryBulkUploadPdf';
import { BulkUploadOrder } from '../../../../store/reducers/bulk-upload';

interface Props {
    deliveryOrder: BulkUploadOrder;
    shipToAddress: string;
    shipToName: string;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: boldWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: boldWeight,
        width: '8.75em'
    }
});

const DeliveryBulkConfirmationLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        arr_date?: string;
        arr_time?: string;
        load_seq?: number;
        product_id?: string;
        graphic_id?: string;
        product_name?: string;
        descr: string;
        quantity_ordered?: number;
        quantity_eaches?: number;
        release_number?: string;
        customer_po_number?: string;
        filler_line?: string;
        reference_number?: string;
        shipToId?: number;
        customer_po?: string;
        delivery_instructions?: string;
    }

    const csvHeaders = [
        { label: t('deliveryDate', 'Delivery Date').toUpperCase(), key: 'arr_date' },
        { label: t('deliveryTime', 'Delivery Time').toUpperCase(), key: 'arr_time' },
        { label: t('loadSeq', 'Load Seq.').toUpperCase(), key: 'load_seq' },
        { label: t('productId', 'Product Id').toUpperCase(), key: 'product_id' },
        { label: t('productName', 'Product Name').toUpperCase(), key: 'product_name' },
        {
            label: `${t('productDescription', 'Product Description').toUpperCase()} ${t(
                'characteristics',
                'Characteristics'
            ).toUpperCase()}`,
            key: 'descr'
        },
        { label: t('quantityPL', 'Quantity (PL)').toUpperCase(), key: 'quantity_ordered' },
        { label: t('quantityEA', 'Quantity (EA)').toUpperCase(), key: 'quantity_eaches' },
        { label: t('releaseNumber', 'Release Number').toUpperCase(), key: 'release_number' },
        { label: t('fillerLineNumber', 'Filler Line #').toUpperCase(), key: 'filler_line' },
        { label: t('referenceNumber', 'Reference Number').toUpperCase(), key: 'reference_number' },
        { label: t('shipToId', 'SHIP TO ID').toUpperCase(), key: 'shipToId' },
        { label: t('customerPoNumLong', 'Customer PO Number').toUpperCase(), key: 'customer_po' },
        {
            label: t('deliveryInstructions', 'Delivery Instructions').toUpperCase(),
            key: 'delivery_instructions'
        }
    ];

    const csvArray = () => {
        let rows: any[] = [];
        props.deliveryOrder.shipments?.map((shipment) => {
            shipment.loads?.map((order) => {
                const seq = shipment.loads?.indexOf(order);
                const eaches =
                    order.quantityPerPallet && order.quantityPerPallet * order.palletQuantity;
                const adjustedDateTime = addTimezoneOffset(shipment.deliveryDateTime);

                const row: CSVRow = {
                    arr_date: getSimpleFormattedDate('', adjustedDateTime),
                    arr_time: getSimpleFormattedTime('', adjustedDateTime),
                    load_seq: seq! + 1,
                    product_id: order.userSuppliedProductId ?? order.displayId,
                    product_name: order.displayName,
                    descr: `${order.description}`,
                    quantity_ordered: order.palletQuantity,
                    quantity_eaches: eaches,
                    release_number: order.releaseNumber,
                    filler_line: order.fillerLine,
                    reference_number: order.referenceNumber,
                    shipToId: props.deliveryOrder.shipToId,
                    customer_po: order.purchaseOrderNumber,
                    delivery_instructions: order.deliveryInstructions
                };
                rows.push(row);
                return null;
            });
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={`${props.deliveryOrder.deliveryOrderId}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('orderConfirmation', 'Order Confirmation')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <DeliveryBulkUploadPdf
                    title={t('orderConfirmation', 'Order Confirmation')}
                    deliveryOrder={props.deliveryOrder}
                    shipToAddress={props.shipToAddress}
                    shipToName={props.shipToName}
                />
            </Modal>
        </Grid>
    );
};

export default DeliveryBulkConfirmationLinks;
