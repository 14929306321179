import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Route, useHistory, useLocation, Switch } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import LoginCallback from './utility/auth/LoginCallback';
import Home from './components/pages/Home/Home';
import ProductPortfolio from './components/pages/ProductPortfolio/ProductPortfolio';
import ContentPage from './components/pages/ContentPage/ContentPage';
import ProductDetailsPage from './components/pages/ProductDetails/ProductDetailsPage';
import { ThemeProvider, CircularProgress, makeStyles } from '@material-ui/core';
import rootTheme from './themes/rootTheme';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import {
    FlagsProvider,
    FlagState,
    FlagTypes,
    GetFeatureFlag
} from './utility/helpers/feature-flag';
import ProductEstimate from './components/pages/ProductEstimate/ProductEstimatePage';
import RegistrationPage from './components/pages/Registration/RegistrationPage';
import ProductEndDetailsPage from './components/pages/EndDetails/ProductEndDetailsPage';
import MakeItOrderConfirmation from './components/pages/MakeItOrderConfirmation/MakeItOrderConfirmation';
import NotFoundPage from './components/pages/NotFound/NotFoundPage';
import RegistrationCallback from './components/pages/RegistrationCallback/RegistrationCallback';
import CustomerDashboard from './components/pages/CustomerDashboard/CustomerDashboard';
import GraphicsIntakePage from './components/pages/GraphicsIntake/GraphicsIntakePage';
import GraphicsSummary from './components/pages/GraphicsSummary/GraphicsSummary';
import GraphicsIntakeReadOnlyPage from './components/pages/GraphicsIntakeReadOnly/GraphicsIntakeReadOnlyPage';
import GraphicsColorMatchingReadOnlyPage from './components/pages/GraphicsColorMatchingReadOnly/GraphicsColorMatchingReadOnlyPage';
import GraphicsIntakeSuccessPage from './components/pages/GraphicsIntake/GraphicsIntakeSuccessPage';
import ProductPlanning from './components/pages/ProductPlanning/ProductPlanning';
import MakeIt from './components/pages/MakeIt/MakeIt';
import MakeItSummary from './components/pages/MakeItSummary/MakeItSummary';
import ShipIt from './components/pages/ShipIt/ShipIt';
import UnauthorizedPage from './components/pages/Unauthorized/UnauthorizedPage';
import HomepageCallback from './components/reusable/molecules/HomepageCallback';
import MakeItConfiguration from './components/pages/MakeItConfiguration/MakeItConfiguration';
import EmeaGraphicsIntakePage from './components/pages/EmeaGraphicsIntake/EmeaGraphicsIntakePage';
import PendingPayments from './components/pages/PendingPayments/PendingPayments';
import ShipItConfiguration from './components/pages/ShipItConfiguration/ShipItConfiguration';
import ShipItConfirmation from './components/pages/ShipItConfirmation/ShipItConfirmation';
import ProductionOrderDetails from './components/pages/ProductionOrderDetails/ProductionOrderDetails';
import ShipItSummary from './components/pages/ShipItSummary/ShipItSummary';
import DeliveryOrderDetails from './components/pages/DeliveryOrderDetails/DeliveryOrderDetails';
import EditShipmentsSummary from './components/pages/EditShipmentsSummary/EditShipmentsSummaryPage';
import DeliveryBulkUpload from './components/pages/DeliveryBulkUpload/DeliveryBulkUpload';
import EditShipmentsConfiguration from './components/pages/EditShipmentsConfiguration/EditShipmentsConfiguration';
import EditShipmentsConfirmation from './components/pages/EditShipmentsConfirmation/EditShipmentsConfirmation';
import DeliveryBulkUploadReview from './components/pages/DeliveryBulkUpload/DeliveryBulkUploadReview';
import ShipToAddressList from './components/pages/ShipToAddressList/ShipToAddressList';
import DeliveryBulkUploadConfirmation from './components/pages/DeliveryBulkUpload/DeliveryBulkUploadConfirmation';
import DeliveryBulkUploadEditShipment from './components/pages/DeliveryBulkUpload/DeliveryBulkUploadEditShipment';
import CancelDeliveryOrderConfirmation from './components/pages/CancelDeliveryOrderConfirmation/CancelDeliveryOrderConfirmation';
import EditProductionOrderConfirmation from './components/pages/EditProductionOrderConfirmation/EditProductionOrderConfirmation';
import CancelProductionOrderConfirmation from './components/pages/CancelProductionOrderConfirmation/CancelProductionOrderConfirmation';
import EditProductionOrder from './components/pages/EditProductionOrder/EditProductionOrder';
import DeliveryBulkUploadValidationRules from './components/pages/OrderingValidation/DeliveryBulkUploadValidationRules';
import MakeItBulkCampaigns from './components/pages/MakeItBulkCampaigns/MakeItBulkCampaigns';
import MakeItBulkUploadReview from './components/pages/MakeItBulkUploadReview/MakeItBulkUploadReview';
import MakeItBulkUploadReviewATM from './components/pages/MakeItBulkReviewATM/MakeItBulkUploadReviewATM';
import MakeItBulkUpload from './components/pages/MakeItBulkUpload/MakeItBulkUpload';
import MakeItBulkUploadConfirmation from './components/pages/MakeItBulkUploadConfirmation/MakeItBulkUploadConfirmation';
import MakeItBulkUploadValidationRules from './components/pages/OrderingValidation/MakeItBulkUploadValidationRules';
import PlanItBulkUploadValidationRules from './components/pages/OrderingValidation/PlanItBulkUploadValidationRules';
import UserAdminAddUser from './components/pages/UserAdminAddUser/UserAdminAddUser';
import UserAdminRolesAndAccess from './components/pages/UserAdminRolesAndAccess/UserAdminRolesAndAccess';
import RequestAccess from './components/pages/RequestAccess/RequestAccess';
import UserAdminDashboard from './components/pages/UserAdminDashboard/UserAdminDashboard';
import UserAdminUserDetails from './components/pages/UserAdminUserDetails/UserAdminUserDetails';
import MyAccountDashboard from './components/pages/MyAccountDashboard/MyAccountDashboard';
import UserAdminReviewRequest from './components/pages/UserAdminReviewRequest/UserAdminReviewRequest';
import { useTypedSelector } from './store/reducers/reducer';
import { RegionCultureState } from './store/reducers/region-culture';
import { Region } from './store/reducers/graphic-intake';
import KBMaxProductComparison from './components/pages/KBMaxProductComparison/KBMaxProductComparison';
import KBMaxBuildAndEstimate from './components/pages/KBMaxBuild/KBMaxBuildAndEstimate';
import KBMaxMyProject from './components/pages/KBMaxMyProject/KBMaxMyProject';
import LiquidTestIntakeConfirmation from './components/pages/LiquidTestIntakeConfirmation/LiquidTestIntakeConfirmation';
import LiquidTestIntakePage from './components/pages/LiquidTestIntake/LiquidTestIntakePage';
import LiquidTestSummaryPage from './components/pages/LiquidTestSummary/LiquidTestSummary';
import OnboardingDashboard from './components/pages/OnboardingDashboard/OnboardingDashboard';
import SalesAndUseTax from './components/pages/SalesAndUseTax/SalesAndUseTax';
import CreditApplicationPage from './components/pages/CreditApplication/CreditApplicationPage';
import OnboardBillingAndShipping from './components/pages/OnboardBillingAndShipping/OnboardBillingAndShipping';
import TermsAndConditions from './components/pages/TermsAndConditions/TermsAndConditions';
import MakeItBulkATMConfirmation from './components/pages/MakeItBulkATMConfirmation/MakeItBulkATMConfirmation';
import MakeItATMSummary from './components/pages/MakeItATMSummary/MakeItATMSummary';
import MakeItATMOrderDetails from './components/pages/MakeItATMOrderDetails/MakeItATMOrderDetails';
import KBMaxCapacityCheck from './components/pages/KBMaxCapacityCheck.tsx/KBMaxCapacityCheck';
import ProductPlanningATM from './components/pages/ProductPlanningATM/ProductPlanningATM';
import CustomerServiceSurvey from './components/pages/CustomerServiceSurvey/CustomerServiceSurvey';
import ProductInformationPage from './components/pages/ProductInformation/ProductInformationPage';
import PlanItOrderDetails from './components/pages/PlanItOrderDetails/PlanItOrderDetails';
import EmeaGraphicsPDF from './components/pages/EmeaGraphicsIntake/components/EmeaGraphicsPDF';
import TranslationHandler from './utility/translations/translation-handler';
import PlanItGuided from './components/pages/PlanItGuided/PlanItGuided';
import PlanItBulkUpload from './components/pages/PlanItBulkUpload/PlanItBulkUpload';
import ProductAlerts from './components/pages/ProductAlerts/ProductAlerts';
import PlanItSummary from './components/pages/PlanItSummary/PlanItSummary';
import PlanItBulkReview from './components/pages/PlanItBulkReview/PlanItBulkReview';
import PlanItBulkUploadConfirmation from './components/pages/PlanItBulkUploadConfirmation/PlanItBulkUploadConfirmation';
import PricingBreakdown from './components/pages/PricingBreakdown/PricingBreakdown';
import ContractSummary from './components/pages/ContractSummary/ContractSummary';
import PricingContractAcknowledgement from './components/pages/PricingContractAcknowledgement/PricingContractAcknowledgement';
import MakeItConversionReview from './components/pages/MakeItConversionReview/MakeItConversionReview';
import MakeItConversionConfirmation from './components/pages/MakeItConversionConfirmation/MakeItConversionConfirmation';
import MakeItDraftsSummary from './components/pages/MakeItDraftsSummary/MakeItDraftsSummary';
import ManageProducts from './components/pages/ManageProducts/ManageProducts';
import PlanItConfiguration from './components/pages/PlanItConfiguration/PlanItConfiguration';
import ScrapIt from './components/pages/ScrapIt/ScrapIt';

import TradeIt from './components/pages/TradeIt/TradeIt';
import TradeRequestSummary from './components/pages/TradeRequestSummary/TradeRequestSummary';
import TradeItConfirmation from './components/pages/TradeItConfirmation/TradeItConfirmation';
import PlanItGuidedConfirmation from './components/pages/PlanItGuidedConfirmation/PlanItGuidedConfirmation';
import TradeRequestDetails from './components/pages/TradeRequestDetails/TradeRequestDetails';
import ScrapItReview from './components/pages/ScrapItReview/ScrapItReview';
import ManageLocations from './components/pages/ManageLocations/ManageLocations';
import ScrapItConfirmation from './components/pages/ScrapItConfirmation/ScrapItConfirmation';
import ScrapItSummary from './components/pages/ScrapItSummary/ScrapItSummary';
import ScrapItRequestDetails from './components/pages/ScrapItRequestDetails/ScrapItRequestDetails';
import ShipToLocationAdd from './components/pages/ShipToLocationAdd/ShipToLocationAdd';
import ShipToLocationAddConfirmation from './components/pages/ShipToLocationAddConfirmation/ShipToLocationAddConfirmation';
import ShipToLocationAddReview from './components/pages/ShipToLocationAddReview/ShipToLocationAddReview';
import ManageCommunications from './components/pages/ManageCommunications/ManageCommunications';
import ConfigureCommunicationPage from './components/pages/ConfigureCommunication/ConfigureCommunicationPage';
import CustomerSurvey from './components/reusable/molecules/CustomerSurvey/CustomerSurvey';

/** OrderingV2 pages */
import BulkUploadOrders from './components/pages/BulkUploadOrders/BulkUploadOrders';
import CreateOrder from './components/pages/CreateOrder/CreateOrder';
import CreateShipments from './components/pages/CreateShipments/CreateShipments';
import Inventory from './components/pages/Inventory/Inventory';
import ReviewOrders from './components/pages/ReviewOrders/ReviewOrders';
import ReviewShipments from './components/pages/ReviewShipments/ReviewShipments';
import OrderDetails from './components/pages/OrderDetails/OrderDetails';

// lazy loading for code splitting
const LoginPage = lazy(() => import('./components/pages/LoginPage/LoginPage'));

export enum RouterConfirmationOverride {
    PreventBrowserBack = 'preventBack'
}

const redirectUriPath = window.location.origin.includes('localhost')
    ? '/implicit/callback'
    : '/source/implicit/callback';

const config = {
    issuer: `${process.env.REACT_APP_OKTA_ISSUER_URL}/oauth2/${
        process.env.REACT_APP_OKTA_AUTH_SERVER ?? 'default'
    }`,
    redirectUri: window.location.origin + redirectUriPath,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
    tokenManager: {
        // expire tokens at 55 minutes
        expireEarlySeconds: 300
    }
};

const kbMax = `${process.env.REACT_APP_FEATURE_KB_MAX}`;
const graphicsSummary = `${process.env.REACT_APP_FEATURE_GRAPHICS_SUMMARY}`;
const coke = `${process.env.REACT_APP_FEATURE_COKE}`;
const makeItBulkUpload = `${process.env.REACT_APP_FEATURE_MAKEIT_BULK}`;
const playground = `${process.env.REACT_APP_FEATURE_PLAYGROUND}`;
const liquids = `${process.env.REACT_APP_FEATURE_LIQUIDS}`;
const hideEdit = `${process.env.REACT_APP_FEATURE_HIDE_EDIT}`;
const internalPasswordReset = `${process.env.REACT_APP_FEATURE_PASSWORD_RESET}`;
const tradeIt = `${process.env.REACT_APP_FEATURE_TRADE_IT}`;
const tradeItSummary = `${process.env.REACT_APP_FEATURE_TRADE_IT_SUMMARY}`;
const scrapIt = `${process.env.REACT_APP_FEATURE_SCRAP_IT}`;
const productIdManagement = `${process.env.REACT_APP_FEATURE_PRODUCT_ID_MANAGEMENT}`;
const availableToTrade = `${process.env.REACT_APP_FEATURE_AVAILABLE_TO_TRADE}`;
const activateProduct = `${process.env.REACT_APP_FEATURE_ACTIVATE_PRODUCT}`;
const deactivateProduct = `${process.env.REACT_APP_FEATURE_DEACTIVATE_PRODUCT}`;
const editProductName = `${process.env.REACT_APP_FEATURE_EDIT_PRODUCT_NAME}`;
const manageLocations = `${process.env.REACT_APP_FEATURE_MANAGE_LOCATIONS}`;
const graphicsFileOptional = `${process.env.REACT_APP_FEATURE_GRAPHICS_FILE_OPTIONAL}`;
const productAlerts = `${process.env.REACT_APP_FEATURE_PRODUCT_ALERTS}`;
const unrecognizedProductsTab = `${process.env.REACT_APP_FEATURE_UNRECOGNIZED_PRODUCTS_TAB}`;
const orderingV2 = `${process.env.REACT_APP_FEATURE_ORDERING_V2}`;

const flags: FlagState[] = [
    { name: FlagTypes.KBMax, isActive: kbMax === 'true' },
    { name: FlagTypes.GraphicsSummary, isActive: graphicsSummary === 'true' },
    { name: FlagTypes.Coke, isActive: coke === 'true' },
    { name: FlagTypes.MakeItBulkUpload, isActive: makeItBulkUpload === 'true' },
    { name: FlagTypes.Playground, isActive: playground === 'true' },
    { name: FlagTypes.Liquids, isActive: liquids === 'true' },
    { name: FlagTypes.HideEdit, isActive: hideEdit === 'true' },
    { name: FlagTypes.InternalPasswordReset, isActive: internalPasswordReset === 'true' },
    { name: FlagTypes.TradeIt, isActive: tradeIt === 'true' },
    { name: FlagTypes.TradeItSummary, isActive: tradeItSummary === 'true' },
    { name: FlagTypes.ScrapIt, isActive: scrapIt === 'true' },
    { name: FlagTypes.ProductIdManagement, isActive: productIdManagement === 'true' },
    { name: FlagTypes.AvailableToTrade, isActive: availableToTrade === 'true' },
    { name: FlagTypes.ActivateProduct, isActive: activateProduct === 'true' },
    { name: FlagTypes.DeactivateProduct, isActive: deactivateProduct === 'true' },
    { name: FlagTypes.EditProductName, isActive: editProductName === 'true' },
    { name: FlagTypes.ManageLocations, isActive: manageLocations === 'true' },
    { name: FlagTypes.GraphicsFileOptional, isActive: graphicsFileOptional === 'true' },
    { name: FlagTypes.ProductAlerts, isActive: productAlerts === 'true' },
    { name: FlagTypes.UnrecognizedProductsTab, isActive: unrecognizedProductsTab === 'true' },
    { name: FlagTypes.OrderingV2, isActive: orderingV2 === 'true' }
];

const useCircularProgressStyles = makeStyles(() => ({
    circularProgressContaier: {
        height: '100vh',
        width: '100vw',
        position: 'relative'
    },
    circularProgress: {
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: 'calc(50% - 20px)'
    }
}));

declare global {
    interface Window {
        KAMPYLE_ONSITE_SDK: {
            updatePageView: () => void;
        };
    }
}

const AppWithRouterAccess = () => {
    const history = useHistory();
    const onAuthRequired = () => {
        history.push('/login');
    };
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const liquidsFeatureFlag = GetFeatureFlag(FlagTypes.Liquids);
    const tradeItFeatureFlag = GetFeatureFlag(FlagTypes.TradeIt);
    const scrapItFeatureFlag = GetFeatureFlag(FlagTypes.ScrapIt);
    const tradeItSummaryFeatureFlag = GetFeatureFlag(FlagTypes.TradeItSummary);
    const manageLocationsFeatureFlag = GetFeatureFlag(FlagTypes.ManageLocations);
    const orderingV2 = GetFeatureFlag(FlagTypes.OrderingV2);

    // Allow alternate route for each path including a path modifier
    // Example: A route that's normally reached at "/welcome" can also be reached at "/business/welcome"
    const alternatePathModifier = 'source';
    const routeWithAltPath = (route: string) => `/(${alternatePathModifier})?${route}`;

    // auto scroll to top on route change
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const CircularProgressFallback = () => {
        const classes = useCircularProgressStyles();

        return (
            <div className={classes.circularProgressContaier}>
                <CircularProgress className={classes.circularProgress} />
            </div>
        );
    };

    // Need to add to support Medallia forms across the application
    if (
        window.KAMPYLE_ONSITE_SDK &&
        typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function'
    ) {
        window.KAMPYLE_ONSITE_SDK.updatePageView();
    }

    return (
        <Security {...config} onAuthRequired={onAuthRequired}>
            <Suspense fallback={<CircularProgressFallback />}>
                <Switch>
                    <SecureRoute
                        path={routeWithAltPath('/')}
                        exact={true}
                        component={HomepageCallback}
                    />
                    <SecureRoute path={routeWithAltPath('/welcome')} component={Home} />
                    <Route
                        path={routeWithAltPath('/implicit/callback')}
                        render={() => <LoginCallback homePath={`/${alternatePathModifier}`} />}
                    />
                    <Route
                        path={routeWithAltPath('/login')}
                        render={() => <LoginPage {...config} />}
                    />
                    <Route
                        path={routeWithAltPath('/registration')}
                        render={() => <RegistrationPage {...config} />}
                    />
                    <Route
                        path={routeWithAltPath('/customer-satisfaction-survey')}
                        render={() => <CustomerServiceSurvey />}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/registration-callback')}
                        component={RegistrationCallback}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product-portfolio')}
                        component={ProductPortfolio}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product/:id')}
                        component={ProductDetailsPage}
                    />
                    <SecureRoute path={routeWithAltPath('/content/*')} component={ContentPage} />
                    <SecureRoute
                        path={routeWithAltPath('/product-estimate')}
                        component={ProductEstimate}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product-ends')}
                        component={ProductEndDetailsPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/dashboard')}
                        exact={true}
                        component={CustomerDashboard}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/onboard')}
                        exact={true}
                        component={OnboardingDashboard}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/onboard/billing-and-shipping')}
                        exact={true}
                        component={OnboardBillingAndShipping}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/terms-and-conditions')}
                        exact={true}
                        component={TermsAndConditions}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/graphics-intake/:id')}
                        component={GraphicsIntakePage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/graphics-intake')}
                        component={GraphicsIntakePage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/emea-graphics-intake/:id')}
                        component={EmeaGraphicsIntakePage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/emea-graphics-intake')}
                        component={EmeaGraphicsIntakePage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/emea-graphics-pdf/:id')}
                        component={EmeaGraphicsPDF}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/graphics-summary')}
                        component={GraphicsSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/graphics-intake-viewer/:id')}
                        component={GraphicsIntakeReadOnlyPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/graphics-color-viewer/:id')}
                        component={GraphicsColorMatchingReadOnlyPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/graphics-intake-success')}
                        component={GraphicsIntakeSuccessPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-order/:id')}
                        component={PlanItOrderDetails}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/makeit-conversion-review')}
                        component={MakeItConversionReview}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-conversion-confirmation')}
                        component={MakeItConversionConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-summary')}
                        component={PlanItSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-bulk-upload-confirmation')}
                        component={PlanItBulkUploadConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product-planning')}
                        component={ProductPlanning}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/po-product-planning')}
                        component={ProductPlanningATM}
                    />
                    <SecureRoute path={routeWithAltPath('/make-it/:id')} component={MakeIt} />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-po-summary')}
                        component={MakeItATMSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-po-order/:id')}
                        component={MakeItATMOrderDetails}
                    />
                    <SecureRoute path={routeWithAltPath('/make-it')} component={MakeIt} />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-summary')}
                        component={MakeItSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-drafts-summary')}
                        component={MakeItDraftsSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-configuration/:id')}
                        component={MakeItConfiguration}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-configuration')}
                        component={MakeItConfiguration}
                    />
                    <SecureRoute path={routeWithAltPath('/ship-it')} component={ShipIt} />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-configuration')}
                        component={ShipItConfiguration}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/pending-payments')}
                        component={PendingPayments}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/unauthorized')}
                        component={UnauthorizedPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-order-confirmation')}
                        component={MakeItOrderConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-confirmation')}
                        component={ShipItConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-summary/')}
                        component={ShipItSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/production-order/:id')}
                        component={ProductionOrderDetails}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/delivery-order/:id')}
                        component={DeliveryOrderDetails}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-shipments-summary/:id')}
                        component={EditShipmentsSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-bulk-upload')}
                        component={DeliveryBulkUpload}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-bulk-upload-confirmation')}
                        component={DeliveryBulkUploadConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-shipments-configuration/:id')}
                        component={EditShipmentsConfiguration}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-shipments-configuration')}
                        component={EditShipmentsConfiguration}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-bulk-upload-review')}
                        component={DeliveryBulkUploadReview}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-shipments-confirmation')}
                        component={EditShipmentsConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/cancel-delivery-order-confirmation')}
                        component={CancelDeliveryOrderConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/assigned-ship-tos/:id')}
                        component={ShipToAddressList}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-bulk-data-validation')}
                        component={DeliveryBulkUploadValidationRules}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/ship-it-bulk-upload-edit-shipment/:id')}
                        component={DeliveryBulkUploadEditShipment}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-production-order-confirmation')}
                        component={EditProductionOrderConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/cancel-production-order-confirmation')}
                        component={CancelProductionOrderConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-production-order/:id')}
                        component={EditProductionOrder}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-upload')}
                        component={MakeItBulkUpload}
                    />
                    <SecureRoute path={routeWithAltPath('/plan-it')} component={PlanItGuided} />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-configuration')}
                        component={PlanItConfiguration}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-confirmation')}
                        component={PlanItGuidedConfirmation}
                    />

                    <SecureRoute
                        path={routeWithAltPath('/plan-it-bulk-upload')}
                        component={PlanItBulkUpload}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product-alerts')}
                        component={ProductAlerts}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-bulk-upload-review')}
                        component={PlanItBulkReview}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product-information')}
                        component={ProductInformationPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-campaigns')}
                        component={MakeItBulkCampaigns}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-upload-confirmation')}
                        component={MakeItBulkUploadConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-upload-review')}
                        component={MakeItBulkUploadReview}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-upload-po-review')}
                        component={MakeItBulkUploadReviewATM}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-po-confirmation')}
                        component={MakeItBulkATMConfirmation}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/make-it-bulk-data-validation')}
                        component={MakeItBulkUploadValidationRules}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/manage-products')}
                        component={ManageProducts}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/plan-it-bulk-data-validation')}
                        component={PlanItBulkUploadValidationRules}
                    />
                    {scrapItFeatureFlag && (
                        <SecureRoute path={routeWithAltPath('/scrap-it')} component={ScrapIt} />
                    )}
                    {scrapItFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/scrap-it-confirmation')}
                            component={ScrapItConfirmation}
                        />
                    )}
                    {scrapItFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/scrap-it-requests')}
                            component={ScrapItSummary}
                        />
                    )}
                    {scrapItFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/scrap-it-review')}
                            component={ScrapItReview}
                        />
                    )}
                    {scrapItFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/scrap-it-request-details/:id')}
                            component={ScrapItRequestDetails}
                        />
                    )}
                    <SecureRoute
                        path={routeWithAltPath('/add-new-user')}
                        component={UserAdminAddUser}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/roles-and-access')}
                        component={UserAdminRolesAndAccess}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/user-admin-dashboard')}
                        component={UserAdminDashboard}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/user-admin-user-details')}
                        component={UserAdminUserDetails}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/user-admin-review-request/:id')}
                        component={UserAdminReviewRequest}
                    />
                    <SecureRoute path={routeWithAltPath('/pricing')} component={PricingBreakdown} />
                    <SecureRoute
                        path={routeWithAltPath('/terms-and-pricing')}
                        component={ContractSummary}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/acknowledge-terms/:billToId/:documentId')}
                        component={PricingContractAcknowledgement}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/product-comparison')}
                        component={KBMaxProductComparison}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/my-project')}
                        component={KBMaxMyProject}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/compare-and-build')}
                        component={KBMaxBuildAndEstimate}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/capacity-checks')}
                        component={KBMaxCapacityCheck}
                    />
                    <SecureRoute
                        exact={true}
                        path={routeWithAltPath('/onboard/sales-and-use-tax')}
                        component={SalesAndUseTax}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/onboard/credit-application')}
                        component={CreditApplicationPage}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/manage-communications')}
                        component={ManageCommunications}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/configure-communication/:communicationId?')}
                        component={ConfigureCommunicationPage}
                    />
                    {manageLocationsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/add-ship-to/:billToId')}
                            component={ShipToLocationAdd}
                        />
                    )}
                    {manageLocationsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/manage-locations')}
                            component={ManageLocations}
                        />
                    )}
                    {manageLocationsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/add-ship-to-review')}
                            component={ShipToLocationAddReview}
                        />
                    )}
                    {manageLocationsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/add-ship-to-confirmation')}
                            component={ShipToLocationAddConfirmation}
                        />
                    )}
                    {tradeItFeatureFlag && (
                        <SecureRoute path={routeWithAltPath('/trade-it')} component={TradeIt} />
                    )}
                    {tradeItFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/trade-it-confirmation')}
                            component={TradeItConfirmation}
                        />
                    )}

                    {tradeItSummaryFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/trade-it-summary')}
                            component={TradeRequestSummary}
                        />
                    )}
                    {tradeItSummaryFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/trade-it-request/:id')}
                            component={TradeRequestDetails}
                        />
                    )}

                    {liquidsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/liquid-test-summary')}
                            component={LiquidTestSummaryPage}
                        />
                    )}
                    {liquidsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/liquid-test-confirmation')}
                            component={LiquidTestIntakeConfirmation}
                        />
                    )}
                    {liquidsFeatureFlag && (
                        <SecureRoute
                            path={routeWithAltPath('/liquid-test-intake')}
                            component={LiquidTestIntakePage}
                        />
                    )}
                    {regionCode === Region.NA && (
                        <SecureRoute
                            path={routeWithAltPath('/request-access')}
                            component={RequestAccess}
                        />
                    )}
                    {regionCode === Region.NA && (
                        <SecureRoute
                            path={routeWithAltPath('/my-account-dashboard')}
                            component={MyAccountDashboard}
                        />
                    )}
                    <SecureRoute path={routeWithAltPath('/create-order')} component={CreateOrder} />
                    <SecureRoute
                        path={routeWithAltPath('/create-shipments')}
                        component={CreateShipments}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/edit-order-shipments/:id')}
                        component={CreateShipments}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/create-order-summary')}
                        component={OrderDetails}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/order-details/:id')}
                        component={OrderDetails}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/bulk-upload-orders')}
                        component={BulkUploadOrders}
                    />
                    <SecureRoute path={routeWithAltPath('/inventory')} component={Inventory} />
                    <SecureRoute
                        path={routeWithAltPath('/orders-summary')}
                        component={ReviewOrders}
                    />
                    <SecureRoute
                        path={routeWithAltPath('/review-shipments')}
                        component={ReviewShipments}
                    />
                    <Route component={NotFoundPage} />
                </Switch>
            </Suspense>
        </Security>
    );
};

const App = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <TranslationHandler />
            <ThemeProvider theme={rootTheme}>
                <FlagsProvider flags={flags}>
                    <BrowserRouter
                        basename="/source"
                        // Override default getUserConfirmation to overcome known issue with react-router
                        // https://github.com/ReactTraining/react-router/issues/5405#issuecomment-682730905

                        // react-router Prompt components can check if action === 'POP', meaning browser back or forward button was clicked
                        // If you want the Prompt to supress this action, pass RouterConfirmationOverride.PreventBrowserBack as the message
                        // Otherwise, the behavior will work as normal
                        getUserConfirmation={(message, callback) => {
                            if (message === RouterConfirmationOverride.PreventBrowserBack) {
                                window.history.forward();
                                return false;
                            }

                            const allowTransition = window.confirm(message);
                            callback(allowTransition);
                        }}
                    >
                        <AppWithRouterAccess />
                    </BrowserRouter>
                </FlagsProvider>
                <CustomerSurvey />
            </ThemeProvider>
        </I18nextProvider>
    );
};

export default App;
