import {
    Activity,
    hasPersonasByShipTo,
    Persona,
    SecurityLevel
} from '../../../utility/auth/useSecurity';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { enUS } from '../../../utility/translations/locales';
import React, { useEffect, useState } from 'react';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Trans, useTranslation } from 'react-i18next';
import { ballLtGray, blackWeight, large, red, white } from '../../../themes/globalConstants';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    Account,
    CustomerContextState,
    ProdOrderType
} from '../../../store/reducers/customer-context';
import { selectIsLargeCustomerAccount, selectImpersonationShipTos } from '../../../store/selectors';
import { AuthState } from '../../../store/reducers/auth';
import { isValidEmail } from '../../../utility/helpers/user-helpers';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { useHistory } from 'react-router';
import { selectCustomerAccountId } from '../../../store/actions/customer-context';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { getAvailableTradeBalances, submitTradeItRequest } from '../../../store/actions/trade-it';
import moment from 'moment';
import { TradeItRequestState } from '../../../store/reducers/trade-it';
import TradeItProductsGrid from './components/TradeItProductsGrid';
import {
    TRAADE_IT_FROM_EMAILS_RESET,
    TRADE_IT_BALANCES_RESET,
    TRADE_IT_EMAILS_RESET,
    TRADE_IT_TO_EMAILS_RESET
} from '../../../store/actions/action-types';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import EmailSelectorDropdown from '../../reusable/molecules/EmailSelectorDropdown';
import TradeItCampaignModal from './components/TradeItCampaignModal';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    stepNum: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        width: '1.875em',
        height: '1.875em',
        fontSize: large,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: blackWeight
    },
    stepWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    stepTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.5em'
    },
    selector: {
        display: 'flex',
        marginTop: '0.25em'
    },
    selectorTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        marginTop: '1em'
    },
    receivingSelector: {
        minWidth: '90%'
    },
    emailInput: {
        height: '2em',
        border: `1.8px solid ${ballLtGray}`,
        paddingLeft: '0.5em',
        borderRadius: '.25em',
        marginRight: '0.5em',
        backgroundColor: white,
        minWidth: '90%'
    },
    invalidEmail: {
        color: red
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    sectionTwo: {
        marginTop: '1.5em'
    },
    makeSelections: {
        marginTop: '1.5em',
        marginLeft: '1em'
    },
    tableWrapper: {
        marginTop: '1.5em'
    }
}));

export default function TradeIt() {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [originalShipToAccountsList, setOriginalShipToAccountsList] = useState<
        FormattedAccount[]
    >([]);
    const [selectedOriginalShipTo, setSelectedOriginalShipTo] = useState<string>();
    const [receivingShipToAccountsList, setReceivingShipToAccountsList] = useState<
        FormattedAccount[]
    >([]);
    const [selectedReceivingShipTo, setSelectedReceivingShipTo] = useState<string>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [originalShipToContactEmail, setOriginalShipToContactEmail] = useState<string>();
    const [receivingShipToContactEmail, setReceivingShipToContactEmail] = useState<string>();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const impersonationShipTos = useTypedSelector<string[]>(selectImpersonationShipTos);
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const { products, loaded, loading, submitted, fromEmails, toEmails } =
        useTypedSelector<TradeItRequestState>((state) => state.tradeItRequest);
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const [campaignItemsRequested, setCampaignItemsRequested] = useState<boolean>(false);
    const [showCampaignModal, setShowCampaignModal] = useState<boolean>(false);

    const populateShipToSelectors = (shipToAccounts: Account[] | undefined) => {
        const formattedOriginalAccounts: FormattedAccount[] = [];
        const formattedReceivingAccounts: FormattedAccount[] = [];
        if (shipToAccounts) {
            shipToAccounts
                .filter(
                    (account) =>
                        account.prodOrderType === ProdOrderType.AuthorizationToManufacture &&
                        hasPersonasByShipTo(
                            [Persona.MakeItOnly, Persona.OrderFulfillment, Persona.AllAccess],
                            account.accountId,
                            auth.permissions!
                        )
                )
                .forEach((account) => {
                    if (account.accountId !== selectedReceivingShipTo) {
                        formattedOriginalAccounts.push({
                            account: account,
                            state: 'valid'
                        } as FormattedAccount);
                    }
                });
            shipToAccounts
                .filter(
                    (account) =>
                        account.prodOrderType === ProdOrderType.AuthorizationToManufacture &&
                        hasPersonasByShipTo(
                            [Persona.MakeItOnly, Persona.OrderFulfillment, Persona.AllAccess],
                            account.accountId,
                            auth.permissions!
                        )
                )
                .forEach((account) => {
                    if (account.accountId !== selectedOriginalShipTo) {
                        formattedReceivingAccounts.push({
                            account: account,
                            state: 'valid'
                        } as FormattedAccount);
                    }
                });

            setOriginalShipToAccountsList(formattedOriginalAccounts);
            setReceivingShipToAccountsList(formattedReceivingAccounts);
        }
    };

    useEffect(() => {
        dispatch({ type: TRADE_IT_BALANCES_RESET });
        dispatch({ type: TRADE_IT_EMAILS_RESET });
    }, []);

    useEffect(() => {
        setSubmitEnabled(false);
        if (products) {
            if (
                products.some((product) => product.quantityToTrade && product.quantityToTrade > 0)
            ) {
                if (
                    products.every(
                        (product) =>
                            !product.quantityToTrade ||
                            (product.quantityToTrade &&
                                product.originalShipToProductionBalance &&
                                product.quantityToTrade <= product.originalShipToProductionBalance)
                    )
                ) {
                    if (
                        selectedOriginalShipTo &&
                        selectedReceivingShipTo &&
                        selectedReceivingShipTo &&
                        originalShipToContactEmail &&
                        receivingShipToContactEmail &&
                        isValidEmail(originalShipToContactEmail) &&
                        isValidEmail(receivingShipToContactEmail)
                    ) {
                        setSubmitEnabled(true);
                    }
                }
            }
        }
    }, [
        products,
        selectedOriginalShipTo,
        selectedReceivingShipTo,
        originalShipToContactEmail,
        receivingShipToContactEmail
    ]);

    useEffect(() => {
        if (selectedAccountId && isLargeCustomerAccount) {
            setSelectedOriginalShipTo(selectedAccountId);
        }
    }, [selectedAccountId, isLargeCustomerAccount]);

    useEffect(() => {
        // handle change selected ship to - future stories
        if (selectedOriginalShipTo) {
            dispatch(selectCustomerAccountId(selectedOriginalShipTo));
        }
    }, [selectedOriginalShipTo]);

    useEffect(() => {
        if (selectedOriginalShipTo && shipToAccounts) {
            populateShipToSelectors(shipToAccounts);
            if (selectedReceivingShipTo) {
                const today = moment().format('MM/DD/YYYY');
                dispatch(
                    getAvailableTradeBalances(
                        selectedOriginalShipTo,
                        selectedReceivingShipTo,
                        today
                    )
                );
            }
        }
    }, [selectedOriginalShipTo, selectedReceivingShipTo, shipToAccounts]);

    useEffect(() => {
        dispatch({ type: TRADE_IT_TO_EMAILS_RESET });
        setReceivingShipToContactEmail(undefined);
    }, [selectedReceivingShipTo]);

    useEffect(() => {
        dispatch({ type: TRAADE_IT_FROM_EMAILS_RESET });
        setOriginalShipToContactEmail(undefined);
    }, [selectedOriginalShipTo]);

    useEffect(() => {
        const requestedProducts = products.filter(
            (product) => !!product.quantityToTrade && product.quantityToTrade > 0
        );
        setCampaignItemsRequested(requestedProducts.some((product) => !!product.campaignTiming));
    }, [products]);

    const finalizeTradeItRequest = () => {
        if (
            selectedOriginalShipTo &&
            selectedReceivingShipTo &&
            originalShipToContactEmail &&
            receivingShipToContactEmail
        ) {
            if (
                impersonationShipTos.length > 0 &&
                impersonationShipTos.some(
                    (impersonationShipTo) =>
                        impersonationShipTo === selectedOriginalShipTo ||
                        impersonationShipTo === selectedReceivingShipTo
                )
            ) {
                setSubmitEnabled(false);
                setShowImpersonationWarning(true);
            } else {
                setSubmitEnabled(false);
                dispatch(
                    submitTradeItRequest(
                        selectedOriginalShipTo,
                        selectedReceivingShipTo,
                        originalShipToContactEmail,
                        receivingShipToContactEmail
                    )
                );
                history.push('/trade-it-confirmation');
            }
        }
    };

    const handleSubmitButtonPressed = () => {
        if (
            selectedOriginalShipTo &&
            selectedReceivingShipTo &&
            originalShipToContactEmail &&
            receivingShipToContactEmail
        ) {
            if (campaignItemsRequested) {
                setShowCampaignModal(true);
            } else {
                finalizeTradeItRequest();
            }
        }
    };

    const handleCampaignModalConfirmation = () => {
        setShowCampaignModal(false);
        finalizeTradeItRequest();
    };

    const handleCampaignModalCancel = () => {
        setShowCampaignModal(false);
    };

    useEffect(() => {
        if (submitted) {
            history.push('/trade-it-confirmation');
        }
    }, [submitted]);

    const handleCancel = () => {
        setOpenCancelModal(false);
        history.push('/');
    };
    const handleBackNavigation = () => {
        history.push('/po-product-planning');
    };

    const onCancel = () => {
        setOpenCancelModal(true);
    };

    const onBack = () => {
        setOpenBackModal(true);
    };

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else if (openCancelModal) {
            setOpenCancelModal(false);
        }
    };

    const footerActions = (
        <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={1}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                    noMarginTop={true}
                />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Grid container item xs={7}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={onBack}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={5} justify="flex-end">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="submit-request-button"
                        className={classes.actionBtn}
                        onClick={() => handleSubmitButtonPressed()}
                        disabled={!submitEnabled}
                    >
                        <Trans i18nKey="submitRequest">Submit Request</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('tradeIt', 'Trade It'),
                description: t('createTradeRequest', 'Create balance trade request'),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.TradeIt}
            restrictToSecurityLevel={SecurityLevel.Edit}
            loading={loading}
        >
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.dashboard}
                data-testid={'trade-it'}
            >
                <Grid data-testid="makeit-date-selector" container item justify="space-between">
                    <Grid container item md={12} className={classes.main}>
                        <Grid container item xs={9}>
                            <Grid item className={classes.stepWrapper}>
                                <Typography component="span" className={classes.stepNum}>
                                    1
                                </Typography>
                                <Typography variant="h3" className={classes.stepTitle}>
                                    <Trans i18nKey="selectYourShipTos">
                                        ${enUS.selectYourShipTos}
                                    </Trans>
                                    :
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={9}>
                            <Grid container item xs={5}>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.selectorTitle}
                                    >
                                        {t('originalShipTo', 'Original Ship To')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={5}>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.selectorTitle}
                                    >
                                        {t('receivingShipTo', 'Receiving Ship To')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={9}>
                            <Grid
                                container
                                item
                                xs={5}
                                className={classes.selector}
                                data-testid="original-ship-to-selector"
                            >
                                <ShipToDropdown
                                    name="originalShipToSelect"
                                    testId="originalShipToSelect"
                                    selectObjects={originalShipToAccountsList}
                                    selected={selectedOriginalShipTo}
                                    updateSelected={setSelectedOriginalShipTo}
                                    showIcons={false}
                                    className={classes.receivingSelector}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={5}
                                className={classes.selector}
                                data-testid="receiving-ship-to-selector"
                            >
                                <ShipToDropdown
                                    name="receivingShipToSelect"
                                    testId="receivingShipToSelect"
                                    selectObjects={receivingShipToAccountsList}
                                    selected={selectedReceivingShipTo}
                                    updateSelected={setSelectedReceivingShipTo}
                                    showIcons={false}
                                    className={classes.receivingSelector}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={9}>
                            <Grid container item xs={5}>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.selectorTitle}
                                    >
                                        {t(
                                            'originalShipToContactEmail',
                                            'Original Ship To Contact Email'
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={5}>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.selectorTitle}
                                    >
                                        {t(
                                            'receivingShipToContactEmail',
                                            'Receiving Ship To Contact Email'
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={9}>
                            <Grid item xs={5}>
                                <EmailSelectorDropdown
                                    name="originalShipToEmailSelector"
                                    testId="original-ship-to-email-selector"
                                    selectObjects={fromEmails}
                                    selected={originalShipToContactEmail}
                                    updateSelected={(email) => setOriginalShipToContactEmail(email)}
                                ></EmailSelectorDropdown>
                            </Grid>
                            <Grid item xs={5}>
                                <EmailSelectorDropdown
                                    name="receivingShipToEmailSelector"
                                    testId="receiving-ship-to-email-selector"
                                    selectObjects={toEmails}
                                    selected={receivingShipToContactEmail}
                                    updateSelected={(email) =>
                                        setReceivingShipToContactEmail(email)
                                    }
                                ></EmailSelectorDropdown>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    className={clsx(classes.stepWrapper, classes.sectionTwo)}
                                >
                                    <Typography component="span" className={classes.stepNum}>
                                        2
                                    </Typography>
                                    <Typography variant="h3" className={classes.stepTitle}>
                                        <Trans i18nKey="selectProductsToTrade">
                                            ${enUS.selectProductsToTrade}
                                        </Trans>
                                        :
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!(selectedOriginalShipTo && selectedReceivingShipTo) && (
                            <Grid container item xs={12}>
                                <Grid item className={classes.makeSelections}>
                                    <Typography>
                                        <Trans i18nKey="makeYourShipToSelections">
                                            Make your selections above to see products eligible for
                                            trades for the Ship Tos you select.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {selectedOriginalShipTo &&
                            selectedReceivingShipTo &&
                            loaded &&
                            products.length === 0 && (
                                <Grid container item xs={12}>
                                    <Grid item className={classes.makeSelections}>
                                        <Typography>
                                            <Trans i18nKey="noTradeItProducts">
                                                No products with available to trade balance from
                                                original ship and mapped to the receiving ship to.
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        {selectedOriginalShipTo &&
                            selectedReceivingShipTo &&
                            loaded &&
                            products.length > 0 && (
                                <Grid container item xs={12}>
                                    <Grid className={classes.tableWrapper}>
                                        <TradeItProductsGrid
                                            originalShipToId={selectedOriginalShipTo}
                                            receivingShipToId={selectedReceivingShipTo}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmationModal
                data-testid="go-back-modal"
                saveProgress={handleBackNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="back">Back</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="goBackConfirm">Are you sure you want to go back?</Trans>
                }
                continueText={<Trans i18nKey="yesGoBack">Yes, Go Back</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBackModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="cancel-modal"
                saveProgress={handleCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
            />
            <TradeItCampaignModal
                open={showCampaignModal}
                onCancel={handleCampaignModalCancel}
                onOk={handleCampaignModalConfirmation}
                data-testid="campaign-modal"
            />
        </ProcessingPageTemplate>
    );
}
