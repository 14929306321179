import * as types from './action-types';
import OrdersService, { ProductType } from '../../utility/services/orders-service';
import {
    DeliveryOrder,
    DeliveryShipment,
    ProductToShip,
    ProductWithPallets
} from '../reducers/shipping-dashboard';
import { Account } from '../reducers/customer-context';
import {
    EditShipment,
    ExpandableLoad,
    NewProductAvailability,
    ProductPalletsByDate,
    ProductPalletTotals
} from '../reducers/edit-shipments';
import moment from 'moment';
import {
    constructCanDescription,
    constructEndDescription,
    productTypeHelper,
    updateDeliveryOrderWithProductInfo
} from '../../utility/helpers/order-helpers';
import { AuthState } from '../reducers/auth';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { getSimpleFormattedTime } from '../../utility/helpers/date-helpers';
import { getLastShipmentDate } from '../../utility/helpers/shipment-helpers';

export const updateProductsToEdit = (productsToEdit: ProductToShip[]) => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_UPDATE_PRODUCTS_TO_EDIT,
            productsToEdit: productsToEdit
        });
    };
};

export const updatePalletTotals = (productPalletTotals: ProductPalletTotals[]) => {
    productPalletTotals.forEach((product) => {
        if (!product.originalPalletTotal) {
            product.originalPalletTotal = product.palletTotal;
        }
    });
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_UPDATE_PALLET_TOTALS,
            productPalletTotals: productPalletTotals
        });
    };
};

export const updatePalletsByDay = (palletTotalsByDay: ProductPalletsByDate[]) => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_UPDATE_PALLETS_BY_DAY,
            palletTotalsByDay: palletTotalsByDay
        });
    };
};

export const updateDeliveryToEdit = (deliveryToEdit: EditShipment) => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_SUMMARY_UPDATE_ORDER,
            deliveryToEdit: deliveryToEdit
        });
    };
};

export const updateEditedShipment = (deliveryToEdit: EditShipment) => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_SHIPMENT_EDITED,
            deliveryToEdit: deliveryToEdit
        });
    };
};

export const updateCancelledShipment = (deliveryToEdit: EditShipment) => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_SHIPMENT_CANCELLED,
            deliveryToEdit: deliveryToEdit
        });
    };
};

export const clearEditShipmentsSummary = () => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_CLEAR_STATE,
            deliveryToEdit: undefined
        });
    };
};

export const updateLastEditedShipmentId = (shipmentId: string) => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_LAST_EDITED_SHIPMENT_ID,
            shipmentId
        });
    };
};

export const updateDeliveryDate = (editedShipment: EditShipment, shipment: DeliveryShipment) => {
    return (dispatch, getState) => {
        const shipToId = editedShipment.deliveryOrder.shipToId!.toString();
        const deliveryDateFormatted = moment(shipment.updatedDeliveryDate).format('MM/DD/YYYY');

        getProductAvailability(
            dispatch,
            getState,
            shipToId,
            deliveryDateFormatted,
            shipment,
            editedShipment
        ).then(
            ([products, newAvailability]: [
                Array<ProductWithPallets>,
                {
                    [key: string]: Array<NewProductAvailability>;
                }
            ]) => {
                const productsToEdit = getProductsToEdit(shipToId, deliveryDateFormatted, [
                    products,
                    newAvailability
                ]);
                dispatch({
                    type: types.EDIT_SHIPMENTS_UPDATE_DELIVERY_DATE,
                    deliveryToEdit: editedShipment,
                    productsToEdit
                });
            }
        );
    };
};

export const loadEditShipmentsSummary = (deliveryOrderId: string, shipToId) => {
    return (dispatch, getState) => {
        dispatch({ type: types.EDIT_SHIPMENTS_SUMMARY_LOADING });
        const state = getState();
        const shipTo: Account = state.customerContext.shipToAccounts.filter(
            (account) => account.accountId === shipToId
        );
        const transportType = shipTo[0].modeOfTransport;

        OrdersService.getDeliveryOrder(state.auth.accessToken, deliveryOrderId)
            .then((response) => {
                let order: DeliveryOrder = response.data;
                let deliveryToEdit = {
                    deliveryOrder: order,
                    shipToAccount: shipTo,
                    modeOfTransport: transportType
                };
                const auth: AuthState = getState().auth;
                const filteredShipToIds = filterShipToIdsByPermission(
                    auth,
                    [order.shipToId as number],
                    Activity.NewOpenDeliveryOrders,
                    SecurityLevel.Edit
                );
                if (deliveryToEdit.deliveryOrder?.shipments) {
                    deliveryToEdit.deliveryOrder?.shipments.forEach((shipment) => {
                        shipment.deliveryTime = getSimpleFormattedTime(
                            '',
                            shipment.deliveryDateTime
                        );
                    });
                }

                OrdersService.getDeliveryOrderProducts(state.auth.accessToken, filteredShipToIds)
                    .then((response) => {
                        const availableProducts = response.data.products;
                        order = updateDeliveryOrderWithProductInfo(order, availableProducts);
                        dispatch({
                            type: types.EDIT_SHIPMENTS_SUMMARY_LOADED,
                            deliveryToEdit: deliveryToEdit
                        });
                    })
                    .catch((error) => {
                        dispatch({
                            type: types.EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR,
                            error: 'Error loading products for delivery order with: ' + error
                        });
                    });
            })
            .catch((error) => {
                dispatch({
                    type: types.EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR,
                    error: 'Error loading delivery order with: ' + error
                });
            });
    };
};

export const clearProductsToEdit = () => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_CLEAR_PRODUCTS_TO_EDIT
        });
    };
};

const getUpdateProductAvailabilityStarted = () => ({
    type: types.EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_LOADING
});

const getUpdateProductAvailabilitySuccess = (newAvailability) => ({
    type: types.EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_SUCCESS,
    newAvailability
});

const getUpdateProductAvailabilityFailed = (error) => ({
    type: types.EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_ERROR,
    error
});

/**
 *
 * @param dispatch
 * @param getState
 * @param shipToId
 * @param newDeliveryDate Optional.  When user changes delivery date.  Formatted as MM/DD/YYYY
 */
const getProductAvailability = (
    dispatch,
    getState,
    shipToId: string,
    newDeliveryDate: string,
    editShipment: DeliveryShipment,
    editedShipment: EditShipment
) => {
    dispatch(getUpdateProductAvailabilityStarted());
    const state = getState();
    const account = state.customerContext.shipToAccounts.find(
        (account) => account.accountId === shipToId
    );

    const deliveryOrder = editedShipment.deliveryOrder;
    const deliveryOrderId = deliveryOrder.deliveryOrderId;
    let lastShipmentDate = getLastShipmentDate(deliveryOrder);
    if (newDeliveryDate) {
        const newDeliveryDateMoment = moment(newDeliveryDate, 'MM/DD/YYYY');
        if (newDeliveryDateMoment.isAfter(lastShipmentDate)) {
            lastShipmentDate = newDeliveryDateMoment;
        }
    }
    //
    // Set the requested date to one day after the last shipment date.
    //
    // This will coerce the endpoint to return all desired availability
    // within the response.
    //
    const requestedDate = moment(lastShipmentDate, 'MM/DD/YYYY').add(1, 'day');
    const requestedDateFormatted = requestedDate.format('MM/DD/YYYY');
    const previousNDays = requestedDate.diff(moment(), 'days') + 1;

    const accessToken = getState().auth.accessToken;

    return OrdersService.getProductsNew(accessToken, {
        ShipToIds: [shipToId],
        WithAvailablePallets: true,
        IntervalLengthDays: 1,
        RequestedDate: [requestedDateFormatted],
        TotalsForPreviousNDays: previousNDays,
        PaymentTerms: [account.paymentTerms],
        ExcludeDeliveryOrderId: [deliveryOrderId]
    })
        .then((response) => {
            const products = response.data.products;
            const days = previousNDays;

            // Access the array of previousPalletTotals in a backwards manner, matching the correct
            // day with its corresponding availability in the list.
            const newAvailabilityExcludingDo: {
                [key: string]: Array<NewProductAvailability>;
            } = {};
            // For each day, backwards from the requested date to today.
            for (let i = 0; i < days; i++) {
                const dateFormatted = requestedDate.subtract(1, 'day').format('MM/DD/YYYY');
                newAvailabilityExcludingDo[dateFormatted] = [];

                // Select the pallets available from each product's previousPalletTotals matching
                // the date.
                for (let j = 0; j < products.length; j++) {
                    const productSku = products[j].productSku!;
                    const palletsAvailable =
                        products[j].previousPalletTotals![
                            products[j].previousPalletTotals!.length - (i + 1)
                        ];
                    newAvailabilityExcludingDo[dateFormatted].push({
                        productSku,
                        palletsAvailable
                    });
                }
            }

            dispatch({
                type: types.EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_EXCLUDE_DO,
                newAvailabilityExcludingDo
            });

            const newAvailability: {
                [key: string]: Array<NewProductAvailability>;
            } = JSON.parse(JSON.stringify(newAvailabilityExcludingDo));

            //
            // For each shipment, subtract held inventory from list created above.
            //
            if (deliveryOrder.shipments && deliveryOrder.shipments.length) {
                deliveryOrder.shipments
                    // Filter out the shipment currently being edited.
                    .filter(
                        (shipment) =>
                            !shipment.cancelled && shipment.shipmentId !== editShipment.shipmentId
                    )
                    // Add the edited shipment to use user's changes.
                    .concat([editShipment])
                    .forEach((shipment) => {
                        if (shipment.loads) {
                            shipment.loads.forEach((load) => {
                                //
                                // For each load, subtract the pallet quantity from the pallets available
                                // for every date within the list.
                                //
                                for (let date in newAvailability) {
                                    const availabilityEntry = newAvailability[date];
                                    const product = availabilityEntry.find(
                                        (product) => product.productSku === load.productSku
                                    );
                                    if (
                                        product &&
                                        product.palletsAvailable > 0 &&
                                        product.palletsAvailable < load.palletQuantity
                                    ) {
                                        product.palletsAvailable =
                                            product.palletsAvailable +
                                            (load.originalPalletQuantity ?? 0) -
                                            load.palletQuantity;
                                    } else if (
                                        product &&
                                        product.palletsAvailable > 0 &&
                                        product.palletsAvailable >= load.palletQuantity
                                    ) {
                                        product.palletsAvailable =
                                            product.palletsAvailable - load.palletQuantity;
                                    } else if (
                                        product &&
                                        product?.palletsAvailable === 0 &&
                                        load.availablePallets
                                    ) {
                                        product.palletsAvailable = load.availablePallets;
                                    }
                                }
                            });
                        }
                    });
            }

            dispatch(getUpdateProductAvailabilitySuccess(newAvailability));

            return [products, newAvailability];
        })
        .catch((err) => dispatch(getUpdateProductAvailabilityFailed(err)));
};

export function getProductsToEdit(
    shipToId: string,
    deliveryDate: string,
    [products, newAvailability]: [
        Array<ProductWithPallets>,
        {
            [key: string]: Array<NewProductAvailability>;
        }
    ]
) {
    const availabilityForShipmentDate = newAvailability[deliveryDate];
    const productsToEdit: Array<ProductWithPallets> = [];

    availabilityForShipmentDate.forEach((curr) => {
        const productSku = curr.productSku;
        const product = products.find((product) => product.productSku === productSku);
        if (product) {
            const productToEdit = { ...product };
            const availablePallets = curr.palletsAvailable;
            const canBottleDesc = constructCanDescription(productToEdit);
            const endDesc = constructEndDescription(productToEdit);
            const isEndType = productTypeHelper(productToEdit.type) === ProductType.Ends;

            productToEdit.description = isEndType ? endDesc : canBottleDesc;
            productToEdit.availableItemsPerPallet = productToEdit?.quantityPerPallet!;
            productToEdit.availablePallets = availablePallets;

            if (productToEdit.destinations) {
                productToEdit.destinations?.forEach((destination) => {
                    const destinationShipToString = destination.shipToId.toString();
                    const shipToString = shipToId.toString();

                    if (destinationShipToString === shipToString) {
                        productToEdit.customerProductId = destination.customerProductId;
                        productToEdit.customerProductName = destination.customerProductName;
                    }
                });
            }
            productToEdit.displayId = productToEdit.customerProductId
                ? productToEdit.customerProductId
                : productToEdit.productSku;
            productToEdit.displayName = productToEdit.customerProductName
                ? productToEdit.customerProductName
                : productToEdit.name;

            productsToEdit.push(productToEdit);
        }
    });

    return productsToEdit;
}

/**
 *
 * @param shipToId
 * @param deliveryDate The shipment's delivery date.  Formatted as MM/DD/YYYY.
 */
export const loadShipmentProducts = (
    shipToId: string,
    deliveryDate: string,
    shipment: DeliveryShipment
) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_PRODUCTS_LOADING
        });

        const state = getState();

        const editedShipment: EditShipment = state.editShipments.deliveryToEdit;

        getProductAvailability(
            dispatch,
            getState,
            shipToId,
            deliveryDate,
            shipment,
            editedShipment
        ).then(
            ([products, newAvailability]: [
                Array<ProductWithPallets>,
                {
                    [key: string]: Array<NewProductAvailability>;
                }
            ]) => {
                const productsToEdit = getProductsToEdit(shipToId, deliveryDate, [
                    products,
                    newAvailability
                ]);

                if (productsToEdit.length) {
                    dispatch({
                        type: types.EDIT_SHIPMENTS_PRODUCTS_LOADED,
                        productsToEdit: productsToEdit
                    });
                } else {
                    dispatch({
                        type: types.EDIT_SHIPMENTS_NO_PRODUCTS,
                        productsToEdit: []
                    });
                }
            }
        );
    };
};

export const updateExpandableLoad = (expandableLoad: ExpandableLoad) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().editShipments.expandableLoads;
        const updatedExpandableLoads = expandableLoads.filter(
            (load) => load.loadId !== expandableLoad.loadId
        );
        dispatch({
            type: types.EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads, expandableLoad]
        });
    };
};

export const resetExpandableLoads = () => {
    return (dispatch) => {
        dispatch({
            type: types.EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: []
        });
    };
};

export const changeShipmentExpandableLoads = (
    state: any,
    shipmentId: string,
    expanded: boolean
) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().editShipments.expandableLoads;
        const updatedExpandableLoads = expandableLoads
            .filter((load) => load.shipmentId === shipmentId)
            .map((load) => {
                return { ...load, expanded: expanded };
            });
        dispatch({
            type: types.EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads]
        });
    };
};

export const changeAllExpandableLoads = (expanded: boolean) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().editShipments.expandableLoads;
        const updatedExpandableLoads = expandableLoads.map((load) => {
            return { ...load, expanded: expanded };
        });
        dispatch({
            type: types.EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads]
        });
    };
};
