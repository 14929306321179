import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Typography, Grid, Button, makeStyles } from '@material-ui/core';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import InformationalCard from '../../reusable/molecules/InformationalCard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { OrderingState } from '../../../store/reducers/ordering-dashboard';
import { resetOrderingProcess, loadOrder } from '../../../store/actions/ordering-dashboard';

import ShipmentSummaryCard from './components/ShipmentSummaryCard';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        borderRadius: 'unset'
    },
    header: {
        padding: '1.2em'
    },
    shipmentsContainer: {}
}));

export default function OrderDetails() {
    const { id } = useParams<DefaultQueryParams>();

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { loadingOrder, loadedOrder, shipmentsOrder, deliveryOrder, orderInformation } =
        useTypedSelector<OrderingState>((state) => state.orderingDashboard);

    const handleClose = () => {
        if (id) {
            history.goBack();
        } else {
            dispatch(resetOrderingProcess());
            history.push('/dashboard');
        }
    };

    /** Load Order from API if ID is present */
    useEffect(() => {
        if (id && !deliveryOrder && !loadingOrder && !loadedOrder) {
            dispatch(loadOrder(id));
        }
    }, [dispatch, id, deliveryOrder, loadingOrder, loadedOrder]);

    const footerActions = (
        <>
            <Grid container item xs={4} justify="flex-end" spacing={4}>
                <InformationalCard />
                <Grid item alignContent="center">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="close-btn"
                        onClick={handleClose}
                    >
                        <Trans i18nKey="close">Close</Trans>
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            actionFooter={{
                footerAction: footerActions,
                justify: 'flex-end',
                sticky: true
            }}
            banner={{
                header: t('orderSummary', 'Order Summary'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.CreateOrder}
            loading={loadingOrder}
        >
            <Grid container className={classes.header} justify="space-between" spacing={2}>
                <Grid container item xs={4} alignItems="center" justify="flex-start">
                    <Typography variant="h4">
                        Delivery Order Number {deliveryOrder?.deliveryOrderNumber || 'Pending'}
                    </Typography>
                </Grid>
                <Grid container item xs={4} alignItems="center" justify="center">
                    <Typography color="primary" variant="h3">
                        ORDER SUMMARY
                    </Typography>
                </Grid>
                <Grid container item xs={4} alignItems="center" justify="flex-end">
                    {/**
                     * // TODO: Implement the Print and Download CSV functionality
                     * <OrderConfirmationLinks deliveryOrder={{}} shipToAddress="" shipToName="" />
                     */}
                </Grid>
            </Grid>
            <Grid container className={classes.shipmentsContainer} spacing={2}>
                {shipmentsOrder &&
                    shipmentsOrder.length > 0 &&
                    shipmentsOrder.map((shipment, index) => (
                        <ShipmentSummaryCard
                            orderInformation={orderInformation}
                            shipment={shipment}
                            index={index}
                            total={shipmentsOrder.length}
                        />
                    ))}
            </Grid>
        </ProcessingPageTemplate>
    );
}
