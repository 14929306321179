import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField,
    InputAdornment,
    Select,
    MenuItem
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';
import OrderProductCard from '../../../reusable/molecules/OrderProductCard';
import { makeStyles } from '@material-ui/core/styles';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import { Trans } from 'react-i18next';
import { isProductCanType, isProductEndType } from '../../../../utility/helpers/order-helpers';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '24px',
        gap: '16px',
        overflow: 'hidden'
    },
    filterContainer: {
        flexShrink: 0,
        marginBottom: '16px'
    },
    filterButton: {
        borderRadius: 0
    },
    button: {
        borderBottom: 'none',
        color: 'gray'
    },
    activeButton: {
        borderBottom: '3px solid #009BBC',
        color: '#009BBC'
    },
    rightAlign: {
        textAlign: 'right'
    },
    redText: {
        color: 'red',
        fontSize: '11px'
    },
    boldText: {
        fontSize: '9px',
        fontWeight: 'bold'
    },
    productContainer: {
        flex: 1,
        overflowY: 'auto',
        border: '1px solid #eee',
        borderRadius: '4px',
        padding: '16px',
        marginRight: '-24px',
        marginLeft: '-24px',
        textAlign: 'left'
    },
    columnNames: {
        color: 'gray',
        fontWeight: 'bold',
        fontSize: '9px',
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    filterInput: {
        width: '100%',
        marginTop: '4px',
        marginBottom: '4px',
        fontSize: '8px'
    },
    filterInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    selectInput: {
        height: '38px',
        width: '100%',
        marginTop: '4px',
        marginBottom: '4px',
        fontSize: '8px'
    },
    labelColumn: {
        alignItems: 'flex-end'
    },
    palletLabel: {
        textAlign: 'left',
        marginBottom: '45px',
        color: 'gray',
        fontWeight: 'bold',
        fontSize: '9px',
        textTransform: 'uppercase'
    },
    hidden: {
        display: 'none'
    }
}));

interface OrderProductGridProps {
    products: ProductToShip[];
    IsAnyShipmentEditing: boolean;
    editingType?: string;
    onAddProductToShipment: (product: ProductToShip) => void;
}

const OrderProductGrid: React.FC<OrderProductGridProps> = ({
    products,
    IsAnyShipmentEditing,
    editingType,
    onAddProductToShipment
}) => {
    const classes = useStyles();

    const [typeFilter, setTypeFilter] = useState('CAN');
    const [filters, setFilters] = useState({
        id: '',
        productName: '',
        coating: '',
        size: '',
        palletsAvailable: ''
    });
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [productContainerHeight, setProductContainerHeight] = useState<number>(0);

    const productContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const filtered = products.filter((product) => {
            const matchesId = filters.id
                ? (product.productSku ?? '').toUpperCase().includes(filters.id.toUpperCase()) ||
                  (product.productId ?? '')
                      .toString()
                      .toUpperCase()
                      .includes(filters.id.toUpperCase()) ||
                  (product.graphicId ?? '').toUpperCase().includes(filters.id.toUpperCase())
                : true;

            const matchesProductName = filters.productName
                ? (product.displayName ?? '')
                      .toUpperCase()
                      .includes(filters.productName.toUpperCase()) ||
                  (product.description ?? '')
                      .toUpperCase()
                      .includes(filters.productName.toUpperCase())
                : true;

            const matchesCoating = filters.coating
                ? (product.coating ?? '').toUpperCase().includes(filters.coating.toUpperCase())
                : true;

            const matchesSize = filters.size
                ? (product.size ?? '')
                      .toString()
                      .toUpperCase()
                      .includes(filters.size.toString().toUpperCase())
                : true;

            const matchesPalletsAvailable = filters.palletsAvailable
                ? (product.availablePallets ?? '')
                      .toString()
                      .toUpperCase()
                      .includes(filters.palletsAvailable.toUpperCase())
                : true;

            const matchesType =
                (typeFilter === 'CAN' && isProductCanType(product.type)) ||
                (typeFilter === 'END' && isProductEndType(product.type));

            return (
                matchesId &&
                matchesProductName &&
                matchesCoating &&
                matchesSize &&
                matchesPalletsAvailable &&
                matchesType
            );
        });

        setFilteredProducts(filtered);
    }, [filters, products, typeFilter]);

    useEffect(() => {
        if (IsAnyShipmentEditing && editingType) {
            setTypeFilter(editingType);
        }
    }, [IsAnyShipmentEditing, editingType]);

    useLayoutEffect(() => {
        setProductContainerHeight((productContainerRef?.current?.offsetTop || 0) + 36);
    }, []);

    const handleFilterChange = (newFilter: string) => {
        setTypeFilter(newFilter);
    };

    const handleInputChange = (field: string, value: string) => {
        setFilters({
            ...filters,
            [field]: value
        });
    };

    const handleAddProduct = (product: ProductToShip) => {
        onAddProductToShipment(product);
    };

    const uniqueSizes = useMemo(() => {
        return Array.from(
            new Set(
                products
                    .filter(
                        (product) =>
                            (typeFilter === 'CAN' && isProductCanType(product.type)) ||
                            (typeFilter === 'END' && isProductEndType(product.type))
                    )
                    .map((product) => product.size)
            )
        );
    }, [products, typeFilter]);

    return (
        <div className={classes.container}>
            <div className={classes.filterContainer}>
                <Grid container spacing={2} className={classes.filterContainer}>
                    <Grid item>
                        <Button
                            onClick={() => handleFilterChange('CAN')}
                            className={clsx(classes.filterButton, {
                                [classes.activeButton]: typeFilter === 'CAN',
                                [classes.button]: typeFilter !== 'CAN'
                            })}
                        >
                            <Trans i18nKey="canBottleCategory">Cans/Bottles</Trans>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => handleFilterChange('END')}
                            className={clsx(classes.filterButton, {
                                [classes.activeButton]: typeFilter === 'END',
                                [classes.button]: typeFilter !== 'END'
                            })}
                        >
                            <Trans i18nKey="endsClosuresCategory">Ends/Closures</Trans>
                        </Button>
                    </Grid>
                    <Grid item xs className={classes.rightAlign}>
                        <Typography variant="body2" className={classes.redText}>
                            <Trans i18nKey="sizeProductWarning">
                                Remember to group like products and sizes <br /> while planning your
                                shipments.
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.filterContainer}>
                <Grid container spacing={2} className={classes.labelColumn}>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="customProductGraphicId">
                                CUSTOM ID / PRODUCT ID / GRAPHIC ID
                            </Trans>
                        </Typography>
                        <div className={classes.filterInputContainer}>
                            <TextField
                                className={classes.filterInput}
                                value={filters.id}
                                onChange={(e) => handleInputChange('id', e.target.value)}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search
                                                fontSize="inherit"
                                                style={{ fontSize: '0.75rem' }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="productNameDescription">
                                PRODUCT NAME / DESCRIPTION
                            </Trans>
                        </Typography>
                        <div className={classes.filterInputContainer}>
                            <TextField
                                className={classes.filterInput}
                                value={filters.productName}
                                onChange={(e) => handleInputChange('productName', e.target.value)}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search
                                                fontSize="inherit"
                                                style={{ fontSize: '0.75rem' }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="coating">COATING</Trans>
                        </Typography>
                        <div className={classes.filterInputContainer}>
                            <TextField
                                className={classes.filterInput}
                                value={filters.coating}
                                onChange={(e) => handleInputChange('coating', e.target.value)}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search
                                                fontSize="inherit"
                                                style={{ fontSize: '0.75rem' }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="size">SIZE</Trans>
                        </Typography>
                        <div className={classes.filterInputContainer}>
                            <Select
                                className={classes.selectInput}
                                value={filters.size}
                                onChange={(e) =>
                                    handleInputChange('size', e.target.value as string)
                                }
                                variant="outlined"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {uniqueSizes.map((size, index) => (
                                    <MenuItem key={index} value={size}>
                                        {size}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={2} className={`${classes.labelColumn} ${classes.hidden}`}>
                        <Typography variant="body1" className={classes.columnNames}>
                            <Trans i18nKey="palletsAvailable">PALLETS AVAILABLE</Trans>
                        </Typography>
                        <div className={classes.filterInputContainer}>
                            <TextField
                                className={classes.filterInput}
                                value={filters.palletsAvailable}
                                onChange={(e) =>
                                    handleInputChange('palletsAvailable', e.target.value)
                                }
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search
                                                fontSize="inherit"
                                                style={{ fontSize: '0.75rem' }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2} className={classes.labelColumn}>
                        <Typography variant="body1" className={classes.palletLabel}>
                            <Trans i18nKey="addPalletsToOrder">ADD PALLETS TO ORDER</Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <div
                className={classes.productContainer}
                style={{ maxHeight: `calc(100vh - ${productContainerHeight}px)` }}
                ref={productContainerRef}
            >
                <Grid container spacing={2}>
                    {filteredProducts.map((product, index) => (
                        <Grid item xs={12} key={index}>
                            <OrderProductCard
                                product={product}
                                IsAnyShipmentEditing={IsAnyShipmentEditing}
                                onAddProduct={() => handleAddProduct(product)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default OrderProductGrid;
