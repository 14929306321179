import { OrderProductTableRow } from '../../store/reducers/orders-dashboard';
import {
    ProductionOrder,
    ProductionOrders,
    ProductionOrderRequest,
    ProductionOrderLine,
    MakeItProductsRequest,
    LinkedDeliveryOrder
} from '../../store/reducers/makeit-dashboard';
import Axios, { AxiosResponse } from 'axios';
import { createAPIQueryParam } from '../helpers/query-helpers';
import { Invoice, ProductionOrderSummaryResponse } from '../../store/reducers/make-it-summary';
import { convertToMT, convertToMTSetMidNight } from '../helpers/date-helpers';
import moment from 'moment';
import {
    ProductWithPallets,
    DeliveryOrderRequest,
    DeliveryShipment,
    ShipItProductsRequest,
    DeliveryOrder,
    DeliveryOrderLinkedProductionOrder,
    ProductToShip
} from '../../store/reducers/shipping-dashboard';
import { Orders as DeliveryOrders } from '../../store/reducers/shipping-summary';
import { LinkedProductionOrderRequest } from '../../store/reducers/product-planning';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType
} from '../../utility/helpers/order-helpers';
import { BulkUploadTemplateRequest, BulkUploadOrder } from '../../store/reducers/bulk-upload';
import { MakeItBulkOrder } from '../../store/reducers/makeit-bulk-upload';
import { getOrderLines, getRequestedDate } from '../helpers/production-order-helpers';
import {
    ForecastStatus,
    MakeItBulkATMLineItem,
    MakeItBulkATMOrderRequest
} from '../../store/reducers/makeit-bulk-atm';
import { PaymentTerms } from '../../store/reducers/customer-context';
import { ProductionOrderDraft } from '../../store/reducers/make-it-drafts';
import { createAxiosHeader } from '../helpers/axios-helpers';
import { TradeItRequest } from '../../store/reducers/trade-it';
import { ScrapItRequestDetails } from '../../store/reducers/scrap-it-details';
import { OrderInformation } from '../../store/reducers/ordering-dashboard';

export enum UpdateStatus {
    IsLocked = 'IsLocked',
    NotFound = 'NotFound',
    Updated = 'Updated',
    BadRequest = 'BadRequest'
}

export enum Order_Status {
    Draft = 'DRFT',
    Submit = 'SUBMIT',
    Reject = 'REJC',
    Accounts_Payable = 'IN_AP',
    Payment_Delinquent = 'PMT_D',
    Payment_Remitted = 'PMT_R',
    Confirmed = 'CONF',
    Shp_C = 'SHP_C',
    Open = 'OPEN',
    Completed = 'COMP',
    Closed = 'CLSD',
    Cancelled = 'CANC',
    Unknown = 'UNKNOWN',
    CancelledPending = 'CANC_P',
    EditedPending = 'EDIT_P',
    Cancelled_Suppress = 'CANC_SUP',
    Invoice_Generated = 'INV_G',
    Review = 'REVIEW'
}

export enum AgedStatus {
    Aged = 'Aged',
    Aging = 'Aging',
    NotAging = 'NotAging'
}

export enum OrderType {
    Delivery = 'DELIVERY',
    Production = 'PRODUCTION',
    ProductionATM = 'PRODUCTION_ATM',
    Forecast = 'FORECAST'
}

export enum ProductStatus {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE'
}

export enum ProductType {
    Cans = 'CAN',
    Bottles = 'BOTTLE',
    Ends = 'END',
    Caps = 'CAP'
}

export enum QuantityType {
    Pallets = 'PALLETS',
    Eaches = 'EACHES'
}

export enum RowsOrColumns {
    Rows = 'ROWS',
    Columns = 'COLUMNS'
}

export interface BulkProductRequest {
    shipToIds: number[];
    productionOrderIds?: number[];
    types?: string[];
    usedWithinNMonths?: number;
    withAvailablePallets?: boolean;
    requestedDate?: string;
    totalsForPreviousNDays?: number;
    intervalLengthDays?: number;
    excludeDeliveryOrderId?: number;
    activeOnly?: boolean;
}

interface ProductionOrdersLookup {
    productionOrderIds?: number[];
    shipToIds?: number[];
    statuses?: Order_Status[];
    includeLastShipmentData?: boolean;
    invoiceStatuses?: Order_Status[];
}

export interface MakeItBulkUploadProductionOrdersRequest {
    shipToIds: number[];
    intervalStartWeek: string[];
    intervalFinalWeek: string[];
    includeAllOrders: boolean;
}

export interface GetProductionOrdersResponse {
    productionOrderId: number;
    productionOrderNumber?: number;
    customerProductionOrderId?: string;
    status: Order_Status;
    isLocked: boolean;
    submittedDate?: string;
    submittedBy?: string;
    createdBy?: string;
    updateDate?: string;
    originalProductionOrderNumber?: number;
    canQuantityPallets: number;
    canQuantitySKUs: number;
    endQuantityPallets: number;
    endQuantitySKUs: number;
    canQuantityEaches?: number;
    endQuantityEaches?: number;
    totalOrderedQuantity: number;
    totalOrderSKUs: number;
    availableShipToQuantity: number;
    availableToShipSKUs: number;
    atmWeekStart?: string;
    lines?: Array<MakeItBulkATMLineItem>;
    invoice: Invoice;
    updateStatus: UpdateStatus;
    lastShipmentDate?: string;
    shipToId: number;
}
interface ProductionOrdersAtmLookup {
    shipToIds?: number[];
    intervalStartWeek?: string[];
    intervalFinalWeek?: string[];
    statuses?: Order_Status[];
}

interface ForecastOrdersLookup {
    shipToIds?: number[];
}

interface DeliveryOrdersLookup {
    shipToIds?: number[];
    statuses?: Order_Status[];
}

export interface GetDraftsRequest {
    shipToIds?: string[];
}
export interface ProductResponse {
    productSku: string;
    productId: number;
    shipToIds?: Array<number>;
    graphicId: string;
    graphicVersion: string;
    name: string;
    size: number;
    unit: string;
    shape: string;
    neckDiameter: string;
    endProfile: string;
    shellColor: string;
    tabColor: string;
    coating: string;
    status: string;
    type: string;
    leadTime: string;
    quantityPerPallet: number;
    availablePallets: number;
    lastOrderDate: string;
    previousPalletTotals: Array<number>;
    previousProductionBalancePalletTotals: Array<number>;
    previousPendingPaymentPalletTotals: [
        {
            bucketStartDate: string;
            bucketEndDate: string;
            bucketQuantity: number;
            firstRequestedDateInBucket: string;
        }
    ];
    previousShipmentTotals: Array<number>;
    destinations: [
        {
            shipToId: number;
            customerProductId: string;
            customerProductName: string;
        }
    ];
}

export interface DashboardRequest {
    shipToId: string;
    paymentTerms?: string;
    orderedPriorTo?: string;
}

export interface MakeItOrderATMCreateDraftRequest {
    createDraft: {
        name: string;
    };
    productionOrders: MakeItBulkATMOrderRequest[];
}

export interface MakeItOrderATMUpdateDraftRequest {
    updateDraft: {
        name: string;
        draftId: number;
    };
    productionOrders: MakeItBulkATMOrderRequest[];
}

export interface ScrapItRequestResponseLines extends ProductToShip {
    scrapRequestLineId: number;
    scrapRequestId: number;
    requestedQuantity: number;
    quantityUnits: QuantityType;
    status: Order_Status;
    productSku: string;
    approvedAmount: number;
}

export interface ScrapItRequestResponse {
    scrapRequestId: number;
    requestedBy: string;
    accountId: number;
    submittedDate: string;
    requestLines: ScrapItRequestResponseLines[];
}

export interface UpdateProductionOrderRequest {
    shipToId?: number;
    region?: string;
    productionOrderId?: number;
    productionOrderNumber?: number;
    customerProductionOrderId?: number;
    company?: string;
    paymentTerms?: PaymentTerms;
    orderStatus?: string;
    submittedDate?: Date;
    submittedBy?: string;
    createdBy?: string;
    lines?: UpdateProductionOrderLine[];
}

export interface UpdateProductionOrderLine {
    shipToId?: number;
    shortProductId?: string;
    status?: string;
    palletQuantity?: number;
    requestedDate?: string;
    splitOrder?: boolean;
    productSku?: string;
    forecastStatus?: ForecastStatus;
}

export interface UpdateProductionOrderResponse {
    orders: GetProductionOrdersResponse[];
}

export interface UpdateProductInformationRequest {
    shipToId?: number;
    productId?: string;
    newProductId: string;
    newProductName: string;
}

export interface AgedProductionOrder {
    agedOrder: GetProductionOrdersResponse;
    agedQuantity: number;
    agedStatus: AgedStatus;
}

export interface AgedProductsResponse {
    productSku: string;
    agedOrders: AgedProductionOrder[];
    skuAgedQuantity: number;
    daysAged: number;
}

export interface ProductSkusResponse {
    productSkus: AgedProductsResponse[];
    palletQuantity: number;
}

export interface OrderDraft {
    orders: DeliveryOrder[];
}

export interface CreateDraftName {
    name: string;
}
export interface CreateOrderDraftRequest {
    createDraft: CreateDraftName;
    orders: DeliveryOrder[];
}

export default class OrdersService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static createMultipartConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' }
    });

    // -- MAKE IT DASHBOARD & CONFIG -- //
    static getMakeItProducts = (accessToken: string, requestObject: MakeItProductsRequest) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/products`;
        const urlContent = createAPIQueryParam(requestObject);
        const requestUrl = baseUrl + urlContent;

        return Axios.get<{ products: OrderProductTableRow[] }>(
            requestUrl,
            OrdersService.createConfig(accessToken)
        );
    };

    static getBulkProducts = (accessToken: string, requestObject: BulkProductRequest) => {
        const requestUrl = `${process.env.REACT_APP_ORDER_API}/products/post-request`;

        return Axios.post<{ products: OrderProductTableRow[] }>(
            requestUrl,
            requestObject,
            OrdersService.createConfig(accessToken)
        );
    };

    static saveDraftMakeItOrder = (order: ProductionOrder, accessToken: string) => {
        // Update existing draft order
        if (order.productionOrderId) {
            return Axios.put(
                `${process.env.REACT_APP_ORDER_API}/productionOrders`,
                order,
                OrdersService.createConfig(accessToken)
            );
        } else {
            // Create draft order
            order.status = Order_Status.Draft;
            return Axios.post(
                `${process.env.REACT_APP_ORDER_API}/productionOrders`,
                order,
                OrdersService.createConfig(accessToken)
            );
        }
    };

    static updateMakeItOrder = (order: ProductionOrder, accessToken: string) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/productionOrders`,
            order,
            OrdersService.createConfig(accessToken)
        );
    };

    static submitDraftAsMakeItOrder = (order: ProductionOrder, accessToken: string) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/from-draft`,
            order,
            OrdersService.createConfig(accessToken)
        );
    };

    static createMakeItOrderlines = (productInformation: any) => {
        let orderLines = [] as ProductionOrderLine[];
        productInformation.forEach((product) => {
            const canBottleDesc = constructCanDescription(product);
            const endDesc = constructEndDescription(product);
            const isEndType = isProductEndType(product.type);
            const productDescription = isEndType ? endDesc : canBottleDesc;
            const graphicIdAndVersion =
                product.graphicId && product.graphicVersion
                    ? product.graphicId + '-' + product.graphicVersion
                    : '';
            if (
                product.childLines &&
                product.childLines.length > 0 &&
                (!product.subRows || product.subRows.length === 0)
            ) {
                let updatedLines: ProductionOrderLine[] = getOrderLines(
                    product,
                    product.childLines,
                    graphicIdAndVersion,
                    productDescription
                );
                updatedLines.forEach((line) => {
                    orderLines.push(line);
                });
            } else if (!product.subRows || product.subRows.length === 0) {
                const quantity = product.quantity ? product.quantity : product.palletQuantity;
                const requestedDateInformation = getRequestedDate(product);

                orderLines.push({
                    ...product,
                    palletQuantity: quantity,
                    splitOrder: false,
                    graphicIdAndVersion: graphicIdAndVersion,
                    description: productDescription,
                    requestedDate: requestedDateInformation.requestedDate,
                    invalidRequestedDate: requestedDateInformation.invalidRequestedDate
                });
            } else if (product.subRows && product.subRows.length > 0) {
                let updatedLines: ProductionOrderLine[] = getOrderLines(
                    product,
                    product.subRows,
                    graphicIdAndVersion,
                    productDescription
                );
                updatedLines.forEach((line) => {
                    orderLines.push(line);
                });
            }
        });

        return orderLines;
    };

    static submitMakeItOrder = (
        poNumber: string,
        orderLines: ProductionOrderLine[],
        state: any
    ) => {
        const accessToken = state.auth.accessToken;
        const account = state.customerContext.shipToAccounts.find(
            (account) => account.accountId === state.customerContext.selectedAccountId
        );
        const today = convertToMT(moment());

        let order: ProductionOrderRequest = {
            region: state.regionCulture.regionCode,
            country: account.address.country,
            customerProductionOrderId: poNumber,
            createdBy: state.auth.userInfo.email,
            company: account.name,
            paymentTerms: account.paymentTerms,
            status: Order_Status.Submit,
            shipToId: parseInt(state.customerContext.selectedAccountId),
            submittedBy: state.auth.userInfo.email,
            submittedDate: today,
            lines: orderLines
        };
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/productionOrders`,
            order,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- SHIP IT DASHBOARD & CONFIG -- //
    static getShipItProducts = (
        accessToken: string,
        requestObject: ShipItProductsRequest,
        queryDate: any
    ) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/products`;
        const palletsForPreviousDays = 7;
        const convertedRequestedDate = convertToMTSetMidNight(moment(queryDate));
        //Format request object (ShipToIds)
        const urlReqObj = createAPIQueryParam(requestObject);
        //Add in requested date
        const urlReqDate = `&RequestedDate=${convertedRequestedDate}`;
        //Only get products with available pallets
        const urlAvailPallets = `&WithAvailablePallets=${true}`;
        //Get the available pallets for the prior 7 days of each requested date
        const urlPallets = `&TotalsForPreviousNDays=${palletsForPreviousDays}`;
        //Construct URL
        const requestUrl = baseUrl + urlReqObj + urlReqDate + urlAvailPallets + urlPallets;

        return Axios.get<{ products: ProductWithPallets[] }>(
            requestUrl,
            OrdersService.createConfig(accessToken)
        );
    };

    static submitShipItOrder = (
        shipments: DeliveryShipment[],
        state: any,
        orderInformation?: OrderInformation
    ) => {
        const accessToken = state.auth.accessToken;
        let accountId;
        if (orderInformation) {
            accountId = orderInformation.shipToId;
        } else {
            accountId = state.customerContext.selectedAccountId;
        }
        const account = state.customerContext.accounts.filter(
            (account) => account.accountId === accountId
        );
        const today = convertToMT(moment());

        let order: DeliveryOrderRequest = {
            region: state.regionCulture.regionCode,
            country: account[0].address.country,
            createdBy: state.auth.userInfo.email,
            type: 'CIA',
            status: Order_Status.Submit,
            shipToId: parseInt(accountId),
            submittedDate: today,
            shipments: shipments,
            deliveryInstructions: orderInformation?.deliveryInstructions,
            poNumber: orderInformation?.poNumber
        };
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/deliveryOrders`,
            order,
            OrdersService.createConfig(accessToken)
        );
    };

    static getProductionOrders = (accessToken, request: ProductionOrdersLookup) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<ProductionOrders>(
            `${process.env.REACT_APP_ORDER_API}/productionOrders${queryString}`,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- MAKE IT SUMMARY -- //
    static getProductionOrderSummaries = (accessToken: any, request: ProductionOrdersLookup) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<ProductionOrderSummaryResponse>(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/summary${queryString}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static deleteProductionOrder = (accessToken: any, productionOrderId?: number) => {
        return Axios.delete(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/${productionOrderId}`,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- SHIP IT SUMMARY -- //
    static getDeliveryOrders = (accessToken: any, request: DeliveryOrdersLookup) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<DeliveryOrders>(
            `${process.env.REACT_APP_ORDER_API}/deliveryOrders/summary${queryString}`,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- PRODUCTION ORDER DETAILS -- //
    static getProductionOrder = (state: any, productionOrderId: number) => {
        return Axios.get<ProductionOrder>(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/${productionOrderId}`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static getProductionOrderLinkedDeliveryOrders = (state: any, productionOrderId: number) => {
        return Axios.get<LinkedDeliveryOrder>(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/${productionOrderId}/deliveryOrders`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    // -- DELIVERY ORDER DETAILS -- //
    static getDeliveryOrder = (accessToken: any, deliveryOrderId: string) => {
        return Axios.get<DeliveryOrder>(
            `${process.env.REACT_APP_ORDER_API}/deliveryOrders/${deliveryOrderId}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static updateShipmentDeliveryOrder = (accessToken: any, deliveryOrder: DeliveryOrder) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/deliveryOrders`,
            deliveryOrder,
            OrdersService.createConfig(accessToken)
        );
    };

    static submitScrapIt = (accessToken: string, requestObject: any) => {
        const requestUrl = `${process.env.REACT_APP_ORDER_API}/scrap`;
        return Axios.post<{ products: OrderProductTableRow[] }, { data: ScrapItRequestResponse }>(
            requestUrl,
            requestObject,
            OrdersService.createConfig(accessToken)
        );
    };

    static getScrapItRequests = (
        accessToken: string,
        shipToIds: Array<number>,
        cultureCode: string,
        regionCode: string
    ) => {
        let queryString = createAPIQueryParam({ shipToIds });
        const axiosConfig = createAxiosHeader(cultureCode, regionCode, accessToken);
        return Axios.get<any[]>(
            `${process.env.REACT_APP_ORDER_API}/scraps${queryString}`,
            axiosConfig
        );
    };

    static getScrapItRequest = (accessToken: any, scrapRequestId: string) => {
        return Axios.get<ScrapItRequestDetails>(
            `${process.env.REACT_APP_ORDER_API}/scrap/${scrapRequestId}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static getDeliveryOrderLinkedProductionOrders = (accessToken: any, deliveryOrderId: string) => {
        return Axios.get<DeliveryOrderLinkedProductionOrder>(
            `${process.env.REACT_APP_ORDER_API}/deliveryOrders/${deliveryOrderId}/productionOrders`,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- PENDING PAYMENTS -- //
    static getInvoices = (accessToken: string, requestObject: any) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/invoices`;
        const urlContent = createAPIQueryParam(requestObject);
        const requestUrl = baseUrl + urlContent;

        return Axios.get<{ invoices: Invoice[] }>(
            requestUrl,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- PRODUCT PLANNING -- //
    static getProductsNew = (accessToken: string, request: any) => {
        const queryString = createAPIQueryParam(request);

        return Axios.get<{ products: Array<ProductWithPallets> }>(
            `${process.env.REACT_APP_ORDER_API}/products${queryString}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static getProductPlanning = (state: any, request: any) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<any>(
            `${process.env.REACT_APP_ORDER_API}/products/planning${queryString}`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static getLinkedProductionOrders = (
        state: any,
        productSku: string,
        request: LinkedProductionOrderRequest
    ) => {
        let queryString = createAPIQueryParam(request);

        const requestUrl = `${process.env.REACT_APP_ORDER_API}/products/${productSku}/productionOrders${queryString}`;
        return Axios.get<ProductionOrders>(
            requestUrl,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static getOrderShipments = (state: any, productSku: string, request: any) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<any>(
            `${process.env.REACT_APP_ORDER_API}/products/${productSku}/deliveryOrders${queryString}`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static getDeliveryOrderProducts = (accessToken: any, shipToId: number[]) => {
        return Axios.get<any>(
            `${process.env.REACT_APP_ORDER_API}/products?ShipToIds=${shipToId}`,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- BULK UPLOAD -- //

    static getBulkUpload = (state: any, userName: string, orderType: OrderType) => {
        return Axios.get<any>(
            `${process.env.REACT_APP_ORDER_API}/bulkUpload/configuration?UserName=${userName}&OrderType=${orderType}`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static reviewDeliveryBulkUpload = (state: any, userName: string, file: File | File[]) => {
        const formData = new FormData();
        formData.append('CsvData', file[0], file[0].name);
        formData.append('UserName', userName);
        formData.append('Region', state.regionCulture.regionCode);
        formData.append('CreatedBy', userName);
        formData.append('Country', 'US');

        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/bulkUpload/deliveryOrders`,
            formData,
            OrdersService.createMultipartConfig(state.auth.accessToken)
        );
    };

    static createBulkUploadDeliveryTemplate = (
        accessToken: any,
        requestObj: BulkUploadTemplateRequest
    ) => {
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/bulkUpload/configuration`,
            requestObj,
            OrdersService.createConfig(accessToken)
        );
    };

    static updateBulkUploadDeliveryTemplate = (
        accessToken: any,
        requestObj: BulkUploadTemplateRequest
    ) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/bulkUpload/configuration`,
            requestObj,
            OrdersService.createConfig(accessToken)
        );
    };

    static submitDeliveryBulkUpload = (accessToken: any, orders: BulkUploadOrder[]) => {
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/deliveryOrders/bulk`,
            orders,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- EDIT/CANCEL SHIPMENTS -- //
    static getExcludedDOProducts = (
        state: any,
        deliveryOrderId: number,
        shipToId: number,
        queryDate: string
    ) => {
        const convertedRequestedDate = convertToMTSetMidNight(moment(queryDate));
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/products`;
        //Format request object (ShipToIds)
        const urlShipTo = `?ShipToIds=${shipToId}`;
        //Add in requested date
        const urlReqDate = `&RequestedDate=${convertedRequestedDate}`;
        //Only get products with available pallets
        const urlAvailPallets = `&WithAvailablePallets=${true}`;
        //Add in flag to exclude current delivery order from pallet totals
        const excludeDO = `&ExcludeDeliveryOrderId=${deliveryOrderId}`;

        const requestUrl = baseUrl + urlShipTo + urlReqDate + urlAvailPallets + excludeDO;
        // const requestUrl = baseUrl + urlShipTo + urlReqDate + excludeDO;
        return Axios.get<{ products: ProductWithPallets[] }>(
            requestUrl,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static reviewPlanItBulkUpload = (
        state: any,
        userName: string,
        file: File | File[],
        weeks?: moment.Moment[]
    ) => {
        const formData = new FormData();
        formData.append('CsvData', file[0], file[0].name);
        formData.append('UserName', userName);
        formData.append('Region', state.regionCulture.regionCode);
        formData.append('CreatedBy', userName);
        formData.append('Country', 'US');
        formData.append('OrderType', OrderType.Forecast);
        if (weeks?.length) {
            for (let week of weeks) {
                formData.append('WeekDates', moment(week).format('M/D/YYYY'));
            }
        }
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/bulkUpload/forecasts`,
            formData,
            OrdersService.createMultipartConfig(state.auth.accessToken)
        );
    };

    static reviewMakeItBulkUpload = (
        state: any,
        userName: string,
        isLargeCustomer: boolean,
        file: File | File[],
        weeks?: moment.Moment[]
    ) => {
        const formData = new FormData();
        formData.append('CsvData', file[0], file[0].name);
        formData.append('UserName', userName);
        formData.append('Region', state.regionCulture.regionCode);
        formData.append('CreatedBy', userName);
        formData.append('Country', 'US');
        if (isLargeCustomer) {
            formData.append('OrderType', 'PRODUCTION_ATM');
            if (weeks?.length) {
                for (let week of weeks) {
                    formData.append('WeekDates', moment(week).format('M/D/YYYY'));
                }
            }
        }
        let endPoint = isLargeCustomer ? 'atmProductionOrders' : 'productionOrders';
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/bulkUpload/${endPoint}`,
            formData,
            OrdersService.createMultipartConfig(state.auth.accessToken)
        );
    };

    static getExistingOrders = (
        state: any,
        intervalStartWeek: string,
        intervalFinalWeek: string,
        shipToIds: Array<number>,
        forecastStatus: ForecastStatus,
        getAllProducts = false
    ) => {
        const paramObject: MakeItBulkUploadProductionOrdersRequest = {
            intervalFinalWeek: [intervalFinalWeek], // M/D/YYYY
            intervalStartWeek: [intervalStartWeek], // M/D/YYYY
            shipToIds: shipToIds,
            includeAllOrders: getAllProducts
        };
        const urlContent = createAPIQueryParam(paramObject);

        const defaultLineProps = { isCustomerProductIdDistinct: true };

        const controller =
            forecastStatus === ForecastStatus.Forecast ? '/forecasts' : '/atmProductionOrders';

        return Axios.get<{ orders: Array<GetProductionOrdersResponse> }>(
            `${process.env.REACT_APP_ORDER_API}${controller}${urlContent}`,
            OrdersService.createConfig(state.auth.accessToken)
        ).then((resp) =>
            resp.data.orders.map((order) => {
                if (order.lines && order.lines.length)
                    order.lines = order.lines.map((line) => ({
                        ...defaultLineProps,
                        ...line,
                        originalPalletQuantity: line.palletQuantity
                    }));
                return order;
            })
        );
    };

    static submitMakeItBulkUpload = (state: any, orders: MakeItBulkOrder[]) => {
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/bulk`,
            orders,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    static submitMakeItBulkAtmUpload = (payload: any, authToken: any) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/atmProductionOrders/bulk`,
            payload,
            OrdersService.createConfig(authToken)
        );
    };

    static submitPlanItBulkAtmUpload = (payload: any, authToken: any) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/forecast`,
            payload,
            OrdersService.createConfig(authToken)
        );
    };

    static submitMakeItConversion = (
        payload: UpdateProductionOrderRequest[],
        authToken: any,
        poID: number
    ) => {
        return Axios.put<
            UpdateProductionOrderRequest[],
            AxiosResponse<UpdateProductionOrderResponse>
        >(
            `${process.env.REACT_APP_ORDER_API}/forecastCopy?forecastId=${poID}`,
            payload,
            OrdersService.createConfig(authToken)
        );
    };

    // -- MAKE IT SUMMARY ATM -- //
    static getProductionOrdersATM = (state: any, request: ProductionOrdersAtmLookup) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<ProductionOrders>(
            `${process.env.REACT_APP_ORDER_API}/atmProductionOrders${queryString}`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    // -- ATM PRODUCTION ORDER DETAILS -- //
    static getATMProductionOrder = (state: any, atmProductionOrderId: number) => {
        return Axios.get<GetProductionOrdersResponse>(
            `${process.env.REACT_APP_ORDER_API}/atmProductionOrders/${atmProductionOrderId}`,
            OrdersService.createConfig(state.auth.accessToken)
        );
    };

    // -- FORECAST SUMMARY -- //
    static getForecastOrders = (accessToken: string, request: ForecastOrdersLookup) => {
        let queryString = createAPIQueryParam(request);

        return Axios.get<ProductionOrders>(
            `${process.env.REACT_APP_ORDER_API}/forecasts${queryString}`,
            OrdersService.createConfig(accessToken)
        );
    };

    // -- NEW DASHBOARD ENDPOINT -- //
    static getDashboardInformation = (accessToken: string, request: DashboardRequest) => {
        const baseUrl = `${process.env.REACT_APP_ORDER_API}/dashboard`;

        let urlContent = `?ShipToId=${request.shipToId}`;
        urlContent += request.orderedPriorTo && `&OrderedPriorTo=${request.orderedPriorTo}`;
        urlContent += request.paymentTerms && `&PaymentTerms=${request.paymentTerms}`;
        const requestUrl = baseUrl + urlContent;

        return Axios.get(requestUrl, OrdersService.createConfig(accessToken));
    };

    static submitCreateDraft = (payload: any, authToken: any) => {
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/drafts`,
            payload,
            OrdersService.createConfig(authToken)
        );
    };

    static submitUpdateDraft = (payload: any, authToken: any) => {
        return Axios.put(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/drafts`,
            payload,
            OrdersService.createConfig(authToken)
        );
    };

    static getDrafts = (
        accessToken: string,
        request: GetDraftsRequest,
        cultureCode: string,
        regionCode: string
    ) => {
        let queryString = createAPIQueryParam(request);
        const axiosConfig = createAxiosHeader(cultureCode, regionCode, accessToken);

        return Axios.get<ProductionOrderDraft[]>(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/drafts${queryString}`,
            axiosConfig
        );
    };

    static deleteDraft = (accessToken: string, draftId: string) => {
        return Axios.delete(
            `${process.env.REACT_APP_ORDER_API}/productionOrders/drafts/${draftId}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static getTradeRequestSummary = (
        accessToken: string,
        shipToIds: Array<number>,
        cultureCode: string,
        regionCode: string
    ) => {
        let queryString = createAPIQueryParam({ shipToIds });
        const axiosConfig = createAxiosHeader(cultureCode, regionCode, accessToken);
        return Axios.get<any[]>(
            `${process.env.REACT_APP_ORDER_API}/trade${queryString}`,
            axiosConfig
        );
    };

    static getProductBalances = (
        accessToken: string,
        request: any,
        cultureCode: string,
        regionCode: string
    ) => {
        let queryString = createAPIQueryParam(request);
        const axiosConfig = createAxiosHeader(cultureCode, regionCode, accessToken);
        return Axios.get<any[]>(
            `${process.env.REACT_APP_ORDER_API}/products/trades${queryString}`,
            axiosConfig
        );
    };

    static submitTradeItRequest = (tradeItRequest: TradeItRequest, state: any) => {
        const accessToken = state.auth.accessToken;
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/trade`,
            tradeItRequest,
            OrdersService.createConfig(accessToken)
        );
    };

    static getTradeRequestDetails = (state: any, tradeRequestId: number) => {
        const accessToken = state.auth.accessToken;
        return Axios.get<any>(
            `${process.env.REACT_APP_ORDER_API}/trade/${tradeRequestId}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static submitProductChange = (
        updateProductInformation: UpdateProductInformationRequest,
        state: any
    ) => {
        const accessToken = state.auth.accessToken;
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/products/datachange`,
            updateProductInformation,
            OrdersService.createConfig(accessToken)
        );
    };

    static getAgedProducts = (state: any, requestObject: any) => {
        const accessToken = state.auth.accessToken;
        const urlReqObj = createAPIQueryParam(requestObject);
        return Axios.get<ProductSkusResponse>(
            `${process.env.REACT_APP_ORDER_API}/products/aged${urlReqObj}`,
            OrdersService.createConfig(accessToken)
        );
    };

    static updateProductStatus = (state: any, requestObject: any) => {
        const accessToken = state.auth.accessToken;
        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/products/statuschange`,
            requestObject,
            OrdersService.createConfig(accessToken)
        );
    };

    static submitOrderDraft = (
        draftName: string,
        shipments: DeliveryShipment[],
        state: any,
        orderInformation?: OrderInformation
    ) => {
        const accessToken = state.auth.accessToken;
        let accountId;
        if (orderInformation) {
            accountId = orderInformation.shipToId;
        } else {
            accountId = state.customerContext.selectedAccountId;
        }
        const account = state.customerContext.accounts.filter(
            (account) => account.accountId === accountId
        );
        const today = convertToMT(moment());

        let order: DeliveryOrderRequest = {
            region: state.regionCulture.regionCode,
            country: account[0].address.country,
            createdBy: state.auth.userInfo.email,
            type: 'CIA',
            status: Order_Status.Draft,
            shipToId: parseInt(accountId),
            submittedDate: today,
            shipments: shipments,
            deliveryInstructions: orderInformation?.deliveryInstructions,
            poNumber: orderInformation?.poNumber
        };

        const createDraftName: CreateDraftName = {
            name: draftName
        };

        const payload: CreateOrderDraftRequest = {
            createDraft: createDraftName,
            orders: [order]
        };

        return Axios.post(
            `${process.env.REACT_APP_ORDER_API}/orders/drafts`,
            payload,
            OrdersService.createConfig(accessToken)
        );
    };
}
