import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { CustomerContextState} from '../../../store/reducers/customer-context';

export default function Inventory() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { accounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('inventory', 'Inventory'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.Inventory}
        >
        </ProcessingPageTemplate>
    );
}