import { Grid, makeStyles, Container, Typography } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import React, { useEffect, useState, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useTitle } from '../../../utility/use-title';
import { getHeaderContent, setPersonas } from '../../../store/root-actions';
import LoadingIndicator from '../../reusable/atoms/LoadingIndicator';
import { boldWeight, containerMaxWidth, red, xxl } from '../../../themes/globalConstants';

import ProfileMenu from '../molecules/ProfileMenu';
import NavBar from './NavBar';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../store/reducers/auth';
import CustomerNavBar from './CustomerNavBar';
import { Culture, RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import EmeaCustomerNavBar from './EmeaCustomerNavBar';
import { getSpanishCulture } from '../../../utility/helpers/content-helpers';
import { SecurityLevelState } from '../../../store/reducers/security-level';
import {
    CustomerContextState,
    CustomerType,
    MetaContentCustomerType,
    PaymentTerms,
    ProdOrderType
} from '../../../store/reducers/customer-context';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { GetFeatureFlag, FlagTypes } from '../../../utility/helpers/feature-flag';
import { Trans, useTranslation } from 'react-i18next';
import { hasJoiningPersona } from '../../../utility/auth/useSecurity';
import Lang from 'tubular-react/dist/utils/Lang';
import { getLanguageFile } from '../../../utility/helpers/grid-helpers';
import MainNavBar from './MainNavBar';

interface Props {
    title?: string;
    loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black
    },
    container: {
        maxWidth: containerMaxWidth,
        paddingLeft: 15,
        paddingRight: 15
    },
    status: {
        paddingRight: '0.6em'
    },
    playgroundText: {
        fontSize: xxl,
        fontWeight: boldWeight,
        color: red,
        textTransform: 'uppercase'
    }
}));

const Header = ({ title = 'The Source', loading }: Props) => {
    const { headerContent, regionCulture } = useTypedSelector((state) => state);
    const { userInfo, isCustomer, permissions } = useTypedSelector<AuthState>(
        (state) => state.auth
    );
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [navType, setNavType] = useState<ReactNode>();
    const [hasPersona, setHasPersona] = useState<boolean>(false);
    const [isJoining, setIsJoining] = useState<string>('');
    const [cultureCode, setCultureCode] = useState<string>('es-ES');
    const [walkMeRegionCode, setWalkMeRegionCode] = useState<string>('NCA');
    const [paymentType, setPaymentType] = useState<string>('');
    const [customerType, setCustomerType] = useState<MetaContentCustomerType>('');
    const [orderType, setOrderType] = useState<ProdOrderType>(ProdOrderType.Unknown);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { activity, securityLevel, personas } = useTypedSelector<SecurityLevelState>(
        (state) => state.securityLevel
    );

    const isPlayground = GetFeatureFlag(FlagTypes.Playground);

    useTitle(title);
    useTranslation();

    useEffect(() => {
        if (regionCulture.cultureCode !== headerContent.cultureCode) {
            dispatch(getHeaderContent());
        }
    }, [dispatch, headerContent.cultureCode, regionCulture.cultureCode]);

    useEffect(() => {
        Object.values(Culture).forEach((cultureCode) => {
            Lang.addLanguage(cultureCode, getLanguageFile(cultureCode));
        });
    }, []);

    useEffect(() => {
        let code = regionCulture.cultureCode;
        if (regionCulture.cultureCode === Culture.esES) {
            code = getSpanishCulture(regionCulture.regionCode);
        }
        setCultureCode(code);
        Lang.changeLanguage(regionCulture.cultureCode);

        const walkMeRegionCode =
            regionCode === Region.EU ? 'EMEA' : regionCode === Region.SA ? 'SA' : 'NCA';

        setWalkMeRegionCode(walkMeRegionCode);
    }, [regionCulture.regionCode, regionCulture.cultureCode, regionCode]);

    useEffect(() => {
        if (userInfo) {
            setHasPersona(true);
            if (isCustomer) {
                if (regionCode === Region.EU) {
                    setNavType(<EmeaCustomerNavBar />);
                } else {
                    setNavType(<CustomerNavBar />);
                }
            } else {
                setNavType(<NavBar />);
            }
        }
    }, [userInfo, isCustomer, regionCode]);

    useEffect(() => {
        let hasCredit = false;
        let hasCIA = false;
        let customerTypesList: CustomerType[] = [];
        let selectedCustomerType: CustomerType | undefined = undefined;
        if (shipToAccounts && shipToAccounts.length > 0) {
            shipToAccounts.forEach((shipTo) => {
                //Prepare payment info for review
                if (shipTo.paymentTerms === PaymentTerms.CIA) {
                    hasCIA = true;
                } else if (shipTo.paymentTerms === PaymentTerms.Credit) {
                    hasCredit = true;
                }
                //Prepare customer info for review
                if (selectedAccountId === shipTo.accountId) {
                    selectedCustomerType = shipTo.customerType;
                }
                customerTypesList.push(shipTo.customerType);

                if (shipTo.prodOrderType === ProdOrderType.AuthorizationToManufacture) {
                    setOrderType(ProdOrderType.AuthorizationToManufacture);
                }
            });

            //User has both credit and CIA accounts
            if (hasCredit && hasCIA) {
                setPaymentType(PaymentTerms.CIA_Credit);
                //User credit accounts only
            } else if (hasCredit) {
                setPaymentType(PaymentTerms.Credit);
                //User has CIA accounts only
            } else if (hasCIA) {
                setPaymentType(PaymentTerms.CIATag);
                //Payment terms are unknown
            } else {
                setPaymentType('');
            }

            if (
                selectedCustomerType === CustomerType.Contract ||
                customerTypesList.some((type) => type === CustomerType.Contract)
            ) {
                setCustomerType('Contract');
            } else if (
                selectedCustomerType === CustomerType.Spot ||
                customerTypesList.some((type) => type === CustomerType.Spot)
            ) {
                setCustomerType('Spot');
            } else {
                setCustomerType('Negotiated');
            }
        }
    }, [shipToAccounts, selectedAccountId]);

    useEffect(() => {
        if (permissions && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, shipToAccounts, permissions]);

    useEffect(() => {
        if (permissions) {
            if (!personas) {
                let personaList = permissions?.map((permission) => permission.personaId).join(', ');
                if (!personaList || personaList === '') {
                    personaList = 'NONE';
                }

                dispatch(setPersonas(personaList));
            }
            setIsJoining(hasJoiningPersona(permissions) ? 'Yes' : 'No');
        }
    }, [dispatch, permissions, personas]);

    return (
        <>
            <Toolbar className={classes.toolbar}>
                <Container className={classes.container}>
                    <Grid container alignItems="center" justify="space-between" xs={12}>
                        <MainNavBar />
                        {hasPersona && (
                            <Grid item justify="space-between">
                                {navType}
                            </Grid>
                        )}
                        <Helmet htmlAttributes={{ lang: cultureCode }} />
                        <Helmet meta={[{ name: 'region', content: walkMeRegionCode }]} />
                        {!!securityLevel && (
                            <Helmet meta={[{ name: 'securityLevel', content: securityLevel }]} />
                        )}
                        {activity !== undefined && (
                            <Helmet meta={[{ name: 'activity', content: activity }]} />
                        )}
                        {personas !== undefined && (
                            <Helmet meta={[{ name: 'personas', content: personas }]} />
                        )}
                        {paymentType !== '' && (
                            <Helmet meta={[{ name: 'PaymentTerms', content: paymentType }]} />
                        )}
                        {customerType !== '' && (
                            <Helmet meta={[{ name: 'CustomerType', content: customerType }]} />
                        )}
                        {isJoining !== '' && (
                            <Helmet meta={[{ name: 'NewCustomer', content: isJoining }]} />
                        )}
                        {orderType !== ProdOrderType.Unknown && (
                            <Helmet meta={[{ name: 'OrderType', content: orderType }]} />
                        )}
                        {isPlayground && (
                            <Grid justify="space-between">
                                <Typography className={classes.playgroundText}>
                                    <Trans i18nKey="playground">Playground</Trans>
                                </Typography>
                            </Grid>
                        )}
                        <ProfileMenu />
                    </Grid>
                </Container>
            </Toolbar>
            {loading ? <LoadingIndicator /> : null}
        </>
    );
};

export default Header;
