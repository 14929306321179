import moment from 'moment';

import { AuthState } from '../reducers/auth';
import {
    CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE,
    CUSTOMER_ORDERING_DASHBOARD_ERROR,
    CUSTOMER_ORDERING_DASHBOARD_LOADED,
    CUSTOMER_ORDERING_DASHBOARD_LOADING,
    CUSTOMER_ORDERING_DASHBOARD_RESET_STATUS
} from './action-types';
import {  CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_ERROR, CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_ERROR, CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_ERROR, CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED, CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING, CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED, CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING, CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING, CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED } from "./action-types";

import OrderingService, {
    DashboardRequest
} from '../../utility/services/customer-ordering-dashboard-service';

export const loadCustomerDashboardInformation = (
    shipToId: string,
    soldToId?: string,
    paymentTerms?: string
) => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        dispatch({ type: CUSTOMER_ORDERING_DASHBOARD_LOADING });
        const dashboardRequest: DashboardRequest = {
            shipToId: shipToId,
            soldToId: soldToId,
            paymentTerms: paymentTerms
        };
        let dashboardCalls = [
            OrderingService.getOrderManagementDashboardInformation(
                auth.accessToken,
                dashboardRequest
            ),
            OrderingService.getProductPortfolioDashboardInformation(
                auth.accessToken,
                dashboardRequest
            ),
            OrderingService.getShipmentsDeliveriesDashboardInformation(
                auth.accessToken,
                dashboardRequest
            )
        ];
        Promise.all(dashboardCalls)
            .then((response) => {
                const dashboard = {
                    ...response[0].data,
                    ...response[1].data,
                    ...response[2].data,
                    orderManagementDashboard: {
                        ...response[0].data
                    },
                    productPortfolioDashboard: {
                        ...response[1].data
                    },
                    shipmentDeliveriesDashboard: {
                        ...response[2].data
                    }
                };
                dispatch({
                    type: CUSTOMER_ORDERING_DASHBOARD_LOADED,
                    dashboardData: dashboard
                });
                const dateLimit = moment().add(15, 'minutes').toDate();
                dispatch({
                    type: CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE,
                    dashboard,
                    shipToId: shipToId,
                    dateLimit
                });
            })
            .catch((err) => {
                dispatch({ type: CUSTOMER_ORDERING_DASHBOARD_ERROR });
            });
    };
};

export const resetCustomerDashboard = () => {
    return (dispatch) => {
        dispatch({ type: CUSTOMER_ORDERING_DASHBOARD_RESET_STATUS });
    };
};

export const loadOrderManagementDashboardInformation = (shipToId: string, soldToId?: string, paymentTerms?: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        const dashboardRequest: DashboardRequest = {
            shipToId: shipToId,
            soldToId: soldToId,
            paymentTerms: paymentTerms
        };

        const dateLimit = moment().add(15, 'minutes').toDate();

        dispatch({ type: CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING });

        OrderingService.getOrderManagementDashboardInformation(auth.accessToken, dashboardRequest)
            .then((response) => {
                const dashboard = {
                    orderManagementDashboard: response.data
                };
                dispatch({
                    type: CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED,
                    dashboardData: dashboard,
                });
                dispatch({
                    type: CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE,
                    dashboard,
                    shipToId: shipToId,
                    dateLimit
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_ERROR });
            });
    };
};

export const loadProductPortfolioDashboardInformation = (shipToId: string, soldToId?: string, paymentTerms?: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        const dashboardRequest: DashboardRequest = {
            shipToId: shipToId,
            soldToId: soldToId,
            paymentTerms: paymentTerms
        };

        const dateLimit = moment().add(15, 'minutes').toDate();

        dispatch({ type: CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING });

        OrderingService.getProductPortfolioDashboardInformation(auth.accessToken, dashboardRequest)
            .then((response) => {
                const dashboard = {
                    productPortfolioDashboard: response.data
                };
                dispatch({
                    type: CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED,
                    dashboardData: dashboard,
                });
                dispatch({
                    type: CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE,
                    dashboard,
                    shipToId: shipToId,
                    dateLimit
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_ERROR });
            });
    };
};

export const loadShipmentsDeliveriesDashboardInformation = (shipToId: string, soldToId?: string, paymentTerms?: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        const dashboardRequest: DashboardRequest = {
            shipToId: shipToId,
            soldToId: soldToId,
            paymentTerms: paymentTerms
        };

        const dateLimit = moment().add(15, 'minutes').toDate();

        dispatch({ type: CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING });

        OrderingService.getShipmentsDeliveriesDashboardInformation(auth.accessToken, dashboardRequest)
            .then((response) => {
                const dashboard = {
                    shipmentDeliveriesDashboard: response.data
                };
                dispatch({
                    type: CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED,
                    dashboardData: dashboard,
                });
                dispatch({
                    type: CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE,
                    dashboard,
                    shipToId: shipToId,
                    dateLimit
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_ERROR });
            });
    };
};

export const loadCustomerCachedDashboard = (shipToId: string) => {
    return (dispatch, getState) => {
        dispatch({ type: CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING });
        dispatch({ type: CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING });
        dispatch({ type: CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING });

        const state = getState();
        const cachedDashboard = state.customerOrderingDashboard.cachedDashboard.find(
            (cd) => cd.shipToId === shipToId
        );
        if (cachedDashboard) {
            dispatch({ type: CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED, dashboardData: cachedDashboard.data });
            dispatch({ type: CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED, dashboardData: cachedDashboard.data });
            dispatch({ type: CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED, dashboardData: cachedDashboard.data });
        } else {
            dispatch({ type: CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_ERROR });
            dispatch({ type: CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_ERROR });
            dispatch({ type: CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_ERROR });
        }
    };
};