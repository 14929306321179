import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '../../reusable/atoms/Button';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    EditShipment,
    EditShipmentsState,
    ProductPalletsByDate,
    ProductPalletTotals
} from '../../../store/reducers/edit-shipments';
import { blackWeight, containerMaxWidth } from '../../../themes/globalConstants';
import {
    clearEditShipmentsSummary,
    loadEditShipmentsSummary,
    updateCancelledShipment,
    updateDeliveryToEdit,
    updatePalletsByDay,
    updatePalletTotals,
    updateLastEditedShipmentId
} from '../../../store/actions/edit-shipments';
import { ballGray } from '../../../themes/globalConstants';
import { AuthState } from '../../../store/reducers/auth';
import {
    getShipToAccounts,
    selectCustomerAccountId
} from '../../../store/actions/customer-context';
import ShipmentSummaryCard from './components/ShipmentSummaryCard';
import EditShipmentsDisclaimerSection from './components/EditShipmentsDisclaimerSection';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import ShipmentDiscardModal from '../../reusable/molecules/ShipmentDiscardModal';
import EditDeliveryOrderSubmitChangesModal from './components/EditDeliveryOrderSubmitChangesModal';
import OrdersService, { Order_Status } from '../../../utility/services/orders-service';
import {
    DeliveryOrder,
    DeliveryShipment,
    ShipmentEditStatus
} from '../../../store/reducers/shipping-dashboard';
import { EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR } from '../../../store/actions/action-types';
import {
    getDeliveryLeadTimeDays,
    updateDeliveryOrderWithProductInfo
} from '../../../utility/helpers/order-helpers';
import moment from 'moment';
import { updateOpenDeliveryOrders } from '../../../store/actions/shipping-summary';
import { selectIsImpersonation } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { subtractTimezoneOffset } from '../../../utility/helpers/date-helpers';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: '3em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    headerText: {
        fontWeight: blackWeight,
        color: ballGray,
        marginBottom: '2em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    }
}));

const EditShipmentsSummaryPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams<DefaultQueryParams>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions, accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const { deliveryToEdit, palletTotalsByDay, productPalletTotals, lastEditedShipmentId } =
        useTypedSelector<EditShipmentsState>((state) => state.editShipments);
    const [editingLocked, setEditingLocked] = useState<boolean>(false);
    const [discardModal, setDiscardModal] = useState<boolean>(false);
    const [submitChangesModalOpen, setSubmitChangesModalOpen] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [submitLocked, setSubmitLocked] = useState<boolean>(true);
    const [leadTime, setLeadTime] = useState<number | undefined>();
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const shipItSummaryLink = '/ship-it-summary';
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);

    const handleCancelledShipmentPallets = (cancelledShipment: DeliveryShipment) => {
        let tempProductPalletTotals = JSON.parse(JSON.stringify(productPalletTotals));
        let tempPalletTotalsByDay = JSON.parse(JSON.stringify(palletTotalsByDay));

        //Remove cancelled loads from product pallet totals
        cancelledShipment.loads?.map((load) => {
            let index = tempProductPalletTotals.findIndex(
                (total) => total.productSku === load.productSku
            );

            // Need to check the index value isn't -1 which will happen
            // if we splice a product out once spliced from tempProductPalletTotals array
            if (index !== -1) {
                //Remove pallet total of cancelled load from total obj

                let newPalletTotal =
                    tempProductPalletTotals[index].palletTotal - load.palletQuantity;

                if (newPalletTotal === 0) {
                    //Remove cancelled product from productPalletTotals obj
                    tempProductPalletTotals.splice(index, 1);
                } else {
                    //Splice in new calculated totals to productPalletTotals obj
                    tempProductPalletTotals[index].palletTotal = newPalletTotal;
                }
                dispatch(updatePalletTotals(tempProductPalletTotals));
            }
            return null;
        });

        //Remove cancelled shipment from palletTotalsByDay obj
        if (cancelledShipment.shipmentId) {
            let index = tempPalletTotalsByDay.findIndex(
                (tempTotal) =>
                    tempTotal.shipmentId.toString() === cancelledShipment!.shipmentId!.toString()
            );
            if (index !== -1) {
                tempPalletTotalsByDay.splice(index, 1);
            }
        }
        dispatch(updatePalletsByDay(tempPalletTotalsByDay));
    };

    const calculatePalletQuantities = () => {
        let deliveryToEditCopy = JSON.parse(JSON.stringify(deliveryToEdit));
        let tempProductPalletTotals =
            productPalletTotals && JSON.parse(JSON.stringify(productPalletTotals));
        let products = new Map();

        //Loop through all shipments in DO and all loads in all shipments to grab products and qties
        if (deliveryToEditCopy) {
            deliveryToEditCopy.deliveryOrder?.shipments?.map((shipment) => {
                shipment.loads?.map((loadProduct) => {
                    const currentSku = loadProduct.productSku;

                    //If product sku exists in Map, update the pallet qty
                    //Else, add the sku to the map
                    if (products.has(loadProduct.productSku)) {
                        let key = loadProduct.productSku;
                        let currentPallets = products.get(key);
                        let updatedQtyInOrders = currentPallets + loadProduct.palletQuantity;
                        products.set(currentSku, updatedQtyInOrders);
                    } else {
                        let currentPallets = loadProduct.palletQuantity;
                        products.set(currentSku, currentPallets);
                    }
                    return null;
                });
                return null;
            });

            products.forEach((value, key) => {
                if (tempProductPalletTotals && tempProductPalletTotals.length > 0) {
                    let index = tempProductPalletTotals.findIndex(
                        (item) => key === item.productSku
                    );
                    if (index !== -1) {
                        tempProductPalletTotals[index].palletTotal = value;
                    } else {
                        const prodPalletObj = { productSku: key, palletTotal: value };
                        tempProductPalletTotals.push(prodPalletObj as ProductPalletTotals);
                    }
                } else {
                    const prodPalletObj = { productSku: key, palletTotal: value };
                    tempProductPalletTotals.push(prodPalletObj as ProductPalletTotals);
                }
            });
            dispatch(updatePalletTotals(tempProductPalletTotals));
        }
    };

    const calculatePalletQtyByDay = () => {
        let tempShipments =
            deliveryToEdit && JSON.parse(JSON.stringify(deliveryToEdit)).deliveryOrder?.shipments;
        let tempPalletTotalsByDay =
            palletTotalsByDay &&
            (JSON.parse(JSON.stringify(palletTotalsByDay)) as Array<ProductPalletsByDate>);
        let shipmentDate;

        if (tempShipments) {
            tempShipments.map((shipment) => {
                if (shipment.deliveryDateTime || shipment.updatedDeliveryDate) {
                    let dateToFormat = shipment.updatedDeliveryDate
                        ? shipment.updatedDeliveryDate
                        : shipment.deliveryDateTime;
                    shipmentDate = moment(dateToFormat).format('MM/DD/YYYY');
                }

                if (shipment.loads) {
                    shipment.loads.map((load) => {
                        //For each load, assign to categories below
                        const sku = load.productSku;
                        const pallets = load.palletQuantity;
                        const id = shipment.shipmentId!.toString();
                        let productObj = {
                            productSku: sku,
                            palletTotal: pallets
                        } as ProductPalletTotals;

                        if (tempPalletTotalsByDay) {
                            //Map through palletsByDay to get index if this shipment already exists in the object
                            let shipmentIndex = tempPalletTotalsByDay.findIndex(
                                (item) => item.shipmentId === id
                            );

                            //If this shipment already exists in the object
                            if (shipmentIndex !== -1) {
                                //Check if product exists
                                //If it does exist, add the values
                                let productIndex = tempPalletTotalsByDay[
                                    shipmentIndex
                                ].products.findIndex((item) => item.productSku === load.productSku);
                                let currentProduct =
                                    tempPalletTotalsByDay[shipmentIndex].products[productIndex];

                                tempPalletTotalsByDay[shipmentIndex].date = shipmentDate;

                                if (
                                    productIndex !== -1 &&
                                    currentProduct.productSku !== load.productSku
                                ) {
                                    currentProduct.palletTotal = load.palletQuantity;
                                } else if (
                                    productIndex !== -1 &&
                                    currentProduct.productSku === load.productSku
                                ) {
                                    currentProduct.palletTotal += load.palletQuantity;
                                } else {
                                    tempPalletTotalsByDay[shipmentIndex].products.push({
                                        productSku: load.productSku!,
                                        palletTotal: load.palletQuantity
                                    });
                                }
                            } else {
                                //Else if this shipment doesn't exist yet, push it to the object array
                                const itemObj = {
                                    date: shipmentDate,
                                    shipmentId: id,
                                    products: [productObj]
                                };
                                tempPalletTotalsByDay.push(itemObj as ProductPalletsByDate);
                            }
                        }
                        return null;
                    });
                }
                return null;
            });

            dispatch(updatePalletsByDay(tempPalletTotalsByDay));
        }
    };

    const handleSubmitChanges = () => {
        if (!isImpersonation) {
            setSubmit(true);
            setShowError(false);
            let editedDeliveryOrder = JSON.parse(JSON.stringify(deliveryToEdit))
                .deliveryOrder as DeliveryOrder;

            if (editedDeliveryOrder) {
                let nonCancelledShipments = editedDeliveryOrder.shipments?.filter(
                    (shipment) => shipment.cancelled !== true
                ) as DeliveryShipment[];

                editedDeliveryOrder.shipments = nonCancelledShipments;

                editedDeliveryOrder.shipments.forEach((shipment) => {
                    if (shipment.updatedDeliveryDate) {
                        shipment.deliveryDateTime = subtractTimezoneOffset(
                            shipment.updatedDeliveryDate
                        );
                    }
                });

                OrdersService.updateShipmentDeliveryOrder(accessToken, editedDeliveryOrder)
                    .then((response) => {
                        let editShipmentResponse = {} as EditShipment;
                        let responseObj = { ...response.data };
                        editShipmentResponse.deliveryOrder = { ...responseObj };
                        editShipmentResponse =
                            updateShipmentResponseWithEditStatus(editShipmentResponse);

                        OrdersService.getDeliveryOrderProducts(accessToken, [
                            editShipmentResponse.deliveryOrder.shipToId as number
                        ])
                            .then((response) => {
                                const availableProducts = response.data.products;
                                editShipmentResponse.deliveryOrder =
                                    updateDeliveryOrderWithProductInfo(
                                        editShipmentResponse.deliveryOrder,
                                        availableProducts
                                    );
                                dispatch(updateDeliveryToEdit(editShipmentResponse));
                                dispatch(
                                    updateOpenDeliveryOrders(editShipmentResponse.deliveryOrder)
                                );
                                history.push('/edit-shipments-confirmation');
                            })
                            .catch((error) => {
                                setSubmit(false);
                                dispatch({
                                    type: EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR,
                                    error:
                                        'Error loading products for delivery order with: ' + error
                                });
                            });
                    })
                    .catch((error) => {
                        setSubmit(false);
                        setShowError(true);
                        dispatch({
                            type: EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR,
                            error: 'Error updating delivery order: ' + error
                        });
                    })
                    .finally(() => {
                        setSubmit(false);
                    });
            }
        } else {
            setImpersonationWarning(true);
            setSubmitChangesModalClose();
        }
    };

    const updateShipmentResponseWithEditStatus = (editShipmentResponse: EditShipment) => {
        if (editShipmentResponse.deliveryOrder.shipments) {
            editShipmentResponse.deliveryOrder.shipments.forEach((shipment) => {
                if (shipment.status === Order_Status.EditedPending) {
                    shipment.shipmentEditStatus = ShipmentEditStatus.Edited;
                } else if (shipment.status === Order_Status.CancelledPending) {
                    shipment.shipmentEditStatus = ShipmentEditStatus.Cancelled;
                } else {
                    shipment.shipmentEditStatus = ShipmentEditStatus.Unedited;
                }
            });
        }

        return editShipmentResponse;
    };

    const openSubmitChangesModal = () => {
        setShowError(false);
        setSubmitChangesModalOpen(true);
    };

    const setSubmitChangesModalClose = () => {
        setShowError(false);
        setSubmitChangesModalOpen(false);
    };

    const handleDiscardChanges = () => {
        dispatch(clearEditShipmentsSummary());
        history.push(shipItSummaryLink);
    };

    const handleOpenDiscardModal = () => {
        setDiscardModal(true);
    };

    const handleCloseDiscardModal = () => {
        setDiscardModal(false);
    };

    // If going back to the Ship-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('ship-it-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [dispatch, history]);

    const handleToggleCancelShipment = (shipmentToToggle: DeliveryShipment) => {
        let editDeliveryOrder = JSON.parse(JSON.stringify(deliveryToEdit)) as EditShipment;
        let toggledShipment = editDeliveryOrder.deliveryOrder?.shipments?.find(
            (shipment) => shipment.shipmentId === shipmentToToggle.shipmentId
        ) as DeliveryShipment;

        if (toggledShipment) {
            toggledShipment.cancelled = !toggledShipment.cancelled;
        }

        dispatch(updateCancelledShipment(editDeliveryOrder));
    };

    useEffect(() => {
        if (deliveryToEdit) {
            const shipToId = deliveryToEdit?.deliveryOrder?.shipToId;
            const stringShipToId = shipToId?.toString();

            if (stringShipToId) {
                dispatch(selectCustomerAccountId(stringShipToId));
            }
        }
    }, [deliveryToEdit, dispatch]);

    // Find the last-edited shipment's summary card, scroll to it if found, then clear the value.
    useEffect(() => {
        if (deliveryToEdit && lastEditedShipmentId) {
            setTimeout(() => {
                const shipmentSummaryCard = document.getElementById(
                    'summary-card-' + lastEditedShipmentId
                );
                if (shipmentSummaryCard) {
                    window.scrollTo(0, shipmentSummaryCard.offsetTop - 100);
                }
                dispatch(updateLastEditedShipmentId(''));
            });
        }
    }, [deliveryToEdit, dispatch, lastEditedShipmentId]);

    useEffect(() => {
        if (permissions && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [shipToAccounts, permissions, dispatch]);

    useEffect(() => {
        if (id && selectedAccountId && !deliveryToEdit) {
            dispatch(loadEditShipmentsSummary(id, selectedAccountId));
        }
    }, [deliveryToEdit, dispatch, id, selectedAccountId]);

    useEffect(() => {
        if (selectedAccountId && shipToAccounts) {
            setLeadTime(getDeliveryLeadTimeDays(parseInt(selectedAccountId), shipToAccounts));
        }
    }, [selectedAccountId, shipToAccounts]);

    useEffect(() => {
        if (selectedAccountId && deliveryToEdit) {
            if (deliveryToEdit.deliveryOrder.isLocked) {
                setEditingLocked(true);
            }

            if (deliveryToEdit.deliveryOrder && deliveryToEdit.deliveryOrder.shipments) {
                deliveryToEdit.deliveryOrder.shipments.forEach((shipment) => {
                    if (shipment.edited || shipment.cancelled) {
                        setSubmitLocked(false);
                    }
                });
            }
        }
    }, [selectedAccountId, deliveryToEdit]);

    useEffect(() => {
        if (deliveryToEdit) {
            calculatePalletQuantities();
            calculatePalletQtyByDay();
        }
    }, [deliveryToEdit]);

    useEffect(() => {
        if (deliveryToEdit) {
            let shipments = deliveryToEdit.deliveryOrder?.shipments;

            if (shipments) {
                shipments.map((shipment) => {
                    if (shipment.cancelled) {
                        handleCancelledShipmentPallets(shipment);
                    }
                    return null;
                });
            }
        }
    }, [deliveryToEdit]);

    const footerActions = (
        <>
            <Grid container item xs={3}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={handleOpenDiscardModal}
                >
                    <Trans i18nKey="discardChanges">Discard Changes</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6}>
                <ImpersonationWarning showWarning={impersonationWarning} warningType={'SUBMIT'} />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="update-btn"
                    onClick={openSubmitChangesModal}
                    disabled={submitLocked}
                >
                    <Trans i18nKey="submitChanges">Submit Changes</Trans>
                </Button>
            </Grid>
        </>
    );

    deliveryToEdit?.deliveryOrder?.shipments?.sort((a: DeliveryShipment, b: DeliveryShipment) => {
        if ((a.deliveryDateTime || '') > (b.deliveryDateTime || '')) return 1;
        if ((a.deliveryDateTime || '') < (b.deliveryDateTime || '')) return -1;
        return 0;
    });

    const renderShipments = deliveryToEdit?.deliveryOrder?.shipments?.map((shipment, index) => {
        //Only display a shipment if it has products on it
        const loads = shipment.loads;
        if (loads && loads.length) {
            return (
                <ShipmentSummaryCard
                    key={shipment.shipmentId}
                    shipmentIndex={index}
                    currentShipment={shipment}
                    editingLocked={editingLocked}
                    edited={shipment.edited}
                    cancelled={shipment.cancelled}
                    shipmentStatus={shipment.shipmentEditStatus}
                    onToggleCancelShipment={handleToggleCancelShipment}
                />
            );
        }
        return null;
    });

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('editYourShipment', 'EDIT YOUR SHIPMENT').toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: true,
                disableSelect: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
        >
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                data-testid="edit-shipments-root"
                className={classes.root}
            >
                <Grid item xs={11}>
                    <EditShipmentsDisclaimerSection leadTime={leadTime} />
                </Grid>
                <Grid item xs={11}>
                    {deliveryToEdit?.deliveryOrder?.deliveryOrderNumber ? (
                        <Typography variant="h3" className={classes.headerText}>
                            <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                            {' #'}
                            {deliveryToEdit?.deliveryOrder?.deliveryOrderNumber}
                        </Typography>
                    ) : (
                        <Typography variant="h3" className={classes.headerText}>
                            <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                            {' # '}
                            <Trans i18nKey="pending">Pending</Trans>
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {renderShipments}
            <ShipmentDiscardModal
                open={discardModal}
                onDiscardChanges={handleDiscardChanges}
                onClose={handleCloseDiscardModal}
                onCancel={handleCloseDiscardModal}
            />
            <EditDeliveryOrderSubmitChangesModal
                open={submitChangesModalOpen}
                onClose={setSubmitChangesModalClose}
                onContinue={handleSubmitChanges}
                submitting={submit}
                showError={showError}
            />
        </ProcessingPageTemplate>
    );
};

export default EditShipmentsSummaryPage;
