import {
    Activity,
    Persona,
    SecurityLevel,
    hasPersonasByShipTo
} from '../../../utility/auth/useSecurity';
import { Grid, Typography, makeStyles, TextField, Link, Paper } from '@material-ui/core';
import {
    MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
    MAKEIT_DASHBOARD_UPDATE_RANGE,
    MAKEIT_DASHBOARD_UPDATE_VIEW
} from '../../../store/actions/action-types';
import { MakeState } from '../../../store/reducers/makeit-dashboard';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    makeItGridName,
    ballBlue,
    boldWeight,
    white,
    ballGray,
    black,
    shipRed,
    xl
} from '../../../themes/globalConstants';
import {
    getExistingOrders,
    loadMakeDashboard,
    makeItDashboardUpdateRange,
    makeItDashboardUpdateView,
    resetMakeItState,
    setRequestedMakeItWeek
} from '../../../store/actions/makeit-dashboard';

import { AuthState } from '../../../store/reducers/auth';
import Button from '../../reusable/atoms/Button';

import { CustomerContextState } from '../../../store/reducers/customer-context';
import { DefaultQueryParams, useQuery } from '../../../utility/helpers/query-helpers';
import { LocalStorage } from 'tubular-common';

import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, Link as RouterLink, Prompt } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    BulkUploadMakeItATMState,
    DeletedSnoSku,
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku,
    SnoSkuAllocationsByWeek
} from '../../../store/reducers/makeit-bulk-atm';
import {
    copyAtmOrderBySnoSku,
    deleteProductFromAtmOrder,
    getAllocationsWithShipTos,
    loadProductsFromPlanIt,
    loadProductsFromOrder,
    resetMakeItBulkATMState,
    saveOrdersBySnoSku,
    submitAtmOrders,
    submitDraftAsMakeIt
} from '../../../store/root-actions';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import { selectCustomerAccountId } from '../../../store/actions/customer-context';
import MakeItSpotLegend from './components/MakeItSpotLegend';
import { AllocationState } from './components/MakeItSpotWeekButton';
import {
    addQuantitiesFromOtherOrders,
    DateProperties,
    evaluateAllocationQuantityValid,
    evaluatePalletQuantityValid,
    getDateProperties,
    getFeeStructure,
    groupOrdersBySnoSku,
    PalletQuantityState
} from '../../../utility/helpers/make-it-bulk-helpers';
import {
    customerPoIsValid,
    getWeekValidationState,
    isProductCanType,
    isProductEndType,
    ToggleAll
} from '../../../utility/helpers/order-helpers';
import { FormattedWeek } from '../MakeItBulkReviewATM/models/FormattedWeek';
import OrderSection from './components/OrderSection';
import ValidationFilterMenu from '../../reusable/molecules/ValidationFilterMenu';
import {
    getMoqFeeFloor,
    getMoqFeeCeiling
} from '../../../utility/helpers/production-order-helpers';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { RouterConfirmationOverride } from '../../../App';
import WeekSelectorDropdownModal from '../../reusable/molecules/WeekSelectorDropdownModal';
import Modal from '../../reusable/molecules/Modal';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import clsx from 'clsx';
import OrderWeekSelector from './components/OrderWeekSelector';
import FeeModal from '../MakeItConfiguration/components/FeeModal';
import {
    cancelProductionOrder,
    loadProductsByProductList,
    loadProductsFromBulkUpload,
    loadProductsFromDraft,
    updateCustomerProductionOrderNumber
} from './../../../store/actions/makeit-bulk-atm';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { selectIsImpersonation } from '../../../store/selectors';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import SubmitModal from './components/SubmitModal';
import { SubmitStatus, WeekStatus } from './models/WeekStatus';
import SaveDraftModal from '../../reusable/molecules/SaveDraftModal';
import { cancelRed, medium } from './../../../themes/globalConstants';
import CancelOrderModal from '../../reusable/molecules/CancelOrderModal';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import AddProductsModal from '../../reusable/molecules/AddProductsModal/AddProductsModal';
import { selectIsBillToAccount } from '../../../store/selectors';
import {
    clearContractsAndDocuments,
    loadContractsAndDocuments
} from '../../../store/actions/contract-summary';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import NotificationBanner from '../../reusable/molecules/NotificationBanner';
import { getCurrentlyActiveDocuments } from '../../../utility/helpers/pricing-helpers';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0',
        marginTop: '1.750em'
    },
    mainTitle: {
        fontSize: '25px',
        color: ballGray,
        fontWeight: boldWeight,
        textTransform: 'capitalize',
        marginTop: '2.25em'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    actionRow: {
        justifyContent: 'flex-end',
        marginLeft: 'auto'
    },
    bulkUploadRow: {
        marginBottom: '1.5em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.5em 0'
    },
    subtitle: {
        color: theme.palette.secondary.main
    },
    warningContainer: {
        width: '100%',
        marginBottom: '1em'
    },
    poLabel: {
        color: black,
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '2em',
        marginBottom: '.5em'
    },
    alertMarker: {
        color: shipRed,
        fontSize: xl
    },
    fieldInput: {
        width: '360px',
        height: '100%',
        marginRight: '0.25em',

        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            backgroundColor: white,
            height: '100%',
            padding: '0.5em',
            '&:before': {
                display: 'none'
            }
        }
    },
    customerPOAlert: {
        '& .MuiInputBase-root': {
            borderColor: shipRed
        }
    },
    addProducts: {
        marginBottom: '5em',
        height: '6em'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    },
    header: {
        '& > div:first-of-type': {
            display: 'flex',
            flexGrow: 1
        }
    },
    calendarDiv: {
        margin: '2em'
    },
    tableSection: {
        marginTop: '2em'
    },
    noSupplyPlan: {
        backgroundColor: white,
        marginTop: '2em',
        '& .MuiTypography-body1': {
            fontSize: xl,
            fontWeight: boldWeight,
            fontStyle: 'italic',
            color: ballGray
        },
        '& .MuiPaper-root': {
            padding: '3em'
        }
    },
    cancelButton: {
        textTransform: 'capitalize',
        color: cancelRed,
        fontSize: medium
    },
    errorDiv: {
        marginLeft: '-20px',
        marginTop: '-10px'
    }
}));

export default function MakeIt() {
    const { id } = useParams<DefaultQueryParams>();
    const editingOrder = id !== undefined;
    const query = useQuery();
    const from = query.get('from');
    const fromBulkUpload = from === 'bulk';
    const fromPlanItSummary = from === 'summary';
    const fromMakeItDraftsSummary = from === 'draft';
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);
    const [currentRangeTypes] = useState([''] as string[]);
    const [storage] = useState<any>(new LocalStorage(makeItGridName));
    const dashboardProducts = useTypedSelector<MakeState>(
        (state) => state.makeItDashboard
    ).products;
    const bulkProducts = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    ).products;
    const { error, shipToId, view, range, loading, requestedDate, loaded } =
        useTypedSelector<MakeState>((state) => state.makeItDashboard);
    const apiError = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    ).error;
    const { selectedAccountId, shipToAccounts, accounts, useOrderingV2 } =
        useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { orders } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { documents } = useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const pricingLoading = useTypedSelector<boolean>((state) => state.contractSummary.loading);
    const [orderingWeeks, setOrderingWeeks] = useState<string[] | undefined>(undefined);
    const [showSpotLegend, setShowSpotLegend] = useState<boolean>(true);

    const {
        atmOrders,
        allocations,
        ordersBySnoSku,
        submitAtmOrdersSubmissionState,
        allocationsAndProducts,
        existingOrders,
        weekToDisplay,
        readonly
    } = useTypedSelector<BulkUploadMakeItATMState>((state) => state.bulkUploadMakeItATMState);
    const [activeDateOrders, setActiveDateOrders] = useState<MakeItBulkATMOrderByWeekAndSku[]>();
    const [expandAll] = useState<ToggleAll>({ toggle: false });
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [poNumber, setPONumber] = useState<string>('');
    const poNumberMaxLength = 25;

    const [orderToEdit, setOrderToEdit] = useState<MakeItBulkATMOrder | undefined>(undefined);

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [showWarnings, setShowWarnings] = useState<boolean>(false);
    const [showCampaigns, setShowCampaigns] = useState<boolean>(false);
    const [showAlerts, setShowAlerts] = useState<boolean>(false);
    const [showAll, setShowAll] = useState<boolean>(true);

    // existing lines by snosku for the current week
    const [existingLinesBySnoSku, setExistingLinesBySnoSku] =
        useState<MakeItBulkATMOrderByWeekAndSku[]>();

    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [navigationPath, setNavigationPath] = useState<undefined | string>();
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);
    const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);
    const [openMoveModal, setOpenMoveModal] = useState<boolean>(false);
    const [addProductsOpen, setAddProductsOpen] = useState(false);
    const [openRemoveGroupModal, setOpenRemoveGroupModal] = useState<boolean>(false);
    const [openBlockedNavigationModal, setOpenBlockedNavigationModal] = useState<boolean>(false);
    const [openSaveDraftModal, setOpenSaveDraftModal] = useState<boolean>(false);

    const [cancelOrderModal, setCancelOrderModal] = useState<boolean>(false);
    const [openFeeModal, setOpenFeeModal] = useState<boolean>(false);
    const [feeModalRecords, setFeeModalRecords] = useState<OrderProductTableRow[]>(
        [] as OrderProductTableRow[]
    );

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [daypickerCopyDate, setDaypickerCopyDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [daypickerMoveDate, setDaypickerMoveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [orderSubmitStatus, setOrderSubmitStatus] = useState<SubmitStatus[]>([]);
    const [selectedWeek, setSelectedWeek] = useState<undefined | Moment>(undefined);
    const [activeGrouping, setActiveGrouping] = useState<MakeItBulkATMLinesBySnoSku>();
    const [formattedWeeks, setFormattedWeeks] = useState<FormattedWeek[]>();
    const [deletedSnoSkus, setDeletedSnoSkus] = useState<DeletedSnoSku[]>();
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const shipToAccountsList = useMemo<FormattedAccount[]>(
        () =>
            !shipToAccounts
                ? []
                : shipToAccounts
                      .filter((account) =>
                          hasPersonasByShipTo(
                              [Persona.MakeItOnly, Persona.OrderFulfillment, Persona.AllAccess],
                              account.accountId,
                              auth.permissions!
                          )
                      )
                      .filter(
                          (account) =>
                              !fromBulkUpload ||
                              (orders &&
                                  orders
                                      .map((o) => o.shipToId)
                                      .includes(parseInt(account.accountId)))
                      )
                      .filter(
                          (account) =>
                              !editingOrder ||
                              (orderToEdit && orderToEdit.shipToId === parseInt(account.accountId))
                      )
                      .filter(
                          (account) =>
                              !fromPlanItSummary ||
                              (atmOrders &&
                                  atmOrders.length > 0 &&
                                  atmOrders[0].shipToId === parseInt(account.accountId))
                      )
                      .map(
                          (account) =>
                              ({
                                  account: account,
                                  state: 'valid'
                              } as FormattedAccount)
                      ),
        [
            shipToAccounts,
            fromBulkUpload,
            orders,
            editingOrder,
            orderToEdit,
            fromPlanItSummary,
            atmOrders
        ]
    );
    const [showShipmentWarning, setShowShipmentWarning] = useState<boolean>(false);
    const isBillToAccount = useTypedSelector<boolean>(selectIsBillToAccount);

    const customerPoInputIsValid = useMemo(() => {
        return poNumber && customerPoIsValid(poNumber);
    }, [poNumber]);

    const showInvalidCharactersWarning = useMemo(() => {
        return (
            // eslint-disable-next-line no-useless-escape
            poNumber && poNumber.length > 0 && !poNumber.match(/^[a-zA-Z0-9\/_\-\:. ',#()+&;=]*$/)
        );
    }, [poNumber]);

    useEffect(() => {
        const account = accounts?.find((acc) => acc.accountId === selectedAccountId);
        if (selectedAccountId && account && (account?.listCustomer || !isBillToAccount)) {
            dispatch(loadContractsAndDocuments([selectedAccountId], !isBillToAccount));
        } else {
            dispatch(clearContractsAndDocuments());
        }
    }, [selectedAccountId, isBillToAccount, accounts]);

    const someCurrentlyActiveDocumentIsUnapproved = useMemo(() => {
        const currentlyActiveDocuments = getCurrentlyActiveDocuments(documents);
        const someCurrentlyActiveDocumentIsUnapproved = currentlyActiveDocuments.some(
            (d) => !d.isApproved
        );

        return someCurrentlyActiveDocumentIsUnapproved;
    }, [documents]);

    // get unique weeks from allocations array
    const weeks = useMemo(() => {
        if (editingOrder) {
            return orderToEdit ? [moment(orderToEdit?.weekStart)] : [];
        } else {
            const weeks = !!atmOrders
                ? atmOrders
                      .filter(
                          (a) => selectedAccountId && a.shipToId === parseInt(selectedAccountId)
                      )
                      ?.map((a) => moment(a.weekStart))
                      .sort((a, b) => a.diff(b))
                : [];
            return weeks;
        }
    }, [editingOrder, orderToEdit, selectedAccountId, atmOrders]);

    const allocationStateByWeek = useMemo(() => {
        if (!ordersBySnoSku || !allocations) return [];

        // for each week
        return weeks.map((week) => {
            // get allocation quantity for week
            const allocationsForWeek = allocations?.filter(
                (a) =>
                    moment(a.week).isSame(week, 'day') &&
                    a.shipToId === parseInt(selectedAccountId!)
            );
            const linesForWeekOrder = ordersBySnoSku
                .filter(
                    (order) =>
                        moment(order.weekStart).isSame(moment(week), 'week') &&
                        order.shipToId === parseInt(selectedAccountId!)
                )
                .map((p) => p.linesBySnoSku)
                .flat();

            const orderQuantitiesBySnoSku: MakeItBulkATMLinesBySnoSku[] = [];

            linesForWeekOrder.forEach((line) => {
                let isCanType = false;
                if (line.lines.length > 0) {
                    isCanType = isProductCanType(line.lines[0].type);
                }

                const matchingLines = orderQuantitiesBySnoSku.filter(
                    (f) => f.snoSku === line.snoSku
                );
                // ignore allocation state for End types
                if (isCanType) {
                    if (matchingLines.length === 0) {
                        orderQuantitiesBySnoSku.push(line);
                    } else {
                        matchingLines[0].totalPalletQuantity += line.totalPalletQuantity;
                        matchingLines[0].lines = matchingLines[0].lines.concat(line.lines);
                    }
                }
            });
            let allocationState: AllocationState = [];
            orderQuantitiesBySnoSku.forEach((orderQuantity) => {
                const allocation = allocationsForWeek.find(
                    (allocation) => allocation.snoSku === orderQuantity.snoSku
                );
                // does not have matching supply plan
                if (allocation) {
                    if (allocation.quantity === 0) {
                        //has an enforced supply plan with quantity of 0
                        allocationState.push('NONE');
                    } else if (orderQuantity.totalPalletQuantity < allocation.quantity) {
                        allocationState.push('UNDER');
                    } else if (orderQuantity.totalPalletQuantity > allocation.quantity) {
                        allocationState.push('OVER');
                    } else {
                        allocationState.push('MET');
                    }
                }
            });
            // remove duplicate allocation states
            allocationState = allocationState.filter(
                (value, index) => allocationState.indexOf(value) === index
            );
            return {
                week: week.format('MM/DD/YYYY'),
                state: allocationState
            };
        });
    }, [ordersBySnoSku, allocations, weeks]);

    const isSubmitDisabled = useMemo(() => {
        // evaluate orders for all shipTos
        if (!atmOrders || !ordersBySnoSku) return true;

        // return false if at least one order:
        // 1. has a supply plan or account is exempt from allocations
        // 2. is not over quantity or account is exempt from allocations
        // 3. has a PO number assigned
        // 4. an End type can be submitted without supply plan

        const submitStatusByShipTo: SubmitStatus[] = shipToAccountsList.map((account) => {
            return {
                shipToId: account.account.accountId,
                shipToName: account.account.name.substring(0, 100),
                weeks: [] as WeekStatus[]
            };
        });
        ordersBySnoSku.forEach((order) => {
            const orderStatus: WeekStatus = {
                week: moment(order.weekStart).format('MM/DD/YYYY'),
                errorConditions: []
            };
            order.linesBySnoSku.forEach((lineBySnoSku) => {
                // Add each error condition only once
                if (lineBySnoSku.allocationAmount !== undefined) {
                    const allocationState = evaluateAllocationQuantityValid(lineBySnoSku);
                    if (
                        allocationState === 'aboveAllocationError' &&
                        !orderStatus.errorConditions.includes('over')
                    ) {
                        orderStatus.errorConditions.push('over');
                    }
                }
                if (lineBySnoSku.lines.length > 0) {
                    const selectedAtmOrder = atmOrders.find(
                        (atmOrder) =>
                            moment(atmOrder.weekStart).format('MM/DD/YYYY') ===
                                moment(order.weekStart).format('MM/DD/YYYY') &&
                            atmOrder.shipToId === order.shipToId
                    );
                    if (
                        selectedAtmOrder &&
                        !customerPoIsValid(selectedAtmOrder.customerProductionOrderId)
                    ) {
                        if (!orderStatus.errorConditions.includes('noPurchaseOrderNumber')) {
                            orderStatus.errorConditions.push('noPurchaseOrderNumber');
                        }
                    }
                    if (
                        selectedAtmOrder &&
                        selectedAtmOrder.lines.filter((line) => line.palletQuantity === undefined)
                            .length > 0
                    ) {
                        if (!orderStatus.errorConditions.includes('quantityUndefined')) {
                            orderStatus.errorConditions.push('quantityUndefined');
                        }
                    }
                    if (
                        selectedAtmOrder &&
                        selectedAtmOrder.lines.filter(
                            (line) => line.minimumMet === false && line.withinRange === false
                        ).length > 0
                    ) {
                        if (!orderStatus.errorConditions.includes('belowMinimumQuantity')) {
                            orderStatus.errorConditions.push('belowMinimumQuantity');
                        }
                    }
                }
            });
            const selectedStatus = submitStatusByShipTo.find(
                (status) => status.shipToId === order.shipToId.toString()
            );
            if (selectedStatus) {
                selectedStatus.weeks.push(orderStatus);
            }
        });
        const validOrders = submitStatusByShipTo
            .filter(
                (shipTo) =>
                    shipTo.weeks.length > 0 &&
                    shipTo.weeks.some((week) => week.errorConditions.length === 0)
            )
            .map((order) => {
                return {
                    shipToId: order.shipToId,
                    shipToName: order.shipToName,
                    weeks: order.weeks.filter((week) => week.errorConditions.length === 0)
                };
            });
        setOrderSubmitStatus(submitStatusByShipTo);
        return validOrders.length === 0;
    }, [atmOrders, ordersBySnoSku]);

    const showSameWeekShipTosWarning = useMemo(() => {
        const existingOrdersForSelectedWeek = existingLinesBySnoSku?.filter(
            (lines) =>
                moment(lines.weekStart).format('MM/DD/YYYY') === activeDate.fullDate &&
                (!id || lines.productionOrderId !== +id)
        );
        if (existingOrdersForSelectedWeek && existingOrdersForSelectedWeek.length > 0) {
            return true;
        } else {
            return false;
        }
    }, [existingLinesBySnoSku, activeDate]);

    useEffect(() => {
        if (selectedAccountId && atmOrders && fromBulkUpload) {
            if (!atmOrders.map((order) => order.shipToId).includes(parseInt(selectedAccountId))) {
                if (atmOrders.length > 0) {
                    //change to one of the shiptos in the file
                    const shipto = atmOrders[0].shipToId;
                    dispatch(selectCustomerAccountId(shipto.toString()));
                }
            }
        }
    }, [selectedAccountId, atmOrders, fromBulkUpload]);

    useEffect(() => {
        setShowShipmentWarning(
            !!atmOrders?.some((o) =>
                o.lines.some(
                    (l) =>
                        editingOrder &&
                        !fromPlanItSummary &&
                        (l.hasLinkedDeliveryOrder || l.isRequestedDateAfterLeadTime)
                )
            )
        );
        // see if any deletes snoskus have been added back
        if (atmOrders && deletedSnoSkus && deletedSnoSkus.length > 0) {
            const updatedDeletedSnoSkus = deletedSnoSkus.filter((deletedSnoSku) => {
                const atmOrder = atmOrders.find(
                    (order) =>
                        order.shipToId === deletedSnoSku.shipToId &&
                        moment(order.weekStart).format('MM/DD/YYYY') === deletedSnoSku.date
                );
                const lines =
                    atmOrder &&
                    atmOrder.lines.filter((line) => line.snoSku === deletedSnoSku.snoSku);
                return lines?.every((line) => line.deleted === true);
            });
            setDeletedSnoSkus(updatedDeletedSnoSkus);
        }
    }, [atmOrders]);

    const handleBlockedNavigation = (location) => {
        if (submitAtmOrdersSubmissionState === 'success') {
            return true;
        } else {
            if (location !== history.location.pathname) setLastLocationPath(location);
            if (
                !allowNavigation &&
                !openCancelModal &&
                !openBackModal &&
                atmOrders &&
                atmOrders.some((order) =>
                    order.lines?.some((line) => (line.palletQuantity ?? 0) > 0)
                )
            ) {
                setOpenBlockedNavigationModal(true);
                return false;
            }
            return true;
        }
    };
    const handleConfirmNavigation = () => {
        dispatch(resetMakeItBulkATMState());
        setOpenBlockedNavigationModal(false);

        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            setNavigationPath(lastLocationPath);
        }
    };

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else if (openSubmitModal) {
            setOpenSubmitModal(false);
        } else if (openCancelModal) {
            setOpenCancelModal(false);
        } else if (openCopyModal) {
            setOpenCopyModal(false);
        } else if (openMoveModal) {
            setOpenMoveModal(false);
        } else if (openRemoveGroupModal) {
            setOpenRemoveGroupModal(false);
        } else if (openSaveDraftModal) {
            setOpenSaveDraftModal(false);
        } else if (addProductsOpen) {
            setAddProductsOpen(false);
        } else {
            setOpenBlockedNavigationModal(false);
        }
    };

    const handleCancel = () => {
        setOpenCancelModal(false);
        if (fromPlanItSummary) {
            history.push('/plan-it-summary');
        } else if (fromBulkUpload) {
            history.push('/make-it-bulk-upload');
        } else if (fromMakeItDraftsSummary) {
            history.push('/make-it-drafts-summary');
        } else {
            history.push('/');
        }
    };

    const onSaveDraft = () => {
        setOpenBlockedNavigationModal(false);
        setOpenSaveDraftModal(true);
    };

    const onCloseBlockedNavigationModal = () => {
        setOpenBlockedNavigationModal(false);
    };

    const onCloseFeeModal = () => {
        setOpenFeeModal(false);
    };

    const onAcceptFee = () => {
        handleOpenSubmitModal();
    };

    const getProductsWithFees = (products: MakeItBulkATMLineItem[]) => {
        return products.filter((product) => {
            let absoluteMinimum: number | undefined = undefined;
            if ((product.moqFees && product.moqFees.length > 0) || product.minimumOrderQuantity) {
                absoluteMinimum =
                    product.moqFees && product.moqFees.length > 0
                        ? getMoqFeeFloor(product.moqFees)
                        : product.minimumOrderQuantity;
            }

            return (
                product.palletQuantity &&
                product.palletQuantity > 0 &&
                !product.deleted &&
                absoluteMinimum &&
                product.palletQuantity >= absoluteMinimum &&
                product.minimumOrderQuantity &&
                product.palletQuantity < product.minimumOrderQuantity
            );
        });
    };

    const handleOpenSubmitModal = () => {
        if (!isImpersonation) {
            setOpenSubmitModal(true);
        } else {
            setShowImpersonationWarning(true);
        }
    };

    const handleSubmitClick = () => {
        //determine whether any items require fees
        const allLines = atmOrders && atmOrders.map((atmOrder) => atmOrder.lines).flat();
        const productsWithFees =
            allLines && (getProductsWithFees(allLines) as OrderProductTableRow[]);

        if (productsWithFees && productsWithFees.length > 0) {
            setFeeModalRecords(
                productsWithFees.sort((a, b) =>
                    moment(a.requestedDate).isSame(moment(b.requestedDate))
                        ? (a.displayId && b.displayId && a.displayId.localeCompare(b.displayId)) ||
                          0
                        : moment(a.requestedDate).diff(moment(b.requestedDate))
                )
            );
            setOpenFeeModal(true);
        } else {
            handleOpenSubmitModal();
        }
    };

    const handleSubmit = () => {
        setIsSubmitting(true);
        if (fromMakeItDraftsSummary) {
            dispatch(submitDraftAsMakeIt(setIsSubmitting));
        } else {
            dispatch(submitAtmOrders(setIsSubmitting));
        }
        setOpenSubmitModal(false);
    };

    const onMakeItBulkClicked = () => {
        history.push('/make-it-bulk-upload');
    };

    useEffect(() => {
        let selectedAllocations: SnoSkuAllocationsByWeek[] = [];
        if (allocations && selectedAccountId) {
            selectedAllocations = allocations.filter(
                (alloc) => alloc.shipToId === parseInt(selectedAccountId)
            );
        }
        if (selectedAllocations.length === 0) {
            setShowSpotLegend(false);
        } else {
            setShowSpotLegend(true);
        }
    }, [allocations, selectedAccountId]);

    useEffect(() => {
        if (submitAtmOrdersSubmissionState === 'success') {
            history.push('/make-it-bulk-po-confirmation?from=spot');
            setOpenBlockedNavigationModal(false);
        }
    }, [history, submitAtmOrdersSubmissionState]);

    const handleCopyButtonClicked = useCallback((grouping: MakeItBulkATMLinesBySnoSku) => {
        setActiveGrouping(grouping);
        setOpenCopyModal(true);
    }, []);

    const handleRemoveGroupButtonClicked = useCallback((grouping: MakeItBulkATMLinesBySnoSku) => {
        setActiveGrouping(grouping);
        setOpenRemoveGroupModal(true);
    }, []);

    const handleMoveButtonClicked = useCallback((grouping: MakeItBulkATMLinesBySnoSku) => {
        setActiveGrouping(grouping);
        setOpenMoveModal(true);
    }, []);

    const handleCopy = () => {
        if (
            !!activeGrouping &&
            daypickerCopyDate.fullDate !==
                moment.utc(activeDate.fullDate).startOf('day').format('MM/DD/YYYY')
        ) {
            dispatch(
                copyAtmOrderBySnoSku(
                    moment.utc(activeDate.fullDate).startOf('day').format('MM/DD/YYYY'),
                    activeGrouping?.snoSku,
                    daypickerCopyDate.fullDate
                )
            );
            setSelectedWeek(moment.utc(daypickerCopyDate.fullDate));
        }
        setOpenCopyModal(false);
    };

    const handleMove = () => {
        if (
            !!activeGrouping &&
            daypickerMoveDate.fullDate !==
                moment.utc(activeDate.fullDate).startOf('day').format('MM/DD/YYYY')
        ) {
            dispatch(
                copyAtmOrderBySnoSku(
                    moment.utc(activeDate.fullDate).startOf('day').format('MM/DD/YYYY'),
                    activeGrouping?.snoSku,
                    daypickerMoveDate.fullDate,
                    true
                )
            );
            setSelectedWeek(moment.utc(daypickerMoveDate.fullDate));
        }
        setOpenMoveModal(false);
    };

    const handleRemoveGroup = () => {
        const products: OrderProductTableRow[] = fromBulkUpload
            ? bulkProducts
                ? (bulkProducts as OrderProductTableRow[])
                : []
            : dashboardProducts
            ? dashboardProducts
            : [];
        if (!!activeDateOrders && !!activeGrouping && !!products && !!selectedAccountId) {
            const filteredProducts = products.filter((product) => {
                return product?.snoSku === activeGrouping.snoSku;
            });
            filteredProducts.forEach((product) => {
                if (!!product.productSku) {
                    let dateWeek = moment.utc(activeDate.fullDate).startOf('day').format();
                    dateWeek = dateWeek.substring(0, dateWeek.length - 1); // Match format to weekStart date
                    dispatch(
                        deleteProductFromAtmOrder(
                            product.productSku,
                            dateWeek,
                            parseInt(selectedAccountId)
                        )
                    );
                }
            });
            activeDateOrders.forEach((order) => {
                const lineToDelete =
                    order.linesBySnoSku.find((line) => line.snoSku === activeGrouping.snoSku) ??
                    null;
                if (lineToDelete) {
                    order.linesBySnoSku.splice(order.linesBySnoSku.indexOf(lineToDelete), 1);
                }
            });
            const deletedSkus = deletedSnoSkus ?? [];
            deletedSkus.push({
                shipToId: parseInt(selectedAccountId),
                snoSku: activeGrouping.snoSku,
                date: moment.utc(activeDate.fullDate).startOf('day').format('MM/DD/YYYY')
            });
            setDeletedSnoSkus(deletedSkus);
            setOpenRemoveGroupModal(false);
        }
    };

    useEffect(() => {
        if (id && atmOrders && atmOrders.length === 1) {
            setOrderToEdit(atmOrders[0]);
            setPONumber(atmOrders[0].customerProductionOrderId);
        }
    }, [id, atmOrders]);

    useEffect(() => {
        if (selectedWeek) {
            setActiveDate(getDateProperties(selectedWeek));
            setDaypickerCopyDate(getDateProperties(selectedWeek));
            setDaypickerMoveDate(getDateProperties(selectedWeek));
        }
    }, [selectedWeek]);

    useEffect(() => {
        if (loaded) {
            // products from the makeItDashboard need to be also in
            // bulkUploadMakeItState for shared
            // MakeItBulkATMAddProducts component
            if (from === null || fromPlanItSummary) {
                // don't update product if fromBulkUpload = true
                dispatch({ type: MAKEIT_BULK_UPLOAD_GET_PRODUCTS, products: dashboardProducts });
            }
        }
    }, [dispatch, loaded, dashboardProducts, from, fromPlanItSummary]);

    useEffect(() => {
        const smallestLeadTime = (products) => {
            return products
                .filter((product) => product.type && isProductCanType(product.type))
                .map((product) => product.leadTimeWeeks)
                .reduce((prev, cur) => (prev <= cur ? prev : cur), 0);
        };
        const assignFirstOrderingDate = (leadTime) => {
            const startDate =
                moment().startOf('day').isoWeekday() === 1
                    ? moment()
                    : moment().add(7, 'days').startOf('isoWeek');
            const earliestOrderingDay = startDate.add(leadTime, 'weeks').format('MM/DD/YYYY');
            const weeks: string[] = [];
            [0, 1, 2, 3].forEach((weekIndex) => {
                const week = moment(earliestOrderingDay).add(weekIndex, 'weeks');
                weeks.push(week.format('MM/DD/YYYY'));
            });
            setSelectedWeek(undefined);
            setOrderingWeeks(weeks);
        };
        if (selectedAccountId) {
            let leadTime: number | undefined = undefined;
            if (
                !fromBulkUpload &&
                !fromMakeItDraftsSummary &&
                dashboardProducts &&
                dashboardProducts.length > 0
            ) {
                leadTime = smallestLeadTime(dashboardProducts);
                assignFirstOrderingDate(leadTime);
            } else {
                setOrderingWeeks(undefined);
            }
        }
    }, [selectedAccountId, fromBulkUpload, fromMakeItDraftsSummary, dashboardProducts]);

    useEffect(() => {
        if (useOrderingV2) {
            history.push('/dashboard');
        }
    }, [useOrderingV2, history]);

    useEffect(() => {
        if (selectedAccountId && loaded && allocations) {
            if (!editingOrder && !fromBulkUpload) {
                if (!fromPlanItSummary) {
                    if (fromMakeItDraftsSummary) {
                        dispatch(loadProductsFromDraft());
                    } else {
                        if (orderingWeeks) {
                            dispatch(loadProductsByProductList(orderingWeeks));
                        }
                    }
                } else {
                    dispatch(loadProductsFromPlanIt());
                }
            }
        }
    }, [
        selectedAccountId,
        loaded,
        allocations,
        fromPlanItSummary,
        fromBulkUpload,
        editingOrder,
        fromMakeItDraftsSummary,
        orderingWeeks
    ]);

    useEffect(() => {
        if (bulkProducts && fromBulkUpload) {
            dispatch(loadProductsFromBulkUpload());
        }
    }, [bulkProducts, fromBulkUpload]);

    useEffect(() => {
        if (selectedAccountId && loaded && editingOrder) {
            dispatch(loadProductsFromOrder(id));
        }
    }, [selectedAccountId, loaded, editingOrder]);

    useEffect(() => {
        if (!!weeks && !!allocations && !!activeGrouping) {
            let isEnd = false;
            if (!!activeGrouping.lines.length && activeGrouping.lines.length > 0) {
                if (!!activeGrouping.lines[0].type) {
                    isEnd = isProductEndType(activeGrouping.lines[0].type);
                }
            }
            const filteredAllocations = allocations.filter(
                (allocation) => allocation.snoSku === activeGrouping.snoSku
            );
            let formattedWeeksObject: FormattedWeek[] = [];
            weeks.forEach((week) => {
                const foundAllocation = filteredAllocations.find(
                    (allocation) =>
                        moment.utc(allocation.week).format('YYYY-MM-DD') ===
                        week.format('YYYY-MM-DD')
                );
                if (
                    (!!foundAllocation && foundAllocation.quantity > 0) ||
                    isEnd ||
                    !foundAllocation ||
                    foundAllocation?.ignoreAllocation === true
                ) {
                    formattedWeeksObject.push({ weekAsDate: week, state: 'valid' });
                }
            });
            setFormattedWeeks(formattedWeeksObject);
        }
    }, [weeks, allocations, activeGrouping]);

    useEffect(() => {
        if (existingOrders && allocations) {
            setExistingLinesBySnoSku(groupOrdersBySnoSku(existingOrders, allocations));
        }
    }, [existingOrders, allocations]);

    useEffect(() => {
        if (atmOrders && allocationsAndProducts && existingLinesBySnoSku) {
            let combinedOrdersBySnoSku: MakeItBulkATMOrderByWeekAndSku[] = [];
            const ordersByWeekAndSku = groupOrdersBySnoSku(atmOrders, allocationsAndProducts);
            atmOrders.forEach((atmOrder) => {
                const linesBySnoSkuForThisWeek = existingLinesBySnoSku
                    .filter(
                        (lines) =>
                            lines.shipToId === atmOrder.shipToId &&
                            moment(lines.weekStart).format('MM/DD/YYYY') ===
                                moment(atmOrder.weekStart).format('MM/DD/YYYY') &&
                            lines.productionOrderId !== +id
                    )
                    .map((line) => line.linesBySnoSku)
                    .flat();

                const ordersByWeekAndSkuForWeek = ordersByWeekAndSku.filter(
                    (lines) =>
                        lines.shipToId === atmOrder.shipToId &&
                        moment(lines.weekStart).format('MM/DD/YYYY') ===
                            moment(atmOrder.weekStart).format('MM/DD/YYYY')
                );
                const ordersWithTotals = addQuantitiesFromOtherOrders(
                    atmOrder,
                    ordersByWeekAndSkuForWeek ? ordersByWeekAndSkuForWeek : [],
                    linesBySnoSkuForThisWeek
                );
                combinedOrdersBySnoSku = combinedOrdersBySnoSku.concat(ordersWithTotals);
            });
            if (ordersBySnoSku) {
                // restore the isActive status from the existing ordersBySnoSku
                combinedOrdersBySnoSku.forEach((newOrder) => {
                    const targetOrder = ordersBySnoSku.find(
                        (order) =>
                            order.weekStart === newOrder.weekStart &&
                            order.shipToId === newOrder.shipToId
                    );
                    newOrder.linesBySnoSku.forEach((line) => {
                        const targetLine = targetOrder?.linesBySnoSku.find(
                            (existingLine) =>
                                existingLine.snoSku && existingLine.snoSku === line.snoSku
                        );
                        if (targetLine) {
                            line.isActive = targetLine.isActive;
                        }
                    });
                });
            }
            combinedOrdersBySnoSku.forEach((order) => {
                const selectedAtmOrder = atmOrders.find(
                    (atmOrder) =>
                        atmOrder.shipToId === order.shipToId &&
                        moment(atmOrder.weekStart).format('MM/DD/YYYY') ===
                            moment(order.weekStart).format('MM/DD/YYYY')
                );
                order.state = selectedAtmOrder
                    ? selectedAtmOrder.customerProductionOrderId &&
                      selectedAtmOrder.customerProductionOrderId.length > 0
                        ? getWeekValidationState(order.linesBySnoSku)
                        : 'error'
                    : 'error';
                if (!fromBulkUpload) {
                    order.linesBySnoSku.forEach((line) => {
                        if (line.lines.length > 0) {
                            if (isProductEndType(line.lines[0].type)) {
                                const sumPalletQuantity = line.lines
                                    .map((l) => l.palletQuantity)
                                    .reduce((prev, cur) => {
                                        return (prev ?? 0) + (cur ?? 0);
                                    });
                                // for END type groups, collapse if all quantities are zero
                                if (sumPalletQuantity === 0) {
                                    line.isActive = false;
                                }
                            }
                        }
                    });
                }
            });

            if (deletedSnoSkus) {
                combinedOrdersBySnoSku.forEach((order) => {
                    let lines: MakeItBulkATMLinesBySnoSku[] = [];
                    order.linesBySnoSku.forEach((line) => {
                        const foundSnoSku = deletedSnoSkus.find(
                            (item) =>
                                item.date === order.weekStart &&
                                item.snoSku === line.snoSku &&
                                item.shipToId === order.shipToId
                        );
                        if (!foundSnoSku) {
                            lines.push(line);
                        }
                    });
                    order.linesBySnoSku = lines;
                });
            }
            // sort with enforced allocations at the top
            if (allocationsAndProducts && allocationsAndProducts.length > 0) {
                combinedOrdersBySnoSku.forEach((ordersBySnoSku) => {
                    const getLinesBySnoSkuSortProperty = (
                        linesBySnoSku: MakeItBulkATMLinesBySnoSku
                    ): string => {
                        let isEndType = false;
                        if (linesBySnoSku.lines.length > 0) {
                            if (isProductEndType(linesBySnoSku.lines[0].type)) {
                                isEndType = true;
                            }
                        }
                        const alloc = allocationsAndProducts.find(
                            (alloc) =>
                                alloc.shipToId === ordersBySnoSku.shipToId &&
                                moment(alloc.week).format('MM/DD/YYYY') ===
                                    moment(ordersBySnoSku.weekStart).format('MM/DD/YYYY') &&
                                alloc.snoSku === linesBySnoSku.snoSku
                        );
                        if (isEndType) {
                            return `c_${linesBySnoSku.snoSku}`;
                        } else if (
                            alloc &&
                            alloc.ignoreAllocation === false &&
                            alloc.quantity > 0
                        ) {
                            return `a_${linesBySnoSku.snoSku}`;
                        } else {
                            return `b_${linesBySnoSku.snoSku}`;
                        }
                    };
                    ordersBySnoSku.linesBySnoSku.sort((a, b) =>
                        getLinesBySnoSkuSortProperty(a).localeCompare(
                            getLinesBySnoSkuSortProperty(b)
                        )
                    );
                });
            }
            dispatch(saveOrdersBySnoSku(combinedOrdersBySnoSku));
        }
    }, [atmOrders, allocationsAndProducts, existingLinesBySnoSku, deletedSnoSkus, dispatch]);

    useEffect(() => {
        if (selectedWeek && atmOrders && selectedAccountId) {
            const selectedOrder = atmOrders.filter(
                (order) =>
                    order.weekStart === selectedWeek.format('MM/DD/YYYY') &&
                    order.shipToId === parseInt(selectedAccountId)
            );
            if (selectedOrder.length > 0) {
                setPONumber(selectedOrder[0].customerProductionOrderId);
            } else {
                setPONumber('');
            }
        }
    }, [selectedWeek, atmOrders, selectedAccountId]);

    useEffect(() => {
        if (!selectedAccountId) return;
        // If Ship To ID changes, requery the products
        if (selectedAccountId !== shipToId && !error) {
            setSelectedWeek(undefined);
            if (!!requestedDate) {
                const date = requestedDate;
                dispatch(resetMakeItState());
                dispatch(loadMakeDashboard());
                setCurrentViewTypes(['']);
                dispatch(setRequestedMakeItWeek(moment(date, 'MM/DD/YYYY')));
            } else {
                dispatch(resetMakeItState());
                dispatch(loadMakeDashboard(false, fromPlanItSummary ? false : true));
                setCurrentViewTypes(['']);
            }
        }

        if (selectedAccountId === shipToId && dashboardProducts?.length === 0) {
            dispatch(loadMakeDashboard());
        }

        //Make sure selectedShipToId and shipToId are both defined
        //Then, if selectedShipToId changes, reset pagination rows to default and reset search
        if (selectedAccountId && shipToId && selectedAccountId !== shipToId) {
            storage.setTextSearch('');
        }
    }, [selectedAccountId, shipToId, error, dashboardProducts?.length, dispatch, storage]);

    useEffect(() => {
        // ensure dashboard has loaded if not from Bulk
        if (!fromBulkUpload && !editingOrder && loaded) {
            // ensure products are loaded
            const startDate =
                moment().startOf('day').isoWeekday() === 1
                    ? moment().format('MM/DD/YYYY')
                    : moment().add(7, 'days').startOf('isoWeek').format('MM/DD/YYYY');
            dispatch(
                getAllocationsWithShipTos(
                    startDate,
                    moment().add(1, 'year').format('MM/DD/YYYY'),
                    [parseInt(selectedAccountId!)],
                    true
                )
            );
        }
    }, [loaded, fromBulkUpload, editingOrder]);

    useEffect(() => {
        if (loaded) {
            // ensure products are loaded
            dispatch(makeItDashboardUpdateView([''], MAKEIT_DASHBOARD_UPDATE_VIEW));
        }
    }, [loaded]);

    useEffect(() => {
        if (loaded && dashboardProducts && selectedAccountId && !fromBulkUpload) {
            dispatch(
                getExistingOrders(
                    [parseInt(selectedAccountId)],
                    dashboardProducts,
                    moment(),
                    moment().add(1, 'year')
                )
            );
        }
    }, [loaded, dashboardProducts, selectedAccountId, fromBulkUpload]);

    useEffect(() => {
        if (bulkProducts && fromBulkUpload && orders) {
            const shipToIds = orders.map((order) => order.shipToId);
            dispatch(
                getExistingOrders(
                    shipToIds,
                    bulkProducts as OrderProductTableRow[],
                    moment(),
                    moment().add(1, 'year')
                )
            );
        }
    }, [bulkProducts, fromBulkUpload, orders]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(makeItDashboardUpdateView(currentViewTypes, MAKEIT_DASHBOARD_UPDATE_VIEW));
        }
        if (JSON.stringify(currentRangeTypes) !== JSON.stringify(range)) {
            dispatch(makeItDashboardUpdateRange(currentRangeTypes, MAKEIT_DASHBOARD_UPDATE_RANGE));
        }
    }, [currentViewTypes, currentRangeTypes, view, range, dispatch]);

    useEffect(() => {
        if (!selectedWeek && !!weeks.length) {
            if (!fromBulkUpload && !fromPlanItSummary) {
                setSelectedWeek(moment.utc(weeks[0]));
            } else if (!!weekToDisplay && selectedAccountId) {
                //If the user tries to copy a plan it with lead time violations
                if (weekToDisplay[0] === 'NO_VALID_DATE') {
                    setSelectedWeek(moment.utc(weeks[0]));
                } else {
                    //to handle multiple shipTo's for bulk upload
                    const formattedWeeks = weeks.map((week) =>
                        moment.utc(week).format('MM/DD/YYYY')
                    );
                    const firstWeek = weekToDisplay.filter((week) =>
                        formattedWeeks.includes(week)
                    )[0];
                    setSelectedWeek(moment.utc(firstWeek));
                }
            }
        }
    }, [weeks, weekToDisplay, selectedAccountId]);

    const handleWeekAdded = (week: Moment) => {
        setSelectedWeek(week);
    };

    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length > 0 && activeDate && selectedAccountId) {
            const ordersBySnoSkuUpdate = JSON.parse(JSON.stringify(ordersBySnoSku));
            const activeOrders = ordersBySnoSkuUpdate.filter((week) => {
                return (
                    getDateProperties(moment(week.weekStart)).week === activeDate.week &&
                    week.shipToId === parseInt(selectedAccountId)
                );
            });

            let formattedOrders: Array<MakeItBulkATMLinesBySnoSku> = [];

            activeOrders.forEach((order) => {
                order.linesBySnoSku.forEach((productGroup) => {
                    const palletQuantityStates: PalletQuantityState[] = [];
                    let hasDuplicateProducts = false;
                    productGroup.lines.forEach((line) => {
                        if (line.minimumOrderQuantity && !line.deleted) {
                            palletQuantityStates.push(
                                evaluatePalletQuantityValid(
                                    line.palletQuantity,
                                    line.minimumOrderQuantity,
                                    getFeeStructure(line),
                                    getMoqFeeFloor(line.moqFees),
                                    getMoqFeeCeiling(line.moqFees)
                                )
                            );
                        }
                        if (!line.isCustomerProductIdDistinct) {
                            hasDuplicateProducts = true;
                        }
                    });

                    productGroup.hasError =
                        palletQuantityStates.some(
                            (state) =>
                                state === 'minimumOrderQuantityError' ||
                                state === 'undefinedQuantity'
                        ) || hasDuplicateProducts;
                    productGroup.hasWarning = palletQuantityStates.some(
                        (state) => state === 'feeWarning'
                    );
                    if (!orderToEdit && !fromPlanItSummary && !fromBulkUpload) {
                        productGroup.totalPalletQuantity =
                            productGroup.totalPalletQuantity -
                            productGroup.lines.reduce(
                                (prev, cur) => (prev += cur.originalPalletQuantity ?? 0),
                                0
                            );
                    }
                    if (productGroup.allocationAmount !== undefined) {
                        if (productGroup.totalPalletQuantity < productGroup.allocationAmount) {
                            productGroup.hasWarning = true;
                        }
                        if (productGroup.totalPalletQuantity > productGroup.allocationAmount) {
                            productGroup.hasError = true;
                        }
                    }
                    formattedOrders.push(productGroup);
                });
                order.linesBySnoSku = formattedOrders;
            });
            setActiveDateOrders(activeOrders);
        }
    }, [ordersBySnoSku, activeDate, selectedAccountId]);

    useEffect(() => {
        if (
            (allowNavigation ||
                !atmOrders ||
                !atmOrders.some((order) =>
                    order.lines?.some((line) => (line.palletQuantity ?? 0) > 0)
                )) &&
            navigationPath !== undefined
        ) {
            history.push(navigationPath);
        }
    }, [allowNavigation, navigationPath]);

    const updatePoNumber = () => {
        if (!!selectedWeek) {
            dispatch(
                updateCustomerProductionOrderNumber(
                    poNumber,
                    selectedWeek.format('MM/DD/YYYY'),
                    parseInt(selectedAccountId!)
                )
            );
        }
    };

    const cancelOrder = () => {
        setCancelOrderModal(false);
        dispatch(cancelProductionOrder(onOrderCancelled));
    };

    const onOrderCancelled = (success: boolean) => {
        if (success) {
            setAllowNavigation(true);
            setNavigationPath('/make-it-summary');
        }
    };

    const onAddProductsOpen = () => {
        setAddProductsOpen(true);
    };

    useTranslation();

    const ballProdOrderAndWeek = useMemo(() => {
        let ballProductionOrder = ' ' + t('pending', 'Pending');
        if (editingOrder) {
            ballProductionOrder = orderToEdit?.productionOrderNumber?.toString() ?? '';
        }
        return `${t('ballProdOrder', 'Ball Production Order')} #${ballProductionOrder} ${t(
            'forTheWeekOf',
            'for the week of'
        )} ${moment.utc(selectedWeek).format('MM/DD')}`;
    }, [orderToEdit, selectedWeek, editingOrder, t]);

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={handleCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="saveDraft-btn"
                    className={classes.actionBtn}
                    onClick={onSaveDraft}
                    disabled={readonly}
                >
                    <Trans i18nKey="saveDraft">Save Draft</Trans>
                </Button>
            </Grid>
            <Grid
                container
                item
                xs={7}
                className={clsx({ [classes.errorDiv]: !showImpersonationWarning })}
            >
                <ErrorAlert
                    errorMessage={
                        <Trans i18nKey="submitATMError">
                            The system was unable to process your changes.
                        </Trans>
                    }
                    showError={apiError === 'submitDraft' || apiError === 'submit'}
                    dataTestId="submit-error"
                ></ErrorAlert>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                    noMarginTop={true}
                />
                {someCurrentlyActiveDocumentIsUnapproved ? (
                    <NotificationBanner
                        notificationMessage={t(
                            'pricingWarningText',
                            `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`
                        )}
                        notificationType={'WARNING'}
                    />
                ) : null}
            </Grid>
            <Grid container item xs={2} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    className={classes.actionBtn}
                    onClick={handleCancel}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="mk-submit-btn"
                    className={classes.actionBtn}
                    onClick={handleSubmitClick}
                    disabled={
                        isSubmitDisabled ||
                        isSubmitting ||
                        readonly ||
                        someCurrentlyActiveDocumentIsUnapproved
                    }
                >
                    <Trans i18nKey="submitChanges">Submit Changes</Trans>
                </Button>
            </Grid>
        </>
    );

    const handleWeekClick = (week: Moment) => {
        setSelectedWeek(week);
        const weekOrder = atmOrders?.find((order) => order.weekStart === week.format('MM/DD/YYYY'));
        setPONumber(weekOrder?.customerProductionOrderId ?? '');
    };

    const handleShowErrors = () => {
        setShowWarnings(false);
        setShowAll(false);
        setShowErrors(true);
        setShowCampaigns(false);
        setShowAlerts(false);
    };

    const handleShowWarnings = () => {
        setShowWarnings(true);
        setShowAll(false);
        setShowErrors(false);
        setShowCampaigns(false);
        setShowAlerts(false);
    };

    const handleShowAll = () => {
        setShowWarnings(false);
        setShowAll(true);
        setShowErrors(false);
        setShowCampaigns(false);
        setShowAlerts(false);
    };

    const handleShowAlerts = () => {
        setShowWarnings(false);
        setShowAll(false);
        setShowErrors(false);
        setShowCampaigns(false);
        setShowAlerts(true);
    };

    const makeItSummaryLink = (
        <Link
            to={'/make-it-summary'}
            component={RouterLink}
            underline="none"
            data-testid="make-it-summary-link"
            className={clsx(classes.linkedPO, classes.linkSpacer)}
        >
            Make It Summary
        </Link>
    );

    const productionOrderLink = (
        <Link
            to={`/production-order/${id}`}
            component={RouterLink}
            underline="none"
            data-testid="production-order-link"
            className={clsx(classes.linkedPO, classes.linkSpacer)}
        >
            Make It Details
        </Link>
    );

    const getSameWeekShipTosWarning = (
        <Trans i18nKey="sameWeekShipTosWarning">
            There are additional Make It Order(s) for this week. Please see {makeItSummaryLink}
            page for details.
        </Trans>
    );

    const getShipmentWarning = (
        <Trans i18nKey="existingShipmentWarning">
            One or more products cannot be edited because of associated delivery order(s) or lead
            times. View details of tied delivery orders in the {productionOrderLink}.
        </Trans>
    );

    const checkShouldRender = (order: MakeItBulkATMLinesBySnoSku): Boolean => {
        switch (true) {
            case !order.lines || !order.lines.length:
                return false;
            case showErrors:
                return !!order.hasError;
            case showWarnings:
                return !!order.hasWarning;
            case showCampaigns:
                return order.lines.length > 0 && !!order.lines[0].campaignTiming;
            case showAlerts:
                return (
                    order.lines.length > 0 &&
                    order.lines.some((line) => line.hasLinkedDeliveryOrder)
                );
            default:
                return true;
        }
    };

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('reviewOrder', 'Review Order'),
                thinBanner: true,
                displayDropdown: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            loading={loading || submitAtmOrdersSubmissionState === 'loading' || pricingLoading}
            activity={Activity.MakeIt}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid
                container
                alignItems="flex-start"
                className={classes.dashboard}
                data-testid={'make-it'}
            >
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.warningContainer}
                    data-testid="warning-container"
                >
                    <WarningAlert
                        showWarning={showSameWeekShipTosWarning}
                        warningMessage={getSameWeekShipTosWarning}
                        dataTestId="existing-order-warning"
                    />
                    <WarningAlert
                        showWarning={showShipmentWarning}
                        warningMessage={getShipmentWarning}
                        dataTestId="existing-shipment-warning"
                    />
                </Grid>
                <Grid container className={classes.header}>
                    <Grid item xs={12}>
                        <Grid item xs={9}>
                            <OrderWeekSelector
                                weeks={weeks}
                                onWeekClick={handleWeekClick}
                                onWeekAdded={handleWeekAdded}
                                selectedWeek={selectedWeek ?? moment()}
                                allocationStateByWeek={allocationStateByWeek}
                                showAddWeek={!editingOrder}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container item xs={12} spacing={2} className={classes.actionRow}>
                                <Grid item className={classes.bulkUploadRow}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        variant="outlined"
                                        data-testid="add-products-btn"
                                        className={classes.actionBtn}
                                        onClick={onAddProductsOpen}
                                        disabled={readonly}
                                    >
                                        <Trans i18nKey="addProducts">Add Products</Trans>
                                    </Button>
                                </Grid>
                                <Grid item className={classes.bulkUploadRow}>
                                    {/*!editingOrder && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="outlined"
                                            data-testid="make-it-bulk-btn"
                                            onClick={onMakeItBulkClicked}
                                            className={classes.actionBtn}
                                            disabled={readonly}
                                        >
                                            <Trans i18nKey="bulkUpload">Bulk Upload</Trans>
                                        </Button>
                                    )*/}
                                </Grid>
                            </Grid>
                            {showSpotLegend && <MakeItSpotLegend />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Typography className={classes.mainTitle}>{ballProdOrderAndWeek}</Typography>

                    <Typography className={classes.poLabel}>
                        <Trans i18nKey="customerPoNumLong">Customer PO Number</Trans>
                        <span className={classes.alertMarker}> *</span>
                    </Typography>
                </Grid>
                <Grid container alignItems="center">
                    <TextField
                        label=""
                        variant="standard"
                        onChange={(control) => setPONumber(control.target.value)}
                        value={poNumber}
                        onBlur={updatePoNumber}
                        className={classes.fieldInput}
                        disabled={orderToEdit !== undefined || readonly}
                        inputProps={{
                            maxLength: poNumberMaxLength,
                            'data-testid': 'po-number-input'
                        }}
                        placeholder={t('poNumberPlaceholder', '25 characters or less')}
                        error={!customerPoInputIsValid}
                        helperText={
                            showInvalidCharactersWarning && (
                                <Trans i18nKey="poNumberWarning">
                                    Please enter a Customer PO Number with no special characters
                                </Trans>
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} container className={classes.tableSection}>
                    <Grid container item xs={12} justify="space-between">
                        <Grid item>
                            <ValidationFilterMenu
                                onShowErrors={handleShowErrors}
                                onShowWarnings={handleShowWarnings}
                                onShowAlerts={handleShowAlerts}
                                onShowAll={handleShowAll}
                                showAll={showAll}
                                showErrors={showErrors}
                                showAlerts={showAlerts}
                                showWarnings={showWarnings}
                            />
                        </Grid>
                        <Grid item>
                            {editingOrder && (
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => setCancelOrderModal(true)}
                                    disabled={showShipmentWarning}
                                >
                                    {t('cancelOrder', 'Cancel Order')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        justify="center"
                        alignItems="center"
                        data-testid="product-groupings"
                    >
                        {loading && (
                            <Grid item xs={12} className={classes.noSupplyPlan}>
                                <Paper elevation={2}>
                                    <Grid container alignItems="center" direction="column">
                                        <Grid item>
                                            <Typography>
                                                {t('searchForProducts', 'Searching for products')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        {!weeks?.length && !loading && (
                            <Grid item xs={12} className={classes.noSupplyPlan}>
                                <Paper elevation={2}>
                                    <Grid container alignItems="center" direction="column">
                                        <Grid item>
                                            <Typography>
                                                {t(
                                                    'noSupplyPlanAvailable',
                                                    'No products available'
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        {activeDateOrders &&
                            activeDateOrders.map((order) => {
                                return order.linesBySnoSku
                                    .filter((order) => checkShouldRender(order))
                                    .map((grouping) => {
                                        return (
                                            <OrderSection
                                                key={grouping.snoSku}
                                                activeDate={activeDate}
                                                selectedAccountId={selectedAccountId}
                                                grouping={grouping}
                                                expandAll={expandAll}
                                                editingOrder={editingOrder}
                                                fromPlanItSummary={fromPlanItSummary}
                                                handleCopyButtonClicked={handleCopyButtonClicked}
                                                handleMoveButtonClicked={handleMoveButtonClicked}
                                                handleRemoveGroupButtonClicked={
                                                    handleRemoveGroupButtonClicked
                                                }
                                            />
                                        );
                                    });
                            })}
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={openCopyModal}
                title={<Trans i18nKey="copy">Copy</Trans>}
                subheader={`${t('chooseWeekCopy', 'Choose a week to copy this Product to')}`}
                close={onClose}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv} data-testid="calendar-move-modal">
                    <WeekSelectorDropdownModal
                        onCancel={onClose}
                        onOk={handleCopy}
                        testId={'dropdown-move-modal'}
                        weeks={!!formattedWeeks ? formattedWeeks : []}
                        selectedDate={daypickerCopyDate}
                        onDateSelected={setDaypickerCopyDate}
                        showIcons={false}
                    />
                </div>
            </Modal>
            <Modal
                open={openMoveModal}
                title={<Trans i18nKey="changeWeek">Change Week</Trans>}
                subheader={`${t('chooseWeekMove', 'Choose a week to move this Product to')}`}
                close={onClose}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv} data-testid="calendar-move-modal">
                    <WeekSelectorDropdownModal
                        onCancel={onClose}
                        onOk={handleMove}
                        testId={'dropdown-move-modal'}
                        weeks={!!formattedWeeks ? formattedWeeks : []}
                        selectedDate={daypickerMoveDate}
                        onDateSelected={setDaypickerMoveDate}
                        showIcons={false}
                    />
                </div>
            </Modal>
            <ConfirmationModal
                data-testid="remove-group-modal"
                saveProgress={handleRemoveGroup}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="removeProductTitle">Remove Product from Order</Trans>}
                subheader={`${t(
                    'removeProductSubheader',
                    'Are you sure you want to remove this product from your order?'
                )}`}
                continueText={<Trans i18nKey="ok">Ok</Trans>}
                cancelText={<Trans i18nKey="cancel">Cancel</Trans>}
                open={openRemoveGroupModal}
                navigationLink=""
                onCancel={onClose}
            />

            <ConfirmationModal
                data-testid="cancel-back-modal"
                saveProgress={handleCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="sureYouWantToLeave">Are You Sure You Want To Leave?</Trans>}
                subheader={`${t('changesNotSaved', 'Your changes will not be saved.')} ${t(
                    'sureYouWantToNavigateAway',
                    'Are you sure you want to navigate away?'
                )} `}
                continueText={<Trans i18nKey="yes">Yes</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="confirm-exit-modal"
                saveProgress={handleConfirmNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="exit">Exit</Trans>}
                subheader={
                    <Trans i18nKey="orderExitConfirm">
                        Are you sure you want to exit the order without saving?
                    </Trans>
                }
                description={
                    <Trans i18nKey="orderChangesNotSaved">
                        Any changes made to the order will not be saved
                    </Trans>
                }
                continueText={<Trans i18nKey="yesExit">Yes, Exit</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                saveInputLabel={<Trans i18nKey="saveChanges">Save Changes</Trans>}
                saveInput={false}
                onSave={onSaveDraft}
                open={openBlockedNavigationModal}
                navigationLink=""
                onCancel={onCloseBlockedNavigationModal}
            />
            <SaveDraftModal
                data-testid="save-draft-modal"
                onClose={onClose}
                open={openSaveDraftModal}
                onCancel={onCloseBlockedNavigationModal}
                setClose={setOpenSaveDraftModal}
                setAllowNavigation={setAllowNavigation}
            />
            <SubmitModal
                data-testid="confirm-submit-modal"
                open={openSubmitModal}
                onClose={onClose}
                onSubmit={handleSubmit}
                orderSubmitStatus={orderSubmitStatus}
            />
            <FeeModal
                products={feeModalRecords}
                open={openFeeModal}
                onClose={onCloseFeeModal}
                onAcceptFee={onAcceptFee}
                showWeek={true}
            />
            <CancelOrderModal
                open={cancelOrderModal}
                onClose={() => {
                    setCancelOrderModal(false);
                }}
                onConfirmCancel={cancelOrder}
            />
            <Prompt
                when={
                    !allowNavigation &&
                    atmOrders &&
                    atmOrders.some((order) =>
                        order.lines?.some((line) => (line.palletQuantity ?? 0) > 0)
                    )
                }
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
            <AddProductsModal
                open={addProductsOpen}
                onClose={onClose}
                selectedShipTo={selectedAccountId}
                activeDate={activeDate}
                fromMakeIt={true}
            />
        </ProcessingPageTemplate>
    );
}
