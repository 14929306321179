export const esVE = {
    // Reusable
    cans: 'Latas',
    view: 'Visualizar',
    design: 'Diseño',
    filling: 'Envase',
    canHandling: 'Manejo de Latas',
    regulations: 'Regulación',
    labLiquid: 'Laboratorio de Liquidos',
    sustainability: 'Sustentabilidad',
    faq: 'Preguntas Frecuentes',
    designInspiration: 'Inspiración de Diseño',
    backToAllProducts: 'Volver a todos los Productos',
    selectShipTo: 'Seleccionar Envie a',

    // -- NAVIGATION -- //
    home: 'Incio',
    homepage: 'Mi Página de Inicio',
    ourProcess: 'Proceso',
    processOverview: 'Resumen de Proceso',
    labDashLiquid: 'Laboratorio / Líquido',
    handling: 'Manejo',
    regulatory: 'Regulación',
    ourProducts: 'Los Productos',
    productPortfolio: 'Catálogo de Productos',
    contactUs: 'Contáctenos',
    logout: 'Sair',
    navaigation: 'Navegación',
    backToPortfolio: 'Volver a Catálogo de Productos',
    dashboard: 'Tablero',
    payables: 'Cuentas por pagar',
    discoverBall: 'Descubre Ball',
    productionOrderSummary: 'Resumen de orden de producción',
    deliveryOrderSummary: 'Resumen del Pedido de Entrega',
    shipmentSummary: 'Resumen de envío',
    paymentsSummary: 'Resumen de pagos',
    ourProcessLearningContent: 'Nuestro proceso y contenido de aprendizaje',
    howToGuides: 'Guías para The Source',
    buildAndEstimateNav: 'Crear y calcular',
    buildNav: 'Crear',
    guided: 'Guiado',
    graphics: 'Gráficos',
    overview: 'Visión General',
    theProcess: 'El proceso',
    ballProductPortfolio: 'Portafolio de productos Ball',
    guidesLink: 'Cómo Guías',
    faqLink: 'FAQ',
    makeItSummary: 'Make It Resumen',
    shipItSummary: 'Resumen de Ship It',
    submitNewGraphicLink: 'Enviar Nuevo Gráfico',

    // --USER ADMIN -- //
    admin: 'Admin',
    addNewUser: 'Agregar Nuevo Usuario',
    userInformation: 'Información del Usuario',
    newUserEmailHelper: 'Email@domain.com',
    confirmEmail: 'Confirmar Correo Electrónico',
    locationRoleAccess: 'Ubicación y Acceso a Roles',
    userAdmin: 'Administrador de usuarios',
    myAccount: 'My Account',
    pendingAccessRequests: 'Pending Access Requests',
    myAccess: 'My Access',
    allAccess: 'Acceso Total',
    orderFulfillment: 'Cumplimiento del Pedido',
    procurementManagement: 'Gestión de Adquisiciones',
    forecastManagement: 'Gestión de Previsiones',
    packagingManagement: 'Gestión de Embalaje',
    coPacker: 'Embalador',
    warehousingLogistics: 'Almacenamiento/Logística',
    artManagement: 'Gestión de Arte',
    marketingManagement: 'Gestión de Marketing',
    accountsPayableAccountsReceivable: 'Cuentas por Pagar / por Cobrar',
    graphicsVendor: 'Proveedor de Gráficos',
    edit: 'Editar',
    selectedAccess: 'Acceso Seleccionado',
    graphicsIntakeForm: 'Formulario de Admisión de Gráficos',
    draftGraphicsIntakeForms: 'Formularios de Admisión de Gráficos en Borrador',
    inProgressGraphicsStatuses: 'Estados de Gráficos en Curso',
    graphicsQuote: 'Cotización de Gráficos',
    graphicsProof: 'Prueba de Gráficos',
    graphicsStandardPilotCan: 'Estándar de Gráficos (Pilot Can)',
    recentlyCompletedGraphics: 'Gráficos Completados Recientemente',
    planning: 'Planificación',
    newOpenProductionOrders: 'Pedidos de Producción Nuevos/Abiertos',
    newOpenDeliveryOrders: 'Órdenes de Entrega Nuevas/Abiertas',
    draftDeliveryOrders: 'Borradores de Órdenes de Entrega',
    paymentsCIAOnly: 'Pagos (CIA Solamente)',
    orderPaymentStatus: 'Estado de Pago de Orden',
    orderPaymentFinancials: 'Finanzas de Pago de Pedidos',
    liquidTesting: 'Pruebas Líquidas',
    liquidIntakeForm: 'Formulario de Admisión Líquida',
    warrantyLetter: 'Carta de Garantía',
    termsandConditions: 'Términos y Condiciones',
    temporarilyHideSKUs: 'Ocultar Temporalmente las SKU',
    accountAdmin: 'Administración de cuentas',
    createNewUser: 'Crear nuevos usuarios',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Bienvenido a <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> será su ventanilla única para la entrega de gráficos. Con el tiempo, esta página tendrá métricas útiles como Órdenes de producción "Make It", Órdenes de entrega "Ship It", Gestión de productos y más. Agradecemos sus comentarios y su paciencia mientras mejoramos su experiencia.',
    readyToOrder: '¿Listo para ordenar productos?',
    makeIt: 'Make It',
    readyToSchedule: '¿Listo para programar un envío?',
    shipIt: 'Ship It',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'User Administration',
    setupNewUser:
        'Setup a new user with the correct permissions and locations in <i>The Source</i>.',
    userAdminDashboard: 'User Admin Dashboard',
    viewPendingRequests:
        'View pending access requests, pending new user requests, and active users in <i>The Source</i>.',
    viewUserAdminDashboard: 'View User Admin Dashboard',

    // -- DASHBOARD -- //
    agedSku: 'SKU envejecidos',
    agedSkus: 'SKUs envejecidos',

    // -- FAQ Page -- //
    searchFAQs: 'Buscar preguntas frecuentes',
    searchFAQplaceholder: 'Cans, Bottles, etc!',

    // -- QUICK VIEW -- //
    production: 'Producción',
    limitedProductionRun: 'Producción Limitada',
    seeFullProductDtls: 'Ver todos los detalles del producto',
    timesPerYear: 'Veces al Año',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Product Information',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Make It Lock Window',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'View My Product Information',
    customerProductId: 'Customer Product Id',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Ball Product ID',

    // -- PRODUCT PORTFOLIO -- //
    all: 'Todos',
    bottles: 'Botellas',
    ends: 'Tapas',
    end: 'Tapa',
    lookingForInspiration: 'Buscando Inspiración',
    pallets: 'Palet',
    itemsPerPallet: 'Cuantidad por Palet',
    lookingForSomething: '¿No encuentras lo que necesitas?',
    sendUsYourComments: 'Cuéntanos qué estás buscando',
    minOrderPallet: 'Orden Mínima de Paletas',
    cansPerPallet: 'Latas Por Palet',
    quickLook: 'Vistazo rápido',
    select: 'Seleccionar',
    seeDetailsBtn: 'Ver Detalles Completos del Producto',
    compareProducts: 'Comparar Productos',
    pleaseSelectProducts: 'Seleccione productos para obtener un presupuesto.',
    endTile: 'Tapas',
    compare: 'Comparar',
    myProjects: 'Mis proyectos',
    belowAreYourSavedProjects:
        'A continuación, se muestran los proyectos guardados de los últimos 180 días.',
    projectName: 'Nombre del proyecto',
    productCode: 'Código de producto',
    savedOn: 'Guardado el',
    viewProject: 'Ver proyecto',

    // -- FACETS -- //
    filterBy: 'Filtrar Por',
    clearAll: 'Limpiar Todos',
    // Facet Category
    stylesCategory: 'Gama de productos',
    sizesCategory: 'Talla',
    featuresCategory: 'Mejoras de producto',
    endsCategory: 'Tapas Compatibles',
    // Facet Tooltips
    stylesTooltip: 'Gama de productos tooltip',
    sizesTooltip: 'Talla tooltip',
    featuresTooltip: 'Mejoras de producto tooltip',
    endsTooltip: 'Tapas Compatibles tooltip',
    style: 'Estilo',
    size: 'Tamaño',
    features: 'Caracteristicas',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Solicitar una Cotización',
    productDetails: 'Detalles del producto',
    printGuide: 'Guía de Impresión',
    customerDesignGuide: 'Guía de diseño del cliente',
    download: 'Descargar',
    canSpecification: 'Especificaciones de Lata',
    dieLine: 'Die Line',
    palletSpecifications: 'Especificaciones de Palet',
    greatFor: 'Ideal para',
    specifications: 'Especificaciones',
    currentLeadTime: 'Plazo de Producción',
    fillingAvailability: 'Disponibilidad de Envase',
    compatibleEnds: 'Tapas Compatibles',
    weeks: 'Semanas',
    months: 'Meses',
    minimum: 'Mínimo',
    productEnhancements: 'Mejora de Producto',
    compatibleFilling: 'Envase Compatible',
    pasteurization: 'Pasteurización',
    hotFill: 'Llenado en Caliente',
    coldFill: 'Llenado en Frio',
    color: 'Color',
    aluminum: 'Aluminio',
    printingOptions: 'Tipos de Impresión: Lata Impresa, Lata con Sleeve, Lata Brillante',
    // Ordering
    ordering: 'Ordenar',
    pricePerThousand: 'Precio por Millar',
    quantityPerPallet: 'Cantidad por Palet',
    minOrder: 'Orden Mínima',
    // Design Specs
    designSpecs: 'Especificaciones de Diseño',
    circumference: 'Circunferencia',
    designLength: 'Longitud de Diseño (Circunferencia)',
    designHeight: 'Altura de Diseño',
    maxTALength: 'Área de texto máxima (Longitud)',
    maxTAHeight: 'Área de texto máxima (Altura)',
    maximumColors: 'Número máximo de colores',
    windowLimitWidth: 'Límite de Ancho',
    windowLimitHeight: 'Límite de Altura',
    underOverlap: 'Área de superposición',
    // Can Specs
    canSpecs: 'Especificaciones de Lata',
    endSize: 'Tamaño de la Tapa',
    bodyOutsideDiameter: 'Diámetro exterior del cuerpo',
    flangedCanHeight: 'Altura de Pestaña',
    coldFillVolume: 'Volumen de Envase',
    flangedCanWidth: 'Ancho de Pestaña',
    endDiameter: 'Diámetro exterior del cuerpo',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Efectos y características disponibles para esta lata',
    canFinishesAndEffects: 'Efectos Especiales',
    inks: 'Tintas',
    overvarnish: 'Barnices',
    effects: 'Efectos',
    specialFeatures: 'Características Especiales',

    // -- END DETAILS -- //
    endDetails: 'Detalle de la Tapa',
    endFinishesAndEffects: 'Tapas Especiales',
    compatibleCans: 'Latas Compatibles',
    endFeaturesAndEffects: 'Tapas Especiales',
    orderIndividually: 'Se puede pedir individualmente',
    roppClosures: 'ROPP Closures se venden a través de terceros',
    endsPack: 'Paquete de Información de Tapas',

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Añadir Producto',
    details: 'Detalles',
    name: 'Nombre',
    type: 'Tipo',
    maxColors: 'Max colores',
    innovativeFeatures: 'Características innovadoras',
    leadTimes: 'Tiempos de entrega',
    currentFillingAvail: 'Disponibilidad de llenado actual',
    products: 'Productos',
    addAnotherToCompare: 'Agregue Otro Producto para Comparar',
    removeSelection: 'Eliminar selección',
    addToCompare: 'Añadir a comparar',
    prodComparisonDesc:
        'Compare hasta tres productos diferentes a la vez, sin importar el volumen o el estilo. Vea qué productos ofrecen',
    prodEstimateDesc:
        'Nuestro estimador puede ayudarlo a comprender mejor el costo de ordenar latas con nosotros. Tenga en cuenta algunas cosas al crear su estimación',
    prodHasMinOrder: 'Cada producto tiene un pedido mínimo',
    orderInFullPallets:
        'Las estimaciones y los pedidos solo se pueden colocar en paletas completas',
    orderInFullTrucks:
        'Recomendamos ordenar en camiones completos, la mayoría de los camiones pueden caber en aproximadamente 25 paletas. Menos de la carga completa de camiones puede incurrir en cargos adicionales',
    productComparison: 'Comparación de productos',
    compareBuildEstimate: 'Comparar, crear y calcular',
    compareBuild: 'Comparar y crear',
    myProject: 'Mi proyecto',

    // -- CONTACT FORM -- //
    howCanWeHelp: 'How Can We Help?',
    yourName: 'Su Nombre',
    email: 'Correo Electrónico',
    yourCountry: 'Tu País de Envase',
    yourCity: 'Tu Ciudad de Envase',
    subjectLine: 'Linea de Asunto',
    contactMessage: 'Dinos como podemos ayudar...',
    submit: 'Enviar',
    cancel: 'Cancelar',
    sendUsANote: '¿Cómo podemos ayudar?',
    msgSuccessful: 'Mensaje enviado con éxito',
    msgFailed: 'No se pudo enviar el mensaje. Vuelve a intentarlo en otro momento.',
    sending: 'Enviando',
    close: 'Cierre',
    contactReason: 'Motivo de contacto',
    contact: 'Tengo una pregunta',
    support: 'Necesito ayuda',
    login: `I can't log in`,

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Obtener una cotización',
    desiredAnnualQuantity: 'Cantidad Anual Deseada',
    enterQuantity: 'Escribir Cantidad',
    // wouldYouLikeEndsWithThisProduct: '¿Te gustaría tapas con este producto?',
    remove: 'Eliminar',
    estimateFailedToSend: 'No se pudo enviar el cálculo, vuelva a intentarlo en otro momento',
    productEstInquiry: 'Consulta de Estimación de Producto',
    submittingEstimate: 'Enviar la información a continuación para un presupuesto',
    enterValueGreaterThan: 'Por favor, introduzca un valor mas que 0',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Volver al inicio',
    thankYou: 'Gracias',
    yourRequestHasBeenSubmitted: 'Su pedido ha sido enviado!',
    someoneFromOurTeamWillBeInTouchSoon:
        'Alguien de nuestro equipo se pondrá en contacto tan pronto.',

    // -- USER ADMIN -- //
    pendingRequests: 'Solicitudes pendientes',
    pendingRegistration: 'Registro pendiente',
    activeUsers: 'Usuarios activos',
    rolesLocations: 'Funciones (cant. de ubicaciones)',
    viewEdit: 'Ver/editar',
    disable: 'Deshabilitar',
    roles: 'Rol(es)',
    sentOn: 'Enviado el',
    resend: 'Reenviar',
    reviewRequest: 'Solicitud de revisión',
    disableUserError: 'El sistema no pudo deshabilitar al usuario seleccionado',
    disableUser: 'Deshabilitar usuario',
    disableUserWarning:
        'Está a punto de deshabilitar los permisos de este usuario en su cuenta. Deberán crear una nueva solicitud para recuperar los permisos. ¿Está seguro de que desea continuar?',
    yesDisable: 'Sí, deshabilitar',
    location: 'Ubicación',
    userAdminSuccessMessage: 'User permissions have been updated',

    // -- ROLES -- //
    procurementMgmt: 'Gestión de adquisiciones',
    forecastMgmt: 'Gestión de previsiones',
    packagingMgmt: 'Gestión de embalaje',
    artMgmt: 'Gestión de arte',
    marketingMgmt: 'Gestión de marketing',
    arAp: 'Cuentas por pagar/por cobrar',
    impersonation: 'Impersonation',

    // -- REGISTRATION -- //
    registrationHeader: 'Hagamos latas juntos',
    registrationDescEMEA:
        'Ya casi estamos allí, solo necesitamos un poco de información de usted para llevarlo al Portal del cliente de Ball, donde puede obtener más información sobre las latas y cómo hacer un pedido.',
    registrationDescription:
        'Ya casi estamos allí, solo necesitamos un poco de información de usted para llevarlo al Portal del cliente de Ball, donde puede obtener más información sobre las latas, cómo hacer un pedido y estimar los costos.',
    region: 'Región',
    language: 'Idioma',
    dateFormat: 'DATE FORMAT',
    aboutYourCompany: 'Acerca de su empresa',
    companyName: 'Nombre de empresa',
    country: 'País',
    stateProvince: 'Estado/Provincia',
    city: 'Ciudad',
    postalCode: 'Código Postal',
    yearsInBusiness: 'Años de negocio',
    numberOfEmployees: 'Numero de Empleados',
    estimatedAnnualRevenue: 'Ventas Anuales Estimadas',
    beverageInRetail: '¿Actualmente comercializa alguna bebida?',
    currentPackageType: 'Tipo de empaque actual',
    beveragePackagingNeeds: 'Sus necesidades de empaque de bebidas',
    beverageCategory: 'Categoría de Bebidas',
    productSize: 'Tamaño del producto',
    expectedAnnualQuantity: 'Cantidad Anual esperada',
    canningLine: '¿Tiene o planea comprar una línea de envase?',
    copackerName: 'Nombre del coenvasador',
    fillingCountry: 'País de Envase',
    fillingCity: 'Ciudad de Envase',
    additionalComments: 'Comentarios adicionales: (Opcional)',
    artworkComments: 'Comentarios de ilustraciones (Opcional)',
    setupCustomerPortalAccount: 'Configure su cuenta del Portal del Cliente',
    firstName: 'Nombre',
    lastName: 'Apellido',
    phoneNumber: 'Número de teléfono',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    alreadyRegistered: '¿Ya registrado?',
    accountCreated: 'This email account is already tied to The Source.',
    signIn: 'Inicie Sesión en el Portal del Cliente',
    signInConfirmation: 'Please Sign In to The Source and if needed, reset your password.',
    yes: 'Sí',
    no: 'No',
    incorrectEmailAddress: 'Correo Electrónico es Incorrecto',
    atLeastEightLetters: 'Al menos 8 letras',
    atLeastOneCapitalLetter: 'Al menos una letra mayúscula',
    atLeastOneLowerCaseLetter: 'Al menos una letra minúscula',
    atLeastOneNumber: 'Al menos un numero',
    mustNotBeACommonPassword: 'No debe ser una contraseña común',
    passwordsShouldMatch: 'Las contraseñas deben coincidir!',
    year: 'año',
    printType: '¿Estás buscando Latas sin Impresión, Litografiadas, o Latas con Sleeves?',
    iAgree: 'Acepto los',
    termsOfUse: 'Términos de uso y las Políticas de privacidad',
    million: 'Millón',
    newToBeverage: 'Nuevo en Bebidas',
    title: 'Título',
    jobTitle: 'Job Title',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Lisa',
    sleevedCans: 'Sleeve',
    printedCans: 'Litografada',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Cerveza',
    beverageCategoryBeerCraft: 'Cerveza Artesanal',
    beverageCategoryBeerDomestic: 'Cerveza Nacional',
    beverageCategoryBeerImport: 'Cerveza Importada',
    beverageCategoryCsd: 'Bebida Gaseosa',
    beverageCategoryCider: 'Cider',
    beverageCategoryCoffee: 'Café',
    beverageCategoryIcedCoffee: 'Café helado',
    beverageCategoryEnergyDrink: 'Energético',
    beverageCategoryFab: 'FAB’s',
    beverageCategoryFmb: 'Flavored Malt Beverage (FMB)',
    beverageCategoryHardLiquor: 'Destilado',
    beverageCategoryThc: 'Hemp/CBD/THC',
    beverageCategoryJuice: 'Jugo',
    beverageCategoryProtein: 'Protein/Milk Based',
    beverageCategoryRtd: 'Bebidas Mezcladas',
    beverageCategorySpirits: 'Destilado',
    beverageCategorySportsDrink: 'Bebida Deportiva',
    beverageCategoryTea: 'Té',
    beverageCategoryIcedTea: 'Te helado',
    beverageCategoryWater: 'Agua',
    beverageCategoryWine: 'Vino',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Otros',

    // Validation
    required: 'Necesario',

    // Graphic Intake - Vendor
    formOverview: 'Resumen del formulario',
    graphicsSubmissionInfo: 'Información de Envío de Gráficos',
    productOptions: 'Opciones de Producto',
    colorMatchingOptional: 'Coincidencia de Color (Opcional)',
    uploadGraphicsAndSubmit: 'Subir Gráficos y Enviar',
    submitGraphics: 'Envíe sus Gráficos',
    graphicUploadTimeout:
        'se ha cronod de tiempo de espera de carga gráfica. Inténtelo de nuevo más tarde.',
    requiresSpecialty:
        'usted señaló que su producto requiere una capa especial o especialidad, por favor seleccione su recubrimiento, acabado o efecto. Inténtelo de nuevo más tarde.',
    fillOutRequiredFields: 'Todos los campos obligatorios deben completarse antes de enviar',
    dateErrors:
        'Asegúrese de que la fecha solicitada, la fecha de venta al por menor y la fecha de venta al por menor sean fechas válidas.',
    uploadFileError: 'Cargue un archivo zip válido antes de enviar',
    intakeFailedToSave: 'El formulario de admisión de gráficos no se pudo guardar',
    saveProgress: 'Guardar progreso',
    graphicDraftSaved: 'Borrador de Admisión Gráfica Guardado',
    promotionStartDatePrior:
        'Asegúrate de que la fecha de inicio de tu promoción sea anterior a la fecha de finalización de la promoción.',
    promotionStartDateAfter:
        'Por favor, asegúrese de que su fecha de promoción sea posterior a la fecha solicitada',
    unknownError:
        'une erreur inconnue s’est produite.  S’il vous plaît essayer à nouveau plus tard.',
    requestedByDate: 'Fecha solicitada',
    requestedDateHelper: 'Fecha solicitada para sus latas',
    inRetailDate: 'Fecha en el mercado',
    shortTermStartDateHelper: 'Fecha en que los productos terminados estarán en el mercado',
    validInRetailDateError:
        'Por favor, asegúrese de que la fecha en la venta al por menor es después de la fecha solicitada',
    outOfRetailDate: 'Fecha fuera de venta',
    shortTermEndDate: 'Fecha en que se estima que los productos terminados están fuera de venta',
    validOutOfRetailError:
        'Por favor, asegúrese de que la fecha fuera de venta al por menor es después de la fecha de venta al por menor',
    iDontKnow: 'No sé',
    typeOfGraphic: '¿Qué tipo de gráfico quieres enviar?',
    graphicType: 'Tipo Gráfico',
    previousSubmissions: 'Presentaciones Anteriores',
    revisionToAnExistingGraphic: '¿Es una revisión de un gráfico existente?',
    existingGraphicName: 'Nombre Gráfico Existente',
    revisionGraphicNameHelper: 'Este es el nombre del gráfico que está revisando',
    enterGraphicNumberOrName: 'Introduzca un número gráfico o un nombre',
    existingGraphicNumber: 'Número gráfico existente',
    revisionGraphicNumberHelper: 'Este es el número gráfico que está revisando',
    newGraphicSubmissionName: 'Nuevo nombre de envío gráfico',
    submissionNameHelper: 'Asigne a este envío el nombre para futuras referencias',
    alphanumericPattern: 'Escriba un nombre usando alfanuméricos',
    conversion: '¿Es esta una conversión dura o blanda?',
    conversionTooltip:
        'Una conversión dura será obsoleta y reemplazará por completo la versión seleccionada de este gráfico. Una conversión blanda le permitirá mantener la versión seleccionada y la nueva versión de este gráfico.',
    shortTerm: '¿Es esta una promoción a corto plazo?',
    //graphicsContact: '',
    //customerSKUNo: '',
    //colorStandardsApprover: '',
    //colorMatchNote: '',
    //colorMatchNoteText: '',
    //canFinishes: '',
    //colorMatching: '',
    //dateCodeMatching: '',
    //colorMatch: '',
    graphicsContactInformation: 'Información de Contacto de Gráficos',
    customerNameHelper: 'Empresa que se Somete en Nombre de',
    yourCompany: 'Su Empresa',
    nameHelper: 'Nombre de la persona que envía este formulario',
    phoneNumberHelper: 'Número de teléfono de la persona que envía este formulario',
    emailAddress: 'Correo electrónico',
    submitterEmailHelper: 'Dirección de correo electrónico de la persona que envía este formulario',
    colorStandards: 'Información del Aprobador de Estándares de Color',
    approverName: 'Nombre del aprobador',
    approverNameHelper:
        'Especifique el nombre de la(s) persona(s( responsable(s) de la aprobación del diseño. Ball se asegurará de que estas personas reciban los documentos necesarios para su aprobación.',
    approverEmail: 'Correo electrónico del aprobador',
    approverEmailHelper: 'Dirección de correo electrónico del aprobador',
    approverPhoneNumberHelper:
        'Especifique el número de teléfono de la(s) persona(s) responsable(s) de la aprobación. Esto permite una comunicación rápida durante el proceso de desarrollo.',
    address: 'Dirección de envío del aprobador',
    addressHelper: 'Calle, ciudad, código postal',
    copyWarning:
        'Está a punto de enviar un nuevo gráfico, aprovechando la información proporcionada para un gráfico anterior. Revise y actualice cuidadosamente todos los campos para garantizar la precisión del nuevo gráfico.',
    revisionWarning:
        'Está a punto de enviar una revisión para un gráfico activo. Revise y actualice cuidadosamente todos los campos para garantizar la precisión del nuevo gráfico.',
    hundredMaxLengthError: 'Asegúrese de que el valor del campo es de 100 caracteres o menos',
    alphaNumericPatternError: 'Por favor, asegúrese de que el campo es alfanumérico',
    numericPatternError: 'Introduzca un valor numérico',
    thirtyMaxLengthError: 'Asegúrese de que el valor del campo es de 30 caracteres o menos',
    fiftyMaxLengthError: 'Asegúrese de que el valor del campo es de 50 caracteres o menos',
    customerGraphicsID: 'Número de gráfico del cliente',
    customerGraphicsIDHelper: 'Número de  gráfico del cliente (opcional)',
    customerSKU: 'Número de SKU del cliente',
    customerSKUHelper: 'Número de SKU del cliente (opcional)',
    upc: 'UPC # / EAN',
    upcHelper:
        'Proporcione el número UPC completo o el código EAN, incluidos los dígitos iniciales (opcional)',
    productCodeHelper: 'Tamaño del producto en el que se ejecutará el gráfico',
    neckSize: 'Tamaño del cuello',
    neckSizeTooltip:
        'El tamaño del cuello debe ser 206 a menos que Ball haya autorizado previamente 209',
    lookingForDesignInspiration: '¿Buscas inspiración para el diseño?',
    specialties: '¿Su producto requiere recubrimientos o específicaciones especiales?',
    ink: 'Tinta',
    inkHelper: 'Opciones de tinta disponibles para este producto',
    effect: 'Efectos',
    effectHelper: 'Opciones de efectos disponibles para este producto',
    overvarnishHelper: 'Opciones de exceso de barniz disponibles para este producto',
    doYouNeedColorMatching:
        '¿Necesita un color que coincida con el aluminio existente u otro material?',
    colorMatchComment: 'Describa qué color le gustaría combinar en su garantía.',
    comments: 'Comentarios',
    colorMatchingText:
        'Es posible hacer coincidir los colores de las latas de aluminio existentes (no tiene que ser de Ball) o de otros materiales impresos (embalaje de cartón, lata / envoltura de botella / papel impreso, etc.). Al completar este formulario de admisión, se le proporcionará un documento imprimible para incluir en su paquete con su muestra de coincidencia de color y la dirección donde debe enviar su muestra por correo.',
    colorStandardText:
        'Ball proporciona un estándar de color para cada nueva etiqueta y todas las etiquetas vienen con cambios de color.',
    dateCode: 'Código de manufactura coincidente',
    dateCodeText:
        'Código alfanumérico de 9 dígitos ubicado cerca del código de barras UPC. La ubicación exacta variará según el tamaño de la lata y el fabricante.',
    dateCodeMinMaxError: 'Asegúrese de que el código de fecha es de 9 caracteres',
    invalidIntakeFile: 'Este tipo de archivo no es válido. Adjunte un archivo .zip',
    onUploadFileError: '*Por favor, cargue un archivo zip válido',
    uploadSection1:
        'Todas las latas de Ball se ejecutarán en un sustrato de aluminio con brillo sobre barniz a menos que se especifique lo contrario.',
    uploadSection2:
        'La disponibilidad de tintas o barnices especiales puede ser limitada; solicitar estos artículos en este formulario no garantiza la disponibilidad.',
    dragAndDropZipFileHere: 'Arrastre y suelte un archivo aquí o haga clic.',
    dragAndDropCsvFileHere: 'Arrastre y suelte un archivo aquí o haga clic.',
    dragAndDropFileHere: 'Arrastre y suelte un archivo aquí o haga clic.',
    dropHere: 'Suelte el archivo aquí',
    fileUploadedSuccessfully: 'Archivo cargado correctamente:',
    acceptedFileTypes:
        'Tipo de archivo aceptado: archivo Zip que puede incluir un archivo TIFF y/o de Illustrator recopilado con imágenes y fuentes suministradas o convertidas en contornos.',
    weRecommendPDF:
        'Recomendamos incluir un PDF de solo lectura en su archivo zip recopilado solo para fines de visualización.',
    acceptedFileTypesEmea: 'Accepted file types',
    weRecommendPDFEmea:
        'Note: .Zip files can include a TIFF and/or collected Illustrator file with images and fonts supplied or converted to outlines. We recommend including a read-only PDF in your collected zip file for viewing purposes only.',
    onUploadFileErrorEmea:
        '*One of the files you are trying to upload is not of an approved file type. Please remove and upload an approved file type (.pdf, .ai, .psd, .doc, .docx, .xls, .xlsx, .zip).',

    saveProgressAttachments:
        'No se guardan los documentos adjuntados cuando se selecciona «GUARDAR PROGRESO».',
    clearYourForm: 'Borrar su Formulario',
    clearFormConfirmation: '¿Está seguro de que desea borrar el formulario?',
    formInfoWillBeLost: 'Cualquier información se perderá.',
    yesClearForm: 'Sí, Forma Clara',
    excitedThankYou: '¡Gracias!',
    graphicsSubmitted: '¡Sus gráficos han sido enviados para su revisión!',
    submissionText:
        'Nuestro equipo revisará su envío para verificar su idoneidad y desarrollará una cotización. Un especialista en gráficos se pondrá en contacto dentro de 2 días hábiles.',
    subText:
        'Su formulario también está disponible para ver. Por favor guarde esto para sus registros.',
    intakeForm: 'Formulario de admisión: ',
    pleasePrintColorMatchingForm:
        'Para imprimir o descargar una copia de su formulario de coincidencia de color, por favor ',
    clickHere: 'haga clic aquí.',
    startNewForm: 'Iniciar nuevo formulario',
    backToDashboard: 'Volver al Tablero',
    graphicsColorSubmitted:
        'La información de resumen de gráficos está disponible para que la abra o descargue en su navegador.',
    submissionColorText:
        'Nuestro equipo revisará esta actualización y un especialista en gráficos se pondrá en contacto con usted en breve. Si desea revisar su envío o reimprimir un formulario de coincidencia de color, haga clic aquí.',
    viewColorMatchingInfo: 'Ver información de coincidencia de color',
    graphicsInformationMessageIE:
        'La información de resumen de gráficos está disponible para que la abra o descargue en su navegador.',
    colorMatchingInformationMessageIE:
        'Su información de coincidencia de color está disponible para que pueda abrirla o descargarla en su navegador.',
    companyId: 'ID de Empresa',
    submissionType: 'Tipo de Envío',
    billTo: 'Bill To',
    approverAddress: `Dirección del Aprobador`,
    approverCountry: `País del Aprobador`,
    graphicsSummary: 'Resumen de Gráficos',
    shippingAddress: 'Dirección de envío',
    yourColorMatchingInfo: 'Su información de coincidencia de color',
    pleasePrintThisCM:
        'Por favor, imprima esto e incluya en el envío con su objeto de coincidencia de color.',
    submissionName: 'Nombre del envío',
    colorMatchingDetails: 'Detalles de coincidencia de color',

    // EMEA Graphics Intake Page

    // Form Overview
    designInfo: 'Informaciónes del diseño',
    colourInfo: 'Información Colores',
    marketInfo: 'Información del mercado',
    productQuality: 'Especificación de calidad del producto',
    approverInfo: 'La aprobacion del diseño',
    // General Info
    graphicsSubInfo: 'Información General',
    customerAccountHelper: 'Nombre del socio comercial de Ball',
    contractFiller: 'Rellanador',
    contractFillerHelper:
        'Envasador, complete sólo en caso de que el diseño no se haya especificado por el socio comercial de Ball',
    brandOwner: 'Propietario de la marca',
    brandOwnerHelper:
        'Propietario de la marca, complete solo si no se trata del  socio comercial de Ball',
    canSize: 'Tamaño de la lata',
    canSizeHelper: 'Seleccione el tamaño de lata de la lista desplegable',
    canType: 'Tipo de lata',
    canTypeHelper: 'Seleccione el tipo de lata y diámetro del cuello de la lista desplegable',
    metalType: 'Metal',
    metalTypeHelper:
        'Seleccione el metal de la lista desplegable. El metal común para las latas es el aluminio, el acero solo se puede suministrar en casos excepcionales.',
    // Design Info
    requestType: 'Tipo Gráfico',
    requestTypeHelper:
        'Seleccione "Nuevo diseño" si este arte final no reemplaza uno de sus diseños existentes con Ball. Seleccione "Cambio de diseño" si este arte final reemplaza uno de sus diseños existentes con Ball.',
    designSubInfo: 'Informaciónes del diseño',
    requestTypeNew: 'Nuevo diseño',
    requestTypeExisting: 'Cambio del diseño',
    requestTypePromo: 'Diseño promocional – basado en (pero no reemplazando) el diseño existente',
    designPoNo: 'Número de Pedido de diseño',
    designPoNoHelper:
        'Número de orden de compra para el cobro del desarrollo gráfico seegún contrato.',
    previousBallDesignNum: 'Presentaciones Anteriores',
    existingHelper:
        'Si se trata de cambio del diseño, ingrese el número de diseño anterior de Ball (este número aparece en el PDF de Ball que recibe como parte del proceso de aprobación). Esto nos ayuda a identificar el arte final  reemplazado por esta nueva versión.',
    designName: 'Nuevo Nombre del diseño',
    designNameHelper:
        'Nombre del diseño que se va a utliziar durante todo el proceso (desde su desarrrollo gráifico hasta su llenado)',
    newCustRefNum: 'Nuevo numero del diseño (Ball)',
    newCustRefNumHelper:
        'Ingrese su número de referencia para este arte final, el número que usa para identificar este diseño en su organización',
    oldCustRefNum: 'Antiguo numero del diseño (Ball)',
    oldCustRefNumHelper:
        'Si se trata de cambio del diseño, ingrese en este campo su número de referencia del arte final anteriormente desarollado por Ball (esto nos ayuda a identificar el arte final al que sustituye)',
    barcode: 'Código de barras (el numero)',
    barcodeHelper:
        'Ingrese los 8 ó 13 dígitos del código EAN o los 12 dígitos del código UPC. Utilizamos esto para comprobar que el arte final tiene el mismo código y evitar errores',
    barcodeBarsColor: 'Color del código de barras (Barras)',
    barcodeBarsColorHelper:
        'Ingrese el color para las barras. Ball usa esta información para comprobar que el arte final tiene el mismo color.',
    barcodeInfillColor: 'Color del código de barras (Fondo)',
    barcodeInfillColorHelper:
        'Ingrese el color para el fondo del código de barras. Ball usa esta información para comprobar que el arte final tiene el mismo color.',
    specialFinish: 'Acabados especiales Ball',
    specialFinishHelper:
        'Seleccione un efecto especial de acabado para su arte final. Por defecto, este campo está marcado como “no tiene un acabado especial“. Aviso importante: existen costes adicionales asociados a los acabados especales. Por favor, póngase en contacto con su representante comercial  para informarse de los precios asociados.',
    // Color Info
    colourSubInfo: 'Información Colores',
    overVarnish: 'Barniz (el tipo del acabado de la lata)',
    overVarnishHelper:
        'Seleccione el barniz que le gustaría que Ball aplicara a su diseño. El barniz estándar es brillo. Aviso importante: existen costes adicionales asociados a los acabados especales. Por favor, póngase en contacto con su representante comercial  para informarse de los precios asociados.',
    basecoatColour: 'Color base de la lata (de hojalata)',
    basecoatColourHelper:
        'El "basecoat"  solo está disponible para  latas de acero. Por defecto este campo está marcado como "Sin capa base", porque no está disponible en latas de aluminio.',
    specialInk: 'Tintas Especiales Ball',
    specialInkHelper:
        'Seleccione un efecto especial de tintas para su arte final.  De forma predeterminada, este campo está configurado como "sin acabado especial".  Aviso importante: se aplica un pago adicional. Por favor, póngase en contacto con gerente comercial de Ball para informarse de los precios actuales para valores añadidos.',
    colourTarget: 'Objetivo de color',
    colourTargetHelper:
        'Especifique en este campo si tiene un objetivo de color que le gustaría que usemos como referencia.  Si desea que Ball use una muestra de lata producida en el pasado por una de nuestras plantas o por nuestra competencia, es importante indicarlo  al principio del proceso para obtener el  resultado deseado.',
    // Market Info
    marketSubInfo: 'Información del mercado',
    distributeCountries: 'Gráficos de países para comercializar',
    distributeCountriesHelperText:
        'Seleccione en cuál de los siguientes países planea comercializar este diseño. (Seleccione todos los que corresponden)',
    depositScheme: 'Esquema del depósito',
    depositSchemeHelper:
        'Especifique si su arte final se comercializará en algún país que tiene un esquema de depósito (elija de la lista)',
    recycleMark:
        'El metal se recicla para siempre síbolo en el diseño. Disponible en varios idiomas.',
    recycleMarkHelper:
        'Seleccione "Sí" si desea que Ball agregue el logo  MRF a su lata. Al utilizar este logo, contribuye a promover la  infinita reciclabilidad del aluminio y proporciona un mensaje de sostenibilidad a su consumidor.',
    glnNumber: 'Número GLN del cliente',
    glnNumberHelper:
        'Ingrese su código GLN de 13 dígitos en este campo. Si su arte final incluye el logo del depósito Alemán, Ball está obligado a registrar su número GLN en nuestro sistema. El número GLN lo proporciona la "German Pfand Organization" al registrarse en su sistema. Ball tiene que verificar este registro antes de la producción de su arte final. (ingrese "0" en este campo en caso de que su arte final no esté destinado para el mercado alemán)',
    // Product Quality Info
    productQualitySubInfo: 'Especificación de calidad del producto',
    productContent: 'Contenido del producto',
    productContentHelper:
        'Seleccione de la lista la categoría que mejor identifique el contenido de su producto. Esta información se utiliza para estudios de mercado.',
    productFormulation: 'Fórmula del producto',
    productFormulationHelper:
        'Seleccione de la lista si el contenido de la lata corresponde a  una receta “nueva” o “existente”. Utilizamos esta información para determinar si necesitamos realizar un Test de Compatibilidad de Producto.',
    refDesignNum: 'Si corresponde, qué diseñoutilizar como referencia (código)?',
    refDesignNumHelper:
        'Si selecciona “Receta existente” en el campo anterior, especifique en este campo su número de referencia o nuestro número del diseño. Esto nos ayuda identificar los Test de Compatibilidad asociados a dicho producto. ',
    productPasteurisation: 'Pasteurización del producto',
    productPasteurisationHelper:
        'Seleccione de la lista a qué temperatura aproximadamente se pasteurizará su producto. Esto permite a Ball que trate las latas para su proceso.',
    bpaFree: 'BPANI?',
    bpaFreeHelper:
        'Seleccione SÍ si comercializará este diseño en un país que requiere el  uso de lacas BPA-free. El incumplimiento de esta legislación está sujeto a importantes sanciones legales  para el propietario de la marca.',
    bpaniId: 'Identificación N en el diseño?',
    bpaniIdHelper:
        'Si ha seleccionado "Si" para BPANI, confime si desea que apareceza en su diseño. Ball considerará "No" por defecto. Esta identificación ha sido acordada por la industria.',
    // Approval Info (For Approval)
    approvalSubInfo: 'La aprobacion del diseño',
    forApprovalSubTitle:
        'Ball siempre envía un PDF(Layout proof) para la aprobación del elementos del diseño.(los textos, el codigo de barras, los elementos de grafica...) Ball siempre envia muestras de colores (Rollouts / Contract proof) a menos que recibimos una muestra para utilizar como objetivo de producción',
    proofSession: 'Sesión de prueba necesaria?',
    proofSessionHelper:
        'Es posible producir una lata de  prueba. Seleccione "Sí" si desea utilizar esta opción. Existen costes asociados  a esta prueba.  Por favor, póngase en contacto con responsble comercial  para informarse de los precios de este servicio',
    attendProduction: 'Asistir al primer tiraje/ producción',
    attendProductionHelper:
        'Seleccione "Sí" si desea asistir a la primera producción. . Su coordinador de gráficos o representante de CF puede proporcionarle los detalles del procedimiento.',
    responsibleForApproval: 'Responsable de la aprobación',
    // Additional Remarks
    generalCommentsSubInfo: 'Observaciones generales',
    generalComments: 'Observaciones generales',
    generalCommentsHelper:
        'Ingrese los comentarios generales que le gustaría que Ball tuviera en cuenta',
    additionalRemarksSubInfo: 'Comentarios adicionales',
    additionalRemarks: 'Comentarios adicionales',
    additionalRemarksHelper:
        'En este campo puede añadir cualquier comentario que considere relevante para el correcto desarrollo de su arte final ',
    responsibleForCompletion: 'Responsable de llenar el formulario',
    responsibleForCompletionHelper:
        'Persona de contacto responsable del contenido de este formulario ',
    emeaApproverEmailHelper:
        'Especifique la(s) direccion(es) de correo electrónico de la(s) persona(s) responsable(s) de la aprobación. Ball se asegurará de que estas personas reciban los artes finales  por correo electrónico',

    // -- ORDER SHIPPING -- //
    deliveryInstructionsMaxLength: 'Máximo de 30 caracteres',

    // -- PENDING PAYMENTS --//
    pendingPayment: 'Pago Pendiente',
    pendingPayments: 'Pagos pendientes',
    pendingPaymentsInvoiceEmail:
        'Se le han enviado facturas proforma para los siguientes pedidos. Al remitir un pago, consulte el número de pedido de Ball Production correspondiente. Nota: El envío de pagos luego de la fecha de vencimiento afectará la primera fecha disponible para su pedido.',
    pendingPaymentsSubmitPayments:
        'Para acelerar el procesamiento de un pago, haga clic en el botón "Haga clic si se envió" a continuación y proporcione un número de remesa (opcional) para su pago.',
    pendingPaymentsDeadlineWarning:
        'Tiene pagos que se acercan a la fecha límite o la superan, lo que afectará la fecha solicitada de su pedido si no se paga antes de la fecha de vencimiento. Si ha pagado, haga clic en "Haga clic si se envió" a continuación para notificarnos.',
    clickIfSubmitted: 'Haga Clic si se Envio',
    provideRemittanceNumber: 'Proporcione un número de pago',
    provideRemittance:
        'Proporcionar un número de pago puede acelerar el procesamiento de fondos a su cuenta.',
    remittanceNumber: 'Número de pago',
    optional: 'Opcional',
    confirmPayment: 'Confirmar Pago',
    paymentSubmittedMessage: 'Gracias por enviar su pago. Procesaremos su pago de inmediato.',
    paymentSubmitted: 'Pago Enviado',
    paymentOverDue: 'El pago está atrasado',
    paymentOverDueThisWeek: 'El pago vence esta semana',
    paymentOverDueThisMonth: 'El pago vence este mes',

    // -- MAKE IT -- //
    myDashboard: 'Mi Tablero',
    createProductionOrder: 'CREAR ORDEN DE PRODUCCIÓN',
    saveForLater: 'GUARDAR PARA MÁS ADELANTE',
    back: 'Espalda',
    setDatesQuantities: 'ESTABLECER FECHAS Y CANTIDADES',
    adjustmentsToYourOrder:
        'Es posible que se hayan realizado ajustes en su pedido desde la última vez que guardó. Revise todos los productos y cantidades de su pedido antes de continuar.',
    failedToLoadDraft: 'No se pudo cargar el borrador del pedido',
    selectProducts: 'Seleccione productos para agregar a su pedido:',
    draftName: 'Nombre del borrador',
    continueEditing: 'Continuar editando',
    yourOrderHasBeenSaved: '¡Su pedido se ha guardado correctamente!',
    shipTo: 'Envie a',
    yourOrder: 'Su Pedido',
    search: 'Buscar',
    range: 'Rango',
    cansBottles: 'Latas / Botellas',
    endsClosures: 'Tapas / Cierres',
    closures: 'Cierres',
    closure: 'Clausura',
    orderedInLastSixMonths: 'Pedido en los últimos 6 meses',
    removeAll: 'Eliminar Todo',
    addInstructions:
        'Cree su pedido haciendo clic en el botón "AÑADIR" para cada producto que desee incluir.',
    qtyInstructions:
        'Ingresará cantidades y fechas solicitadas para los productos seleccionados en la siguiente pantalla.',
    cancelSubmission: 'Cancelar su envío',
    allInfoWillBeLost:
        '¿Seguro que desea cancelar su progreso sin guardar? Toda la información ingresada se perderá.',
    yesCancel: 'Sí, Cancelar',
    productId: 'Identificación del producto',
    graphicID: 'Identificacion del gráfico',
    productDescription: 'Descripción del producto',
    characteristics: 'Caracteristicas',
    coating: 'Revestimiento',
    add: 'Añadir',
    rowsPerPage: 'Filas por página',
    filter: 'Filtro',
    poNumber: 'Numero de orden de compra',
    poNumberHelper: 'Se requiere un Número de orden de compra.',
    poNumberMaxLengthHelper: 'El Número de orden de compra debe tener 25 caracteres o menos.',
    addProducts: 'Añadir Productos',
    submitError: 'Se ha producido un error al guardar el pedido. Inténtelo de nuevo más tarde.',
    submitOrder: 'Enviar pedido',
    draftHasBeenSuccessfullySaved: '¡Su borrador se ha guardado correctamente!',
    viewMyInProgressGraphics: 'Ver resumen de gráficos',
    enterDraftName:
        'Introduzca un nombre de borrador para su pedido de producción para guardarlo para más adelante.',
    draftOrderError:
        'El borrador del pedido no se ha podido guardar correctamente. Inténtelo de nuevo más tarde.',
    minimumOrderQuantities: 'Cantidades mínimas de pedido',
    feeWarning:
        'Una o más cantidades no cumplen con la cantidad mínima de pedido e incurrirán en una tarifa.',
    product: 'Producto',
    minimumOrderQuantity: 'Cantidad mínima de pedido',
    fee: 'Cuota',
    confirmFeeMessage: '¿Desea actualizar la cantidad o aceptar la tarifa?',
    editQuantity: 'Editar Cantidad',
    acceptFee: 'Aceptar Tarifa',
    today: 'Hoy',
    leadTimeForThisProduct: 'Tiempo de espera para este producto: {{leadTime}} semanas',
    minimumOrderIs: 'El pedido mínimo es',
    feeForOrders: 'Tarifa por pedidos',
    split: 'División',
    moqWarningMessage:
        'Una o más cantidades ingresadas no cumplen con la cantidad mínima de pedido.',
    orderSubmitted: 'GRACIAS, SU PEDIDO HA SIDO ENVIADO',
    customerPO: 'Orden de Compra del cliente',
    submitted: 'Enviado',
    invoiceSent: 'Factura Enviada',
    cashReceived: 'Pago recibido',
    reactToScheduleShipment: 'Listo para programar el envío',
    proFormaInvoice:
        'Debería recibir su factura pro forma para este pedido <strong>dentro de 2-3 días hábiles</strong>.',
    proFormaInvoiceBulk:
        'Debería recibir su factura(s) pro forma para estes pedido(s) <strong>dentro de 2-3 días hábiles</strong>.',
    paymentDueImmediately:
        'El pago de la factura <strong>debe realizarse inmediatamente</strong> para asegurar la fecha solicitada para los productos en el pedido.',
    paymentDueImmediatelyBulk:
        'El pago de la factura(s) <strong>debe realizarse inmediatamente</strong> para asegurar la fecha solicitada para los productos en el pedido(s).',
    paymentFor:
        'El pago de <strong>la factura se vence {{paymentDue}}</strong> (cuatro semanas antes de la fecha más temprana solicitada).',
    paymentForBulk:
        'El pago de <strong>la factura(s) se vence {{paymentDue}}</strong> (cuatro semanas antes de la fecha más temprana solicitada).',
    paymentForBulkNoDate:
        'Payment for the invoice(s) is due four weeks before your earliest requested date.',
    share: 'Compartir:',
    print: 'Impresión',
    downloadCSV: 'Descargar .CSV',
    canQuantity: 'Cantidad de lata',
    endQuantity: 'Cantidad de tapas',
    quantity: 'Cantidad',
    orderConfirmation: 'Confirmación del Pedido',
    leadTime: 'Tiempo de entrega',
    requestedAmount: 'Requested Amount',
    quantityToPurchase: 'Cantidad De Compra',
    purchaseOrderNumber: 'Número De Orden De Compra',
    ballProdOrderNumber: 'Número De Orden De Produccion De Ball',
    ballProdOrder: 'Orden De Produccion De Ball',
    invoiceAmount: 'Monto De La Factura',
    orderRequestedDate: 'Pedido Fecha Solicitada',
    orderSubmittedDate: 'Fecha de envío del pedido',
    paymentDueDate: 'Fecha De Pago',
    zeroOrderQuantity:
        'La cantidad del pedido no puede ser 0. Introduzca una cantidad o elimine el producto del pedido.',
    zeroForecastQuantity:
        'Forecasted quantity cannot be 0. Please enter a quantity or remove the product from the forecast.',
    firstRequestedDate: 'Solicitado',

    // -- Delivery Orders --//
    submittedByUser: 'Usuario',
    deliveryOrderNumber: 'Pedido de Entrega #',
    deliveryOrderDetails: 'Detalles del Pedido de Entrega',
    linkedProductionOrder: 'Orden de producción',
    linkedATMOrder: 'Make It (ATM) Order',
    shipmentPickupDate: 'Fecha de recogida',
    deliveryDate: 'Fecha de entrega',
    shipmentPickupTime: 'Hora de recogida',
    deliveryTime: 'Hora de entrega',
    shipmentShippedFrom: 'Enviado desde',
    shipmentCarrier: 'Empresa de transporte',
    deliveryOrderDates: 'Fechas del Pedido de Entrega',
    needToMakeChange: '¿Necesita hacer un cambio?',
    editShipments24Hours: 'Hasta 24 horas antes de la fecha y hora del envío, puede:',
    editShipmentsOption1:
        'Añadir/Quitar una versión de producto/gráfico del pedido global o de un envío individual',
    editShipmentsOption2: 'Actualizar la cantidad de un producto para un envío',
    editShipmentsOption3: 'Eliminar un envío de un pedido de entrega',
    editShipmentsOption4:
        'Actualizar la fecha u hora de un pedido de entrega siempre y cuando sea más de {{leadTime}} días a partir de la fecha actual',
    editShipmentsOption5:
        'Actualice las instrucciones de entrega, la secuencia de carga, el número de liberación, la línea de relleno y el número de referencia',
    editShipmentsOption6: 'Update delivery order date / time within the allowable delivery window',
    editShipmentsCancellingOrders:
        'Cancelación de pedidos: las cancelaciones deben completarse al menos 24 horas antes de la fecha de envío más temprana en el pedido de entrega.',
    editShipmentsContactRep:
        'Póngase en contacto con un representante de ventas de Ball si necesita solicitar un cambio diferente de los anteriores.',
    yourDeliveryOrder: 'Su Pedido De Entrega',
    skuCansBottles: '{{ skuCount }} SKU (Latas/Botellas)',
    linkedDeliveryOrderOrLeadTimeWarning:
        'Uno o más productos no pueden ser editados debido a los plazos de entrega',
    impersonationWarningDiscard:
        'This work cannot be discarded. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSave:
        'This work cannot be saved. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSubmit:
        'Este trabajo no puede ser enviado. Usted está usando un rol de suplantación de identidad que tiene acceso de solo lectura en The Source.',
    dataIsFiltered: 'Los datos están filtrados. Haga clic en el icono para borrar el filtro:',

    // -- Unauthorized -- //
    unauthorized: 'Sin autorización',
    youAreNotAuthorized: 'No tiene autorización para ver esta página',
    unknownActivity: 'this area',
    dontHaveAccess: "Looks like you don't have access to {{activityString}} for",
    deactivateReactivateUsers: 'Deactivate / Reactivate Users',
    makeItBulkATM: 'Make It Bulk ATM',
    makeItBulkUpload: 'Make It Bulk Upload',
    shipItBulkUpload: 'Ship It Bulk Upload',
    openAccess: 'Open Access',
    onboardingWizard: 'Onboarding Wizard',
    billingAndShipping: 'Billing And Shipping',

    // -- SHIP IT -- //
    createDeliveryOrder: 'CREAR PEDIDO DE ENTREGA',
    selectDeliveryWk: 'Seleccione su semana de entrega',
    todayWithDate: 'Hoy: {{ today }}',
    chooseDates: 'Choose Dates',
    firstAvailable: 'Primero disponible',
    firstShipment: 'Su primer envío debe ser al menos {{ leadTime }} días a partir de hoy',
    selectProductsForDelivery: 'Añadir productos a su pedido de entrega',
    shipitAddInstructions: `Cree su pedido especificando el número de pallets para cada producto que desea incluir y luego haga clic en el botón "AGREGAR" para agregarlo a su pedido.`,
    configureTrucksInstructions:
        'Construirá sus envíos y seleccionará fechas y horas de entrega en su próxima pantalla',
    totalPallets: 'Pallets totales',
    estimatedTrucks: 'Camiones estimados necesarios:',
    trucksVarianceInstructions:
        'El número de camiones variará según el peso total de los tapas / cierres en la entrega.',
    buildYourShipments: 'CONSTRUYE TUS ENVÍOS',
    deliveryWeek: 'Semana de entrega',
    nextDeliveryWeek: 'Próxima semana de entrega',
    youDidntLoadAll: `No cargaste todas tus paletas`,
    remainingPalletsToBeOrdered:
        'Hay {{ remainingBalance }} palés sin asignar en su pedido. Si continúa, los palés sin cargar permanecerán sin liberarse y estarán disponibles para futuros pedidos de entrega.',
    continueBuildingShipments: 'Seguir creando envíos',
    deliveryDay: 'Dia de entrega',
    availableQuantToLoad: 'Cantidad disponible de {{ maxPalletsToLoad }} PL para cargar',
    availOutOfMax: '{{ availablePallets }} PL de {{ maxPallets }} PL',
    addShipment: 'AGREGAR ENVÍO',
    productsToShip: 'Productos para enviar',
    remainingProductsToLoad: 'Restante para cargar: {{ totalProductPallets }} PL',
    remainingEndsToLoad: 'Restante para cargar: {{ totalEndsPallets }} PL',
    palletsAvailableOn: 'Disponible el {{ date }}: {{ firstAvail }} PL de {{ firstAvailMax }} PL',
    viewBalancesByDay: 'Ver saldos por día',
    notEnoughRemainingPallets:
        'Se superó la cantidad máxima de palés para un producto. Actualice las cantidades dentro del envío antes de guardar.',
    maximumNumberOfPalletsWarning:
        'La cantidad máxima de palés para un envío es de {{truckPalletLimit}} PL. Actualice las cantidades dentro del envío antes de guardar.',
    freightWeightWarning:
        'Su tarifa de flete se basa en un camión completo con 25 palés. ¿Se enviará menos de un camión completo? Le enviaremos una factura por separado por el resto del flete. Puede calcular la cantidad que verá en su factura de la siguiente manera:',
    atmFreightWeightWarning:
        'Su tarifa de flete se basa en un camión completo. ¿Se enviará menos de un camión completo? Le enviaremos una factura por separado por el resto del flete.',
    shipmentFeeModalWarning:
        'Su tarifa de flete se basa en un camión completo. ¿Se enviará menos de un camión completo? Le enviaremos una factura por separado por el resto del flete. Así es como se calcularía la cantidad en base a 25 palés.',
    feesMayIncur: 'Se puede incurrir en pagos por envíos de menos de un camión completo',
    deliverOn: 'Entregar el',
    pickupOn: 'Recoger el',
    addDeliveryInstructions:
        'Agregue un producto de la lista que creó e ingrese las cantidades aquí. El carrito se actualizará para mostrar los pallets restantes para cargar, si corresponde.',
    deliveryInstructions: 'Instrucciones de entrega',
    time: 'Hora',
    at: 'En',
    arriving: 'Llegando',
    none: 'Aucun',
    done: 'Done',
    deliveryMountainTime:
        'El horario de entrega debe ingresarse en horario de montaña (Mountain Time, MT)',
    deliveryTimeZone: 'Select desired delivery time',
    pleaseEnterDeliveryInstructions: 'Ingrese las instrucciones de entrega para este envío.',
    deliveryOrderError:
        'Las instrucciones de entrega no se guardaron correctamente. Inténtelo de nuevo más tarde.',
    deliveryOrder: 'Su Orden de Entrega',
    deliveryOrderPending: '# de Orden de Entrega Pendiente',
    deliveryConfirmationCarrierAlert:
        'El transportista será asignado 7 días antes de la fecha de entrega programada.',
    incursFees: 'El envío de menos de {{truckPalletLimit}} PL incurre en una tarifa.',
    mayIncurFees: 'El envío de menos de {{truckPalletLimit}} PL puede incurrir en una tarifa.',
    mayIncurFeesTruckload: 'El envío de menos de un camión completo puede incurrir en un pago.',
    shipmentSize:
        'El tamaño de envío estándar para una orden de entrega es de 25 palés, pero se pueden permitir hasta 27 palés para algunos lugares.',
    buildShipments: 'CONSTRUYE TUS ENVÍOS',
    shipment: 'Envío',
    shipments: 'Envíos',
    sku: 'SKU',
    skus: 'SKUs',
    delete: 'Eliminar',
    selectedWeek: 'Semana seleccionada',
    company: 'Empresa',
    availBalance: 'Saldo disponsible',
    numberOfPalletsToShip: 'Número de palets a enviar',
    releaseNumber: 'Número de liberación',
    loadSeq: 'Secuencia de carga',
    fillerLineNumber: 'Línea de llenado #',
    referenceNumber: 'Número de referencia',
    invalidBulkFile: 'Este tipo de archivo no es válido. Adjunte un archivo .csv.',
    invalidCsv: '*Cargue un archivo CSV válido',
    reviewOrder: 'Orden de Revisión',
    resetMapping: 'Restablecer Su Ssignación',
    mappingPermanentlyGone: 'Cualquier cambio realizado en su mapeo desaparecerá permanentemente.',
    yesReset: 'Sí, Restablecer',
    editMapping: 'Editar Asignación',
    changeDeliveryWeekWarning:
        'Cambiar su semana de entrega eliminará todos los productos que haya cargado en su pedido. ¿Desea continuar con el cambio de la semana de entrega?',
    deliveryOrderDayTimeError:
        'Los envíos en el mismo pedido de entrega no pueden tener fechas y horas duplicadas. Modifique la hora elegida.',
    changeDeliveryWeek: 'Change Delivery Week',
    changeDeliveryDayWarning:
        'Cambiar su día de entrega podría requerir alteraciones en su envío.  ¿Quieres continuar?',
    changeDeliveryDate: 'Fecha de entrega de la modificación',

    // -- Summary Pages -- //
    customerPoNumber: 'Número de orden de compra del cliente',
    status: 'Estado',
    createdBy: 'Creado por',
    dateSubmitted: 'Fecha Enviado',
    productGraphicId: 'ID producto / ID gráfico',
    graphicId: 'ID gráfico',
    descriptionCharacteristics: 'Descripción del producto / Caracteristicas',
    quantityOrdered: 'Cantidad pedida',
    quantityToDeliver: 'Cantidad a entregar',
    requestedDate: 'Fecha solicitada',
    open: 'Abierto',
    closed: 'Cerrado',
    availableToShip: 'Disponible para envío',
    cancelled: 'Cancelado',
    completed: 'Completar',
    productionOrderDetails: 'Detalles de orden de producción',
    totalOrderQuantity: 'Cantidad total de pedido',
    openProductionOrders: 'Órdenes de producción abiertas',
    closedProductionOrders: 'Órdenes de Producción Cerradas',
    totalDeliveryQuantity: 'Cantidad total de entrega',
    draftProductionOrders: 'Pedidos de Producción en Borrador',
    draftOrderName: 'Nombre del pedido de borrador',
    lastSaved: 'Última Guardado',
    lastSavedBy: 'Last Saved By',
    actions: 'Acciones',
    openDeliveryOrders: 'Pedidos de entrega abiertos',
    closedDeliveryOrders: 'Pedidos de entrega cerrados',
    planItPlanItSummary: 'Planificarlo/Planificarlo Resumen',
    orderStatus: 'Estado del pedido',
    pending: 'Pendiente',
    received: 'Pago recibido',
    reject: 'Rechazado',
    confirmed: 'Confirmado',
    shipConfirmed: 'Envío confirmado',
    draft: 'corriente de aire',
    delinquent: 'Pago adeudado',
    payable: 'En aprobación',
    originallyScheduled: 'Originalmente programado',
    lineStatus: 'Estado de la Línea',
    scheduledFor: 'Programado Para',
    receivedOn: 'Recibido En',

    // -- Date Pickers -- //
    legend: 'Leyenda',
    day: 'Día',
    restrictedDate: 'Fecha <br/> Restringida',
    dateFirstAvailable: 'Primer fecha disponible',
    availableDate: 'Fecha disponible',
    ok: 'Okay',

    // -- PRODUCT PLANNING -- //
    units: 'Unidades',
    expandAll: 'Expandir todo',
    collapseAll: 'Contraer todo',
    thisWeek: 'Esta Semana',
    weeks0to8: 'Semanas 0-8',
    weeks9to16: 'Semanas 9-16',
    productionBalance: 'Balance de Producción',
    weekOfWithDate: 'Semana de {{date}}',
    productionOrders: 'Orden(es) de producción',
    ordersPendingPayment: 'Pedidos pendientes de pago',
    originalRequestedDate: 'Fecha de vencimiento del pago',
    revisedAvailableDate: 'Fecha de solicitud original',
    late: 'Demora',
    deliveryDates: 'Fecha(s) de Entraga',
    productPlanning: 'Planificación de Productos',
    emptyString: '',
    week: 'Semana',
    graphicsID: 'Número de Gráfico',
    ballOrderNumber: 'Número de Pedido de Ball',

    // -- DELIVERY ORDER PDF -- //
    total: 'Total',
    dateTime: 'Fecha / Hora',

    // -- EDIT SHIPMENTS SUMMARY -- //
    pleaseSelectProductsShipment: 'Seleccione los productos que desea agregar a su envío',
    changesWillNotBeSaved: 'Los cambios realizados no se guardarán',
    areYouSureDiscard: '¿Está seguro de que desea descartar algún cambio?',
    yesDiscard: 'Sí, Descartar',
    changeDeliveryOrderModalTitle: 'Está a punto de cambiar su pedido de entrega',
    changeDeliveryOrderModalConfirmationText:
        '¿Está seguro de que desea guardar los cambios en su pedido de entrega?',
    yesSave: 'Sí, Guardar',

    // -- DELIVERY BULK UPLOAD --//
    mapTemplateFields: 'Campos de Plantilla de Mapa',
    mapShipItTemplateValues: 'Mapear Valores do Modelo de Envio',
    updateShipItTemplateValues: 'Atualizar Valores do Modelo de Envio',
    uploadShipItOrders: 'Enviar Seu(s) Pedido(s) de Envio',
    mapTemplateFieldsDescription:
        'Asigne los valores necesarios a su columna correspondiente en la plantilla. Todos los valores necesarios deben asignarse, los campos opcionales se pueden dejar en blanco si no los está utilizando.',
    templateFieldMapping: 'Asignación de Campos de Plantilla',
    shipToId: 'Enviar a ID',
    columnLetter: 'Carta de Columna',
    columnLetterOptional: 'Carta de Columna (Opcional)',
    fillerLine: 'Número de Línea de Llenado',
    saveMapping: 'Guardar Asignación',
    uploadOrderFile: 'Cargar Archivo de Pedido',
    bulkUploadOverview: 'Descripción General de la Carga Masiva',
    mapTemplateValues: 'Valores de Plantilla de Mapa',
    columnPatternError: 'Por favor, introduzca 1-2 letras',
    bulkUpload: 'Bulk Upload',
    reset: 'Restablecer',
    uploadDeliveryOrder: 'Upload Your Delivery Order',
    acceptedBulkFiles:
        'Por favor, cargue su archivo de .csv, asegurándose de que sus valores coincidan con su asignación.',
    lookUpShipTo: 'Need to look up a Ship To?',
    viewAssignedShipTos: 'View Assigned Ship Tos',
    multipleColumnValidationError:
        'Column Letters cannot be duplicated. Please review the above entries and resubmit.',
    mustSaveMapping: 'Debe guardar la asignación anterior para cargar un archivo.',
    documentName: 'NOMBRE DEL DOCUMENTO',
    documentNotUploaded: 'No se ha cargado ningún documento',
    removeFile: 'Eliminar archivo',
    confirmFileRemoval: 'Confirme que desea eliminar este archivo',
    areYouSureRemoveFile: '¿Está seguro de que desea eliminar este archivo?',
    yesRemove: 'Sí, Quitar',
    confirmCancel: '¿Estás seguro de que deseas cancelar?',
    lostFile:
        'Cualquier archivo cargado o asignaciones no guardadas desaparecerán permanentemente.',
    deliveryOrderHeader: 'Orden de Entrega {{deliveryOrderNumber}} - <i>ID pendiente</i>',
    deleteShipment: 'Eliminar Envío',
    editShipment: 'Editar Envío',
    delivery: 'Entrega',
    lowTruckPalletCountWarning: 'Esta entrega tiene una carga de camión inferior a la completa.',
    goBack: 'Volver',
    reviewYourShipments: 'Revise Sus Envíos',
    pleaseReviewShipmentDetails: '',
    allOrdersDeleted:
        'All orders have either been deleted or removed. Please "undo" at least one deleted order or upload a new order file to proceed.',
    allShipmentsDeleted:
        'All shipments have been deleted. Please "undo" at least one deleted shipment or upload a new order file to proceed.',
    allProductsRemoved: 'All products have been removed. Please add products to proceed.',
    line: 'Línea',
    fileErrors: 'Errores de archivo',
    lineErrorHelper: 'Corrija los siguientes errores y vuelva a cargar el archivo.',

    // -- DELIVERY BULK UPLOAD Errors -- //
    E000001: 'Enviar a ID es necesario y falta en esta línea. Por favor proporcione.',
    E000002: 'El ID del producto es necesario y falta en esta línea. Por favor proporcione.',
    E000003: 'La cantidad es necesaria y falta en esta línea. Por favor proporcione.',
    E000004: 'La fecha de entrega es necesaria y falta en esta línea. Por favor proporcione.',
    E000005: 'El tiempo de entrega es necesario y falta en esta línea. Por favor proporcione.',
    E100003: 'La cantidad debe ser un valor numérico menor o igual que 25.',
    E110001: 'La cantidad debe ser un valor numérico menor o igual que 25.',
    E100004:
        'La fecha de entrega está en un formato incorrecto. Por favor, introduzca como MM/DD/AAAA',
    E100005:
        'El tiempo de entrega está en un formato incorrecto. Por favor, ingrese como HHMM (24 horas de tiempo).',
    E100006:
        'Las instrucciones de entrega están en un formato incorrecto. El valor debe ser una cadena menor o igual que 30 caracteres.',
    E100007:
        'El número de versión está en un formato incorrecto. El valor debe ser una cadena menor o igual que 10 caracteres.',
    E100009:
        'El número de referencia está en un formato incorrecto. El valor debe ser una cadena menor o igual que 30 caracteres.',
    E100001:
        'Enviar a ID no válido. Compruebe la lista de los envíos asignados para obtener los ID de envío válidos.',
    E200001:
        'Enviar a ID no válido. Compruebe la lista de los envíos asignados para obtener los ID de envío válidos.',
    E200002: 'ID de producto no válido. Por favor, compruebe y corrija.',
    E100002: 'ID de producto no válido. Por favor, compruebe y corrija.',
    E200004:
        'El ID de producto introducido no se asigna al envío a introducido en esta línea. Cambie el Producto o Enviar a.',
    E300005:
        'No tiene permisos para enviar pedidos para este envío a. Elimine las filas con este archivo Enviar a.',
    E110002:
        'La fecha introducida es dentro de los 14 días de hoy. Por favor, introduzca una fecha que sea de al menos 14 días a partir de hoy.',
    E100008: 'Línea de relleno no válida. Por favor, compruebe y corrija.',
    // Note: if you change the following translations be sure the format strings, {n}, are put in the right place of the new string
    E300004:
        'Cantidad especificada para {0} de ID de producto en Enviar a {1} en {2} es mayor que el saldo disponible {3}.',
    E210005:
        'Cantidad introducida para Enviar a {0} en {1} es superior a 25 PL. Mueva algunos palés a una fecha/hora de entrega diferente para que cada fecha/hora de envío y entrega tenga un máximo de 25 PL.',

    // -- MAKE IT BULK UPLOAD -- //
    acceptedMakeItBulkFiles:
        'Please upload your .csv file, making sure your values match with your mapping.',
    uponSubmitCancelMessage:
        'Upon submitting, any products previously forecasted and not included again for the Forecast Week and Ship to will be canceled.',
    removeShipToLocationsP1:
        'If you would like to remove full Ship To locations from a forecast week, please edit those records via ',
    removeShipToLocationsP2:
        ' for each ship to and week or include those in the file with a 0 quantity.',
    productNotIncluded: 'Product not included on updated file. Enter quantity to add.',
    inactiveProductId: 'Este producto está inactivo y no se puede pedir.',

    // -- ASSIGNED SHIP TOS -- //
    yourAssignedShipTos: 'Su envie a asignado',
    shipToName: 'Enviar a nombre',
    shipToAddress: 'Enviar a dirección',
    shipTos: 'Envie a',

    availableBalanceDisclaimer: '*Saldo disponible basado en la semana de entrega seleccionada.',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'Ordering for your {0} products will close on {1}.  The first available production date is {2}. ',
    CAMPAIGN_UPCOMING:
        'Ordering for your {0} products will begin on {1}.  Orders must be placed by {2} and the first available production date is {3}. ',
    CAMPAIGN_UPDATE:
        'Ordering information for your {0} products has changed.  The updated ordering window is: {1} - {2} and the first available production date is {3}. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Created Date',
    unauthorizedPoTooltip: 'Not authorized to manage POs for this location',
    discard: 'Discard',
    resumeOrder: 'Resume Order',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Manage Products',
    productInformation: 'Product Information',
    activeProducts: 'Active Products',
    inactiveProducts: 'Inactive Products',
    planItSummaryDashboardDesc:
        'View previously submitted order forecasts. Edit future order forecasts.',
    previousForecasts: 'Previous Forecasts',

    // Countries
    usa: 'EEUU',
    eu: 'Europa',
    uk: 'Reino Unido',
    germany: 'Alemania',
    france: 'Francia',
    canada: 'Canadá',
    china: 'China',
    japan: 'Japón',
    southKorea: 'Corea del Sur',
    ausNZ: 'Australia/Nueva Zelanda',
    australia: 'Australia',
    nz: 'Nueva Zelanda',
    mexico: 'México',
    india: 'India',
    vietnam: 'Vietnam',
    taiwan: 'Taiwan',
    russia: 'Russia',
    argentina: 'Argentina',
    brazil: 'Brasil',
    paraguay: 'Paraguay',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    noCountryApplies: 'Ninguno de los países especificados se aplica'
};
