import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import Button from '../atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { OrderingState, ProductToShip } from '../../../store/reducers/ordering-dashboard';
import { productTypeHelper } from '../../../utility/helpers/order-helpers';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid #eee',
        borderRadius: '4px',
        padding: '16px',
        marginBottom: '16px'
    },
    textField: {
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: '.125em',
        width: '100%'
    },
    column: {
        flex: 1,
        textAlign: 'center',
        padding: '0 8px',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    redText: {
        color: 'red',
        fontSize: '8px'
    },
    hidden: {
        display: 'none'
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3em'
    }
}));

interface OrderProductCardProps {
    product: ProductToShip;
    IsAnyShipmentEditing: boolean;
    onAddProduct: () => void;
}

const OrderProductCard: React.FC<OrderProductCardProps> = ({
    product,
    IsAnyShipmentEditing,
    onAddProduct
}) => {
    const classes = useStyles();
    //const [pallets, setPallets] = useState(product.orderedPallets);
    //const [error, setError] = useState<string | null>(null);
    const [isProductDisabled, setIsProductDisabled] = useState<boolean>(false);

    const { shipmentsOrder } = useTypedSelector<OrderingState>((state) => state.orderingDashboard);

    useEffect(() => {
        const currentShipment = shipmentsOrder?.find(
            (shipment) => shipment.editing && !shipment.saved
        );
        if (currentShipment && currentShipment.loads) {
            const productInShipment = currentShipment?.loads.find(
                (p) => p.productSku === product.productSku
            );
            if (productInShipment) {
                setIsProductDisabled(true);
            } else {
                setIsProductDisabled(false);
            }
            if (
                currentShipment.shipmentType &&
                currentShipment.shipmentType !== productTypeHelper(product.type)
            ) {
                setIsProductDisabled(true);
            }
        } else {
            setIsProductDisabled(false);
        }
    }, [shipmentsOrder, product.productSku, product.type]);

    /*
    const handleQuantityInputChange = (count: string) => {
        const newPallets = parseInt(count, 10);
        if (!isNaN(newPallets)) {
            if (product.availablePallets !== undefined && newPallets > product.availablePallets) {
                setError(`Cannot add more than ${product.availablePallets} pallets`);
            } else if (newPallets > 25) {
                setError('Cannot add more than 25 pallets');
            } else {
                setError(null);
                //setPallets(newPallets);
            }
        }
    };
    */

    const handleAddProduct = () => {
        onAddProduct();
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={2} className={classes.column}>
                <Typography>{product.productId}</Typography>
                <Typography style={{ fontWeight: 'bold' }}>{product.productSku}</Typography>
                <Typography>{product.graphicId}</Typography>
            </Grid>
            <Grid container item xs={4} className={classes.column} direction="column">
                <Typography style={{ fontWeight: 'bold' }}>{product.displayName}</Typography>
                <Typography>{product.description}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.column}>
                <Typography>{product.coating}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.column}>
                <Typography>{product.size}</Typography>
            </Grid>
            <Grid item xs={2} className={`${classes.column} ${classes.hidden}`}>
                <Typography style={{ fontWeight: 'bold' }}>
                    {product.availablePallets} PL
                </Typography>
            </Grid>
            <Grid container item xs={2} className={classes.column} direction="column">
                {/*
                <input
                    type="number"
                    value={pallets}
                    onChange={(e) => handleQuantityInputChange(e.target.value)}
                    className={classes.textField}
                />
                {error && <Typography className={classes.redText}>{error}</Typography>}
                */}

                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={handleAddProduct}
                    disabled={!IsAnyShipmentEditing || isProductDisabled}
                    className={classes.addBtn}
                    data-testid="add-product-btn"
                >
                    <Trans i18nKey="add">Add</Trans>
                </Button>
            </Grid>
        </Grid>
    );
};

export default OrderProductCard;
