import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import NavBarTile from './NavBarTile';
import NavBarMenu from './NavBarMenu';

const useStyles = makeStyles((theme) => ({
    createOrderIcon: {
        marginBottom: '0.625em',
        maxHeight: '3em'
    },
    bulkOrderIcon: {
        marginBottom: '0.625em',
        maxHeight: '3em'
    },
    reviewOrderIcon: {
        marginBottom: '0.625em',
        maxHeight: '3em'
    },
    reviewShipmentIcon: {
        marginTop: '15%',
        marginBottom: '0.625em',
        maxHeight: '3em'
    }
}));

const OrderingNavV2 = () => {
    const classes = useStyles();
    const createOrderIcon = '/Create_Orders_Nav_Icon.png';
    const bulkOrderIcon = '/Bulk_Upload_Orders_Nav_Icon.png';
    const reviewOrderIcon = '/Review_Orders_Nav_Icon.svg';
    const reviewShipmentIcon = '/Ship_It_Icon_Nav.svg';

    /**
     * New Bulk upload feature:
     * /bulk-upload-orders
     *
     * Old Bulk Upload Feature:
     * /ship-it-bulk-upload
     */
    const paths = ['/create-order', '/ship-it-bulk-upload', '/orders-summary', '/review-shipments'];

    return (
        <NavBarMenu menuName={'Ordering'} sections={[]} paths={paths}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <NavBarTile
                        link={'/create-order'}
                        translationKey="createNewOrderTranslationKey"
                        defaultText="Create a new Order"
                        icon={createOrderIcon}
                        isProduct={false}
                        testId={'createOrderCard'}
                        secondaryText="Create a new Order"
                        secondaryTranslationKey="createNewOrderSecondaryTranslationKey"
                        iconClassName={classes.createOrderIcon}
                    />
                </Grid>
                <Grid item xs={3}>
                    <NavBarTile
                        link="/ship-it-bulk-upload"
                        translationKey="bulkUploadTranslationKey"
                        defaultText="Bulk Upload"
                        icon={bulkOrderIcon}
                        isProduct={false}
                        testId={'bulkUploadId'}
                        secondaryText="Upload Multiple Orders"
                        secondaryTranslationKey="bulkUploadSecondaryTranslationKey"
                        iconClassName={classes.bulkOrderIcon}
                    />
                </Grid>
                <Grid item xs={3}>
                    <NavBarTile
                        link="/orders-summary"
                        translationKey="reviewOrdersTranslationKey"
                        defaultText="Review Orders"
                        icon={reviewOrderIcon}
                        isProduct={false}
                        testId={'reviewOrderId'}
                        secondaryText="Review delivery orders and pick-ups"
                        secondaryTranslationKey="reviewOrdersSecondaryTranslationKey"
                        iconClassName={classes.reviewOrderIcon}
                    />
                </Grid>
                <Grid item xs={3}>
                    <NavBarTile
                        link="/review-shipments"
                        translationKey="reviewShipmentsTranslationKey"
                        defaultText="Shipments"
                        icon={reviewShipmentIcon}
                        isProduct={false}
                        testId={'reviewShipmentId'}
                        secondaryText="Review delivery orders and pick-ups"
                        secondaryTranslationKey="reviewShipmentsSecondaryTranslationKey"
                        iconClassName={classes.reviewShipmentIcon}
                    />
                </Grid>
            </Grid>
        </NavBarMenu>
    );
};

export default OrderingNavV2;
