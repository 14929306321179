import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { OrderingState } from '../../../store/reducers/ordering-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { small } from '../../../themes/globalConstants';

const useStyles = makeStyles(() => ({
    totalsText: {
        textTransform: 'uppercase',
        fontSize: small
    },
    totalsHeader: {
        textDecoration: 'underline',
        fontWeight: 'bold'
    }
}));

export default function InformationalCard() {
    const classes = useStyles();
    const { orderInformation } = useTypedSelector<OrderingState>(
        (state) => state.orderingDashboard
    );

    const orderShipmentCount = orderInformation?.shipmentCount ?? 0;
    const orderPalletCount = orderInformation?.palletCount ?? 0;
    const orderProductCount = orderInformation?.productCount ?? 0;

    return (
        <Grid item>
            <Grid container item xs={12} justify="flex-end">
                <Typography className={clsx(classes.totalsText, classes.totalsHeader)}>
                    <Trans i18nKey="totalsForOrder">Totals for this Order</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="flex-end">
                <Typography className={classes.totalsText}>
                    <Trans i18nKey="shipments">Shipments</Trans>: {orderShipmentCount || ' - '}
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="flex-end">
                <Typography className={classes.totalsText}>
                    <Trans i18nKey="pallets">Pallets</Trans>: {orderPalletCount || ' - '}
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="flex-end">
                <Typography className={classes.totalsText}>
                    <Trans i18nKey="products">Products</Trans>: {orderProductCount || ' - '}
                </Typography>
            </Grid>
        </Grid>
    );
}
