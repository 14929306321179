import * as types from './action-types';
import AccountService from '../../utility/services/account-service';
import { Account, CustomerContextState } from '../reducers/customer-context';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../utility/AppInsights';
import { loadState } from '../../utility/helpers/sessionStorage';

export const selectCustomerAccountId = (accountId: string) => ({
    type: types.CUSTOMER_SELECT_ACCOUNT,
    selectedAccountId: accountId
});

export const setLastShipToId = (accountId: string) => ({
    type: types.CUSTOMER_LAST_SHIP_TO,
    lastShipToIdSelected: accountId
});

export const setLastBillToId = (accountId: string) => ({
    type: types.CUSTOMER_LAST_BILL_TO,
    lastBillToIdSelected: accountId
});

export const updateAccounts = (accounts: Account[]) => ({
    type: types.CUSTOMER_LOADED,
    accounts: accounts
});
export const selectChildrenAccountId = (accountId: string) => ({
    type: types.CUSTOMER_SELECT_CHILDREN_ACCOUNT,
    selectedChildrenAccountId: accountId
});

// Gets all accounts associated to a user with Hierarchies
export const getShipToAccounts = () => {
    return (dispatch, getState) => {
        if (getState()?.customerContext?.loading) {
            return;
        }
        dispatch({ type: types.CUSTOMER_SHIP_TOS_LOADING });

        return Promise.all([
            AccountService.getAccounts(getState(), true),
            AccountService.getUserAccounts(getState())
        ])
            .then(([accountShipTo, adminAccountResponse]) => {
                const accounts = accountShipTo.data?.accounts || [];
                const shipTos = accounts?.filter(
                    (account) =>
                        (account.addressType === 'CS' || account.addressType === 'CX') &&
                        account.status === 'Active'
                );
                const adminAccounts =
                    adminAccountResponse.data?.accounts?.map((account) => {
                        return {
                            ...account,
                            shipToAccounts: shipTos.filter(
                                (shipTo) => shipTo.soldToAccount === account.accountId
                            )
                        };
                    }) || [];

                if (shipTos?.length !== 0) {
                    dispatch({
                        type: types.CUSTOMER_SHIP_TOS_LOADED,
                        accounts: accounts,
                        shipToAccounts: shipTos,
                        adminAccounts: adminAccounts
                    });
                    dispatch(getDefaultShipTo(getState(), shipTos));
                } else if (accounts?.length > 0) {
                    dispatch({
                        type: types.CUSTOMER_LOADED,
                        accounts: accounts
                    });
                } else {
                    dispatch({ type: types.CUSTOMER_NO_SHIP_TOS_LOADED });
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.CUSTOMER_SHIP_TOS_LOADING_ERROR,
                    error: error.message
                });
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError = error.message + ' getShipToAccounts-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            });
    };
};

// If no selectedShipTo, defaults to first account in ShipTo array
export const getDefaultShipTo = (state: any, shipTos: any) => {
    return (dispatch) => {
        if (state.customerContext.selectedAccountId === '') {
            const lastStoredSelection = loadState('selectedAccountId');
            const accountFromLastStoredSelection = shipTos.find(
                (account) => account.accountId === lastStoredSelection
            );
            const selectedShipTo =
                accountFromLastStoredSelection?.accountId || shipTos[0].accountId;
            dispatch(selectCustomerAccountId(selectedShipTo));
        }
    };
};

// Gets all accounts associated to a user without shipTo hierarchies
export const getUserCustomerAccounts = () => {
    return (dispatch, getState) => {
        dispatch({ type: types.CUSTOMER_LOADING });

        return AccountService.getAccounts(getState(), true)
            .then((customers) => {
                const customerAccounts = customers.data.accounts;
                dispatch({
                    type: types.CUSTOMER_LOADED,
                    accounts: customerAccounts
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.CUSTOMER_LOADING_ERROR,
                    error: error.message
                });
            });
    };
};

export const updateOrderingV2Flag = (globalOrderingV2Flag) => {
    return (dispatch, getState) => {
        const { customerContext } = getState();
        const { selectedAccountId, selectedChildrenAccountId, accounts } =
            customerContext as CustomerContextState;

        if (!globalOrderingV2Flag) {
            dispatch({
                type: types.CUSTOMER_UPDATE_ORDERING_FLAG,
                useOrderingV2: false
            });
            return;
        }

        let useAccountLevelFlag = false;
        if (accounts) {
            let account: Account | undefined;
            let childrenAccount: Account | undefined;

            if (!selectedAccountId && !selectedChildrenAccountId) {
                useAccountLevelFlag = true;
            }

            if (selectedAccountId) {
                account = accounts.find((account) => account.accountId === selectedAccountId);
                useAccountLevelFlag = !!account?.orderingV2;
            }
            if (selectedChildrenAccountId) {
                childrenAccount = accounts.find(
                    (account) => account.accountId === selectedChildrenAccountId
                );
                useAccountLevelFlag = !!childrenAccount?.orderingV2;
            }
        }

        dispatch({
            type: types.CUSTOMER_UPDATE_ORDERING_FLAG,
            useOrderingV2: useAccountLevelFlag
        });
    };
};
