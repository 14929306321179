import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectSelectedShipTo } from '../../../store/selectors';
import ReactPdf from '@react-pdf/renderer';
import { Activity } from '../../../utility/auth/useSecurity';
import { enUS } from '../../../utility/translations/locales';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import ProductInformationGrid from './components/ProductInformationGrid';
import { ProductInformationRow } from './models/product-information-row.interface';
import { CSVLink } from 'react-csv';
import { ballBlue, medium, regularWeight } from '../../../themes/globalConstants';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { Culture } from '../../../store/reducers/region-culture';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { PRODINFO_PRODUCTS_UPDATE_VIEW } from '../../../store/actions/action-types';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { selectCustomerAccountId } from '../../../store/actions/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import {
    loadProductsForProductInformationPage,
    productInformationPageResetState,
    productInformationPageUpdateView
} from '../../../store/actions/product-information';
import { ProductInfoState } from '../../../store/reducers/product-information';

const useStyles = makeStyles(() => ({
    container: {
        padding: '0'
    },
    shareLink: {
        marginTop: '1em'
    },
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em'
    }
});

export default function ProductInformationPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const query = useQuery();
    const from = query.get('from');
    const selectedShipToFromQuery = query.get('selectedShipTo');

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const selectedShipTo = useTypedSelector(selectSelectedShipTo);
    const { products, loading, view } = useTypedSelector<ProductInfoState>(
        (state) => state.productInformation
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [lockPeriodWeeks, setLockPeriodWeeks] = useState<number>(0);
    const [data, setData] = useState<Array<ProductInformationRow>>([]);
    const [fromMakeIt, setFromMakeIt] = useState<boolean>(from === 'make-it');
    const [fromPlantIt, setFromPlanIt] = useState<boolean>(from === 'plan-it');
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);
    const [filteredViewProducts, setFilteredViewProducts] = useState<OrderProductTableRow[]>();

    // Set up CSV file name information
    const today = new Date().toLocaleDateString(Culture.enUS, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    const fileName = `${t('productInformationToday', {
        shipToId: selectedAccountId,
        today: today
    })}.csv`;

    // Set up CSV File information
    interface CSVRow {
        shipToId?: string;
        customer_product_id?: string;
        ball_product_id?: string;
        product_description?: string;
        coating?: string;
    }

    const csvBaseHeaders = [
        { label: t('shipToId', 'SHIP TO ID').toUpperCase(), key: 'shipToId' },
        {
            label: t('customerProductId', 'CUSTOMER PRODUCT ID').toUpperCase(),
            key: 'customer_product_id'
        },
        { label: t('ballProductId', 'BALL PRODUCT ID').toUpperCase(), key: 'ball_product_id' },
        {
            label: t('productDescription', 'PRODUCT DESCRIPTION').toUpperCase(),
            key: 'product_description'
        },
        { label: t('coating', 'COATING').toUpperCase(), key: 'coating' }
    ];
    const [csvHeaders, setCsvHeaders] = useState<any>(csvBaseHeaders);

    useEffect(() => {
        return () => {
            dispatch(productInformationPageResetState());
        };
    }, []);

    useEffect(() => {
        if (fromPlantIt) {
            setCsvHeaders(
                csvBaseHeaders.concat([
                    {
                        label: t('forecastWeek', 'FORECAST WEEK').toUpperCase(),
                        key: 'forecastWeek'
                    },
                    {
                        label: t('forecastedQuantity', 'FORECASTED QUANTITY').toUpperCase(),
                        key: 'forecastedQuantity'
                    }
                ])
            );
        }
    }, [fromPlantIt]);

    const csvArray = () => {
        let rows: any[] = [];
        data.forEach((product) => {
            if (product.status === 'ACTIVE') {
                const row: CSVRow = {
                    shipToId: selectedAccountId,
                    customer_product_id: product.customerProductId ?? '-',
                    ball_product_id: product.productSku,
                    product_description: product.customerProductName
                        ? product.customerProductName
                        : product.name,
                    coating: product.coating
                };
                rows.push(row);
            }
        });
        return rows;
    };

    const csvContents = csvArray();

    useEffect(() => {
        setFromMakeIt(from === 'make-it');
        setFromPlanIt(from === 'plan-it');
    }, [from]);

    useEffect(() => {
        if (selectedAccountId) {
            setLockPeriodWeeks((selectedShipTo?.lockedPeriodDays || 14) / 7);
            dispatch(
                loadProductsForProductInformationPage(
                    fromMakeIt
                        ? Activity.MakeItBulkUpload
                        : fromPlantIt
                        ? Activity.PlanItBulkUpload
                        : Activity.ShipItBulkUpload
                )
            );
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (selectedShipToFromQuery && auth && auth.authenticated && auth.permissions) {
            dispatch(selectCustomerAccountId(selectedShipToFromQuery));
        }
    }, [selectedShipToFromQuery, auth]);

    useEffect(() => {
        if (filteredViewProducts && lockPeriodWeeks) {
            let updatedProducts = filteredViewProducts.map((product) => {
                (product as ProductInformationRow).leadTimeWeeksFormatted =
                    product.leadTimeString ?? '';
                (
                    product as ProductInformationRow
                ).lockWindowWeeksFormatted = `${lockPeriodWeeks} ${t('weeks', enUS.weeks)}`;
                if (product.destinations) {
                    let name: string | undefined = undefined;
                    let id: string | undefined = undefined;

                    const destination = product.destinations?.find(
                        (destination) => destination.shipToId.toString() === selectedAccountId
                    );

                    if (destination) {
                        name = destination.customerProductName;
                        id = destination.customerProductId;
                    }

                    product.customerProductName = name;
                    product.customerProductId = id;
                }
                product.displayId = product.customerProductId ? product.customerProductId : '-';
                product.displayName = product.customerProductName
                    ? product.customerProductName
                    : product.name;
                return product;
            }) as Array<ProductInformationRow>;

            setData(updatedProducts);
        }
    }, [filteredViewProducts, lockPeriodWeeks]);

    const handleViewFilterClicked = (viewType: string[]) => {
        setCurrentViewTypes(viewType);
    };

    useEffect(() => {
        if (products) {
            handleViewFilterClicked(currentViewTypes);
        }
    }, [products]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(
                productInformationPageUpdateView(currentViewTypes, PRODINFO_PRODUCTS_UPDATE_VIEW)
            );
        }
        if (products && currentViewTypes) {
            if (currentViewTypes[0] === '') {
                setFilteredViewProducts(products);
            } else if (currentViewTypes[0] === 'CAMPAIGN') {
                const updatedProducts = products.filter((product) => product.campaignTiming);
                setFilteredViewProducts(updatedProducts);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, currentViewTypes]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('atmProductForecastBannerTitle', enUS.atmProductForecastBannerTitle),
                description: '',
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.ForecastProductInformation}
            loading={loading}
        >
            <Grid
                container
                item
                xs={12}
                spacing={2}
                alignItems="center"
                justify="flex-end"
                className={classes.shareLink}
            >
                <Grid item>
                    <Typography variant="h6" className={classes.label}>
                        <Trans i18nKey="share">share</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        underline="always"
                        data-testid="download-btn"
                        headers={csvHeaders}
                        data={csvContents}
                        filename={fileName}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            </Grid>
            <div className={classes.container}>
                <ProductInformationGrid
                    data={data}
                    lockPeriodWeeks={lockPeriodWeeks}
                    fromMakeIt={fromMakeIt}
                    fromPlanIt={fromPlantIt}
                    onViewFiltersClicked={handleViewFilterClicked}
                />
            </div>
        </ProcessingPageTemplate>
    );
}
