import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import {
    blackWeight,
    ballGray,
    small,
    warningLabel,
    ballBlue,
    boldWeight
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import EditShipmentsConfigColumns from './EditShipmentsConfigColumns';
import EditShipmentsConfigRow from './EditShipmentsConfigRow';
import { useDispatch } from 'react-redux';
import {
    changeAllExpandableLoads,
    changeShipmentExpandableLoads
} from '../../../../store/actions/edit-shipments';
import ShipItConfigToolbar from '../../../reusable/molecules/ShipItConfigToolbar';

interface Props {
    shipmentId?: string;
    items: ProductToShip[];
    onUpdateItem: (item: ProductToShip, palletDifference?: number) => void;
    onRemoveItem: (item: ProductToShip) => void;
    onUpdatePallets: (item: ProductToShip, numOfPallets: number, palletDifference: number) => void;
    warning?: boolean;
    warningText?: React.ReactNode;
    isBulk?: boolean;
    readOnly?: boolean;
    maxPallets?: number;
    availBalanceWarning?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '6%'
                },
                '&:nth-child(2)': {
                    width: '13%'
                },
                '&:nth-child(3)': {
                    width: '13%'
                },
                '&:nth-child(4)': {
                    width: '23%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '20%'
                },
                '&:nth-child(7)': {
                    width: '5%'
                },
                '&:nth-child(8)': {
                    width: '5%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        padding: '0.875em 0',
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: ballBlue,
            display: 'flex',
            alignItems: 'center'
        }
    },
    warning: {
        backgroundColor: warningLabel
    },
    warningText: {
        fontWeight: boldWeight
    }
}));

export default function EditShipmentsConfigGrid({
    shipmentId,
    items,
    onRemoveItem,
    onUpdateItem,
    onUpdatePallets,
    warning = false,
    warningText,
    isBulk = false,
    readOnly = false,
    maxPallets,
    availBalanceWarning
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const dispatch = useDispatch();
    const { state } = useTypedSelector((state) => state);
    let itemIndex = 0;

    const onCollapseAll = () => {
        if (shipmentId) {
            dispatch(changeShipmentExpandableLoads(state, shipmentId.toString(), false));
        } else if (isBulk) {
            dispatch(changeAllExpandableLoads(false));
        }
    };
    const onExpandAll = () => {
        if (shipmentId) {
            dispatch(changeShipmentExpandableLoads(state, shipmentId.toString(), true));
        } else if (isBulk) {
            dispatch(changeAllExpandableLoads(true));
        }
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <ShipItConfigToolbar
                onCollapseAll={onCollapseAll}
                onExpandAll={onExpandAll}
                warning={warning}
                warningText={warningText}
                maxPallets={maxPallets}
                availBalanceWarning={availBalanceWarning}
            />
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const EditShipmentsProductRow = ({ row }: { row: Partial<ProductToShip> }) => {
        return (
            <EditShipmentsConfigRow
                shipmentId={shipmentId}
                key={generateKey('product')}
                item={row as ProductToShip}
                index={itemIndex++}
                onRemoveItem={onRemoveItem}
                onUpdateItem={onUpdateItem}
                onUpdatePallets={onUpdatePallets}
                isBulk={isBulk}
                readOnly={readOnly}
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper} key={generateKey('product-grid')}>
            <DataGrid
                columns={EditShipmentsConfigColumns.getEditShipmentsConfigColumns(cultureCode)}
                dataSource={items}
                gridName="Edit Shipments Config Grid"
                rowComponent={EditShipmentsProductRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
