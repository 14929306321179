import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import Link from '../../reusable/atoms/Link';
import Button from '../../reusable/atoms/Button';
import ShipItGrid from './components/ShipItGrid';
import ShipItCheckout from './components/ShipItCheckout';
import ShipItDeliveryOptions from './components/ShipItDeliveryOptions';
import ShipItProductsOptions from './components/ShipItProductsOptions';
import {
    ShippingState,
    ProductWithPallets,
    ProductToShip
} from '../../../store/reducers/shipping-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    loadShippingDashboard,
    resetShipItProductState,
    resetShipItState,
    shippingDashboardUpdatePallets,
    shippingDashboardUpdateRange,
    shippingDashboardUpdateView,
    updateProductToBeShipped
} from '../../../store/actions/shipping-dashboard';
import { ltBlueGrey_34, shipItGridName, warningLabel } from '../../../themes/globalConstants';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity, Persona, SecurityLevel } from '../../../utility/auth/useSecurity';
import {
    CreditHold,
    CustomerContextState,
    PaymentTerms
} from '../../../store/reducers/customer-context';
import {
    SHIPPING_DASHBOARD_UPDATE_RANGE,
    SHIPPING_DASHBOARD_UPDATE_VIEW
} from '../../../store/actions/action-types';
import { isProductCanType, isProductEndType } from '../../../utility/helpers/order-helpers';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import { LocalStorage } from 'tubular-common';
import { AuthState } from '../../../store/reducers/auth';
import { getStringInputValue } from '../../../utility/helpers/grid-helpers';
import { getPersonaAccounts } from '../../../utility/helpers/user-helpers';
import { getProductCellQuantities } from '../../../utility/helpers/shipment-helpers';
import QuantityUnitSelector from '../../reusable/molecules/QuantityUnitSelector';
import { QuantityUnitSelectorProvider } from '../../reusable/molecules/QuantityUnitSelectorContext';
import { selectIsBillToAccount } from '../../../store/selectors';
import {
    clearContractsAndDocuments,
    loadContractsAndDocuments
} from '../../../store/actions/contract-summary';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import NotificationBanner from '../../reusable/molecules/NotificationBanner';
import { getCurrentlyActiveDocuments } from '../../../utility/helpers/pricing-helpers';

export interface CheckOutItem {
    product: ProductWithPallets;
    pallets: number;
}

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '1.75em'
    },
    side: {
        backgroundColor: ltBlueGrey_34,
        flexDirection: 'column',
        padding: '1em',
        marginTop: '.5em'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    addProducts: {
        marginBottom: '5em',
        height: '6em'
    },
    creditHoldWarningMessage: {
        width: '100%',
        margin: '0 1.3em 1.3em',
        padding: '1em',
        backgroundColor: warningLabel,
        justifyContent: 'center'
    },
    fullWidth: {
        width: '100%'
    },
    disabledLink: {
        //This will disable the link in all non-IE browsers
        pointerEvents: 'none',
        //This is for IE
        cursor: 'default'
    },
    bulkUploadBtn: {
        marginBottom: '1em',
        marginRight: '1.5em'
    },
    quantityUnitSelector: {
        marginTop: '1em'
    }
}));

export default function ShipIt() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        productsWithPallets,
        products,
        view,
        range,
        productsToBeShipped,
        fromConfig,
        loading,
        quantityUnit
    } = useTypedSelector<ShippingState>((state) => state.shippingDashboard);

    const { selectedAccountId, accounts, useOrderingV2 } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const { documents } = useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const pricingLoading = useTypedSelector<boolean>((state) => state.contractSummary.loading);

    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const [open, setOpen] = useState<boolean>(false);
    const [draft, setDraft] = useState<boolean>(false);
    const [checkOutList, setCheckOutList] = useState<CheckOutItem[]>([]);
    const [cansList, setCansList] = useState<CheckOutItem[]>([]);
    const [endsList, setEndsList] = useState<CheckOutItem[]>([]);
    const [totalPallets, setTotalPallets] = useState<number>(0);
    const [canPallets, setCanPallets] = useState<number>(0);
    const [endPallets, setEndPallets] = useState<number>(0);
    const [reloaded, setReloaded] = useState<boolean>(true);
    const [currentViewTypes, setCurrentViewTypes] = useState<string[]>(['']);
    const [currentRangeTypes, setCurrentRangeTypes] = useState<string[]>(['']);
    const [shipToIdCache, setShipToIdCache] = useState<string | undefined>('');
    const [hasCreditHold, setHasCreditHold] = useState<boolean>(false);
    const [copackerAccounts, setCopackerAccounts] = useState<string[]>([]);
    const [isCopacker, setIsCopacker] = useState<boolean>(false);
    const [storage] = useState<any>(new LocalStorage(shipItGridName));
    const [navToConfigLocked, setNavToConfigLocked] = useState<boolean>(true);
    const [leadTime, setLeadTime] = useState<number>();
    const isBillToAccount = useTypedSelector<boolean>(selectIsBillToAccount);

    const dashboardLink = '/dashboard';
    const bulkUploadLink = '/ship-it-bulk-upload';
    const shipItConfigLink = navToConfigLocked ? '#' : '/ship-it-configuration';
    const canSaveDraft = false;

    useEffect(() => {
        if (useOrderingV2) {
            history.push('/dashboard');
        }
    }, [useOrderingV2, history]);

    useEffect(() => {
        const account = accounts?.find((acc) => acc.accountId === selectedAccountId);
        if (selectedAccountId && account && (account?.listCustomer || !isBillToAccount)) {
            dispatch(loadContractsAndDocuments([selectedAccountId], !isBillToAccount));
        } else {
            dispatch(clearContractsAndDocuments());
        }
    }, [selectedAccountId, isBillToAccount, accounts]);

    const someCurrentlyActiveDocumentIsUnapproved = useMemo(() => {
        const currentlyActiveDocuments = getCurrentlyActiveDocuments(documents);
        const someCurrentlyActiveDocumentIsUnapproved = currentlyActiveDocuments.some(
            (d) => !d.isApproved
        );

        return someCurrentlyActiveDocumentIsUnapproved;
    }, [documents]);

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onConfirmedCancel = () => {
        handleBackNavigation();
        setOpen(false);
    };

    const handleBackNavigation = () => {
        history.push(dashboardLink);
    };

    const onSaveDraft = () => {
        setDraft(true);

        if (open) {
            setOpen(false);
        }
        // mimic saving draft functionality
        setTimeout(() => {
            setDraft(false);
        }, 1000);
    };

    const onCancel = () => {
        setOpen(true);
    };

    //Handles adding products to checkout
    const handleAddItem = (product: ProductWithPallets, numberOfPallets: number, type?: string) => {
        var newEndsList: CheckOutItem[];
        var newCansList: CheckOutItem[];
        var itemList: CheckOutItem[];

        const checkOutIndex = indexHelper(checkOutList, product);
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);

        if (isEndType) {
            if (checkOutIndex !== -1) {
                let updatedEndsList = [...endsList];
                const index = indexHelper(updatedEndsList, product);
                const updatedPallets = updatedEndsList[index].pallets + numberOfPallets;
                updatedEndsList.splice(index, 1, {
                    product: product,
                    pallets: updatedPallets
                });

                setEndsList(updatedEndsList);
                updateCheckOutList(checkOutList, product, numberOfPallets);
                setEndPallets(endPallets + numberOfPallets);
            } else {
                newEndsList = [{ product: product, pallets: numberOfPallets }, ...endsList];
                setEndsList(newEndsList);
                itemList = [...cansList, ...newEndsList];
                setCheckOutList(itemList);
                setEndPallets(endPallets + numberOfPallets);
            }
        } else if (isCanType) {
            if (checkOutIndex !== -1) {
                let updatedCansList = [...cansList];
                const index = indexHelper(updatedCansList, product);
                const updatedPallets = updatedCansList[index].pallets + numberOfPallets;
                updatedCansList.splice(index, 1, {
                    product: product,
                    pallets: updatedPallets
                } as CheckOutItem);

                setCansList(updatedCansList);
                updateCheckOutList(checkOutList, product, numberOfPallets);
                setCanPallets(canPallets + numberOfPallets);
            } else {
                newCansList = [{ product: product, pallets: numberOfPallets }, ...cansList];
                setCansList(newCansList);
                itemList = [...newCansList, ...endsList];
                setCheckOutList(itemList);
                setCanPallets(canPallets + numberOfPallets);
            }
        }
        setTotalPallets(totalPallets + numberOfPallets);
    };

    //Handles adding pallets for table
    const handlePalletAdd = (product: ProductWithPallets, numberOfPallets: number) => {
        let currentProductPallets = {
            ...product,
            availablePallets: product.availablePallets,
            availableItemsPerPallet: product.availableItemsPerPallet,
            inputPallets: product.inputPallets,
            orderedPallets: product.orderedPallets
        };

        let itemIndex = productsWithPallets!.findIndex(
            (product) => product.productSku === currentProductPallets.productSku
        );

        if (itemIndex !== -1) {
            let requestedPallets = numberOfPallets;
            const availablePallets = product?.availablePallets;

            if (availablePallets) {
                const newOrderedPallets = product.orderedPallets! + requestedPallets;
                const newAvailablePallets = availablePallets - requestedPallets;
                const newAvailableItems = product.quantityPerPallet!;

                setPalletsForProduct(
                    product,
                    newAvailablePallets,
                    newAvailableItems,
                    newOrderedPallets
                );
            }
        }
    };

    const indexHelper = (list: any, item: any) => {
        let index = -1;
        index = list.findIndex((listItem) => listItem.product.productSku === item.productSku);
        return index;
    };

    const updateCheckOutList = (
        checkOutList: CheckOutItem[],
        product: ProductWithPallets,
        numberOfPallets: number
    ) => {
        let updatedCheckOutList = [...checkOutList];

        const checkOutIndex = indexHelper(checkOutList, product);
        const updatedPallets = updatedCheckOutList[checkOutIndex].pallets + numberOfPallets;

        updatedCheckOutList.splice(checkOutIndex, 1, {
            product: product,
            pallets: updatedPallets
        } as CheckOutItem);

        setCheckOutList(updatedCheckOutList);
    };

    const handleRemoveItem = (i: number, pallets: number, type?: string) => {
        var newEndsList: CheckOutItem[];
        var newCansList: CheckOutItem[];
        var itemList: CheckOutItem[];
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);

        if (isCanType) {
            newCansList = [...cansList];
            newCansList.splice(i, 1);
            setCansList(newCansList);
            itemList = [...newCansList, ...endsList];
            setCheckOutList(itemList);
            setCanPallets(canPallets - pallets);
        } else if (isEndType) {
            newEndsList = [...endsList];
            newEndsList.splice(i, 1);
            setEndsList(newEndsList);
            itemList = [...cansList, ...newEndsList];
            setCheckOutList(itemList);
            setEndPallets(endPallets - pallets);
        }
        setTotalPallets(totalPallets - pallets);
    };

    const handleRemoveAll = () => {
        setCheckOutList([]);
        setCansList([]);
        setEndsList([]);
        setCanPallets(0);
        setEndPallets(0);
    };

    const handleRemoveAllOfType = (type: string) => {
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);
        handleSearchText();

        if (isCanType) {
            setCheckOutList(
                [...checkOutList].filter((item) => isProductEndType(item.product.type))
            );
            setCansList([]);
            setCanPallets(0);
        } else if (isEndType) {
            setCheckOutList(
                [...checkOutList].filter((item) => isProductCanType(item.product.type))
            );
            setEndsList([]);
            setEndPallets(0);
        }
    };

    const handlePalletRemove = (product: ProductWithPallets, numberOfPallets: number) => {
        handleSearchText();
        let pristinePallets;
        let currentProductPallets = {
            ...product,
            availablePallets: product.availablePallets,
            availableItemsPerPallet: product.availableItemsPerPallet,
            orderedPallets: product.orderedPallets
        };

        //Grab the pristine pallet value for this product to reset
        products?.map((pristineProduct) => {
            if (pristineProduct.productSku === currentProductPallets.productSku) {
                pristinePallets = pristineProduct.availablePallets;
            }
            return null;
        });

        let itemIndex = productsWithPallets!.findIndex(
            (product) => product.productSku === currentProductPallets.productSku
        );

        if (itemIndex !== -1) {
            const pallets = pristinePallets;
            const palletsOrdered = 0;

            if (pallets) {
                const restoredAvailableItems = product.quantityPerPallet!;

                setPalletsForProduct(
                    product,
                    pristinePallets,
                    restoredAvailableItems,
                    palletsOrdered
                );
            }
        }
    };

    const handlePalletRemoveAll = (productsArr: ProductWithPallets[]) => {
        handleSearchText();
        let updatedProductsWithPallets = [...productsWithPallets];
        let pristinePallets;

        productsArr.map((product) => {
            //Get pristine pallets for each product
            products?.map((pristineProduct) => {
                if (pristineProduct.productSku === product.productSku) {
                    pristinePallets = pristineProduct.availablePallets;
                }
                return null;
            });

            //Reset productsWithPallets with pristine pallets
            productsWithPallets.map((item, index) => {
                if (product.productSku === item.productSku) {
                    updatedProductsWithPallets.splice(index, 1, {
                        ...product,
                        availablePallets: pristinePallets,
                        availableItemsPerPallet: item.quantityPerPallet!,
                        orderedPallets: 0,
                        inputPallets: 0,
                        inputEaches: 0
                    });
                }
                return null;
            });
            dispatch(shippingDashboardUpdatePallets(updatedProductsWithPallets));
            return null;
        });
    };

    const setPalletsForProduct = (
        product: ProductWithPallets,
        newAvailablePallets: number,
        newAvailableItems: number,
        newOrderedPallets: number
    ) => {
        let updatedProductWithPallets = [...productsWithPallets];

        productsWithPallets?.map((item, index) => {
            if (product.productSku === item.productSku) {
                updatedProductWithPallets.splice(index, 1, {
                    ...product,
                    availablePallets: newAvailablePallets,
                    availableItemsPerPallet: newAvailableItems,
                    inputPallets: 0,
                    inputEaches: 0,
                    orderedPallets: newOrderedPallets
                });
            }
            return null;
        });

        dispatch(shippingDashboardUpdatePallets(updatedProductWithPallets));
    };

    const handleViewFilterClick = (viewType: string[]) => {
        setCurrentViewTypes(viewType);
    };

    const handleRangeFilterClick = (rangeTypes: string[]) => {
        setCurrentRangeTypes(rangeTypes);
    };

    //Push input values to state on blur
    const handleInputBlur = (product: ProductWithPallets, inputQuantity: number) => {
        handleSearchText();
        let updatedProductInputPallets = [...productsWithPallets];

        productsWithPallets?.map((item, index) => {
            if (product.productSku === item.productSku) {
                const [inputPallets, inputEaches] = getProductCellQuantities(
                    inputQuantity,
                    product.quantityPerPallet!,
                    quantityUnit
                );
                updatedProductInputPallets.splice(index, 1, {
                    ...product,
                    inputPallets: inputPallets,
                    inputEaches: inputEaches,
                    orderedPallets: item.orderedPallets
                });
            }
            return null;
        });

        dispatch(shippingDashboardUpdatePallets(updatedProductInputPallets));
    };

    const handleSearchText = () => {
        const gridElementValue = getStringInputValue('.MuiInputBase-input');
        storage.setTextSearch(gridElementValue);
    };

    //Remove everything from checkout list on date update
    const handleDateUpdate = () => {
        if (!fromConfig) {
            dispatch(updateProductToBeShipped([]));
            dispatch(loadShippingDashboard());
            handleRemoveAll();
        }
    };

    const buildProductList = (productList: CheckOutItem[]) => {
        return productList.map((item) => {
            let numOfPalletsOrdered = item.pallets;
            let newPreviousDaysPalletTotals = item.product.previousPalletTotals?.map((total) => {
                return total < numOfPalletsOrdered ? total : numOfPalletsOrdered;
            });
            return {
                ...item.product,
                previousPalletTotals: newPreviousDaysPalletTotals,
                calculatedTotalsForPrevNDays: newPreviousDaysPalletTotals
            };
        });
    };

    const handleSubmit = () => {
        const cans = buildProductList(cansList);
        const ends = buildProductList(endsList);
        const productsArray = ends.concat(cans);
        dispatch(updateProductToBeShipped(productsArray as ProductToShip[]));
    };

    //Handles disabling navigation to ShipItConfig
    useEffect(() => {
        if (checkOutList.length > 0 && !hasCreditHold) {
            setNavToConfigLocked(false);
        } else if (checkOutList.length === 0 || hasCreditHold) {
            setNavToConfigLocked(true);
        }
    }, [checkOutList, hasCreditHold]);

    // Clears the search input on page load
    useEffect(() => {
        if (!fromConfig && !storage.name) {
            storage.setGridName(shipItGridName);
            storage.setTextSearch('');
        }
    }, [fromConfig]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(shippingDashboardUpdateView(currentViewTypes, SHIPPING_DASHBOARD_UPDATE_VIEW));
        }

        if (JSON.stringify(currentRangeTypes) !== JSON.stringify(range)) {
            dispatch(
                shippingDashboardUpdateRange(currentRangeTypes, SHIPPING_DASHBOARD_UPDATE_RANGE)
            );
        }
    }, [currentViewTypes, currentRangeTypes]);

    useEffect(() => {
        if (selectedAccountId !== shipToIdCache && !productsToBeShipped.length) {
            setShipToIdCache(selectedAccountId);
            dispatch(loadShippingDashboard());
        }

        // Clears the search input on Ship to change
        if (storage.getTextSearch() !== '') {
            storage.setTextSearch('');
        }
    }, [selectedAccountId]);

    //Remove all from checkout if ship to changes
    useEffect(() => {
        if (selectedAccountId !== shipToIdCache && checkOutList.length) {
            dispatch(resetShipItProductState());
            setShipToIdCache(selectedAccountId);
            handleRemoveAll();
            dispatch(loadShippingDashboard());
        }
    }, [checkOutList.length, dispatch, selectedAccountId, shipToIdCache]);

    useEffect(() => {
        if (!fromConfig) {
            dispatch(resetShipItState());
            dispatch(loadShippingDashboard());
            handleRemoveAll();
        }
        // Runs only once
    }, []);

    // Check to see if there's a credit hold on the selected Ship To
    useEffect(() => {
        if (selectedAccountId !== shipToIdCache && accounts) {
            const selectedShipTo = accounts.find(
                (account) => account.accountId === selectedAccountId
            );

            // account has credit hold if it's a Credit account and Credit Hold is C1
            if (
                !!selectedShipTo &&
                selectedShipTo.paymentTerms === PaymentTerms.Credit &&
                selectedShipTo.creditHold === CreditHold.C1
            ) {
                // display a different message for Co-packer accounts
                setIsCopacker(copackerAccounts.includes(selectedShipTo.accountId));
                setHasCreditHold(true);
            } else {
                setHasCreditHold(false);
            }
            if (selectedShipTo && selectedShipTo.deliveryLeadTimeDays) {
                setLeadTime(selectedShipTo.deliveryLeadTimeDays);
            }
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (permissions) {
            // get copacker account ID's if there are none set variable to an empty array
            setCopackerAccounts(getPersonaAccounts(permissions, Persona.CoPacker));
        }
    }, [permissions]);

    useEffect(() => {
        const emptyItemLists = !cansList.length && !endsList.length;

        if (productsToBeShipped.length && emptyItemLists && reloaded) {
            setReloaded(false);
            let tempProducts = productsToBeShipped;
            let tempCheckoutList: CheckOutItem[] = [];
            let totalCanPallets = 0;
            let totalEndPallets = 0;
            tempProducts.map((product) => {
                const isEndType = isProductEndType(product.type);
                const isCanType = isProductCanType(product.type);

                if (product.previousPalletTotals) {
                    let tempCheckoutItem = {
                        product: product,
                        pallets:
                            product.previousPalletTotals[product.previousPalletTotals.length - 1]
                    } as CheckOutItem;
                    tempCheckoutList.push(tempCheckoutItem);
                    if (isCanType) {
                        totalCanPallets += tempCheckoutItem.pallets;
                    }
                    if (isEndType) {
                        totalEndPallets += tempCheckoutItem.pallets;
                    }
                }
                return null;
            });

            setCheckOutList(tempCheckoutList);
            setCansList(
                [...tempCheckoutList].filter(
                    (item) => item.product.type === 'CAN' || item.product.type === 'BOTTLE'
                )
            );
            setEndsList(
                [...tempCheckoutList].filter(
                    (item) => item.product.type === 'END' || item.product.type === 'CAP'
                )
            );
            setCanPallets(totalCanPallets);
            setEndPallets(totalEndPallets);
        }
    }, [productsToBeShipped, checkOutList, cansList.length, endsList.length, reloaded]);

    useTranslation();

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                {canSaveDraft && (
                    <Button
                        type="submit"
                        variant="outlined"
                        data-testid="save-btn"
                        onClick={onSaveDraft}
                        className={classes.actionBtn}
                        disabled={draft}
                    >
                        <Trans i18nKey="saveForLater">Save For Later</Trans>
                    </Button>
                )}
            </Grid>
            {someCurrentlyActiveDocumentIsUnapproved ? (
                <Grid container item xs={7}>
                    <NotificationBanner
                        notificationMessage={t(
                            'pricingWarningText',
                            `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`
                        )}
                        notificationType={'WARNING'}
                    />
                </Grid>
            ) : null}
            <Grid container item xs={3} justify="flex-end" className={classes.btnWrapper}>
                <Link
                    to={dashboardLink}
                    underline="none"
                    component={RouterLink}
                    data-testid="back-link"
                >
                    <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        data-testid="back-btn"
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Link>
                <Link
                    to={shipItConfigLink}
                    underline="none"
                    component={RouterLink}
                    data-testid="shipit-config-link"
                    className={navToConfigLocked ? classes.disabledLink : undefined}
                >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="submit-btn"
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                        disabled={navToConfigLocked || someCurrentlyActiveDocumentIsUnapproved}
                    >
                        <Trans i18nKey="buildShipments">build your shipments</Trans>
                    </Button>
                </Link>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('createDeliveryOrder', 'CREATE DELIVERY ORDER'),
                thinBanner: true,
                displayDropdown: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
            loading={loading || pricingLoading}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.dashboard}
                data-testid="ship-it-page"
            >
                <QuantityUnitSelectorProvider state={{ quantityUnit: 'pallets' }}>
                    <Grid container item md={9} className={classes.main}>
                        <Grid item className={classes.fullWidth}>
                            <WarningAlert
                                warningMessage={
                                    isCopacker
                                        ? t('copackerCreditHoldWarning', 'Co-packer Credit Hold')
                                        : t('creditHoldWarning', 'Credit Hold Warning')
                                }
                                showWarning={hasCreditHold}
                                className={classes.creditHoldWarningMessage}
                            />
                        </Grid>
                        <Grid container item xs={12} justify="flex-end">
                            <Link
                                to={bulkUploadLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="bulk-upload-link"
                            >
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    data-testid="bulk-upload-btn"
                                    className={classes.bulkUploadBtn}
                                >
                                    <Trans i18nKey="bulkUpload">Bulk Upload</Trans>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item container className={classes.addProducts}>
                            {leadTime && (
                                <ShipItDeliveryOptions
                                    onDateUpdate={handleDateUpdate}
                                    itemsInCart={checkOutList.length}
                                    leadTime={leadTime}
                                />
                            )}
                        </Grid>
                        <ShipItProductsOptions />
                        <QuantityUnitSelector
                            type={'ShipIt'}
                            styles={classes.quantityUnitSelector}
                        />
                        <ShipItGrid
                            shipItItems={productsWithPallets as ProductWithPallets[]}
                            onAddItem={handleAddItem}
                            onPalletAdd={handlePalletAdd}
                            onViewFiltersClick={handleViewFilterClick}
                            onRangeFiltersClick={handleRangeFilterClick}
                            onInputBlur={handleInputBlur}
                            hasCreditHold={hasCreditHold}
                            storage={storage}
                        />
                    </Grid>
                </QuantityUnitSelectorProvider>
                <Grid container item md={3} className={classes.side}>
                    <ShipItCheckout
                        products={checkOutList}
                        canPallets={canPallets}
                        endPallets={endPallets}
                        onRemoveAllOfType={(type) => handleRemoveAllOfType(type)}
                        onRemoveOrder={handleRemoveItem}
                        onPalletRemove={handlePalletRemove}
                        onPalletRemoveAll={handlePalletRemoveAll}
                    />
                </Grid>
            </Grid>
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
            />
        </ProcessingPageTemplate>
    );
}
