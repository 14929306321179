import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import { Account } from '../../../store/reducers/customer-context';
import { white, medium, small, black } from '../../../themes/globalConstants';

interface Props {
    account?: Account | null;
    readOnly?: boolean;
    reverse?: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    company: {
        paddingTop: '0.5em',
        fontSize: medium,
        lineHeight: '1.2em'
    },
    account: {
        fontSize: small
    },
    address: {
        fontSize: small
    },
    readOnlyAccount: {
        color: white,
        padding: 6
    },
    blackFont: {
        color: black
    }
}));

export default function AccountSelectionNameAddressDisplay({
    account = null,
    readOnly = false,
    reverse = false
}: Props) {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.main, {
                [classes.readOnlyAccount]: readOnly,
                [classes.blackFont]: reverse
            })}
        >
            <Typography variant="body1" className={classes.account} data-testid="ship-to-id">
                {`#${account?.accountId}`}
            </Typography>
            <Typography variant="subtitle2" className={classes.company}>
                {account?.name}
            </Typography>
            <Typography variant="body1" className={classes.address}>
                {account?.address.address1}
                {', '}
                {account?.address.city}
                {', '}
                {account?.address.stateProvince} {account?.address.postalCode}
                {', '}
                {account?.address.country}
            </Typography>
        </div>
    );
}
