import React, { useEffect, useState } from 'react';
import { Collapse, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import FormSection from '../../../reusable/molecules/FormSection';
import {
    boldWeight,
    brightBlue,
    brightGrey,
    medium,
    paleGrey,
    large,
    xl,
    red,
    black
} from '../../../../themes/globalConstants';
import { Link as RouterLink } from 'react-router-dom';
import FileUpload from '../../../reusable/molecules/FileUpload';
import { useDispatch } from 'react-redux';
import { uploadDeliveryFile } from '../../../../store/root-actions';
import { BulkUploadDeliveryState, LineErrorCode } from '../../../../store/reducers/bulk-upload';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Alert } from '@material-ui/lab';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { stringFormatterReplace } from '../../../../utility/helpers/formatters';
import { formatDate } from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

interface Props {
    isValidFile: boolean;
    setIsValidFile: (boolean) => void;
    isEdit: boolean;
}

const useStyles = makeStyles((theme) => ({
    sectionMargin: {
        margin: '2em 0'
    },
    label: {
        color: brightGrey,
        fontSize: medium,
        padding: '0.5em'
    },
    description: {
        fontSize: medium,
        color: brightGrey
    },
    linksWrapper: {
        fontWeight: boldWeight,
        color: black,
        marginTop: '.5em',
        marginLeft: '.5em'
    },
    error: {
        color: red
    },
    lineErrorGrid: {
        color: black,
        paddingBottom: '0.25em'
    },
    lineErrorHelper: {
        color: red,
        paddingBottom: '0.75em'
    },
    lineErrorMessage: {
        color: red,
        fontSize: large,
        fontWeight: boldWeight,
        letterSpacing: 0
    },
    warningIconRed: {
        '& .MuiAlert-icon': {},
        minWidth: '95%'
    },
    collapse: {
        width: '100%'
    },
    collapseText: {
        color: brightBlue,
        fontSize: medium,
        fontWeight: boldWeight
    },
    arrow: {
        fill: brightBlue
    },
    rotateArrow: {
        transform: 'rotate(180deg)'
    }
}));

const uploadStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: paleGrey,
        height: '11.5em',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    divContainer: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    invalidText: {
        color: 'red'
    },
    icon: {
        height: '1.875em',
        width: '1.4em',
        fill: theme.palette.secondary.main
    },
    text: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px'
    }
}));

const UploadDeliveryOrder = ({ isValidFile, setIsValidFile, isEdit }: Props) => {
    const classes = useStyles();
    const [file, setFile] = useState<File | undefined>();
    const [hideForm, setHideForm] = useState<boolean>(isEdit);
    const [showUploadError, setShowUploadError] = useState<boolean>(false);
    const acceptedFileTypes =
        '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values';
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const bulkUploadDelivery = useTypedSelector<BulkUploadDeliveryState>(
        (state) => state.bulkUploadDelivery
    );
    const [hasLineErrors, setHasLineErrors] = useState<boolean>(false);
    const { lineErrors } = useTypedSelector<BulkUploadDeliveryState>(
        (state) => state.bulkUploadDelivery
    );
    const [lineErrorElements, setLineErrorElements] = useState<JSX.Element[]>([]);
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const storeFiles = (fileData) => {
        if (fileData === undefined) {
            setFile(fileData);
            setIsValidFile(false);
            setShowUploadError(false);
            dispatch(uploadDeliveryFile(fileData));
        } else if (fileData.length > 0) {
            setFile(fileData);
            setIsValidFile(true);
            setShowUploadError(false);
            dispatch(uploadDeliveryFile(fileData));
        } else {
            setShowUploadError(true);
        }
        // reset line errors on file upload/removal
        setHasLineErrors(false);
        setLineErrorElements([]);
    };

    useEffect(() => {
        if (lineErrors && lineErrors.length > 0) {
            // error handling
            setHasLineErrors(true);
            setLineErrorElements(
                lineErrors.map((error) => {
                    let errorText;
                    switch (error.errorCode) {
                        case LineErrorCode.E110001:
                            errorText = stringFormatterReplace(
                                t(error.errorCode, error.errorMessage),
                                `${error.maxShipmentPallets}`
                            );
                            break;
                        case LineErrorCode.E110002:
                            errorText = stringFormatterReplace(
                                t(error.errorCode, error.errorMessage),
                                `${error.leadTimeDays ? error.leadTimeDays : 14}`
                            );
                            break;
                        case LineErrorCode.E210005:
                            errorText = stringFormatterReplace(
                                t(error.errorCode, error.errorMessage),
                                `${error.shipToId}`,
                                `${error.deliveryDateTime}`,
                                `${error.maxShipmentPallets}`
                            );
                            break;
                        case LineErrorCode.E200006:
                            errorText = stringFormatterReplace(
                                t(error.errorCode, error.errorMessage),
                                `${error.attemptedValue?.runningLoadSum}`,
                                `${error.productId}`,
                                `${error.shipToId}`,
                                formatDate(
                                    `${moment(error.deliveryDateTime).startOf('week').add(1, 'd')}`
                                ),
                                `${error.attemptedValue?.currentBalance}`,
                                `${
                                    error.attemptedValue?.runningLoadSum -
                                    error.attemptedValue?.currentBalance
                                }`
                            );
                            break;
                        case LineErrorCode.E200007:
                            errorText = stringFormatterReplace(
                                t(error.errorCode, error.errorMessage),
                                `${error.shipToId}`,
                                `${moment(error.deliveryDateTime).format('M/D/YYYY')}`,
                                `${moment(error.deliveryDateTime).format('h:mm a')}`
                            );
                            break;
                        default:
                            errorText = (
                                <>
                                    <Trans i18nKey={error.errorCode}>{error.errorMessage}</Trans>
                                </>
                            );
                    }
                    return (
                        <Grid container item xs={12} className={classes.lineErrorGrid}>
                            <Alert severity="error" className={classes.warningIconRed}>
                                <Typography>
                                    {error.lineNumber && (
                                        <>
                                            <Trans i18nKey="line">Line</Trans> {error.lineNumber}
                                            {' - '}
                                        </>
                                    )}
                                    {errorText}
                                </Typography>
                            </Alert>
                        </Grid>
                    );
                })
            );
        }
    }, [classes, t, lineErrors, regionCulture]);

    useEffect(() => {
        setHideForm(!isEdit);
    }, [isEdit]);

    // Reset the file once the user enters the page
    useEffect(() => {
        setFile(undefined);
        dispatch(uploadDeliveryFile());
    }, []);

    return (
        <FormSection
            testId={'upload-section'}
            sectionHeader={<Trans i18nKey="uploadShipItOrders">Upload Your Ship It Order(s)</Trans>}
        >
            {isEdit && (
                <Grid container item xs={12}>
                    <Typography className={classes.description}>
                        <Trans i18nKey="mustSaveMapping">
                            You must save your mapping above in order to upload a file.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            <Collapse in={hideForm} className={classes.collapse}>
                <Grid item xs={12}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="acceptedBulkFiles">
                            Please upload your .csv file, making sure your match with your mapping.
                        </Trans>
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography className={classes.linksWrapper}>
                        <Trans i18nKey="needMoreInfo">Need more information?</Trans>
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography className={classes.linksWrapper}>
                        <Link
                            to="/assigned-ship-tos/ship-it"
                            target="_blank"
                            component={RouterLink}
                            underline="none"
                        >
                            <Trans i18nKey="viewAssignedShipToLocations">
                                View Your Assigned Ship To Locations
                            </Trans>
                        </Link>{' '}
                        <Trans i18nKey="or">or</Trans>{' '}
                        <Link
                            to="/ship-it-bulk-data-validation"
                            target="_blank"
                            component={RouterLink}
                            underline="none"
                        >
                            <Trans i18nKey="viewDataValidationRules">
                                View Data Validation Rules
                            </Trans>
                        </Link>{' '}
                        <Trans i18nKey="or">or</Trans>{' '}
                        <Link
                            to={`/product-information?from=ship-it&selectedShipTo=${selectedAccountId}`}
                            target="_blank"
                            component={RouterLink}
                            underline="none"
                        >
                            <Trans i18nKey="viewAtmProductForecast">
                                View My Product Information
                            </Trans>
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.sectionMargin}>
                    <FileUpload
                        styles={uploadStyles}
                        fileTypes={acceptedFileTypes}
                        name="deliveryOrderUpload"
                        testId="deliveryOrder-upload"
                        enableDelete={true}
                        onUploadAttempt={storeFiles}
                        fileReset={false}
                        invalidMessage={t(
                            'invalidBulkFile',
                            'This file type is invalid. Please attach a .csv file.'
                        )}
                        currentFile={file}
                    />
                </Grid>
                {showUploadError && (
                    <Grid item xs={12} className={classes.error}>
                        <Typography>
                            <Trans i18nKey="invalidCsv">*Please upload a valid csv file</Trans>
                        </Typography>
                    </Grid>
                )}
                {hasLineErrors && (
                    <Grid>
                        <Grid container item xs={12}>
                            <Typography className={classes.lineErrorMessage}>
                                <Trans i18nKey="fileErrors">File Errors</Trans>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} className={classes.lineErrorHelper}>
                            <Typography>
                                <Trans i18nKey="lineErrorHelper">
                                    Please correct the following errors and re-upload your file.
                                </Trans>
                            </Typography>
                        </Grid>
                        {lineErrorElements}
                    </Grid>
                )}
            </Collapse>
        </FormSection>
    );
};

export default UploadDeliveryOrder;
