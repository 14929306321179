import {
    CUSTOMER_ORDERING_DASHBOARD_ERROR,
    CUSTOMER_ORDERING_DASHBOARD_RESET_STATUS,
    CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE,
    CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED,
    CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED,
    CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED,
    CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING,
    CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING,
    CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING
} from '../actions/action-types';

export interface orderManagement {
    openOrders: number;
    pendingOrders: number;
    draftOrders: number;
    pastDuePayments: number;
    approachingPayments: number;
    productionBalanceItems: productionBalance[];
}

export interface productionBalance {
    week: number;
    productSkus: number;
    pallets: number;
}

export interface productPortFolio {
    activeItems: number;
    graphics: portFolioInfo;
    liquidTests: portFolioInfo;
}

export interface ShipmentDeliveries {
    arrivingThisWeek: number;
    draftShipments: number;
    futureShipments: {
        weekDate: string;
        pallets: number;
    }[];
}

interface CachedDashboard {
    shipToId: string;
    dateLimit: Date;
    data: Dashboard;
}

export interface portFolioInfo {
    inProgress: number;
    drafts?: number;
    recentlyCompleted: number;
}

export interface Dashboard {
    orderManagementDashboard?: orderManagement;
    productPortfolioDashboard?: productPortFolio;
    shipmentDeliveriesDashboard?: ShipmentDeliveries;
}

export interface CustomerOrderingDashboardState {
    status: 'idle' | 'loading' | 'error' | 'success';
    orderManagementStatus: 'idle' | 'loading' | 'error' | 'success';
    productPortfolioStatus: 'idle' | 'loading' | 'error' | 'success';
    shipmentsDeliveriesStatus: 'idle' | 'loading' | 'error' | 'success';
    inventoryStatus: 'idle' | 'loading' | 'error' | 'success';
    paymentsManagementStatus: 'idle' | 'loading' | 'error' | 'success';
    dashboardData?: Dashboard;
    cachedDashboard: CachedDashboard[];
}

const initialState: CustomerOrderingDashboardState = {
    status: 'idle',
    orderManagementStatus: 'idle',
    productPortfolioStatus: 'idle',
    shipmentsDeliveriesStatus: 'idle',
    inventoryStatus: 'idle',
    paymentsManagementStatus: 'idle',
    dashboardData: undefined,
    cachedDashboard: []
};

const customerOrderingDashboard = (
    state: CustomerOrderingDashboardState = initialState,
    action: { type: string; [x: string]: any }
): CustomerOrderingDashboardState => {
    switch (action.type) {
        case CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADING:
            return {
                ...state,
                orderManagementStatus: 'loading'
            };
        case CUSTOMER_ORDER_MANAGEMENT_DASHBOARD_LOADED:
            return {
                ...state,
                orderManagementStatus: 'success',
                dashboardData: {
                    ...state.dashboardData,
                    orderManagementDashboard: action.dashboardData.orderManagementDashboard
                }
            };
        case CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADING:
            return {
                ...state,
                productPortfolioStatus: 'loading'
            };
        case CUSTOMER_PRODUCT_PORTFOLIO_DASHBOARD_LOADED:
            return {
                ...state,
                productPortfolioStatus: 'success',
                dashboardData: {
                    ...state.dashboardData,
                    productPortfolioDashboard: action.dashboardData.productPortfolioDashboard
                }
            };
        case CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADING:
            return {
                ...state,
                shipmentsDeliveriesStatus: 'loading'
            };
        case CUSTOMER_SHIPMENTS_DELIVERIES_DASHBOARD_LOADED:
            return {
                ...state,
                shipmentsDeliveriesStatus: 'success',
                dashboardData: {
                    ...state.dashboardData,
                    shipmentDeliveriesDashboard: action.dashboardData.shipmentDeliveriesDashboard
                }
            };
        case CUSTOMER_ORDERING_DASHBOARD_ERROR:
            return {
                ...state,
                orderManagementStatus: 'error',
                productPortfolioStatus: 'error',
                shipmentsDeliveriesStatus: 'error'
            };
        case CUSTOMER_ORDERING_DASHBOARD_RESET_STATUS:
            return {
                ...state,
                orderManagementStatus: 'idle',
                productPortfolioStatus: 'idle',
                shipmentsDeliveriesStatus: 'idle'
            };
        case CUSTOMER_ORDERING_DASHBOARD_CACHE_SAVE:
            let newCachedDashboard = state.cachedDashboard;

            if (action.shipToId && action.dashboard) {
                const index = newCachedDashboard.findIndex((cd) => cd.shipToId === action.shipToId);
                if (index >= 0) {
                    newCachedDashboard[index].data = action.dashboard;
                    newCachedDashboard[index].dateLimit = action.dateLimit;
                } else {
                    newCachedDashboard.push({
                        shipToId: action.shipToId,
                        data: action.dashboard,
                        dateLimit: action.dateLimit
                    });
                }
            }
            return {
                ...state,
                cachedDashboard: newCachedDashboard
            };
        default:
            return state;
    }
};

export default customerOrderingDashboard;
