import { dateFormats, regions } from '../../static-data/dropdown-lists';
import {
    USER_CONFIGURATION_LOADED,
    USER_CONFIGURATION_LOADING,
    USER_CONFIGURATION_UPDATE,
    USER_CONFIGURATION_ERROR,
    UPDATE_DATEFORMAT
} from '../actions/action-types';
import { loadState, saveState } from '../../utility/helpers/localStorage';

export type DashboardElementId = 'makeit' | 'shipit';

export interface DashboardElement {
    name: DashboardElementId;
    elementCode?: string;
    rowPosition?: number;
    columnPosition: number;
}

export interface UserConfigurationState {
    dashboardPositions: Array<DashboardElement>;
    dateFormatCode: string;
    regionCode: string;
    cultureCode: string;
    loading?: boolean;
    loaded?: boolean;
    error?: boolean;
}

export enum DateFormat {
    mmddyy = 'MM/DD/YY',
    ddmmyy = 'DD/MM/YY'
}

export const userConfigurationInitialState: UserConfigurationState = {
    dashboardPositions: [
        {
            name: 'makeit',
            columnPosition: 0
        },
        {
            name: 'shipit',
            columnPosition: 1
        }
    ],
    dateFormatCode: dateFormats[0].value,
    regionCode: regions[0].value,
    cultureCode: regions[0].languages[0].value,
    loading: false,
    loaded: false,
    error: undefined
};

const persistState = (
    state: UserConfigurationState | undefined,
    change: any
): UserConfigurationState => {
    if (!state) {
        state = loadState('userConfig') as UserConfigurationState;
    }

    let newState: UserConfigurationState = {
        ...state,
        dashboardPositions: change.dashboardPositions ?? state.dashboardPositions,
        dateFormatCode: change.dateFormatCode ?? state.dateFormatCode,
        regionCode: change.regionCode ?? state.regionCode,
        cultureCode: change.cultureCode ?? state.cultureCode
    };

    saveState('userConfig', newState);

    return newState;
};

const userConfiguration = (
    state = userConfigurationInitialState,
    action: any
): UserConfigurationState => {
    switch (action.type) {
        case USER_CONFIGURATION_LOADING:
            return { ...state, loading: true, loaded: false, error: undefined };
        case USER_CONFIGURATION_UPDATE:
            return {
                ...persistState(state, action),
                loading: false,
                loaded: true,
                error: undefined
            };
        case USER_CONFIGURATION_LOADED:
            return {
                ...persistState(state, action),
                loading: false,
                loaded: true,
                error: undefined
            };
        case UPDATE_DATEFORMAT: {
            const newDateFormatCode = action.value;

            return {
                ...persistState(state, newDateFormatCode),
                loading: false,
                loaded: true,
                error: undefined
            };
        }
        case USER_CONFIGURATION_ERROR:
            return { ...state, loading: false, loaded: true, error: action.error };
        default:
            return state;
    }
};

export default userConfiguration;
