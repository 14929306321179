import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import {
    makeStyles,
    Typography,
    Grid,
    Paper,
    List,
    TextField,
    Toolbar,
    AppBar,
    RadioGroup
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import {
    Account,
    AddressType,
    DeliverToAccount,
    SelectableAccount
} from '../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import {
    selectCustomerAccountId,
    getUserCustomerAccounts,
    setLastBillToId,
    setLastShipToId,
    selectChildrenAccountId,
    updateOrderingV2Flag
} from '../../../store/actions/customer-context';
import { Activity, Persona } from '../../../utility/auth/useSecurity';
import { white, large, medium, small, ballBlue, red } from '../../../themes/globalConstants';
import Link from '../atoms/Link';
import Modal from './Modal';
import Button from '../atoms/Button';
import AccountFilter from './AccountFilter';
import AccountListItem from './AccountListItem';
import { AuthState } from '../../../store/reducers/auth';
import NewAccountSelectionNameAddressDisplay from './NewAccountSelectionNameAddressDisplay';
import { loadState } from '../../../utility/helpers/sessionStorage';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import { getPersonaAccounts } from '../../../utility/helpers/user-helpers';
import DeliverToAccounts from './DeliverToAccounts';
import OtherShipToAccounts from './OtherShipToAccounts';
import { FlagTypes, useFeatureFlag } from '../../../utility/helpers/feature-flag';

interface Props {
    filter?: Activity;
    disableSelect?: boolean;
    selectionType?: 'soldTo' | 'shipTo' | 'account';
    hasChildrenSelection?: boolean;
    isChildrenSelection?: boolean;
    isRequired?: boolean;
    hasClearSelection?: boolean;
    reverseBackground?: boolean;
    alternateLabel?: string;
}

const useStyles = makeStyles((theme) => ({
    singleSideText: {
        letterSpacing: 0.2,
        fontSize: medium,
        lineHeight: '1.2em'
    },
    multiplesSideText: {
        marginTop: '0.4em',
        letterSpacing: 0.2,
        fontSize: medium,
        lineHeight: '1.2em'
    },
    main: {
        backgroundColor: white,
        minWidth: '24em',
        minHeight: '4.6em',
        maxHeight: '6em',
        borderRadius: 2,
        cursor: 'pointer',
        padding: '0.25em 0.5em'
    },
    mainReadOnly: {
        backgroundColor: ballBlue
    },
    mainReadOnlyReverse: {
        backgroundColor: white
    },
    mainIsChildren: {
        marginTop: '0.25em'
    },
    paperSizer: {
        minWidth: '17em'
    },
    paperContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch'
    },
    paperLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    paperRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    required: {
        border: `2px solid ${red}`
    },
    modalContainer: {
        maxWidth: '40em',
        height: '25em',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    address: {
        fontSize: small
    },
    links: {
        fontSize: large,
        fontWeight: 'bold'
    },
    pad: {
        paddingTop: '2px'
    },
    indicator: {
        width: '1em',
        height: '1em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.25em 0 0.5em 0.5em',
        padding: '0.25em 0.25em',
        backgroundColor: ballBlue,
        color: white
    },
    accountContainer: {
        marginTop: '2em',
        width: '35em',
        margin: 'auto'
    },
    accountText: {
        marginLeft: '2em',
        fontSize: medium
    },
    searchField: {
        marginBottom: '0.5em'
    },
    list: {
        marginTop: '1.5em',
        minHeight: '20em'
    },
    btnContainer: {
        width: '30em',
        margin: 'auto',
        height: '3em'
    },
    btn: {
        width: '10em'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        width: '100%'
    },
    toolBar: {
        backgroundColor: white
    },
    textPadding: {
        padding: 6
    }
}));

const soldToAddressTypes = [AddressType.CB, AddressType.CX, AddressType.CP, AddressType.CG];
const shipToAddressTypes = [AddressType.CS, AddressType.CX];

const allAddressTypes = [...new Set([...soldToAddressTypes, ...shipToAddressTypes])];

export default function NewAccountSelection({
    disableSelect = false,
    selectionType = 'account',
    hasChildrenSelection = false,
    isChildrenSelection = false,
    isRequired = false,
    hasClearSelection = false,
    reverseBackground = false,
    alternateLabel
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalOrderingV2Flag = useFeatureFlag().find(
        (flag) => flag.name === FlagTypes.OrderingV2
    )?.isActive;

    const {
        accounts,
        selectedAccountId,
        selectedChildrenAccountId,
        lastShipToIdSelected,
        lastBillToIdSelected
    } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { requiresAcknowledgement } = useTypedSelector<ContractSummaryState>(
        (state) => state.contractSummary
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [radioValue, setRadioValue] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);
    const [sortedAccounts, setSortedAccounts] = useState<Account[]>([]);
    const [deliverToAccounts, setDeliverToAccounts] = useState<DeliverToAccount[]>([]);
    const [otherAccounts, setOtherAccounts] = useState<Account[]>([]);

    const emptySelection = () => {
        let selectionTypeDescription;
        switch (selectionType) {
            case 'shipTo':
                selectionTypeDescription = 'Ship To';
                break;
            case 'soldTo':
                selectionTypeDescription = 'Sold To';
                break;
            default:
                selectionTypeDescription = 'Account';
        }

        return (
            <Trans i18nKey={`empty${selectionType}Selection`}>
                Click to Select Individual {selectionTypeDescription}
            </Trans>
        );
    };

    const translationValues = useMemo(() => {
        if (alternateLabel && disableSelect) {
            return {
                title: alternateLabel
            };
        }
        if (selectionType === 'shipTo') {
            return {
                title: <Trans i18nKey="shipTo">ShipTo</Trans>,
                selection: <Trans i18nKey="selectShipTo">Select Ship To</Trans>,
                modalTitle: t('selectShipTo', 'Select Ship To'),
                modalCounter: <Trans i18nKey="shiptos">Ship-tos</Trans>,
                modalSearch: t('searchForShipTo', 'Search for Ship-to')
            };
        }
        if (selectionType === 'soldTo') {
            return {
                title: <Trans i18nKey="soldTo">Sold To</Trans>,
                selection: <Trans i18nKey="selectSoldTo">Select Sold To</Trans>,
                modalTitle: t('selectSoldTo', 'Select Sold To'),
                modalCounter: <Trans i18nKey="Sold-Tos">Sold-Tos</Trans>,
                modalSearch: t('searchForSoldTo', 'Search for Sold-to')
            };
        }
        return {
            title: <Trans i18nKey="account">Account</Trans>,
            selection: <Trans i18nKey="selectAccount">Select Account</Trans>,
            modalTitle: t('selectAccount', 'Select Account'),
            modalCounter: <Trans i18nKey="accounts">Accounts</Trans>,
            modalSearch: t('searchForAccount', 'Search for Account')
        };
    }, [selectionType, t, alternateLabel, disableSelect]);

    const copackerAccounts: string[] = useMemo(
        () =>
            getPersonaAccounts(auth.permissions ? auth.permissions : [], Persona.CoPacker).sort(
                (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
            ),
        [auth]
    );

    // This changes account selected and saves it to the needed stores
    const handleAccountChange = useCallback(
        (accountId: string) => {
            setSearchQuery('');
            setRadioValue(accountId);

            if (selectedAccount) {
                soldToAddressTypes.includes(selectedAccount.addressType) &&
                    dispatch(setLastBillToId(selectedAccount.accountId));
                shipToAddressTypes.includes(selectedAccount.addressType) &&
                    dispatch(setLastShipToId(selectedAccount.accountId));
            }
            if (!isChildrenSelection) {
                dispatch(selectCustomerAccountId(accountId));
                dispatch(selectChildrenAccountId(''));
            } else {
                dispatch(selectChildrenAccountId(accountId));
            }
            dispatch(updateOrderingV2Flag(globalOrderingV2Flag));
        },
        [dispatch, selectedAccount, isChildrenSelection, globalOrderingV2Flag]
    );

    // This changes the value based on radio selection after hitting the Select button in the Modal
    const handleSelect = useCallback(() => {
        if (accounts && accounts.length && radioValue) {
            const accountSelected = accounts.find((account) => account.accountId === radioValue);
            if (accountSelected) {
                handleAccountChange(accountSelected.accountId);
            }
        }
        setOpenModal(false);
    }, [accounts, handleAccountChange, radioValue]);

    // Loads the accounts when the user is authenticated
    useEffect(() => {
        if (auth.permissions && !accounts?.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [dispatch, accounts, auth.permissions]);

    // Load the list of Accounts to show in the modal list
    useEffect(() => {
        if (accounts && accounts.length) {
            const filteredAccounts = accounts.filter((account) => {
                if (selectionType === 'soldTo') {
                    return soldToAddressTypes.includes(account.addressType);
                }
                if (selectionType === 'shipTo') {
                    return shipToAddressTypes.includes(account.addressType);
                }
                return allAddressTypes.includes(account.addressType);
            });

            if (isChildrenSelection && selectedAccountId && selectionType) {
                const filteredChildrenAccounts = filteredAccounts.filter(
                    (account) => account.parentAccountId === selectedAccountId
                );

                const currentAccount = accounts.find(
                    (account) => account.accountId === selectedAccountId
                );

                if (currentAccount?.addressType === AddressType.CX) {
                    filteredChildrenAccounts.push(currentAccount);
                }

                setSortedAccounts(
                    filteredChildrenAccounts.sort((a, b) => a.name.localeCompare(b.name))
                );
            } else {
                setSortedAccounts(filteredAccounts.sort((a, b) => a.name.localeCompare(b.name)));
            }
        }
    }, [accounts, selectionType, selectedAccountId, isChildrenSelection]);

    // Find the account information to show in the Selection
    useEffect(() => {
        if (accounts && accounts.length) {
            let accountId;
            if (!isChildrenSelection) {
                accountId = selectedAccountId;
            } else if (isChildrenSelection && selectedChildrenAccountId) {
                accountId = selectedChildrenAccountId;
            }
            const foundAccount = accounts.find((account) => account.accountId === accountId);
            setRadioValue(foundAccount? foundAccount.accountId : '');
            setSelectedAccount(foundAccount);
        }
    }, [
        accounts,
        selectionType,
        selectedAccountId,
        selectedChildrenAccountId,
        isChildrenSelection
    ]);

    // If no account is selected, try to find one in the session storage based on the children props
    useEffect(() => {
        setReadOnly(false);
        if (!selectedAccountId && sortedAccounts && sortedAccounts.length) {
            const stateString = !isChildrenSelection
                ? 'selectedAccountId'
                : 'selectedChildrenAccountId';

            const lastStoredSelectionId = loadState(stateString) as string;
            const accountFromLastStoredSelection = sortedAccounts.find(
                (account) => account.accountId === lastStoredSelectionId
            );
            handleAccountChange(accountFromLastStoredSelection?.accountId || '');
        }

        if (
            disableSelect ||
            (!isChildrenSelection && sortedAccounts && sortedAccounts.length === 1)
        ) {
            setReadOnly(true);
        }
    }, [
        sortedAccounts,
        handleAccountChange,
        selectedAccountId,
        isChildrenSelection,
        disableSelect
    ]);

    // Change the Account to the lastSelected if the selection type is different than the actual selected account type
    useEffect(() => {
        if (sortedAccounts && sortedAccounts.length && selectedAccount) {
            switch (selectionType) {
                case 'shipTo':
                    if (
                        [AddressType.CB, AddressType.CP, AddressType.CG].includes(
                            selectedAccount.addressType
                        )
                    ) {
                        let newSelection: Account | undefined;
                        if (lastShipToIdSelected) {
                            newSelection = sortedAccounts.find(
                                (account) => account.accountId === lastShipToIdSelected
                            );
                        } else {
                            newSelection = sortedAccounts[0];
                        }
                        if (newSelection) {
                            handleAccountChange(newSelection.accountId);
                        }
                    }
                    break;
                case 'soldTo':
                    if (selectedAccount.addressType === AddressType.CS) {
                        let newSelection: Account | undefined;
                        if (lastBillToIdSelected) {
                            newSelection = sortedAccounts.find(
                                (account) => account.accountId === lastBillToIdSelected
                            );
                        } else {
                            newSelection = sortedAccounts[0];
                        }
                        if (newSelection) {
                            handleAccountChange(newSelection.accountId);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [
        sortedAccounts,
        selectionType,
        selectedAccount,
        handleAccountChange,
        lastShipToIdSelected,
        lastBillToIdSelected
    ]);

    // Logic to separate Copacker accounts from other accounts, only when ShipTo Selection
    useEffect(() => {
        if (selectionType === 'shipTo' && sortedAccounts) {
            setOtherAccounts(
                sortedAccounts.filter((account) => !copackerAccounts.includes(account.accountId))
            );
            const copackerShipTos = sortedAccounts.filter((account) =>
                copackerAccounts.includes(account.accountId)
            );
            const deliverToAccountIds = copackerShipTos
                .map((account) => account.deliverToAccountId!)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) =>
                    a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );
            setDeliverToAccounts(
                deliverToAccountIds.map((deliverToAccountId) => {
                    const copackers = copackerShipTos.filter(
                        (account) => account.deliverToAccountId === deliverToAccountId
                    );
                    return {
                        deliverToAccountId,
                        copackers: copackers as SelectableAccount[],
                        address: copackers[0].address
                    };
                })
            );
        }
    }, [selectionType, sortedAccounts, copackerAccounts]);

    useEffect(() => {
        if (disableSelect) {
            setReadOnly(true);
        }
    }, [disableSelect]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        handleCancelModal();
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const bubbleRadioChange = (event) => {
        setRadioValue(event.currentTarget.value);
    };

    const handleCancelModal = () => {
        setOpenModal(false);
        setSearchQuery('');
    };

    const handleClearSelection = () => {
        if (isChildrenSelection) {
            dispatch(selectChildrenAccountId(''));
        } else {
            dispatch(selectChildrenAccountId(''));
            dispatch(selectCustomerAccountId(''));
        }
        dispatch(updateOrderingV2Flag(globalOrderingV2Flag));
        setOpenModal(false);
    };

    return (
        <>
            <Grid item className={classes.textPadding}>
                <Typography
                    variant="subtitle2"
                    className={readOnly ? classes.singleSideText : classes.multiplesSideText}
                >
                    {translationValues.title}
                </Typography>
            </Grid>
            <Grid
                item
                className={clsx({
                    [classes.mainReadOnly]: readOnly,
                    [classes.mainReadOnlyReverse]: readOnly && reverseBackground,
                    [classes.mainIsChildren]: isChildrenSelection
                })}
            >
                {readOnly ? (
                    <NewAccountSelectionNameAddressDisplay
                        account={selectedAccount || null}
                        readOnly
                        reverse={reverseBackground}
                    />
                ) : (
                    <Paper
                        className={clsx(classes.main, {
                            [classes.required]: isRequired && !selectedAccount,
                            [classes.paperSizer]: isChildrenSelection
                        })}
                        onClick={handleOpenModal}
                    >
                        <Grid container className={classes.paperContainer}>
                            <div className={classes.paperLeft}>
                                {selectedAccount ? (
                                    <>
                                        <NewAccountSelectionNameAddressDisplay
                                            account={selectedAccount}
                                        />
                                        <Typography
                                            className={classes.links}
                                            data-testid="edit-account-links"
                                            color="primary"
                                        >
                                            {translationValues.selection}
                                        </Typography>
                                    </>
                                ) : hasChildrenSelection ? (
                                    <Typography
                                        className={classes.links}
                                        data-testid="edit-account-links"
                                        color="primary"
                                    >
                                        <Trans i18nKey="emptyParentSelection">
                                            Click to Select Individual Sold To
                                            <br />
                                            or
                                            <br />
                                            Choose Ship To Below
                                        </Trans>
                                    </Typography>
                                ) : (
                                    <Typography
                                        className={classes.links}
                                        data-testid="edit-account-links"
                                        color="primary"
                                    >
                                        {emptySelection()}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.paperRight}>
                                <Link
                                    component="button"
                                    underline="always"
                                    className={classes.links}
                                    data-testid="edit-account-links"
                                    onClick={handleOpenModal}
                                >
                                    {hasChildrenSelection && !selectedAccount ? (
                                        <ExpandMore style={{ marginTop: '20px' }} />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </Link>
                            </div>
                        </Grid>
                    </Paper>
                )}
            </Grid>
            <Modal
                open={openModal}
                title={translationValues.modalTitle}
                close={handleCloseModal}
                closeIcon={true}
                maxWidth="md"
            >
                <Grid container className={classes.modalContainer} data-testid="account-modal">
                    <Grid container className={classes.accountContainer}>
                        <Grid container item xs={6} justify="flex-start" alignItems="center">
                            <Typography variant="body2" className={classes.accountText}>
                                {translationValues.modalCounter}
                            </Typography>
                            <Typography component="span" className={classes.indicator}>
                                {sortedAccounts.length || 0}
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            <TextField
                                variant="outlined"
                                onChange={(query) => handleSearch(query.target.value)}
                                placeholder={translationValues.modalSearch}
                                className={classes.searchField}
                                inputProps={{
                                    style: {
                                        padding: '0.5em',
                                        height: '1.5em',
                                        width: '12em'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <List className={classes.list}>
                                <RadioGroup
                                    name="account-radio"
                                    onChange={(value) => bubbleRadioChange(value)}
                                >
                                    {selectionType !== 'shipTo' &&
                                        sortedAccounts &&
                                        sortedAccounts.map((account, i) => (
                                            <AccountFilter
                                                data-testid={`account-filter${account.accountId}`}
                                                key={i}
                                                account={account as SelectableAccount}
                                                filterText={searchQuery}
                                                render={(account, words) => {
                                                    const warning: any = {};
                                                    if (
                                                        requiresAcknowledgement &&
                                                        requiresAcknowledgement.some(
                                                            ({ billToId }) =>
                                                                billToId === account.accountId
                                                        )
                                                    ) {
                                                        warning.showWarning = true;
                                                        warning.warningMessage = t(
                                                            'acknowledgementRequired',
                                                            'This Sold To has documents that require acknowledgment, please review.'
                                                        );
                                                    }
                                                    return (
                                                        <AccountListItem
                                                            account={account}
                                                            selected={
                                                                account.accountId === radioValue
                                                            }
                                                            {...warning}
                                                        />
                                                    );
                                                }}
                                                setVisibility={() => {}}
                                            />
                                        ))}
                                    {selectionType === 'shipTo' && (
                                        <>
                                            {!!deliverToAccounts.length && (
                                                <DeliverToAccounts
                                                    deliverToAccounts={deliverToAccounts}
                                                    filterText={searchQuery}
                                                    selectedAccountId={radioValue}
                                                />
                                            )}
                                            {!!otherAccounts.length && (
                                                <OtherShipToAccounts
                                                    accounts={otherAccounts as SelectableAccount[]}
                                                    filterText={searchQuery}
                                                    selectedAccountId={radioValue}
                                                    showHeader={!!deliverToAccounts.length}
                                                />
                                            )}
                                        </>
                                    )}
                                </RadioGroup>
                            </List>
                        </Grid>
                    </Grid>
                    <AppBar className={classes.appBar} position="sticky" elevation={10}>
                        <Toolbar className={classes.toolBar} variant="regular">
                            <Grid
                                container
                                direction="row"
                                className={classes.btnContainer}
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={3}>
                                    {hasClearSelection && (
                                        <Grid container item justify="flex-start">
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                data-testid="clear-btn"
                                                onClick={handleClearSelection}
                                                className={classes.btn}
                                            >
                                                <Trans i18nKey="clearSelection">
                                                    Clear Selection
                                                </Trans>
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid
                                        container
                                        item
                                        xs="auto"
                                        spacing={2}
                                        alignItems="center"
                                        justify="flex-end"
                                    >
                                        <Grid container item justify="flex-end" xs={6}>
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                data-testid="cancel-btn"
                                                onClick={handleCancelModal}
                                                className={classes.btn}
                                            >
                                                <Trans i18nKey="cancel">Cancel</Trans>
                                            </Button>
                                        </Grid>
                                        <Grid container item justify="flex-start" xs={6}>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                data-testid="select-btn"
                                                onClick={handleSelect}
                                                className={classes.btn}
                                            >
                                                <Trans i18nKey="select">Select</Trans>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Grid>
            </Modal>
        </>
    );
}
