import React, { useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    buildDeliveryTimes,
    calculateLeadTime,
    setDateTimeToMidnight,
    getSimpleFormattedDate,
    getSimpleFormattedTime,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import { DeliveryTime, ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import Link from '../../../reusable/atoms/Link';
import {
    getDeliveryLeadTimeDays,
    isProductEndType
} from '../../../../utility/helpers/order-helpers';
import ShipItConfigTruck from '../../ShipItConfiguration/components/ShipItConfigTruck';
import EditShipmentsConfigGrid from '../../EditShipmentsConfiguration/components/EditShipmentsConfigGrid';
import EditShipmentsConfigDateModal from '../../EditShipmentsConfiguration/components/EditShipmentsConfigDateModal';
import { Alert } from '@material-ui/lab';
import {
    canUpdateDesiredPallets,
    getLoadIndexBySequence,
    getReferenceLoad,
    editShipmentUseStyles,
    renderTimeSelectDisclaimer,
    renderTimeSelect,
    checkPONumbers,
    getNewDateTime
} from '../../../../utility/helpers/shipment-helpers';
import { BulkUploadShipment } from '../../../../store/reducers/bulk-upload';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { enUS } from '../../../../utility/translations/locales';
import { fullATMTruckPallets } from '../../../../themes/globalConstants';

interface Props {
    shipment: BulkUploadShipment;
    onDateUpdate?: (newDateTime: string, currentShipment: BulkUploadShipment) => void;
    onShowProductGrid: () => void;
    onUpdateShipment: (shipment: BulkUploadShipment) => void;
    isUniqueDateTime: (time: string, id: number, currentShipmentId: number) => boolean;
    onUpdatePallets: (
        product: ProductToShip,
        numOfPallets: number,
        palletDifference?: number
    ) => void;
    isValidShipment: Dispatch<SetStateAction<boolean>>;
    deleteProductUpdatePallets?: (product: ProductToShip) => void;
    hasCreditHold?: boolean;
    initialShipmentState?: BulkUploadShipment;
    productsToEdit?: ProductToShip[];
    editShipToId?: number;
    isLargeCustomer: boolean;
    truckPalletLimit: number;
}

export default function BulkEditShipmentConfigShipment({
    shipment,
    onDateUpdate,
    onShowProductGrid,
    onUpdateShipment,
    onUpdatePallets,
    isUniqueDateTime,
    isValidShipment,
    deleteProductUpdatePallets,
    hasCreditHold,
    initialShipmentState,
    productsToEdit,
    isLargeCustomer,
    truckPalletLimit
}: Props) {
    const classes = editShipmentUseStyles();
    const { t } = useTranslation();
    const [isCanType, setIsCanType] = useState<boolean>(true);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warningText, setWarningText] = useState<ReactNode>([]);
    const [showChangeWarning, setShowChangeWarning] = useState<boolean>(false);
    const [customerPickUp] = useState<boolean>(false);
    const [calendarDate, setCalendarDate] = useState<string>('');
    const [modalDeliveryDate, setModalDeliveryDate] = useState<moment.Moment | null>(null);
    const [firstAvailableDate, setFirstAvailableDate] = useState<moment.Moment | null>(null);
    const [shipmentDeliveryDateAndTime, setShipmentDeliveryDateAndTime] = useState<string>();
    const [selectedTime, setSelectedTime] = useState<string>();
    const [originalShipmentDateAndTime, setOriginalShipmentDateAndTime] = useState<string>();
    const [currentShipment, setCurrentShipment] = useState<BulkUploadShipment>(
        {} as BulkUploadShipment
    );
    const [deliveryTimeList, setDeliveryTimeList] = useState<DeliveryTime[]>([]);
    const [openDaypickerModal, setOpenDaypickerModal] = useState<boolean>(false);
    const [leadTime, setLeadTime] = useState<number>();
    const [showDateTimeError, setShowDateTimeError] = useState<boolean>(false);
    const [dateTimeWarningText, setDateTimeWarningText] = useState([] as React.ReactNode);
    const [shipToId, setShipToId] = useState<number>();
    const [showAddProductButton, setShowAddProductButton] = useState<boolean>(false);
    const [availBalanceWarning, setAvailBalanceWarning] = useState<boolean | undefined>(undefined);

    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const errorIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'} alt="Error Icon" />
    );

    const isShipmentValid = () => {
        // Customer PO will not be required for all customers
        // const validPONumberCheck = checkPONumbers(isLargeCustomer, currentShipment);
        const validPONumberCheck = true;
        const hasShipmentDeliveryDateAndTime = !!shipmentDeliveryDateAndTime;
        const hasShipmentLoads = !!currentShipment?.loads?.length;
        const hasPalletCount = !!currentShipment.palletCount;

        let isValid =
            hasShipmentLoads &&
            hasPalletCount &&
            validPONumberCheck &&
            hasShipmentDeliveryDateAndTime;
        isValidShipment(isValid);
    };

    const handleChangeTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        const timeString = String(event.target.value);
        const dateToCompare = `${getSimpleFormattedDate('', calendarDate)} ${getSimpleFormattedTime(
            '',
            timeString
        )}`;

        if (
            isUniqueDateTime &&
            isUniqueDateTime(dateToCompare, shipToId!, currentShipment.bulkShipmentId)
        ) {
            clearDateTimeError();
            const selectedTime = moment(timeString).format('HH:mm:ss');
            const selectedDate = moment(calendarDate).format('YYYY-MM-DDT');
            const newDateTime = getNewDateTime(selectedTime, selectedDate);
            setSelectedTime(selectedTime);
            setShipmentDeliveryDateAndTime(newDateTime);
            onDateUpdate && onDateUpdate(newDateTime, currentShipment);
        } else {
            setShipmentDeliveryDateAndTime(undefined);
            renderTimeSelect(
                classes,
                shipmentDeliveryDateAndTime,
                selectedTime,
                ExpandMoreIcon,
                handleChangeTime,
                deliveryTimeList
            );
            displayDateTimeError(
                t(
                    'deliveryOrderDayTimeError',
                    'Shipments on the same delivery order cannot have duplicate dates and times. Please modify the time chosen.'
                )
            );
        }
    };

    const clearDateTimeError = () => {
        setDateTimeWarningText(false);
        setShowDateTimeError(false);
    };

    const displayDateTimeError = (label: React.ReactNode) => {
        setDateTimeWarningText(label);
        setShowDateTimeError(true);
    };

    const handleRemoveProductFromShipment = (product: ProductToShip) => {
        let currentProduct = { ...product, palletsOrdered: 0 };
        let items = [...currentShipment.loads!];
        let itemIndex = items.findIndex((item) => item.sequence === currentProduct.sequence);

        if (deleteProductUpdatePallets) {
            deleteProductUpdatePallets(product);
        }

        if (itemIndex !== -1) {
            items.splice(itemIndex, 1);
            let totPallets = 0;
            items?.map((load) => {
                const pallets = load.palletQuantity;
                if (pallets) {
                    totPallets = totPallets + pallets;
                }
                return null;
            });

            items.map((item, index) => {
                item.sequence = index + 1;
                return null;
            });

            setCurrentShipment({
                ...currentShipment,
                loads: items,
                palletCount: totPallets,
                shipmentQuantity: totPallets
            });
            onUpdateShipment({
                ...currentShipment,
                loads: items,
                palletCount: totPallets,
                shipmentQuantity: totPallets
            });
        }
    };

    const getTotalOrderedPallets = (product: ProductToShip, tempShipment: BulkUploadShipment) => {
        let totalOrderedPallets = 0;

        tempShipment.loads?.map((load) => {
            if (load.sequence !== product.sequence) {
                totalOrderedPallets += load.palletQuantity;
            } else {
                totalOrderedPallets = totalOrderedPallets + product.palletQuantity;
            }
            return null;
        });

        return totalOrderedPallets;
    };

    const checkPalletsWithCreditHold = (product: ProductToShip) => {
        let tempShipment = { ...currentShipment };

        if (initialShipmentState) {
            if (tempShipment && tempShipment.loads && initialShipmentState.loads) {
                const loadIndex = getLoadIndexBySequence(product, tempShipment.loads);
                const referenceLoad = initialShipmentState.loads[loadIndex];

                //If attempted pallet increase, show warning
                if (product.palletQuantity > referenceLoad.palletQuantity) {
                    displayWarning(
                        t(
                            'creditHoldBlockPalletIncrease',
                            'There is a credit hold on your account.  You cannot increase the pallets on this order.'
                        )
                    );
                } else {
                    //Allow same or decreased pallet qty
                    checkPallets(product);
                }
            }
        } else {
            //If no initialShipmentState prop, short circuit and go directly to checkPallets method
            checkPallets(product);
        }
    };

    const checkPallets = (product: ProductToShip, canUpdatePallets?: boolean) => {
        let tempShipment = { ...currentShipment };
        let tempLoad = tempShipment.loads?.find((item) => item.sequence === product.sequence);
        let totalOrderedPallets = getTotalOrderedPallets(product, tempShipment);
        const isEndType = isProductEndType(product.type);
        const loadIndex = tempShipment.loads && getLoadIndexBySequence(product, tempShipment.loads);
        //If we have productsToEdit prop, use that as a reference for calculations
        //This allows us to compare input pallets to actual avail pallets, not just displayed avail pallets
        const referenceLoad = productsToEdit && getReferenceLoad(product, productsToEdit);
        setAvailBalanceWarning(undefined);

        if (
            totalOrderedPallets <= truckPalletLimit ||
            isEndType ||
            customerPickUp ||
            canUpdatePallets
        ) {
            clearWarning();
            // if editing, want to keep loadId so that we can properly track "edit" status
            if (tempLoad && tempLoad.loadId) {
                product.loadId = tempLoad?.loadId;
            }
            tempShipment.loads?.splice(loadIndex as number, 1, product);

            setCurrentShipment({
                ...tempShipment,
                loads: tempShipment.loads,
                palletCount: totalOrderedPallets,
                shipmentQuantity: totalOrderedPallets
            });
            onUpdateShipment({
                ...tempShipment,
                loads: tempShipment.loads,
                palletCount: totalOrderedPallets,
                shipmentQuantity: totalOrderedPallets
            });

            if (product.palletQuantity <= 0) {
                // If product pallet quantity is less than available pallets, but is zero, display warning
                product.availablePallets = product.availablePallets! - product.palletQuantity;
                tempShipment.loads?.splice(loadIndex as number, 1, product);

                setCurrentShipment({
                    ...tempShipment,
                    loads: tempShipment.loads
                });
                onUpdateShipment({
                    ...tempShipment,
                    loads: tempShipment.loads
                });
                displayWarning(t('palletQtyNotZero', enUS.palletQtyNotZero));
            }
        } else if (totalOrderedPallets > truckPalletLimit && !isEndType) {
            setCurrentShipment({
                ...currentShipment
            });
            //Removing Warning as it is expected now to create orders without limit
            /*
            displayWarning(
                t('maximumNumberOfPalletsWarning', enUS.maximumNumberOfPalletsWarning, {
                    truckPalletLimit: truckPalletLimit
                })
            );
            */
        }
    };

    const handleUpdateProduct = (product: ProductToShip, palletDifference?: number) => {
        if (hasCreditHold) {
            checkPalletsWithCreditHold(product);
        } else {
            if (palletDifference) {
                const canUpdatePallets = canUpdateDesiredPallets(product, palletDifference);
                //Remove available pallet logic check
                //checkPallets(product, canUpdatePallets);
                checkPallets(product, true);
            } else {
                let tempShipment = { ...currentShipment };
                let tempLoad = tempShipment.loads?.find(
                    (item) => item.sequence === product.sequence
                );
                const loadIndex =
                    tempShipment.loads && getLoadIndexBySequence(product, tempShipment.loads);

                clearWarning();
                // if editing, want to keep loadid so that we can properly track "edit" status
                if (tempLoad && tempLoad.loadId) {
                    product.loadId = tempLoad?.loadId;
                }
                tempShipment.loads?.splice(loadIndex as number, 1, product);

                setCurrentShipment({
                    ...tempShipment,
                    loads: tempShipment.loads
                });
                onUpdateShipment({
                    ...tempShipment,
                    loads: tempShipment.loads
                });
            }
        }
    };

    const clearWarning = () => {
        setWarningText(false);
        setShowWarning(false);
    };

    const displayWarning = (label: React.ReactNode) => {
        setWarningText(label);
        setShowWarning(true);
    };

    const handleDaypickerOpen = () => {
        setOpenDaypickerModal(true);
    };

    const handleDaypickerCancel = () => {
        setOpenDaypickerModal(false);

        //Reset date state
        const originalDeliveryDateFormatted =
            (shipment.deliveryDateTime && getSimpleFormattedDate('', shipment.deliveryDateTime)) ??
            '';
        setCalendarDate(originalDeliveryDateFormatted);

        const originalDeliveryDate = moment(shipment.deliveryDateTime);
        setModalDeliveryDate(originalDeliveryDate);
    };

    const handleDaypickerOk = () => {
        setOpenDaypickerModal(false);

        if (calendarDate && shipToId) {
            const fullDateTime = calendarDate + ' ' + selectedTime;
            const dateToCompare = getFullFormattedDateTime(moment(fullDateTime).format());

            if (
                isUniqueDateTime &&
                isUniqueDateTime(dateToCompare, shipToId, currentShipment.bulkShipmentId)
            ) {
                clearDateTimeError();
                const newDate = moment(dateToCompare).format('YYYY-MM-DDT');
                const newTime = moment(dateToCompare).format('HH:mm:ss');
                const newDateTime = getNewDateTime(newTime, newDate);
                setShipmentDeliveryDateAndTime(newDateTime);

                onDateUpdate && onDateUpdate(dateToCompare, currentShipment);
            } else {
                setShipmentDeliveryDateAndTime(undefined);
                displayDateTimeError(
                    t(
                        'deliveryOrderDayTimeError',
                        'Shipments on the same delivery order cannot have duplicate dates and times. Please modify the time chosen.'
                    )
                );
            }
        }
    };

    //Handles selection of day within daypicker
    const handleDateSelect = (newDate: moment.Moment | null) => {
        const originalDeliveryDate = shipment.deliveryDateTime && moment(shipment.deliveryDateTime);
        const updatedDeliveryDate =
            shipment.updatedDeliveryDate && moment(shipment.updatedDeliveryDate);
        const formattedDate = moment(newDate).format('MM/DD/YYYY');
        const beforeDeliveryDate =
            newDate?.isBefore(originalDeliveryDate) || newDate?.isBefore(updatedDeliveryDate);

        if (newDate) {
            // Don't show this alert as the avaialable balance will not be shown
            /*
            if (beforeDeliveryDate) {
                setShowChangeWarning(true);
            } else {
                setShowChangeWarning(false);
            }
            */
            setCalendarDate(formattedDate);
            setModalDeliveryDate(newDate);
        }
    };

    useEffect(() => {
        if (!hasCreditHold || (currentShipment.shipmentType === 'END' && !hasCreditHold)) {
            setShowAddProductButton(true);
        } else {
            setShowAddProductButton(false);
        }
    }, [currentShipment.shipmentType, hasCreditHold]);

    //Set shipToId to local state variable no matter which flow is linked
    // On load, set delivery date and time to what's in the API data
    useEffect(() => {
        if (shipment.shipToId) {
            setShipToId(shipment.shipToId);
        }
        if (shipment.deliveryDateTime) {
            const deliveryDateTime = shipment.updatedDeliveryDate
                ? moment(shipment.updatedDeliveryDate)
                : moment(shipment.deliveryDateTime);

            setCalendarDate(deliveryDateTime.format('MM/DD/YYYY'));
            setModalDeliveryDate(deliveryDateTime);
        }
        // Runs only once
    }, []);

    //Calculate and set the first available delivery day for the daypicker
    useEffect(() => {
        if (shipToId && shipToAccounts) {
            const deliveryLeadTimeDays = getDeliveryLeadTimeDays(shipToId, shipToAccounts);
            if (deliveryLeadTimeDays) {
                const today = moment();
                const leadTimeDay = calculateLeadTime(today, deliveryLeadTimeDays, 'days');
                const firstAvailableDay = setDateTimeToMidnight(leadTimeDay);
                setFirstAvailableDate(firstAvailableDay);
                setLeadTime(deliveryLeadTimeDays);
            }
        }
    }, [shipToId, shipToAccounts]);

    useEffect(() => {
        const isEndType = isProductEndType(shipment.shipmentType);
        const originalDateTime = moment(shipment.originalDateTime).format('MM/DD/YYYY hh:mm A');
        isEndType ? setIsCanType(false) : setIsCanType(true);
        const updatedPalletCount =
            shipment.palletCount ||
            shipment.shipmentQuantity ||
            shipment?.loads?.map((load) => load.palletQuantity).reduce((prev, curr) => prev + curr);
        setCurrentShipment({
            ...shipment,
            palletCount: updatedPalletCount
        });

        setOriginalShipmentDateAndTime(originalDateTime);
    }, [shipment]);

    useEffect(() => {
        if (shipmentDeliveryDateAndTime) {
            setDeliveryTimeList(buildDeliveryTimes(moment(shipmentDeliveryDateAndTime)));
        } else if (!shipmentDeliveryDateAndTime && !showDateTimeError) {
            setDeliveryTimeList(buildDeliveryTimes(moment(currentShipment.deliveryDateTime)));
            setShipmentDeliveryDateAndTime(currentShipment.deliveryDateTime);
            setSelectedTime(currentShipment.deliveryTime);
        }
        isShipmentValid();
    }, [currentShipment, shipmentDeliveryDateAndTime]);

    useTranslation();

    return (
        <>
            <Paper className={classes.main}>
                <Grid container>
                    <Grid container item md={6}>
                        <Grid item xs={12} className={classes.dateDescription}>
                            <Typography
                                className={clsx(
                                    classes.dateDescriptionMain,
                                    classes.altDescriptionColor
                                )}
                            >
                                <>
                                    <Trans i18nKey="deliveryDate">Delivery Date</Trans>:{' '}
                                </>
                                {calendarDate}
                            </Typography>
                            {
                                <Link
                                    component="button"
                                    underline="always"
                                    onClick={handleDaypickerOpen}
                                    className={classes.changeDayLink}
                                >
                                    <Trans i18nKey="changeDay">Change Day</Trans>
                                </Link>
                            }
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="baseline"
                            className={classes.originallyScheduled}
                        >
                            <Typography>
                                <Trans i18nKey="originallyScheduled">Originally scheduled</Trans>:{' '}
                                {originalShipmentDateAndTime}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item md={6}>
                        <Grid
                            container
                            item
                            xs={12}
                            justify="flex-end"
                            className={classes.timeSelect}
                        >
                            {renderTimeSelect(
                                classes,
                                shipmentDeliveryDateAndTime,
                                selectedTime,
                                ExpandMoreIcon,
                                handleChangeTime,
                                deliveryTimeList
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {renderTimeSelectDisclaimer(classes)}
                        </Grid>
                    </Grid>
                </Grid>
                {showDateTimeError && (
                    <Grid container justify="center">
                        <Alert
                            icon={errorIcon}
                            severity="warning"
                            className={classes.dataTimeError}
                        >
                            <Typography>{dateTimeWarningText}</Typography>
                        </Alert>
                    </Grid>
                )}
                {currentShipment.loads && currentShipment.loads.length ? (
                    <Grid container>
                        {isCanType &&
                            !customerPickUp &&
                            !(
                                !isNaN(currentShipment.palletCount!) &&
                                (currentShipment.palletCount as number) > truckPalletLimit
                            ) && (
                                <ShipItConfigTruck
                                    items={currentShipment}
                                    isLargeCustomer={truckPalletLimit === fullATMTruckPallets}
                                    truckPalletLimit={truckPalletLimit}
                                />
                            )}
                        <Grid container item>
                            <EditShipmentsConfigGrid
                                shipmentId={currentShipment.bulkShipmentId.toString()}
                                items={currentShipment.loads}
                                onUpdatePallets={onUpdatePallets}
                                onRemoveItem={handleRemoveProductFromShipment}
                                onUpdateItem={handleUpdateProduct}
                                warning={showWarning}
                                warningText={warningText}
                                isBulk={true}
                                availBalanceWarning={availBalanceWarning}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container className={classes.preview}>
                        <Typography className={classes.previewText}>
                            <Trans i18nKey="addDeliveryInstructions">
                                Add a product from the list you created and enter the quantities
                                here. The cart will update to display the remaining pallets to load,
                                if any.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                <Grid container justify="flex-end" className={classes.actionsBar}>
                    <Grid item className={classes.shipmentBtnContainer}>
                        <Link
                            underline="always"
                            data-testid="add-product-link"
                            className={`${classes.addProductLink} ${
                                showAddProductButton ? 'enabled' : ''
                            }`}
                            onClick={onShowProductGrid}
                            disabled={!showAddProductButton}
                        >
                            {'+ '}
                            <Trans i18nKey="addProduct">add product</Trans>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
            <EditShipmentsConfigDateModal
                open={openDaypickerModal}
                onCancel={handleDaypickerCancel}
                onOk={handleDaypickerOk}
                firstDay={firstAvailableDate}
                highlightedDay={modalDeliveryDate}
                leadTime={leadTime}
                showChangeWarning={showChangeWarning}
                selectDate={(newDate) => handleDateSelect(newDate)}
            />
        </>
    );
}
