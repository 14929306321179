import React, { useState } from 'react';
import { Button, Typography, makeStyles, Grid, Menu, MenuItem, Link } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ballGray, boldWeight, medium, xl } from '../../../../../themes/globalConstants';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { enUS } from '../../../../../utility/translations/locales';

interface Props {
    isLargeCustomer?: boolean;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: 1.3,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main
    },
    button: {
        padding: 0
    },
    moreIcon: {
        width: '1.25em',
        height: '1.25em',
        marginLeft: '.5em',
        color: ballGray
    },
    linksWrapper: {
        '&:focus': {
            outline: 'none'
        },
        width: '35em'
    },
    orderingHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: 1.3,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        paddingTop: '1em',
        paddingBottom: '1em',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
        cursor: 'default'
    },
    subheader: {
        color: ballGray,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        paddingBottom: '0.5em'
    },
    links: {
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            cursor: 'default'
        },
        lineHeight: '2em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    },
    text: {
        color: ballGray,
        whiteSpace: 'normal'
    },
    width: {
        width: '35em'
    },
    section: {
        paddingTop: '1em',
        paddingBottom: '1em'
    }
}));

const MakeItLinksDropdown = ({ isLargeCustomer }: Props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const makeItLink = '/make-it';
    const makeItSummaryLink = isLargeCustomer ? '/make-it-po-summary' : '/make-it-summary';
    const productPlanningLink = isLargeCustomer ? '/po-product-planning' : '/product-planning';

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                onClick={handleClick}
                aria-haspopup="true"
                className={classes.button}
                data-testid="user-menu"
            >
                <MoreHorizIcon className={classes.moreIcon} />
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <section className={classes.linksWrapper}>
                    <MenuItem className={classes.orderingHeader}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Trans i18nKey="ordering">Ordering</Trans>
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem className={classes.links}>
                        <Grid container className={classes.width}>
                            <Grid container item xs={12} className={classes.section}>
                                <Typography className={classes.subheader}>
                                    <Trans i18nKey="makeProducts">Make Products</Trans>
                                </Typography>
                                <Typography className={classes.text}>
                                    <Trans i18nKey="makeProductsTileDesc">
                                        Create and update Make It orders. Check the status of your
                                        Make It orders.
                                    </Trans>
                                </Typography>
                                <Grid item xs={12}>
                                    <Link
                                        to={makeItLink}
                                        underline="none"
                                        component={RouterLink}
                                        data-testid="make-it-link"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="createNewMakeItOrder">
                                            Create New Make It Order
                                        </Trans>
                                    </Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        to={makeItSummaryLink}
                                        underline="none"
                                        component={RouterLink}
                                        data-testid="make-it-summary-link"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="viewEditMakeItOrders">
                                            ${enUS.viewEditMakeItOrders}
                                        </Trans>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={classes.section}>
                                <Typography className={classes.subheader}>
                                    <Trans i18nKey="productPlanning">Product Planning</Trans>
                                </Typography>
                                <Typography className={classes.text}>
                                    <Trans i18nKey="productPlanningTileDesc">
                                        View production balances and scheduled shipments for up to
                                        sixteen weeks.
                                    </Trans>
                                    <Grid item xs={12}>
                                        <Link
                                            to={productPlanningLink}
                                            underline="none"
                                            component={RouterLink}
                                            data-testid="product-planning-link"
                                            className={classes.link}
                                        >
                                            <Trans i18nKey="viewProductPlanning">
                                                ${enUS.viewProductPlanning}
                                            </Trans>
                                        </Link>
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                </section>
            </Menu>
        </>
    );
};

export default MakeItLinksDropdown;
