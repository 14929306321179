import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Input, Link } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ProductToShip, ProductWithPallets } from '../../../../store/reducers/shipping-dashboard';
import { numericRegex } from '../../../../utility/helpers/formatters';
import {
    blackWeight,
    ballLtGray,
    orange,
    shipItReferenceNumberMaxLength,
    shipItReleaseNumberMaxLength,
    shipItPONumberMaxLength,
    deliveryInstructionsMaxLength,
    shipRed,
    errorBackground,
    small,
    black,
    regularWeight,
    boldWeight
} from '../../../../themes/globalConstants';
import shipItConfigColors from '../../ShipItConfiguration/components/ShipItConfigColors';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import { EditShipmentsState } from '../../../../store/reducers/edit-shipments';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { useDispatch } from 'react-redux';
import { updateExpandableLoad } from '../../../../store/actions/edit-shipments';
import ShipmentExpandableSection from '../../../reusable/molecules/ShipmentExpandableSection';
import {
    getEachesConversion,
    isCustomerProductIdDuplicate
} from '../../../../utility/helpers/shipment-helpers';
import DeliveryBulkProductIdModal from '../../../reusable/molecules/DeliveryBulkProductIdModal';
import { updateProductSku } from '../../../../store/actions/bulk-upload';

interface Props {
    shipmentId?: string;
    item: ProductToShip;
    index: number;
    onUpdateItem: (item: ProductToShip, palletDifference?: number) => void;
    onRemoveItem: (item: ProductToShip) => void;
    onUpdatePallets: (item: ProductToShip, numOfPallets: number, palletDifference: number) => void;
    isBulk?: boolean;
    readOnly?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: boldWeight
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.125em',
        fontWeight: blackWeight
    },
    smalltextField: {
        height: '1.5em',
        marginRight: '0.5em'
    },
    alignRight: {
        textAlign: 'right'
    },
    alignLeft: {
        '& .MuiInputBase-input': {
            textAlign: 'left',
            paddingLeft: '0.375em'
        }
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    trash: {
        paddingRight: '0',
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    colorBlock: {
        width: 16,
        height: 50
    },
    sequence: {
        marginLeft: '0.750em'
    },
    expand: {
        transform: 'rotate(0)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    eachesMargin: {
        marginRight: '0.3em'
    },
    errorAlert: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    errorAlertText: {
        fontSize: small,
        color: black
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

export default function EditShipmentsConfigRow({
    shipmentId,
    item,
    index,
    onRemoveItem,
    onUpdateItem,
    onUpdatePallets,
    isBulk = false,
    readOnly = false
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [palletQuantity, setPalletQuantity] = useState<number>(0);
    const [releaseNumber, setReleaseNumber] = useState<string>('');
    const [fillerLine, setFillerLine] = useState<string>('');
    const [referenceNumber, setReferenceNumber] = useState<string>('');
    const [deliveryInstructions, setDeliveryInstructions] = useState<string>('');
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>('');
    const [active, setActive] = useState(false);
    const [openProductIdModal, setOpenProductIdModal] = useState<boolean>(false);
    const { expandableLoads } = useTypedSelector<EditShipmentsState>(
        (state) => state.editShipments
    );
    const uniqueProductId = useMemo(
        () => !isCustomerProductIdDuplicate(item),
        // runs only when item.isCustomerProductIdDistinct is changed

        [item.isCustomerProductIdDistinct]
    );

    const toggleExpand = () => {
        dispatch(
            updateExpandableLoad({
                shipmentId: shipmentId,
                expanded: !active,
                loadId: item.editLoadId
            })
        );
    };

    const updatePalletCount = (count: string) => {
        const numOfPallets = parseInt(count);
        if (numericRegex(count)) {
            !isNaN(numOfPallets) ? setPalletQuantity(numOfPallets) : setPalletQuantity(0);
        }
    };

    const updateOptionalField = (fieldValue: string, fieldName: string) => {
        if (fieldName === 'releaseNumber') {
            setReleaseNumber(fieldValue.slice(0, shipItReleaseNumberMaxLength));
        } else if (fieldName === 'referenceNumber') {
            setReferenceNumber(fieldValue.slice(0, shipItReferenceNumberMaxLength));
        } else if (fieldName === 'purchaseOrderNumber') {
            setPurchaseOrderNumber(fieldValue.slice(0, shipItPONumberMaxLength));
        } else if (fieldName === 'deliveryInstructions') {
            setDeliveryInstructions(fieldValue.slice(0, deliveryInstructionsMaxLength));
        }
    };

    const handleSelectChange = (fieldValue: string) => {
        setFillerLine(fieldValue);
    };

    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    const handleUpdateItem = (fieldValue: string, fieldName: string) => {
        const currentProduct = { ...item, [fieldName]: fieldValue };
        if (fieldName === 'palletQuantity') {
            const numOfPallets = parseInt(fieldValue);
            const palletDifference = item.palletQuantity - numOfPallets;
            let currentProduct: ProductToShip = {
                ...item,
                [fieldName]: numOfPallets
            };

            if (numericRegex(fieldValue)) {
                !isNaN(numOfPallets) ? setPalletQuantity(numOfPallets) : setPalletQuantity(0);
                !isNaN(numOfPallets) && onUpdateItem(currentProduct, palletDifference);
            }

            onUpdatePallets(currentProduct, numOfPallets, palletDifference);
        } else {
            onUpdateItem(currentProduct);
        }
    };

    const handleAcceptProductSku = (product: ProductWithPallets) => {
        if (shipmentId) {
            dispatch(
                updateProductSku(
                    item.customerProductId!,
                    product,
                    shipmentId,
                    isBulk,
                    item.sequence
                )
            );
        }
    };

    useEffect(() => {
        if (expandableLoads) {
            const expandableLoad = expandableLoads?.find((l) => l.loadId === item.editLoadId);
            if (expandableLoad) {
                setActive(expandableLoad.expanded);
            } else if ((shipmentId || isBulk) && item) {
                dispatch(
                    updateExpandableLoad({
                        shipmentId: shipmentId,
                        expanded: false,
                        loadId: item.editLoadId
                    })
                );
            }
        }
    }, [dispatch, expandableLoads, isBulk, item, shipmentId]);

    useEffect(() => {
        if (item.palletQuantity !== undefined) {
            setPalletQuantity(item.palletQuantity);
        }
        if (item.releaseNumber !== undefined) {
            setReleaseNumber(item.releaseNumber);
        }
        if (item.fillerLine !== undefined) {
            setFillerLine(item.fillerLine);
        }
        if (item.referenceNumber !== undefined) {
            setReferenceNumber(item.referenceNumber);
        }
        if (item.purchaseOrderNumber !== undefined) {
            setPurchaseOrderNumber(item.purchaseOrderNumber);
        }
        if (item.deliveryInstructions !== undefined) {
            setDeliveryInstructions(item.deliveryInstructions);
        }
    }, [item]);

    useTranslation();

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`productrow-${item.loadId}`}
            >
                <TableCell data-testid="sequence">
                    <Grid container alignItems="center">
                        {readOnly ? (
                            <Grid item className={classes.sequence}>
                                <Typography>{item.sequence}</Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid
                                    item
                                    className={classes.colorBlock}
                                    style={{
                                        backgroundColor: `${shipItConfigColors[index]}`
                                    }}
                                />
                                <Grid item className={classes.sequence}>
                                    <Typography>{item.sequence}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography data-testid="customer-product-id">
                        {item.customerProductId || (
                            <Trans i18nKey="idNotAvailable">ID not available</Trans>
                        )}
                    </Typography>
                </TableCell>
                <TableCell
                    data-testid="user-supplied-product-id"
                    className={clsx({
                        [classes.errorAlert]: !uniqueProductId
                    })}
                >
                    <Grid container>
                        {!uniqueProductId && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        )}
                        <Grid container>
                            <Typography data-testid="display-id">{item.displayId}</Typography>
                        </Grid>
                        {!uniqueProductId && (
                            <>
                                <Typography className={classes.errorAlertText}>
                                    <Trans>Duplicate Product IDs found </Trans>
                                    <Link
                                        className={classes.link}
                                        underline="always"
                                        data-testid="dup-product-id-link"
                                        onClick={() => setOpenProductIdModal(true)}
                                    >
                                        <Trans i18nKey="clickToChooseCorrect">
                                            Click to choose the correct one.
                                        </Trans>
                                    </Link>
                                </Typography>
                            </>
                        )}
                    </Grid>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Typography>{item.displayName}</Typography>
                    <Typography>{item.description}</Typography>
                </TableCell>
                {/*
                <TableCell>
                    {uniqueProductId && (
                        <Typography data-testid={`available-balance-${item.loadId}`}>
                            {Number(item.availablePallets).toLocaleString()}
                            {' PL'}
                        </Typography>
                    )}
                </TableCell>
                */}
                <TableCell>
                    <Grid container alignContent="center">
                        <Grid item className={classes.paletteInputContainer}>
                            {readOnly ? (
                                <>
                                    <Typography>{palletQuantity} PL</Typography>
                                    <Typography>
                                        {item.quantityPerPallet &&
                                            getEachesConversion(
                                                palletQuantity,
                                                item.quantityPerPallet,
                                                true
                                            )}
                                    </Typography>
                                </>
                            ) : (
                                <Grid container justify="flex-end">
                                    <Grid container item>
                                        <Grid item xs={10}>
                                            <Input
                                                type="text"
                                                data-testid="quantity-input"
                                                className={clsx(
                                                    classes.textField,
                                                    classes.smalltextField
                                                )}
                                                disableUnderline={true}
                                                name="palletQuantity"
                                                onBlur={(e) =>
                                                    handleUpdateItem(e.target.value, e.target.name)
                                                }
                                                onChange={(e) => updatePalletCount(e.target.value)}
                                                value={palletQuantity}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>PL</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.eachesMargin}
                                        >
                                            {item.quantityPerPallet &&
                                                getEachesConversion(
                                                    palletQuantity,
                                                    item.quantityPerPallet,
                                                    true
                                                )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <Flags authorizedFlags={[FlagTypes.Coke]}>
                    <TableCell data-testid="purchase-order-number">
                        <Grid item>
                            {readOnly ? (
                                <Typography>{purchaseOrderNumber}</Typography>
                            ) : (
                                <Input
                                    type="text"
                                    data-testid="purchase-order-number-input"
                                    className={clsx(classes.textField, classes.alignLeft)}
                                    disableUnderline={true}
                                    name="purchaseOrderNumber"
                                    onBlur={(e) => handleUpdateItem(e.target.value, e.target.name)}
                                    onChange={(e) =>
                                        updateOptionalField(e.target.value, e.target.name)
                                    }
                                    value={purchaseOrderNumber}
                                />
                            )}
                        </Grid>
                    </TableCell>
                </Flags>
                <TableCell>
                    <ExpandMoreIcon
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: active
                        })}
                        onClick={toggleExpand}
                        color={'primary'}
                        data-testid="expand-icon"
                    />
                </TableCell>

                {!readOnly && (
                    <TableCell>
                        <Button
                            type="button"
                            className={classes.trash}
                            onClick={handleRemoveItem}
                            data-testid="trash-icon"
                        >
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        </Button>
                    </TableCell>
                )}
            </TableRow>
            <ShipmentExpandableSection
                active={active}
                releaseNumber={releaseNumber}
                reference={referenceNumber}
                referenceFieldName={'referenceNumber'}
                deliveryInstructions={deliveryInstructions}
                fillerLine={fillerLine}
                readOnly={readOnly}
                handleUpdateItem={handleUpdateItem}
                updateOptionalField={updateOptionalField}
                handleSelectChange={handleSelectChange}
            />
            <DeliveryBulkProductIdModal
                customerProductId={item.customerProductId!}
                candidateProductSkus={item.candidateProductSkus!}
                requestedQuantity={item.palletQuantity}
                open={openProductIdModal}
                onClose={() => setOpenProductIdModal(false)}
                onAcceptProductSku={handleAcceptProductSku}
            />
        </>
    );
}
