import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import {
    DeliveryOrderRow,
    ShippingSummaryState
} from '../../../../store/reducers/shipping-summary';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    medium,
    regularWeight
} from '../../../../themes/globalConstants';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import {
    formatOrderQuantity,
    getSearchableOrderQuantityString
} from '../../../../utility/helpers/order-helpers';
import { usePrevious } from '../../../../utility/helpers/react-util';
import { formatDeliveryWeek } from '../../../../utility/helpers/shipment-helpers';
import TranslationService from '../../../../utility/services/translation-service';
import closedDeliveryOrderColumns from './ClosedDeliveryOrderColumns';
import ShipItClosedDeliveriesRow from './ShipItClosedDeliveriesRow';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import { getCurrentDate } from '../../../../utility/helpers/date-helpers';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { selectDateFormat, selectDateTimeFormat } from '../../../../store/selectors';

interface Props {
    onFilteredOrdersChanged: (numOrders: number) => void;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    title: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.625em'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '15%'
                },
                '&:nth-child(3)': {
                    width: '15'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '25%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const ShipItClosedDeliveriesTab = ({ onFilteredOrdersChanged }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { closedDeliveryOrders } = useTypedSelector<ShippingSummaryState>(
        (state) => state.shippingSummary
    );
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const dateFormat = useTypedSelector<string>(selectDateFormat);
    const dateTimeFormat = useTypedSelector<string>(selectDateTimeFormat);

    const [formattedClosedOrders, setFormattedClosedOrders] = useState({} as DeliveryOrderRow[]);
    const [filteredOrders, setFilteredOrders] = useState<DeliveryOrderRow[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [columns, setColumns] = useState<any>([]);

    const prevSelectedShipTo = usePrevious(selectedAccountId);

    // const options = new ToolbarOptions({
    //     advancePagination: false,
    //     exportButton: false,
    //     printButton: false,
    //     searchText: false
    // });

    const getShipToName = (deliveryOrderId?: number) => {
        if (shipToAccounts && closedDeliveryOrders) {
            let filteredOrder = closedDeliveryOrders.find(
                (ord) => deliveryOrderId === ord.deliveryOrderId
            );
            let filteredAccount = shipToAccounts.find(
                (shipToAccount) =>
                    filteredOrder && filteredOrder.shipToId === +shipToAccount.accountId
            );

            if (filteredAccount) {
                return filteredAccount.name;
            }
        }
    };

    const getShipToAddress = (deliveryOrderId?: number) => {
        if (shipToAccounts && closedDeliveryOrders) {
            let filteredOrder = closedDeliveryOrders.find(
                (ord) => deliveryOrderId === ord.deliveryOrderId
            );
            let filteredAccount = shipToAccounts.find(
                (shipToAccount) =>
                    filteredOrder && filteredOrder.shipToId === +shipToAccount.accountId
            );

            if (filteredAccount) {
                return getAccountAddress(filteredAccount);
            }
        }
    };

    const formatClosedOrders = (unformattedOrders: DeliveryOrder[]) => {
        let formattedOrders: DeliveryOrderRow[] = [];
        unformattedOrders.forEach((order) => {
            if (order.shipToId && order.shipToId.toString() === selectedAccountId) {
                let newOrder: DeliveryOrderRow;
                let formattedDeliveryWeek = formatDeliveryWeek(
                    order?.earliestShipmentDate || order?.deliveryWeekDate
                );
                newOrder = {
                    ...order,
                    shipToName: getShipToName(order.deliveryOrderId),
                    shipToAddress: getShipToAddress(order.deliveryOrderId),
                    shipmentDeliveryDateTime: formattedDeliveryWeek,
                    formattedShipmentDeliveryDateTime: formattedDeliveryWeek
                        ? moment(formattedDeliveryWeek).format(dateFormat)
                        : 'N/A',
                    orderQuantityText: getSearchableOrderQuantityString(
                        cultureCode,
                        order.totalShipments,
                        order.totalOrderQuantity,
                        order.totalOrderSkus,
                        order.totalOrderQuantityEaches
                    ),
                    orderQuantityContent: formatOrderQuantity(
                        cultureCode,
                        order.totalShipments,
                        order.totalOrderQuantity,
                        order.totalOrderSkus,
                        order.totalOrderQuantityEaches
                    ),
                    deliveryOrderNumberString: order.deliveryOrderNumber
                        ? order.deliveryOrderNumber.toString()
                        : 'Pending'
                };
                formattedOrders.push(newOrder);
            }
        });

        setFormattedClosedOrders(formattedOrders);
        onFilteredOrdersChanged(formattedOrders.length);
        setIsLoaded(true);
    };

    const ClosedDeliveryOrderRow = ({ row }: { row: Partial<DeliveryOrderRow> }) => {
        if (row) {
            return (
                <ShipItClosedDeliveriesRow
                    order={row as DeliveryOrderRow}
                    key={row.deliveryOrderId}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (
            !formattedClosedOrders.length ||
            !isLoaded ||
            prevSelectedShipTo !== selectedAccountId
        ) {
            formatClosedOrders(closedDeliveryOrders);
        }
        //eslint-disable-next-line
    }, [closedDeliveryOrders, shipToAccounts, selectedAccountId]);

    useEffect(() => {
        setColumns(closedDeliveryOrderColumns.getClosedDeliveryOrderColumns(cultureCode));
        formatClosedOrders(closedDeliveryOrders);
        //eslint-disable-next-line
    }, [cultureCode]);

    const handleRowFilter = (filteredRows) => {
        setFilteredOrders(filteredRows);
    };

    interface CSVRow {
        ship_to_detail?: string;
        delivery_order_number?: string;
        next_delivery_week?: string;
        total_delivery_quantity?: string;
        status?: string;
    }

    const csvHeaders = [
        { label: t('shipTo', 'Ship to').toUpperCase(), key: 'ship_to_detail' },
        {
            label: t('deliveryOrderNumber', 'Delivery Order #').toUpperCase(),
            key: 'delivery_order_number'
        },
        { label: t('status', 'STATUS').toUpperCase(), key: 'status' },
        {
            label: t('nextDeliveryWeek', 'Next Delivery Week').toUpperCase(),
            key: 'next_delivery_week'
        },
        {
            label: t('totalDeliveryQuantity', 'Total Delivery Quantity').toUpperCase(),
            key: 'total_delivery_quantity'
        }
    ];
    const csvArray = () => {
        let rows: any[] = [];
        if (!Array.isArray(filteredOrders) || filteredOrders.length === 0) return rows;
        filteredOrders.map((order) => {
            const row: CSVRow = {
                ship_to_detail: `${order.shipToName}, ${order.shipToAddress}, #${order.shipToId}`,
                delivery_order_number: order.deliveryOrderNumberString?.toUpperCase(),
                next_delivery_week:
                    moment(order.shipmentDeliveryDateTime).format(dateFormat) || 'N/A',
                total_delivery_quantity: order.orderQuantityText,
                status: order.status
            };

            rows.push(row);
            return null;
        });
        return rows;
    };
    const csvContents = csvArray();

    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                    {TranslationService.getTranslatedText(cultureCode, 'closedDeliveryOrders')}
                </Typography>
                <Grid className={classes.downloadWrapper}>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        underline="always"
                        data-testid="download-btn"
                        headers={csvHeaders}
                        data={csvContents}
                        filename={`Ship it Summary - Closed Delivery Order - ${getCurrentDate(
                            dateTimeFormat
                        )}.csv`}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            </div>
            <Grid className={classes.tableWrapper} data-testid="closed-delivery-order-grid">
                <SummarySearchBar
                    rows={formattedClosedOrders || []}
                    columns={columns}
                    onRowFilter={handleRowFilter}
                />
                {formattedClosedOrders && isLoaded && (
                    <StandardTable
                        gridName="Closed Orders"
                        columns={columns}
                        lines={filteredOrders}
                        rowComponent={ClosedDeliveryOrderRow}
                        searchText={false}
                    />
                )}
            </Grid>
        </Paper>
    );
};

export default ShipItClosedDeliveriesTab;
