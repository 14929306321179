import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, Paper } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import {
    blackWeight,
    small,
    ballBlue,
    boldWeight,
    ballGray,
    dateOptionInvalidGray,
    xxl,
    large,
    black
} from '../../../../themes/globalConstants';
import { Trans } from 'react-i18next';
import {
    DeliveryShipment,
    ProductWithPallets
} from '../../../../store/reducers/shipping-dashboard';
import { generateKey, productTypeHelper } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import EditShipmentsAddProductColumns from './EditShipmentsAddProductColumns';
import EditShipmentsAddProductRow from './EditShipmentsAddProductRow';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

interface Props {
    shipItItems: ProductWithPallets[];
    onAddItem: (item: ProductWithPallets, pallets: number, type: string) => void;
    shipment: DeliveryShipment;
    isBulkUpload: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        padding: '0 0.5em',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        marginTop: '1.5em',
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            },
            '& ::-ms-clear': {
                display: 'none'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            margin: '0 .125em 1.125em .125em',
            padding: '0.875em 1.125em',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1',
                marginRight: '-0.375em'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                padding: 0,
                '&:nth-child(3)': {
                    width: '20%'
                }
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    tableTitle: {
        fontSize: xxl,
        fontWeight: blackWeight,
        color: ballGray,
        padding: '1em 0.25em 0.75em 0.25em'
    },
    tableSubTitle: {
        fontSize: large,
        fontWeight: boldWeight,
        color: ballGray,
        padding: '0 0.375em'
    }
}));

export default function EditShipmentsAddProductGrid({
    shipItItems,
    onAddItem,
    shipment,
    isBulkUpload
}: Props) {
    const classes = useStyles();
    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [availableProducts, setAvailableProducts] = useState<ProductWithPallets[]>([]);

    const { useOrderingV2 } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        advancePagination: false
    });

    const CustomerShippingRow = ({ row }: { row: Partial<ProductWithPallets> }) => {
        const productInShipment = shipment?.loads?.find(
            (load) => load.productSku === row.productSku
        );

        return (
            <EditShipmentsAddProductRow
                key={generateKey('product')}
                item={row}
                onAddItem={onAddItem}
                disableAdd={isBulkUpload ? !!productInShipment : false}
                isBulkUpload={isBulkUpload}
            />
        );
    };

    useEffect(() => {
        setColumns(EditShipmentsAddProductColumns.getAddProductColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (shipItItems) {
            const addProductItems: ProductWithPallets[] = [];
            // Remove the filters when it is a BulkUpload or a V2 customer as we are expecting all the products to be available
            if (isBulkUpload || useOrderingV2) {
                setAvailableProducts(shipItItems);
                return;
            }
            shipItItems.forEach((item) => {
                const allowedProductType =
                    productTypeHelper(item.type) === shipment.shipmentType &&
                    item.availablePallets! >= 0;
                const hasAvailablePallets = item.availablePallets
                    ? item.availablePallets > 0
                    : false;
                if (allowedProductType && hasAvailablePallets) {
                    addProductItems.push(item);
                }
            });
            setAvailableProducts(shipItItems);
        }
    }, [shipItItems, shipment.shipmentType]);

    return (
        <Paper>
            <Grid
                container
                className={classes.tableWrapper}
                key={generateKey('edit-add-product-grid')}
            >
                <Grid>
                    <Typography className={classes.tableTitle}>
                        <Trans i18nKey="addProduct">add product</Trans>
                    </Typography>
                    <Typography className={classes.tableSubTitle}>
                        <Trans i18nKey="pleaseSelectProductsShipment">
                            Please select products to add to your shipment
                        </Trans>
                    </Typography>
                </Grid>
                <DataGrid
                    columns={columns}
                    dataSource={availableProducts}
                    gridName="Edit Shipments Grid"
                    rowComponent={CustomerShippingRow}
                    toolbarOptions={CustomToolbarOptions}
                />
            </Grid>
        </Paper>
    );
}
