import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { boldWeight, red, small } from '../../../themes/globalConstants';
import { BulkUploadDeliveryState, BulkUploadOrder } from '../../../store/reducers/bulk-upload';
import { useTypedSelector } from '../../../store/reducers/reducer';
import DeliveryBulkUploadOrderSection from './components/DeliveryBulkUploadOrderSection';
import Button from '../../reusable/atoms/Button';
import { useDispatch } from 'react-redux';
import { handleDeleteShipment, submitDeliveryBulkUpload } from '../../../store/root-actions';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { selectHasImpersonationShipTo } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { isCustomerProductIdDuplicate } from '../../../utility/helpers/shipment-helpers';
import { subtractTimezoneOffset } from '../../../utility/helpers/date-helpers';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0.5em 0'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    errorMessage: {
        fontSize: small,
        fontWeight: boldWeight,
        color: red
    }
}));

export default function DeliveryBulkUploadReview() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { orders, loading, submittedOrders } = useTypedSelector<BulkUploadDeliveryState>(
        (state) => state.bulkUploadDelivery
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [shipmentIndex, setShipmentIndex] = useState<number>(0);
    const [orderIndex, setOrderIndex] = useState<number>(0);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [noActiveShipments, setNoActiveShipments] = useState<boolean>(true);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const hasImpersonationShipTo = useTypedSelector<boolean>(selectHasImpersonationShipTo);
    const hasDuplicateIds = useMemo(
        () =>
            orders?.some((order) =>
                order.shipments?.some((shipment) =>
                    shipment.loads?.some((load) => isCustomerProductIdDuplicate(load))
                )
            ),
        [orders]
    );

    useEffect(() => {
        if (orders) {
            setNoActiveShipments(!hasActiveShipments(orders));
        }
    }, [orders]);

    const hasActiveShipments = (orders: BulkUploadOrder[]): boolean => {
        let active = false;
        orders.forEach((order) => {
            const activeShipments = order.shipments?.filter(
                (shipment) => shipment.isDeleted === false
            );
            if (activeShipments && activeShipments.length > 0) {
                active = true;
            }
        });
        return active;
    };

    const onDelete = () => {
        dispatch(handleDeleteShipment(orderIndex, shipmentIndex));
        setOpenDelete(false);
    };

    const onCloseDeleteModal = () => {
        setOpenDelete(false);
    };

    const onOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const handleConfirmCancel = () => {
        setOpenCancelModal(false);
        history.push('/ship-it');
    };

    const onBack = () => {
        setOpenBackModal(true);
    };

    const onGoBack = () => {
        history.push('/ship-it-bulk-upload');
    };

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else {
            setOpenCancelModal(false);
        }
    };

    const resetTruckNumbers = (orders: BulkUploadOrder[]) => {
        orders.forEach((order) => {
            order.shipments?.forEach((shipment, index) => {
                shipment.truckId = index + 1;
            });
        });
        return orders;
    };

    const onSubmit = () => {
        setIsSubmitting(true);
        if (orders) {
            let ordersToFilter = [...orders];
            ordersToFilter.forEach((order) => {
                let filteredShipments = order.shipments?.filter(
                    (shipment) => shipment.isDeleted === false
                );
                if (filteredShipments) {
                    order.shipments?.splice(0, order.shipments.length, ...filteredShipments);
                }
                if (order.shipments) {
                    order.shipments.forEach((shipment) => {
                        const deliveryTime = subtractTimezoneOffset(shipment.deliveryDateTime);
                        shipment.deliveryDateTime = deliveryTime;
                    });
                }
            });
            ordersToFilter = resetTruckNumbers(ordersToFilter);
            if (!hasImpersonationShipTo) {
                dispatch(submitDeliveryBulkUpload(ordersToFilter));
            } else {
                setIsSubmitting(false);
                setShowImpersonationWarning(true);
            }
        }
    };

    useEffect(() => {
        if (!loading && isSubmitting) {
            setIsSubmitting(false);
        }
    }, [loading]);

    useEffect(() => {
        if (submittedOrders) {
            history.push('/ship-it-bulk-upload-confirmation');
        }
    });

    const footerActions = (
        <>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onOpenCancelModal}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6}>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                />
            </Grid>
            <Grid container item xs={3} justify="space-between">
                <Grid container item xs={5}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={onBack}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.actionBtn}
                        onClick={onSubmit}
                        disabled={
                            isSubmitting || (orders && (noActiveShipments || hasDuplicateIds))
                        }
                    >
                        <Trans i18nKey="submitOrder">Submit Order</Trans>
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: t('bulkUpload', 'Bulk Upload'),
                    description: '',
                    thinBanner: true,
                    displayDropdown: false
                }}
                actionFooter={{
                    footerAction: footerActions,
                    justify: 'space-between',
                    sticky: true
                }}
                activity={Activity.ShipItBulkUpload}
                shipTos={false}
            >
                <Grid container justify="space-between" data-testid="ship-it-bulk-upload-page">
                    <Grid container item md={9} className={classes.main}>
                        {orders &&
                            orders.map((order, index) => {
                                const initialIndex = index;
                                const deliveryOrderNumber = (index += 1);
                                return (
                                    <DeliveryBulkUploadOrderSection
                                        key={`delivery_order_section_${index}`}
                                        orderInformation={order}
                                        deliveryOrderNumber={deliveryOrderNumber}
                                        orderIndex={initialIndex}
                                        setShipmentIndex={setShipmentIndex}
                                        setOrderIndex={setOrderIndex}
                                        setOpenDelete={setOpenDelete}
                                    />
                                );
                            })}
                    </Grid>
                    {isSubmitting && (
                        <Grid container item xs={12} className={classes.spinningLoader}>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
                {orders && noActiveShipments && (
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justify="center"
                        className={classes.actionBar}
                    >
                        <Typography className={classes.errorMessage}>
                            <Trans i18nKey="allShipmentsDeleted">{enUS.allShipmentsDeleted}</Trans>
                        </Typography>
                    </Grid>
                )}
            </ProcessingPageTemplate>

            <ConfirmationModal
                saveProgress={onDelete}
                onClose={onCloseDeleteModal}
                logo={false}
                title={<Trans i18nKey="deleteShipment">Delete Shipment</Trans>}
                subheader={
                    <Trans i18nKey="confirmDeleteShipment">
                        Are you sure you want to delete this shipment?
                    </Trans>
                }
                continueText={<Trans i18nKey="yesDelete">Yes, Delete</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openDelete}
                navigationLink=""
                onCancel={onCloseDeleteModal}
            />
            <ConfirmationModal
                saveProgress={handleConfirmCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="go-back-modal"
                saveProgress={onGoBack}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                subheader={<Trans i18nKey="goBackConfirm">Are you sure you want to go back?</Trans>}
                continueText={<Trans i18nKey="yesGoBack">Yes, Go Back</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBackModal}
                navigationLink=""
                onCancel={onClose}
            />
        </>
    );
}
