import { HEADER_LABELS_LOAD } from '../actions/action-types';

export interface HeaderContent {
    loading: boolean;
    content: {
        regionLabel: string;
        languageLabel: string;
        contactUs: string;
        logout: string;
        appLink?: string;
        dateFormatLabel: string;
    };
    cultureCode: string;
}

const initialState: HeaderContent = {
    loading: false,
    content: {
        regionLabel: 'REGION',
        languageLabel: 'LANGUAGE',
        contactUs: 'Contact Us',
        logout: 'Logout',
        dateFormatLabel: 'DATE FORMAT'
    },
    cultureCode: 'en-US'
};

const headerContent = (state: HeaderContent = initialState, action: any) => {
    switch (action.type) {
        case HEADER_LABELS_LOAD:
            return {
                ...state,
                content: action.content,
                cultureCode: action.cultureCode
            };
        default:
            return state;
    }
};

export default headerContent;
