import React, { useEffect, useState } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Grid, makeStyles, Typography, Paper, Button } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { blackWeight, ballGray, containerMaxWidth } from '../../../themes/globalConstants';
import DeliveryOrderDetailsSubheader from './components/DeliveryOrderDetailsSubheader';
import { useHistory, useParams } from 'react-router-dom';
import { DeliveryOrderDetailsState } from '../../../store/reducers/delivery-order-details';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { Activity } from '../../../utility/auth/useSecurity';
import { loadDeliveryOrder, loadLinkedProductionOrders } from '../../../store/root-actions';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import DeliveryOrderShareLinks from './components/DeliveryOrderShareLinks';
import {
    getShippingInformation,
    ShipToAccountInformation
} from '../../../utility/helpers/address-helpers';
import { DeliveryOrderDetailsATMProvider } from './context/DeliveryOrderDetailsContext';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';
import { deliveryOrderEditShouldBeDisabledBasedOnReviewStatus } from '../../../utility/helpers/order-helpers';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    subheader: {
        marginBottom: '1em'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    actionBtn: {
        borderRadius: 'unset'
    }
}));

const DeliveryOrderDetails = () => {
    const { id } = useParams<DefaultQueryParams>();
    const { deliveryOrder, linkedProductionOrders, loading } =
        useTypedSelector<DeliveryOrderDetailsState>((state) => state.deliveryOrderDetails);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [shipToId, setShipToId] = useState<string>('');
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToAddress, setDisplayAddress] = useState<string>('');
    const [modeOfTransport, setModeOfTransport] = useState<string>('');
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const history = useHistory();
    const classes = useStyles();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [isATM, setIsATM] = useState<boolean>(false);

    const handleBackNavigation = () => {
        history.goBack();
    };

    // If going back to the Ship-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('ship-it-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (id && shipToAccounts && shipToAccounts.length > 0 && !loading) {
            dispatch(loadDeliveryOrder(id));
            dispatch(loadLinkedProductionOrders(id));
        }
        //eslint-disable-next-line
    }, [dispatch, id, shipToAccounts]);

    useEffect(() => {
        if (permissions && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, shipToAccounts, permissions]);

    useEffect(() => {
        if (deliveryOrder && deliveryOrder.shipToId && shipToAccounts) {
            const filteredAccount = shipToAccounts.find(
                (account) => account.accountId === deliveryOrder.shipToId?.toString()
            );
            if (filteredAccount) {
                const information = getShippingInformation(
                    filteredAccount
                ) as ShipToAccountInformation;
                setShipToId(filteredAccount.accountId);
                setShipToName(information.shipToName.toString());
                setModeOfTransport(information.modeOfTransport);
                setDisplayAddress(information.displayAddress);
                setIsATM(
                    filteredAccount.prodOrderType === ProdOrderType.AuthorizationToManufacture
                );
            }
        }
    }, [dispatch, deliveryOrder, shipToAccounts]);

    const onEdit = () => {
        if (deliveryOrder?.deliveryOrderId) {
            history.push(`/edit-shipments-summary/${deliveryOrder?.deliveryOrderId}`);
        }
    };

    const isEditDisabled = () => {
        const deliveryDatesList = deliveryOrder?.shipments?.map((i) => moment(i.deliveryDateTime));
        const latestShipmentDate = moment.max(deliveryDatesList || []);
        if (deliveryOrder?.isLocked) {
            return true;
        } else if (
            deliveryOrder &&
            deliveryOrderEditShouldBeDisabledBasedOnReviewStatus(deliveryOrder)
        ) {
            return true;
        } else if (latestShipmentDate) {
            let currentDate = moment();
            let latestShipDate = moment(latestShipmentDate);
            let hourDiff = latestShipDate.diff(currentDate, 'hours');
            return hourDiff < 24;
        } else {
            return true;
        }
    };

    const footerActions = (
        <>
            <Grid container item xs={6}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="dashboard-btn"
                    className={classes.actionBtn}
                    onClick={handleBackNavigation}
                >
                    <Trans i18nKey="goBack">Go Back</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6} justify="flex-end">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="edit-btn"
                    onClick={onEdit}
                    disabled={isEditDisabled()}
                >
                    <Trans i18nKey="editShipment">Edit Shipment</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('deliveryOrderDetails', 'Delivery Order Details'),
                thinBanner: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} align-items="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={8} justify="flex-start">
                            {deliveryOrder?.deliveryOrderNumber ? (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                                    {' #'}
                                    {deliveryOrder?.deliveryOrderNumber}
                                </Typography>
                            ) : (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                                    {' # '}
                                    <Trans i18nKey="pending">Pending</Trans>
                                </Typography>
                            )}
                        </Grid>
                        {deliveryOrder && (
                            <Grid container item xs={4} justify="flex-end">
                                <DeliveryOrderShareLinks
                                    shipToAddress={shipToAddress}
                                    shipToName={shipToName}
                                    order={deliveryOrder}
                                    linkedDeliveryOrder={linkedProductionOrders}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container className={classes.subheader}>
                        <DeliveryOrderDetailsATMProvider value={{ isATM: isATM }}>
                            <DeliveryOrderDetailsSubheader
                                modeOfTransport={modeOfTransport}
                                shipToAddress={shipToAddress}
                                shipToName={shipToName}
                                shipToId={shipToId}
                                deliveryOrder={deliveryOrder}
                                linkedProductionOrders={linkedProductionOrders}
                            />
                        </DeliveryOrderDetailsATMProvider>
                    </Grid>
                </Grid>
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default DeliveryOrderDetails;
