import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Button, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { OrderSummary } from '../../../../store/reducers/order-summaries';
import {
    formattedDateWithTimezone,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import EachesConversion from '../../../reusable/atoms/EachesConversion';
import { Trans } from 'react-i18next';
import SummaryContextMenu from './SummaryContextMenu';

interface ReviewOrderSummaryDetailsCardProps {
    order?: OrderSummary;
    isviewOrderDetailsTab: boolean;
    isviewSummaryOtherTabs: boolean;
}
const useStyles = makeStyles((theme) => ({
    container: {},
    textField: {
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: '.125em',
        width: '100%'
    },
    column: {
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionsBtn: {
        transform: 'rotate(90deg)',
        filter: 'invert(45%) sepia(93%) saturate(539%) hue-rotate(145deg) brightness(87%) contrast(104%)'
    },

    gridItemSmall: {
        maxWidth: '6em',
        wordWrap: 'break-word'
    },
    gridItemMedium: {
        maxWidth: '8em',
        wordWrap: 'break-word'
    }
}));

const ReviewOrderSummaryDetailsCard: React.FC<ReviewOrderSummaryDetailsCardProps> = ({
    order,
    isviewOrderDetailsTab,
    isviewSummaryOtherTabs
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuBlur = () => {
        setAnchorEl(null);
    };

    const handleEditOrder = () => {
        history.push(`/edit-order-shipments/${order?.deliveryOrderId}`);
    };
    const handleViewOrder = () => {
        history.push(`/order-details/${order?.deliveryOrderId}`);
    };

    const formatDeliveryOrderNumber = (deliveryOrderNumber?: string, deliveryOrderId?: number) => {
        if (deliveryOrderNumber && deliveryOrderNumber !== '0') {
            return (
                <>
                    <Link
                        to={`/order-details/${deliveryOrderId}`}
                        component={RouterLink}
                        underline="none"
                    >
                        {deliveryOrderNumber}
                    </Link>
                </>
            );
        } else {
            return (
                <em>
                    <Link
                        to={`/order-details/${deliveryOrderId}`}
                        component={RouterLink}
                        underline="none"
                    >
                        <Trans i18nKey="pending">Pending</Trans>
                    </Link>
                </em>
            );
        }
    };

    return (
        <>
            <Grid container className={classes.container} spacing={2}>
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>#{order?.soldTo}</Typography>
                </Grid>
                <Grid item className={`${classes.column} ${classes.gridItemMedium}`}>
                    <Typography>#{order?.shipTo}</Typography>
                </Grid>
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>
                        {formatDeliveryOrderNumber(order?.orderNumber, order?.deliveryOrderId)}
                    </Typography>
                </Grid>
                {!isviewSummaryOtherTabs && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.shipmentNumber}</Typography>
                    </Grid>
                )}
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>{order?.customerPoNumber}</Typography>
                </Grid>
                {isviewOrderDetailsTab && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography style={{ fontWeight: 'bold' }}>{order?.productSKU}</Typography>
                        <Typography>{order?.productGraphicId}</Typography>
                    </Grid>
                )}
                {isviewOrderDetailsTab && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.productName}</Typography>
                    </Grid>
                )}
                {isviewOrderDetailsTab && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.size}</Typography>
                    </Grid>
                )}
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>#{order?.shipFrom}</Typography>
                </Grid>
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>{order?.palletQuantity + ' PL '}</Typography>
                    <Typography>
                        <EachesConversion
                            palletQuantity={order?.palletQuantity || 0}
                            quantityPerPallet={order?.quantityPerPallet || 0}
                            label={true}
                        />
                    </Typography>
                </Grid>
                {!isviewOrderDetailsTab && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.shipments}</Typography>
                    </Grid>
                )}
                {isviewOrderDetailsTab && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.poLine}</Typography>
                    </Grid>
                )}
                {isviewOrderDetailsTab && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.releaseNumber}</Typography>
                    </Grid>
                )}
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>
                        {formattedDateWithTimezone(moment(order?.submitted), 'MM/DD/YYYY')}
                    </Typography>
                </Grid>
                {!isviewSummaryOtherTabs && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>
                            {getFullFormattedDateTime('', order?.requestedDateTime)}
                        </Typography>
                    </Grid>
                )}
                {!isviewSummaryOtherTabs && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>
                            {getFullFormattedDateTime('', order?.scheduledDateTime)}
                        </Typography>
                    </Grid>
                )}
                {!isviewSummaryOtherTabs && (
                    <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                        <Typography>{order?.carrier}</Typography>
                    </Grid>
                )}
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Typography>{order?.status}</Typography>
                </Grid>
                <Grid item className={`${classes.column} ${classes.gridItemSmall}`}>
                    <Button
                        className={classes.actionsBtn}
                        data-testid="action-menu-button"
                        onClick={handleActionMenuClick}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/ellipsis.svg`}
                            alt={`Action menu for ${order?.deliveryOrderId}`}
                        />
                    </Button>
                </Grid>
            </Grid>
            <SummaryContextMenu
                onEditOrder={handleEditOrder}
                onViewOrder={handleViewOrder}
                onMenuBlur={handleMenuBlur}
                anchorEl={anchorEl}
                orderStatus={order?.status || ''}
            />
        </>
    );
};
export default ReviewOrderSummaryDetailsCard;
